 import { Injectable} from '@angular/core';
 
 @Injectable({
   providedIn: 'root',
 })

 export class  OverlayTextService{
   
   constructor() {
   }
 
   ngOnInit() { }
  }


