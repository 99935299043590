<div class="whole-container">
  
  <div class="project-title">
    <strong *ngIf="data.onCreateVideo" class="main-headings">{{data.titleValue}}</strong>
  </div>
  <div class="title">
    <strong *ngIf="!data.onCreateVideo && !data.isPublishScreen" class="main-headings page-name">Script</strong>
    <strong *ngIf="data.onCreateVideo && !data.isSceneSelected && !data.isPublishScreen" class="main-headings page-name">Storyboard</strong>
    <strong *ngIf="data.onCreateVideo && data.isSceneSelected" class="main-headings page-name">Edit Scene</strong>
    <strong *ngIf="data.isPublishScreen" class="main-headings page-name">Publish</strong>
  </div>

  <div class="nav-buttons">
    <!-- scripts page -->
    <div *ngIf="!data.onCreateVideo && !data.isPublishScreen" class="btn-box">
      <button routerLink="/" (click)="data.reload=true" class="white-btn">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Cancel
      </button>
      <button (click)="data.notifyOnCreateVideo('clicked')" class="blue-btn">Create PSV</button>
    </div>
    <!-- all scenes page -->
    <div *ngIf="data.onCreateVideo && !data.isSceneSelected && !data.isPublishScreen" class="btn-box">
      <div class="conatiner-tran" *ngFor="let index of data.scenes; index as i">
      <div [ngStyle]="{'margin': '5%'}" id = "transcript-player{{i}}" class="tran-element"></div>
    </div>
      <button (click)="data.reloadComponents()" class="white-btn">
        <mat-icon>keyboard_arrow_left</mat-icon>
        &nbsp;Script
      </button>
      <button (click)="openTemplateModal()" class="blue-btn">Save Template</button>
    </div>
    <!-- edit scenes page -->
    <div *ngIf="data.onCreateVideo && data.isSceneSelected" class="btn-box">
      <button (click)="data.notifyOnAllSceneSelected('clicked')" class="white-btn">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Storyboard
      </button>
      <!-- <button (click)="onPublish()" class="blue-btn">Publish</button> -->
    </div>
    <!-- publish page -->
    <div *ngIf="data.isPublishScreen" class="btn-box">
      <button *ngIf="!data.isPublishClicked" routerLink="/template-page" (click)="data.isPublishClicked = false; data.onPublishBtn = true; data.isPublishScreen = false" class="white-btn">
        <mat-icon>keyboard_arrow_left</mat-icon>
        All Template
      </button>
      <button *ngIf="data.isPublishClicked" (click)="data.isPublishClicked = false; data.isFewUsers = true" class="white-btn" >
        <mat-icon>keyboard_arrow_left</mat-icon>
        &nbsp;Back
      </button>
      <button *ngIf="!data.isPublishClicked" (click)="data.notifyOnGenerateUrl('clicked')" class="blue-btn">Generate PSV</button>
      <button *ngIf="data.isPublishClicked" routerLink="/" (click)="reloadData()" class="blue-btn">Go Home</button>
    </div>
  </div>

  <!-- <div class="bc-container">
      <ul *ngIf="!data.isPublishScreen" class="progressbar">
        <li class="active" (click)="data.reloadComponents()">Script</li>
        <li [ngClass]="{'active' : data.onCreateVideo}" (click)="data.notifyOnAllSceneSelected('clicked')">All Scenes</li>
        <li [ngClass]="{'active' : (data.onCreateVideo && data.isSceneSelected)}" (click)="data.notifyOnSceneSelected('clicked')">Scene Elements</li>
        <li [ngClass]="{'active' : data.isPublishScreen}" (click)="data.onPublish()">Publish</li>
      </ul>
      <ul *ngIf="data.isPublishScreen" class="progressbar">
        <li class="active" routerLink="/editor" (click)="data.reloadComponents()">Script</li>
        <li class="active" routerLink="/editor" (click)="data.onPublishBtn = true; data.isPublishScreen = false">All Scenes</li>
        <li class="active" routerLink="/editor" (click)="data.isPublishScreen = false">Scene Elements</li>
        <li class="active">Publish</li>
      </ul>
  </div> -->
</div>

