import { Component, OnInit, Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-api-error-handler',
  templateUrl: './api-error-handler.component.html',
  styleUrls: ['./api-error-handler.component.css']
})

export class ApiErrorHandlerComponent implements OnInit {

  apiStatus:any;
  apiStatusText:any;

  constructor( public dialogRef: MatDialogRef<ApiErrorHandlerComponent>) {}

  ngOnInit(): void {}

  cancelDialog() {
    this.dialogRef.close('cancel');
  }

}