<div class="container-fluid">
  <h3 class="text-center"><strong><strong>Choose Background Video</strong></strong></h3>
  <div>
    <p class="text-center" [ngStyle]="{'font-size' : '15px'}">
      Please choose from the options below
    </p>
    <div class="sample">
      <button class="white-btn choose-btn" (click)="isSamplefiles=true;isLocalfiles=false">
        Choose from Gallery
      </button>
      <button class="white-btn choose-btn" (click)="isLocalfiles=true;isSamplefiles=false">
        Choose from Local Files
      </button>
    </div>
  </div>

  <br>
  <div class="choose-bgvideo-dailog container-fluid"
    [ngStyle]="{'background-color': ' rgb(249, 250, 250)', 'overflow-x' : 'hidden' }">
    <div class="properties font-size-sm file-display" *ngIf="isLocalfiles">
      <input style="margin-left: 36%;" mat-button accept="video/*" (change)="onFileUpload($event)" type="file"><br>

      <div *ngIf="this.saveLoader" class="loadScreen">
        <div id="overlay">
          <em class="fa fa-spinner fa-spin spin-big"></em>
        </div>
      </div>
    </div>

    <div>
      <div class="media-container" [style.visibility]="isSamplefiles ? 'visible': 'hidden'" [style.pointerEvents]="isSamplefiles?'auto': 'none'">
        <!-- <div class="container-fluid ">
          <div class="card-group row">
            <div class="col-6" *ngFor="let video of videoSrc;index as i"
              [style.background-color]="selectedVideoIndex === i ? 'blue' : ''">
              <div class="hoverable" [ngStyle]="{'width': '300px', 'margin-left':''}">
                <div class="card-body">
                  <video #bgVideoElement class="btn btn-default" controls="true" [ngStyle]="{'width': '100%', 'margin-left':''}"
                    [src]="video" type="video/mp4" (click)="clicked(video,i,bgVideoElement)">

                  </video>
                  <div class="videoName">
                    <strong>{{videoList[i]}}</strong>
                  </div>
                </div>


              </div>
              <br>
            </div>
          </div>
        </div> -->
        <div class="gallery-list">
          <div class="gallery-card video" *ngFor="let video of videoSrc;index as i">
              <div (click)="clicked(video.url,i,videocheck)" [style.background]="selectedVideoIndex === i ? '#1e8efb' : '' " class="highlight">
                  <div class="video-style">
                      <!-- <input #checkbox (click)="radioChecked(videocheck,i)" type="radio" name="videos" class="checkbox"
                          id={{i}} /> -->
                      <video #videocheck [src]="video.blobUrl" controls for="check" height="100%" width="100%"></video>
                  </div>
                  <span class="media-label">{{video.videoName}}</span>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>

  <div class="action-row">
    <button *ngIf="enableEdit" class="blue-btn edit-button" type="button" (click)="editSelectedVideo()"> Edit
    </button>
    <button class="white-btn" type="button" (click)="cancel()"> Cancel
    </button>
    <button class="blue-btn" style="margin-left : 10px" type="button" matTooltip="Use selected video"
      (click)="closeDialog()"> Use
    </button>
  </div>
  <!-- <input id="videoSrc" accept="video/*"   type="file" class="change-bgvideo"> -->
</div>