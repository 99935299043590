import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/shared/services/data.service';
import { MatDialog } from '@angular/material/dialog';
import { UserDataComponent } from 'projects/user-data/src/public-api';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-script-content',
  templateUrl: './script-content.component.html',
  styleUrls: ['./script-content.component.css']
})
export class ScriptContentComponent implements OnInit {
  datafields: boolean = false;
  temp!: any;
  listofelements: any;
  listofinputs: any = {};
  scriptlines: any;
  listofLabels: any = [];
  isTemplateUpdated: boolean = false;
  updateDataSubscription!: Subscription; //Triggered to notify the changes in all scenes section to script section
  inputForm = new FormGroup({
    script: new FormArray([])
  });

  constructor(readonly data: DataService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.setScriptArray();
    this.updateDataSubscription = this.data.updateTemplateObservable.subscribe(val => {
      if (val === "scene_updated") {
        this.setScriptArray();
        this.updateTemplate();
        let newFormattedUserdata = this.data.formatUserDataJSON(this.listofLabels);
        this.data.userData = this.data.MergeObjects(newFormattedUserdata, this.data.userData);
      }
    })
  }

  ngOnDestroy() {
    this.updateDataSubscription.unsubscribe();
  }
  get script(): FormArray {
    return this.inputForm.get('script') as FormArray;
  }

  //Triggered when drag and drop event occur, change the items in scriptArray and scenes
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.data.scriptArray, event.previousIndex, event.currentIndex);
    moveItemInArray(this.data.scenes, event.previousIndex, event.currentIndex);
    for (let i = 0; i < this.data.scenes.length; i++) {
      this.data.scenes[i].id = 'player' + i.toString();
    }
    this.templateChanged();
  }

  // To add new line in script
  addSection() {
    this.script.push(new FormControl(null));
    this.data.scriptArray.push('');
    this.templateChanged();
  }

  // Triggered when script got changed from input field
  scriptContentChange(contentAfterChange: any, index: number) {
    this.data.scriptArray[index] = contentAfterChange;
    this.setScriptArray();
    this.templateChanged();
  }

  templateChanged() {
    this.data.stateChanged = true;
    this.data.fileContent = "";
    this.data.fileContent = this.data.scriptArray.join('\n');
    this.updateTemplate();
    let newFormattedUserdata = this.data.formatUserDataJSON(this.listofLabels);
    this.data.userData = this.data.MergeObjects(newFormattedUserdata, this.data.userData);
    this.data.notifyOnUpdateData("template_updated");
    this.isTemplateUpdated = true;
  }

  userdata() {
    this.datafields = true;
  }

  openUserDataDialog(): void {
    this.updateTemplate();

    const dialogRef = this.dialog.open(UserDataComponent, {
      data: {}
    });

    if (!dialogRef.componentInstance.dataService.userData && Object.keys(this.data.userData).length == 0) {
      dialogRef.componentInstance.dataService.userData = this.data.formatUserDataJSON(this.listofLabels);
    }
    else {
      dialogRef.componentInstance.dataService.userData = this.data.userData;
    }
    if (this.isTemplateUpdated) {
      dialogRef.componentInstance.dataService.userData = this.data.userData;
      this.isTemplateUpdated = false;
    }

    dialogRef.afterClosed().subscribe(result => {
      this.data.userData = result;
      this.data.notifyOnUpdateData("userdata_updated");
    });
  }

  updateTemplate() {
    this.listofLabels = [];
    this.temp = this.data.fileContent;
    this.listofelements = this.temp.match(/{(.*?)}/gm);
    if (this.listofelements) {
      let size = this.listofelements.length
      for (let i = 0; i < size; i++) {
        let key = (this.listofelements[i].substring(1, this.listofelements[i].length - 1));
        this.listofLabels.push(key.split('.'));
      }
    }
  }


  // To set new script FormArray once the content change in script
  setScriptArray() {
    this.script.clear();
    for (let i = 0; i < this.data.scriptArray.length; i++) {
      this.script.push(new FormControl(null));
    }
    this.script.setValue(this.data.scriptArray)
  }

}
