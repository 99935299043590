import { Component, OnInit, Optional } from '@angular/core';
import { VideoAnnotation } from 'src/app/shared/models/video-annotation.model';
import { AudioAnnotation } from 'src/app/shared/models/audio-annotation.model';
import {AudioOverlayAnnotation} from 'src/app/shared/models/audio-overlay.model';
import { ChartAnnotation } from 'src/app/shared/models/chart-annotation.model';
import { CssProperties } from './../../shared/models/css-properties.model';
import { ImageAnnotation } from 'src/app/shared/models/image-annotation.model';
import { LinkAnnotation } from 'src/app/shared/models/link-annotation.model';
import { ProgressbarAnnotation } from 'src/app/shared/models/progressbar-annotation.model';
import { TextAnnotation } from './../../shared/models/text-annotation.model';
import { DataService } from 'src/app/shared/services/data.service';
import { WebpageAnnotation } from 'src/app/shared/models/webpage-annotation.model';
import { ErrorComponent } from 'src/app/shared/error/error.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import { FileAnnotation } from 'src/app/shared/models/file-annotation.model';
import { MediaDialogComponent } from '../media-dialog/media-dialog.component';
import { environment } from 'src/environments/environment';
import { AnnotationGalleryComponent } from '../annotation-gallery/annotation-gallery.component';
import { StorageService } from 'src/app/shared/services/storage.service';
import {MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions} from '@angular/material/tooltip';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { CaptionComponent } from '../../../../projects/caption/caption.component';
import { CaptionAnnotation } from 'src/app/shared/models/caption-annotation.model';
import { MediaHubComponent } from '../media-hub/media-hub.component';

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 500,
  hideDelay: 0,
  touchendHideDelay: 0,
  position: 'left',
};

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css'],
  providers: [{provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}]
})
export class ToolbarComponent implements OnInit {
  fontSize: any;
  // updateToolbar: boolean=false;
  newOverlayObject: any;
  newAudioObject: any;
  constructor(readonly data:DataService,private storageService: StorageService, private dialog : MatDialog, @Optional() private dialogRef: MatDialogRef<MediaDialogComponent>) { }

  openDialog(type:string, id?:any){
    this.data.mediaType = type; 
    let annotationElement:any;
    // open new component - media - both audio and video
    //new component - 'some text - test' - html - <lib-live-video>
    this.dialogRef = this.dialog.open(MediaDialogComponent,{ disableClose: true});
    if(id){
      annotationElement = this.data.overlayJSON.overlays[id - 1].srcUrl.split('/');
      this.dialogRef.componentInstance.mediaAnnotation = annotationElement[annotationElement.length-1] ? annotationElement[annotationElement.length-1] : undefined;
    }
    this.dialogRef.afterClosed().subscribe((result:any) =>{ 
      if(result){
        if(id){
          // let videoElement: any = document.querySelector("#videoAnnotation"+ id);
          // videoElement.src = null;
          // videoElement.srcUrl = null;
          this.data.overlayJSON.overlays[id - 1].srcUrl = result;
          // this.callOverlayAnnotations(this.data.overlayJSON.overlays[id - 1]);
          this.data.overlayAnnotations();
          console.log('Rerecord')
          // videoElement.src = result;
          // videoElement.load();
        }else{
          this.initializeOverlayObject(type,result);
          this.data.isToolBarOpen = true;
          this.data.activeTool = type;
          if(type =="livevideo") {
            this.data.enableAspectRatio=true;
            this.data.initializeFontSize();
            this.data.aspectRatio = parseInt(this.data.selectedAnnotation.cssProperties.width.substring(-2))/ parseInt(this.data.selectedAnnotation.cssProperties.height.substring(-2));
          }
          console.log('LiveRecord')
        }
      }
    })
  }

  ngOnInit(): void {
  }

  // function to open Media Hub
  openMediaHub(mediaType: string){
    let dialogRef = this.dialog.open(MediaHubComponent, { disableClose: true});
    dialogRef.componentInstance.mediaType = mediaType;
    dialogRef.afterClosed().subscribe((media:any) =>{
      if(media){
        this.initializeOverlayObject(mediaType,media.url);
        this.data.isToolBarOpen = true;
        this.data.activeTool = mediaType;
      }
    })
  }

  callOverlayAnnotations($event:any){
    this.data.stateChanged = true;
   this.data.overlayAnnotations();
   }
 
   callSetDraggerPositionEvent($event:any){
   this.data.setDraggerPosition();
   }

   updateAnnotationTableData($event:any){
     this.data.annotationTableData=$event;
   }
   updateCaptionAnnotationTableData($event: any){
    this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData = $event;
   }
 
  
  showToolbar(type:any) {
    if(type === 'caption'){
      this.initializeCaptionOverlayObject(type);
    }
    else if(type !== 'tts'){
    // if(type !== 'tts'){
      this.initializeOverlayObject(type);
    // }
    // else{
    //   if(this.data.overlayJSON.audios.length < 1){
    //     this.initializeOverlayObject(type);
    //   }
    //   else{ 
    //     this.data.showSnackBar("You can only add one TTS in a scene",'close');
    //   }
    // }
    this.data.enableAspectRatio=true;
    this.data.initializeFontSize();
    // if(type === 'tts'){
    //   this.data.selectedAnnotation = this.data.overlayJSON.audios[0];
    //   this.data.selectedDatasourceId = this.data.getAnnotationTableId(this.data.selectedAnnotation);
    //   this.data.notifyAnnotationSwitch("notified");
    //   this.data.updateCurrentTime(this.data.overlayJSON.audios[0].start);
    // }
    if(type !== 'livevideo' || type !== 'liveAudio'){
      this.data.isToolBarOpen = true;
      this.data.activeTool = type;
    }
  }
}

  

  updateValuesAfterAudioPersonalization($event:any){
    this.data.tts = $event.tts;
    this.data.isToolBarOpen = $event.isToolBarOpen;
    this.data.overlayJSON = $event.overlayJSON;
    this.data.videoDuration = $event.videoDuration;
    this.data.annotationTableData = $event.annotationTableData;
    this.data.updateAudio = $event.updateAudio;
    this.data.ttsProviderOptions = $event.ttsProviderOptions;
    this.data.ttsOptions = $event.ttsOptions;
    this.data.isTtsProviderChanged = $event.isTtsProviderChanged;
    this.data.isLoaderEnabled = $event.isLoaderEnabled;
  }

  callNotifyDurationChange($event:any){
    this.data.notifyDurationChange($event);
  }

  callUpdateCurrentTime($event:any){
    this.data.updateCurrentTime($event);
  }

  initializeCaptionOverlayObject(type:any){
    let snackBarMessage = "";
    this.data.stateChanged = true;
    const startTime = 0.001;
    //const isDurationChanged = false;
      this.newOverlayObject = {};
      this.data.italicBtn = false;
      this.data.boldBtn = false;
      this.data.underlineBtn = false;
      const id = this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.length + 1 ;
      const cssProps = new CssProperties();
      const captionAnnotation = new CaptionAnnotation(id, startTime, this.data.videoDuration, cssProps);
      this.newOverlayObject=captionAnnotation;
      snackBarMessage = "Caption added";
      this.data.setAnnotationTableData(this.newOverlayObject);
      // this.data.overlayJSON.overlays.push(this.newOverlayObject);
      this.data.selectedAnnotation = this.newOverlayObject;
      // this.data.overlayAnnotations();
      this.data.setDraggerPosition();
      this.data.notifyAnnotationSwitch("notified");
      this.data.updateCurrentTime(this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[id - 1].start);
      this.data.showAnnotationSnackBar(snackBarMessage);
    this.data.selectedDatasourceId = this.data.caption_annotationTableData.length - 1;
  }

  updateOverlayJson($event:any){
    this.data.overlayJSON = $event 
}
  initializeOverlayObject(type:any,srcUrl?:any) {
    let snackBarMessage = "";
    this.data.stateChanged = true;
    const startTime = 0.001;
    if (type === "tts") {
      this.data.selectedAnnotation = {};
      const id = this.data.overlayJSON.audios.length + 1;
      const audioAnnotation = new AudioAnnotation(id, startTime, this.data.videoDuration, this.data.tts);
      this.newAudioObject = audioAnnotation;
      snackBarMessage = "TTS added"
      this.newAudioObject.params.Text = "New Audio Added";
      this.data.selectedAnnotation = this.newAudioObject;
      this.data.setAnnotationTableData(this.newAudioObject);
      this.data.overlayJSON.audios.push(this.newAudioObject);
      this.data.overlayJSON.audios[this.newAudioObject.id - 1].params.Text = "New Audio Added";
      this.data.updateAudio = true;
      this.data.overlayAnnotations();
      this.data.notifyAnnotationSwitch("notified");
      this.data.updateCurrentTime(this.data.overlayJSON.audios[id - 1].start);
      this.data.showAnnotationSnackBar(snackBarMessage);
    } else {
      this.newOverlayObject = {};
      this.data.selectedAnnotation = {};
      this.data.italicBtn = false;
      this.data.boldBtn = false;
      this.data.underlineBtn = false;
      const id = this.data.overlayJSON.overlays.length + 1;
      switch (type) {
        case 'text': {
          const cssProps = new CssProperties();
          const textAnnotation = new TextAnnotation(id, startTime, this.data.videoDuration, cssProps);
          this.newOverlayObject=textAnnotation;
          snackBarMessage = "TEXT added";
          break;
        }
        case 'image': {
          const cssProps = new CssProperties();
          const imageAnnotation = new ImageAnnotation(id, startTime, this.data.videoDuration, cssProps);
          this.newOverlayObject = imageAnnotation;
          this.newOverlayObject.srcUrl = srcUrl ? srcUrl : this.storageService.storageWebServerUrl + 'psv2/images/SampleImage.png';
          snackBarMessage = "IMAGE added";
          break;
        }
        case 'liveAudio': {
          const cssProps = new CssProperties();
          const liveAudioAnnotation = new AudioOverlayAnnotation(id, startTime, this.data.videoDuration, cssProps);
          liveAudioAnnotation.type='liveAudio';
          liveAudioAnnotation.srcUrl = srcUrl;
          this.newOverlayObject = liveAudioAnnotation;
          snackBarMessage = "LIVE AUDIO added";
          break;
        }
        case 'video': {
          const cssProps = new CssProperties();
          const videoAnnotation = new VideoAnnotation(id, startTime, this.data.videoDuration, cssProps);
          this.newOverlayObject = videoAnnotation;
          this.newOverlayObject.srcUrl = srcUrl ? srcUrl : this.storageService.storageWebServerUrl + 'psv2/videos/SampleVideo.mp4';
          snackBarMessage = "VIDEO added";
          break;
        }
        case 'livevideo': {
          const cssProps = new CssProperties();
          const liveVideoAnnotation = new VideoAnnotation(id, startTime, this.data.videoDuration, cssProps);
          liveVideoAnnotation.type = 'livevideo';
          liveVideoAnnotation.srcUrl=  srcUrl;
          this.newOverlayObject = liveVideoAnnotation;
          snackBarMessage = "LIVE VIDEO added";
          break;
        }
        case 'audio': {
          const cssProps = new CssProperties();
          const audioOverlayAnnotation = new AudioOverlayAnnotation(id, startTime, this.data.videoDuration, cssProps);
          this.newOverlayObject = audioOverlayAnnotation;
          this.newOverlayObject.srcUrl = srcUrl ? srcUrl : this.storageService.storageWebServerUrl + 'psv2/audios/SampleAudio.m4a'
          snackBarMessage = "AUDIO added";
          break;
        }
        case 'link': {
          const cssProps = new CssProperties('link');
          const linkAnnotation = new LinkAnnotation(id, startTime, this.data.videoDuration, cssProps);
          this.newOverlayObject = linkAnnotation;
          snackBarMessage = "CALL-TO-ACTION added";
          break;
        }
        case 'chart': {
          const cssProps = new CssProperties('chart');
          const chartAnnotation = new ChartAnnotation(id, startTime, this.data.videoDuration, cssProps);
          this.newOverlayObject = chartAnnotation;
          snackBarMessage = "CHART added";
          break;
        }
        case 'progressbar': {
          const cssProps = new CssProperties();
          const progressbarAnnotation = new ProgressbarAnnotation(id, startTime, this.data.videoDuration, cssProps);
          this.newOverlayObject = progressbarAnnotation;
          snackBarMessage = "PROGRESS BAR added";
          break;
        }
        case 'webpage': {
          const cssProps = new CssProperties();
          const webpageAnnotation = new WebpageAnnotation(id, startTime, this.data.videoDuration, cssProps);
          this.newOverlayObject = webpageAnnotation;
          this.newOverlayObject.src = "https://panther.ad.infosys.com:5605";
          snackBarMessage = "WEB PAGE added";
          break;
        }
        case 'file': {
          const cssProps = new CssProperties();
          const fileAnnotation = new FileAnnotation(id, startTime, this.data.videoDuration, cssProps);
          this.newOverlayObject = fileAnnotation;
           this.newOverlayObject.src = srcUrl
          snackBarMessage = "DOCUMENT added";
          break;
        }
      }
      this.data.setAnnotationTableData(this.newOverlayObject);
      this.data.overlayJSON.overlays.push(this.newOverlayObject);
      this.data.selectedAnnotation = this.newOverlayObject;
      this.data.overlayAnnotations();
      // if(type !== "webpage" || type !== "file"){
      //   this.data.setDraggerPosition();
      // }
      this.data.setDraggerPosition();
      this.data.notifyAnnotationSwitch("notified");
      this.data.updateCurrentTime(this.data.overlayJSON.overlays[id - 1].start);
      this.data.showAnnotationSnackBar(snackBarMessage);
    }
    this.data.selectedDatasourceId = this.data.annotationTableData.length - 1;
    // const scrollElement = <HTMLElement>document.querySelector("div.annotations-table > div.table-container.scrollable-pane");
    // scrollElement.scrollTop = scrollElement.scrollHeight;
  }
  openGallery(){
    let dialogRef = this.dialog.open(AnnotationGalleryComponent);
    
  }

  async loadFile(blob:any){
    await this.storageService.uploadDocumentFiles(blob, this.data.selectedAnnotation.fileName, 'tempProject').then(src => {
      this.data.selectedAnnotation.src = src;
      this.data.overlayAnnotations();
     }).catch((err:any) => {
      this.data.showSnackBar(this.data.genericErrorMsg,'close');
    });
  }
  // openCaptionDialog(): void {
  //   const dialogRef = this.dialog.open(CaptionComponent, {
  //     data: {}
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //   });
  // }
  }
