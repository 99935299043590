export class OutputProperties{
    tts:any;
    isToolBarOpen:boolean;
    overlayJSON:any;
    annotationTableData:any;
    videoDuration:any;
    updateAudio:boolean;
    ttsProviderOptions:any;
    ttsOptions:any;
    isTtsProviderChanged:boolean;
    isLoaderEnabled:boolean;

    constructor(tts:any,isToolBarOpen:boolean,overlayJSON:any,annotationTableData:any,videoDuration:any,updateAudio:boolean,ttsProviderOptions:any,ttsOptions:any,isTtsProviderChanged:boolean,isLoaderEnabled:boolean){
        this.tts = tts;
        this.isToolBarOpen =isToolBarOpen;
        this.overlayJSON = overlayJSON;
        this.annotationTableData = annotationTableData;
        this.videoDuration = videoDuration;
        this.updateAudio = updateAudio;
        this.ttsProviderOptions = ttsProviderOptions;
        this.ttsOptions = ttsOptions;
        this.isTtsProviderChanged = isTtsProviderChanged;
        this.isLoaderEnabled = isLoaderEnabled;
    }

}