<div class="home-flex-container">
    <div class="home-container tile"
        style="width: 40%;  height: 550px; box-shadow: 0px 0px 10px 0px rgb(70 118 193 / 25%);">
        <strong style="margin-left: 5%; font-size: 2rem;" class="main-headings">Tutorials to get you started with
            PSV</strong>
        <video class="help-video" controls>
            <source src=".././assets/videos/psv.mp4" type="video/mp4">
            <source src=".././assets/videos/psv.mp4" type="video/ogg">
        </video>
        <div class="sub-container">
            <p class="sub-heading1" style="padding-top: 5px;">Start here</p>
            <p class="heading2" style="padding-top: 5px;">Ready to get started on a new video? we have
                everything ready for you! Hello and welcome to PSV, this is an application that will enable you to
                give a personalised and smart touch to all of your videos. PSV stands for
                "Personalized Smart Videos".</p>
        </div>

    </div>


</div>