import { UserDataService } from './user-data.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, AfterViewInit, Inject, Injectable, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Subject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';

export class TodoItemNode {
  children!: TodoItemNode[];
  filename!: string;
  type!: string;
  id!: number | null;
}

export class TodoItemFlatNode {
  type!: string;
  level!: number;
  expandable!: boolean;
  filename!: string;
  id!: number;
}

@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);
  userDataJsonSubject = new Subject<any>();
  userDataObj:any;
  itemIdCounter = 0;

  get data(): TodoItemNode[] { return this.dataChange.value; }
  

  constructor( public dataService: UserDataService ) {
  }

  initialize() {
    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    // file node as children.
    if(JSON.stringify(this.dataService.userData).indexOf('userData') == -1){
      this.userDataObj  = JSON.parse(`{ "userData": ${JSON.stringify(this.dataService.userData)} }`);
    } else {
      this.userDataObj  = this.dataService.userData;
    }
    // Remove chart specific data from the object
    for(let key of Object.keys(this.userDataObj.userData)){
      if(key.substr(0,9) == 'psv-chart'){
        delete this.userDataObj.userData[key];
      }
    }
    const data = this.buildFileTree(this.userDataObj, 0);
    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
  buildFileTree(obj: {[key: string]: any}, level: number): TodoItemNode[] {
    return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new TodoItemNode();
      node.filename = key;
      node.id = ++this.itemIdCounter;

      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.type = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  tempParent!:any;
  traverseNestedObjectAndDelete(obj:any, deleteId:any){
    if(obj.id === deleteId){
      for(let index in this.tempParent.children){
        if(this.tempParent.children[index] == obj){
          this.tempParent.children.splice(index,1);
        }
      }
      this.dataChange.next(this.data);
    }
    if(obj.id !== deleteId){
      if(obj.children){
        obj.children.forEach((object:any) => {
          this.tempParent = obj;
          this.traverseNestedObjectAndDelete(object, deleteId);
        });
        return;
      } else {
        return;
      }
    }
  }

  newJson = {};

  transformTreeToJson(obj:any, newJson:any){
    newJson[obj.filename] = {};
    if(obj.children) {
      obj.children.forEach((element:any) => {
        newJson[obj.filename][element.filename] = {};
        if(element.children) {
          newJson[obj.filename][element.filename] = {};
          this.transformTreeToJson(element, newJson[obj.filename]);
        } else {
          newJson[obj.filename][element.filename] = element.type;
        }
      });
    } else {
      newJson[obj.filename] = obj.type;
    }
  }

  reInitializeIds(obj:any){
    obj.id = ++this.itemIdCounter;
    if(obj.children){
      obj.children.forEach((object:any) => {
        this.reInitializeIds(object);
      });
      return;
    } else {
      return;
    }
  }

  insertItem(parent: TodoItemNode, name: string) {
    let tempNode = new TodoItemNode();
    tempNode.filename = '';
    tempNode.type = '';
    tempNode.id = null;
    if(parent.children){
      parent.children.push(tempNode);
    } else {
      parent.children = [];
      parent.type = '';
      parent.children.push(tempNode);
      this.updateItem(parent, parent.filename);
    }
    this.itemIdCounter = 0;
    this.reInitializeIds(this.data[0]);
    this.dataChange.next(this.data);
    this.newJson = {};
  }

  deleteItem(deleteNode: TodoItemFlatNode){
    this.itemIdCounter = 0;
    this.reInitializeIds(this.data[0]);
    this.traverseNestedObjectAndDelete(this.data[0], deleteNode.id);
    this.dataChange.next(this.data);
    this.newJson = {};
    this.transformTreeToJson(this.data[0],this.newJson);
    this.userDataJsonSubject.next(this.newJson);
    const data = this.buildFileTree(this.newJson, 0);
    this.dataChange.next(data);
  }

  discardInput(parent: any, name: string) {
    for(let index in parent.children){
      if(parent.children[index].filename == '' && parent.children[index].type == ''){
        parent.children.splice(Number(index),1);
        break;
      }
    }
    if(parent.children.length == 0){
      delete parent['children'];
      parent.type = "";
      this.updateItem(parent, parent.filename + ': null');
    }
    this.dataChange.next(this.data);
  }

  updateItem(node: TodoItemNode, name: string) {
    let split_at=name.indexOf(":");
    let keyval=[];
    if(split_at!=-1){
      keyval.push(name.slice(0,split_at));
      keyval.push(name.slice(split_at+1));
    }else{
      keyval.push(name.slice(0));
    }
    node.filename = keyval[0];
    node.type = keyval[1];
    this.dataChange.next(this.data);
    this.newJson = {};
    this.transformTreeToJson(this.data[0],this.newJson);
    this.userDataJsonSubject.next(this.newJson);
    const data = this.buildFileTree(this.newJson, 0);
    this.dataChange.next(data);
  }
}

@Component({
  selector: 'user-data-dialog',
  templateUrl: 'user-data.html',
  styleUrls: ['user-data.css'],
  providers: [ChecklistDatabase]
})
export class UserDataComponent implements OnInit, AfterViewInit {

  faPlusCircle = faPlusCircle;
  userDataArray:any = [];
  userDataObject:any;
  userJsonFileUrl:any;
  userJsonName: any;
  uploadedUserData:any;
  temp!: any;
  listofelements: any;
  listofinputs: any = {};
  scriptlines: any;
  listofLabels: any = [];

  @Input() fileContent:any;


  constructor(private database: ChecklistDatabase, public dialogRef: MatDialogRef<UserDataComponent>,
   @Inject(MAT_DIALOG_DATA) public data: any, readonly dataService: UserDataService,
    private snackBar: MatSnackBar) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    database.dataChange.subscribe(data => {
      this.itemIdCounter = 0;
      this.dataSource.data = data;
      if(this.userDataTree && this.userDataTree.treeControl){
        this.userDataTree.treeControl.expandAll();
      }
    });

    database.userDataJsonSubject.subscribe(data => {
      this.userDataObject = data;
    });
  }

  ngOnInit() {
    this.database.initialize();
  }

  replaceInputs(listofinputs: any) {
    let t: any = this.temp;
    for (let key in listofinputs) {
      if (listofinputs[key]) {
        t = t.replaceAll("{" + key + "}", listofinputs[key]);
      }
    }
    this.scriptlines = t;
    // this.data.scriptlines = this.scriptlines;
  }

  @ViewChild('userDataMatTree',{static: true}) userDataTree:any;

  ngAfterViewInit(){
    this.userDataTree.treeControl.expandAll();
  }

  validateUserDataKeyStrings(){
    let keyStringPattern = /^[a-zA-Z0-9_]+$/i;
    let isValidKeyString = true;
    let keyInputBoxesNodeList = document.querySelectorAll('.hide-input-box.key');
    let keyInputBoxesArray = Array.prototype.slice.call(keyInputBoxesNodeList);
    for(let index in keyInputBoxesArray){
      let keyInputBoxesNode = <HTMLInputElement>keyInputBoxesNodeList[index];
      if(!keyStringPattern.test(keyInputBoxesNode.value.toString())){
        keyInputBoxesArray[index].className = keyInputBoxesArray[index].className + ' invalid-user-data-key';
        isValidKeyString = false;
      }
    }
    return isValidKeyString;
  }

  updateUserData(userdata?:any) {
    if(userdata)
        this.transformUserDataArrayToObject(userdata);

    if (this.validateUserDataKeyStrings()||userdata) {
        this.transformUserDataArrayToObject();
        this.dismiss();
    } else {
      this.snackBar.open('User data key should have only Alphanumeric characters!', "", {
        duration: 2000,
      });
    }
  }

  transformUserDataArrayToObject(data?:any) {
    if(data){
      this.dataService.userData = data;
    }
    else if (this.userDataObject) {
      this.dataService.userData = this.userDataObject.userData;
    }
   
    this.data.updateAudio = true;
    //this.dataService.overlayAnnotations();
    //this.dataService.addAudioAnnotations();
    //this.dataService.updateCurrentTime(0);
  }

  addMoreUserInputs() {
    this.userDataArray.push([]);
  }

  dismiss(): void {
    this.dialogRef.close(this.dataService.userData);
  }

  tempParentNode!: TodoItemFlatNode;

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<TodoItemFlatNode>;

  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);

  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.type === '';
  
  itemIdCounter = 0;

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.type === node.type
        ? existingNode
        : new TodoItemFlatNode();
    flatNode.id = ++this.itemIdCounter;
    flatNode.type = node.type;
    flatNode.filename = node.filename;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Select the category so we can insert the new item. */
  addNewItem(node: TodoItemFlatNode) {
    let flag = true;
    this.tempParentNode = node;
    let parentNode = this.flatNodeMap.get(node);
    this.database.insertItem(parentNode!, '');
    this.treeControl.expand(node);
  }

  removeItem(node: TodoItemFlatNode) {
    this.database.deleteItem(node);
  }

  discardNode(value: string) {
    const parentNode = this.flatNodeMap.get(this.tempParentNode);
    this.database.discardInput(parentNode!, '');
  }

  /** Save the node to database */
  saveNode(node: TodoItemFlatNode, itemValue: string) {
    const nestedNode = this.flatNodeMap.get(node);
    this.database.updateItem(nestedNode!, itemValue);
  }

  loadUserJsonFile(event:any) {
    const file = event.srcElement.files[0];
    this.userJsonFileUrl = URL.createObjectURL(file);
    this.userJsonName = this.truncateFileText(file);
    this.dataService.getUserDataJson(this.userJsonFileUrl).subscribe((userData:any) => {
      
      if(this.checkJSON(userData)){
        this.uploadedUserData = userData
        if (JSON.stringify(userData).indexOf('userData') == -1) {
          var parsedUserData = JSON.parse(`{ "userData": ${JSON.stringify(userData)} }`);
        } else {
          parsedUserData = userData;

        }

        // Remove chart specific data from the object
        for (let key of Object.keys(parsedUserData)) {
          if (key.substr(0, 9) == 'psv-chart') {
            delete parsedUserData.userData[key];
          }
        }
        const data = this.database.buildFileTree(parsedUserData, 0);

        // Notify the change.
        this.database.dataChange.next(data);

        this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
          this.isExpandable, this.getChildren);
        this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

        this.database.dataChange.subscribe(data => {
          this.itemIdCounter = 0;
          this.dataSource.data = data;
          this.snackBar.open('Data Uploaded successfully', "", {
            duration: 2000,panelClass: ['info-snackbar']
          });
        });
      }
    else{
      this.snackBar.open('User data key should have only Alphanumeric characters!', "", {
        duration: 2000,panelClass: ['error-snackbar']
      });
    }
    })
  }

  truncateFileText(file:any) {
    let fileText;
    if (file.name.length > 17) {
      if (file.type.split("/")[1] === "javascript") {
        fileText = file.name.substring(0, 10) + "..." + "js";
      }
      else {
        fileText = file.name.substring(0, 10) + "..." + file.type.split("/")[1];
      }
    }
    else {
      fileText = file.name;
    }
    return fileText;
  }
  
  checkJSON(jsData:any){
    let jsonParsedData = JSON.parse(JSON.stringify(jsData));
    let pattern = /^[a-zA-Z0-9_]*$/;
    for(let key in jsonParsedData){
      if(!pattern.test(key)){
        return false;
      }
    } 
    return true;
  }
}