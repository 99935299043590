export class URLModel{

    // overrides URL methods to be able to retrieve the original blobs later on
    old_create = URL.createObjectURL;
    old_revoke = URL.revokeObjectURL;
    dict:any = {};

    constructor(){ }
    
    createObjectURL(blob:any) {
        let url = this.old_create(blob);
        this.dict[url] = blob;
        return url
    }
    
    revokeObjectURL(url:string) {
        this.old_revoke(url);
        try {
            if(new URL(url).protocol === 'blob:')
            delete this.dict[url];
        }catch(e){}
    }
    
    getBlob(url:string) {
        return this.dict[url];
    }
}
