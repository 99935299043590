<div>
    <video class="editVideo" (play)="setTimeStamps()" (loadedmetadata)="videoPropertiesOnLoaded($event)" id="bgVideoTrim" controls="true" [src]="this.data.bgSelectedVideoUrl" type="video/mp4">
    </video>
    <br>
    <div *ngIf="enableSlider" class="trimHeader">
        <p><b> Select the Portion to Trim & Apply :</b> </p>
        <br>
        <label class="starttime"><b>Start Time : </b></label><input type="number" [(ngModel)]="minValue">
        <label class="endtime"><b>End Time : </b></label><input type="number" [(ngModel)]="maxValue">
    </div>
    <ngx-slider class="ngxSlider" *ngIf="enableSlider" [(value)]="minValue" [(highValue)]="maxValue" [options]="options" ></ngx-slider>
    <div class="action-row">
        <button class="white-btn cancel" type="button" (click)="closeModal()">Cancel</button>
        <button *ngIf="enableSlider" class="blue-btn trim" type="button" (click)="trimAndApply()" >Trim & Add
        <div *ngIf="this.data.loadTrimmedBgVideo" class="loadScreen">
            <div id="overlay">
                <em class="fa fa-spinner fa-spin spin-big"></em>
            </div>
          </div></button>
    </div>
</div>