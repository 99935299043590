import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-qr-modal',
  templateUrl: './qr-modal.component.html',
  styleUrls: ['./qr-modal.component.css']
})
export class QrModalComponent implements OnInit {
  public psvQrCode: any;
  url: any
  constructor(private dialogRef: MatDialogRef<QrModalComponent>) {

  }

  ngOnInit(): void {
    this.psvQrCode = this.dialogRef.componentInstance.url;

  }
  closeDialog() {
    this.dialogRef.close()
  }
}
