<div *ngIf="operationType!='delete'">
  <div fxLayout="column" fxLayoutGap="1rem" class="fullDiv">
    <strong *ngIf="operationType=='clone'">Clone User Data</strong>
    <strong *ngIf="operationType=='edit'">Edit User Data</strong>
    <strong *ngIf="operationType=='map'">Map User Data</strong>
    <hr>

    <mat-expansion-panel *ngIf="operationType === 'map'" class="api-response-panel">
      <mat-expansion-panel-header>
        <mat-panel-title>View API Response</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="api-response-area">
        <!-- display api response here -->
        <div [style.color]="apiResponse.ok ? '#008000' : '#ff0000'" class="response-code">
          Response Code: &nbsp; {{apiResponse.status}} &nbsp; {{apiResponse.statusText}}
        </div>
        <div class="response">
          {{_database.apiData | json}}
        </div>
      </div>
    </mat-expansion-panel>

    <div *ngIf="operationType === 'map'" class="identifier-picker">
      Identifier Key :
      <select #idKey [ngModel]="identifierKey" (change)="identifierKey = idKey.value">
        <option *ngFor="let key of apiKeys; index as i" [value]="key">{{key}}</option>
      </select>
    </div>

    <label *ngIf="operationType === 'map'" class="map-label">Mapping: PSV User Data <-> API Response</label>
    <div class="treeDiv">
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
          <div class="leftDiv">{{node.filename}}&nbsp;:</div>
          <div class="rightDiv"> 
            <input *ngIf="operationType!='map'" type="text" #value1 [ngModel]="node.item" (change)="saveNode(node,value1.value)">
            <select #apiSelect *ngIf="operationType=='map'" [ngModel]="node.item" (change)="saveNode(node,apiSelect.value)">
                <option *ngFor="let key of apiKeys; index as i" [value]="key">{{key}}</option>
            </select>
          </div>
        </mat-tree-node>
        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
          {{node.filename}} : {{node.item}}
        </mat-tree-node>
      </mat-tree>
    </div>
    <div fxLayoutAlign="end" fxLayout="row" fxLayoutGap="30px">
      <form *ngIf="operationType=='clone'" enctype="multipart/form-data">
        <button class="updateBtn" (click)="updateUserData()" mat-button>Clone</button>
      </form>
      <form *ngIf="operationType=='edit'" enctype="multipart/form-data">
        <button class="updateBtn" (click)="updateUserData()" mat-button>OK</button>
      </form>
      <form *ngIf="operationType=='map'" enctype="multipart/form-data">
        <button class="updateBtn" (click)="updateUserData()" mat-button>Map</button>
      </form>
      <button class="cancelBtn" (click)="cancelDialog()" mat-button>Cancel</button>
    </div>
  </div>
</div>
<div *ngIf="operationType=='delete'" fxLayout="column">
  <p>Are you sure you want delete?</p>
  <div fxLayoutAlign="end" fxLayout="row" fxLayoutGap="30px">
    <button class="updateBtn" mat-button (click)="deleteUserData()">Delete</button>
    <button class="cancelBtn" (click)="cancelDialog()" mat-button>Cancel</button>
  </div>
</div>