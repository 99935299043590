<div class="media-hub-container">
    <mat-tab-group class="media-tab-group" [selectedIndex]="tabIndex">
        <mat-tab label="Images" [disabled]="mediaType !== 'image'">
            <ng-template matTabContent>
                <app-gallery-view [mediaType]="mediaType" (selectedMediaEvent)="mediaSelected($event)"></app-gallery-view>
            </ng-template>
        </mat-tab>
        <mat-tab label="Videos" [disabled]="mediaType !== 'video'  && mediaType !== 'bgVideo'">
            <ng-template matTabContent>
                <app-gallery-view [mediaType]="mediaType" (selectedMediaEvent)="mediaSelected($event)"></app-gallery-view>
            </ng-template>
        </mat-tab>
        <mat-tab label="Audios" [disabled]="mediaType !== 'audio'">
            <ng-template matTabContent>
                <app-gallery-view [mediaType]="mediaType" (selectedMediaEvent)="mediaSelected($event)"></app-gallery-view>
            </ng-template>
        </mat-tab>
        <mat-tab label="Documents" [disabled]="mediaType !== 'file'">
            <ng-template matTabContent>
                <app-gallery-view [mediaType]="mediaType" (selectedMediaEvent)="mediaSelected($event)"></app-gallery-view>
            </ng-template>
        </mat-tab>
    </mat-tab-group>

    <div class="button-container">
        <div>
            <button type="button" mat-button id="edit-btn" class="edit" (click)="editSelectedVideo()" *ngIf="(mediaType === 'video' || mediaType === 'bgVideo') && isMediaSelected">
                Edit
            </button>
        </div>
        <div>
            <button type="button" mat-button id="use-btn" class="upload" (click)="useMedia()" [disabled]="!isMediaSelected">
                Use
            </button>
            <button type="button" mat-button id="cancel-btn" class="cancel" (click)="closeDialog()">
                Cancel
            </button>
        </div>
    </div>
</div>
