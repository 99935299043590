import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ReplaceTempUrlsService {

  constructor(readonly data: DataService, readonly storageService: StorageService) { }

  async replaceTempUrls(){
    for(let [i, scene] of this.data.scenes.entries()){
      console.log("videoUrl", scene.videoPath);
      if(scene.videoPath.includes('blob:')){
        console.log("inside upload section");
        
        //storing temp blob video in minio
        let blob = this.data.blobs.getBlob(scene.videoPath);
        await this.storageService.uploadBgVideo(blob, this.data.projectModel.id, scene.videoName).then((srcUrl:any) =>{
          this.data.scenes[i].videoPath = srcUrl;
          this.data.previewScenes[i].sceneUrl = srcUrl;
        });
      }
      if(scene.videoMeta.captions['is-available']){
      if(scene.videoMeta.captions['src'].includes('blob:')){
          let blob = new Blob([scene.captionStr], {type: 'text/vtt'});
          await this.storageService.uploadCaptionBlob(blob, this.data.projectModel.id, `caption-${i}.vtt`).then((srcUrl:any) =>{
          this.data.scenes[i].videoMeta.captions['src'] = srcUrl;
          this.data.previewScenes[i].captionUrl = srcUrl;
        });
      }
      else{
          let srcUrl = scene.videoMeta.captions['src']
          this.data.scenes[i].videoMeta.captions['src'] = srcUrl;
          this.data.previewScenes[i].captionUrl = srcUrl;
      }
    }

      for(let [j, overlay] of scene.videoMeta.overlays.entries()){
        switch(overlay.type){
          case 'image': {
            let url = overlay.srcUrl ? overlay.srcUrl : overlay.imageData;
            if(url && url !== "" && url.includes('blob:') && this.data.blobs.getBlob(url)){
              let titleId = Math.floor((Math.random()*1000)+ 1);
              let blob = this.data.blobs.getBlob(url);
              await this.storageService.uploadMediaFiles("image",blob,this.data.projectModel.id,titleId).then((srcUrl:any) =>{
                this.data.scenes[i].videoMeta.overlays[j].srcUrl = srcUrl;
              })
            }
            // else if(url && url !== "" && !url.includes(this.storageService.storageWebServerUrl)){
            //   let blob;
            //   let titleId = Math.floor((Math.random()*1000)+ 1);
            //   await fetch(url).then((res:any) => res.blob().then((resBlob:any) =>{
            //     blob = resBlob;
            //   }));
            //   this.data.blobs.createObjectURL(blob);
            //   await this.storageService.uploadMediaFiles("image",blob,this.data.projectModel.id,titleId).then((srcUrl:any) =>{
            //     this.data.scenes[i].videoMeta.overlays[j].srcUrl = srcUrl;
            //   })
            // }
            break;
          }
          case 'audio': {
            let url = overlay.srcUrl ? overlay.srcUrl : overlay.audioData;
            if(url && url !== "" && url.includes('blob:') && this.data.blobs.getBlob(url)){
              let titleId = Math.floor((Math.random()*1000)+ 1);
              let blob = this.data.blobs.getBlob(url);
              await this.storageService.uploadMediaFiles("audio",blob,this.data.projectModel.id,titleId).then((srcUrl:any) =>{
                this.data.scenes[i].videoMeta.overlays[j].srcUrl = srcUrl;
              })
            }
            else if(url && url !== "" && !url.includes(this.storageService.storageWebServerUrl)){
              let blob;
              let titleId = Math.floor((Math.random()*1000)+ 1);
              await fetch(url).then((res:any) => res.blob().then((resBlob:any) =>{
                blob = resBlob;
              }));
              this.data.blobs.createObjectURL(blob);
              await this.storageService.uploadMediaFiles("audio",blob,this.data.projectModel.id,titleId).then((srcUrl:any) =>{
                this.data.scenes[i].videoMeta.overlays[j].srcUrl = srcUrl;
              })
            }
            break;
          }
          case 'video': {
            let url = overlay.srcUrl ? overlay.srcUrl : overlay.videoData;
            if(url && url !== "" && url.includes('blob:') && this.data.blobs.getBlob(url)){
              let titleId = Math.floor((Math.random()*1000)+ 1);
              let blob = this.data.blobs.getBlob(url);
              await this.storageService.uploadMediaFiles("video",blob,this.data.projectModel.id,titleId).then((srcUrl:any) =>{
                this.data.scenes[i].videoMeta.overlays[j].srcUrl = srcUrl;
              })
            }
            else if(url && url !== "" && !url.includes(this.storageService.storageWebServerUrl)){
              let blob;
              let titleId = Math.floor((Math.random()*1000)+ 1);
              await fetch(url).then((res:any) => res.blob().then((resBlob:any) =>{
                blob = resBlob;
              }));
              this.data.blobs.createObjectURL(blob);
              await this.storageService.uploadMediaFiles("video",blob,this.data.projectModel.id,titleId).then((srcUrl:any) =>{
                this.data.scenes[i].videoMeta.overlays[j].srcUrl = srcUrl;
              });
            }
            break;
          }
          case 'livevideo': {
            let url = overlay.srcUrl ? overlay.srcUrl : overlay.videoData;
            if(url && url !== "" && url.includes('blob:') && this.data.blobs.getBlob(url)){
              let titleId = Math.floor((Math.random()*1000)+ 1);
              let blob = this.data.blobs.getBlob(url);
              await this.storageService.uploadMediaFiles("video",blob,this.data.projectModel.id,titleId).then((srcUrl:any) =>{
                this.data.scenes[i].videoMeta.overlays[j].srcUrl = srcUrl;
              })
            }
            else if(url && url !== "" && !url.includes(this.storageService.storageWebServerUrl)){
              let blob;
              let titleId = Math.floor((Math.random()*1000)+ 1);
              await fetch(url).then((res:any) => res.blob().then((resBlob:any) =>{
                blob = resBlob;
              }));
              this.data.blobs.createObjectURL(blob);
              await this.storageService.uploadMediaFiles("video",blob,this.data.projectModel.id,titleId).then((srcUrl:any) =>{
                this.data.scenes[i].videoMeta.overlays[j].srcUrl = srcUrl;
              });
            }
            break;
          }
          case 'liveAudio': {
            let url = overlay.srcUrl ? overlay.srcUrl : overlay.videoData;
            if (url && url !== "" && url.includes('blob:') && this.data.blobs.getBlob(url)) {
              let titleId = Math.floor((Math.random() * 1000) + 1);
              let blob = this.data.blobs.getBlob(url);
              await this.storageService.uploadMediaFiles("audio", blob, this.data.projectModel.id, titleId).then((srcUrl: any) => {
                this.data.scenes[i].videoMeta.overlays[j].srcUrl = srcUrl;
              })
            }
            else if (url && url !== "" && !url.includes(this.storageService.storageWebServerUrl)) {
              let blob;
              let titleId = Math.floor((Math.random() * 1000) + 1);
              await fetch(url).then((res: any) => res.blob().then((resBlob: any) => {
                blob = resBlob;
              }));
              this.data.blobs.createObjectURL(blob);
              await this.storageService.uploadMediaFiles("audio", blob, this.data.projectModel.id, titleId).then((srcUrl: any) => {
                this.data.scenes[i].videoMeta.overlays[j].srcUrl = srcUrl;
              });
            }
            break;
          }
          case 'file': {
            let url = overlay.src;
            if(url && url !== "" && (url.includes('tempProject'))){
              let blob;
              await fetch(url).then((res:any) => res.blob().then((resBlob:any) =>{
                blob = resBlob;
              }));
              this.data.blobs.createObjectURL(blob);
              await this.storageService.uploadDocumentFiles(blob, overlay.fileName, this.data.projectModel.id).then( srcUrl =>{
                this.data.scenes[i].videoMeta.overlays[j].src = srcUrl;
              });
            }
            break;
          }
          default : {
            break;
          }

        }
      }
    }
  }
}
