import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartData } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import overviewchartData from '../../../assets/_files/overviewchart1.json';

interface overviewChart {
  numberOfPSVs: number[];
  chartData: String[];
}
@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {

 constructor() {
}
 
buttonName: string = "Daily";
  
@ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;


viewDetails: overviewChart = overviewchartData.PSVs
ngOnInit(): void {

// this.getJSON().subscribe(data => {
//  console.log(data);
//  this.totalData = data;
// });
}
  viewsChartData: ChartData<'line'> = {
    labels: this.viewDetails.chartData,
    datasets: [
      {
        label: '',
        borderColor: "#1971FB",
        pointBackgroundColor: "#1971FB",
        pointRadius: 5,
        data: this.getData()
      },
    ],
  };
  getData() {
    return this.viewDetails.numberOfPSVs
  }
  updateChart(btnName:string) {
    this.buttonName = btnName;
    switch (this.buttonName) {
      case "Daily":
        this.viewDetails = overviewchartData.PSVs;
        break;
        case "Weekly":
          this.viewDetails = overviewchartData.PSVs;
          break;
      case "Monthly":
        this.viewDetails = overviewchartData.PSVs;
        break;
         
      default:
        break;
    }
    this.viewsChartData.datasets[0].data = [];
    this.viewsChartData.labels = this.viewDetails.chartData
    this.viewsChartData.datasets[0].data = this.viewDetails.numberOfPSVs
    this.chart?.update();
  }
 
}
