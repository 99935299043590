<div class="toolbar-container">
  <div class="toolbar" >
    <button type="button" matTooltip="Text" (click)="showToolbar('text')" [disabled]="!data.isSceneSelected"><em
        class="material-icons">title</em>
    </button>
    <button type="button" matTooltip="Image " [disabled]="!data.isSceneSelected" (click)="openMediaHub('image')"><em
        class="material-icons">image</em>
    </button>
    <button type="button" matTooltip="Call-To-Action " (click)="showToolbar('link')" [disabled]="!data.isSceneSelected"><em
        class="material-icons">touch_app</em>
    </button>
    <button type="button" matTooltip="Webpage" [disabled]="!data.isSceneSelected" (click)="showToolbar('webpage')"><em
        class="material-icons">language</em>
    </button>
    <button type="button" matTooltip="Document" (click)="openMediaHub('file')" [disabled]="!data.isSceneSelected"><em class="material-icons">insert_drive_file</em>
    </button>
    <button type="button" matTooltip="Audio Clip" (click)="openMediaHub('audio')" [disabled]="!data.isSceneSelected"><em
        class="material-icons">audio_file</em>
    </button>
    <button type="button" matTooltip="Video Clip" [disabled]="!data.isSceneSelected" (click)="openMediaHub('video')">
      <em class="material-icons">video_file</em>
    </button>
    <button type="button" matTooltip="Live Audio" (click)="openDialog('liveAudio')" [disabled]="!data.isSceneSelected"><em
        class="material-icons">keyboard_voice</em>
    </button>
    <button type="button" matTooltip="Live Video" (click)="openDialog('livevideo')" [disabled]="!data.isSceneSelected"><em class="material-icons">radio_button_checked</em>
    </button>
    <button type="button" matTooltip="Text to Speech" [disabled]="!data.isSceneSelected" (click)="showToolbar('tts')"
      [disabled]="!data.isSceneSelected"><em class="material-icons">record_voice_over</em>
    </button>
    <button type="button" matTooltip="Caption" (click)="showToolbar('caption')" [disabled]="!data.isSceneSelected"><em
      class="material-icons">closed_caption</em>
  </button>

    <button type="button" matTooltip="Chart" [disabled]="!data.isSceneSelected" (click)="showToolbar('chart')"><em class="material-icons">add_chart</em>
    </button>
    
    <!-- <button type="button" [disabled]="!data.isSceneSelected"><em class="material-icons">pie_chart</em>
        <div class="font-size-sm">Chart</div>
      </button> -->
    <!-- <button type="button" [disabled]="!data.isSceneSelected"><em class="material-icons">description</em>
        <div class="font-size-sm">File</div>
      </button> -->
  </div>
  
  
  <lib-audio-Overlay *ngIf="data.isToolBarOpen && (data.activeTool === 'audio' || data.activeTool==='liveAudio')"
    [audioAnnotation]="data.selectedAnnotation" [isToolBarOpen]="data.isToolBarOpen" [selectedAnnotation]="data.selectedAnnotation"
    [overlayJSON]="data.overlayJSON" [annotationTableData]="data.annotationTableData" [currentTime]="data.currentTime"
    [userData]="data.userData" (reRecordEvent)="openDialog('liveAudio',$event)"
    (overlayAnnotationsEvent)="data.overlayAnnotations($event)" (setDraggerPositionEvent)="data.setDraggerPosition()"
    (updateCurrentTimeEvent)="callUpdateCurrentTime($event)" (annotationTableDataEvent)="data.annotationTableData"
    (selectedAnnotationEvent)="data.selectedAnnotation" (notifyDurationChangeEvent)="data.notifyDurationChange($event)"
    (notifyDurationChangeInToolbarEvent)="data.notifyDurationChangeInToolbar($event)"  (openGalleryEvent)="data.openGallery('audio')"
    (overlayJSONEvent)="data.overlayJSON = $event" (showSnackBarEvent)="data.showSnackBar($event,$event)" (onMinimizing)="data.isToolBarOpen = false">
  </lib-audio-Overlay>
  
  
  <psv2-audio-toolbar [updateAudio]="data.updateAudio" [isLoaderEnabled]="data.isLoaderEnabled"
    [isTtsProviderChanged]="data.isTtsProviderChanged" [ttsOptions]="data.ttsOptions"
    [ttsProviderOptions]="data.ttsProviderOptions" [selectedScene]="data.selectedScene"
    [annotationTableData]="data.annotationTableData" [tts]="data.tts" [overlayJSON]="data.overlayJSON"
    [videoDuration]="data.videoDuration" [isToolBarOpen]="data.isToolBarOpen"
    (updateAudioOutput)="data.updateAudio = $event" (overlayJSONOutput)="data.overlayJSON = $event"
    (annotationTableDataOutput)="data.annotationTableData = $event" 
    (outputProperties)="updateValuesAfterAudioPersonalization($event)"
    (notifyDurationChangeEvent)="callNotifyDurationChange($event)"
    (overlayAnnotationsEvent)="callOverlayAnnotations($event)" (updateCurrentTimeEvent)="callUpdateCurrentTime($event)"
    (onMinimizing)="data.isToolBarOpen = false" *ngIf="data.isToolBarOpen && data.activeTool === 'tts'"
    [newAudioComponent]="data.selectedAnnotation"></psv2-audio-toolbar>
  
  <!-- <div>
    </div> -->
  <div *ngIf="data.isToolBarOpen && data.activeTool !== 'tts' && data.activeTool === 'webpage'">
    <psv-webpage (onMinimizing)="data.isToolBarOpen = false"  *ngIf="data.isToolBarOpen && data.activeTool === 'webpage'" 
    [isToolBarOpen]="data.isToolBarOpen"  [webpageOverlayComponent]="data.selectedAnnotation" (overlayAnnotationsEvent)="callOverlayAnnotations($event)"
      (setAnnotationTableDataEvent)="data.setAnnotationTableData($event)" [fontSize]="data.fontSize" [aspectRatio]="data.aspectRatio"
      (setDraggerPositionEvent)="callSetDraggerPositionEvent($event)" [selectedAnnotation]="data.selectedAnnotation"
      [annotationTableData]="data.annotationTableData" (annotationTableDataEvent)="updateAnnotationTableData($event)"
      (updateCurrentTimeEvent)="data.updateCurrentTime($event)" (setDraggerPositionEvent)="data.setDraggerPosition()"
      (notifyDurationChangeEvent)="data.notifyDurationChange($event)" [overlayJSON]="data.overlayJSON"
      (webpageOverlayOutputEvent)="data.selectedAnnotation = $event"  (overlayJSONEvent)="updateOverlayJson($event)"
      (notifyDurationChangeInToolbarEvent)="data.notifyDurationChangeInToolbar($event)"
      (showSnackBarEvent)="data.showSnackBar($event,$event)" [isToolBarOpen]="data.isToolBarOpen"></psv-webpage>
    </div>
    
    <div *ngIf="data.isToolBarOpen && data.activeTool === 'file'">
      <psv-file-toolbar 
      (onMinimizing)="data.isToolBarOpen = false"  *ngIf="data.isToolBarOpen && data.activeTool === 'file'" 
      [isToolBarOpen]="data.isToolBarOpen"  [fileOverlayComponent]="data.selectedAnnotation" (overlayAnnotationsEvent)="callOverlayAnnotations($event)"
      (setAnnotationTableDataEvent)="data.setAnnotationTableData($event)" [fontSize]="data.fontSize" [aspectRatio]="data.aspectRatio"
      (setDraggerPositionEvent)="callSetDraggerPositionEvent($event)" [selectedAnnotation]="data.selectedAnnotation"
      [annotationTableData]="data.annotationTableData" (annotationTableDataEvent)="data.annotationTableData"
      (updateCurrentTimeEvent)="data.updateCurrentTime($event)" (setDraggerPositionEvent)="data.setDraggerPosition()"
      (notifyDurationChangeEvent)="data.notifyDurationChange($event)" [overlayJSON]="data.overlayJSON"
      (webpageOverlayOutputEvent)="data.selectedAnnotation = $event"  (overlayJSONEvent)="data.overlayJSON = $event"
      (notifyDurationChangeInToolbarEvent)="data.notifyDurationChangeInToolbar($event)"
      (openGalleryEvent)="data.openGallery('document')"
      (showSnackBarEvent)="data.showSnackBar($event,$event)" [isToolBarOpen]="data.isToolBarOpen"></psv-file-toolbar>
  </div>
  
  <div
    *ngIf="data.isToolBarOpen && data.activeTool !== 'tts' && data.activeTool !== 'webpage' && data.activeTool !== 'audio' && data.activeTool !== 'file' && data.activeTool !== 'liveAudio' "
    class="common-toolbar">

    <!-- Annotation specific properties-->
    <psv-image-overlay *ngIf="data.isToolBarOpen && data.activeTool === 'image'" (onMinimizing)="data.isToolBarOpen = false" 
    [imageOverlayComponent]="data.selectedAnnotation" [selectedAnnotation]="data.selectedAnnotation"
      [overlayJSON]="data.overlayJSON" [annotationTableData]="data.annotationTableData" [currentTime]="data.currentTime"
      [userData]="data.userData" [fontSize]="data.fontSize" [aspectRatio]="data.aspectRatio" (overlayAnnotationsEvent)="callOverlayAnnotations($event)"
      (setDraggerPositionEvent)="data.setDraggerPosition()" (updateCurrentTimeEvent)="callUpdateCurrentTime($event)"
      (annotationTableDataEvent)="data.annotationTableData" (selectedAnnotationEvent)="data.selectedAnnotation"
      (overlayJSONEvent)="data.overlayJSON = $event" [isToolBarOpen]="data.isToolBarOpen" (openGalleryEvent)="data.openGallery('image')" >
    </psv-image-overlay>
    <lib-video-overlay (onMinimizing)="data.isToolBarOpen = false" *ngIf="data.isToolBarOpen && (data.activeTool === 'video' || data.activeTool ==='livevideo')"
    [videoOverlayComponent]="data.selectedAnnotation" [selectedAnnotation]="data.selectedAnnotation"
    [overlayJSON]="data.overlayJSON" [annotationTableData]="data.annotationTableData" [aspectRatio]="data.aspectRatio" [currentTime]="data.currentTime"
    [userData]="data.userData" [fontSize]="data.fontSize" (overlayAnnotationsEvent)="callOverlayAnnotations($event)" (setDraggerPositionEvent)="data.setDraggerPosition()"
    (updateCurrentTimeEvent)="callUpdateCurrentTime($event)" (openGalleryEvent)="data.openGallery('video')"
    (annotationTableDataEvent)="data.annotationTableData = $event"
    (selectedAnnotationEvent)="data.selectedAnnotation = $event" (reRecordEvent)="openDialog('livevideo',$event)" (overlayJSONEvent)="data.overlayJSON = $event" [isToolBarOpen]="data.isToolBarOpen">
  </lib-video-overlay>
    <psv-overlay-text *ngIf="data.isToolBarOpen && data.activeTool === 'text'"  [isToolBarOpen]="data.isToolBarOpen"
      [annotationTableData]="data.annotationTableData" [textOverlayComponent]="data.selectedAnnotation"
      (annotationTableDataEvent)="updateAnnotationTableData($event)" [selectedAnnotation]="data.selectedAnnotation"
      (overlayAnnotationsEvent)="callOverlayAnnotations($event)"
      (setDraggerPositionEvent)="callSetDraggerPositionEvent($event)" (onMinimizing)="data.isToolBarOpen = false">
    </psv-overlay-text>
    <psv-call-to-action *ngIf="data.isToolBarOpen && (data.activeTool === 'link' || data.activeTool === 'mail' || data.activeTool === 'tel' || data.activeTool === 'section')" [isToolBarOpen]="data.isToolBarOpen"
      [annotationTableData]="data.annotationTableData" [calltoactioncomponent]="data.selectedAnnotation"
      [overlayJSON]="data.overlayJSON" (overlayJSONOutputEvent)="data.overlayJSON = $event"
      (overlayAnnotationsEvent)="callOverlayAnnotations($event)" (onMinimizing)="data.isToolBarOpen = false">
    </psv-call-to-action>

    <app-caption *ngIf="data.isToolBarOpen && data.activeTool === 'caption' " [isToolBarOpen]="data.isToolBarOpen"
    [caption_annotationTableData]="data.caption_annotationTableData" [captionComponent]="data.selectedAnnotation"
    (caption_annotationTableDataEvent)="updateCaptionAnnotationTableData($event)" [selectedAnnotation]="data.selectedAnnotation"
    (overlayAnnotationsEvent)="callOverlayAnnotations($event)" 
    (setDraggerPositionEvent)="callSetDraggerPositionEvent($event)" (onMinimizing)="data.isToolBarOpen = false"
    (updateCurrentTimeEvent)="data.updateCurrentTime($event)"
    (notifyDurationChangeInToolbarEvent)="data.notifyDurationChangeInToolbar($event)"
    (notifyDurationChangeEvent)="data.notifyDurationChange($event)">
    </app-caption>

    <psv-chart *ngIf="data.isToolBarOpen && data.activeTool === 'chart'" [isToolBarOpen]="data.isToolBarOpen" [annotationTableData]="data.annotationTableData"
     [chartcomponent]="data.selectedAnnotation" [overlayJSON]="data.overlayJSON" (overlayJSONOutputEvent)="data.overlayJSON = $event"
     (overlayAnnotationsEvent)="callOverlayAnnotations($event)" (setDraggerPositionEvent)="callSetDraggerPositionEvent($event)" (setDraggerPositionEvent)="data.setDraggerPosition()" (updateCurrentTimeEvent)="callUpdateCurrentTime($event)"
     (annotationTableDataEvent)="data.annotationTableData" (selectedAnnotationEvent)="data.selectedAnnotation"
     (overlayJSONEvent)="data.overlayJSON = $event"
      (onMinimizing)="data.isToolBarOpen = false">
    </psv-chart> 
  
    <!--Common Properties-->
    <psv-common-properties [videoDuration]="data.videoDuration"
      (overlayAnnotationsEvent)="data.overlayAnnotations($event)"
      (updateCurrentTimeEvent)="data.updateCurrentTime($event)"
      (notifyDurationChangeInToolbarEvent)="data.notifyDurationChangeInToolbar($event)"
      (notifyDurationChangeEvent)="data.notifyDurationChange($event)"
      (notifyAnnotationSwitchEvent)="data.notifyAnnotationSwitch($event)"
      *ngIf="(data.isToolBarOpen && data.activeTool !== 'tts' && data.activeTool !== 'caption')" [newOverlayAnnotation]="data.selectedAnnotation"
      (newOverlayAnnotationOutput)="data.selectedAnnotation = $event">
    </psv-common-properties>

  </div>
</div>
