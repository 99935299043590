<!------------------------------------------------------------------------------
 # COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
 # Reserved. Infosys believes the information in this document is accurate as of
 # its publication date; such information is subject to change without notice.
 # Infosys acknowledges the proprietary rights of other companies to the
 # trademarks, product names and such other intellectual property rights
 # mentioned in this document. Except as expressly permitted, neither this
 # documentation nor any part of it may be reproduced, stored in a retrieval
 # system, or transmitted in any form or by any means, electronic, mechanical,
 # printing, photocopying, recording or otherwise, without the prior permission
 # of Infosys Limited and/or any named intellectual property rights holders
 # under this document.
-------------------------------------------------------------------------------->
<div>
  <div class="file-properties">
    <div
      (click)="minimizeToolBar()"
      class="toolbar-minimize"
      *ngIf="isToolBarOpen"
    >
      <em class="icon-class material-icons">highlight_off</em>
    </div>
    <div class="sub-heading2 header">DOCUMENT PROPS.</div>
    <div class="properties-bar time-box custom-properties-bar">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title class="heading-container"
            >ANNOTATION
          </mat-panel-title> </mat-expansion-panel-header
        ><br />
        <label class="font-size-sm"> START TIME (in s)&nbsp;</label>
        <input
          #startTime
          class="input-time start-field"
          type="number"
          min="0.000"
          step="0.001"
          [ngModel]="fileOverlayComponent.start"
          (blur)="setStartTimeMinVal()"
          (ngModelChange)="
            fileOverlayComponent.start = $event;
            changeStartTime(startTime.value)
          "
        /><br />
        <br /><label class="font-size-sm">LINK</label>
        <input
          class="font-size-md"
          type="text"
          [(ngModel)]="fileOverlayComponent.src"
          placeholder="Enter File Location"
          (change)="loadFile()"
          (input)="changeAnnotationValue()"
        /><br />
        <div class="properties font-size-sm">
          <mat-checkbox
            class="check-box"
            style="padding-right: 6px"
            (ngModelChange)="toggleMaintainAspectRatio()"
            [ngModel]="fileOverlayComponent.maintainAspectRatio"
            >MAINTAIN ASPECT RATIO</mat-checkbox
          >
        </div>
        <div class="properties-bar">
          <label class="font-size-sm aspect-width"
            >WIDTH (in em)&nbsp;
            <input
              #imgWidth
              [ngModel]="
                fileOverlayComponent.cssProperties.webFileWidth | calcFontSize
              "
              (ngModelChange)="updateFileWidth(imgWidth.value)"
              class="input-time font-size-md"
              type="number"
              min="0"
            />
          </label>
          <label class="font-size-sm aspect-width"
            >HEIGHT (in em)&nbsp;
            <input
              #imgHeight
              [ngModel]="
                fileOverlayComponent.cssProperties.webFileHeight | calcFontSize
              "
              (ngModelChange)="updateFileHeight(imgHeight.value)"
              class="input-time font-size-md"
              type="number"
              min="0"
            />
          </label>
        </div>
      </mat-expansion-panel>
    </div>
  </div>
</div>