/*******************************************************************************
 * COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
 * Reserved. Infosys believes the information in this document is accurate as of
 * its publication date; such information is subject to change without notice.
 * Infosys acknowledges the proprietary rights of other companies to the
 * trademarks, product names and such other intellectual property rights
 * mentioned in this document. Except as expressly permitted, neither this
 * documentation nor any part of it may be reproduced, stored in a retrieval
 * system, or transmitted in any form or by any means, electronic, mechanical,
 * printing, photocopying, recording or otherwise, without the prior permission
 * of Infosys Limited and/or any named intellectual property rights holders
 * under this document.
 ******************************************************************************/
import { Pipe, PipeTransform } from '@angular/core';
declare var require:any;

@Pipe({
  name: 'colorConvert'
})
export class colorConvertPipe implements PipeTransform {
    colorConvert=require('color-convert');


    transform(value: string, args?: any): any {
        if ( value[0] ==='#' ) { return value; }
        else {
            if ( value.match(/^rgb[(]/) && value.match(/[)]$/) && value.slice(4,-1).split(",").length==3 ) {
                let inpColor=value.slice(4,-1).split(",");
                try { return '#'+this.colorConvert.rgb.hex(inpColor); }
                catch(e) {
                    return '#000';
                }
            }
            else if ( value.match(/^hsl[(]/) && value.match(/[)]$/) && value.slice(4,-1).split(",").length==3 &&  value.slice(4,-1).split(",")[1].slice(-1)=="%" && value.slice(4,-1).split(",")[2].slice(-1)=="%" ) {
                let inpColor=value.slice(4,-1).split(",");
                try { return '#'+this.colorConvert.hsl.hex(parseInt(inpColor[0]), parseInt(inpColor[1].slice(0,-1)), parseInt(inpColor[2].slice(0,-1))); }
                catch(e) { return '#000'; }
            }
            else {
                try { return '#'+this.colorConvert.keyword.hex(value.toLowerCase()); }
                catch(e) { return '#000'; }
            }
        }
      }

}
