import { Component, OnInit } from '@angular/core';
import { Project_Meta } from '../../shared/models/project.model';
import { DataService } from '../../shared/services/data.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SceneModel } from 'src/app/shared/models/scene.model';
import { StorageService } from 'src/app/shared/services/storage.service';
import {MatDialog} from '@angular/material/dialog';
import { HomeComponent } from '../home.component';
import { InsightsComponent } from 'src/app/insights/insights.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { trigger,state,style,animate,transition} from '@angular/animations';
@Component({
  selector: 'app-recent-project-page',
  templateUrl: './recent-project-page.component.html',
  styleUrls: ['./recent-project-page.component.css'],
  animations: [
    trigger('flyInOut', [
      transition('void => *', [
        animate("0ms 5s")
      ]),
      transition('* => void', [
        animate(1000, style({ transform: 'translateX(100%)' }))
      ])
    ])

  ]
})
export class RecentProjectPageComponent implements OnInit {
  projects: Project_Meta[] = [];
  sceneDatas: any[] = [];
  openConfirmationModal: boolean = false;
  deleteProjectId: number = -1;
  PSVids: any = [];
  SceneIds: any = [];

  constructor(public data: DataService, public http: HttpClient, private router: Router, public storageService: StorageService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.data.fetchAllProjectsByUserId(this.data.userId).then((data: any) => {
      for (let item of data) {
        let project = new Project_Meta(item.title, item.description, item.script, item.createDate, item.lastModifiedDate, item._id, item.concatenatedPSV, item.bgAudioUrl, item.bgAudioVolume);
        project.scenes = item.scenes;
        project.isPublished = item.isPublished ? true : false;
        this.projects.push(project);

      }
      this.projects.reverse();
    }).catch(this.data.handleAPIErrors);
  }

  openDialog() {
    this.dialog.open(AnalyticsComponent );
  }

  closeDialog(){
    this.dialog.closeAll();
  }

  openAnalyticsProject(id:any){
    this.data.analyticsProjectId = this.projects[id].id;
    this.data.analyticsProjectTitle = this.projects[id].title;
    this.data.analyticsProjectCreateDate = this.projects[id].createDate;
    this.router.navigate(['/insights']);
  }

  async loadProject(id: any) {
    this.data.stateChanged = false;
    this.data.scenes = [];
    this.data.titleValue = this.projects[id].title;
    this.data.descriptionValue = this.projects[id].description;
    this.data.fileContent = this.projects[id].script;
    this.data.projectModel = this.projects[id];
    if(this.data.projectModel.bgAudioUrl){
      this.data.isBgAudioAdded = true;
    }
    let scenePromises: any = [];
    let psvPromises: any = [];


    for (let sceneId of this.data.projectModel.scenes) {
      scenePromises.push(this.data.fetchScene(sceneId));
    }
    await Promise.all(scenePromises).then(async (sceneDatas: any) => {
      this.sceneDatas = sceneDatas;
      for (let sceneData of sceneDatas) {
        psvPromises.push(this.data.fetchPSV(sceneData.PSV));
      }
      await Promise.all(psvPromises).then(async (psvDatas: any) => {
        this.data.userDataId = psvDatas[0].userDatalist.user1;
        await this.data.fetchUserDataById(psvDatas[0].userDatalist.user1).then((data: any) => {
          this.data.userData = data.userData;
        }).catch(this.data.handleAPIErrors);
        for (let index = 0; index < psvDatas.length; index++) {
          this.data.scenes.push(new SceneModel(this.sceneDatas[index].playerId, this.sceneDatas[index].script, psvDatas[index].videoSourceObj.path, this.data));
          this.data.scriptArray.push(this.data.scenes[index].script);
          this.data.scenes[index]._id = this.sceneDatas[index]._id;
          this.data.scenes[index].duration = this.sceneDatas[index].duration;
          this.data.scenes[index].videoMeta = this.data.transformOverlayObject(psvDatas[index].psvMetaDataObj);
          this.data.scenes[index].videoSource = psvDatas[index].videoSourceObj;
          this.data.scenes[index].psvId = this.sceneDatas[index].PSV;
          this.data.scenes[index].isDefaultAnnotationsAdded = true;
          this.data.scenes[index].setAnnotationTableData();
        }
        this.data.onCreateVideo = true;
        this.data.onPublishBtn = true;
        this.router.navigate(['/editor']);
      }).catch(this.data.handleAPIErrors);
    }).catch(this.data.handleAPIErrors);
  }

  deleteConfirmation(Id: any) {
    this.openConfirmationModal = true;
    this.deleteProjectId = Id;
  }

  closeModal() {
    this.openConfirmationModal = false;
    this.deleteLoader=false;
  }
  deleteLoader:boolean=false;
  async deleteProject() {
    this.deleteLoader=true;
    //Getting all Ids of a Project

    if (this.projects[this.deleteProjectId].scenes.length !== 0) {
      for (var i = 0; i < this.projects[this.deleteProjectId].scenes.length; i++) {
        await this.data.fetchScene(this.projects[this.deleteProjectId].scenes[i]).then((data: any) => {
          this.PSVids[i] = data.PSV;
          this.SceneIds[i] = this.projects[this.deleteProjectId].scenes[i];
        }).catch(this.data.handleAPIErrors);
      }
    } else { this.data.showSnackBar("Scenes Not Found", "OK"); }

    // Deletion Process starts from here

    if (this.deleteProjectId !== -1 && this.PSVids && this.SceneIds) {
      for (var i = 0; i < this.projects[this.deleteProjectId].scenes.length; i++) {
        this.data.deletePSV(this.PSVids[i]);
        await this.data.deleteScene(this.projects[this.deleteProjectId].scenes[i]).catch(this.data.handleAPIErrors);
      }
      await this.storageService.deleteProject(this.projects[this.deleteProjectId].id).then(async (data: any) => {
        await this.data.deleteProject(this.projects[this.deleteProjectId].id).then(() => {
          this.projects.splice(this.deleteProjectId, 1);
          this.openConfirmationModal = false;
          this.deleteLoader=false;
          this.data.showSnackBar("Project Deleted Succesfully", "Ok");
        }).catch(this.data.handleAPIErrors);
      }).catch(this.data.handleAPIErrors);
    }
    else {
      this.data.showSnackBar("Unable to Delete this Project Please Contact Admin", "OK");
    }
  }
}
