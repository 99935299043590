<div class="embed-code-container">
    <textarea #embedCodeField class="code">{{embedCode}}</textarea>
    <div class="embed-instruction">
      <span>Copy this code segment to embed this PSV to your application</span>
    </div>
</div>
<div class="embed-btn-container">
  <button mat-button [cdkCopyToClipboard]="embedCodeField.value" (click)="closeDialog()">
    <mat-icon style="font-size: 2.1rem;">content_copy</mat-icon>&nbsp;Copy and Close
  </button>
</div>
