import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DataService } from 'src/app/shared/services/data.service';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-annotation-gallery',
  templateUrl: './annotation-gallery.component.html',
  styleUrls: ['./annotation-gallery.component.css']
})
export class AnnotationGalleryComponent implements OnInit {

  constructor(public data: DataService, public storageService: StorageService, public dialog: MatDialogRef<AnnotationGalleryComponent>) { }
  galleryView: any;
  imagepath: any;
  listOfImages: any = [];
  listOfAudio: any = [];
  listOfVideo: any = [];
  listOfDocument:any=[];
  imagePathArray: any = [];
  audioArray: any = [];
  videoArray: any = [];
  docArray: any = [];
  matchedList: any = [];
  selectedMedia: any;
  imgwidth: any=10;
  imgheight: any=5;
  aspectRatio: any;
  selectedMediaIndex: any;

  ngOnInit(): void {
    this.galleryView = "Search" + " " + this.data.selectedAnnotation.type;
    if (this.data.selectedAnnotation.type == 'image') {
      this.storageService.listFiles('imagegallery').then((datas: any) => {
        this.listOfImages = datas;

        for (let i = 0; i < this.listOfImages.length; i++) {
          this.imagePathArray[i] = this.storageService.storageWebServerUrl + 'psv2/imagegallery/' + this.listOfImages[i];
        }
        this.data.matchedMedia = this.imagePathArray;
        this.data.matchedNamesOfMedia = this.listOfImages;
      }
      );
    }
    else if (this.data.selectedAnnotation.type == 'document') {
      this.storageService.listFiles('documentgallery').then((datas: any) => {
        this.listOfDocument = datas;

        for (let i = 0; i < this.listOfDocument.length; i++) {
          this.docArray[i] = this.storageService.storageWebServerUrl + 'psv2/documentgallery/' + this.listOfDocument[i];
        }
        this.data.matchedMedia = this.docArray;
        this.data.matchedNamesOfMedia = this.listOfDocument;
      }
      );
    }
    else if (this.data.selectedAnnotation.type == 'audio') {
      this.storageService.listFiles('audiogallery').then((datas: any) => {
        this.listOfAudio = datas;

        for (let i = 0; i < this.listOfAudio.length; i++) {
          this.audioArray[i] = this.storageService.storageWebServerUrl + 'psv2/audiogallery/' + this.listOfAudio[i];
        }
        this.data.matchedMedia = this.audioArray;
        this.data.matchedNamesOfMedia = this.listOfAudio;
      }
      );
    }
    else if (this.data.selectedAnnotation.type == 'video') {
      this.storageService.listFiles('videogallery').then((datas: any) => {
        this.listOfVideo = datas;

        for (let i = 0; i < this.listOfVideo.length; i++) {
          this.videoArray[i] = this.storageService.storageWebServerUrl + 'psv2/videogallery/' + this.listOfVideo[i];
        }
        this.data.matchedMedia = this.videoArray;
        this.data.matchedNamesOfMedia = this.listOfVideo;
      }
      );
    }
  }


  onSearch(data: any) {
    if (this.data.selectedAnnotation.type == 'image') {
      this.imagePathArray = [];
      this.matchedList = [];
      for (let i = 0; i < this.listOfImages.length; i++) {
        if (this.listOfImages[i].match(data)) {
          this.matchedList.push(this.listOfImages[i]);
        }
      }
      for (let i = 0; i < this.matchedList.length; i++) {
        this.imagePathArray[i] = this.storageService.storageWebServerUrl + 'psv2/imagegallery/' + this.matchedList[i];
      }
      this.data.matchedMedia = this.imagePathArray;
      this.data.matchedNamesOfMedia = this.matchedList;
    }
    else if (this.data.selectedAnnotation.type == 'document') {
      this.docArray = [];
      this.matchedList = [];
      for (let i = 0; i < this.listOfDocument.length; i++) {
        if (this.listOfDocument[i].match(data)) {
          this.matchedList.push(this.listOfDocument[i]);
        }
      }
      for (let i = 0; i < this.matchedList.length; i++) {
        this.docArray[i] = this.storageService.storageWebServerUrl + 'psv2/documentgallery/' + this.matchedList[i];
      }
      this.data.matchedMedia = this.docArray;
      this.data.matchedNamesOfMedia = this.matchedList;
    }

    else if (this.data.selectedAnnotation.type == 'audio') {
      this.audioArray = [];
      this.matchedList = [];
      for (let i = 0; i < this.listOfAudio.length; i++) {
        if (this.listOfAudio[i].match(data)) {
          this.matchedList.push(this.listOfAudio[i]);
        }
      }
      for (let i = 0; i < this.matchedList.length; i++) {
        this.audioArray[i] = this.storageService.storageWebServerUrl + 'psv2/audiogallery/' + this.matchedList[i];
      }
      this.data.matchedMedia = this.audioArray;
      this.data.matchedNamesOfMedia = this.matchedList;
    }

    else if (this.data.selectedAnnotation.type == 'video') {
      this.videoArray = [];
      this.matchedList = [];
      for (let i = 0; i < this.listOfVideo.length; i++) {
        if (this.listOfVideo[i].match(data)) {
          this.matchedList.push(this.listOfVideo[i]);
        }
      }
      for (let i = 0; i < this.matchedList.length; i++) {
        this.videoArray[i] = this.storageService.storageWebServerUrl + 'psv2/videogallery/' + this.matchedList[i];
      }
      this.data.matchedMedia = this.videoArray;
      this.data.matchedNamesOfMedia = this.matchedList;
    }
  }

  radioChecked(src: any, index: any) {
    this.selectedMedia = src;
    this.selectedMediaIndex = index;
  }

  closeDialog(){
    this.dialog.close();
  }

  loadMedia() {
    if (this.data.selectedAnnotation.type == "image") {
      this.data.selectedAnnotation.srcUrl = this.selectedMedia;
      this.data.selectedAnnotation.cssProperties.width = this.imgwidth + "em";
      this.data.selectedAnnotation.cssProperties.height = this.imgheight + "em";
      this.aspectRatio = this.imgwidth / this.imgheight;
      this.data.aspectRatio=this.aspectRatio;
      this.dialog.close();
      this.data.overlayAnnotations();
      this.data.setDraggerPosition();
      this.data.annotationTableData[this.data.getAnnotationTableId(this.data.selectedAnnotation)].value = this.listOfImages[this.selectedMediaIndex];
    }

    else if (this.data.selectedAnnotation.type == "video") {
      this.data.selectedAnnotation.srcUrl = this.selectedMedia;
      this.data.selectedAnnotation.videoData = "";
      this.data.selectedAnnotation.cssProperties.width = this.imgwidth + "em";
      this.data.selectedAnnotation.cssProperties.height = this.imgheight + "em"; 
      this.aspectRatio = this.imgwidth / this.imgheight;
      this.data.aspectRatio=this.aspectRatio;
      this.dialog.close();
      this.data.overlayAnnotations();
      this.data.setDraggerPosition();
      this.data.annotationTableData[this.data.getAnnotationTableId(this.data.selectedAnnotation)].value = this.listOfVideo[this.selectedMediaIndex];

    }
   
    else if (this.data.selectedAnnotation.type == "audio") {
      this.dialog.close();
      this.data.selectedAnnotation.srcUrl = this.selectedMedia;
      this.data.selectedAnnotation.audioData = "";
      this.data.overlayAnnotations();
      this.data.annotationTableData[this.data.getAnnotationTableId(this.data.selectedAnnotation)].value = this.listOfAudio[this.selectedMediaIndex];
    }
  }

}
