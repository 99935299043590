import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { WebpageService } from './webpage.service';
import { DomSanitizer, SafeValue } from '@angular/platform-browser';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { faCaretRight} from '@fortawesome/free-solid-svg-icons';
import { style } from '@angular/animations';
@Component({
  selector: 'psv-webpage',
  templateUrl: './webpage.component.html',
  styleUrls: ['./webpage.component.css'
  ]
})
export class WebpageComponent implements OnInit {

  webpagePath: string | SafeValue = "";
  page: boolean = false;
  faCaretRight:any = faCaretRight;
  @Input() overlayJSON:any;
  @Input() selectedAnnotation: any;
  @Input() annotationTableData: any;
  @Input() fontSize:any;
  @Input() aspectRatio:any;
  @Output() overlayJSONEvent =new EventEmitter<any>();

  constructor(readonly data: WebpageService, public sanitizer: DomSanitizer, public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.annotationTableData = this.annotationTableData;
    this.loadElement(this.webpageOverlayComponent);
    this.webpagePath = this.webpageOverlayComponent.src;
  }

  @Output() annotationTableDataEvent = new EventEmitter<any>();
  @Output() overlayAnnotationsEvent = new EventEmitter<any>();
  @Output() setDraggerPositionEvent = new EventEmitter<any>();
  @Output() updateCurrentTimeEvent = new EventEmitter<any>();
  @Output() notifyDurationChangeEvent = new EventEmitter<any>();
  @Output() showSnackBarEvent = new EventEmitter<any>();
  @Output() notifyDurationChangeInToolbarEvent = new EventEmitter<any>();
  @Output() webpageOverlayOutputEvent = new EventEmitter<any>();
  @Output() setAnnotationTableDataEvent = new EventEmitter<any>();
  @Input() isToolBarOpen!:boolean;
  @Output() onMinimizing = new EventEmitter<boolean>();

  @Input() webpageOverlayComponent: any;

  @Output() onMimimizing = new EventEmitter<boolean>();

  loadWebpage() {
    this.page = true;
    this.webpageOverlayComponent.src = this.webpagePath;
    this.webpageOverlayOutputEvent.emit(this.webpageOverlayComponent);
    this.loadElement();
  }
  errorClassList = ["error-snackbar"];

  loadElement(updatedOverlays?:any) {
            this.overlayAnnotations(updatedOverlays);
  }

  changeAnnotationValue() {
    this.annotationTableData[this.getAnnotationTableId(this.webpageOverlayComponent)].value = this.webpageOverlayComponent.src;
    this.annotationTableDataEvent.emit(this.annotationTableData);
    this.loadElement(this.webpageOverlayComponent);
  }

  getAnnotationTableId(overlayObj: any): string {
    for (let index in this.annotationTableData) {
      if (this.annotationTableData[index].type === "tts" && overlayObj.type === "tts" && overlayObj.id === this.annotationTableData[index].id) {
        return index;
      } else if (this.annotationTableData[index].type !== "tts" && overlayObj.type !== "tts" && overlayObj.id === this.annotationTableData[index].id) {
        return index;
      }
    }
    return '';
  }

  changeStartTime(startTime: any) {
    this.webpageOverlayComponent.isDurationChanged = true;
    startTime = parseFloat(startTime)

    var endTime = this.webpageOverlayComponent.end;
    if (endTime > startTime) {
      if (this.webpageOverlayComponent.start) {
        this.webpageOverlayComponent.duration = endTime - startTime;
        this.updateCurrentTime(this.webpageOverlayComponent.start);
        this.webpageOverlayOutputEvent.emit(this.webpageOverlayComponent);
        this.loadElement();
        this.notifyDurationChange("notified");
        this.notifyDurationChangeInToolbar("changed");
      }
    }
    else {
      let classList = ["error-snackbar"];
      this.showSnackBar("`START TIME` time must be less than the `END TIME` time.", classList);
      return;
    }
  }

  setStartTimeMinVal() {
    let startTime = parseFloat(this.webpageOverlayComponent.start)
    if (startTime === 0) {
      this.webpageOverlayComponent.start = 0.001
      this.webpageOverlayOutputEvent.emit(this.webpageOverlayComponent);
      this.loadElement();
      this.notifyDurationChange("notified");
      this.notifyDurationChangeInToolbar("changed");
    }
  }

  updateWebHeight(height:any){

    this.webpageOverlayComponent.cssProperties.webFileHeight = `${height}%`;
    if(this.webpageOverlayComponent.maintainAspectRatio){
      const widthInPx = (height*this.fontSize)*this.aspectRatio;
      this.webpageOverlayComponent.cssProperties.webFileWidth = `${(widthInPx/this.fontSize)}%`;
        }
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.annotationTableDataEvent.emit(this.annotationTableData);
    this.loadElement(this.overlayJSON);
  }
  updateWebWidth(width:any){
    this.webpageOverlayComponent.cssProperties.webFileWidth = `${width}%`;

    if(this.webpageOverlayComponent.maintainAspectRatio){
      const heightInPx = (width*this.fontSize)/this.aspectRatio;
      this.webpageOverlayComponent.cssProperties.webFileHeight = `${(heightInPx/this.fontSize)}%`;

    }
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.annotationTableDataEvent.emit(this.annotationTableData);
    this.loadElement(this.overlayJSON);
  }
  toggleMaintainAspectRatio(){
    this.webpageOverlayComponent.maintainAspectRatio = !this.webpageOverlayComponent.maintainAspectRatio;
    const height = this.webpageOverlayComponent.cssProperties.webFileHeight.split("%")[0];
    const widthInPx = (height*this.fontSize)*this.aspectRatio;
    this.webpageOverlayComponent.cssProperties.webFileWidth = `${(widthInPx/this.fontSize)}%`;
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.annotationTableDataEvent.emit(this.annotationTableData);
    this.loadElement();
  }

  overlayAnnotations(updateAnnotation?: any) {
    this.overlayAnnotationsEvent.emit(updateAnnotation);
    }

    setDraggerPosition(){
      this.setDraggerPositionEvent.emit();
    }
    updateCurrentTime(currentTime:number){
      this.updateCurrentTimeEvent.emit(currentTime);
    }
    notifyDurationChange(message:any){
      this.notifyDurationChangeEvent.emit(message);
    }
    notifyDurationChangeInToolbar(message:string){
      this.notifyDurationChangeInToolbarEvent.emit(message);
    }
    showSnackBar(message: any, classList: any) {
      const config = new MatSnackBarConfig();
      config.horizontalPosition = "right";
      config.verticalPosition = "bottom";
      config.panelClass = classList;
      config.duration = 3000;
      this.snackBar.open(message, 'Ok', config);
    }
    minimizeToolBar() {
      this.onMinimizing.emit(true);
    }

}

