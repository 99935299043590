<!------------------------------------------------------------------------------
 # COPYRIGHT NOTICE © 2017 Infosys Limited, Bangalore, India. All Rights
 # Reserved. Infosys believes the information in this document is accurate as of
 # its publication date; such information is subject to change without notice.
 # Infosys acknowledges the proprietary rights of other companies to the
 # trademarks, product names and such other intellectual property rights
 # mentioned in this document. Except as expressly permitted, neither this
 # documentation nor any part of it may be reproduced, stored in a retrieval
 # system, or transmitted in any form or by any means, electronic, mechanical,
 # printing, photocopying, recording or otherwise, without the prior permission
 # of Infosys Limited and/or any named intellectual property rights holders
 # under this document.
-------------------------------------------------------------------------------->
<div class="toolbar-properties">
    <!-- <div (click)="minimizeToolBar()" class="toolbar-minimize" *ngIf="isToolBarOpen">
      <fa-icon [icon]="faCaretRight"></fa-icon>
    </div>
    <div class="header">{{newAudioComponent.type | uppercase}}</div>-->

    <!-- <div class="properties-bar time-box custom-properties-bar">
      <label class="font-size-sm">
        START TIME (in s)&nbsp;
        <input #startTime class="input-time start-field" type="number" min="0.000" step="0.001"
          [ngModel]="overlayJSON.audios[newAudioComponent.id - 1].start | number" (blur)="setStartTimeMinVal()"
          (ngModelChange)="overlayJSON.audios[newAudioComponent.id - 1].start= $event"
          (input)="changeStartTime(startTime.value,endTime.value)">
      </label>
      <label class="font-size-sm">
        END TIME (in s)
        <input #endTime class="input-time end-field" style="margin-right: 5px;" type="number" min="0.000" step="0.001"
          [max]="videoDuration" [ngModel]="overlayJSON.audios[newAudioComponent.id - 1].end | number"
          (input)="changeEndTime(startTime.value,endTime.value)"
          (ngModelChange)="overlayJSON.audios[newAudioComponent.id - 1].end=$event">
      </label>
    </div> -->
    <div (click)="minimizeToolBar()" class="toolbar-minimize" *ngIf="isToolBarOpen">
      <!-- <fa-icon [icon]="faCaretRight"></fa-icon> -->
      <em class="icon-class material-icons">highlight_off</em>
    </div>
    <div class="sub-heading2 header">TEXT-TO-SPEECH</div>
    <div>
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
           <mat-panel-title class="heading-container">AUDIO SETTINGS </mat-panel-title>
        </mat-expansion-panel-header>
    <div class="tts-provider-selector">
      <label class="properties font-size-sm" for="annotation-text">
        TTS PROVIDER - <span *ngIf="tts === 'polly'">AWS Polly</span>
        <span *ngIf="tts === 'google'">Google TTS</span>
        <span *ngIf="tts === 'azure'">Azure TTS</span>
      </label>
      <button  class="change-src" matTooltip="Change TTS provider" (click)="openChangeTTSDialog()">
        <fa-layers class="fa-fw">
          <fa-icon [icon]="faVolumeUp"></fa-icon>
          <fa-icon [icon]="faCog" transform="shrink-8 down-8 right-11"></fa-icon>
        </fa-layers>
      </button> 
    </div>
  
    <div class="inner-properties">
      <label class="properties font-size-sm" for="annotation-text">
        AUDIO TEXT
      </label>
      <textarea #input *ngIf="tts === 'polly'" class="font-size-md input-area" id="audio-text" placeholder="Your text here..."
        [(ngModel)]="this.data.overlayJSON.audios[this.newAudioComponent.id - 1].params.Text" (ngModelChange)="changeAudioText(input.value)" (blur)="loadElement()"></textarea>
      <textarea #input *ngIf="tts === 'google'" class="font-size-md input-area" id="audio-text" placeholder="Your text here..."
        [(ngModel)]="selectedScene.script" (ngModelChange)="changeAudioText(input.value)" (blur)="loadElement()"></textarea>
      <textarea #input *ngIf="tts === 'azure'" class="font-size-md input-area" id="audio-text" placeholder="Your text here..."
        [(ngModel)]="selectedScene.script" (ngModelChange)="changeAudioText(input.value)" (blur)="loadElement()"></textarea>
    </div>
  
    <!-- <div class="inner-properties" *ngIf='showSSMLOptions'>
      <label class="properties font-size-sm">SSML OPTIONS</label>
      <mat-form-field>
        <mat-select [(ngModel)]="selectedSsmlTag" (selectionChange)="changeTTSVoice()">
          <mat-option *ngFor="let tag of ssmlTags" [value]="tag.name">
            {{tag.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-select [(ngModel)]="selectedSsmlTag" (selectionChange)="changeTTSVoice()">
          <mat-option *ngFor="let tag of ssmlTags" [value]="tag.name">
            {{tag.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div> -->
    <mat-label class="font-size-small">SSML OPTIONS</mat-label>
    <mat-accordion class="example-headers-align">
      <mat-expansion-panel>
        
        <mat-expansion-panel-header>
          <mat-panel-title class="heading-container">Break</mat-panel-title>
          <mat-panel-description>
            <mat-icon style="background-color:#E3E4FA;" (click)="addBreakSsml()">add</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
   
        <div class="input-dropdown">
          <label class="font-size-small">Strength</label>
          <div  class="center1"  appearance="fill">
            <select class="full-width" (selectionChange)="loadElement()"
             [(ngModel)]="selectedStrength">
              <option *ngFor="let strength of strengthList" [value]="strength" >{{strength}} </option>
            </select>
          </div>
        </div>
   
        <div class="break-time input-time start-field" appearance="fill">
          <label class="font-size-small" >Time</label>
          <input [(ngModel)]="time" type="number" min="0">
        </div>
      </mat-expansion-panel> <br>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="heading-container">Emphasis</mat-panel-title>
          <mat-panel-description>
            <mat-icon style="background-color:#E3E4FA;" (click)="addEmphasisLevel()">add</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
   
        <label class="font-size-small">Level</label>
        <div class="center1" appearance="fill">
          <select class="full-width" (selectionChange)="loadElement()"
            [(ngModel)]="selectedLevel">
            <option *ngFor="let level of levelList" [value]="level" >{{level}} </option>
          </select>
        </div>
      </mat-expansion-panel> <br>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="heading-container">Prosody</mat-panel-title>
          <mat-panel-description>
            <mat-icon style="background-color:#E3E4FA;" (click)="addProsodySsml()">add</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
   
        <label class="font-size-small">Rate</label>
        <select class="full-width" [(ngModel)]="prosodyRate">
          <option *ngFor="let rate of prosodyRateList" [value]="rate" >{{rate}}</option>
        </select>
        <!-- <input style="background-color:#c6c7ce;" class="full-width" list="select" name="select" [(ngModel)]="prosodyRate">
          <datalist  class="form-control" id="select">
            <option value="x-slow">
            <option value="slow">
            <option value="medium">
            <option value="fast">
            <option value="x-fast">
            <option value="%">
          </datalist> -->
   
        <label class="font-size-small">Volume</label>
        <select class="full-width" [(ngModel)]="prosodyVolume">
          <option *ngFor="let volume of prosodyVolumeList" [value]="volume" >{{volume}}</option>
        </select>
        <!-- <input style="background-color:#c6c7ce;" class="full-width" list="select2" name="select" [(ngModel)]="prosodyVolume">
          <datalist class="form-control" id="select2">
            <option value="default">
            <option value="silent">
            <option value="x-soft">
            <option value="soft">
            <option value="medium">
            <option value="loud">
            <option value="x-loud">
            <option value="+ndB">
            <option value="-ndB">
          </datalist> -->
   
        <label class="font-size-small">Pitch</label>
        <select class="full-width" [(ngModel)]="prosodyPitch">
          <option *ngFor="let pitch of prosodyPitchList" [value]="pitch" >{{pitch}}</option>
        </select>
        <!-- <input style="background-color:#c6c7ce;" class="full-width" list="select3" name="select3" [(ngModel)]="prosodyPitch">
          <datalist class="form-control" id="select3">
            <option value="default">
            <option value="x-low">
            <option value="low">
            <option value="medium">
            <option value="high">
            <option value="x-high">
            <option value="+%">
            <option value="-%">
          </datalist> -->
      </mat-expansion-panel> <br>

      <mat-expansion-panel >
        <mat-expansion-panel-header>
          <mat-panel-title class="heading-container">Sub</mat-panel-title>
          <mat-panel-description>
            <mat-icon style="background-color:#E3E4FA;" (click)="addSubAlias()">add</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
   
        <div class="alias input-time start-field center1"  appearance="fill">
          <label class="font-size-small">Alias</label>
          <input [(ngModel)]="subAlias" type="string" min="0">
        </div>
      </mat-expansion-panel> <br>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="heading-container">Say-as</mat-panel-title>
          <mat-panel-description>
            <mat-icon style="background-color:#E3E4FA;" (click)="addSayInterpret()">add</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
   
        <mat-label class="font-size-small">Interpret-as</mat-label>
        <div class="center1" appearance="fill">
          <select class="full-width" (selectionChange)="loadElement()"
            [(ngModel)]="sayInterpret">
              <option *ngFor="let interpret of interpretList" [value]="interpret" >{{interpret}} </option>
          </select>
        </div>
      </mat-expansion-panel> <br>
    </mat-accordion>

  </mat-expansion-panel>
</div>   
</div>