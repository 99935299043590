/*******************************************************************************
 * COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
 * Reserved. Infosys believes the information in this document is accurate as of
 * its publication date; such information is subject to change without notice.
 * Infosys acknowledges the proprietary rights of other companies to the
 * trademarks, product names and such other intellectual property rights
 * mentioned in this document. Except as expressly permitted, neither this
 * documentation nor any part of it may be reproduced, stored in a retrieval
 * system, or transmitted in any form or by any means, electronic, mechanical,
 * printing, photocopying, recording or otherwise, without the prior permission
 * of Infosys Limited and/or any named intellectual property rights holders
 * under this document.
 ******************************************************************************/
import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appPositionTimeStamp]'
})
export class PositionTimeStampDirective implements OnInit {

  constructor(readonly el: ElementRef) { 
    el.nativeElement.style.position = 'absolute';
    el.nativeElement.style.top = '0px';
  }

  @Input() timeStampsCount!: number;
  @Input() timeStampIndex!: number;

  ngOnInit(){
    this.calculateLeftPosition();
    this.el.nativeElement.style.left = `calc(${this.calculateLeftPosition()}% - 10px)`;
  }

  calculateLeftPosition(){
    const basicUnit = 100 / this.timeStampsCount;
    return basicUnit * this.timeStampIndex;
  }

}
