import { Options } from '@angular-slider/ngx-slider';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-editor-modal',
  templateUrl: './editor-modal.component.html',
  styleUrls: ['./editor-modal.component.css']
})
export class EditorModalComponent implements OnInit {

  minValue: any = 0;
  maxValue: any;
  bgVideoElement: any;
  enableSlider: boolean = false;
  timeString: any;
  options: Options = {
    floor: 0,
    step: 1,
    ceil: 10,
    translate: (value: number): string => {
      let minutes: number = Math.floor(value / 60);
      return minutes.toString().padStart(2, '0') + ':' + parseInt((value - minutes * 60).toString()).toString().padStart(2, '0');
    }
  };
  constructor(private dialogRef: MatDialogRef<EditorModalComponent>, public data: DataService) { }

  async ngOnInit() {
  }

  setTimeStamps() {
    this.bgVideoElement = document.getElementById('bgVideoTrim');
    this.bgVideoElement.currentTime=this.minValue;
    setInterval(()=>{        
      if(this.bgVideoElement.currentTime > this.maxValue){
        this.bgVideoElement.pause();
      }      
    },1000);
  }

  videoPropertiesOnLoaded(trimElement:any) {
    this.bgVideoElement=trimElement;
    this.maxValue = trimElement.target.duration;
    this.options.ceil = this.maxValue;
    this.enableSlider = true;
  }

  async trimAndApply() {
    this.data.loadTrimmedBgVideo = true;
    let minutes: number = Math.floor(this.minValue / 60);
    let seconds = parseInt((this.minValue - minutes * 60).toString()).toString().padStart(2, '0');
    this.timeString = '00:' + minutes.toString().padStart(2, '0') + ':' + seconds;
    let trimDuration = (this.maxValue - this.minValue).toFixed(1);
    await this.data.trimBgVideos(this.data.bgSelectedVideoUrl, this.timeString, trimDuration).then((result: any) => {
      this.data.scenes[this.data.bgSceneIndex].videoName = this.data.bgSelectedVideoUrl.split('/videogallery/')[1].split('.')[0] + "_trimmed_" + seconds + "_" + (parseInt(seconds) + trimDuration).padStart(2, '0') + Date.now() + ".mp4";
      this.data.loadTrimmedBgVideo = false;
      this.dialogRef.close(trimDuration);
    }).catch(err =>{
      console.log(err,'err');
      this.data.loadTrimmedBgVideo = false;      
    })
  }

  closeModal() {
    this.data.loadTrimmedBgVideo = false;
    this.dialogRef.close();
  }
}
