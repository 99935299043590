import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'timestamp',
  templateUrl: './timestamp.component.html',
  styleUrls: ['./timestamp.component.css']
})
export class Psv2TimestampComponent implements OnInit {

  @Input()timeStamp!: string;

  constructor() { }

  ngOnInit() {
  }
}
