import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-template-status-modal',
  templateUrl: './template-status-modal.component.html',
  styleUrls: ['./template-status-modal.component.css']
})
export class TemplateStatusModalComponent implements OnInit {

  isErrorMessage: boolean = false;

  constructor(private dialogRef: MatDialogRef<TemplateStatusModalComponent>, private router: Router) { }

  ngOnInit(): void {
  }
  templatePage() {
    this.router.navigate(['/template-page']);
    this.dialogRef.close()

  }
  closeDialog() {
    this.dialogRef.close()
  }

}
