import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from 'src/app/shared/services/data.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gallery-view',
  templateUrl: './gallery-view.component.html',
  styleUrls: ['./gallery-view.component.css'],
})
export class GalleryViewComponent implements OnInit {
  @Input() mediaType: any;
  @Output() selectedMediaEvent: any = new EventEmitter<any>();
  imageNames: string[] = [];
  videoNames: string[] = [];
  audioNames: string[] = [];
  documentNames: string[] = [];
  imageSrc: any[] = [];
  videoSrc: any[] = [];
  audioSrc: any[] = [];
  documentSrc: any[] = [];
  uploadsImageSrc: any[] = [];
  uploadsVideoSrc: any[] = [];
  uploadsAudioSrc: any[] = [];
  uploadsdocumentSrc: any[] = [];
  matchedImageSrc: any[] = [];
  matchedVideoSrc: any[] = [];
  matchedAudioSrc: any[] = [];
  matchedDocumentSrc: any[] = [];
  uploadsMatchedImageSrc: any[] = [];
  uploadsMatchedVideoSrc: any[] = [];
  uploadsMatchedAudioSrc: any[] = [];
  uploadsMatchedDocumentSrc: any[] = [];
  galleryLoader: boolean = false;
  uploadsLoader: boolean = false;
  selectedMediaIndex: number = -1;
  selectedMediaUrl: string = '';
  selectedMediaDuration: number = -1;
  searchPlaceholder: string = 'Search';
  fileNames: any = {
    pdf: '../../../../assets/images/pdf-icon.png',
    pptx: '../../../../assets/images/ppt-icon.jpg',
    txt: '../../../../assets/images/text-icon.png',
    json: '../../../../assets/images/json-icon.png',
    docx:'../../../../assets/images/doc-icon.png',
    html:'../../../../assets/images/html-icon.png'
  };

  constructor(
    readonly storageService: StorageService,
    readonly data: DataService
  ) {}

  ngOnInit(): void {
    // loading gallery medias
    this.searchPlaceholder += ' ' + this.mediaType;
    this.loadGalleryMedia(this.mediaType);
  }

  loadGalleryMedia(mediaType: string, index?: string) {
    this.galleryLoader = true;
    if (mediaType === 'image') {
      this.storageService
        .listFiles('imagegallery')
        .then((imageList: any) => {
          this.galleryLoader = false;
          for (let image of imageList) {
            let URL = image.url;
            this.imageSrc.push({
              name: image.name,
              url: URL,
            });
            this.matchedImageSrc.push({
              name: image.name,
              url: URL,
            });
            // this.storageService.fetchImageBlobURL(URL).subscribe({
            //   next: (response: any) => {
            //   },
            // });
          }
        })
        .catch((err) => {
          this.data.showSnackBar(
            'Something went wrong while fetching ' + mediaType + 's',
            'close'
          );
        });
    } else if (mediaType === 'video' || mediaType === 'bgVideo') {
      this.storageService
        .listFiles('videogallery')
        .then((videoList: any) => {
          this.galleryLoader = false;
          for (let video of videoList) {
            let URL = video.url;
            this.videoSrc.push({
              name: video.name,
              url: URL,
            });
            this.matchedVideoSrc.push({
              name: video.name,
              url: URL,
            });
            // this.storageService.fetchVideoBlobURL(URL).subscribe({
            //   next: (response: any) => {
            //   },
            // });
          }
        })
        .catch((err) => {
          this.data.showSnackBar(
            'Something went wrong while fetching ' + mediaType + 's',
            'close'
          );
        });
    } else if (mediaType === 'audio') {
      this.storageService
        .listFiles('audiogallery')
        .then((audioList: any) => {
          this.galleryLoader = false;
          for (let audio of audioList) {
            let URL = audio.url;
            this.audioSrc.push({
              name: audio.name,
              url: URL,
            });
            this.matchedAudioSrc.push({
              name: audio.name,
              url: URL,
            });
            // this.storageService.fetchVideoBlobURL(URL).subscribe({
            //   next: (response: any) => {
            //   },
            // });
          }
        })
        .catch((err) => {
          this.data.showSnackBar(
            'Something went wrong while fetching ' + mediaType + 's',
            'close'
          );
        });
    } else if (mediaType === 'file') {
      this.storageService
        .listFiles('documentgallery')
        .then((documentList: any) => {

          this.galleryLoader = false;
          for (let document of documentList) {
            let URL = document.url;
            this.documentSrc.push({
              name: document.name,
              url: URL,
            });
            this.matchedDocumentSrc.push({
              name: document.name,
              url: URL,
            });
            // this.storageService.fetchDocumentBlobURL(URL).subscribe({
            //   next: (response: any) => {
            //     console.log(response, 'response', URL);
            //   },
            // });
          }
        })
        .catch((err) => {
          this.data.showSnackBar(
            'Something went wrong while fetching ' + mediaType + 's',
            'close'
          );
        });
    }
  }
  fileImages(doc: any){
    let name = doc.name.split('.')[1];
    return this.fileNames[name];
  }
  clicked(media: any, index: any, mediaElement?: any): void {
    this.selectedMediaUrl = media.url;
    this.selectedMediaDuration = mediaElement ? mediaElement.duration : -1;
    this.selectedMediaEvent.emit({
      name: media.name,
      url: this.selectedMediaUrl,
      duration: this.selectedMediaDuration,
    });
    this.selectedMediaIndex = index;
  }

  searchKeyword(keyword: string, inside: string) {
    keyword = keyword.toLowerCase();
    let matchedMedias: any[] = [];

    if (inside === 'gallery') {
      if (this.mediaType === 'image') {
        for (let image of this.imageSrc) {
          if (image.name.toLowerCase().match(keyword)) {
            matchedMedias.push(image);
          }
        }
        this.matchedImageSrc = matchedMedias;
      } else if (this.mediaType === 'video' || this.mediaType === 'bgVideo') {
        for (let video of this.videoSrc) {
          if (video.name.toLowerCase().match(keyword)) {
            matchedMedias.push(video);
          }
        }
        this.matchedVideoSrc = matchedMedias;
      } else if (this.mediaType === 'audio') {
        for (let audio of this.audioSrc) {
          if (audio.name.toLowerCase().match(keyword)) {
            matchedMedias.push(audio);
          }
        }
        this.matchedAudioSrc = matchedMedias;
      }
    } else if (inside === 'uploads') {
      if (this.mediaType === 'image') {
        for (let image of this.uploadsImageSrc) {
          if (image.name.toLowerCase().match(keyword)) {
            matchedMedias.push(image);
          }
        }
        this.uploadsMatchedImageSrc = matchedMedias;
      } else if (this.mediaType === 'video' || this.mediaType === 'bgVideo') {
        for (let video of this.uploadsVideoSrc) {
          if (video.name.toLowerCase().match(keyword)) {
            matchedMedias.push(video);
          }
        }
        this.uploadsMatchedVideoSrc = matchedMedias;
      } else if (this.mediaType === 'audio') {
        for (let audio of this.uploadsAudioSrc) {
          if (audio.name.toLowerCase().match(keyword)) {
            matchedMedias.push(audio);
          }
        }
        this.uploadsMatchedAudioSrc = matchedMedias;
      }
    }
  }
}
