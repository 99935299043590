import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DomSanitizer, SafeValue } from '@angular/platform-browser';
import { AudioOverlayService } from './audio-overlay.service';
import {faCaretRight} from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'lib-audio-Overlay',
  templateUrl:'./audio-overlay.html' ,
  styleUrls: ['./audio-overlay.css']
})
export class AudioOverlayComponent implements OnInit {
  audioSelected: any;
  faCaretRight:any = faCaretRight;
  pathUrl:any;



  constructor(public sanitizer: DomSanitizer, readonly snackBar : MatSnackBar) { }
@Input() audioAnnotation:any;
@Input() selectedAnnotation: any;
@Input() annotationTableData: any = [];
@Input() currentTime: any;
@Input() overlayJSON: any;
@Input() userData:any = {};
@Input() fontSize:any;
@Input() videoDuration:any;
@Input() isToolBarOpen!:boolean;
@Output() onMinimizing = new EventEmitter<boolean>();
@Output() openGalleryEvent =new EventEmitter<any>();
@Output() notifyDurationChangeInToolbarEvent = new EventEmitter<any>();
@Output() notifyDurationChangeEvent = new EventEmitter<any>();
@Output() change = new EventEmitter<MatSlideToggleChange>();
@Output() overlayAnnotationsEvent = new EventEmitter<any>();
@Output() annotationTableDataEvent = new EventEmitter<any>();
@Output() selectedAnnotationEvent = new EventEmitter<any>();
@Output() overlayJSONEvent = new EventEmitter<any>();
@Output() showSnackBarEvent = new EventEmitter<any>();
@Output() updateCurrentTimeEvent = new EventEmitter<number>();
@Output() audioOverlayComponentOutput = new EventEmitter<any>();
@Output() renameEvent = new EventEmitter<any>();
@Output() reRecordEvent = new EventEmitter<any>();

TitleRename: any;

demo:boolean=false;

  ngOnInit(): void {
  }
  updateCurrentTime(currentTime: number) {
    this.updateCurrentTimeEvent.emit(currentTime);
  }

  setStartTimeMinVal(){
    let startTime = parseFloat(this.audioAnnotation.start)
    if(startTime <= 0){
        this.audioAnnotation.start = 0.001;
        this.audioAnnotation.duration = this.audioAnnotation.end - startTime;
        this.audioOverlayComponentOutput.emit(this.audioAnnotation);
        this.loadElement( this.audioAnnotation);
        this.notifyDurationChange("notified");
        this.notifyDurationChangeInToolbar('changed');
    }
 
  }
  notifyDurationChange(message: string) {
    this.notifyDurationChangeEvent.emit(message);
  }
  notifyDurationChangeInToolbar(message:string){
    this.notifyDurationChangeInToolbarEvent.emit(message);
  }

  openGalleryForAudio(){
    this.openGalleryEvent.emit();
    this.pathUrl = "";
  }

  changeStartTime(startTime: any,endTime: any) {
    this.audioAnnotation.isDurationChanged = true;
     startTime = parseFloat(startTime)
     if(startTime <= 0){
       startTime = 0.001;
     }
     endTime = parseFloat(endTime)
     if(endTime>startTime){
       if (this.audioAnnotation.start) {
         if(this.audioAnnotation.start <= 0){
          this.audioAnnotation.start = 0.001;
         }
        this.audioAnnotation.duration = endTime - startTime;
         this.updateCurrentTime(this.audioAnnotation.start);
         this.audioOverlayComponentOutput.emit(this.audioAnnotation);
         this.loadElement(this.audioAnnotation);
         this.notifyDurationChange("notified");
         this.notifyDurationChangeInToolbar('changed');
       }
     }
     else{
       let classList = ["error-snackbar"];
       this.showSnackBar("`START TIME` time must be less than the `END TIME` time.", classList);
       return;
     }
     
   }
   showSnackBar(message: any, classList: any) {
    const config = new MatSnackBarConfig();
    config.horizontalPosition = "right";
    config.verticalPosition = "bottom";
    config.panelClass = classList;
    config.duration = 3000;
    this.snackBar.open(message, 'Ok', config);
  }
 
   changeEndTime(startTime: any ,endTime: any) {
    this.audioAnnotation.isDurationChanged = true;
     startTime = parseFloat(startTime)
     endTime = parseFloat(endTime)
     if (endTime>this.videoDuration){
       let classList = ["error-snackbar"];
       this.showSnackBar("`END TIME` time must be less than the `Video Duration` time.", classList);
       return;
     }
 
     if (endTime > startTime) {
       this.audioAnnotation.duration = endTime - startTime;
      this.loadElement(this.audioAnnotation);
      this.notifyDurationChange("notified");
      this.notifyDurationChangeInToolbar('changed');
     }
     else{
       let classList = ["error-snackbar"];
       this.showSnackBar("`END TIME` time must be greater than the `START TIME` time.", classList);
       return;
     }
     
   }
 

  onFileUpload(e: any) {
    let audioData: any;

    //newCode for Audio Annotation

    let blob: Blob;
    const fileReader = new FileReader();
    const arrayBuffer = e.target.files[0];
    const fileType = "audio/mp3";
    blob = new Blob([arrayBuffer], { type: fileType });
    this.annotationTableData[this.getAnnotationTableId(this.audioAnnotation)].value = e.srcElement.files[0].name;
    fileReader.onloadend = (e) => {
      const src : any | SafeValue = URL.createObjectURL(arrayBuffer);
      this.audioAnnotation.audioData = src;
      audioData = src
      this.audioAnnotation.srcUrl = "";
      // this.audioAnnotation.audioData = this.sanitizer.bypassSecurityTrustUrl(src);      
      this.demo = true;
      this.loadElement();
    };
    fileReader.readAsArrayBuffer(blob);

    //newCode for Audio Annotation

    const inputDataFlag = setInterval(() => {
      if (audioData && audioData !== "") {
        this.audioAnnotation.srcUrl = "";
        this.annotationTableData[this.getAnnotationTableId(this.audioAnnotation)].value = e.srcElement.files[0].name;
        this.audioAnnotation.altText = e.srcElement.files[0].name;
        this.audioAnnotation.audioName = e.srcElement.files[0].name;
        //this.aspectRatio = parseInt(this.audioAnnotation.cssProperties.width.substring(-2) )/ parseInt(this.audioAnnotation.cssProperties.height.substring(-2));
        this.overlayJSONEvent.emit(this.overlayJSON);
        this.annotationTableDataEvent.emit(this.annotationTableData);
        this.loadElement();
        clearInterval(inputDataFlag);
      }
    }, 10);
  }

  loadVideoPathtest() {
    this.demo = true;
    this.audioAnnotation.audioData = this.audioAnnotation.srcUrl;
    const inputDataFlag = setInterval(() => {
      if (this.audioAnnotation.audioData !== "") {
        this.annotationTableData[this.getAnnotationTableId(this.audioAnnotation)].value = this.audioSelected;
        this.audioAnnotation.altText = "";
        this.audioAnnotation.audioName = this.audioSelected;
        //this.aspectRatio = parseInt(this.audioAnnotation.cssProperties.width.substring(-2) )/ parseInt(this.audioAnnotation.cssProperties.height.substring(-2));
        this.overlayJSONEvent.emit(this.overlayJSON);
        this.annotationTableDataEvent.emit(this.annotationTableData);
        this.loadElement();
        clearInterval(inputDataFlag);
      }
    }, 10);
  }

  changeTitleName() {
     this.annotationTableData[this.getAnnotationTableId(this.audioAnnotation)].value = this.TitleRename;
  }

  getAnnotationTableId(overlayObj: any) {
    for (let index in this.annotationTableData) {
      if (this.annotationTableData[index].type === "tts" && overlayObj.type === "tts" && overlayObj.id === this.annotationTableData[index].id) {
        return index;
      } else if (this.annotationTableData[index].type !== "tts" && overlayObj.type !== "tts" && overlayObj.id === this.annotationTableData[index].id) {
        return index;
      }
    }
    return "";
  }
  loadElement(updatedOverlayAnnotation?: any) {
    this.overlayAnnotations();
  }
  overlayAnnotations(updateAnnotation?: any) {
    this.selectedAnnotationEvent.emit(this.audioAnnotation);
    this.annotationTableDataEvent.emit(this.annotationTableData);
    this.overlayAnnotationsEvent.emit(updateAnnotation);
  }
  reRecord(){
    this.reRecordEvent.emit(this.audioAnnotation.id);
  }
  minimizeToolBar() {
    this.onMinimizing.emit(true);
  }

}
