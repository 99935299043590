<div class="live-audio-tool">
  <h4>Live Audio Recorder</h4>
  <i class="fa fa-microphone fa-3x mic" aria-hidden="true"></i>
  <audio class="audioPlayer" type="audio/mp3" controlsList="nodownload" [src]="this.url | sanitizeVideo" controls="" *ngIf="enabler">
  </audio>
  <div class="button-container">
    <button class="button button1" (click)="initiateRecording()" *ngIf="!recording"> Record </button>
    <button class="button button2" (click)="stopRecording()" *ngIf="recording"> Stop </button>
    <button *ngIf="url" class="button buttoncontinue" (click)="continue()">Add</button>
  </div>
</div>