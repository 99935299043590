<div class="heading-container">
    <strong class=" main-headings"></strong>Templates
</div>
<div class="sec-text" *ngIf="allTemplates.length == 0">No projects yet</div>
<div class="projects-container">
    <div *ngFor="let template of allTemplates; index as i" style="max-width: 20%;">
        <div class="project-sub-container tile">
            <mat-icon class="del-template-btn" (click)="deleteConfirmation(template._id,i)">delete_outline</mat-icon>
            <img class="recent-project-thumbnail" alt="Project Thumbnail" src="../../../assets/images/thumbnail.png">
            <div style="padding-left: 6%; padding-top: 1%; margin-top: 8%;">
                <p class="project-heading sub-heading2">{{template.title}}</p>
                <p class="date-container sec-headings-icons">{{template.createdDate | date:'short'}}</p>
                <p class=" status" [ngClass]="getStatus(template.concatStatus)">{{getStatus(template.concatStatus)}}
                    <!-- <span *ngIf="template.concatStatus == 'Success'"
                        class="material-icons material-icon-refresh-disabled"> refresh </span> -->
                        <span *ngIf="template.concatStatus !== 'Success' && template.concatStatus !== 'Failed'" class="material-icons material-icon-refresh rotate"
                        (click)="refreshTemplateById(template._id,i)"> refresh </span></p>
                        
                        <div style="margin-top: 4%;">
                            <div *ngIf="template.concatStatus !== 'Success'" button class="progress-template-btn">View Template</div>
                            <div *ngIf="template.concatStatus == 'Success'" button class="use-template-btn-analytics"
                            (click)="createPsv(template._id)">View Template</div>
                            <div mat-button class="use-template-btn-analytics analytics-btn" (click)="openAnalyticsPage(template._id,template.title)">View Analytics</div>
                            <div *ngIf="template.concatStatus == 'Failed'">
                                <button class="retry" *ngIf="this.retryLoader" (click)="retry(template._id,i)" > Retry </button>
                                <div *ngIf="!this.retryLoader" class="retryLoader fa-xs">
                                    <i class="fas fa-circle-notch fa-spin"></i>
                                </div>
                            </div>
                </div>

            </div>
        </div>
    </div>
    <div class="backdrop" *ngIf="openConfirmationModal">
        <div class="confirmationmodal">
            Are you sure want to delete this Template?
            <p style="font-size: 1.4rem; color: red; font-weight: bolder;">CAUTION: Generated Links will not work once deleted.</p>
            <button mat-button type="button" class="yesBtn"
                (click)="deleteSelectedTemplate()">Yes</button>
            <button mat-button type="button" class="noBtn" (click)="closeModal()">No</button>
            <div *ngIf="this.deleteLoader" class="loadScreen">
                <div id="overlay">
                    <em class="fa fa-spinner fa-spin spin-big"></em>
                </div>
            </div>
        </div>
    </div>
</div>