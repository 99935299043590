import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EditorComponent } from './editor/editor.component';
import { HomeComponent } from './home/home.component';
import { PsvVideoPlayerComponent } from './publish/psv-video-player/psv-video-player.component';
import { PublishComponent } from './publish/publish.component';
import { RecentProjectPageComponent } from './home/recent-project-page/recent-project-page.component';
import { HelpPageComponent } from './help-page/help-page.component';
import { InsightsComponent } from './insights/insights.component';
import { ThumbnailComponent } from './editor/thumbnail/thumbnail.component';
import { TemplateComponent } from './editor/template/template.component';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  {
    path:'',
    component: HomeComponent,
    canActivate: [MsalGuard]
  },
  {
    path:'editor',
    component: EditorComponent
  },
  {
    path:'publish',
    component: PublishComponent
  },
  {
    path:'psv/:urlcode',
    component:PsvVideoPlayerComponent
  },
  {
    path: 'recent-projects',
    component:RecentProjectPageComponent
  },
  {
    path:'insights',
    component:InsightsComponent
  },
  {
    path:'helppage',
    component:HelpPageComponent
  },
  {path:'template-page',component:TemplateComponent},
  {
    path:'**',
    component: HomeComponent,
    canActivate: [MsalGuard]
  }

];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: !isIframe ? 'enabled' : 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {


}
