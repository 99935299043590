<!------------------------------------------------------------------------------
 # COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
 # Reserved. Infosys believes the information in this document is accurate as of
 # its publication date; such information is subject to change without notice.
 # Infosys acknowledges the proprietary rights of other companies to the
 # trademarks, product names and such other intellectual property rights
 # mentioned in this document. Except as expressly permitted, neither this
 # documentation nor any part of it may be reproduced, stored in a retrieval
 # system, or transmitted in any form or by any means, electronic, mechanical,
 # printing, photocopying, recording or otherwise, without the prior permission
 # of Infosys Limited and/or any named intellectual property rights holders
 # under this document.
-------------------------------------------------------------------------------->
<div class="inner-properties">
  <div (click)="minimizeToolBar()" class="toolbar-minimize" *ngIf="isToolBarOpen">
    <!-- <fa-icon [icon]="faCaretRight"></fa-icon> -->
    <em class="icon-class material-icons">highlight_off</em>
  </div>
  <!-- <div class="sub-heading2 header">WEBPAGE ANNOTATION</div> -->
  <div>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title class="heading-container">WEBPAGE PROPS.</mat-panel-title>
      </mat-expansion-panel-header>

      <label class="font-size-sm">START TIME (in s)</label><br>
      <input #startTime class="input-time start-field" type="number" min="0.000" step="0.001"
        [ngModel]="webpageOverlayComponent.start " (blur)="setStartTimeMinVal()"
        (ngModelChange)="webpageOverlayComponent.start = $event; changeStartTime(startTime.value)"><br>

      <label class="properties font-size-sm">LINK</label><br>
      <input class="font-size-md" type="text" [(ngModel)]="webpagePath" placeholder="https://www.google.com"
        (change)="loadWebpage()" (input)="changeAnnotationValue()">
    </mat-expansion-panel>
  </div>
  <div class="properties font-size-sm">
    <mat-checkbox class="check-box " style="padding-right:6px" (ngModelChange)="toggleMaintainAspectRatio()"
      [ngModel]="webpageOverlayComponent.maintainAspectRatio">MAINTAIN ASPECT RATIO</mat-checkbox>
  </div>
  <div class="properties-bar">
    <label class="font-size-sm aspect-width">WIDTH (in %)&nbsp;
      <input #imgWidth [ngModel]="webpageOverlayComponent.cssProperties.webFileWidth | calcFontSize"
        (ngModelChange)="updateWebWidth(imgWidth.value)" class="input-time font-size-md" type="number" min="0">
    </label>
    <label class="font-size-sm aspect-width">HEIGHT (in %)&nbsp;
      <input #imgHeight [ngModel]="webpageOverlayComponent.cssProperties.webFileHeight | calcFontSize"
        (ngModelChange)="updateWebHeight(imgHeight.value)" class="input-time font-size-md" type="number" min="0">
    </label>
  </div>
</div>
