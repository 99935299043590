import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { BrowserModule } from '@angular/platform-browser';
import { LiveVideoOverlayComponent } from './live-video-overlay.component';
import { SanitizeVideoPipe } from './pipes/sanitize-video.pipe';



@NgModule({
  declarations: [
    LiveVideoOverlayComponent,
    SanitizeVideoPipe
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    MatButtonModule
  ],
  exports: [
    LiveVideoOverlayComponent
  ]
})
export class LiveVideoOverlayModule { }
