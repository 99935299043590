<div class="media-container">
   <em class="icon-class material-icons" (click)="close()">highlight_off</em>
   <div *ngIf="this.data.mediaType == 'liveAudio'">
      <lib-live-Recording (saveEvent)="saveAudio($event)" (closeEvent)="close()" (renameEvent)="renameTitle($event)">
      </lib-live-Recording>
      <div *ngIf="this.audioSaveLoader" class="loadScreen">
         <em class="fa fa-spinner fa-spin spin-big"></em>
      </div>
   </div>
   <div *ngIf="this.data.mediaType == 'livevideo'">
      <lib-liveVideo-overlay (downloadEvent)="saveVideo($event)" (closeEvent)="close()"></lib-liveVideo-overlay>
      <div *ngIf="this.videoSaveLoader" class="loadScreen">
        <div id="overlay">
            <em class="fa fa-spinner fa-spin spin-big"></em>
        </div>
      </div>
   </div>
</div>
