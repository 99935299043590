import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-embed-psv',
  templateUrl: './embed-psv.component.html',
  styleUrls: ['./embed-psv.component.css']
})
export class EmbedPsvComponent implements OnInit {

  embedCode:string = "";
  embedSrc:string = "";

  constructor(public dialogRef: MatDialogRef<EmbedPsvComponent>) { }

  ngOnInit(): void {
    this.embedCode = '<iframe width="560" height="315" src="' + this.embedSrc + '" title="PSV video player" frameborder="0" allow="autoplay; picture-in-picture" allowfullscreen></iframe>';
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
