import { CssProperties } from './../models/css-properties.model';
export class ChartAnnotation {

    type!: string;
    data: any;
    chartLibrary!: string;
    chartName!: string;
    id: number;
    className!: string;
    start: number;
    end: number;
    cssProperties: CssProperties;
    chartData: any;
    chartKey!: string;
    xAxisLabelDistance!: number;
    yAxisLabelDistance!: number;
    maintainAspectRatio!: boolean;
    bgToggle!:boolean;
    exitAnimation!: string;
    isDurationChanged:boolean = false;
    duration:any;

    constructor(id: number, currentTime:number, videoDuration: number, cssProps: CssProperties) {
        this.id = id;
        this.end = videoDuration;
        this.start = currentTime;
        this.duration = this.end - this.start;
        this.cssProperties = cssProps;
        this.initializeAttributes();
    }

    initializeAttributes() {
        this.type = 'chart';
        this.chartLibrary = 'nvd3';
        this.bgToggle=false;
        this.chartName = 'BAR - VARIANT 1';
        this.data = {
            transformedData : undefined,
            options: {
                showValues: true,
                showLegend: false,
                showXAxis: true,
                showYAxis: true,
                staggerLabels: true,
                wrapLabels: true,
                height: this.cssProperties.height,
                width: this.cssProperties.width,
                color: ['#1f78b4'],
                showXAxisLabel: true,
                showYAxisLabel: true,
                animationDuration: 0,
                rotateLabels: 0,
                fontSize: 0.3,
                xAxisLabelDistance: -15,
                yAxisLabelDistance : -17,
                labelColor: "#000000",
                preFormatValue: '',
                postFormatValue: ''
            },
            colorType: 'monochrome',
            selectedColorTheme: 'BuGn',
            chartType: 'bar',
            chartCategory: 'bar'
        }
        this.className = '';
        this.exitAnimation = "";
        this.chartKey = "psv-chart" + this.id;
        this.chartData = {
            xAxisLabel: undefined,
            yAxisLabel: undefined,
            data: []
        };
        this.maintainAspectRatio = true;
    }

    getAttributes() {
        return (
            {
                type: this.type,
                data: this.data,
                chartLibrary: this.chartLibrary,
                chartName: this.chartName,
                chartData: this.chartData,
                id: this.id,
                "class-name": this.className,
                start: this.start,
                end: this.end,
                chartKey: this.chartKey,
                exitAnimation: this.exitAnimation,
                maintainAspectRatio: this.maintainAspectRatio,
               "css-properties": this.cssProperties.getChartAttributes()
            }
        );
    }

    transformAttributes(overlayObject:any, cssProps: CssProperties){
        const overlayAnnotationObject = new ChartAnnotation(overlayObject.id, overlayObject.start, overlayObject.end, cssProps);
        overlayAnnotationObject.data.options =  Object.assign({}, overlayObject.data.options);
        overlayAnnotationObject.data.chartType = overlayObject.data.chartType;
        overlayAnnotationObject.data.chartCategory = overlayObject.data.chartCategory;
        overlayAnnotationObject.data.colorType = overlayObject.data.colorType;
        overlayAnnotationObject.data.selectedColorTheme = overlayObject.data.selectedColorTheme;
        overlayAnnotationObject.chartLibrary = overlayObject.chartLibrary;
        overlayAnnotationObject.chartName = overlayObject.chartName;
        overlayAnnotationObject.start = overlayObject.start;
        if(overlayObject.data.options.axisLabelDistance!=0 && overlayObject.data.options.axisLabelDistance!=undefined){
            overlayAnnotationObject.data.options.xAxisLabelDistance=overlayObject.data.options.axisLabelDistance;
            overlayAnnotationObject.data.options.yAxisLabelDistance=overlayObject.data.options.axisLabelDistance;
            overlayObject.data.options.axisLabelDistance=undefined;
        }
        else{
            overlayAnnotationObject.data.options.xAxisLabelDistance=overlayObject.data.options.xAxisLabelDistance;
            overlayAnnotationObject.data.options.yAxisLabelDistance=overlayObject.data.options.yAxisLabelDistance;
        }
        overlayAnnotationObject.className = overlayObject["class-name"];
        overlayAnnotationObject.chartData.xAxisLabel = overlayObject.chartData.xAxisLabel;
        overlayAnnotationObject.chartData.yAxisLabel = overlayObject.chartData.yAxisLabel;
        overlayAnnotationObject.bgToggle = overlayAnnotationObject.cssProperties.backgroundColor!=='transparent'?true:false;
        overlayAnnotationObject.exitAnimation = overlayObject.exitAnimation || "";
        
        for(const data of overlayObject.chartData.data) {
            overlayAnnotationObject.chartData.data.push(Object.assign({}, data))
        }
        if(overlayObject.chartLibrary === "chartjs") {
            
            overlayAnnotationObject.data.transformedData = {
                "datasets": [{
                    "data": [],
                    "backgroundColor": overlayObject.data.options.backgroundColor,
                    "label": ""
                }],
                "labels": []
            };
            switch (overlayAnnotationObject.data.chartType) {
                case 'line': {
                    overlayAnnotationObject.data.transformedData.datasets[0].fill = false;
                    break;
                }
                case 'area': {
                    overlayAnnotationObject.data.transformedData.datasets[0].fill = true;
                    break;
                }
            }
        }
        overlayAnnotationObject.chartKey = "psv-chart" + overlayObject.id;
        overlayAnnotationObject.maintainAspectRatio = overlayObject.maintainAspectRatio;
        return overlayAnnotationObject;
    }

}
