<body onclick="skipLogin()">

    <div class="container">

        <div class="row">
            <div class="col-md-6" style="background-image:url('')"></div>
            <div class="col-md-6">
                <div class="login-box">
                    <h3 class="text-primary">Welcome to PSV</h3>
                    <h3 class="text-primary">Welcome to PSV</h3>
                    <small>Inorder to start creating you need to sign in</small>
                    <div class="form-group">
                        <input type="email" class="form-control" placeholder="Your Email Address" id="userName">
                    </div>

                    <div class="form-group">
                        <input type="password" class="form-control" placeholder="Password" id="loginPassword">
                    </div>

                    <button type="button" class="btn btn-primary" onclick="logIn()">Continue</button> &nbsp;
                    </div>
                </div>
            </div>
      

    </div>
</body>