/*******************************************************************************
 * COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
 * Reserved. Infosys believes the information in this document is accurate as of
 * its publication date; such information is subject to change without notice.
 * Infosys acknowledges the proprietary rights of other companies to the
 * trademarks, product names and such other intellectual property rights
 * mentioned in this document. Except as expressly permitted, neither this
 * documentation nor any part of it may be reproduced, stored in a retrieval
 * system, or transmitted in any form or by any means, electronic, mechanical,
 * printing, photocopying, recording or otherwise, without the prior permission
 * of Infosys Limited and/or any named intellectual property rights holders
 * under this document.
 ******************************************************************************/
import { CssProperties } from './../models/css-properties.model';
export class ProgressbarAnnotation {

    type!: string;
    value!: string;
    id: number;
    className!: string;
    start: number;
    end: number;
    exitAnimation!: string;
    labelProperties:any;
    active!: boolean;
    striped!: boolean;
    padded!:boolean;
    duebgColor!:string;
    label!: string;
    cssProperties: CssProperties;
    title!:string;
    isDurationChanged:boolean = false;
    duration:any;


    constructor(id:number, currentTime:number, videoDuration: number, cssProps: CssProperties){
        this.id = id;
        this.end = videoDuration;
        this.cssProperties = cssProps;
        this.start = currentTime;
        this.duration = this.end - this.start;
        this.initializeAttributes();
    }

    initializeAttributes(){
        this.type = 'progressbar';
        this.value = 'Enter Progress';
        this.className = '';
        this.exitAnimation = "";
        this.active = false;
        this.striped = false;
        this.padded = false;
        this.label = "Progress Bar Title";
        this.duebgColor="transparent";
        this.title="";
        this.labelProperties={
            "display":'block',
            "font-size":"0.5em",
            "color":"black",
            "margin-bottom":"0.8em"
        }
    }

    getAttributes(){
        return(
            {
                type: this.type,
                value: this.value,
                id: this.id,
                "class-name": this.className,
                start: this.start,
                end: this.end,
                exitAnimation: this.exitAnimation,
                active: this.active,
                striped: this.striped,
                padded: this.padded,
                duebgColor: this.duebgColor,
                label: this.label,
                labelProperties : this.labelProperties,
                title: this.title,
                "css-properties": this.cssProperties.getProgressbarAttributes()
            }
        );
    }

    transformAttributes(overlayObject:any, cssProps: CssProperties){
        const progressbarObject = new ProgressbarAnnotation(overlayObject.id,overlayObject.start, overlayObject.end, cssProps);
        progressbarObject.value = overlayObject.value;
        progressbarObject.start = overlayObject.start;
        //progressbarObject.bgToggle= progressbarObject.cssProperties.backgroundColor!=='transparent'?true:false;
        progressbarObject.className = overlayObject["class-name"];
        progressbarObject.exitAnimation = overlayObject.exitAnimation || "";
        progressbarObject.title=overlayObject.title;
        if(progressbarObject.cssProperties.animation === "" && progressbarObject.className) {
            progressbarObject.cssProperties.animation = `${progressbarObject.className.split(" ")[1]} ${progressbarObject.cssProperties.animationDuration}`;
            progressbarObject.className = "";
        }
        return progressbarObject;
    }

}
