/*******************************************************************************
 * COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
 * Reserved. Infosys believes the information in this document is accurate as of
 * its publication date; such information is subject to change without notice.
 * Infosys acknowledges the proprietary rights of other companies to the
 * trademarks, product names and such other intellectual property rights
 * mentioned in this document. Except as expressly permitted, neither this
 * documentation nor any part of it may be reproduced, stored in a retrieval
 * system, or transmitted in any form or by any means, electronic, mechanical,
 * printing, photocopying, recording or otherwise, without the prior permission
 * of Infosys Limited and/or any named intellectual property rights holders
 * under this document.
 ******************************************************************************/
import { Component, OnInit, Input, Output, EventEmitter , SimpleChange} from '@angular/core';
import { faPlusCircle, faMinusCircle,faCaretRight} from '@fortawesome/free-solid-svg-icons';
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { DomSanitizer, SafeValue } from '@angular/platform-browser';

declare var psv: any;

@Component({
  selector: 'lib-video-overlay',
  templateUrl: './video-overlay.html',
  styleUrls: ['./video-overlay.css']
})
export class VideoOverlayComponent implements OnInit {

  faPlusCircle = faPlusCircle;
  faMinusCircle = faMinusCircle;
  @Input() aspectRatio: any;
  faCaretRight:any = faCaretRight;
  @Input() isToolBarOpen!:boolean;
  @Input() videoOverlayComponent: any;
  @Output() change = new EventEmitter<MatSlideToggleChange>();
  @Output() updateCurrentTimeEvent = new EventEmitter();
  @Output() onMinimizing = new EventEmitter<boolean>();



  @Input() overlayJSON: any;
  @Input() selectedAnnotation: any;
  @Input() annotationTableData: any = [];
  @Input() currentTime: any;
  @Input() userData: any = {};
  @Input() fontSize: any;

  @Output() overlayJSONEvent = new EventEmitter<any>();
  @Output() overlayAnnotationsEvent = new EventEmitter<any>();
  @Output() annotationTableDataEvent = new EventEmitter<any>();
  @Output() selectedAnnotationEvent = new EventEmitter<any>();
  @Output() videoOverlayComponentOutput = new EventEmitter<any>();
  @Output() reRecordEvent = new EventEmitter<any>();
  @Output() openGalleryEvent =new EventEmitter<any>();

                                                                          
  constructor(public sanitizer: DomSanitizer) { }
  VideoLoadCondition: boolean = false;
  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChange): void {
    console.log("onchanges",this.aspectRatio);
  }
  reRecord(){
     this.reRecordEvent.emit(this.videoOverlayComponent.id);
  }

  openGalleryForVideo(){
    this.openGalleryEvent.emit();
  }

  onFileUpload(e: any) {
    let videoData: any;

    //newCode for Video Annotation

    let blob: Blob;
    const fileReader = new FileReader();
    const arrayBuffer = e.target.files[0];
    const fileType = "video/mpeg";
    blob = new Blob([arrayBuffer], { type: fileType });
    fileReader.onloadend = (e) => {
      const src: any | SafeValue = URL.createObjectURL(blob);
      this.videoOverlayComponent.videoData = src;
      // this.videoOverlayComponent.videoData = this.sanitizer.bypassSecurityTrustUrl(src);
      videoData = this.sanitizer.bypassSecurityTrustUrl(src);
      this.VideoLoadCondition = true;
      this.loadElement();
    };
    fileReader.readAsArrayBuffer(blob);

    //newCode for Video Annotation

    const inputDataFlag = setInterval(() => {
      if (videoData && videoData !== "") {
        this.videoOverlayComponent.srcUrl = "";
        this.annotationTableData[this.getAnnotationTableId(this.videoOverlayComponent)].value = e.srcElement.files[0].name;
        this.videoOverlayComponent.altText = e.srcElement.files[0].name;
        this.videoOverlayComponent.videoName = e.srcElement.files[0].name;
        this.aspectRatio = parseInt(this.videoOverlayComponent.cssProperties.width.substring(-2) )/ parseInt(this.videoOverlayComponent.cssProperties.height.substring(-2));
        this.overlayJSONEvent.emit(this.overlayJSON);
        this.annotationTableDataEvent.emit(this.annotationTableData);
        this.loadElement();
        clearInterval(inputDataFlag);
      }
    }, 10);
  }

  videoSelected: any;
  loadVideoPathtest() {
    this.VideoLoadCondition = true;
    this.videoOverlayComponent.videoData = this.videoSelected;
    const inputDataFlag = setInterval(() => {
      if (this.videoOverlayComponent.videoData !== "") {
        this.videoOverlayComponent.srcUrl = this.videoSelected;
        this.annotationTableData[this.getAnnotationTableId(this.videoOverlayComponent)].value = this.videoSelected;
        this.videoOverlayComponent.altText = "";
        this.videoOverlayComponent.videoName = this.videoSelected;
        this.aspectRatio = parseInt(this.videoOverlayComponent.cssProperties.width.substring(-2) )/ parseInt(this.videoOverlayComponent.cssProperties.height.substring(-2));
        this.overlayJSONEvent.emit(this.overlayJSON);
        this.annotationTableDataEvent.emit(this.annotationTableData);
        this.loadElement();
        clearInterval(inputDataFlag);
      }
    }, 10);
  }

  loadElement(updatedOverlayAnnotation?: any) {
    this.overlayAnnotations();
  }

  overlayAnnotations(updateAnnotation?: any) {
    this.selectedAnnotationEvent.emit(this.videoOverlayComponent);
    this.annotationTableDataEvent.emit(this.annotationTableData);
    this.overlayAnnotationsEvent.emit(updateAnnotation);
  }
  getAnnotationTableId(overlayObj: any) {
    for (let index in this.annotationTableData) {
      if (this.annotationTableData[index].type === "tts" && overlayObj.type === "tts" && overlayObj.id === this.annotationTableData[index].id) {
        return index;
      } else if (this.annotationTableData[index].type !== "tts" && overlayObj.type !== "tts" && overlayObj.id === this.annotationTableData[index].id) {
        return index;
      }
    }
    return "";
  }

  updateVideoHeight(height:any){
    this.videoOverlayComponent.cssProperties.height = `${height}em`;
    if(this.videoOverlayComponent.maintainAspectRatio){
      const widthInPx = (height*this.fontSize)*this.aspectRatio;
      this.videoOverlayComponent.cssProperties.width = `${(widthInPx/this.fontSize)}em`;
    }
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.annotationTableDataEvent.emit(this.annotationTableData);
    this.loadElement(this.videoOverlayComponent);
  }
  updateVideoWidth(width:any){
    this.videoOverlayComponent.cssProperties.width = `${width}em`;
    if(this.videoOverlayComponent.maintainAspectRatio){
      const heightInPx = (width*this.fontSize)/this.aspectRatio;
      this.videoOverlayComponent.cssProperties.height = `${(heightInPx/this.fontSize)}em`;
    }
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.annotationTableDataEvent.emit(this.annotationTableData);
    this.loadElement(this.videoOverlayComponent);
  }
  toggleMaintainAspectRatio(){
    this.videoOverlayComponent.maintainAspectRatio = !this.videoOverlayComponent.maintainAspectRatio;
    const height = this.videoOverlayComponent.cssProperties.height.split("em")[0];
    const widthInPx = (height*this.fontSize)*this.aspectRatio;
    this.videoOverlayComponent.cssProperties.width = `${(widthInPx/this.fontSize)}em`;
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.annotationTableDataEvent.emit(this.annotationTableData);
    this.loadElement(this.videoOverlayComponent);
  }
  setBgColor(color:any){
    this.videoOverlayComponent.cssProperties.outlineColor = `${color}`;
    this.videoOverlayComponent.cssProperties.backgroundColor = `${color}`;
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.annotationTableDataEvent.emit(this.annotationTableData);
    this.loadElement(this.videoOverlayComponent);
  }
  toTransparent(){
    if(this.videoOverlayComponent.bgToggle===false){
      this.videoOverlayComponent.cssProperties.backgroundColor="transparent";
      this.videoOverlayComponent.cssProperties.outlineColor = "transparent";
      this.videoOverlayComponent.cssProperties.outlineWidth = `0.0em`;
    }
    else{
      this.videoOverlayComponent.bgToggle=true;
      this.videoOverlayComponent.cssProperties.backgroundColor="#000000";
      this.videoOverlayComponent.cssProperties.outlineColor ="#000000";
    }
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.annotationTableDataEvent.emit(this.annotationTableData);
    this.loadElement(this.videoOverlayComponent);     
  }

  increaseOutlineSize() {
    const paddingSize: string = this.videoOverlayComponent.cssProperties.outlineWidth;
    this.videoOverlayComponent.cssProperties.outlineWidth = `${+paddingSize.replace("em", "") + 0.01}em`;
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.loadElement(this.videoOverlayComponent);
  }

  decreaseOutlineSize() {
    const paddingSize: string = this.videoOverlayComponent.cssProperties.outlineWidth;
    this.videoOverlayComponent.cssProperties.outlineWidth = `${+paddingSize.replace("em", "") - 0.01}em`;
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.loadElement(this.videoOverlayComponent);
  }

  updateOutlineSize(number:any){
    this.videoOverlayComponent.cssProperties.outlineWidth = `${number}em`;
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.loadElement(this.videoOverlayComponent);
  }

  decreaseBorderRadius() {
    const borderRadius: string = this.videoOverlayComponent.cssProperties.borderRadius;
    this.videoOverlayComponent.cssProperties.borderRadius = `${+borderRadius.replace("em", "") - 0.01}em`;
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.loadElement(this.videoOverlayComponent);
  }

  increaseBorderRadius() {
    const borderRadius: string = this.videoOverlayComponent.cssProperties.borderRadius;
    this.videoOverlayComponent.cssProperties.borderRadius = `${+borderRadius.replace("em", "") + 0.01}em`;
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.loadElement(this.videoOverlayComponent);
  }

  updateBorderRadius(number:any){
    this.videoOverlayComponent.cssProperties.borderRadius = `${number}em`;
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.loadElement(this.videoOverlayComponent);
  }

  changeBgColor(inpColor:any) {
    this.videoOverlayComponent.cssProperties.backgroundColor=inpColor;
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.setBgColor(inpColor);
  }

  overlayJSONFunction(overlayJSON:any){
    this.overlayJSONEvent.emit(overlayJSON);
  }

  replaceNestedDataValue(value: any) {
    const nestedData = psv.replaceVariablesWithValues(value, this.userData);
    return nestedData === "NOT FOUND" ? "" : nestedData;
  }
  updateCurrentTime(currentTime: number) {
    this.updateCurrentTimeEvent.emit(currentTime);
  }
  minimizeToolBar() {
    this.onMinimizing.emit(true);
  }

}