/*******************************************************************************
 * COPYRIGHT NOTICE © 2017 Infosys Limited, Bangalore, India. All Rights
 * Reserved. Infosys believes the information in this document is accurate as of
 * its publication date; such information is subject to change without notice.
 * Infosys acknowledges the proprietary rights of other companies to the
 * trademarks, product names and such other intellectual property rights
 * mentioned in this document. Except as expressly permitted, neither this
 * documentation nor any part of it may be reproduced, stored in a retrieval
 * system, or transmitted in any form or by any means, electronic, mechanical,
 * printing, photocopying, recording or otherwise, without the prior permission
 * of Infosys Limited and/or any named intellectual property rights holders
 * under this document.
 ******************************************************************************/
import { DataService } from "../services/data.service";
export class AudioAnnotation {

    type!: string;
    start: number;
    id: number;
    end: number;
    value!: string;
    params: any;
    tts: any;
    isDurationChanged:boolean = false;
    duration:any;



    constructor(id: number, currentTime: number, videoDuration: number, tts: string) {
        this.start = currentTime;
        this.end = videoDuration;
        this.duration = this.end - this.start;
        this.id = id;
        this.tts = tts;
        this.initializeAttributes("");
    }

    initializeAttributes(params: any, ttsProvider?: string) {
        if (!ttsProvider) {
            this.tts = "polly";
        } else {
            this.tts = ttsProvider;
        }

        this.type = 'tts';
        if (this.tts === "polly") {
            this.params = {
                "Engine": params.ttsEngine || "Neural",
                "OutputFormat": "mp3",
                "TextType": "ssml",
                "VoiceId": params.selectedVoice || "Matthew",
                "Text": params.text || "Hey! Do you speak a foreign language? One language is never enough"
            }
        } else if (this.tts === "google") {
            this.params = {
                input: {
                    "ssml": params.text || "Hey! Do you speak a foreign language? One language is never enough"
                },
                voice: {
                    "languageCode": params.languageCode || "en-US",
                    "name": params.selectedVoice || "en-US-Standard-B"
                },
                audioConfig: {
                    "audioEncoding": "MP3"
                }
            }
        } else if (this.tts === "azure") {
            this.params = {
                "OutputFormat": "mp3",
                "text": params.text || "Hey! Do you speak a foreign language? One language is never enough",
                "TextType": "ssml",
                "voiceId": params.selectedVoice || "en-US",
                "languageCode": params.languageCode || "en-US-ZiraRUS"
            }
        }

    }

    getAttributes(tts:any) {
        let audioObj = {
            type: this.type,
            id: this.id,
            start: this.start,
            end: this.end,
            params: {}
        }
        if (tts === "polly") {
            audioObj.params = {
                "Engine": this.params.Engine,
                "OutputFormat": "mp3",
                "TextType": "ssml",
                "VoiceId": this.params.VoiceId,
                "Text": "<speak>" + this.params.Text + "</speak>"
            }
        } else if (tts === "google") {
            audioObj.params = {
                input: {
                    "ssml": "<speak>" + this.params.input.ssml + "</speak>"
                },
                voice: {
                    "languageCode": this.params.voice.languageCode,
                    "name": this.params.voice.name
                },
                audioConfig: {
                    "audioEncoding": "MP3"
                }
            }
        } else if (tts === "azure") {
            audioObj.params = {
                "OutputFormat": "mp3",
                "TextType": "ssml",
                "text": this.params.text,
                "voiceId": this.params.voiceId,
                "languageCode": this.params.languageCode
            }
        }
        return (audioObj);
    }

    transformAttributes(overlayObject:any, ttsType:any) {
        const overlayAnnotationObject = new AudioAnnotation(overlayObject.id, overlayObject.start, overlayObject.end, ttsType);
        overlayAnnotationObject.params = overlayObject.params;
        if (ttsType === "polly" && overlayAnnotationObject.params.Text.indexOf('speak') > -1) {
            overlayAnnotationObject.params.Text = overlayAnnotationObject.params.Text.replace("<speak>", "").replace("</speak>", "")
        } else if (ttsType === "google" && overlayAnnotationObject.params.input.ssml.indexOf('speak') > -1) {
            overlayAnnotationObject.params.input.ssml = overlayAnnotationObject.params.input.ssml.replace("<speak>", "").replace("</speak>", "")
        }
        return overlayAnnotationObject;
    }

}
