<!------------------------------------------------------------------------------
 # COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
 # Reserved. Infosys believes the information in this document is accurate as of
 # its publication date; such information is subject to change without notice.
 # Infosys acknowledges the proprietary rights of other companies to the
 # trademarks, product names and such other intellectual property rights
 # mentioned in this document. Except as expressly permitted, neither this
 # documentation nor any part of it may be reproduced, stored in a retrieval
 # system, or transmitted in any form or by any means, electronic, mechanical,
 # printing, photocopying, recording or otherwise, without the prior permission
 # of Infosys Limited and/or any named intellectual property rights holders
 # under this document.
-------------------------------------------------------------------------------->
<div class="inner-properties">
  <div (click)="minimizeToolBar()" class="toolbar-minimize" *ngIf="isToolBarOpen">
    <!-- <fa-icon [icon]="faCaretRight"></fa-icon> -->
    <em class="icon-class material-icons">highlight_off</em>
  </div>
  <div>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title class="heading-container">TEXT PROPS. </mat-panel-title>
      </mat-expansion-panel-header>
      <label class="properties font-size-sm" for="annotation-text">
        ANNOTATION TEXT
      </label>
      <input class="font-size-md" type="text" id="annotation-text" placeholder="Your text here..."
        [(ngModel)]="textOverlayComponent.value" (input)="changeAnnotationValue()">


      <label class="properties font-size-sm">LINK</label>
      <input class="font-size-md" type="text" [(ngModel)]="textOverlayComponent.link"
        placeholder="https://www.google.com" (input)="loadElement(textOverlayComponent)">

      <label class="properties font-size-sm">CALLBACK</label>
      <input class="font-size-md" type="text" [(ngModel)]="textOverlayComponent.callback" placeholder="callback"
        (input)="loadElement(textOverlayComponent)">

      <label class="properties font-size-sm">TITLE</label>
      <input class="font-size-md" type="text" [(ngModel)]="textOverlayComponent.title" placeholder="title"
        (input)="loadElement(textOverlayComponent)">

      <div class="input-dropdown">
        <label class="properties font-size-sm"> FONT FAMILY</label>
        <mat-form-field>
          <mat-select class="full-width" [(ngModel)]="textOverlayComponent.cssProperties.fontFamily"
            (selectionChange)="loadElement(textOverlayComponent)">
            <mat-option *ngFor="let fontFamily of fontFamilyList | orderBy" [value]="fontFamily">{{fontFamily}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
        <input class="font-size-md" type="text" [(ngModel)]="textOverlayComponent.cssProperties.fontFamily"
        (input)="loadElement(textOverlayComponent)" placeholder="Enter new Font Family">
      <label class="properties font-size-sm">
        FONT STYLE
      </label>
      <div class="properties-bar1">
        <button class="remove-default-style properties-button style-btn"
          [ngClass]="{'active': textOverlayComponent.cssProperties.fontWeight === 'Bold'}"
          (click)="applyStyle(textOverlayComponent,'bold')" matTooltip="Bold" matTooltipPosition=above>
          <fa-icon [icon]="faBold"></fa-icon>
        </button>
        <button class="remove-default-style properties-button style-btn"
          [ngClass]="{'active': textOverlayComponent.cssProperties.fontStyle === 'italic'}"
          (click)="applyStyle(textOverlayComponent,'italic')" matTooltip="Italic" matTooltipPosition=above>
          <fa-icon [icon]="faItalic"></fa-icon>
        </button>
        <button class="remove-default-style properties-button style-btn"
          [ngClass]="{'active': textOverlayComponent.cssProperties.textDecoration === 'underline'}"
          (click)="applyStyle(textOverlayComponent,'underline')" matTooltip="Underline" matTooltipPosition=above>
          <fa-icon [icon]="faUnderline"></fa-icon>
        </button>
      </div>

      <div class="input-dropdown">
        <label class="properties font-size-sm"> FONT WEIGHT</label>
        <mat-form-field>
          <mat-select class="full-width" [(ngModel)]="textOverlayComponent.cssProperties.fontWeight"
            (selectionChange)="settingFontWeight(textOverlayComponent)">
            <mat-option *ngFor="let fontWeight of fontWeightList" [value]="fontWeight">{{fontWeight}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <label class="properties font-size-sm">
        FONT SIZE (in em)
      </label>
      <div class="properties-bar">
        <button class="remove-default-style properties-button" (click)="decreaseFontSize(textOverlayComponent)"
          matTooltip="Reduce Font Size" matTooltipPosition=left>
          <fa-icon [icon]="faMinusCircle"></fa-icon>
        </button>
        <input class="font-size-lg center-align width-half font-padding-text" type="number" step="0.01"
          [ngModel]="textOverlayComponent.cssProperties.fontSize | calcFontSize"
          (ngModelChange)="updateFontSize($event,textOverlayComponent)">
        <button class="remove-default-style properties-button" (click)="increaseFontSize(textOverlayComponent)"
          matTooltip="Add Font Size" matTooltipPosition=left>
          <fa-icon [icon]="faPlusCircle"></fa-icon>
        </button>
      </div>

      <label class="font-size-sm">
        COLOR
      </label>
      <div class="properties-bar">
        <label style="display:inline-flex">
          <input class="color-picker" type="color" #hexColor
            [ngModel]="textOverlayComponent.cssProperties.color | colorConvert" (input)="changeColor(hexColor.value)">
          <input type="text" class="color-picker-value" [(ngModel)]="textOverlayComponent.cssProperties.color"
            (input)="loadElement(textOverlayComponent)">
        </label>
      </div>

      <label class="properties font-size-sm">
        ALIGNMENT
      </label>
      <div class="properties-bar">
        <button class="remove-default-style properties-button align-btn"
          [ngClass]="{'active': textOverlayComponent.cssProperties.textAlign === 'left'}"
          (click)="updateTextAlignment('left')" matTooltip="Left" matTooltipPosition=above>
          <fa-icon [icon]="faAlignLeft"></fa-icon>
        </button>
        <button class="remove-default-style properties-button align-btn"
          [ngClass]="{'active': textOverlayComponent.cssProperties.textAlign === 'center'}"
          (click)="updateTextAlignment('center')" matTooltip="Center" matTooltipPosition=above>
          <fa-icon [icon]="faAlignCenter"></fa-icon>
        </button>
        <button class="remove-default-style properties-button align-btn"
          [ngClass]="{'active': textOverlayComponent.cssProperties.textAlign === 'right'}"
          (click)="updateTextAlignment('right')" matTooltip="Right" matTooltipPosition=above>
          <fa-icon [icon]="faAlignRight"></fa-icon>
        </button>
        <button class="remove-default-style properties-button align-btn"
          [ngClass]="{'active': textOverlayComponent.cssProperties.textAlign === 'justify'}"
          (click)="updateTextAlignment('justify')" matTooltip="Justify" matTooltipPosition=above>
          <fa-icon [icon]="faAlignJustify"></fa-icon>
        </button>
      </div>

      <div class="properties-bar">
        <label class="font-size-sm aspect-width">WIDTH (in em)&nbsp;
          <input [ngModel]="textOverlayComponent.cssProperties.textWidth| calcFontSize"
            (ngModelChange)="updateTextWidth($event)" class="input-size font-size-md" type="number" min="0">
        </label>
        <label class="font-size-sm aspect-width">HEIGHT (in em)&nbsp;
          <input [ngModel]="textOverlayComponent.cssProperties.textHeight | calcFontSize"
            (ngModelChange)="updateTextHeight($event)" class="input-size font-size-md" type="number" min="0">
        </label>
      </div>

      <div class="font-size-sm">
        <label class="bg-label">BACKGROUND COLOR </label>
        <mat-slide-toggle [(ngModel)]="textOverlayComponent.bgToggle" (change)="toTransparent(textOverlayComponent)">
        </mat-slide-toggle>
      </div>
      <div *ngIf="textOverlayComponent.bgToggle">
        <div class="properties-bar">
          <label class="bg-color-label">
            <input class="color-picker" type="color" #bgColorHex
              [ngModel]="textOverlayComponent.cssProperties.backgroundColor | colorConvert"
              (input)="changeBgColor(bgColorHex.value);">
            <input type="text" class="color-picker-value"
              [(ngModel)]="textOverlayComponent.cssProperties.backgroundColor"
              (input)="loadElement(textOverlayComponent);">
          </label>
        </div>

        <label class="properties font-size-sm">
          BACKGROUND PADDING
        </label>
        <div class="properties-bar">
          <button class="remove-default-style properties-button" (click)="decreasePaddingSize(textOverlayComponent)"
            matTooltip="Reduce Padding Size" matTooltipPosition=left>
            <fa-icon [icon]="faMinusCircle"></fa-icon>
          </button>
          <input class="font-size-lg center-align width-half font-padding-text" type="number"
            [ngModel]="textOverlayComponent.cssProperties.padding | calcFontSize"
            (ngModelChange)="updatePaddingSize(textOverlayComponent,$event)">
          <button class="remove-default-style properties-button" (click)="increasePaddingSize(textOverlayComponent)"
            matTooltip="Add Padding Size" matTooltipPosition=left>
            <fa-icon [icon]="faPlusCircle"></fa-icon>
          </button>
        </div>
        <label class="properties font-size-sm label-margin">
          BORDER RADIUS
        </label>
        <div class="properties-bar">
          <button class="remove-default-style properties-button" (click)="decreasePaddingRadius(textOverlayComponent)"
            matTooltip="Reduce Border Radius" matTooltipPosition=left>
            <fa-icon [icon]="faMinusCircle"></fa-icon>
          </button>
          <input class="font-size-lg center-align width-half font-padding-text" type="number" step="0.01"
            [ngModel]="textOverlayComponent.cssProperties.borderRadius | calcFontSize"
            (ngModelChange)="updatePaddingRadius(textOverlayComponent,$event)">
          <button class="remove-default-style properties-button" (click)="increasePaddingRadius(textOverlayComponent)"
            matTooltip="Add Border Radius" matTooltipPosition=left>
            <fa-icon [icon]="faPlusCircle"></fa-icon>
          </button>
        </div>
      </div>
    </mat-expansion-panel>
  </div>


</div>