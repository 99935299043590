export class Project_Meta {

    title: any;
    description: any;
    createDate: Date;
    lastModifiedDate: Date;
    id: any;
    thumbnail: any;
    script: any;
    concatenatedPSV: any;
    scenes:any = [];
    isPublished: boolean = false;
    bgAudioUrl:any;
    createdBy:any;
    bgAudioVolume:any;
    
    constructor(title:string, description:string,script:string,createDate:Date,lastModifiedDate: Date,id?:any,concatenatedPSV?:any,bgAudioUrl?:any,bgAudioVolume?:any,createdBy?:any){
        this.title = title;
        this.description = description;
        this.script = script;
        this.createDate = createDate;
        this.lastModifiedDate = lastModifiedDate;
        this.id = id;
        this.concatenatedPSV = concatenatedPSV;
        this.bgAudioUrl = bgAudioUrl;
        this.createdBy=createdBy;
        this.bgAudioVolume = bgAudioVolume ? bgAudioVolume : 0.5;
    }

}