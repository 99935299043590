import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OverlayTextComponent } from './overlay-text.component';

import { MatInputModule} from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatSliderModule } from '@angular/material/slider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatTreeModule } from '@angular/material/tree';
import { AngularDraggableModule } from 'angular2-draggable';
import { ResizableModule } from 'angular-resizable-element';
import { OrderModule } from 'ngx-order-pipe';
import { HttpClientModule } from '@angular/common/http';
 import { CalcFontSizePipe } from './pipes/calc-font-size/calc-font-size.pipe';
 import { colorConvertPipe } from './pipes/color-convert.pipe';
 import { CommonModule, DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    OverlayTextComponent,
    CalcFontSizePipe,
    colorConvertPipe
  ],
  imports: [
   ReactiveFormsModule,
   CommonModule,
   MatIconModule,
   FontAwesomeModule,
   MatButtonModule,
   MatInputModule,
   MatTooltipModule,
   MatTableModule,
   MatSliderModule,
   AngularDraggableModule,
   ResizableModule,
   MatDialogModule,
   MatFormFieldModule,
   MatSlideToggleModule,
   MatSnackBarModule,
   MatCheckboxModule,
   MatRadioModule,
   MatTreeModule,
   MatSelectModule,
   MatButtonToggleModule,
   MatExpansionModule,
   OrderModule,
   HttpClientModule,
   MatProgressBarModule,
   MatIconModule,
  ReactiveFormsModule,
  MatExpansionModule,
  MatSlideToggleModule,
  FormsModule
  ],
  exports: [
    OverlayTextComponent
  ]
})
export class OverlayTextModule {   
}
