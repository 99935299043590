import { NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { MatInputModule} from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PSVCommonPropertiesComponent } from './psv-common-properties.component';
import { CalcFontSizePipe } from './pipes/calc-font-size/calc-font-size.pipe';
import { CalcRotationPipe } from './pipes/calc-rotation/calc-rotation.pipe';
import { CalcXCoordPipe } from './pipes/calc-x-coordinate/calc-x-coord.pipe';
import { CalcYCoordPipe } from './pipes/calc-y-coordinate/calc-y-coord.pipe';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { colorConvertPipe } from './pipes/color-convert.pipe';
import { MatExpansionModule } from '@angular/material/expansion';


@NgModule({
  declarations: [
    PSVCommonPropertiesComponent,
    CalcFontSizePipe,
    CalcRotationPipe,
    CalcXCoordPipe,
    CalcYCoordPipe,
    colorConvertPipe
  ],  
  imports: [
    FontAwesomeModule,
    MatButtonModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatInputModule,
    FormsModule,
    BrowserModule,
    MatSliderModule,
    MatSelectModule,
    MatIconModule,
    CommonModule
    
  ],
  exports: [
    PSVCommonPropertiesComponent
  ]
})
export class PSVCommonPropertiesModule { }
