import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PublishUserdataComponent } from 'src/app/publish/publish-userdata/publish-userdata.component';
import { PSV_Meta } from 'src/app/shared/models/psv.model';
import { DataService } from 'src/app/shared/services/data.service';


@Component({
  selector: 'app-preview-modal',
  templateUrl: './preview-modal.component.html',
  styleUrls: ['./preview-modal.component.css']
})
export class PreviewModalComponent implements OnInit {


  constructor(readonly data: DataService, public dialog: MatDialog, private router: Router) { }
  player:any;
  ngOnInit(): void {
    this.getUserdata()
  }
  userdata: any
  async getUserdata() {
    this.userdata = await this.data.fetchUserDataById(this.data.templateObj.userdataId);
    this.setPreview();
  }
  async setPreview() {
    let ttsOptions = this.data.ttsOptions;
    let videoSource = {
      "type": "default",
      "path": this.data.templateObj.finalVideoSrc,
      "autoplay": false
    }
    let metaDataUrl = this.data.templateObj.finalMetadataSrc
    this.player = new PSV_Meta("sample-player", videoSource, metaDataUrl, this.userdata.userData, ttsOptions, true)
    this.data.setPSV(this.player);
  }

  publishPsv() {
    this.data.isTemplatePage = false;
    this.data.userDataId = { 'user1': this.data.templateObj.userdataId };
    this.data.fromTemplate = true;
    this.data.isNavigation = false;
    this.data.isPreviewLoaded = true;
    let videoDataPath = this.data.templateObj.finalMetadataSrc;
    var videoMetaScript = document.createElement("script");
    videoMetaScript.src = videoDataPath;
    videoMetaScript.async = true;
    document.getElementsByTagName("script")[0].parentNode?.appendChild(videoMetaScript);
    videoMetaScript.addEventListener("load", () => {
      this.dialog.closeAll()
      this.router.navigate(['/publish']);
      this.data.isPublishScreen = true;
    })
  }

  openUserdataDialog() {
    let dialogRef = this.dialog.open(PublishUserdataComponent, { data: this.userdata, disableClose: true });
    dialogRef.componentInstance._database.userData = this.userdata.userData;
    dialogRef.componentInstance._database.typeOfOperation = 'edit';
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result !== 'cancel') {
        let userDataObj: any = {};
        userDataObj['id'] = this.data.templateObj.userdataId;
        userDataObj['userData'] = result;
        await this.data.updateUserData(userDataObj).then(()=>{
          this.data.removePSV(this.player);
          this.getUserdata();
        });
      }
    })
  }

}
