import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faEdit, faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChartInputModalComponent } from './chart-input-modal.component';
import { MatSnackBarConfig } from "@angular/material/snack-bar";

@Component({
  selector: 'psv-chart',
  templateUrl: `./chart.component.html`,
  styleUrls:['./chart.component.css' 
  ]
})


export class ChartComponent implements OnInit {

faEdit:any = faEdit;
faPlusCircle:any = faPlusCircle;
faMinusCircle:any = faMinusCircle;
chartType: any;

chartTypes = [
  { name: "BAR - VARIANT 1", value: "bar", type: "nvd3" },
  { name: "BAR - VARIANT 2", value: "bar", type: "chartjs" },
  { name: "LINE", value: "line", type: "chartjs" },
  { name: "AREA", value: "area", type: "chartjs" },
  // { name: "PIE - VARIANT 1", value: "pie", type: "nvd3" },
  { name: "PIE - VARIANT 1", value: "pie", type: "chartjs" },
  { name: "DOUGHNUT", value: "doughnut", type: "chartjs" }
];
animationList = [
  { name: "Linear", value: 'linear' },
  { name: "Ease In Circ", value: 'easeInCirc' },
  { name: "Ease Out Circ", value: 'easeOutCirc' },
  { name: "Ease In Out Circ", value: 'easeInOutCirc' },
  { name: "Ease In Sine", value: 'easeInSine' },
  { name: "Ease Out Sine", value: 'easeOutSine' },
  { name: "Ease In Out Sine", value: 'easeInOutSine' },
  { name: "Ease In Bounce", value: 'easeInBounce' },
  { name: "Ease Out Bounce", value: 'easeOutBounce' },
  { name: "Ease In Out Bounce", value: 'easeInOutBounce' },
  { name: "Ease In Back", value: 'easeInBack' },
  { name: "Ease Out Back", value: 'easeOutBack' },
  { name: "Ease In Out Back", value: 'easeInOutBack' },
  { name: "Ease In Quad", value: 'easeInQuad' },
  { name: "Ease Out Quad", value: 'easeOutQuad' },
  { name: "Ease In Out Quad", value: 'easeInOutQuad' },
  { name: "Ease In Cubic", value: 'easeInCubic' },
  { name: "Ease Out Cubic", value: 'easeOutCubic' },
  { name: "Ease In Out Cubic", value: 'easeInOutCubic' },
  { name: "Ease In Out Circ", value: 'easeInOutCirc' },
  { name: "Ease In Quart", value: 'easeInQuart' },
  { name: "Ease Out Quart", value: 'easeOutQuart' },
  { name: "Ease In Out Quart", value: 'easeInOutQuart' },
  { name: "Ease In Quint", value: 'easeInQuint' },
  { name: "Ease Out Quint", value: 'easeOutQuint' },
  { name: "Ease In Out Quint", value: 'easeInOutQuint' },
  { name: "Ease In Expo", value: 'easeInExpo' },
  { name: "Ease Out Expo", value: 'easeOutExpo' },
  { name: "Ease In Out Expo", value: 'easeInOutExpo' },
  { name: "Ease In Elastic", value: 'easeInElastic' },
  { name: "Ease Out Elastic", value: 'easeOutElastic' },
  { name: "Ease In Out Elastic", value: 'easeInOutElastic' }
];
customColorList = "";
aspectRatio = 2;
chartGroups: any
color_data:any;
labelsOutsideCheckbox: boolean=false;
labelsCheckbox: boolean=false;
labelSunbeamCheckbox: boolean=false;
snackBar: any;
selectedAnnotation:any;
@Input() annotationTableData:any;
@Input() overlayJSON:any;
@Input() chartcomponent:any;
// @Output() change: EventEmitter<MatSlideToggleChange>
@Input() isToolBarOpen!:boolean;
@Output() chartcomponentOutput = new EventEmitter<any>();
@Output() onMinimizing = new EventEmitter<boolean>();
@Output() overlayAnnotationsEvent = new EventEmitter<any>();
@Output() setDraggerPositionEvent = new EventEmitter<any>();
color: any;
annotationWidth:any;
annotationHeight:any;

showSnackBar(message:any, classList:any) {
  const config = new MatSnackBarConfig();
  config.horizontalPosition = "right";
  config.verticalPosition = "bottom";
  config.panelClass = classList;
  config.duration = 3000;
  this.snackBar.open(message, 'Ok', config);
}


  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    if (this.overlayJSON.overlays[this.chartcomponent.id - 1].data &&
      this.overlayJSON.overlays[this.chartcomponent.id - 1].chartData.length > 0) {
      this.initCustomColor();
    } else {
      this.transformByChartType();
    }

    this.color.subscribe((color_data: { default: { schemeGroups: { sequential: string | any[]; qualitative: any; }; }; })=>{
      if(color_data){
        this.chartGroups = color_data.default.schemeGroups.sequential.concat(color_data.default.schemeGroups.qualitative);
        this.color_data=color_data;
      }

    });
  }


  initCustomColor() {
    if (this.overlayJSON.overlays[this.chartcomponent.id - 1].colorType === "custom") {
      if (this.overlayJSON.overlays[this.chartcomponent.id - 1].chartLibrary === "nvd3") {
        for (let color of this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.color) {
          this.customColorList += color + ","
        }
      } else if (this.overlayJSON.overlays[this.chartcomponent.id - 1].chartLibrary === "chartjs") {
        for (let bgColor of this.overlayJSON.overlays[this.chartcomponent.id - 1].transformedData.datasets[0].backgroundColor) {
          this.customColorList += bgColor + ","
        }
      }
      this.customColorList = this.customColorList.slice(0, -1);
    }
  }

  loadElement(updatedOverlay?: any) {
    if (this.overlayJSON.overlays[this.chartcomponent.id - 1].data ) {
      if (this.overlayJSON.overlays[this.chartcomponent.id - 1].chartLibrary === "nvd3") {
        if (this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.showXAxisLabel &&
          !this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.showXAxis) {
          const classList = ["info-snackbar"];
          const message = 'Cannot hide X axis when X axis label is to be shown.';
          this.showSnackBar(message, classList);
          this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.showXAxis = true;
        }
        if (this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.showYAxisLabel &&
          !this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.showYAxis) {
          const classList = ["info-snackbar"];
          const message = 'Cannot hide Y axis when Y axis label is to be shown.';
          this.showSnackBar(message, classList);
          this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.showYAxis = true;
        }
      }
      this.overlayAnnotations(updatedOverlay);
      this.setDraggerPosition();
    }
  }
  overlayAnnotations(updatedOverlays?:any) {
    this.overlayAnnotationsEvent.emit(updatedOverlays);
  }

  mapChartType(chartName:any) {
    for (const chartType of this.chartTypes) {
      if (chartType.name === chartName) {
        return chartType;
      }
    }
    return null;
  }

  transformByChartType() {
    const fontSize = parseFloat((document.querySelectorAll("#psv-overlay-player > div.vjs-overlay")[0] as HTMLElement).style.fontSize.split("px")[0]);
    const chartInfo = this.mapChartType(this.overlayJSON.overlays[this.chartcomponent.id - 1].chartName);
  
   
    if(chartInfo) {
      this.overlayJSON.overlays[this.chartcomponent.id - 1].chartLibrary = chartInfo.type;
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.chartType = chartInfo.value;
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.chartCategory = chartInfo.value;
      // this.annotationTableData[this.getAnnotationTableId(this.chartcomponent)].value = chartInfo.value;
      const color=this.getSelectedChartColor();
      if (chartInfo.type === "nvd3") {
        this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options = {
          showValues: true,
          showLegend: false,
          showXAxis: true,
          showYAxis: true,
          staggerLabels: true,
          wrapLabels: false,
          height: this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.height,
          width: this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.width,
          color: color !== undefined ? color : ['#1f78b4'],
          showXAxisLabel: true,
          showYAxisLabel: true,
          animationDuration: 0,
          rotateLabels: 0,
          fontSize: 0.3,
          //axisLabelDistance: -10,
          xAxisLabelDistance: -15,
          yAxisLabelDistance: -10, 
          labelColor: "#000000",
          preFormatValue: '',
          postFormatValue: '',
          labelSunbeamLayout: true,
          labelsOutside: true,
          axisLabelDistance: 5
        }
        if(chartInfo.value === "pie") {
            this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.showLegend = true;
            this.overlayJSON.overlays[this.chartcomponent.id - 1].data.colorType = 'theme';
            if (this.overlayJSON.overlays[this.chartcomponent.id - 1].chartData.data.length > 3) {
              const chartDataLength = this.overlayJSON.overlays[this.chartcomponent.id - 1].chartData.data.length;
              this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.color =
              this.color_data[this.overlayJSON.overlays[this.chartcomponent.id - 1].data.selectedColorTheme][chartDataLength].concat();
            } else {
              this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.color =
              this.color_data[this.overlayJSON.overlays[this.chartcomponent.id - 1].data.selectedColorTheme][3].concat();
            }
        }
      } else if (chartInfo.type === "chartjs") {
        this.overlayJSON.overlays[this.chartcomponent.id - 1].data.transformedData = undefined;
        this.overlayJSON.overlays[this.chartcomponent.id - 1].data.transformedData = {
          "datasets": [{
            "data": [],
            "backgroundColor": color!== undefined? color : "#1f78b4",
            "label": ""
          }],
         
          "labels": []
          
        };
       
        this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options = {
          fontSize: 0.25,
          responsive: false,
          legend: {
            display: true,
            labels: {
              fontColor: '#000000',
              fontSize: 0.25*fontSize
            }
          },
          scales: {
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: '',
                fontColor: '#000000',
                fontSize: 0.25*fontSize
              },
              gridLines: {
                display: false,
                color: '#000000',
                drawBorder: false,
                lineWidth: 1,
                drawOnChartArea: false,
                drawTicks: false
              },
              ticks: {
                fontSize: 0.25*fontSize,
                suggestedMin: 0,
                fontColor: '#000000',
                minRotation: 0,
                maxTicksLimit: 0,
                stepSize: 0,
                beginAtZero: false,
                display: false
              }
            }],
            xAxes: [{
              scaleLabel: {
                display: true,
                fontColor: '#000000',
                fontSize: 0.25*fontSize
              },
              gridLines: {
                display: true,
                color: '#000000',
                drawBorder: false,
                lineWidth: 1,
                drawOnChartArea: false,
                drawTicks: false
              },
              ticks: {
                fontSize: 0.25*fontSize,
                fontColor: '#000000',
                minRotation: 0,
                suggestedMin: 0,
                maxTicksLimit: 0,
                stepSize: 0,
                beginAtZero: false,
                display: true
              }
            }]
          },
          animation: {
            duration: 0,
            easing: "linear"
          },
          elements: {
            line: {
              tension: 0,
              borderWidth: 1
            }
          },
          plugins: {
              labels: false
          }
        };
        this.overlayJSON.overlays[this.chartcomponent.id - 1].data.chartCategory = this.overlayJSON.overlays[this.chartcomponent.id - 1].data.chartType;
        this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.scales.yAxes[0].display = true;
        this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.scales.xAxes[0].display = true;
        this.overlayJSON.overlays[this.chartcomponent.id - 1].data.transformedData.datasets[0].fill = true;
        switch (this.overlayJSON.overlays[this.chartcomponent.id - 1].data.chartType) {
          case 'line': {
            this.overlayJSON.overlays[this.chartcomponent.id - 1].data.transformedData.datasets[0].fill = false;
            break;
          }
          case 'area': {
            this.overlayJSON.overlays[this.chartcomponent.id - 1].data.chartCategory = 'line';
            this.overlayJSON.overlays[this.chartcomponent.id - 1].data.transformedData.datasets[0].fill = true;
            break;
          }
          case 'pie':
          case 'doughnut': {
            this.overlayJSON.overlays[this.chartcomponent.id - 1].data.colorType = 'theme';
            if (this.overlayJSON.overlays[this.chartcomponent.id - 1].chartData.data.length > 3) {
              const chartDataLength = this.overlayJSON.overlays[this.chartcomponent.id - 1].chartData.data.length;
              this.overlayJSON.overlays[this.chartcomponent.id - 1].data.transformedData.datasets[0].backgroundColor =
              this.color_data[this.overlayJSON.overlays[this.chartcomponent.id - 1].data.selectedColorTheme][chartDataLength].concat();
            } else {
              
              this.overlayJSON.overlays[this.chartcomponent.id - 1].data.transformedData.datasets[0].backgroundColor =
              this.color_data[this.overlayJSON.overlays[this.chartcomponent.id - 1].data.selectedColorTheme][3].concat();
            }
            this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.scales.yAxes[0].display = false;
            this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.scales.xAxes[0].display = false;
            this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.scales.yAxes[0].scaleLabel.display = false;
            this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.scales.xAxes[0].scaleLabel.display = false;
            this.labelsCheckbox = true;
            this.labelSunbeamCheckbox = true;
            this.labelsOutsideCheckbox = false;
            this.toggleLabels();
            this.labelSunbeam();
            this.pieLabelsPosition();
            break;
          }
        }
      }
      this.loadElement();
    }
  }

  openChartInputsDialog(): void {
    const dialogRef = this.dialog.open(ChartInputModalComponent, {});
    dialogRef.afterClosed().subscribe(result => {
        this.loadElement();
    });
  }

  toggleMaintainAspectRatio() {
    const fontSize = parseFloat((document.querySelectorAll("#psv-overlay-player > div.vjs-overlay")[0] as HTMLElement).style.fontSize.split("px")[0]);
    this.overlayJSON.overlays[this.chartcomponent.id - 1].maintainAspectRatio = !this.overlayJSON.overlays[this.chartcomponent.id - 1].maintainAspectRatio;
    const height = this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.height.split("em")[0];
    const widthInPx = (parseFloat(height) * fontSize) * this.aspectRatio;
    this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.width = `${(widthInPx / fontSize)}em`;
    this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.height = this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.height;
    this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.width = this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.width;
    this.loadElement(this.overlayJSON.overlays[this.chartcomponent.id - 1]);
  }

  updateChartHeight(height:any) {
    const fontSize = parseFloat((document.querySelectorAll("#psv-overlay-player > div.vjs-overlay")[0] as HTMLElement).style.fontSize.split("px")[0]);
    this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.height = `${height}em`;
    if (this.overlayJSON.overlays[this.chartcomponent.id - 1].maintainAspectRatio) {
      const widthInPx = (height * fontSize) * this.aspectRatio;
      this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.width = `${(widthInPx / fontSize)}em`;
    }
    this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.height = this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.height;
    this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.width = this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.width;
    this.loadElement(this.overlayJSON.overlays[this.chartcomponent.id - 1]);
  }

  updateChartWidth(width:any) {
    const fontSize = parseFloat((document.querySelectorAll("#psv-overlay-player > div.vjs-overlay")[0] as HTMLElement).style.fontSize.split("px")[0]);
    this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.width = `${width}em`;
    if (this.overlayJSON.overlays[this.chartcomponent.id - 1].maintainAspectRatio) {
      const heightInPx = (width * fontSize) / this.aspectRatio;
      this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.height = `${(heightInPx / fontSize)}em`;
    }
    this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.height = this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.height;
    this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.width = this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.width;
    this.loadElement(this.overlayJSON.overlays[this.chartcomponent.id - 1]);
  }

  increaseAnimationDuration() {
    if (this.overlayJSON.overlays[this.chartcomponent.id - 1].chartLibrary === "nvd3") {
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.animationDuration =
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.animationDuration + 1;
    } else if (this.overlayJSON.overlays[this.chartcomponent.id - 1].chartLibrary === "chartjs") {
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.animation.duration =
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.animation.duration + 1;
    }
    this.loadElement(this.overlayJSON.overlays[this.chartcomponent.id - 1]);
  }

  decreaseAnimationDuration() {
    if (this.overlayJSON.overlays[this.chartcomponent.id - 1].chartLibrary === "nvd3" &&
    this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.animationDuration > 0) {
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.animationDuration =
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.animationDuration - 1;
    } else if (this.overlayJSON.overlays[this.chartcomponent.id - 1].chartLibrary === "chartjs"
    && this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.animation.duration > 0) {
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.animation.duration =
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.animation.duration - 1;
    }
    this.loadElement(this.overlayJSON.overlays[this.chartcomponent.id - 1]);
  }

  updateFontSize(number:any) {
    const fontSize = parseFloat((document.querySelectorAll("#psv-overlay-player > div.vjs-overlay")[0] as HTMLElement).style.fontSize.split("px")[0]);
    if (this.overlayJSON.overlays[this.chartcomponent.id - 1].chartLibrary === "nvd3") {
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.fontSize = number;
    } else if (this.overlayJSON.overlays[this.chartcomponent.id - 1].chartLibrary === "chartjs") {
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.fontSize = number;
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.scales.xAxes[0].ticks.fontSize = number*fontSize;
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.scales.yAxes[0].ticks.fontSize = number*fontSize;
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.scales.xAxes[0].scaleLabel.fontSize = number*fontSize;
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.scales.yAxes[0].scaleLabel.fontSize = number*fontSize;
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.legend.labels.fontSize = number*fontSize;
      if (this.labelsCheckbox){
        this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.plugins.labels.fontSize = number*fontSize;
      }
    }
    this.loadElement(this.overlayJSON.overlays[this.chartcomponent.id - 1]);
  }

  increaseFontSize() {
    const number = this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.fontSize + 0.01;
    this.updateFontSize(number);
  }

  decreaseFontSize() {
    const number = this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.fontSize - 0.01;
    this.updateFontSize(number);
  }
  

  decreaseXaxisLabelDistance() {
    this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.xAxisLabelDistance =
    this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.xAxisLabelDistance - 1;
    this.loadElement(this.overlayJSON.overlays[this.chartcomponent.id - 1]);
   
  }

  increaseXaxisLabelDistance() {
    this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.xAxisLabelDistance =
    this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.xAxisLabelDistance + 1;
    this.loadElement(this.overlayJSON.overlays[this.chartcomponent.id - 1]);
  }

  decreaseYaxisLabelDistance() {
    this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.yAxisLabelDistance =
    this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.yAxisLabelDistance - 1;
    this.loadElement(this.overlayJSON.overlays[this.chartcomponent.id - 1]);
  }

  increaseYaxisLabelDistance() {
    this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.yAxisLabelDistance =
    this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.yAxisLabelDistance + 1;
    this.loadElement(this.overlayJSON.overlays[this.chartcomponent.id - 1]);
  }


  updateLabelColor() {
    const labelColor = this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.scales.yAxes[0].scaleLabel.fontColor;
    this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.scales.xAxes[0].scaleLabel.fontColor = labelColor;
    this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.scales.xAxes[0].ticks.fontColor = labelColor;
    this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.scales.yAxes[0].ticks.fontColor = labelColor;
    this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.legend.labels.fontColor = labelColor;
    if (this.labelsCheckbox){
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.plugins.labels.fontColor = labelColor;
    }
    this.loadElement();
  }

  changeColorType() {
    if (this.overlayJSON.overlays[this.chartcomponent.id - 1].data.colorType === "monochrome") {
      if (this.overlayJSON.overlays[this.chartcomponent.id - 1].chartLibrary === "nvd3") {
        this.updateMonochrome();
      } else if (this.overlayJSON.overlays[this.chartcomponent.id - 1].chartLibrary === "chartjs") {
         this.overlayJSON.overlays[this.chartcomponent.id - 1].data.transformedData.datasets[0].backgroundColor =
         this.overlayJSON.overlays[this.chartcomponent.id - 1].data.transformedData.datasets[0].backgroundColor[0];
        this.loadElement();
      }
    } else if (this.overlayJSON.overlays[this.chartcomponent.id - 1].data.colorType === "custom" && this.customColorList !== "") {
      this.updateCustomColorList();
    } else if (this.overlayJSON.overlays[this.chartcomponent.id - 1].data.colorType === "theme") {
      this.updateColorTheme();
    }
  }

  updateColorTheme() {
    let dataLength: number;
    if (this.overlayJSON.overlays[this.chartcomponent.id - 1].chartData.length > 3) {
      dataLength = this.overlayJSON.overlays[this.chartcomponent.id - 1].chartData.data.length;
    } else {
      dataLength = 3;
    }
    if( this.overlayJSON.overlays[this.chartcomponent.id - 1].chartLibrary === "chartjs" && 
      ['pie', 'doughnut'].includes(this.overlayJSON.overlays[this.chartcomponent.id - 1].data.chartType)){
        this.overlayJSON.overlays[this.chartcomponent.id - 1].transformedData.datasets[0].backgroundColor = 
        this.color_data[this.overlayJSON.overlays[this.chartcomponent.id - 1].data.selectedColorTheme][dataLength].concat();
        this.overlayJSON.overlays[this.chartcomponent.id - 1].chartData.data.forEach(( index:any) => {
          this.overlayJSON.overlays[this.chartcomponent.id - 1].chartData.data[index].lineColor = '#ffffff';
        });
    }
    else{
      this.overlayJSON.overlays[this.chartcomponent.id - 1].chartData.data.forEach((index:any) => {
        this.overlayJSON.overlays[this.chartcomponent.id - 1].chartData.data[index].lineColor = 
        this.color_data[this.overlayJSON.overlays[this.chartcomponent.id - 1].data.selectedColorTheme][dataLength][index].concat();
      });
    }
    this.loadElement(this.overlayJSON.overlays[this.chartcomponent.id - 1]);
  }

  updateCustomColorList() {
    const colorList = this.customColorList.split(",");
    if(this.overlayJSON.overlays[this.chartcomponent.id - 1].chartLibrary === "chartjs" && 
    ['pie', 'doughnut'].includes(this.overlayJSON.overlays[this.chartcomponent.id - 1].data.chartType)){
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.transformedData.datasets[0].backgroundColor = colorList;
      this.overlayJSON.overlays[this.chartcomponent.id - 1].chartData.data.forEach((_element: any,index:any) => {
        this.overlayJSON.overlays[this.chartcomponent.id - 1].chartData.data[index].lineColor = '#ffffff';
      });
    }
    else{
      this.overlayJSON.overlays[this.chartcomponent.id - 1].chartData.data.forEach((_element: any,index:any) => {
        this.overlayJSON.overlays[this.chartcomponent.id - 1].chartData.data[index].lineColor = 
        colorList[index];
      });
    }
    this.loadElement(this.overlayJSON.overlays[this.chartcomponent.id - 1]);
  }

  updateMonochrome() {
    this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.color.splice(1);
    this.loadElement(this.overlayJSON.overlays[this.chartcomponent.id - 1]);
  }

  setBgColor(color:any){
  this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.outlineColor = `${color}`;
  this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.backgroundColor = `${color}`;
  this.loadElement(this.overlayJSON.overlays[this.chartcomponent.id - 1]);
  }

  toTransparent(){
    if(this.overlayJSON.overlays[this.chartcomponent.id - 1].bgToggle===false){
      this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.backgroundColor="transparent";
      this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.outlineColor = "transparent";
      this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.outlineWidth = `0.0em`;
    }
    else{
      this.overlayJSON.overlays[this.chartcomponent.id - 1].bgToggle=true;
      this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.backgroundColor="#000000";
      this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.outlineColor ="#000000";
    }
    this.loadElement(this.overlayJSON.overlays[this.chartcomponent.id - 1]);     
  }

  increaseOutlineSize() {
    const paddingSize: string = this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.outlineWidth;
    this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.outlineWidth = `${+paddingSize.replace("em", "") + 0.01}em`;
    this.loadElement(this.overlayJSON.overlays[this.chartcomponent.id - 1]);
  }

  decreaseOutlineSize() {
    const paddingSize: string = this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.outlineWidth;
    this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.outlineWidth = `${+paddingSize.replace("em", "") - 0.01}em`;
    this.loadElement(this.overlayJSON.overlays[this.chartcomponent.id - 1]);
  }

  updateOutlineSize(number:any){
    this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.outlineWidth = `${number}em`;
    this.loadElement(this.overlayJSON.overlays[this.chartcomponent.id - 1]);
  }

  decreaseBorderRadius() {
    const borderRadius: string = this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.borderRadius;
    this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.borderRadius = `${+borderRadius.replace("em", "") - 0.01}em`;
    this.loadElement(this.overlayJSON.overlays[this.chartcomponent.id - 1]);
  }

  increaseBorderRadius() {
    const borderRadius: string = this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.borderRadius;
    this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.borderRadius = `${+borderRadius.replace("em", "") + 0.01}em`;
    this.loadElement(this.overlayJSON.overlays[this.chartcomponent.id - 1]);
  }

  updateBorderRadius(number:any){
    this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.borderRadius = `${number}em`;
    this.loadElement(this.overlayJSON.overlays[this.chartcomponent.id - 1]);
  }

  changeLabelColorNvd3(inpColor:any) {
    this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.labelColor=inpColor;
    this.loadElement(this.overlayJSON.overlays[this.chartcomponent.id - 1]);
  }

  changeLabelColorChartjs(inpColor:any) {
    this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.scales.yAxes[0].scaleLabel.fontColor=inpColor;
    this.updateLabelColor();
  }

  changeBgColor(inpColor:any) {
    this.overlayJSON.overlays[this.chartcomponent.id - 1].cssProperties.backgroundColor=inpColor;
    this.setBgColor(inpColor);
  }

  changeChartColorNvd3(inpColor:any) {
    this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.color[0]=inpColor;
    this.updateMonochrome();
  }

  changeChartColorChartjs(inpColor:any) {
    this.overlayJSON.overlays[this.chartcomponent.id - 1].transformedData.datasets[0].backgroundColor=inpColor;
    this.loadElement(this.overlayJSON.overlays[this.chartcomponent.id - 1]);
  }

  changeGridLinesColor(axis: string, inpColor: string){
    if(axis ==='xAxes'){
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.scales.xAxes[0].gridLines.color = inpColor;
    }
    else if( axis ==='yAxes') {
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.scales.yAxes[0].gridLines.color = inpColor;
    }
    this.loadElement(this.overlayJSON.overlays[this.chartcomponent.id - 1]);
  }
  
  getSelectedChartColor(){
    let color;
    const chartLibrary=this.overlayJSON.overlays[this.chartcomponent.id - 1].chartLibrary;
    const colorType=this.overlayJSON.overlays[this.chartcomponent.id - 1].data.colorType;
    if(colorType === 'monochrome'){
      const getColorByClass:any = document.getElementsByClassName("monochrome-value");
      if(getColorByClass !== undefined && getColorByClass[0] !== undefined){
      color = chartLibrary === "chartjs" ? getColorByClass[0]["value"] : [getColorByClass[0]["value"]];
      }
    }
    else if(colorType === 'theme'){
      const dataLength=this.overlayJSON.overlays[this.chartcomponent.id - 1].chartData.data.length > 3 
                      ? this.overlayJSON.overlays[this.chartcomponent.id - 1].chartData.data.length : 3
      color=this.color_data[this.overlayJSON.overlays[this.chartcomponent.id - 1].data.selectedColorTheme][dataLength].concat();
    }
    else if (colorType === 'custom'){
      color = this.customColorList.split(",");
    }
    return color;
  }

  toggleLabels() : void {
    if(this.labelsCheckbox) {
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.plugins.labels = {
        render: function (args:any) {
          return args.label + ": " + args.value;
        }
      }
      this.updateLabelColor();
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.plugins.labels.fontSize = 
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.legend.labels.fontSize;
    } else {
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.plugins.labels = false;
      this.labelsOutsideCheckbox = false;
      this.labelSunbeamCheckbox = false;
    }
    this.loadElement();
  }

  pieLabelsPosition() : void {
    if(this.labelsOutsideCheckbox) {
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.plugins.labels.position = "outside";
    } else {
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.plugins.labels.position = "default";
    }
    this.loadElement();
  }

  labelSunbeam(): void{
    if(this.labelSunbeamCheckbox){
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.plugins.labels.arc = true;
    } else {
      this.overlayJSON.overlays[this.chartcomponent.id - 1].data.options.plugins.labels.arc = false;
    }
    this.loadElement();
  }
  
  minimizeToolBar() {
    this.onMinimizing.emit(true);
  }
  setDraggerPosition() {
    this.setDraggerPositionEvent.emit();
  }
  
  // getAnnotationTableId(overlayObj:any) {
  //   for (let index in this.annotationTableData) {
  //     if (this.annotationTableData[index].type === "audio" && overlayObj.type === "audio" && overlayObj.id === this.annotationTableData[index].id) {
  //       return index;
  //     } else if (this.annotationTableData[index].type !== "audio" && overlayObj.type !== "audio" && overlayObj.id === this.annotationTableData[index].id) {
  //       return index;
  //     }
  //   }
  //   return null ;
  // }

  }

