<!------------------------------------------------------------------------------
 # COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
 # Reserved. Infosys believes the information in this document is accurate as of
 # its publication date; such information is subject to change without notice.
 # Infosys acknowledges the proprietary rights of other companies to the
 # trademarks, product names and such other intellectual property rights
 # mentioned in this document. Except as expressly permitted, neither this
 # documentation nor any part of it may be reproduced, stored in a retrieval
 # system, or transmitted in any form or by any means, electronic, mechanical,
 # printing, photocopying, recording or otherwise, without the prior permission
 # of Infosys Limited and/or any named intellectual property rights holders
 # under this document.
-------------------------------------------------------------------------------->
<div class="inner-properties" style="position: inherit;">
  <div (click)="minimizeToolBar()" class="toolbar-minimize" *ngIf="isToolBarOpen">
    <!-- <fa-icon [icon]="faCaretRight"></fa-icon> -->
    <em class="icon-class material-icons">highlight_off</em>
  </div>

  <div>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title class="heading-container">VIDEO PROPS. </mat-panel-title>
      </mat-expansion-panel-header><br>
      <div *ngIf="videoOverlayComponent.type == 'livevideo' ">
        <label class="properties font-size-sm no-margin">Re-Record Video</label>
    <div class="properties font-size-sm file-display">
      <button (click)="reRecord()"> Re-Record </button>
      </div>
      </div>
      
      
      <div *ngIf="videoOverlayComponent.type == 'video' ">
      <label class="properties font-size-sm no-margin">ADD VIDEO </label><br>
      <!-- <div class="properties font-size-sm file-display">
        <input id="videoSrc" accept="video/*" (change)="onFileUpload($event)" type="file" class="file-name">
        {{videoOverlayComponent.videoName == "" ? "No file chosen" : videoOverlayComponent.videoName}}
      </div>
      
      <span class="font-span-sm">OR</span> -->
      <div>
        <button class="from-gallery-button" (click)="openGalleryForVideo()" type="button">Choose From Gallery</button>
      </div>
      <span class="font-span-sm">OR</span>
      <br> <label class="properties font-size-sm">VIDEO PATH &nbsp;</label>
      <input type="text" class="font-size-md" (input)="loadVideoPathtest()" [(ngModel)]="videoSelected"
        id="VideoSelected" placeholder="Enter Video path"><br>

      <label class="properties font-size-sm">LINK &nbsp;</label>
      <input class="font-size-md" type="text" [(ngModel)]="videoOverlayComponent.link"
        placeholder="https://www.google.com" (input)="loadElement(videoOverlayComponent)"><br><br>

      <label class="properties font-size-sm">ALTERNATE TEXT &nbsp;</label>
      <input class="font-size-md" type="text" [(ngModel)]="videoOverlayComponent.altText" placeholder="alt-text"
        (input)="loadElement(videoOverlayComponent)">
      <br>
      </div>
      <div class="properties font-size-sm">
        <mat-checkbox class="check-box " style="padding-right:6px" (ngModelChange)="toggleMaintainAspectRatio()" [ngModel]="videoOverlayComponent.maintainAspectRatio">MAINTAIN ASPECT RATIO</mat-checkbox>
      </div>
      <div class="properties-bar">
        <label class="font-size-sm aspect-width">WIDTH (in em)&nbsp;
          <input #imgWidth [ngModel]="videoOverlayComponent.cssProperties.width | calcFontSize" 
          (ngModelChange)="updateVideoWidth(imgWidth.value)" class="input-time font-size-md" type="number" min="0">
        </label>
        <label class="font-size-sm aspect-width">HEIGHT (in em)&nbsp;
          <input #imgHeight [ngModel]="videoOverlayComponent.cssProperties.height | calcFontSize" 
          (ngModelChange)="updateVideoHeight(imgHeight.value)" class="input-time font-size-md" type="number" min="0">
        </label>
      </div>
     