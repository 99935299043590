<strong style="margin-left: 5%; font-size: 2.3rem;" class="main-headings">Welcome to PSV Home!</strong>
<div class="dropdown">
    <em class='icon-default material-icons-outlined dropbtn'>account_circle</em>
    <div class="dropdown-content">
        <label class="drop-content" (click)="data.keycloak.logout()"> Log Out </label>
    </div>
</div>
<div class="home-flex-container">
    <div class="home-container tile">
        <div style="width: 70%;">
            <div class="sub-container">
            <strong class="main-headings">Hello, <strong>{{userFirstName ? this.userFirstName : this.userLastName}}! </strong> </strong>
            <p class="sub-heading2" style="padding-top: 5px;">Ready to get started on a new PSV? We have
                everything ready for you!</p>
            </div>
            <button class="btns primary-btn" (click)="openDialog()">Create</button>
        </div>
        <img alt="Create" src=".././assets/images/Home page - Create.png">
    </div>
    <div class="analytics-container home-container tile">
        <div style="width: 50%;">
            <div class="sub-container">
                <strong class="main-headings">Analytics</strong>
                <p class="sub-heading2" style="padding-top: 5px;">{{analyticsDescription}}</p>
            </div>
            <button class="sec-btn" routerLink="/insights">View Analytics</button>
        </div>
        <img alt="Analytics" src=".././assets/images/Home page - Analytics.png">
    </div>
    <div class="recentPrj-container tile">
        <strong class="main-headings">Recent Projects</strong>
        <div class="see-all-project">
            <a routerLink="/recent-projects" >See All</a>
        </div>
        <div class="sec-text" *ngIf="recentProjectLoader">Loading Recent PSV...</div>
        <div class="sec-text" *ngIf="isGuest">Login to view your recent PSVs </div>
        <div *ngIf="!recentProjectLoader" style="height: 85%;">
            <div class="sec-text" *ngIf="projects.length == 0 && !isGuest">No PSVs yet</div>
            <div *ngIf="projects.length > 0" class="recentPrjTile-container">
                <div *ngFor="let project of projects;let i=index" style="margin-right: 3%; max-width: 22%;" [@flyInOut]>
                    <div class="recentPrjTile-sub-container tile" *ngIf="i<4" >

                        <mat-icon class="del-template-btn" (click)="deleteConfirmation(i)">delete_outline</mat-icon>
                        <img class="recent-project-thumbnail" alt="Project Thumbnail"
                            src="../../assets/images/thumbnail.png">
                        <div style="padding-left: 6%; padding-top: 1%;">
                            <p class="project-heading sub-heading2">{{projects[i].title}}</p>
                            <p class="date-container sec-headings-icons">{{projects[i].createDate| date:'short'}}</p>
                            <textarea rows="2" disabled="true" class="description-container sub-heading2">{{projects[i].description}}</textarea>
                            <div class="use-template-btn" (click)="loadProject(i)">Open Project</div>
                        </div>
                    </div>
                   
                </div>
                <div class="backdrop" *ngIf="openConfirmationModal">
                    <div class="confirmationmodal" >
                        Are you sure want to delete?
                        <button mat-button type="button" class="yesBtn" (click)="deleteProject()">Yes</button>
                        <button mat-button type="button" class="noBtn" (click)="closeModal()">No</button>
                        <div *ngIf="this.deleteLoader" class="loadScreen">
                            <div id="overlay">
                                <em class="fa fa-spinner fa-spin spin-big"></em>
                            </div>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>