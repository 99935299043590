import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MediaHubComponent } from '../editor/media-hub/media-hub.component';
import { AudioOverlayAnnotation } from '../shared/models/audio-overlay.model';
import { CssProperties } from '../shared/models/css-properties.model';
import { DataService } from '../shared/services/data.service';

@Component({
  selector: 'app-bg-audio-poster',
  templateUrl: './bg-audio-poster.component.html',
  styleUrls: ['./bg-audio-poster.component.css']
})
export class BgAudioPosterComponent implements OnInit {
  audioUrl: any;
  posterUrl: any;
  constructor(private dialog: MatDialog, public data: DataService) { }

  ngOnInit(): void {
    // this.audioUrl = this.data.projectModel.bgAudioUrl ? this.data.projectModel.bgAudioUrl: "";
  }
  bgAudioAndPoster(type: any) {
    let dialogRef = this.dialog.open(MediaHubComponent, { disableClose: true });

    dialogRef.componentInstance.mediaType = type;
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (type == 'audio') {
          this.data.projectModel.bgAudioUrl = result.url;
          const cssprop = new CssProperties
          let bgAudio=new AudioOverlayAnnotation(2, 0.001,this.data.estimatedTime, cssprop);
          bgAudio.srcUrl=result.url;
          this.data.bgAudioObj = bgAudio;
          for(let scene of this.data.scenes){
            scene.videoMeta.backgroundAudio = new AudioOverlayAnnotation(2, 0.001, scene.duration, cssprop);
            scene.videoMeta.backgroundAudio.srcUrl = result.url;
          }
          this.data.removeAllPsv();
          this.data.reloadScenes();
          this.data.setAllPsv();
          // this.data.overlayAnnotations()
          // this.sceneModal.videoMeta.backgroundAudio = aud;
          // console.log(this.sceneModal.videoMeta.backgroundAudio,'sm');
          
        }
        else {
          this.posterUrl = result.url
          this.data.posterUrl = result.url

        }
      }

    })
  }
}
