import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule} from '@angular/material/snack-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Psv2AudioToolbarComponent } from './psv2-audio-toolbar.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatRadioModule} from '@angular/material/radio';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { ChangeTTSDialog } from './change-tts-dialog/change-tts-dialog.component';
import { SanitizeVideoPipe } from './pipes/sanitize-video.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [
    Psv2AudioToolbarComponent,
    ChangeTTSDialog,
    SanitizeVideoPipe
  ],
  imports: [
    MatSnackBarModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    MatInputModule,
    MatExpansionModule,
    MatIconModule,
    MatRadioModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    FontAwesomeModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule
  ],
  exports: [
    Psv2AudioToolbarComponent
  ]
})
export class Psv2AudioToolbarModule { }
