<!------------------------------------------------------------------------------
 # COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
 # Reserved. Infosys believes the information in this document is accurate as of
 # its publication date; such information is subject to change without notice.
 # Infosys acknowledges the proprietary rights of other companies to the
 # trademarks, product names and such other intellectual property rights
 # mentioned in this document. Except as expressly permitted, neither this
 # documentation nor any part of it may be reproduced, stored in a retrieval
 # system, or transmitted in any form or by any means, electronic, mechanical,
 # printing, photocopying, recording or otherwise, without the prior permission
 # of Infosys Limited and/or any named intellectual property rights holders
 # under this document.
-------------------------------------------------------------------------------->
<div class="user-data-dialog">
  <div class="tree-holder">
    <h3>
      Sample User Data
    </h3>

    <mat-tree #userDataMatTree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
        <button mat-icon-button disabled></button>
        <input type="text" class="hide-input-box key" #key1 [ngModel]="node.filename"
          (change)="saveNode(node,key1.value+':'+value1.value)">
        <input type="text" class="hide-input-box value" #value1 [ngModel]="node.type"
          (change)="saveNode(node, key1.value+':'+value1.value)">
        <button mat-icon-button class="fade-in-on-hover" (click)="addNewItem(node)">
          <mat-icon class="psv-user-data-plus-btn">add</mat-icon>
        </button>
        <button mat-icon-button class="fade-in-on-hover" (click)="removeItem(node)">
          <mat-icon class="psv-user-data-close-btn">close</mat-icon>
        </button>
      </mat-tree-node>

      <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding>
        <button mat-icon-button disabled></button>
        <input class="hide-input-box key" #keyInput placeholder="Eg: Name"
          (change)="saveNode(node, (keyInput.value + ':') + (valueInput.value ? valueInput.value : ' '))">
        <input class="hide-input-box value" #valueInput placeholder="Eg: John"
          (change)="saveNode(node, (keyInput.value + ':') + (valueInput.value ? valueInput.value : ' '))">
        <button mat-icon-button class="fade-in-on-hover" (click)="addNewItem(node)">
          <mat-icon class="psv-user-data-plus-btn">add</mat-icon>
        </button>
        <button mat-icon-button class="fade-in-on-hover" (click)="removeItem(node)">
          <mat-icon class="psv-user-data-close-btn">close</mat-icon>
        </button>
      </mat-tree-node>

      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <button mat-icon-button matTreeNodeToggle>
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <div class="user-data-obj" *ngIf="node.filename === 'userData'">{{node.filename}}</div>
        <input *ngIf="node.filename !== 'userData'" type="text" class="hide-input-box key" #key2
          [ngModel]="node.filename" placeholder="Eg: Name" (change)="saveNode(node,key2.value+':'+node.type)"> :
        <button mat-icon-button class="fade-in-on-hover" (click)="addNewItem(node)">
          <mat-icon class="psv-user-data-plus-btn">add</mat-icon>
        </button>
        <button mat-icon-button class="fade-in-on-hover" (click)="removeItem(node)">
          <mat-icon class="psv-user-data-close-btn">close</mat-icon>
        </button>
      </mat-tree-node>
    </mat-tree>
  </div>

  <div class="btn-holder">
    <button class="cancel" mat-button style="margin-right: auto" (click)="uploadFile.click()">
      <i class="material-icons pointer" aria-hidden="true">
        cloud_upload
      </i>
      <label style="margin-right: auto">
        Select file to upload
      </label>
      <div class="upload-file">
        <input #uploadFile style="display:none;" class="custom-file-input" id="userJson" accept="application/json"
          (change)="loadUserJsonFile($event)" type="file">
      </div>
    </button>
    <button mat-button (click)="dismiss()" class="cancel">Cancel</button>
    <button mat-button *ngIf="!uploadedUserData" (click)="updateUserData()" class="submit">Update</button>
    <button mat-button *ngIf="uploadedUserData" (click)="updateUserData(uploadedUserData)"
      class="submit">Update</button>
  </div>
</div>