import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartData } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import overviewchartData from '../../../assets/_files/overviewchart1.json';

interface overviewChart {
  numberOfPSVs: number[];
  chartData: String[];
}

@Component({
  selector: 'app-created-psvs',
  templateUrl: './created-psvs.component.html',
  styleUrls: ['./created-psvs.component.css']
})
export class CreatedPSVsComponent implements OnInit {

  constructor() { }

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  viewDetails: overviewChart = overviewchartData.PSVs

  ngOnInit(): void {

  }
    viewsChartData: ChartData<'line'> = {
      labels: this.viewDetails.chartData,
      datasets: [
        {
          label: '',
          borderColor: "#1971FB",
          pointBackgroundColor: "#1971FB",
          pointRadius: 5,
          data: this.getData()
        },
      ],
    };
    getData() {
      return this.viewDetails.numberOfPSVs
    }

}
