import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LinkAnnotation } from '../models/link-annotation.model';
// import { threadId } from 'worker_threads';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  storageWebServerUrl = environment.storageWebServerUrl;
  storageurl = environment.storageService;
  containerName = environment.azureContainerName;
  progress: any;
  templateVideo!: File;
  psvMetaData!: File;
  projectTitleName: any;
  recorder: any;
  private videoCache: Map<string, any>;
  private imageCache: Map<string, any>;
  private audioCache: Map<string, any>;
  private docCache: Map<string, any>;

  constructor(private http: HttpClient,private sanitizer:DomSanitizer) {
    this.videoCache = new Map<string, any>();
    this.imageCache = new Map<string, any>();
    this.audioCache = new Map<string, any>();
    this.docCache = new Map<string, any>();
   }

  uploadconcatFiles(templateVideoUrl: any, psvMetaDataUrl: any, userData: any, projectId: any, index: any) {
    var formdata = new FormData();
    let psvMetaDataBlob = new Blob([psvMetaDataUrl], { type: "application/js" });
    this.projectTitleName = projectId;
    let stringFile = JSON.stringify(userData);
    let userDataBlob = new Blob([stringFile], { type: "application/json" });
    formdata.append("files", templateVideoUrl, "TemplateVideo.mp4");
    formdata.append("files", psvMetaDataBlob, "PsvMetaData.js");
    formdata.append("files", userDataBlob, "UserData_" + index + ".json");
    formdata.append("projecttitlename", projectId);

    let params = new HttpParams();
    params = params.append('containerName', this.containerName);
    params = params.append('folderName','projects/'+ projectId);
    return new Promise((resolve:any, reject:any) =>{

      this.http.post<any>(this.storageurl + "upload-files", formdata, { params: params}).subscribe((res:any) =>{
        if(res.success){
          resolve();
        }
      },err =>{
        reject(err);
      })
    })
  }

  deleteConcatFiles(projectId:any){
    let params = new HttpParams();
    params = params.append('containerName', this.containerName);
    params = params.append('folderName','projects/'+ projectId);
    params = params.append('fileNames', "TemplateVideo.mp4");
    params = params.append('fileNames', "PsvMetaData.js");

    return new Promise((resolve:any,reject:any) =>{
      this.http.delete<any>(this.storageurl + "delete-files", {params : params}).subscribe((res:any) =>{
        if(res.success){
          resolve(res);
        }
      },err =>{
        reject(err);
      })
    })
  }

  deleteTemplateFiles(templateId:any){
    let params = new HttpParams();
    params = params.append('containerName', this.containerName);
    params = params.append('folderName','templates/'+ templateId);
    params = params.append('fileNames', "concatenatedVideo.mp4");
    params = params.append('fileNames', "PsvMetaData.js");

    return new Promise((resolve:any,reject:any) =>{
      this.http.delete<any>(this.storageurl + "delete-files", {params : params}).subscribe((res:any) =>{
        console.log(res,'res');
        if(res.success){
          resolve(res);
        }
      },err =>{
        console.log(err,'err');
        reject(err);
      })
    })
  }

  uploadMediaFiles(type:any,Blob: any, projectId: any, index: any){
        var formdata = new FormData();
        let fileName : string = "";
        if( type === "video"){
          fileName = "Video"+ index +".mp4";
        }
        else if(type === "audio"){
          fileName = "Audio"+ index +".mp3";
        }
        else if(type === "image"){
          fileName = "Image" + index + ".jpg";
        }
        else if(type === "document"){
          fileName = "Document" + index + ".pdf";
        }
        formdata.append('files',Blob, fileName);
        let params = new HttpParams();
        params = params.append('containerName', this.containerName);
        params = params.append('folderName', 'projects/'+ projectId + '/media'); // media/.mp4
        return new Promise((resolve:any,reject:any) =>{
          this.http.post<any>(this.storageurl + "upload-files", formdata, { params: params}).subscribe((res:any) =>{
              if(res.success){
                //let srcUrl = environment.storageWebServerUrl + environment.minioBucketName + '/projects/'+ projectId +'/media/'+ fileName
                resolve(res.data.url);
              }
              else{
                reject();
                  }
            })
        })
  }

  uploadBgVideo(Blob: any, projectId: any, videoName: string){
    var formdata = new FormData();
    formdata.append('files',Blob, videoName);
    let params = new HttpParams();
    params = params.append('containerName', this.containerName);
    params = params.append('folderName', 'projects/'+ projectId + '/videos'); // media/.mp4
    return new Promise((resolve:any,reject:any) =>{
      this.http.post<any>(this.storageurl + "upload-files", formdata, { params: params}).subscribe((res:any) =>{
          if(res.success){
            //let srcUrl = environment.storageWebServerUrl + environment.minioBucketName + '/projects/'+ projectId +'/videos/'+ videoName;
            resolve(res.data.url);
          }
          else{
            reject();
          }
        })
    })
}

uploadCaptionBlob(Blob: any, projectId: any, captionFileName: string){
  var formdata = new FormData();
  formdata.append('files',Blob, captionFileName);
  let params = new HttpParams();
  params = params.append('containerName', this.containerName);
  params = params.append('folderName', 'projects/'+ projectId + '/captions'); // captions/.vtt
  return new Promise((resolve:any,reject:any) =>{
    this.http.post<any>(this.storageurl + "upload-files", formdata, { params: params}).subscribe((res:any) =>{
        if(res.success){
          // let srcUrl = environment.storageWebServerUrl + environment.minioBucketName + '/projects/'+ projectId +'/captions/'+ captionFileName;
          resolve(res.data.url);
        }
        else{
          reject();
        }
      })
  })
}

  uploadDocumentFiles(Blob: any,fileAnnName:any, projectName: any){
    var formdata = new FormData();
    let url : string;
      url = fileAnnName;
      formdata.append('files',Blob, url);
    let params = new HttpParams();
    params = params.append('containerName', this.containerName);
    params = params.append('folderName', 'projects/'+ projectName + '/documents');
    return new Promise((resolve:any,reject:any) =>{
      this.http.post<any>(this.storageurl + "upload-files", formdata, { params: params}).subscribe((res:any) =>{
          if(res.success){
            //let srcUrl = environment.storageWebServerUrl + environment.minioBucketName + '/projects/'+ projectName +'/documents/'+ url
            resolve(res.data.url);
          }
          else{
            reject();          }
        })
    })
}

  listFiles(folderName:any): Promise<any> {
    let params = new HttpParams();
    params = params.append('containerName', this.containerName);
    params = params.append('folderName', folderName);
    return this.http.get<any>(this.storageurl + "list-files", { params: params }).toPromise()
      .catch(this.handleError)

  }


  uploadFiles(files: any, projectId: any, index: any): Observable<any> {
    var formdata = new FormData();
    let stringFile = JSON.stringify(files);
    let userDataBlob = new Blob([stringFile], { type: "application/json" });
    formdata.append('files', userDataBlob, "UserData_" + index + ".json");
    let params = new HttpParams();
    params = params.append('containerName', this.containerName);
    params = params.append('folderName', 'projects/' + projectId); // media/.mp4
    return this.http.post<any>(this.storageurl + "upload-files", formdata, { params: params, reportProgress: true, observe: "events" }).pipe(
      map((event: any) => {
        if (event.type == HttpEventType.UploadProgress) {
          this.progress = Math.round((100 / event.total) * event.loaded);
        } else if (event.type == HttpEventType.Response) {
          this.progress = null;
        } else {
          this.progress = null;
        }
      }),
      catchError(this.handleError)
    )
  }


  uploadFile(fileBlob:any,fileName:any,folderName:any){
    var formdata = new FormData();
    formdata.append('files',fileBlob, fileName);
    let params = new HttpParams();
    params = params.append('containerName', this.containerName);
    params = params.append('folderName', folderName);
    return new Promise<string>((resolve:any, reject:any) =>{
      this.http.post<any>(this.storageurl + "upload-files", formdata, { params: params}).subscribe(res =>{
        if(res.success){
          // let url = this.storageWebServerUrl + this.bucketname + '/' + folderName + '/' + fileName;
          resolve(res.data.url);
        }else{reject()}
      })
    })
  }


  deleteMediaFiles(projectId: any, fileName: any) {
    let params = new HttpParams();
    params = params.append('containerName', this.containerName);
    params = params.append('folderName', 'projects/' + projectId + '/Media');
    params = params.append('fileNames', fileName);
    return new Promise((resolve:any,reject:any) =>{
      this.http.delete<any>(this.storageurl + "delete-files", { params: params }).subscribe((res:any) =>{
          if(res.success){
            resolve();
          }
          else{
            reject();
          }
        })
    })
  }


  deleteFiles(projectId: any, index: any) {
    let params = new HttpParams();
    params = params.append('containerName', this.containerName);
    params = params.append('folderName', 'projects/' + projectId);
    params = params.append('fileNames', "UserData_" + index + ".json");
    return this.http.delete<any>(this.storageurl + "delete-files", { params: params, reportProgress: true, observe: "events" }).pipe(
      map((event: any) => {
        if (event.type == HttpEventType.UploadProgress) {
          this.progress = Math.round((100 / event.total) * event.loaded);
        } else if (event.type == HttpEventType.Response) {
          this.progress = null;
        } else {
          this.progress = null;
        }
      }),
      catchError(this.handleError)
    )
  }

  deleteProject(projectId: any) {
    let params = new HttpParams();
    params = params.append('containerName', this.containerName);
    params = params.append('folderName', 'projects/' + projectId);
    return new Promise((resolve:any,reject:any) =>{
      this.http.delete<any>(this.storageurl + "delete-folder", { params: params }).subscribe((res:any) =>{
          if(res.success){
              resolve(res);
            }
          }, err =>{
            reject(err);
          })
      }
    )
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `REST API returned code ${error.status}, ` +
        `with message: ${error.error.message}`);
    }
    return throwError('Error occurred. Please try again later.');
  }

  getSampleVideo(filename: string) {
    let params = new HttpParams();
    return this.http.get<any>(this.storageWebServerUrl + filename, { params: params }).pipe(
      catchError(this.handleError)
    );
  }

  getReadOnlyUrl(blobPath: string){
    let params = new HttpParams();
    params = params.append('containerName', this.containerName);
    params = params.append('blobName', blobPath);
    return new Promise((resolve, reject) =>{
      this.http.get<any>(this.storageurl + "get-url", { params: params }).subscribe(res =>{
        if(res.success){
          resolve(res.url)
        }
      }, err =>{
        reject(err);
      })
    })
  }
/**
   *
   * This method will take url as input and
   * if the url exists in cache will return it from there
   * else will get repsonse as blob, that response will be stored in the cache and also send it.
   *
   * @param videoUrl
   * @returns Blob URL
   *
   */
  fetchVideoBlobURL(videoUrl: string): Observable<any> {
    let blobURL:any = this.videoCache.get(videoUrl);
    if (blobURL) return of(blobURL);
    const headers = new HttpHeaders().append("Accept", "*/*");
    return this.http.get(videoUrl, { headers: headers, responseType: 'blob' })
      .pipe(
        catchError(this.handleError),
        map((response) => {

          blobURL = URL.createObjectURL(response);
          blobURL=this.sanitizer.bypassSecurityTrustUrl(blobURL);
          this.videoCache.set(videoUrl, blobURL );
          return blobURL;
        })
      );
  }

  fetchImageBlobURL(imageUrl: string): Observable<any> {
    let blobURL:any = this.imageCache.get(imageUrl);
    if (blobURL) return of(blobURL);
    const headers = new HttpHeaders().append("Accept", "*/*");
    return this.http.get(imageUrl, { headers: headers, responseType: 'blob' })
      .pipe(
        catchError(this.handleError),
        map((response) => {

          blobURL = URL.createObjectURL(response);
          blobURL=this.sanitizer.bypassSecurityTrustUrl(blobURL);
          this.imageCache.set(imageUrl, blobURL );
          return blobURL;
        })
      );
  }
  fetchDocumentBlobURL(docUrl: string): Observable<any> {
    let blobURL:any = this.docCache.get(docUrl);
    if (blobURL) return of(blobURL);
    const headers = new HttpHeaders().append("Accept", "*/*");
    return this.http.get(docUrl, { headers: headers, responseType: 'blob' })
      .pipe(
        catchError(this.handleError),
        map((response) => {

          blobURL = URL.createObjectURL(response);
          blobURL=this.sanitizer.bypassSecurityTrustUrl(blobURL);
          this.docCache.set(docUrl, blobURL );
          return blobURL;
        })
      );
  }

  fetchAudioBlobURL(audioUrl: string): Observable<any> {
    let blobURL:any = this.audioCache.get(audioUrl);
    if (blobURL) return of(blobURL);
    const headers = new HttpHeaders().append("Accept", "*/*");
    return this.http.get(audioUrl, { headers: headers, responseType: 'blob' })
      .pipe(
        catchError(this.handleError),
        map((response) => {

          blobURL = URL.createObjectURL(response);
          blobURL=this.sanitizer.bypassSecurityTrustUrl(blobURL);
          this.audioCache.set(audioUrl, blobURL );
          return blobURL;
        })
      );
  }
}
