/*******************************************************************************
 * COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
 * Reserved. Infosys believes the information in this document is accurate as of
 * its publication date; such information is subject to change without notice.
 * Infosys acknowledges the proprietary rights of other companies to the
 * trademarks, product names and such other intellectual property rights
 * mentioned in this document. Except as expressly permitted, neither this
 * documentation nor any part of it may be reproduced, stored in a retrieval
 * system, or transmitted in any form or by any means, electronic, mechanical,
 * printing, photocopying, recording or otherwise, without the prior permission
 * of Infosys Limited and/or any named intellectual property rights holders
 * under this document.
 ******************************************************************************/
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calcFontSize'
})
export class CalcFontSizePipe implements PipeTransform {
  pattern = /^\d*(\.?\d{0,3})em$/ ;   
  
  transform(value: string, args?: any): any {
    if (value != 'nullem'){
      
      if (!this.pattern.test(value)){
        return parseFloat(value.replace("em","")).toFixed(3);
      }
    }
    return parseFloat(value.replace("em",""));
  }

}
