import { NgModule } from '@angular/core';
import { ChartComponent } from './chart.component';
import { NgChartsModule } from 'ng2-charts';
import { MatExpansionModule } from '@angular/material/expansion';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule} from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { CalcFontSizePipe } from './pipes/calc-font-size/calc-font-size.pipe';
import { colorConvertPipe } from './pipes/color-convert.pipe';
import { DurationFormatPipe } from './pipes/duration-format.pipe';
import { CalcXCoordPipe } from './pipes/calc-x-coordinate/calc-x-coord.pipe';
import { CalcYCoordPipe } from './pipes/calc-y-coordinate/calc-y-coord.pipe';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatTreeModule } from '@angular/material/tree';
import { AngularDraggableModule } from 'angular2-draggable';
import { ResizableModule } from 'angular-resizable-element';
import { OrderModule } from 'ngx-order-pipe';
import { HttpClientModule } from '@angular/common/http';
import { ChartInputModalComponent } from './chart-input-modal.component';

@NgModule({
  declarations: [
    ChartComponent,
    CalcFontSizePipe,
      colorConvertPipe,
      DurationFormatPipe,
      CalcXCoordPipe,
      CalcYCoordPipe,
      ChartInputModalComponent
      
  ],
  imports: [

    CommonModule,
    MatIconModule,
    FontAwesomeModule,
    MatButtonModule,
    MatInputModule,
    MatTooltipModule,
    MatTableModule,
    MatSliderModule,
    AngularDraggableModule,
    ResizableModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTreeModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatExpansionModule,
    OrderModule,
    HttpClientModule,
    MatProgressBarModule,
   MatIconModule,
   ReactiveFormsModule,
   MatExpansionModule,
  MatSlideToggleModule,
  FormsModule,
  ],
  exports: [
    ChartComponent,
    // ChartInputModalComponent
    // NgChartsModule,
    // MatExpansionModule,
    // FontAwesomeModule,
    // MatFormFieldModule,
    // MatInputModule,
    // MatSliderModule,
    // MatButtonModule,
    // MatIconModule,
    // MatSelectModule,
    // MatSlideToggleModule,
    // MatCheckboxModule,
    // MatSnackBarModule,
    // FormsModule,
    

  ]
})
export class ChartModule { }
