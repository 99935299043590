import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Psv2AudioToolbarService } from './psv2-audio-toolbar.service';
import { faVolumeUp, faCog, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { MatAccordion } from '@angular/material/expansion';
import { ViewChild } from '@angular/core';
import { OutputProperties } from './outputs';
import { MatDialog } from '@angular/material/dialog';
import { ChangeTTSDialog } from './change-tts-dialog/change-tts-dialog.component';

@Component({
  selector: 'psv2-audio-toolbar',
  templateUrl: 'psv2-audio-toolbar.html',
  styleUrls: ['./psv2-audio-toolbar.css']
})
export class Psv2AudioToolbarComponent implements OnInit {

  @ViewChild('input') private input:any;
  @ViewChild(MatAccordion) accordion!: MatAccordion;

  constructor(readonly data: Psv2AudioToolbarService, public dialog:MatDialog) { }

  @Input() tts:any;
  @Input() isToolBarOpen!:boolean;
  @Input() overlayJSON:any;
  @Input() annotationTableData:any;
  @Input() videoDuration:any;
  @Input() newAudioComponent:any;
  @Input() selectedScene:any;
  @Input() updateAudio!:boolean;
  @Input() ttsProviderOptions:any;
  @Input() ttsOptions:any;
  @Input() isTtsProviderChanged!:boolean;
  @Input() isLoaderEnabled!:boolean;

  @Output() updateCurrentTimeEvent = new EventEmitter<number>();
  @Output() notifyDurationChangeEvent = new EventEmitter<string>();
  @Output() overlayAnnotationsEvent = new EventEmitter<any>();
  @Output() onMinimizing = new EventEmitter<boolean>();
  @Output() outputProperties = new EventEmitter<any>();
  @Output() notifyTTSUpdateEvent = new EventEmitter<string>();

  @Output() overlayJSONOutput = new EventEmitter<any>();
  @Output() annotationTableDataOutput = new EventEmitter<any>();
  @Output() selectedSceneOutput = new EventEmitter<any>();
  @Output() updateAudioOutput = new EventEmitter<boolean>();

  faCaretRight:any = faCaretRight;
  faVolumeUp: any = faVolumeUp;
  faCog:any = faCog;
  time: number = 1;
  selectedStrength: string='medium';
  selectedLevel: string='moderate';
  prosodyRate: string='medium';
  prosodyVolume: string='default';
  prosodyPitch: string='default';
  subAlias:string="";
  sayInterpret: string='number';
  defaultProsodyRate = 'medium';
  defaultProsodyVolume = 'default';
  defaultProsodyPitch = 'default';
  defaultStrength = 'medium';
  defaultLevel = 'moderate';
  defaultInterpret = 'number';
  prosodyRateList:string[] = ['x-slow','slow','medium', 'fast','x-fast','%'];
  prosodyVolumeList:string[] = ['default','silent','x-soft','soft','medium','loud','x-loud','+ndB','-ndB'];
  prosodyPitchList:string[] = ['default','x-low','low','medium','high','x-high','+%','-%'];
  strengthList = ['none','weak', 'x-weak', 'medium', 'strong','x-strong'];
  levelList = [ 'strong','moderate','reduced'];
  interpretList=['number','ordinal','digits','fraction','unit','date','time','address','expletive','telephone'];
  @Input()showSSMLOptions!:boolean;
  ssmlTags = [{
    name: "break",
    options: ["time", "strength"]
  },{
    name: "say-as",
    options: ["types"]
  },{
    name: "sub",
    options: ["alias"]
  },{
    name: "prosody",
    options: ["rate", "pitch", "volume"]
  },{
    name: "emphasis",
    options: ["level"]
  }]
  
  


  ngOnInit() {
    this.data.tts = this.tts;
    this.data.overlayJSON = this.overlayJSON;
    this.data.updateAudio = this.updateAudio;
    this.data.isLoaderEnabled = this.isLoaderEnabled;
    this.data.isTtsProviderChanged = this.isTtsProviderChanged;
    this.data.ttsOptions = this.ttsOptions;
    this.data.ttsProviderOptions = this.ttsProviderOptions;
    // this.updateDefaultEntries();
  }

  ngAfterViewInit() {
    // this.data.notifyTTSUpdateObservable.subscribe(
    //   tts => {
    //     // this.updateDefaultEntries();
    //   }
    // );
  }

  // updateDefaultEntries() {
  //   if (this.data.tts === "polly") {
  //     this.selectedLanguage = "English - US (en-US)";
  //     this.data.getPollyVoicesList()
  //       .subscribe(voicesList => {
  //         this.voicesList = voicesList;
  //         this.languagesList = Object.keys(this.voicesList);
  //         for (let index in this.languagesList) {
  //           if (this.voicesList[this.languagesList[index]].includes(this.data.selectedAnnotation.params.VoiceId)) {
  //             this.selectedLanguage = this.languagesList[index];
  //             this.data.overlayJSON.audios[this.newAudioComponent.id - 1].params.VoiceId = this.data.selectedAnnotation.params.VoiceId;
  //           }
  //         }
  //       });
  //   } else if (this.data.tts === "google") {
  //     this.selectedLanguage = "English (US)";
  //     this.data.getGoogleVoicesList()
  //       .subscribe(voicesList => {
  //         this.voicesList = voicesList;
  //         this.ttsTypeList = Object.keys(this.voicesList);
  //         this.selectedTTSType = this.data.selectedAnnotation.params.voice.name.split("-")[2];
  //         this.languagesList = Object.keys(this.voicesList[this.selectedTTSType]);
  //         for (let index in this.languagesList) {
  //           if (this.voicesList[this.selectedTTSType][this.languagesList[index]].voiceName.includes(this.data.selectedAnnotation.params.voice.name)) {
  //             this.selectedLanguage = this.languagesList[index];
  //             this.data.overlayJSON.audios[this.newAudioComponent.id - 1].params.voice.name = this.data.selectedAnnotation.params.voice.name;
  //             this.voicesOptions = this.voicesList[this.selectedTTSType][this.selectedLanguage].voiceName;
  //           }
  //         }
  //       });
  //   } else if (this.data.tts === "azure") {
  //     this.selectedLanguage = "English (US)";
  //     this.data.getAzureVoicesList()
  //       .subscribe(voicesList => {
  //         this.voicesList = voicesList;
  //         this.ttsTypeList = Object.keys(this.voicesList);
  //         this.selectedTTSType = this.data.selectedAnnotation.params.voiceId.indexOf("Neural") > -1 ? "Neural" : "Standard";
  //         this.languagesList = Object.keys(this.voicesList[this.selectedTTSType]);
  //         for (let index in this.languagesList) {
  //           if (this.voicesList[this.selectedTTSType][this.languagesList[index]].voiceName.includes(this.data.selectedAnnotation.params.voiceId)) {
  //             this.selectedLanguage = this.languagesList[index];
  //             this.data.overlayJSON.audios[this.newAudioComponent.id - 1].params.voiceId = this.data.selectedAnnotation.params.voiceId;
  //             this.voicesOptions = this.voicesList[this.selectedTTSType][this.selectedLanguage].voiceName;
  //           }
  //         }
  //       });
  //   }
  // }


  changeStartTime(startTime:any, endTime:any) {
    startTime = parseFloat(startTime)
    endTime = parseFloat(endTime)
    if (endTime > startTime) {
      if (this.data.overlayJSON.audios[this.newAudioComponent.id - 1].start) {
        this.updateCurrentTime(this.data.overlayJSON.audios[this.newAudioComponent.id - 1].start);
        this.loadElement();
        this.notifyDurationChange("notified");
      }
    }
    else {
      let classList = ["error-snackbar"];
      this.data.showSnackBar("`START TIME` time must be less than the `END TIME` time.", classList);
      return;
    }
  }

  changeEndTime(startTime:any, endTime:any) {
    startTime = parseFloat(startTime)
    endTime = parseFloat(endTime)
    if (endTime > this.videoDuration) {
      let classList = ["error-snackbar"];
      this.data.showSnackBar("`END TIME` time must be less than the `Video Duration` time.", classList);
      return;
    }

    if (endTime > startTime) {
      this.loadElement();
      this.notifyDurationChange("notified");
    }
    else {
      let classList = ["error-snackbar"];
      this.data.showSnackBar("`END TIME` time must be greater than the `START TIME` time.", classList);
      return;
    }
  }

  setStartTimeMinVal() {
    let startTime = parseFloat(this.data.overlayJSON.audios[this.newAudioComponent.id - 1].start)
    if (startTime === 0) {
      this.data.overlayJSON.audios[this.newAudioComponent.id - 1].start = 0.001;
      this.overlayJSONOutput.emit(this.data.overlayJSON);
      this.loadElement();
      this.notifyDurationChange("notified");
    }
  }

  minimizeToolBar() {
    this.emitOutputproperties();
    this.onMinimizing.emit(true);
  }

  emitOutputproperties(){
    let outputPropertiesObj = new OutputProperties(this.data.tts,this.isToolBarOpen,this.data.overlayJSON,this.annotationTableData,this.videoDuration,this.data.updateAudio,this.data.ttsProviderOptions,this.data.ttsOptions,this.data.isTtsProviderChanged,this.data.isLoaderEnabled);
    this.outputProperties.emit(outputPropertiesObj);
  }

  openChangeTTSDialog(): void {
    let dialogRef = this.dialog.open(ChangeTTSDialog, {
      data: {}
    });

    dialogRef.afterClosed().subscribe(result =>{
      this.notifyTTSUpdate("Updated tts");
      this.emitOutputproperties();
      this.overlayAnnotations();
    })
  }

  loadElement() {
    this.data.updateAudio = true;
    this.updateAudioOutput.emit(true);
    this.overlayAnnotations();
    //this.data.addAudioAnnotations();
  }

  changeAudioText(value:any) {
    if(this.data.tts === "polly") {
      // this.selectedScene.script = value;
      // this.selectedSceneOutput.emit(this.selectedScene);
      this.data.overlayJSON.audios[this.newAudioComponent.id - 1].params.Text = value;
      this.annotationTableData[this.getAnnotationTableId(this.newAudioComponent)].value = this.data.overlayJSON.audios[this.newAudioComponent.id - 1].params.Text;
    } else if(this.data.tts === "google") {
      this.selectedScene.script = value;
      this.selectedSceneOutput.emit(this.selectedScene);
      this.data.overlayJSON.audios[this.newAudioComponent.id - 1].params.input.ssml = this.selectedScene.script;
      this.annotationTableData[this.getAnnotationTableId(this.newAudioComponent)].value = this.data.overlayJSON.audios[this.newAudioComponent.id - 1].params.input.ssml;
    } else if(this.data.tts === "azure") {
      this.selectedScene.script = value;
      this.selectedSceneOutput.emit(this.selectedScene);
      this.data.overlayJSON.audios[this.newAudioComponent.id - 1].params.Text = this.selectedScene.script;
      this.annotationTableData[this.getAnnotationTableId(this.newAudioComponent)].value = this.data.overlayJSON.audios[this.newAudioComponent.id - 1].params.Text;
    }
    this.overlayJSONOutput.emit(this.data.overlayJSON);
    this.annotationTableDataOutput.emit(this.annotationTableData);
    this.loadElement();
  }


  addBreakSsml(){
    this.input.nativeElement.focus();
    let startPos = this.input.nativeElement.selectionStart;
    let value = this.input.nativeElement.value;
    this.input.nativeElement.value =
       value.substring(0, startPos) + "<break strength='" + (this.selectedStrength ? this.selectedStrength : 'medium') + "' time='" + (this.time ? this.time : 1) + "s'/>" + value.substring(startPos, value.length);
    this.changeAudioText(this.input.nativeElement.value);
  }

  addEmphasisLevel(){
    this.input.nativeElement.focus();
    let startPos = this.input.nativeElement.selectionStart;
    let value = this.input.nativeElement.value;
    this.input.nativeElement.value =
      value.substring(0, startPos) + "<emphasis level='"+ (this.selectedLevel ? this.selectedLevel : 'moderate') +"'>"+'  '+'</emphasis>'+ value.substring(startPos, value.length);
    this.changeAudioText(this.input.nativeElement.value);
  }
  
  addProsodySsml(){
    this.input.nativeElement.focus();
    let startPos = this.input.nativeElement.selectionStart;
    let value = this.input.nativeElement.value;
    this.input.nativeElement.value =
       value.substring(0, startPos) + "<prosody volume='" + (this.prosodyVolume ? this.prosodyVolume : 'medium') + "' pitch='" + (this.prosodyPitch ? this.prosodyPitch : 'medium') + "' rate='" + (this.prosodyRate ? this.prosodyRate : 'medium') + "'>" + '</prosody>' + value.substring(startPos, value.length);
    this.changeAudioText(this.input.nativeElement.value);
  }
  
  addSubAlias(){
    this.input.nativeElement.focus();
    let startPos = this.input.nativeElement.selectionStart;
    let value = this.input.nativeElement.value;
    this.input.nativeElement.value =
       value.substring(0, startPos) + "<sub alias='" + this.subAlias + "'>" + '</sub>' + value.substring(startPos, value.length)
    this.changeAudioText(this.input.nativeElement.value);
  }
  
  addSayInterpret(){
    this.input.nativeElement.focus();
    let startPos = this.input.nativeElement.selectionStart;
    let value = this.input.nativeElement.value;
    this.input.nativeElement.value =
       value.substring(0, startPos) + "<say-as interpret-as='" + (this.sayInterpret ? this.sayInterpret : 'number') + "'>" + '</say-as>' + value.substring(startPos, value.length)
    this.changeAudioText(this.input.nativeElement.value);
  }

  getAnnotationTableId(overlayObj:any):string {
    for (let index in this.annotationTableData) {
      if (this.annotationTableData[index].type === "tts" && overlayObj.type === "tts" && overlayObj.id === this.annotationTableData[index].id) {
        return index;
      } else if (this.annotationTableData[index].type !== "tts" && overlayObj.type !== "tts" && overlayObj.id === this.annotationTableData[index].id) {
        return index;
      }
    }
    return '';
  }

  updateCurrentTime(currentTime: number) {
    this.updateCurrentTimeEvent.emit(currentTime);
  }

  notifyDurationChange(message:string){
    this.notifyDurationChangeEvent.emit(message);
  }

  overlayAnnotations(updateAnnotation?:any){
    this.overlayAnnotationsEvent.emit(updateAnnotation);
  }

  notifyTTSUpdate(message: string) {
    this.notifyDurationChangeEvent.emit(message);
  }

}
