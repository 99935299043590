import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Pipe({
  name: 'sanitizeVideo'
})
export class SanitizeVideoPipe implements PipeTransform {
  
  constructor(readonly sanitizer: DomSanitizer){

  }

  transform(value: any, args?: any): any {
    return this.sanitizer.bypassSecurityTrustUrl(value);
  }
}
