/*******************************************************************************
 * COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
 * Reserved. Infosys believes the information in this document is accurate as of
 * its publication date; such information is subject to change without notice.
 * Infosys acknowledges the proprietary rights of other companies to the
 * trademarks, product names and such other intellectual property rights
 * mentioned in this document. Except as expressly permitted, neither this
 * documentation nor any part of it may be reproduced, stored in a retrieval
 * system, or transmitted in any form or by any means, electronic, mechanical,
 * printing, photocopying, recording or otherwise, without the prior permission
 * of Infosys Limited and/or any named intellectual property rights holders
 * under this document.
 ******************************************************************************/
 import { CssProperties } from './css-properties.model';
 export class CaptionAnnotation {
     
     isDurationChanged:boolean = false;
     type!: string;
     value!: string;
     id: number;
     start: number;
     end: number;
     cssProperties!: CssProperties;
     Start: any  = { min: "00", sec: "00", msec: "001", valid: true };
     End: any  = { min: "00", sec: "06", msec: "069", valid: true };
     size: any  = { value: 70, valid: true };
     caption_text: any  = "Demo Caption" ;    
     duration:any;
 
     constructor(id:number, currentTime:number, videoDuration: number, cssProps: CssProperties){
         this.id = id;
         this.end = videoDuration;
         this.cssProperties = cssProps;
         this.start = currentTime;
         this.duration = this.end - this.start;
         this.initializeAttributes();
     }
 
     initializeAttributes(){
         this.type = 'caption';
         this.value = 'Demo Caption';
         this.cssProperties.left="0.9%";
         this.cssProperties.paddingTop="15%";
     }
 
     getAttributes(){
         let attributes: any;
             attributes = {
                 type: this.type,
                 value: this.value,
                 id: this.id,
                 start: this.start,
                 end: this.end,
                 "css-properties": this.cssProperties.getTextAttributes()
             };
          
         return attributes;
     }
 
     transformAttributes(overlayObject:any, cssProps: CssProperties){
         const captionObject = new CaptionAnnotation(overlayObject.id,overlayObject.start, overlayObject.end, cssProps);
         captionObject.value = overlayObject.value;
         captionObject.start = overlayObject.start;
         return captionObject;
     }
 
 }
 