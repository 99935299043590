/*******************************************************************************
 * COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
 * Reserved. Infosys believes the information in this document is accurate as of
 * its publication date; such information is subject to change without notice.
 * Infosys acknowledges the proprietary rights of other companies to the
 * trademarks, product names and such other intellectual property rights
 * mentioned in this document. Except as expressly permitted, neither this
 * documentation nor any part of it may be reproduced, stored in a retrieval
 * system, or transmitted in any form or by any means, electronic, mechanical,
 * printing, photocopying, recording or otherwise, without the prior permission
 * of Infosys Limited and/or any named intellectual property rights holders
 * under this document.
 ******************************************************************************/
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, Injector, CUSTOM_ELEMENTS_SCHEMA, ApplicationRef } from '@angular/core';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DOCUMENT } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { EditorComponent } from './editor/editor.component';
import { PublishComponent } from './publish/publish.component';
import { LoginComponent } from './login/login.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { HttpClientModule } from '@angular/common/http';
import { CalcXCoordPipe } from './shared/pipes/calc-x-coordinate/calc-x-coord.pipe';
import { CalcYCoordPipe } from './shared/pipes/calc-y-coordinate/calc-y-coord.pipe';
import { CalcRotationPipe } from './shared/pipes/calc-rotation/calc-rotation.pipe';

import { environment } from '../environments/environment'
import { DataService } from './shared/services/data.service';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatSliderModule } from '@angular/material/slider';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatTreeModule } from '@angular/material/tree';
import { OrderModule } from 'ngx-order-pipe';
import { DurationFormatPipe } from './shared/pipes/duration-format.pipe';
import { SanitizeVideoPipe } from './shared/pipes/sanitize-video.pipe';
import { DurationPipe } from './shared/pipes/duration.pipe';
import { DatePipe } from '@angular/common';
import { AngularDraggableModule } from 'angular2-draggable';
import { InputtextComponent } from './editor/inputtext/inputtext.component';
import { ErrorComponent } from './shared/error/error.component';
import { ScenesComponent } from './editor/scenes/scenes.component';
import { ScriptContentComponent } from './editor/script-content/script-content.component';
import { Psv2TimelineComponent } from './editor/timeline/timeline.component';
import { Psv2TimestampComponent } from './editor/timeline/timestamp/timestamp.component';
import { SyncScrollDirective } from './editor/timeline/directives/synchronize-scroll/sync-scroll.directive';
import { PositionTimeStampDirective } from './editor/timeline/directives/position-time-stamp/position-time-stamp.directive';
import { SeekVideoDirective } from './editor/timeline/directives/seekVideo/seek-video.directive';
import { UserdataComponent } from './editor/userdata/userdata.component';
import { PreviewSceneComponent } from './editor/preview-scene/preview-scene.component';
import { UserDataModule } from 'projects/user-data/src/public-api';
import { PSVCommonPropertiesModule } from 'projects/psv-common-toolbar/src/public-api';
import { NavbarComponent } from './navbar/navbar.component';
import { ToolbarComponent } from './editor/toolbar/toolbar.component';
import { OverlayTextModule } from 'projects/overlay-text/src/public-api';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PublishUserdataComponent } from './publish/publish-userdata/publish-userdata.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { PsvVideoPlayerComponent } from './publish/psv-video-player/psv-video-player.component';
import { ImageOverlayModule } from 'projects/image-overlay/src/lib/image-overlay.module';
import { CalculateWidthDirective } from './editor/timeline/directives/calcWidth/calculate-width.directive';
import { Psv2AudioToolbarModule } from 'projects/psv2-audio-toolbar/src/public-api';
import { StorageService } from './shared/services/storage.service';
import { WebpageModule } from 'projects/webpage/src/public-api';
import { CalcFontSizePipe } from './shared/pipes/calc-font-size/calc-font-size.pipe';
import { colorConvertPipe } from './shared/pipes/color-convert.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DisplayScriptPipe } from './shared/pipes/display-script/display-script.pipe';
import { CallToActionModule } from 'projects/call-to-action/src/src';
import { VideoOverlayModule } from 'projects/video-overlay/src/lib/video-overlay.module';
import { FileToolbarModule } from 'projects/file-toolbar/src/public-api';
import { FormatScriptPipe } from './shared/pipes/format-script/format-script.pipe';
import { HttpErrorInterceptor } from './shared/models/http-error.interceptor';
import { BgVideosComponent } from 'src/app/bg-videos/bg-videos.component';
import { NewProjectUploaderComponent } from './home/new-project-uploader/new-project-uploader.component';
import { RecentProjectPageComponent } from './home/recent-project-page/recent-project-page.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AudioOverlayModule } from 'projects/audio-overlay/src/projects';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { LiveVideoOverlayModule } from 'projects/live-video-overlay/src/public-api';
import { LiveRecordingModule } from 'projects/live-recording/src/projects';
import { MediaDialogComponent } from './editor/media-dialog/media-dialog.component';
import { InsightsComponent } from './insights/insights.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { OverviewComponent } from './insights/overview/overview.component';
import { EngagementComponent } from './insights/engagement/engagement.component';
import { ReviewsComponent } from './insights/reviews/reviews.component';
import { MatCardModule } from '@angular/material/card';
import { NgChartsModule } from 'ng2-charts';
import { AnnotationGalleryComponent } from './editor/annotation-gallery/annotation-gallery.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { CreatedPSVsComponent } from './insights/created-psvs/created-psvs.component';
import { HelpPageComponent } from './help-page/help-page.component';
import { BackgroundAudioComponent } from './background-audio/background-audio.component';
import { EmbedPsvComponent } from './publish/embed-psv/embed-psv.component';
import { EditorModalComponent } from './bg-videos/editor-modal/editor-modal.component';
import { ExportJsonDialog } from './export-json/export-json.component';
import { CaptionComponent } from 'projects/caption/caption.component';
import { ThumbnailComponent } from './editor/thumbnail/thumbnail.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ChartModule } from 'projects/chart/src/projects';
import { ApiErrorHandlerComponent } from '../app/publish/api-error-handler/api-error-handler.component';
import { MediaHubComponent } from './editor/media-hub/media-hub.component';
import { GalleryViewComponent } from './editor/media-hub/gallery-view/gallery-view.component';
import { AnalyticsComponent } from './home/recent-project-page/analytics/analytics.component';

import { PreviewModalComponent } from './editor/template/preview-modal/preview-modal.component';
import { TemplateComponent } from './editor/template/template.component';
import { TemplateModalComponent } from './editor/template/template-modal/template-modal.component';
import { TemplateStatusModalComponent } from './editor/template/template-status-modal/template-status-modal.component';
import { BgAudioPosterComponent } from './bg-audio-poster/bg-audio-poster.component';
import { QRCodeModule } from 'angularx-qrcode';

import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
import { QrModalComponent } from './publish/qr-modal/qr-modal.component';
import { TemplatePublishedLinksComponent } from './template-published-links/template-published-links.component';

function initializeKeycloak(keycloak: KeycloakService) {
  return (): Promise<any> => keycloak.init({
    config: {
      url: environment.keycloakUrl,
      realm: 'psv-realm',
      clientId: environment.clientId
    },
    initOptions: {
      onLoad: 'login-required'
    }
  });
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PublishComponent,
    LoginComponent,
    EditorComponent,
    InputtextComponent,
    ErrorComponent,
    ScenesComponent,
    ScriptContentComponent,
    DurationPipe,
    DurationFormatPipe,
    Psv2TimelineComponent,
    Psv2TimestampComponent,
    PositionTimeStampDirective,
    SyncScrollDirective,
    CalculateWidthDirective,
    SeekVideoDirective,
    SanitizeVideoPipe,
    ToolbarComponent,
    UserdataComponent,
    NavbarComponent,
    CalcFontSizePipe,
    colorConvertPipe,
    PublishUserdataComponent,
    PsvVideoPlayerComponent,
    PreviewSceneComponent,
    CalcXCoordPipe,
    CalcYCoordPipe,
    CalcRotationPipe,
    // CalcFontSizePipe,
    // colorConvertPipe
    DisplayScriptPipe,
    FormatScriptPipe,
    BgVideosComponent,
    NewProjectUploaderComponent,
    RecentProjectPageComponent,
    MediaDialogComponent,
    AnnotationGalleryComponent,
    BreadcrumbComponent,
    InsightsComponent,
    OverviewComponent,
    EngagementComponent,
    ReviewsComponent,
    CreatedPSVsComponent,
    HelpPageComponent,
    BackgroundAudioComponent,




    ThumbnailComponent,
    BackgroundAudioComponent,
    EmbedPsvComponent,
    EditorModalComponent,
    ExportJsonDialog,
    CaptionComponent,
    ApiErrorHandlerComponent,
    MediaHubComponent,
    GalleryViewComponent,
    AnalyticsComponent,
    TemplateComponent,
    TemplateModalComponent,
    PreviewModalComponent,
    TemplateStatusModalComponent,
    BgAudioPosterComponent,
    QrModalComponent,
    TemplatePublishedLinksComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    AudioOverlayModule,
    LiveRecordingModule,
    MatIconModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatExpansionModule,
    MatSlideToggleModule,
    ImageOverlayModule,
    VideoOverlayModule,
    UserDataModule,
    HttpClientModule,
    MatIconModule,
    FontAwesomeModule,
    MatTableModule,
    NgChartsModule,
    MatMenuModule,
    MatCardModule,
    AngularDraggableModule,
    MatIconModule,
    MatSnackBarModule,
    Psv2AudioToolbarModule,
    FormsModule,
    PSVCommonPropertiesModule,
    ReactiveFormsModule,
    BrowserModule,
    FlexLayoutModule,
    MatButtonModule,
    ClipboardModule,
    MatGridListModule,
    MatDialogModule,
    LiveVideoOverlayModule,
    ChartModule,

    MatProgressSpinnerModule,
    CallToActionModule,
    // chartModule,
    MatInputModule,
    FontAwesomeModule,
    MatTooltipModule,
    MatSliderModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    OverlayTextModule,
    MatProgressSpinnerModule,
    OrderModule,
    MatTreeModule,
    BrowserModule,
    WebpageModule,
    FileToolbarModule,
    MatDialogModule,
    DragDropModule,
    MatDatepickerModule,
    MatNativeDateModule,
    KeycloakAngularModule,
    NgxSliderModule,
    QRCodeModule
    ,
    MsalModule.forRoot( new PublicClientApplication({
      auth: {
        clientId: environment.clientId, // Application (client) ID from the app registration
        authority: environment.authority, // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
        redirectUri: environment.redirectUri// This is your redirect URI
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
      }
    }), {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['user.read']
      }
    }, {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: new Map([
        ['https://graph.microsoft.com/v1.0/me', ['user.read']]
      ])
    })
  ],
  providers: [DataService,
    StorageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalGuard
    //  {
    //    provide: APP_INITIALIZER,
    //    useFactory: initializeKeycloak,
    //    multi: true,
    //    deps: [KeycloakService]
    //  }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
