/*******************************************************************************
 * COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
 * Reserved. Infosys believes the information in this document is accurate as of
 * its publication date; such information is subject to change without notice.
 * Infosys acknowledges the proprietary rights of other companies to the
 * trademarks, product names and such other intellectual property rights
 * mentioned in this document. Except as expressly permitted, neither this
 * documentation nor any part of it may be reproduced, stored in a retrieval
 * system, or transmitted in any form or by any means, electronic, mechanical,
 * printing, photocopying, recording or otherwise, without the prior permission
 * of Infosys Limited and/or any named intellectual property rights holders
 * under this document.
 ******************************************************************************/
export class CssProperties {
    fontSize!: string;
    fontFamily!: string;
    fontWeight!: string;
    textAlign!: string;
    transform!: string;
    left!: string;
    color!: string;
    animation!: string;
    opacity!: number;
    rotation!: string;
    backgroundColor!: string;
    buttonBgColor!: string;
    width!: string;
    height!: string;
    border!: string;
    borderRadius!: string;
    paddingTop!: string;
    padding!: string;
    buttonPadding!: string;
    outlineColor!: string;
    outlineWidth!: string;
    fontStyle!: string;
    textDecoration!: string;
    boxShadow!: string;
    animationDuration!: string;
    progressbarWidth!: string;
    progressbarHeight!: string;
    progressbgColor!: string;
    progressbarFontSize!: string;
    progressbarColor!: string;
    progressAnimationDuration!: number;
    textWidth!: string;
    textHeight!: string;
    webFileHeight!:string;
    webFileWidth!:string;
    overflow!: string;
    constructor(type?:any) {

        this.initializeAttributes(type);
    }

    initializeAttributes(type?:any) {
        this.fontSize = type ==='link' ? "0.8em" : type ==='chart'? "0.3em" : "1em" ;
        this.fontFamily = "Montserrat";
        this.fontWeight = "Normal";
        this.textAlign = type ==='link' ? "center" : "left";
        this.transform = "translate3d(0px, 0px, 0px)";
        this.left = type ==='text'?"0.9%":type ==='chart'? "5%" : "15%" ;
        this.color = type ==='link' ? "#ffffff" : "#0073af";
        this.animation = "";
        this.opacity = 1;
        this.rotation = "rotate(0deg)";
        this.backgroundColor = "transparent";
        this.buttonBgColor= type ==='link' ? "#1971FB" : "#ffffff";
        this.width = type ==='chart' ? "13em" : "5em";
        this.height = type ==='chart' ? "6em" : "3em";
        this.webFileHeight="100%"
        this.webFileWidth="100%"
        this.border = "0em";
        this.borderRadius = type ==='link' ? "0.5em" : "0em";
        this.paddingTop = type ==='chart'? "5%" : "15%" ;
        this.padding="0.0em";
        this.buttonPadding= type ==='link' ? "0.5em 1em" : "0.0em";
        this.border= type ==='link' ? "0em" : "0.5em";
        this.outlineColor="transparent";
        this.outlineWidth="0.0em";
        this.fontStyle="Normal";
        this.textDecoration="None";
        this.boxShadow = type === 'link' ? "0px 0px 6px 0px rgb(24 111 247 / 55%)" : "0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08)";
        this.progressbarWidth="12em";
        this.progressbarHeight="2em";
        this.progressbgColor="#87d540";
        this.progressbarColor="#ffffff";
        this.progressbarFontSize="0.5em";
        this.progressAnimationDuration= 4000;
        this.textWidth = "fit-content";
        this.textHeight = "2.2em";
        this.overflow  = "visible";
    }

    getTextAttributes() {
        return ([
            {
                left: this.left,
                "padding-top": this.paddingTop,
                position:"relative",
                "text-align": this.textAlign,
                "display": "block",
                width: this.textWidth,
                overflow: this.overflow
            },
            {
                "white-space": "normal",
                "font-size": this.fontSize,
                "font-family": this.fontFamily,
                "font-weight": this.fontWeight,
                "font-style":this.fontStyle,
                "padding":this.padding,
                "border-radius":this.borderRadius,
                "background-color":this.backgroundColor,
                transform: `${this.transform} ${this.rotation}`,
                color: this.color,
                "animation": this.animation,
                opacity: this.opacity,
                "display":"inline-block",
                "text-decoration":this.textDecoration,
                width: this.textWidth,
                height: this.textHeight
            }]
        );
    }

    getImageAttributes() {
        return ([
            {
                position:"relative",
                "padding-top": this.paddingTop,
                display: "block",
                left: this.left
            },
            {
                color: this.color,
                "animation": this.animation,
                "opacity": this.opacity,
                transform: `${this.transform} ${this.rotation}`,
                width: this.width,
                height: this.height,
                border: this.border,
                "border-radius": this.borderRadius,
                "background-color":this.backgroundColor,
                "padding":"0em",
                "outline-width":this.outlineWidth,
                "outline-color":this.outlineColor,
                "outline-style": "solid",
                display: "block"
            }
        ]);
    }

    getVideoAttributes() {
        return ([
            {
                position:"relative",
                "padding-top": this.paddingTop,
                display: "block",
                left: this.left
            },
            {
                color: this.color,
                "animation": this.animation,
                "opacity": this.opacity,
                transform: `${this.transform} ${this.rotation}`,
                width: this.width,
                height: this.height,
                border: this.border,
                "border-radius": this.borderRadius,
                "background-color":this.backgroundColor,
                "padding":"0em",
                "outline-width":this.outlineWidth,
                "outline-color":this.outlineColor,
                "outline-style": "solid",
                display: "block"
            }
        ]);
    }

    getLiveVideoAttributes() {
        return ([
            {
                position:"relative",
                "padding-top": this.paddingTop,
                display: "block",
                left: this.left
            },
            {
                color: this.color,
                "animation": this.animation,
                "opacity": this.opacity,
                transform: `${this.transform} ${this.rotation}`,
                width: this.width,
                height: this.height,
                border: this.border,
                "border-radius": this.borderRadius,
                "background-color":this.backgroundColor,
                "padding":"0em",
                "outline-width":this.outlineWidth,
                "outline-color":this.outlineColor,
                "outline-style": "solid",
                display: "block"
            }
        ]);
    }

    getAudioAttributes() {
        return ([
            {

            },
            {

            }
        ]);
    }



    getLinkAttributes() {
        return ([
            {
                "display": "block",
                left: this.left,
                "padding-top": this.paddingTop,
                position: "relative"
            },
            {
                "font-size": this.fontSize,
                "font-family": this.fontFamily,
                "font-weight": this.fontWeight,
                color: this.color,
                "animation": this.animation,
                transform: `${this.transform} ${this.rotation}`,
                opacity: this.opacity,
                "display": "table",
                "background-color": this.buttonBgColor,
                "padding": this.buttonPadding,
                "border-radius": this.borderRadius,
                "text-decoration":this.textDecoration,
                "font-style":this.fontStyle,
                "box-shadow": this.boxShadow
            }
        ]);
    }

    getMailAttributes(){
        return ([
            {
                "display": "block",
                left: this.left,
                "padding-top": this.paddingTop,
                position: "relative"
            },
            {
                "font-size": this.fontSize,
                "font-family": this.fontFamily,
                "font-weight": this.fontWeight,
                transform: `${this.transform} ${this.rotation}`,
                color: this.color,
                "animation": this.animation,
                opacity: this.opacity,
                "cursor": "pointer",
                "display": "table",
                "background-color":this.buttonBgColor,
                "padding":this.buttonPadding,
                "border-radius":this.borderRadius,
                "text-decoration":this.textDecoration,
                "font-style":this.fontStyle,
                "box-shadow": this.boxShadow
            }
        ]);
    }

    getTelAttributes() {
        return ([
            {
                "display": "block",
                left: this.left,
                "padding-top": this.paddingTop,
                position: "relative"
            },
            {
                "font-size": this.fontSize,
                "font-family": this.fontFamily,
                "font-weight": this.fontWeight,
                transform: `${this.transform} ${this.rotation}`,
                color: this.color,
                "animation": this.animation,
                opacity: this.opacity,
                "cursor": "pointer",
                "display": "table",
                "background-color":"#1971FB",
                "padding":this.buttonPadding,
                "border-radius":this.borderRadius,
                "text-decoration":this.textDecoration,
                "font-style":this.fontStyle,
                "box-shadow": this.boxShadow
            }
        ]);
    }

    getChartAttributes() {
        return (
            [
                {
                    left: this.left,
                    // css property margin-top is used instead of padding-top only for chart
                    "margin-top": this.paddingTop,
                    position: "relative",
                    width: this.width,
                    height: this.height
                },
                {
                    "background-clip": "border-box",
                    transform: `${this.transform} ${this.rotation}`,
                    "opacity": this.opacity,
                    color: this.color,
                    "background-color":this.backgroundColor,
                    "display": "block",
                    "padding":"0em",
                    "border-radius":this.borderRadius,
                    "outline-width":this.outlineWidth,
                    "outline-color":this.outlineColor,
                    "outline-style": "solid",
                    "animation": this.animation
            }]
        );
    }

    getProgressbarAttributes() {
        return (
            [
                {
                    left: this.left,
                    // css property margin-top is used instead of padding-top only for chart and progress bar
                    "margin-top": this.paddingTop,
                    position: "relative",
                    "border-radius":this.borderRadius,
                     width: this.progressbarWidth,
                     "animation": this.animation
                },
                {
                     height: this.progressbarHeight,
                     "font-size": this.progressbarFontSize,
                     "font-family": this.fontFamily,
                     "font-weight": this.fontWeight,
                     "font-style":this.fontStyle,
                    color: this.progressbarColor,
                    "background-color":this.progressbgColor,
                    "padding":"10px",
                    "justify-content":this.textAlign,
                    "animation":`progress-bar ${this.progressAnimationDuration/1000}s`
            }]
        );
    }

    getWebpageAttributes() {
        return ([
            {
                left: "0%",
                "padding-top": "0%",
                position:"relative",
                "text-align": this.textAlign,
                "display": "block",
                width: this.webFileWidth,
                height: this.webFileHeight,
                overflow: this.overflow
            },
            {
                "font-size": this.fontSize,
                "font-family": this.fontFamily,
                "font-weight": this.fontWeight,
                "font-style":this.fontStyle,
                "padding":"0em",
                "border-radius":this.borderRadius,
                "background-color":"transparent",
                transform: `${this.transform} ${this.rotation}`,
                color: "#0073af",
                "animation": this.animation,
                opacity: "1",
                "display":"inline-block",
                "text-decoration":this.textDecoration,
                width: this.webFileWidth,
                height: this.webFileHeight,
            }]
        );
    }

    getFileAttributes() {
        return ([
            {
                left: "0%",
                "padding-top": "0%",
                position:"relative",
                "text-align": this.textAlign,
                "display": "block",
                width: this.webFileWidth,
                height:this.webFileHeight,
                overflow: this.overflow
            },
            {
                "font-size": this.fontSize,
                "font-family": this.fontFamily,
                "font-weight": this.fontWeight,
                "font-style":this.fontStyle,
                "padding":"0em",
                "border-radius":this.borderRadius,
                "background-color":"transparent",
                transform: `${this.transform} ${this.rotation}`,
                color: "#0073af",
                "animation": this.animation,
                opacity: "1",
                "display":"inline-block",
                "text-decoration":this.textDecoration,
                width: this.webFileWidth,
                height: this.webFileHeight
            }]
        );
    }


    transformCssProperties(cssProperties:any, type:any) : CssProperties{
        let cssProps = new CssProperties();
        switch(type) {
            case 'link':
            case 'mail':
            case 'audio':
            case 'section':
            case 'tel':
            {
                cssProps = this.transformLinkProperties(cssProperties);
                break;
            }
            case 'image': {
                cssProps = this.transformImageProperties(cssProperties);
                break;
            }
            case 'video': {
                cssProps = this.transformVideoProperties(cssProperties);
                break;
            }
            case 'livevideo': {
                cssProps = this.transformLiveVideoProperties(cssProperties);
                break;
            }
            case 'text': {
                cssProps = this.transformTextProperties(cssProperties);
                break;
            }
            case 'chart': {
                cssProps = this.transformChartProperties(cssProperties);
                break;
            }
            case 'progressbar': {
                cssProps = this.transformProgressbarProperties(cssProperties);
                break;
            }
            case 'webpage': {
                cssProps = this.transformWebpageProperties(cssProperties);
                break;
            }
            case 'file': {
                cssProps = this.transformFileProperties(cssProperties);
                break;
            }
        }
        return cssProps;
    }

    transformLinkProperties(cssProperties:any) {
        const cssProps = new CssProperties();
        cssProps.fontFamily = cssProperties[1]["font-family"] ? cssProperties[1]["font-family"] : this.fontFamily;
        cssProps.fontSize = cssProperties[1]["font-size"] ? cssProperties[1]["font-size"] : this.fontSize;
        cssProps.animation = cssProperties[1]["animation"] ? cssProperties[1]["animation"] : "";
        cssProps.animationDuration = cssProperties[1]["animation-duration"] ? cssProperties[1]["animation-duration"] : "";
        cssProps.buttonBgColor = cssProperties[1]["background-color"] ? cssProperties[1]["background-color"] : this.buttonBgColor;
        cssProps.border = cssProperties[1]["border"] ? cssProperties[1]["border"] : this.border;
        cssProps.backgroundColor = cssProperties[1]["background-color"] ? cssProperties[1]["background-color"] : this.backgroundColor;
        cssProps.borderRadius = cssProperties[1]["border-radius"] ? cssProperties[1]["border-radius"] : this.borderRadius;
        cssProps.color = cssProperties[1]["color"] ? cssProperties[1]["color"] : this.color;
        cssProps.fontWeight = cssProperties[1]["font-weight"] ? cssProperties[1]["font-weight"] : this.fontWeight;
        cssProps.height = this.height;
        cssProps.left = cssProperties[0]["left"] ? cssProperties[0]["left"] : this.left;
        cssProps.opacity = cssProperties[1]["opacity"];
        cssProps.paddingTop = cssProperties[0]["padding-top"] ? cssProperties[0]["padding-top"] : this.paddingTop;
        cssProps.rotation = cssProperties[1]["transform"] ? cssProperties[1]["transform"].split(") ")[1] : this.rotation;
        cssProps.textAlign = this.textAlign;
        cssProps.transform = cssProperties[1]["transform"] ? cssProperties[1]["transform"].split(" rotate")[0] : this.transform;
        cssProps.width = this.width;
        cssProps.padding = cssProperties[1]["padding"] ? cssProperties[1]["padding"] : this.padding;
        cssProps.buttonPadding = cssProperties[1]["padding"] ? cssProperties[1]["padding"] : this.buttonPadding;
        cssProps.fontStyle = cssProperties[1]["font-style"] ? cssProperties[1]["font-style"] : this.fontStyle;
        cssProps.textDecoration = cssProperties[1]["text-decoration"] ? cssProperties[1]["text-decoration"] : this.textDecoration;
        cssProps.boxShadow = cssProperties[1]["box-shadow"] ? cssProperties[1]["box-shadow"] : this.boxShadow;
        return cssProps;
    }

    transformImageProperties(cssProperties:any) {
        const cssProps = new CssProperties();
        cssProps.fontFamily = this.fontFamily ;
        cssProps.fontSize = this.fontSize;
        cssProps.animation = cssProperties[1]["animation"] ? cssProperties[1]["animation"] : "";
        cssProps.animationDuration = cssProperties[1]["animation-duration"] ? cssProperties[1]["animation-duration"] : "";
        cssProps.border = cssProperties[1]["border"] ? cssProperties[1]["border"] : this.border;
        cssProps.borderRadius = cssProperties[1]["border-radius"] ? cssProperties[1]["border-radius"] : this.borderRadius;
        cssProps.color = cssProperties[1]["color"] ? cssProperties[1]["color"] : this.color;
        cssProps.fontWeight = this.fontWeight;
        cssProps.height = cssProperties[1]["height"] ? cssProperties[1]["height"] : this.height;
        cssProps.left = cssProperties[0]["left"] ? cssProperties[0]["left"] : this.left;
        cssProps.opacity = cssProperties[1]["opacity"];
        cssProps.paddingTop = cssProperties[0]["padding-top"] ? cssProperties[0]["padding-top"] : this.paddingTop;
        cssProps.rotation = cssProperties[0]["transform"] ? cssProperties[0]["transform"].split(") ")[1] : this.rotation;
        cssProps.textAlign = this.textAlign;
        cssProps.transform = cssProperties[0]["transform"] ? cssProperties[0]["transform"].split(" rotate")[0] : this.transform;
        cssProps.width = cssProperties[1]["width"] ? cssProperties[1]["width"] : this.width;
        cssProps.outlineColor = cssProperties[1]["outline-color"] ? cssProperties[1]["outline-color"] : this.outlineColor;
        cssProps.outlineWidth = cssProperties[1]["outline-width"] ? cssProperties[1]["outline-width"] : this.outlineWidth;
        cssProps.backgroundColor = cssProperties[1]["background-color"] ? cssProperties[1]["background-color"] : this.backgroundColor;
        return cssProps;
    }

    transformVideoProperties(cssProperties:any) {
        const cssProps = new CssProperties();
        cssProps.fontFamily = this.fontFamily ;
        cssProps.fontSize = this.fontSize;
        cssProps.animation = cssProperties[1]["animation"] ? cssProperties[1]["animation"] : "";
        cssProps.animationDuration = cssProperties[1]["animation-duration"] ? cssProperties[1]["animation-duration"] : "";
        cssProps.border = cssProperties[1]["border"] ? cssProperties[1]["border"] : this.border;
        cssProps.borderRadius = cssProperties[1]["border-radius"] ? cssProperties[1]["border-radius"] : this.borderRadius;
        cssProps.color = cssProperties[1]["color"] ? cssProperties[1]["color"] : this.color;
        cssProps.fontWeight = this.fontWeight;
        cssProps.height = cssProperties[1]["height"] ? cssProperties[1]["height"] : this.height;
        cssProps.left = cssProperties[0]["left"] ? cssProperties[0]["left"] : this.left;
        cssProps.opacity = cssProperties[1]["opacity"];
        cssProps.paddingTop = cssProperties[0]["padding-top"] ? cssProperties[0]["padding-top"] : this.paddingTop;
        cssProps.rotation = cssProperties[0]["transform"] ? cssProperties[0]["transform"].split(") ")[1] : this.rotation;
        cssProps.textAlign = "";
        cssProps.transform = cssProperties[0]["transform"] ? cssProperties[0]["transform"].split(" rotate")[0] : this.transform;
        cssProps.width = cssProperties[1]["width"] ? cssProperties[1]["width"] : this.width;
        cssProps.outlineColor = cssProperties[1]["outline-color"] ? cssProperties[1]["outline-color"] : this.outlineColor;
        cssProps.outlineWidth = cssProperties[1]["outline-width"] ? cssProperties[1]["outline-width"] : this.outlineWidth;
        cssProps.backgroundColor = cssProperties[1]["background-color"] ? cssProperties[1]["background-color"] : this.backgroundColor;
        return cssProps;
    }

    transformLiveVideoProperties(cssProperties:any) {
        const cssProps = new CssProperties();
        cssProps.fontFamily = this.fontFamily ;
        cssProps.fontSize = this.fontSize;
        cssProps.animation = cssProperties[1]["animation"] ? cssProperties[1]["animation"] : "";
        cssProps.animationDuration = cssProperties[1]["animation-duration"] ? cssProperties[1]["animation-duration"] : "";
        cssProps.border = cssProperties[1]["border"] ? cssProperties[1]["border"] : this.border;
        cssProps.borderRadius = cssProperties[1]["border-radius"] ? cssProperties[1]["border-radius"] : this.borderRadius;
        cssProps.color = cssProperties[1]["color"] ? cssProperties[1]["color"] : this.color;
        cssProps.fontWeight = this.fontWeight;
        cssProps.height = cssProperties[1]["height"] ? cssProperties[1]["height"] : this.height;
        cssProps.left = cssProperties[0]["left"] ? cssProperties[0]["left"] : this.left;
        cssProps.opacity = cssProperties[1]["opacity"];
        cssProps.paddingTop = cssProperties[0]["padding-top"] ? cssProperties[0]["padding-top"] : this.paddingTop;
        cssProps.rotation = cssProperties[0]["transform"] ? cssProperties[0]["transform"].split(") ")[1] : this.rotation;
        cssProps.textAlign = "";
        cssProps.transform = cssProperties[0]["transform"] ? cssProperties[0]["transform"].split(" rotate")[0] : this.transform;
        cssProps.width = cssProperties[1]["width"] ? cssProperties[1]["width"] : this.width;
        cssProps.outlineColor = cssProperties[1]["outline-color"] ? cssProperties[1]["outline-color"] : this.outlineColor;
        cssProps.outlineWidth = cssProperties[1]["outline-width"] ? cssProperties[1]["outline-width"] : this.outlineWidth;
        cssProps.backgroundColor = cssProperties[1]["background-color"] ? cssProperties[1]["background-color"] : this.backgroundColor;
        return cssProps;
    }

    transformTextProperties(cssProperties:any) {
        const cssProps = new CssProperties();
        cssProps.fontFamily = cssProperties[1]["font-family"] ? cssProperties[1]["font-family"] : this.fontFamily ;
        cssProps.fontSize = cssProperties[1]["font-size"] ? cssProperties[1]["font-size"] : this.fontSize;
        cssProps.animation = cssProperties[1]["animation"] ? cssProperties[1]["animation"] : "";
        cssProps.animationDuration = cssProperties[1]["animation-duration"] ? cssProperties[1]["animation-duration"] : "";
        cssProps.backgroundColor = cssProperties[1]["background-color"] ? cssProperties[1]["background-color"] : this.backgroundColor;
        cssProps.border = cssProperties[1]["border"] ? cssProperties[1]["border"] : this.border;
        cssProps.borderRadius = cssProperties[1]["border-radius"] ? cssProperties[1]["border-radius"] : this.borderRadius;
        cssProps.color = cssProperties[1]["color"] ? cssProperties[1]["color"] : this.color;
        cssProps.fontWeight = cssProperties[1]["font-weight"] ? cssProperties[1]["font-weight"] : this.fontWeight;
        cssProps.textHeight = cssProperties[1]["height"] ? cssProperties[1]["height"] : this.textHeight;
        cssProps.left = cssProperties[0]["left"] ? cssProperties[0]["left"] : this.left;
        cssProps.opacity = cssProperties[1]["opacity"];
        cssProps.paddingTop = cssProperties[0]["padding-top"] ? cssProperties[0]["padding-top"] : this.paddingTop;
        cssProps.rotation = cssProperties[1]["transform"] ? cssProperties[1]["transform"].split(") ")[1] : this.rotation;
        cssProps.textAlign = cssProperties[0]["text-align"] ? cssProperties[0]["text-align"] : this.textAlign;
        cssProps.transform = cssProperties[1]["transform"] ? cssProperties[1]["transform"].split(" rotate")[0] : this.transform;
        cssProps.textWidth = cssProperties[1]["width"] ? cssProperties[1]["width"] : this.textWidth;
        cssProps.padding = cssProperties[1]["padding"] ? cssProperties[1]["padding"] : this.padding;
        cssProps.fontStyle = cssProperties[1]["font-style"] ? cssProperties[1]["font-style"] : this.fontStyle;
        cssProps.textDecoration = cssProperties[1]["text-decoration"] ? cssProperties[1]["text-decoration"] : this.textDecoration;
        return cssProps;
    }

    transformChartProperties(cssProperties:any) {
        const cssProps = new CssProperties();
        cssProps.fontFamily = this.fontFamily ;
        cssProps.fontSize = this.fontSize;
        cssProps.animation = cssProperties[1]["animation"] ? cssProperties[1]["animation"] : "";
        cssProps.backgroundColor = cssProperties[1]["background-color"] ? cssProperties[1]["background-color"] : this.backgroundColor;
        cssProps.border = this.border;
        cssProps.borderRadius = cssProperties[1]["border-radius"] ? cssProperties[1]["border-radius"] : this.borderRadius;
        cssProps.color = cssProperties[1]["color"] ? cssProperties[1]["color"] : this.color;
        cssProps.fontWeight = this.fontWeight;
        cssProps.height = cssProperties[0]["height"] ? cssProperties[0]["height"] : this.height;
        cssProps.left = cssProperties[0]["left"] ? cssProperties[0]["left"] : this.left;
        cssProps.opacity = cssProperties[1]["opacity"];
        cssProps.paddingTop = cssProperties[0]["margin-top"] ? cssProperties[0]["margin-top"] : this.paddingTop;
        cssProps.rotation = cssProperties[1]["transform"] ? cssProperties[1]["transform"].split(") ")[1] : this.rotation;
        cssProps.textAlign = this.textAlign;
        cssProps.transform = cssProperties[1]["transform"] ? cssProperties[1]["transform"].split(" rotate")[0] : this.transform;
        cssProps.width = cssProperties[0]["width"] ? cssProperties[0]["width"] : this.width;
        cssProps.padding = cssProperties[1]["padding"] ? cssProperties[1]["padding"] : this.padding;
        cssProps.outlineColor = cssProperties[1]["outline-color"] ? cssProperties[1]["outline-color"] : this.outlineColor;
        cssProps.outlineWidth = cssProperties[1]["outline-width"] ? cssProperties[1]["outline-width"] : this.outlineWidth;
        return cssProps;
    }

    transformProgressbarProperties(cssProperties:any){
        const cssProps = new CssProperties();
        cssProps.fontFamily = cssProperties[1]["font-family"] ? cssProperties[1]["font-family"] : this.fontFamily ;
        cssProps.progressbarFontSize = cssProperties[1]["font-size"] ? cssProperties[1]["font-size"] : this.fontSize;
        cssProps.animation = cssProperties[0]["animation"] ? cssProperties[0]["animation"] : "";
        cssProps.animationDuration = cssProperties[1]["animation-duration"] ? cssProperties[1]["animation-duration"] : "";
        cssProps.progressbgColor = cssProperties[1]["background-color"] ? cssProperties[1]["background-color"] : this.backgroundColor;
        cssProps.progressbarWidth= cssProperties[0]["width"] ? cssProperties[0]["width"] : this.progressbarWidth;
        cssProps.borderRadius = cssProperties[0]["border-radius"] ? cssProperties[0]["border-radius"] : this.borderRadius;
        cssProps.progressbgColor = cssProperties[1]["color"] ? cssProperties[1]["color"] : this.color;
        cssProps.fontWeight = cssProperties[1]["font-weight"] ? cssProperties[1]["font-weight"] : this.fontWeight;
        cssProps.progressbarHeight = cssProperties[1]["height"] ? cssProperties[1]["height"] : this.height;
        cssProps.left = cssProperties[0]["left"] ? cssProperties[0]["left"] : this.left;
        cssProps.paddingTop = cssProperties[0]["margin-top"] ? cssProperties[0]["margin-top"] : this.paddingTop;
        cssProps.textAlign = cssProperties[1]["justify-content"] ? cssProperties[1]["justify-content"] : this.textAlign;
        return cssProps;
    }

    transformWebpageProperties(cssProperties:any){
        const cssProps = new CssProperties();
        cssProps.fontFamily = this.fontFamily ;
        cssProps.fontSize = this.fontSize;
        cssProps.animation = cssProperties[1]["animation"] ? cssProperties[1]["animation"] : "";
        cssProps.animationDuration = cssProperties[1]["animation-duration"] ? cssProperties[1]["animation-duration"] : "";
        cssProps.border = cssProperties[1]["border"] ? cssProperties[1]["border"] : this.border;
        cssProps.borderRadius = cssProperties[1]["border-radius"] ? cssProperties[1]["border-radius"] : this.borderRadius;
        cssProps.color = cssProperties[1]["color"] ? cssProperties[1]["color"] : this.color;
        cssProps.fontWeight = this.fontWeight;
        cssProps.webFileHeight = cssProperties[1]["height"] ? cssProperties[1]["height"] : this.webFileHeight;
        cssProps.left = cssProperties[0]["left"] ? cssProperties[0]["left"] : this.left;
        cssProps.opacity = cssProperties[1]["opacity"];
        cssProps.paddingTop = cssProperties[0]["padding-top"] ? cssProperties[0]["padding-top"] : this.paddingTop;
        cssProps.rotation = cssProperties[0]["transform"] ? cssProperties[0]["transform"].split(") ")[1] : this.rotation;
        cssProps.textAlign = this.textAlign;
        cssProps.transform = cssProperties[0]["transform"] ? cssProperties[0]["transform"].split(" rotate")[0] : this.transform;
        cssProps.webFileWidth = cssProperties[1]["width"] ? cssProperties[1]["width"] : this.webFileWidth;
        cssProps.outlineColor = cssProperties[1]["outline-color"] ? cssProperties[1]["outline-color"] : this.outlineColor;
        cssProps.outlineWidth = cssProperties[1]["outline-width"] ? cssProperties[1]["outline-width"] : this.outlineWidth;
        cssProps.backgroundColor = cssProperties[1]["background-color"] ? cssProperties[1]["background-color"] : this.backgroundColor;
        return cssProps;
    }


    transformFileProperties(cssProperties:any) {
        const cssProps = new CssProperties();
        cssProps.fontFamily = cssProperties[1]["font-family"] ? cssProperties[1]["font-family"] : this.fontFamily ;
        cssProps.fontSize = cssProperties[1]["font-size"] ? cssProperties[1]["font-size"] : this.fontSize;
        cssProps.animation = cssProperties[1]["animation"] ? cssProperties[1]["animation"] : "";
        cssProps.animationDuration = cssProperties[1]["animation-duration"] ? cssProperties[1]["animation-duration"] : "";
        cssProps.backgroundColor = cssProperties[1]["background-color"] ? cssProperties[1]["background-color"] : this.backgroundColor;
        cssProps.border = cssProperties[1]["border"] ? cssProperties[1]["border"] : this.border;
        cssProps.borderRadius = cssProperties[1]["border-radius"] ? cssProperties[1]["border-radius"] : this.borderRadius;
        cssProps.color = cssProperties[1]["color"] ? cssProperties[1]["color"] : this.color;
        cssProps.fontWeight = cssProperties[1]["font-weight"] ? cssProperties[1]["font-weight"] : this.fontWeight;
        cssProps.webFileHeight = cssProperties[1]["height"] ? cssProperties[1]["height"] : this.webFileHeight;
        cssProps.left = cssProperties[0]["left"] ? cssProperties[0]["left"] : this.left;
        cssProps.opacity = cssProperties[1]["opacity"];
        cssProps.paddingTop = cssProperties[0]["padding-top"] ? cssProperties[0]["padding-top"] : this.paddingTop;
        cssProps.rotation = cssProperties[1]["transform"] ? cssProperties[1]["transform"].split(") ")[1] : this.rotation;
        cssProps.textAlign = cssProperties[0]["text-align"] ? cssProperties[0]["text-align"] : this.textAlign;
        cssProps.transform = cssProperties[1]["transform"] ? cssProperties[1]["transform"].split(" rotate")[0] : this.transform;
        cssProps.webFileWidth = cssProperties[1]["width"] ? cssProperties[1]["width"] : this.webFileWidth;
        cssProps.padding = cssProperties[1]["padding"] ? cssProperties[1]["padding"] : this.padding;
        cssProps.fontStyle = cssProperties[1]["font-style"] ? cssProperties[1]["font-style"] : this.fontStyle;
        cssProps.textDecoration = cssProperties[1]["text-decoration"] ? cssProperties[1]["text-decoration"] : this.textDecoration;
        return cssProps;
    }
}
