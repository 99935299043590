import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { FileToolbarService } from './file-toolbar.service';
import { DomSanitizer, SafeValue } from '@angular/platform-browser';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'psv-file-toolbar',
  templateUrl: './file-toolbar.component.html',
  styleUrls: ['./file-toolbar.component.css'],
})
export class FileToolbarComponent implements OnInit {
  @Input() isToolBarOpen!: boolean;
  @Input() fontSize: any;
  @Input() aspectRatio: any;
  @Input() selectedAnnotation: any;
  @Input() annotationTableData: any;
  @Input() fileOverlayComponent: any;
  @Input() overlayJSON: any;
  @Output() onMinimizing = new EventEmitter<boolean>();
  @Output() annotationTableDataEvent = new EventEmitter<any>();
  @Output() overlayAnnotationsEvent = new EventEmitter<any>();
  @Output() setDraggerPositionEvent = new EventEmitter<any>();
  @Output() updateCurrentTimeEvent = new EventEmitter<any>();
  @Output() notifyDurationChangeEvent = new EventEmitter<any>();
  @Output() showSnackBarEvent = new EventEmitter<any>();
  @Output() notifyDurationChangeInToolbarEvent = new EventEmitter<any>();
  @Output() fileOverlayOutputEvent = new EventEmitter<any>();
  @Output() onFileUpload = new EventEmitter<any>();
  @Output() onFileUploadgetName = new EventEmitter<any>();
  @Output() overlayJSONEvent = new EventEmitter<any>();
  filePath: string | SafeValue = '';
  page: boolean = false;

  constructor(
    readonly data: FileToolbarService,
    public sanitizer: DomSanitizer,
    public snackBar: MatSnackBar,
    public data1: DataService
  ) {}

  ngOnInit(): void {
    this.annotationTableData = this.annotationTableData;
    this.loadElement(this.fileOverlayComponent);
  }

  loadFile() {
    this.page = true;
    // this.fileOverlayComponent.src = this.filePath;
    this.fileOverlayOutputEvent.emit(this.fileOverlayComponent);
    this.loadElement();
  }
  errorClassList = ['error-snackbar'];

  loadElement(updatedOverlays?: any) {
    this.overlayAnnotations(updatedOverlays);
    this.setDraggerPosition();
  }

  uploadFileSelected(file: any) {
    this.page = true;
    let blob: Blob;
    const fileReader = new FileReader();
    const arrayBuffer = file.target.files[0];
    var fileName = file.srcElement.files[0].name;
    this.onFileUploadgetName.emit(fileName);
    console.log(fileName.split('.')[1]);
    const fileType = 'application/pdf';
    blob = new Blob([arrayBuffer], { type: fileType });
    // this.fileOverlayOutputEvent.emit(this.fileOverlayComponent);
    this.annotationTableData[
      this.getAnnotationTableId(this.fileOverlayComponent)
      
    ].value = file.srcElement.files[0].name;
    this.annotationTableDataEvent.emit(this.annotationTableData);
    this.onFileUpload.emit(blob);
    this.loadElement()
  }

  changeAnnotationValue() {
    this.annotationTableData[
      this.getAnnotationTableId(this.fileOverlayComponent)
    ].value = this.fileOverlayComponent.src;
    this.annotationTableDataEvent.emit(this.annotationTableData);
    this.loadElement();
  }

  getAnnotationTableId(overlayObj: any): string {
    for (let index in this.annotationTableData) {
      if (
        this.annotationTableData[index].type === 'audio' &&
        overlayObj.type === 'audio' &&
        overlayObj.id === this.annotationTableData[index].id
      ) {
        return index;
      } else if (
        this.annotationTableData[index].type !== 'audio' &&
        overlayObj.type !== 'audio' &&
        overlayObj.id === this.annotationTableData[index].id
      ) {
        return index;
      }
    }
    return '';
  }

  changeStartTime(startTime: any) {
    this.fileOverlayComponent.isDurationChanged = true;
    startTime = parseFloat(startTime);

    var endTime = this.fileOverlayComponent.end;
    if (endTime > startTime) {
      if (this.fileOverlayComponent.start) {
        this.fileOverlayComponent.duration = endTime - startTime;
        this.updateCurrentTime(this.fileOverlayComponent.start);
        this.fileOverlayOutputEvent.emit(this.fileOverlayComponent);
        this.loadElement();
        this.notifyDurationChange('notified');
        this.notifyDurationChangeInToolbar('changed');
      }
    } else {
      let classList = ['error-snackbar'];
      this.showSnackBar(
        '`START TIME` time must be less than the `END TIME` time.',
        classList
      );
      return;
    }
  }

  setStartTimeMinVal() {
    let startTime = parseFloat(this.fileOverlayComponent.start);
    if (startTime === 0) {
      this.fileOverlayComponent.start = 0.001;
      this.fileOverlayOutputEvent.emit(this.fileOverlayComponent);
      this.loadElement();
      this.notifyDurationChange('notified');
      this.notifyDurationChangeInToolbar('changed');
    }
  }

  overlayAnnotations(updateAnnotation?: any) {
    this.overlayAnnotationsEvent.emit(updateAnnotation);
  }

  setDraggerPosition() {
    this.setDraggerPositionEvent.emit();
  }
  updateCurrentTime(currentTime: number) {
    this.updateCurrentTimeEvent.emit(currentTime);
  }
  notifyDurationChange(message: any) {
    this.notifyDurationChangeEvent.emit(message);
  }
  notifyDurationChangeInToolbar(message: string) {
    this.notifyDurationChangeInToolbarEvent.emit(message);
  }
  showSnackBar(message: any, classList: any) {
    const config = new MatSnackBarConfig();
    config.horizontalPosition = 'right';
    config.verticalPosition = 'bottom';
    config.panelClass = classList;
    config.duration = 3000;
    this.snackBar.open(message, 'Ok', config);
  }
  updateFileHeight(height:any){
    this.fileOverlayComponent.cssProperties.webFileHeight = `${height}em`;
    if(this.fileOverlayComponent.maintainAspectRatio){
      const widthInPx = (height*this.fontSize)*this.aspectRatio;
      this.fileOverlayComponent.cssProperties.webFileWidth = `${(widthInPx/this.fontSize)}em`;
    }
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.annotationTableDataEvent.emit(this.annotationTableData);
    this.loadElement();
  }
  updateFileWidth(width:any){
    this.fileOverlayComponent.cssProperties.webFileWidth = `${width}em`;
    if(this.fileOverlayComponent.maintainAspectRatio){
      const heightInPx = (width*this.fontSize)/this.aspectRatio;
      this.fileOverlayComponent.cssProperties.webFileHeight = `${(heightInPx/this.fontSize)}em`;
    }
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.annotationTableDataEvent.emit(this.annotationTableData);
    this.loadElement();
  }
  toggleMaintainAspectRatio(){
    this.fileOverlayComponent.maintainAspectRatio = !this.fileOverlayComponent.maintainAspectRatio;
    const height = this.fileOverlayComponent.cssProperties.webFileHeight.split("em")[0];
    const widthInPx = (height*this.fontSize)*this.aspectRatio;
    this.fileOverlayComponent.cssProperties.webFileWidth = `${(widthInPx/this.fontSize)}em`;
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.annotationTableDataEvent.emit(this.annotationTableData);
    this.loadElement();
  }
  minimizeToolBar() {
    this.onMinimizing.emit(true);
  }
}
