import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError, Observable, throwError } from 'rxjs';
import { AudioAnnotation } from './audio-annotation.model';

declare var psv:any;

@Injectable({
  providedIn: 'root'
})
export class Psv2AudioToolbarService {

  updateAudio!:boolean;
  audioAnnotation:any;
  ttsProviderOptions:any;
  tts:any;
  ttsOptions:any;
  isTtsProviderChanged:any;
  overlayJSON:any;
  isLoaderEnabled:any;

  constructor(public snackBar:MatSnackBar, readonly http: HttpClient, readonly sanitizer: DomSanitizer ) { }

  showSnackBar(message:any, classList:any) {
    const config = new MatSnackBarConfig();
    config.horizontalPosition = "right";
    config.verticalPosition = "bottom";
    config.panelClass = classList;
    config.duration = 3000;
    this.snackBar.open(message, 'Ok', config);
  }

  getPollyVoicesList(): Observable<any> {
    return this.http.get("assets/data/polly_voices.json").pipe(
      catchError(this.errorHandler)
    );
  }

  getGoogleVoicesList(): Observable<any> {
    return this.http.get("assets/data/google_voices.json").pipe(
      catchError(this.errorHandler)
    );
  }

  getAzureVoicesList(): Observable<any> {
    return this.http.get("assets/data/azure_voices.json").pipe(
      catchError(this.errorHandler)
    );
  }

  getSampleTTSAudio(params: any) {
    this.ttsProviderOptions = params;
    this.audioAnnotation = new AudioAnnotation(1, 1, 6, this.tts);
    this.audioAnnotation.initializeAttributes(this.ttsProviderOptions, this.tts);
    let sampleJson:any;
    sampleJson = {
      "tts": this.tts,
      audios: [
        JSON.parse(JSON.stringify(this.audioAnnotation.getAttributes(this.tts)))
      ]
    }

    return new Promise((resolve, reject) => {
      new psv.textToSpeechConfiguration(sampleJson, this.ttsOptions).then((audioFile:any) => {
        resolve(audioFile);
      }).catch((err:any) => {
        reject(err);
      });
    });
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message)
  }
}
