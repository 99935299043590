<div class="audio-tool">
  <div (click)="minimizeToolBar()" class="toolbar-minimize" *ngIf="isToolBarOpen">
    <!-- <fa-icon [icon]="faCaretRight"></fa-icon> -->
    <em class="icon-class material-icons">highlight_off</em>
  </div>
  <div class="sub-heading2 header">AUDIO FILE</div>
  <div class="properties-bar time-box custom-properties-bar">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title class="heading-container">PROPERTIES</mat-panel-title>
      </mat-expansion-panel-header>

      <label class="font-size-sm">
        START TIME (in s)&nbsp;
        <input #startTime class="input-time start-field" type="number" min="0.000" step="0.001"
          [ngModel]="audioAnnotation.start | number" (blur)="setStartTimeMinVal()"
          (ngModelChange)="audioAnnotation.start= $event" (input)="changeStartTime(startTime.value,endTime.value)">
      </label>
      <label class="font-size-sm">
        END TIME (in s)
        <input #endTime class="input-time end-field" style="margin-right: 5px;" type="number" min="0.000" step="0.001"
          [max]="videoDuration" [ngModel]="audioAnnotation.end | number"
          (input)="changeEndTime(startTime.value,endTime.value)" (ngModelChange)="audioAnnotation.end=$event">
      </label> 
      <div *ngIf="audioAnnotation.type === 'audio'">
        <label class="properties font-size-sm no-margin">ADD AUDIO</label>
        <!-- <div class="properties font-size-sm file-display">
          <input id="audioSrc" accept="audio/*" (change)="onFileUpload($event)" type="file" class="file-name">
        </div>
        <span class="font-span-sm">OR</span> -->
        <div>
          <button class="from-gallery-button" (click)="openGalleryForAudio()" type="button">Choose From Gallery</button>
        </div>
        &nbsp;
        <span class="font-span-sm">OR</span><br>
        <label class="properties font-size-sm">AUDIO PATH</label>
        <input type="text" class="font-size-md" (input)="loadVideoPathtest()" [(ngModel)]="pathUrl"
          id="VideoSelected" placeholder="Enter Audio path">
      </div>
      <div *ngIf="audioAnnotation.type === 'liveAudio'">
        <div class="audio-prop">
          <label>Audio Preview : </label>
          <audio [src]="audioAnnotation.srcUrl | sanitizeVideo" controls></audio>
        </div>
        <div class="updateAud">
          <label>Update Audio :</label>
          <button class="buttonrec" (click)="reRecord()">Record</button>
        </div>
      </div>
      <div class="updateAud">
        <label>Rename Title : </label>
        <input (input)="changeTitleName()" [(ngModel)]="this.TitleRename" type="text" placeholder="Enter Title Name">
      </div>

    </mat-expansion-panel>
  </div>
</div>