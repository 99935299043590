import { NgModule } from '@angular/core';
import { CallToActionComponent } from './call-to-action.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule} from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DurationFormatPipe } from './pipes/duration-format.pipe';
import { MatInputModule} from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CalcXCoordPipe } from './pipes/calc-x-coordinate/calc-x-coord.pipe';
import { CalcYCoordPipe } from './pipes/calc-y-coordinate/calc-y-coord.pipe';
import { CalcRotationPipe } from './pipes/calc-rotation/calc-rotation.pipe';
import { CalcFontSizePipe } from './pipes/calc-font-size/calc-font-size.pipe';
import { colorConvertPipe } from './pipes/color-convert.pipe';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { OrderModule } from 'ngx-order-pipe';


@NgModule({
  declarations: [
  CallToActionComponent,
  CalcXCoordPipe,
  CalcYCoordPipe,
  CalcRotationPipe,
  CalcFontSizePipe,
  colorConvertPipe,
  DurationFormatPipe,
  ],
  imports: [ 
    BrowserModule,
    CommonModule,
    FormsModule,
    MatInputModule,
    MatSliderModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatSlideToggleModule,
    FontAwesomeModule,
   OrderModule
    ],
  exports: [
    CallToActionComponent
  ]
})
export class CallToActionModule { }
