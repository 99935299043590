/*******************************************************************************
 * COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
 * Reserved. Infosys believes the information in this document is accurate as of
 * its publication date; such information is subject to change without notice.
 * Infosys acknowledges the proprietary rights of other companies to the
 * trademarks, product names and such other intellectual property rights
 * mentioned in this document. Except as expressly permitted, neither this
 * documentation nor any part of it may be reproduced, stored in a retrieval
 * system, or transmitted in any form or by any means, electronic, mechanical,
 * printing, photocopying, recording or otherwise, without the prior permission
 * of Infosys Limited and/or any named intellectual property rights holders
 * under this document.
 ******************************************************************************/
 import { CssProperties } from './css-properties.model';
 export class AudioOverlayAnnotation {
     
     type: string='';
     className: string='';
     start: number;
     id: number;
     end: number;
     cssProperties: CssProperties;
     srcUrl: string='';
     audioData:string='';
     altText: string='';
     audioName:string='';
     callback:string='';
     duration:any;
     volume:number = 1.0;
 
     constructor(id: number, currentTime:number, videoDuration: number, cssProps: CssProperties){
         this.end = videoDuration;
         this.id = id;
         this.start = currentTime;
         this.cssProperties = cssProps;
         this.duration = this.end - this.start;
         this.initializeAttributes();
         
     }
 
     initializeAttributes(){
         this.type = 'audio';
         this.className = '';
         this.srcUrl = "";
         this.audioData = "";
         this.altText = "Invalid Path";
         this.audioName="";
         this.callback="";
         this.volume = 1.0;
     }
 
     getAttributes(){
         return(
             {
                 type: 'audio',
                 id: this.id,
                 "src-url": this.srcUrl === "" ? this.audioData : this.srcUrl,
                 "alt-text": this.altText,
                 "class-name": this.className,
                 start: this.start,
                 end: this.end,
                 volume : this.volume ? this.volume : 1.0,
                 callback: this.callback,
                 audioName:this.audioName
             }
         );
     }
 
     transformAttributes(overlayObject:any, cssProps: CssProperties){
         const overlayAnnotationObject = new AudioOverlayAnnotation(overlayObject.id, overlayObject.start, overlayObject.end, cssProps);
         if(overlayObject.audioName) {
             overlayAnnotationObject.audioData = overlayObject["src-url"];
         } else {
             overlayAnnotationObject.srcUrl = overlayObject["src-url"];
         }
         overlayAnnotationObject.altText = overlayObject["alt-text"];
         overlayAnnotationObject.start = overlayObject.start;
         overlayAnnotationObject.className = overlayObject["class-name"];
         overlayAnnotationObject.audioName = overlayObject.audioName;
         overlayAnnotationObject.volume = overlayObject["volume"];
         overlayAnnotationObject.callback= overlayObject.callback;
         return overlayAnnotationObject;
     }
 
 }
 