import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import { DataService } from 'src/app/shared/services/data.service'
import { MatDialog } from 'node_modules/@angular/material/dialog';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { ToolbarComponent } from 'src/app/editor/toolbar/toolbar.component';
import { CssProperties } from 'src/app/shared/models/css-properties.model';
import { CaptionAnnotation } from 'src/app/shared/models/caption-annotation.model';

@Component({
  selector: 'app-caption',
  templateUrl: './caption.component.html',
  styleUrls: ['./caption.component.css'],
  providers: [ToolbarComponent]
})
export class CaptionComponent implements OnInit {

  @Input() selectedAnnotation:any
  @Input() caption_annotationTableData:any;
  @Input() isToolBarOpen!:boolean;
  @Output() onMinimizing = new EventEmitter<boolean>();
  @Output() caption_annotationTableDataEvent = new EventEmitter<any>();
  @Output() overlayAnnotationsEvent = new EventEmitter<any>();
  @Output() updateCurrentTimeEvent = new EventEmitter<any>();
  @Output() setDraggerPositionEvent =new EventEmitter<any>();
  @Output() notifyDurationChangeEvent = new EventEmitter<any>();
  // @Output() showSnackBarEvent = new EventEmitter<any>();
  @Output() captionComponentOutput = new EventEmitter<any>();
  @Output() notifyDurationChangeInToolbarEvent = new EventEmitter<any>();
  @Input() captionComponent:any;
  faTimes = faTimes;
  faPlus = faPlus;
  captionData: any = {
    Start: { min: "00", sec: "00", msec: "001", valid: true },
    End: { min: "00", sec: "06", msec: "069", valid: true },
    size: { value: 70, valid: true },
    caption_text: "Demo Caption"
  }
  classList = ["error-snackbar"];

  @ViewChild('scrollMe',{static: true}) comment!: ElementRef;
  
  scrolltop!: number;
  snackBar: any;

  constructor(public dialog: MatDialog, public data: DataService, public tool: ToolbarComponent) {

  }

  async ngOnInit() {
    this.scrolltop = this.comment.nativeElement.scrollHeight;
    if (this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.length === 0) {
      this.tool.initializeCaptionOverlayObject('caption');
    }
    if (this.data.exisitingCaptionUrl && this.data.exisitingCaptionUrl === this.data.captionUrl ) {
      let data = await this.data.getCaptionData(this.data.exisitingCaptionUrl)

      this.uploadExistingCaption(data)
    }
  }

  

  removeCaption(index:any) {
    this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.splice(index, 1)
  }
  verifyMessageField(value:any) {
    if (value == "") {
      const classList = ["error-snackbar"];
      this.data.showSnackBar("Caption text cannot be empty.", classList)
      return false;
    }
    else {
      return true;
    }
  }

  updateData() {
    let myString = "WEBVTT\n\n";
    for (let item of this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData) {
      myString += '00' + ':' + item.Start.min + ':' + item.Start.sec + '.' + item.Start.msec + ' --> ' + '00' +
        ':' + item.End.min + ':' + item.End.sec + '.' + item.End.msec + '  size:' + item.size.value + '%\n' + item.caption_text + '\n\n';

      item.size.valid = true;
      item.Start.valid = true;
      item.End.valid = true;
    }
    this.data.scenes[this.data.selectedSceneIndex].captionStr = myString;

    let messageStatus = this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.map((element:any) => {
      if (this.verifyMessageField(element.caption_text)) return "true"; else return "false";
    })

    let timestatus = this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.map((element:any, index:any) => {
      if (this.checkTime(element, index)) return "true"; else return "false";
    })
    if (!timestatus.includes("false") && !messageStatus.includes("false")) {

      const blob = new Blob([myString], { type: 'text/vtt' });

      const captionUrl = URL.createObjectURL(blob);
      this.data.scenes[this.data.selectedSceneIndex].captionBlob = blob;
      this.data.scenes[this.data.selectedSceneIndex].captionUrl = captionUrl;
      if (this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.length === 0) {
        this.data.scenes[this.data.selectedSceneIndex].isCaptionAvailable = false;
        this.data.scenes[this.data.selectedSceneIndex].enableTranscript = false;
        this.data.scenes[this.data.selectedSceneIndex].videoMeta.captions = {
          "is-available": this.data.scenes[this.data.selectedSceneIndex].isCaptionAvailable,
          "enable-transcript": this.data.scenes[this.data.selectedSceneIndex].enableTranscript,
          "src": "",
          "kind": "captions",
          "srclang": "en",
          "label": "English"
        }
      } else {
        this.data.scenes[this.data.selectedSceneIndex].isCaptionAvailable = true;
        this.data.scenes[this.data.selectedSceneIndex].enableTranscript = true;
        for(let i=0; i<this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.length; i++){
          this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].isDurationChanged = false;
        }
        this.data.scenes[this.data.selectedSceneIndex].videoMeta.captionTableData = this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData
        this.data.scenes[this.data.selectedSceneIndex].videoMeta.captions = {
          "is-available": this.data.scenes[this.data.selectedSceneIndex].isCaptionAvailable,
          "enable-transcript": this.data.scenes[this.data.selectedSceneIndex].enableTranscript,
          "src": this.data.scenes[this.data.selectedSceneIndex].captionUrl,
          "kind": "captions",
          "srclang": "en",
          "label": "English"
        }
      }
      this.data.overlayAnnotations()
    }

  }


  closeWindow() {
    this.dialog.closeAll();
  }

  _keydown(event: any) {

    const inputChar = event.keyCode
    const specialSym = /[\\\!\@\#\$\%\^\&\*\(\)\_\+\-\=\<\>\,\/\`\:\;\'\"\.\?\|\~]/
    const aplhabets = inputChar >= 65 && inputChar <= 90
    const ctrl = event.ctrlKey ? event.ctrlKey : ((inputChar === 17) ? true : false);

    if ((inputChar !== 67 || inputChar !== 86 || inputChar !== 65) && !ctrl
      && (specialSym.test(event.key) || aplhabets)) {
      this.data.showSnackBar("invalid character", this.classList);
      event.preventDefault();
    }
  }

  uploadCaption(event:any) {
    const file = event.srcElement.files[0];
    this.uploadFile(file)
  }

  uploadExistingCaption(blobFile:any) {
    this.uploadFile(blobFile)
  }

  uploadFile(file:any) {

    let reader = new FileReader()
    reader.onloadend = () => {
      const contents = reader?.result?.toString();
      const arr = contents?.match(/[^\r\n]+/g);
      this.extractCaptionData(arr)
    };

    reader.onerror = function (event) {
      console.error("File could not be read! Code " + reader?.error?.code);
    };

    reader.readAsText(file);
  }

  extractCaptionData(capArray:any) {
    let index:any = 0;
    let start:any;
    let end:any;
    this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData = []
    for (let i = 1; i < capArray.length; i++) {
      if (capArray[i].length <= 3 && !isNaN(parseInt(capArray[i]))) {
        continue;
      }
      else if (capArray[i].substr(2, 1) === ":" && capArray[i].substr(5, 1) === ":") {
        index = index + 1;
        let startTime = capArray[i].substr(3, 2) + capArray[i].substr(6, 2) + capArray[i].substr(9, 3)
        start = parseInt(startTime.substr(0, 2))*60 + parseInt(startTime.substr(2, 2)) + parseInt(startTime.substr(4, 3))*0.001
        let endTime = capArray[i].substr(20, 2) + capArray[i].substr(23, 2) + capArray[i].substr(26, 3)
        end = parseInt(endTime.substr(0, 2))*60 + parseInt(endTime.substr(2, 2)) + parseInt(endTime.substr(4, 3))*0.001
        let textWidth = capArray[i].substr(36, 3)
        let width = ""

        this.captionData.Start = {
          min: startTime.substr(0, 2),
          sec: startTime.substr(2, 2),
          msec: startTime.substr(4, 3),
          valid: true
        }

        this.captionData.End = {
          min: endTime.substr(0, 2),
          sec: endTime.substr(2, 2),
          msec: endTime.substr(4, 3),
          valid: true
        }

        if (textWidth[2] === "%") {
          width = textWidth.substr(0, 2)
        } else {
          width = textWidth
        }

        this.captionData.size = {
          value: width,
          valid: true
        }

        if (startTime.match(/[a-z\\\!\@\#\$\%\^\&\*\(\)\_\+\-\=\<\>\,\/\`\:\;\'\"\.\?\|\~]/i)) {
          this.captionData.Start.valid = false;
        }
        if (endTime.match(/[a-z\\\!\@\#\$\%\^\&\*\(\)\_\+\-\=\<\>\,\/\`\:\;\'\"\.\?\|\~]/i)) {
          this.captionData.End.valid = false;
        }
        if (width.match(/[a-z\\\!\@\#\$\%\^\&\*\(\)\_\+\-\=\<\>\,\/\`\:\;\'\"\.\?\|\~]/i)) {
          this.captionData.size.valid = false;
        }
        
      }
      else {
        if (capArray[i - 1].substr(2, 1) !== ":" && capArray[i - 1].substr(5, 1) !== ":") {
          this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.length - 1].caption_text += '\n' + capArray[i]
        }
        else {
          this.captionData.caption_text = capArray[i];
          this.tool.initializeCaptionOverlayObject('caption');
        this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[index-1].start = start;
        this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[index-1].end = end;
        this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[index-1].Start = this.captionData.Start;
        this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[index-1].End = this.captionData.End;
        this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[index-1].caption_text = this.captionData.caption_text;
        this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[index-1].value = this.captionData.caption_text;
        this.updateCurrentTime(this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[index-1].start);
        this.updateCurrentTime(this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[index-1].end);
        this.setStartTimeMinVal()
        }

        this.captionData = {}
      }

    }
  }

  checkTime(captionTimes:any, i:any) {
    const startTime = captionTimes.Start, endTime = captionTimes.End
    let validStatus = true;
    let startMin:any, endMin, startSec:any, endSec:any, startMsec, endMsec, startDur, endDur, textLength;
    startMin = parseInt(startTime.min);
    endMin = parseInt(endTime.min);
    startSec = parseInt(startTime.sec);
    endSec = parseInt(endTime.sec);
    startMsec = parseInt(startTime.msec);
    endMsec = parseInt(endTime.msec);
    startDur = parseFloat(startMin * 60 + startSec + startMsec * 0.001);
    endDur = parseFloat(endMin * 60 + endSec + endMsec * 0.001);

    textLength = parseInt(captionTimes.size.value);

    // document!.getElementById('start-time-' + i)!.style.borderColor = "#ccc"
    // document!.getElementById('end-time-' + i)!.style.borderColor = "#ccc"
    // document!.getElementById('text-length-' + i)!.style.borderColor = "#ccc"


    if (startTime.sec === "" || startTime.msec === "" || captionTimes.start == "") {
      this.data.showSnackBar("Please enter valid start time", this.classList);
      // document!.getElementById('start-time-' + i)!.style.borderColor = "#de3535"
      validStatus = false
    }

    if (endTime.sec === "" || endTime.msec === "" || captionTimes.end == "") {
      this.data.showSnackBar("Please enter valid end time", this.classList);
      // document!.getElementById('end-time-' + i)!.style.borderColor = "#de3535"
      validStatus = false
    }

    if (startDur > endDur || startDur > this.data.videoDuration || captionTimes.start > captionTimes.end || captionTimes.start > this.data.videoDuration) {
      this.data.showSnackBar("START time must be less than the END time.", this.classList);
      // document!.getElementById('start-time-' + i)!.style.borderColor = "#de3535"
      validStatus = false
    }

    if (endDur > this.data.videoDuration || captionTimes.end > this.data.videoDuration) {
      this.data.showSnackBar("END time must be less than the VIDEO DURATION time.", this.classList);
      const time = new Date(this.data.videoDuration * 1000).toISOString().substr(11, 12);
      this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].End = { min: time.substr(3, 2), sec: time.substr(6, 2), msec: time.substr(9, 3) };
      // document!.getElementById('end-time-' + i)!.style.borderColor = "#de3535"
      validStatus = false

    }

    if (textLength > 100 || captionTimes.size.value === "") {
      this.data.showSnackBar("Caption width should be less than or equal to 100%", this.classList);
      document!.getElementById('text-length-' + i)!.style.borderColor = "#de3535"
      validStatus = false;
    }

    if (captionTimes.caption_text === "") {
      this.data.showSnackBar("Please enter caption text.", this.classList);
      document!.getElementById('text-content-' + i)!.style.borderColor = "#de3535"
      validStatus = false;
    }

    return validStatus
  }

  trackByFn(index: number, item: any) {  
    return item;  
  }

  changeAnnotationValue(i:any) {
    this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].value = this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].caption_text;
    this.caption_annotationTableDataEvent.emit(this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData);
  }

  getAnnotationTableId(overlayObj:any):string {
    for (let index in this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData) {
      if (this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[index].type === "tts" && overlayObj.type === "tts" && overlayObj.id === this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[index].id) {
        return index;
      } else if (this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[index].type !== "tts" && overlayObj.type !== "tts" && overlayObj.id === this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[index].id) {
        return index;
      }
    }
    return '';
  }

  overlayAnnotations(updateAnnotation?: any){
    this.overlayAnnotationsEvent.emit(updateAnnotation);
    }
  
    setDraggerPosition(){
      this.setDraggerPositionEvent.emit();
    }

  loadElement(updatedOverlays?:any) {
    if (!(this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.value === undefined)) {
      this.overlayAnnotations(updatedOverlays);
      this.setDraggerPosition();
    }
  }

  minimizeToolBar() {
    this.onMinimizing.emit(true);
  }

  changeStartTime(startTime: any,endTime: any, i:any) {
    this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].isDurationChanged = true;
    var sTime = parseFloat(startTime)
     if(sTime <= 0){
      sTime = 0.001;
     }
     var eTime = parseFloat(endTime)
     if(eTime>sTime){
       if (this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].start) {
         if(this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].start <= 0){
          this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].start = 0.001;
         }
         this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].duration = endTime - startTime; 
         const StartEndTime = this.data.calTime(startTime, endTime)
         this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].Start = {
          min: StartEndTime[0].substr(0,2),
          sec: StartEndTime[0].substr(3,2),
          msec: StartEndTime[0].substr(6,3),
          valid: true
        }
        this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].End = {
          min: StartEndTime[1].substr(0,2),
          sec: StartEndTime[1].substr(3,2),
          msec: StartEndTime[1].substr(6,3),
          valid: true
        }
        this.updateCurrentTime(this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].start);
        this.setStartTimeMinVal()
       }
     }
     else{
       let classList = ["error-snackbar"];
       this.showSnackBar("`START TIME` time must be less than the `END TIME` time.", classList);
       return;
     }
   }


   showSnackBar(message: any, classList: any) {
    const config = new MatSnackBarConfig();
    config.horizontalPosition = "right";
    config.verticalPosition = "bottom";
    config.panelClass = classList;
    config.duration = 3000;
    this.data.snackBar.open(message, 'Ok', config);
  }
 
   changeEndTime(startTime: any ,endTime: any, i:any) {
    this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].isDurationChanged = true;
      var sTime = parseFloat(startTime)
     var eTime = parseFloat(endTime)
     if (eTime>this.data.videoDuration){
       let classList = ["error-snackbar"];
       this.showSnackBar("`END TIME` time must be less than the `Video Duration` time.", classList);
       return;
     }
 
     if (eTime > sTime) {
      const EndTime = this.data.calTime(startTime, endTime);
      this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].Start = {
        min: EndTime[0].substr(0,2),
        sec: EndTime[0].substr(3,2),
        msec: EndTime[0].substr(6,3),
        valid: true
      }
      this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].End = {
        min: EndTime[1].substr(0,2),
        sec: EndTime[1].substr(3,2),
        msec: EndTime[1].substr(6,3),
        valid: true
      }
      this.updateCurrentTime(this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].start);
      this.setStartTimeMinVal()
     }
     else{
       let classList = ["error-snackbar"];
       this.showSnackBar("`END TIME` time must be greater than the `START TIME` time.", classList);
       return;
     }
   }

  setStartTimeMinVal(){
      this.captionComponentOutput.emit(this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData);
         this.loadElement(this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData);
        this.notifyDurationChange("notified");
        this.notifyDurationChangeInToolbar('changed');
  }

   changeTimelineStartTime(start:any, end:any, i:any){
    start = "" + start;
    end = "" + end;
    const StartEndTime = this.data.calTime(start, end)
         this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].Start = {
          min: StartEndTime[0].substr(0,2),
          sec: StartEndTime[0].substr(3,2),
          msec: StartEndTime[0].substr(6,3),
          valid: true
        }
        this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].End = {
          min: StartEndTime[1].substr(0,2),
          sec: StartEndTime[1].substr(3,2),
          msec: StartEndTime[1].substr(6,3),
          valid: true
        } 
        this.updateCurrentTime(this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].start);
        this.setStartTimeMinVal() 
   }

  updateCurrentTime(currentTime:number){
    this.updateCurrentTimeEvent.emit(currentTime);
  }
  notifyDurationChange(message:any){
    this.notifyDurationChangeEvent.emit(message);
  }
  notifyDurationChangeInToolbar(message:string){
    this.notifyDurationChangeInToolbarEvent.emit(message);
  }

}
