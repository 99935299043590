import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BgVideosComponent } from '../bg-videos/bg-videos.component';
import { TemplateModalComponent } from '../editor/template/template-modal/template-modal.component';
import { TemplateStatusModalComponent } from '../editor/template/template-status-modal/template-status-modal.component';
import { PsvTemplate } from '../shared/interfaces/Interface_template';
import { DataService } from '../shared/services/data.service';
import { ReplaceTempUrlsService } from '../shared/services/replace-temp-urls.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
  

  constructor(readonly data: DataService, readonly replaceTempUrlsService: ReplaceTempUrlsService , private router: Router, public dialog: MatDialog ) { }

  ngOnInit(): void {
  }

  async onPublish() {
    if (Object.keys(this.data.userData).length == 0 || this.data.isEmptyFieldsPresentInUserData(this.data.userData)) {
      this.data.showSnackBar("Please configure userdata before publishing", "close");
      return;
    }

    this.data.publishLoader = true;
    this.data.isFrompreview = false;
    this.data.saveLoaded = true;
    var projectName = this.data.titleValue;
    this.data.notifyOnPublishClick('clicked');

    if (this.data.projectModel.id) {
      await this.data.updateScenesAndPSV();
    }
    else {
      await this.data.storeScenesAndPSV();
    }
    await this.replaceTempUrlsService.replaceTempUrls();
    await this.data.updateScenesAndPSV();
    await this.concatenate();
  }

  async concatenate() {

    await this.data.concatenatedDataOnPreview().then(() => {
      this.router.navigate(['/publish']);
      this.data.isPublishScreen = true;
    });
  }

  reloadData() {
    this.data.isPublishClicked = false;
    this.data.onCreateVideo = false;
    this.data.reload = true;
  }
  openTemplateModal(){
     //userdata check
     if (Object.keys(this.data.userData).length == 0 || this.data.isEmptyFieldsPresentInUserData(this.data.userData)) {
      this.data.showSnackBar("Please configure userdata before publishing", "close");
      return;
    }
    let dialogRef = this.dialog.open(TemplateModalComponent, {
      width: '500px',
      height: '250px',     
      
    })
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result) {
        this.publishTemplate(result);
      }
    });
  }

  async publishTemplate(templateTitle: string) {
    this.data.saveLoaded = true;
    //storing or updating the project
    this.data.projectModel.createdBy = this.data.userId;
    if (!this.data.projectModel.id) {
      await this.data.storeScenesAndPSV();
    }
    else {
      await this.data.updateScenesAndPSV();
    }
    await this.replaceTempUrlsService.replaceTempUrls();
    await this.data.updateScenesAndPSV();
    //upload Userdata
    this.data.userDataObj['userData'] =this.data.formatUserDataOutput();
    await this.data.addNewUserData(this.data.userDataObj).then((userDataId)=>{
      //generating the template
      this.data.generateTemplate(templateTitle, this.data.userId, userDataId).then((psvTemplate) => {
        // saving template
        this.data.storeTemplate(psvTemplate).then(async (result: any) => {
          let templateObj = result;
          console.log(result, 'successfully template saved');
            // concat Api call
            await this.data.concatVideos(result._id).then((result) => {
              this.data.saveLoaded = false;
              let dialogRef = this.dialog.open(TemplateStatusModalComponent, {
                width: '500px',
                height: '200px',     
              });
          }).catch(err =>{
            this.data.updateTemplateStatus(templateObj._id, 'Failed').then((result) => {
              let dialogRef = this.dialog.open(TemplateStatusModalComponent, {
                width: '500px',
                height: '200px'
              });
              dialogRef.componentInstance.isErrorMessage = true;
            })
          })
        })
      })
      });
  }

}
    
