<!-- <b class="main-headings">PSV Analytics</b> -->
<div>
    <b class="main-headings" *ngIf = "!templateId">{{this.data.analyticsProjectTitle}}</b>
</div>
<div>
    <b class="main-headings" *ngIf = "templateId">{{this.data.analyticsTemplateTitle}}</b>
</div>
<!-- <div class="tile-container" *ngIf = "!templateId">
    <div class="tile">
        <div class="tilecontent">
            <div class="status">{{totalLinks||0}}</div>
            My Projects
        </div>

    </div>
    <div class="tile">
        <div class="tilecontent">
            <div class="status">{{views||0}}</div>
            My Templates
        </div>
    </div>
    <div class="tile">
        <div class="tilecontent">
            <div class="status">{{totalLinks||0}}</div>
            Total Links
        </div>

    </div>
    <div class="tile">
        <div class="tilecontent">
            <div class="status">{{views||0}}</div>
            Total Views
        </div>
    </div>
    <mat-form-field class="date-field">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input class="date-input" [formGroup]="range" [rangePicker]="publish">
            <input matStartDate formControlName="start" placeholder="Start date" (dateChange)="publishTime()">
            <input matEndDate formControlName="end" placeholder="End date" (dateChange)="publishTime()">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="publish"></mat-datepicker-toggle>
        <mat-date-range-picker #publish></mat-date-range-picker>
    </mat-form-field>

</div> -->
<div class="tile-container" *ngIf="templateId && this.totalPsvLinks">
    <div class="data-tiles">
        <div class="tile">
            <div class="tilecontent">
                <div class="status">{{totalPsvLinks.length||0}}</div>
                Total Links
            </div>

        </div>
        <div class="tile">
            <div class="tilecontent">
                <div class="status">{{templateViews||0}}</div>
                <!-- Total PSVs -->
                Total Views
            </div>
        </div>
        <div class="tile">
            <div class="tilecontent">
                <div class="status">{{totalUniqueViews||0}}</div>
                Total Unique Views
            </div>
        </div>
        <div class="tile" *ngIf="averageWatchTime">
          <div class="tilecontent">
              <div class="status">{{secondsToMinutes(averageWatchTime)}}</div>
              Average watch time
          </div>
      </div>
    </div>
    <!-- <div *ngIf="watchChartFlag" class="chart-style" style="width:22%;margin:0">
        <canvas baseChart [data]="watchChartData"  [type]="'pie'" [legend]="true">
        </canvas>
    </div> -->
</div>
<div class="chart-tile">
    <!-- <div class="chart-style">
        <canvas id="statusChart"></canvas>
    </div>
    <div class="label-container" >
        <div *ngFor="let label of funnelLabels">
            <span>{{label}}</span>
        </div>
    </div> -->
    <div *ngIf="viewsChartFlag" class="chart-style">
        <canvas baseChart [data]="viewsChartData" [type]="'line'" [legend]="false" [options]="lineChartOptions">
        </canvas>
    </div>
</div>


<div style="margin-top: 4%;margin-bottom: 3%;" *ngIf="averageWatchTime">
  <p style="float: right;margin-right: 10%;font-weight: bold;font-size: 1.6rem;" >Video duration : {{secondsToMinutes(templateVideoDuration)}}</p>
<table style="width: 90%;border: outset;border-color: rgba(255, 255, 255, 0.534);table-layout: fixed;font-size: 2rem;text-align-last: center;">
    <tr>
      <th>Generated Links</th>
      <th>Watched Time</th>
      <th>Watched duration</th>
      <th>Watched User</th>
      <th>Watch Percentage</th>
    </tr>
    <tr *ngFor="let links of this.totalLinkandView">
      <td style="font-size: 1.6rem;text-align-last: left;" >{{links.url}}</td>
      <td style="font-size: 1.6rem;" >{{links.watchTime | date:'short'}} </td>
      <td style="font-size: 1.6rem;" >{{secondsToMinutes(links.totalPlayed)}}</td>
      <td style="font-size: 1.6rem;" >{{links.userFirstName}}</td>
      <td style="font-size: 1.6rem;" >{{links.viewPercentage}}</td>
    </tr>
  </table>

<!-- <div style="margin-top: 3%;width:90%">
  <b>Generated Links</b> <b style="float:right;">Actual watch status</b>
  <hr style="height: 2px;background-color:black" >
</div>
  <div *ngFor="let links of this.totalLinkandView">
      <div class="generatedLinks" >
          <p style="float: left;font-style: oblique;">{{links.url}} | Watched : {{links.totalPlayed}} | Duration : {{links.videoDuration}}</p>
          <p *ngIf="links.viewPercentage >= 0 && links.viewPercentage <= 25" style="float: right;"> 0 - 25% </p>
          <p *ngIf="links.viewPercentage >= 25 && links.viewPercentage <= 50" style="float: right;"> 25 - 50% </p>
          <p *ngIf="links.viewPercentage >= 50 && links.viewPercentage <= 75" style="float: right;"> 50 - 75% </p>
          <p *ngIf="links.viewPercentage >= 75 && links.viewPercentage <= 100" style="float: right;"> 75 - 100% </p>
      </div>
      <mat-progress-bar style="width: 90%;margin-bottom: 1%;" mode="determinate" [value]="links.viewPercentage" ></mat-progress-bar>
  </div> -->
</div>
