<div [ngClass]="{'dim': this.data.saveLoaded}" class="container containers-common-style">
  <button class="sampleUserdataBtn containers-common-style sec-headings-icons " type="button" (click)="openUserDataDialog()"
    matTooltip="Edit User Data">
<strong>    Sample User Data </strong> <mat-icon style="font-size: 1.5em; padding-top: 0.3em;">edit</mat-icon> 
  </button>
  <form class="form" [formGroup]="inputForm">
    <div #scriptBound [ngClass]="{'script-container':script.length>6}" formArrayName="script" cdkDropList
      (cdkDropListDropped)="drop($event)">
      <div class="script-sub-container" [cdkDragBoundary]="scriptBound" *ngFor="let item of script.controls;index as i" cdkDrag>
        <textarea #scriptContent class="script-input-area containers-common-style" [formControlName]="i"
          [value]="data.scriptArray[i]" rows="4" (change)="scriptContentChange(scriptContent.value,i)"></textarea>
      </div>
    </div>
  </form>
  <button class="add-section-btn containers-common-style sec-headings-icons" (click)="addSection()">
    <em class="material-icons">add</em>Add Scene</button>
</div>
<div>
  <app-userdata *ngIf="datafields" (cancel)="datafields=false"></app-userdata>
</div>