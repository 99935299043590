import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { DataService } from '../shared/services/data.service';
import { StorageService } from '../shared/services/storage.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(readonly data: DataService, readonly storageService: StorageService, private router: Router, readonly keycloak: KeycloakService) { }

  ngOnInit(): void { }

}