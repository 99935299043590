import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DomSanitizer } from '@angular/platform-browser';
import RecordRTC from 'recordrtc';

@Component({
  selector: 'lib-liveVideo-overlay',
  templateUrl: './live-video-overlay.html',
  styleUrls: ['./live-video-overlay.css'
  ]
})
export class LiveVideoOverlayComponent implements OnInit {

  recorder:any;
  // @ViewChild('video') video: any;
  videoElement: any;
  videoSrc: any;
  aspectRatio: any;
  isRecording:boolean = false;
  // projectName: any = this.data.titleValue;
  

  @Input() liveVideoOverlayComponent:any = {};
  @Output() change = new EventEmitter<MatSlideToggleChange>();
  @Output() updateCurrentTimeEvent = new EventEmitter();
  @Output() downloadEvent = new EventEmitter();
  @Output() closeEvent = new EventEmitter();


  @Input() overlayJSON: any;
  @Input() selectedAnnotation: any;
  @Input() annotationTableData: any = [];
  @Input() currentTime: any;
  @Input() userData: any = {};
  @Input() fontSize: any;

  @Output() overlayJSONEvent = new EventEmitter<any>();
  @Output() overlayAnnotationsEvent = new EventEmitter<any>();
  @Output() annotationTableDataEvent = new EventEmitter<any>();
  @Output() selectedAnnotationEvent = new EventEmitter<any>();
  // @Output() liveVideoOverlayComponentOutput = new EventEmitter<any>();



  updateCurrentTime(currentTime: number) {
    this.updateCurrentTimeEvent.emit(currentTime);
  }
  loadElement(updatedOverlayAnnotation?: any) {
    this.overlayAnnotations();
  }

  overlayAnnotations(updateAnnotation?: any) {
    this.selectedAnnotationEvent.emit(this.liveVideoOverlayComponent);
    this.annotationTableDataEvent.emit(this.annotationTableData);
    this.overlayAnnotationsEvent.emit(updateAnnotation);
  }
  getAnnotationTableId(overlayObj: any) {
    for (let index in this.annotationTableData) {
      if (this.annotationTableData[index].type === "tts" && overlayObj.type === "tts" && overlayObj.id === this.annotationTableData[index].id) {
        return index;
      } else if (this.annotationTableData[index].type !== "tts" && overlayObj.type !== "tts" && overlayObj.id === this.annotationTableData[index].id) {
        return index;
      }
    }
    return "";
  }






  constructor(readonly sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }


  ngAfterViewInit() {
    this.videoElement= document.querySelector("#vid");
    }
    
    captureCamera(callback:any) {
      navigator.mediaDevices.getUserMedia
      ({
        audio: {echoCancellation:true},
        video: true 
      }).then(function(camera) {
          callback(camera);
      }).catch(function(error) {
          alert('Unable to capture your camera. Please check console logs.');
          console.error(error);
      });
    }
    
    stopRecordingCallback() {
    // let video:any = document.querySelector("#vid");
    this.videoElement.src = null;
    this.videoElement.srcObject = null;
      this.videoSrc = URL.createObjectURL(this.recorder.getBlob());
      this.videoElement.src = this.videoSrc;
      this.recorder.camera.stop();
      // this.recorder.destroy();
      // this.recorder = null;
    }
    
    startRecordingClick() {
      //this.disabled = true;
      this.isRecording = true;
      this.captureCamera((camera:any) => {
    
          this.videoElement.srcObject = camera;

        
          this.recorder = new RecordRTC(camera,{
            mimeType: 'video/mp4', // or video/webm\;codecs=h264 or video/webm\;codecs=vp9
            audioBitsPerSecond: 128000,
            videoBitsPerSecond: 128000,
            bitsPerSecond: 128000 // if this line is provided, skip above two
          });
          this.recorder.startRecording();
          // release camera on stopRecording
          this.recorder.camera = camera;
      });
    };
    
    stopRecordingClick() {
      this.isRecording = false;
      this.recorder.stopRecording(this.stopRecordingCallback.bind(this));
    }



    download(){
      
      let mediaBolb = this.recorder.getBlob();
      this.downloadEvent.emit(mediaBolb);
      console.log('saved');
      this.recorder.destroy();
      this.recorder = null;
      
    }

    close(){
      this.closeEvent.emit();
    }

}
