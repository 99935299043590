/*******************************************************************************
 * COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
 * Reserved. Infosys believes the information in this document is accurate as of
 * its publication date; such information is subject to change without notice.
 * Infosys acknowledges the proprietary rights of other companies to the
 * trademarks, product names and such other intellectual property rights
 * mentioned in this document. Except as expressly permitted, neither this
 * documentation nor any part of it may be reproduced, stored in a retrieval
 * system, or transmitted in any form or by any means, electronic, mechanical,
 * printing, photocopying, recording or otherwise, without the prior permission
 * of Infosys Limited and/or any named intellectual property rights holders
 * under this document.
 ******************************************************************************/
 import { Component, OnInit, Input,  Output, EventEmitter, OnChanges } from '@angular/core';
 import { faPlusCircle, faMinusCircle, faStar, faCaretRight } from '@fortawesome/free-solid-svg-icons';
 import { MatSliderModule } from '@angular/material/slider';
import { PSVCommonPropertiesService } from './psv-common-properties.service';
 
 @Component({
   selector: 'psv-common-properties',
   templateUrl: './psv-common-properties.component.html',
   styleUrls: ['./psv-common-properties.component.css']
 })
 export class PSVCommonPropertiesComponent implements OnInit, OnChanges {
 
   faStar:any = faStar;
   faPlusCircle:any = faPlusCircle;
   faMinusCircle:any = faMinusCircle;
   faCaretRight:any = faCaretRight;
   overlayImageConfig: any;
   overlayLinkConfig: any;
   entryAnimationDuration = 500;
   exitAnimationDuration = 500;
   entryAnimation = "";
   exitAnimation = "";
   entryAnimationList:any;
   exitAnimationList:any;
   isToolBarOpen:any;
   imageOverlayComponent:any;
 
  //  @ViewChild(OverlayImageComponent,{static: true}) imageComponent: any;      
  //  @ViewChild(OverlayLinkTelComponent,{static: true}) linkComponent: any;
   @Input() newOverlayAnnotation:any;
   @Output() onMimimizing = new EventEmitter<boolean>();
   @Input() videoDuration:any;
   @Input() overlayJSON:any;
   @Input() annotationTableData:any;


   @Output() overlayJSONOutput = new EventEmitter<any>();
   @Output() annotationTableDataOutput = new EventEmitter<any>();


   @Output() updateCurrentTimeEvent = new EventEmitter<any>();
   @Output() notifyDurationChangeEvent = new EventEmitter<any>();
   @Output() notifyDurationChangeInToolbarEvent = new EventEmitter<any>();
   @Output() notifyAnnotationSwitchEvent = new EventEmitter<any>();
   @Output() overlayAnnotationsEvent = new EventEmitter<any>();
   @Output() newOverlayAnnotationOutput = new EventEmitter<any>();
   
   currentPlayerTime: any;
   annotationSwitchNotify: any;
   player: any;
   isCaptionAvailable: any;
   enableTranscript: any;
   captionUrl: any;
   aspectRatio: any;
   videoSize: any;
   tts: any;
   posterUrl: any;
   isDashAvailable: any;
   videoName: any;
   videoSrc: any;

   fontSize: any;
   setBgColor: any;
   
   
    constructor(readonly  data: PSVCommonPropertiesService) { }
 
   ngOnInit() { 
     this.data.getAnimationList()
         .subscribe((animationList: { entryAnimations: any; exitAnimations: any; }) => {
           this.entryAnimationList = animationList.entryAnimations;
           this.exitAnimationList = animationList.exitAnimations;
         });
       
   }
 
   ngOnChanges() {
     if(this.newOverlayAnnotation.cssProperties.animation ) {
       this.entryAnimation = this.newOverlayAnnotation.cssProperties.animation.split(' ')[0];
       this.entryAnimationDuration = parseFloat(this.newOverlayAnnotation.cssProperties.animation.split(' ')[1].replace("s", "")) * 1000;
     } else {
       this.entryAnimation = "";
       this.entryAnimationDuration = 500;
     }
     if(this.newOverlayAnnotation.exitAnimation) {
       this.exitAnimation = this.newOverlayAnnotation.exitAnimation.split(' ')[0];
       this.exitAnimationDuration = parseFloat(this.newOverlayAnnotation.exitAnimation.split(' ')[1].replace("s", "")) * 1000; 
     } else {
       this.exitAnimation = "";
       this.exitAnimationDuration = 500;
     }

     this.loadElement(this.newOverlayAnnotation);
   }
 
   changeStartTime(startTime: any,endTime: any) {
    this.newOverlayAnnotation.isDurationChanged = true;
     startTime = parseFloat(startTime)
     if(startTime <= 0){
       startTime = 0.001;
     }
     endTime = parseFloat(endTime)
     if(endTime>startTime){
       if (this.newOverlayAnnotation.start) {
         if(this.newOverlayAnnotation.start <= 0){
          this.newOverlayAnnotation.start = 0.001;
         }
        this.newOverlayAnnotation.duration = endTime - startTime;
         this.updateCurrentTime(this.newOverlayAnnotation.start);
         this.newOverlayAnnotationOutput.emit(this.newOverlayAnnotation);
         this.loadElement(this.newOverlayAnnotation);
         this.notifyDurationChange("notified");
         this.notifyDurationChangeInToolbar('changed');
       }
     }
     else{
       let classList = ["error-snackbar"];
       this.data.showSnackBar("`START TIME` time must be less than the `END TIME` time.", classList);
       return;
     }
     
   }
 
   changeEndTime(startTime: any ,endTime: any) {
    this.newOverlayAnnotation.isDurationChanged = true;
     startTime = parseFloat(startTime)
     endTime = parseFloat(endTime)
     if (endTime>this.videoDuration){
       let classList = ["error-snackbar"];
       this.data.showSnackBar("`END TIME` time must be less than the `Video Duration` time.", classList);
       return;
     }
 
     if (endTime > startTime) {
       this.newOverlayAnnotation.duration = endTime - startTime;
      this.loadElement(this.newOverlayAnnotation);
      this.notifyDurationChange("notified");
      this.notifyDurationChangeInToolbar('changed');
     }
     else{
       let classList = ["error-snackbar"];
       this.data.showSnackBar("`END TIME` time must be greater than the `START TIME` time.", classList);
       return;
     }
     
   }
 
   setStartTimeMinVal(){
     let startTime = parseFloat(this.newOverlayAnnotation.start)
     if(startTime <= 0){
         this.newOverlayAnnotation.start = 0.001;
         this.newOverlayAnnotation.duration = this.newOverlayAnnotation.end - startTime;
         this.newOverlayAnnotationOutput.emit(this.newOverlayAnnotation);
         this.loadElement( this.newOverlayAnnotation);
         this.notifyDurationChange("notified");
         this.notifyDurationChangeInToolbar('changed');
     }
  
   }
 
   updateOpacity(event: any) {
     this.newOverlayAnnotation.cssProperties.opacity = event.value / 100;
     this.newOverlayAnnotationOutput.emit(this.newOverlayAnnotation);
     this.loadElement();
     
   }
 
   updateXCoordinate(number: any) {
     this.newOverlayAnnotation.cssProperties.left = `${number}%`;
     this.newOverlayAnnotationOutput.emit(this.newOverlayAnnotation);
     this.loadElement();
     this.notifyAnnotationSwitch("notified");
   
   }
 
   updateYCoordinate(number: any) {
     this.newOverlayAnnotation.cssProperties.paddingTop = `${number}%`;
     this.newOverlayAnnotationOutput.emit(this.newOverlayAnnotation);
     this.loadElement();
     this.notifyAnnotationSwitch("notified");
    
   }
 
   minimizeToolBar() {
     this.onMimimizing.emit(true);
   }
 
   updateRotation(event: { value: any; }) {
     this.newOverlayAnnotation.cssProperties.rotation = `rotate(${event.value}deg)`;
     this.newOverlayAnnotationOutput.emit(this.newOverlayAnnotation);
     this.loadElement();
    
   }
 
   updateEntryAnimation() {
     const entryAnimation = `${this.entryAnimation} ${(this.entryAnimationDuration / 1000)}s`;
     this.newOverlayAnnotation.cssProperties.animation = entryAnimation;
     this.updateCurrentTime(this.newOverlayAnnotation.end - (this.exitAnimationDuration / 1000));
     this.updateCurrentTime(this.newOverlayAnnotation.start);
     this.newOverlayAnnotationOutput.emit(this.newOverlayAnnotation);
     this.loadElement();
     
   }
 
   increaseEntryAnimationDuration() {
     this.entryAnimationDuration = this.entryAnimationDuration + 1;
     this.updateEntryAnimation();
   }
 
   decreaseEntryAnimationDuration() {
     this.entryAnimationDuration = this.entryAnimationDuration - 1;
     this.updateEntryAnimation();
   }
 
   updateExitAnimation() {
     const exitAnimation = `${this.exitAnimation} ${(this.exitAnimationDuration / 1000)}s`;
     this.newOverlayAnnotation.exitAnimation = exitAnimation;
     setTimeout(() => {
       const overlayObjects = document.querySelectorAll("#psv-overlay-player .custom-overlays");
       (overlayObjects[this.newOverlayAnnotation.id - 1] as HTMLElement).style.animation = exitAnimation;
     }, 10);
     this.updateCurrentTime(this.newOverlayAnnotation.end - (this.exitAnimationDuration / 1000));
     this.newOverlayAnnotationOutput.emit(this.newOverlayAnnotation);
     this.loadElement();
   }
 
   increaseExitAnimationDuration() {
     this.exitAnimationDuration = this.exitAnimationDuration + 1;
     this.updateExitAnimation();
   }
 
   decreaseExitAnimationDuration() {
     this.exitAnimationDuration = this.exitAnimationDuration - 1;
     this.updateExitAnimation();
   }
   
 
   loadElement(updatedOverlays?: any) {
    
     if (!((["tel", "link"].indexOf(this.newOverlayAnnotation.type) >= 0 &&
       this.newOverlayAnnotation.message === undefined) ||
       (this.newOverlayAnnotation.type === "text" &&
         this.newOverlayAnnotation.value === undefined) ||
       (this.newOverlayAnnotation.type === "chart" &&
         this.newOverlayAnnotation.data === undefined &&
         this.newOverlayAnnotation.data.labels.length === 0))) {
         this.overlayAnnotations( updatedOverlays);
         }
         
   }
   updateCurrentTime(currentTime: number) {
    this.updateCurrentTimeEvent.emit(currentTime);
  }
  notifyDurationChange(message: string) {
    this.notifyDurationChangeEvent.emit(message);
  }

  notifyDurationChangeInToolbar(message:string){
    this.notifyDurationChangeInToolbarEvent.emit(message);
  }

  notifyAnnotationSwitch(message: string) {
    this.notifyAnnotationSwitchEvent.emit(message);
 }
 async overlayAnnotations(updateAnnotation: any) {
   this.overlayAnnotationsEvent.emit(updateAnnotation);
   
  }
  overlayJSONFunction(overlayJSON:any) {
    this.overlayJSONOutput.emit(overlayJSON)
  }
}