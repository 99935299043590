<div>
    <em class="icon-class material-icons" (click)="closeDialog()">highlight_off</em>
</div>

<div align="center" class="qr-code">
    <qrcode [qrdata]="psvQrCode" [width]="160" [errorCorrectionLevel]="'M'"></qrcode>
</div>
<h6 align="center" class="url">{{psvQrCode}}</h6>
<div>
    <span class="msg">Scan this QR Code to play your personalized video</span>
</div>