<div>
    <div fxLayout="column" fxLayoutGap="1rem" class="fullDiv">
        <strong>Error in fetching data from the API</strong>
      <hr>
  
      <mat-expansion-panel [expanded]="false" class="api-response-panel">
        <mat-expansion-panel-header>
          <mat-panel-title>API Response</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="api-response-area">
          <!-- display api response here -->
          <div [style.color]="apiStatusText.ok ? '#008000' : '#ff0000'" class="response-code">
            Response Code: &nbsp; {{apiStatus}} &nbsp; {{apiStatusText}}
          </div>
        </div>
      </mat-expansion-panel>
  
       <div fxLayoutAlign="end" fxLayout="row" fxLayoutGap="30px">
        <button class="cancelBtn" (click)="cancelDialog()" mat-button>Cancel</button>
      </div>  
    </div> 
  </div>