<!-- <div class="bc-container">
  <ul class="progressbar">
    <li class="active">Edit Script</li>
    <li (click)="createvideo()">Edit Scenes</li>
    <li>Edit Elements</li>
    <li>Publish</li>
  </ul>
</div> -->
<div class="container box-shadow" [ngClass]="{'dim': psvLoader}">
  <div>
    <form class="form" [formGroup]="inputForm">
      <div class="titledesc">
        <div class="label-video-name">
          <label for="title">PSV Name<span class="error-style">*</span>&nbsp;:</label>
          <input type="text" autocomplete="off" class="inputborder-radius" id="title" [ngModel]="data.titleValue" formControlName="title"
            #box (keyup)="setTitleVal(box.value)">
          <div class="error-style" *ngIf="inputForm.get('title')?.invalid">
            <span *ngIf="inputForm.get('title')?.touched && !oncreate">Title is Required</span>
            <span *ngIf="oncreate && inputForm.get('title') is null">Title is Required</span>
          </div>
        </div>
        <div class="label">
          <label for="description">Description<span></span>&nbsp;:</label>
          <input #store type="text" autocomplete="off" class="inputborder-radius" id="description" value="{{data.descriptionValue}}"
            (keyup)="setDescriptionVal(store.value)">
        </div>
      </div>
      <div class="script-audio">
        <div style="font-size: 1.7rem;">Script</div><span class="error-style">*</span>
        <!-- <button (click)="isAudioOn = true; updateSampleAudio(data.fileContent.replaceAll('\n','. '))" class="read-out-btn">
          Read out the script below
         <mat-icon 
           class="audio-btn">volume_up
         </mat-icon>
        </button> -->
        <mat-progress-spinner *ngIf="changeAudio" mode="indeterminate" [diameter]="25"></mat-progress-spinner>
        <audio class="audio-player" *ngIf="sampleAudio && !changeAudio && isAudioOn" controls controlsList='nodownload'
          #audio [src]="sampleAudio | sanitizeVideo " type="audio/mp3">
        </audio>
      </div>
      <div class="script-whole-container" formArrayName="script">
        <div *ngFor="let item of script.controls; index as i">
          <label class="primary-headings"  for="inputtext">Scene {{i+1}}</label>
          <div class="script-container">
              <textarea  class="script-input-area"   oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"' id="inputtext" [formControlName]="i" autocomplete="off" placeholder="Type here"
              value="{{data.scriptArray[i]}}" (change)="valueChange()"  [ngModel]="data.scriptArray[i]" > </textarea> 
            <button class="remove-btn script-input-area" [disabled]="data.scriptArray.length==1"
              (click)="removeSection(i)">
              <em class="material-icons sec-headings-icons">
                remove
              </em>
            </button>
          </div>
          
            <div class="error-style" *ngIf="item.invalid">
            <span *ngIf="item.touched && !oncreate">Script is Required</span>
            <span *ngIf="oncreate && item is null">Script is Required</span>
            </div>
        </div>
      </div>
      <button class="add-section-btn sec-headings-icons primary-btn" (click)="addSection()">
        <em class="material-icons">add</em>Add Scene</button>
    </form>
    <div class="button-container">
      <!-- <div class="create-cancel-btn-container">
        <button class="cancel-btn primary-btn" routerLink="/" (click)="data.reload=true">Cancel</button>
        <button class="create-btn primary-btn" (click)="createvideo()">Create Video</button>
      </div> -->
      <div class="upload-button-container">
        <input class="defaultButton" type="file" accept="text/plain" #inputfile id="custom" (click)="inputfile.value=''"
          (change)="uploadfile($event); temp=fileContent;isAudioOn=false">
        <label for="custom" class="customizedButton primary-btn">Upload Script File</label>
      </div>
    </div>
    <div *ngIf="allAudioLoaded">
      <audio *ngFor="let script of scriptLines; index as i" preload="metadata"
        data-setup='{ "controls": false, "autoplay": false, "preload": "auto", "fluid" : true}'
        (loadedmetadata)="setAudioDuration($event,i)" #sampleAudioEle [id]="'sample-audio' + i"
        [src]="scriptAudio[script] | sanitizeVideo" style="display: none;">
      </audio>
    </div>
  </div>
</div>
<div *ngIf="psvLoader" class="psv-loader-progress">
  <!-- <mat-progress-spinner class="psv-loader-spinner" mode="indeterminate" [diameter]="50"></mat-progress-spinner> -->
  <img src="../../../assets/gifs/PSV_Wait.gif" class="loader-animation">
  <label class="psv-spinner-label">Creating PSV for you...</label>
</div>