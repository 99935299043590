<div class="main-container"> 
<div class="menu-container">
    <button class="menu-style" mat-button [matMenuTriggerFor]="actionMenu1" type="button" >Q4 Ratings<mat-icon>arrow_drop_down
    </mat-icon></button>
<mat-menu #actionMenu1="matMenu">
    <button mat-menu-item>Q4 Ratings</button>
    <button mat-menu-item>Monthly Meetings</button>
    <button mat-menu-item>Interview Results</button>
</mat-menu>
<button class="menu-style" mat-button [matMenuTriggerFor]="actionMenu2" type="button">Live PSVs<mat-icon>arrow_drop_down
    </mat-icon></button>
<mat-menu #actionMenu2="matMenu">
    <button mat-menu-item>Live PSVs</button>
    <button mat-menu-item>All PSVs</button>
</mat-menu>
<button class="menu-style" mat-button [matMenuTriggerFor]="actionMenu3" type="button">{{buttonName}}<mat-icon>arrow_drop_down
</mat-icon></button>
<mat-menu #actionMenu3="matMenu">
    <button mat-menu-item (click)="updateChart('Daily')">Daily</button>
    <button mat-menu-item (click)="updateChart('Weekly')">Weekly</button>
    <button mat-menu-item (click)="updateChart('Monthly')">Monthly</button>
</mat-menu>
</div>
<div class="views-tab">
    <mat-tab-group class="matTab">
        <mat-tab label="Views over Time">
        </mat-tab>
    </mat-tab-group>   
</div>
<div class="chart-style">
    <canvas baseChart [data]="viewsChartData" [type]="'line'" [legend]="false">
    </canvas>
</div>
</div>