<div class="publishUrls" *ngIf="this.publishModalLoader && !this.noPsv">
  <div style="margin-bottom: 3%;"> Previously Published Links </div>
  <div style="font-size: 1.9rem;">
    <b>
      Filter :
    </b>
  </div>
  <div style="font-size: 1.5rem;">
    Start Date :<input type="text" onfocus="(this.type = 'date')" class="startDate" placeholder="Start date" #startDate
      [autofocus]="true">
    End Date :<input type="text" onfocus="(this.type = 'date')" class="endDate" placeholder="End date" #endDate>
    <button type="submit" style="color: black;float: right;width: 16%;height: 3rem;border: none;margin-right: 15%;"
      class="secondary-btn onDisable" [disabled]="(!startDate.value && !endDate.value)"
      (click)="filteredLinks(startDate.value,endDate.value)"> Search </button>
  </div>
  <br>

  <div *ngIf="!this.noPsv"
    style="border: 2px;border-color: royalblue;border-style: solid;border-width: 2px; height: 54%; width: 100%; padding: 15px;border-radius: 6px;">
    <div>{{this.data.templateObj.title}}'s urls:</div>
    <br>
    <div *ngIf="this.filter">
      <div style="margin-bottom: 1%;"
        *ngFor="let psvLinks of this.filteredTemplateLinks | orderBy : this.filteredTemplateLinks.date : false ; index as i">
        <input type="text" class="common-styling" style="font-size: 1.5rem;width: 22%;text-align: center;"
          [value]="psvLinks.date" disabled> :
        <input type="text" class="common-styling" style="font-size: 1.5rem;width: 73%;" [value]="psvLinks.shortUrl"
          disabled>
        <br>
      </div>
    </div>
    <div *ngIf="!this.filter">
      <div style="margin-bottom: 1%;"
        *ngFor="let psvLinks of this.templatePsvLinks | orderBy : this.templatePsvLinks.date : false ; index as i">
        <input type="text" class="common-styling" style="font-size: 1.5rem;width: 22%;text-align: center;"
          [value]="psvLinks.date" disabled> :
        <input type="text" class="common-styling" style="font-size: 1.5rem;width: 73%;" [value]="psvLinks.shortUrl"
          disabled>
        <br>
      </div>
    </div>
  </div>

</div>
<div *ngIf="!this.publishModalLoader" style="margin-left: 50%;margin-top: 26%;">
  <em class="fa fa-spinner fa-spin spin-big"></em>
</div>
<div *ngIf="this.noPsv" style="text-align: center;margin-top: 25%;">
  No published urls are generated for "{{this.data.templateObj.title}}"
</div>