import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';


import { environment } from 'src/environments/environment';
import { EditorComponent } from '../editor/editor.component';
import { URLModel } from '../shared/models/URL-model';
import { DataService } from '../shared/services/data.service';
import { StorageService } from '../shared/services/storage.service';
import { EditorModalComponent } from './editor-modal/editor-modal.component';



@Component({
  selector: 'app-bg-videos',
  templateUrl: './bg-videos.component.html',
  styleUrls: ['./bg-videos.component.css']
})

export class BgVideosComponent implements OnInit {

  videoList: any;
  index: any;

  public videos: any;
  videoDurations: any = [];

  videoSrc:Array<{url:string, blobUrl:any,videoName:string}> = [];
  isLocalfiles: boolean = false;
  isSamplefiles: boolean = false;
  saveLoader: boolean = false;
  src1: string = "";
  enableEdit: boolean = false;

  selectedVideoIndex: any;

  constructor(readonly http: HttpClient, private dialogRef: MatDialogRef<BgVideosComponent>, readonly storageService: StorageService, readonly data: DataService, public dialog: MatDialog, private sanitizer: DomSanitizer) {
    
  }


  ngOnInit(): void {
    this.isLocalfiles = false;
    this.isSamplefiles = false;
 
  this.storageService.listFiles('videogallery').then((videoList:any) => {
    this.videoList = videoList
    
    for(let videoName of videoList) { 
      this.storageService.fetchVideoBlobURL(videoName).subscribe({
        next: (response:any) =>{
          this.videoSrc.push(response);
          
        }
        
     })
    
    }
  })
}

  editSelectedVideo() {
    let dialogRef = this.dialog.open(EditorModalComponent, {
      width: '900px',
      height: '598px',
    })
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result) {
        this.dialogRef.close();
        
      }
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close(this.data.bgSelectedVideoUrl);
  }

  clicked(v: string, index: any,bgVideoElement:any): void {
    this.data.bgSelectedVideoUrl = v;
    this.data.selectedBgVideoDuration=bgVideoElement.duration;
    this.selectedVideoIndex = index;
    this.enableEdit = true;
  }
  async onFileUpload(e: any) {
    this.enableEdit = false;
    this.saveLoader = true;

    let blob: Blob;
    const fileReader = new FileReader();
    const arrayBuffer = e.target.files[0];
    this.data.sceneFileName[this.data.bgSceneIndex]=e.srcElement.files[0].name;    
    const fileType = "video/mp4";
    blob = new Blob([arrayBuffer], { type: fileType });
    fileReader.onloadend = (e) => {
      this.data.bgSelectedVideoUrl = this.data.blobs.createObjectURL(blob);
      this.saveLoader = false;
    };
    fileReader.readAsArrayBuffer(blob);
  }
}




