import { Component, OnInit,Output,EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'chart-input-modal',
  templateUrl: './chart-input-modal.component.html',
  styleUrls: ['./chart-input-modal.component.css']
})
export class ChartInputModalComponent implements OnInit {

  faPlusCircle:any = faPlusCircle;
  faTrashAlt:any = faTrashAlt;
  xAxisLabel!: string;
  yAxisLabel!: string;
  chartKey!: string;
  disableAnimation = true;
  // @Output() overlayAnnotationsEvent = new EventEmitter<any>();

  constructor(public dialogRef: MatDialogRef<ChartInputModalComponent>, readonly data: DataService) { }
  trackByFn(index: any, item: { id: any; }) { 
    return item.id; 
  }
  ngOnInit() {
    if (this.data.overlayJSON.overlays[this.data.selectedAnnotation.id - 1].chartData.data.length > 0) {
      this.xAxisLabel = this.data.overlayJSON.overlays[this.data.selectedAnnotation.id - 1].chartData.xAxisLabel;
      this.yAxisLabel = this.data.overlayJSON.overlays[this.data.selectedAnnotation.id - 1].chartData.yAxisLabel;
    } else {
      this.data.overlayJSON.overlays[this.data.selectedAnnotation.id - 1].chartData.data[0] = {
        lineColor: "",
        label: "",
        borderWidth: 3,
        dataset: [{ key: "", value: ""}]
      };
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.disableAnimation = false);
  }

  changeChartKey() {
    for (const overlayObject of this.data.overlayJSON.overlays) {
      if (overlayObject.type === "chart" && overlayObject.id !== this.data.selectedAnnotation.id &&
      overlayObject.chartKey === this.data.overlayJSON.overlays[this.data.selectedAnnotation.id - 1].chartKey) {
        const classList = ["error-snackbar"];
        const message = 'Chart key already exists. Please Enter another key.';
        this.data.showSnackBar(message, classList);
      }
    }
  }

  addMoreChartInputs(datasetIndex: string | number) {
    this.data.overlayJSON.overlays[this.data.selectedAnnotation.id - 1].chartData.data[datasetIndex].dataset.push({
      key: "",
      value: ""
    });
  }

  updateChartData() {
    let chartDataSets = this.data.overlayJSON.overlays[this.data.selectedAnnotation.id - 1].chartData.data;
    if( chartDataSets.length >0){
      chartDataSets.forEach((dataSet: { dataset: any; }, dataSetIndex: any) =>{
        let data = dataSet.dataset;
        if(data.length >0){
          let i =0;
          while(i < data.length){
            if(data[i].key =="" && data[i].value ==""){
              this.removeRow(dataSetIndex, i);
              if(i ==0) {this.removeDataSet(dataSetIndex);}
            }
            else{
              i++;
            }
          }
        } 
      });
    }
    this.data.overlayJSON.overlays[this.data.selectedAnnotation.id - 1].chartData.xAxisLabel = this.xAxisLabel;
    this.data.overlayJSON.overlays[this.data.selectedAnnotation.id - 1].chartData.yAxisLabel = this.yAxisLabel;
    // this.overlayAnnotations();
    this.onClose();
    
  }
  // overlayAnnotations(updatedOverlays?:any) {
  //   this.overlayAnnotationsEvent.emit(updatedOverlays);
  // }

  removeRow(datasetIndex: string | number, dataIndex: number) {
    this.data.overlayJSON.overlays[this.data.selectedAnnotation.id - 1].chartData.data[datasetIndex].dataset.splice(dataIndex, 1);
  }

  addDataset(){
    this.data.overlayJSON.overlays[this.data.selectedAnnotation.id -1].chartData.data.push({
      lineColor: "",
      label: "",
      dataset: [{ key: "", value: ""}]
    });
  }

  removeDataSet(dataSetIndex: any){
    this.data.overlayJSON.overlays[this.data.selectedAnnotation.id -1].chartData.data.splice(dataSetIndex, 1);
  }

  onClose(): void {
    this.dialogRef.close();
  }

}
