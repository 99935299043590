import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faPlusCircle, faMinusCircle,faBold,faItalic, faUnderline, faPhone, faLink, faEnvelope,faVolumeUp, faCog, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { MatSnackBarConfig } from "@angular/material/snack-bar";
import { DataService } from 'src/app/shared/services/data.service';


  @Component({
    selector: 'psv-call-to-action',
    templateUrl: './call-to-action.component.html',
    styleUrls: ['./call-to-action.component.css']
    })
      export class CallToActionComponent implements OnInit {
        faCaretRight:any = faCaretRight;
        faVolumeUp: any = faVolumeUp;
        faCog:any = faCog;
        faPlusCircle:any = faPlusCircle;
        faMinusCircle:any = faMinusCircle;
        faBold:any = faBold;
        faItalic:any = faItalic;
        count = 0;
        seekSection:any;
        faUnderline:any = faUnderline;
        placeholder!: string;
        classList = ["error-snackbar"];
        fontFamilyList = ['Arial', 'Domaine_displaybold', 'Helvetica', 'Montserrat', 'Open_sansbold', 'Open_sansregular', 'Open_sanssemibold', 'Sans-serif', 'Times New Roman', 'Times',
        'Courier New', 'Courier', 'Verdana', 'Georgia', 'Palatino', 'Bookman', 'Comic Sans MS','Arial Black', 'Trebuchet MS', 'Oswald'];
        fontWeightList = ['Normal', 'Bold', 'Bolder', 'Lighter'];
        snackBar: any;
        seekTo: any;
        videoDuration: any;
        duration: any;
        boldBtn: boolean = false;
        boldSelected: any;
        italicBtn: boolean = false;
        italicSelected: any;
        underlineBtn: boolean = false;
        underlineSelected: any;
        labelsCheckbox: any;
        
        constructor( readonly data: DataService) { }

      loadElement(updatedOverlays?:any) {
   
      if (!((["tel", "link", "section", "email"].indexOf(this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].type) >= 0 &&
        this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].message === undefined) ||
        (this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].type === "text" &&
         this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].value === undefined) ||
        (this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].type === "chart" &&
         this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].data === undefined &&
         this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].length === 0))) 
         {
         this.overlayAnnotations( updatedOverlays);
         }
}

      ngOnInit() {
        this.loadElement();
        this.placeholder = "Your text here...";
       this.loadSeekData();
       
    
      }
      
     
    
showSnackBar(message:any, classList:any) {
  const config = new MatSnackBarConfig();
  config.horizontalPosition = "right";
  config.verticalPosition = "bottom";
  config.panelClass = classList;
  config.duration = 3000;
  this.data.snackBar.open(message, 'Ok', config);
}

@Input() calltoactioncomponent:any;
@Input() overlayJSON :any;
@Input() annotationTableData:any;
@Input() isToolBarOpen!:boolean;
@Output() change=new EventEmitter<MatSlideToggleChange>();
@Output() overlayAnnotationsEvent = new EventEmitter<any>();
@Output() overlayJSONOutputEvent=new EventEmitter<any>();
@Output() onMinimizing = new EventEmitter<boolean>();
/*
loadElement(updatedOverlays?) {
    if (this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].value !== undefined) {
      this.overlayAnnotations(updatedOverlays);
      this.setDraggerPosition();
    }
  }
*/
loadSeekData(){
    if( this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].seekTo){
    let min=this.duration.transform(this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].seekTo).split(":")[0];
    let sec=this.duration.transform(this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].seekTo).split(":")[1];
    this.seekSection = {
        time: { min: min, sec: sec },
        seekTo: ""
      }
    }else{
      this.seekSection = {
        time: { min: "00", sec: "00" },
        seekTo: ""
      }
    }

  }

  checkValidNumber(event:any){
    if (this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].type== "tel"){
        const classList=["error-snackbar"];
        const inputChar = event.keyCode
        const specialSym = /[\\\!\@\#\$\%\^\&\*\(\)\_\\=\<\>\,\s\/\`]/
        // const aplhabets = inputChar>=65 && inputChar <=90 
        const ctrl = event.ctrlKey ? event.ctrlKey : ((inputChar === 17) ? true : false);
        if((inputChar!==67 || inputChar!==86 || inputChar!==65)  && !ctrl){
          //condition Changed alphabets parameter removed 
          if (specialSym.test(event.key)) {
            this.showSnackBar("Please enter digits, plus and hyphen only",classList);
            event.preventDefault();      
          }
        }
        return false;
    } else {
      return true;
    }
  }

checkValidEmail(email : string) {
    if (this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].type == "mail") {
      const classList = ["error-snackbar"];
      const pattern = /^\w+\.?\w+\@[A-z_]+?\.[A-z]{2,3}$/;
      if (!pattern.test(email)) {
        this.showSnackBar("Please enter a valid email address", classList);
      }
      else {
        this.loadElement(this.overlayJSON.overlays[this.calltoactioncomponent.id - 1]);
        this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].message = email;
        this.changeAnnotationValue()
      }
    }
  }

selectionType(type:any) {
    if (type.value == "tel") {
      this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].message = "+91-999999999";
      this.placeholder = "+91-999999999";
      this.annotationTableData[this.calltoactioncomponent.id].icon = "call";
    } else if (type.value == "link") {
      this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].message = "Your text here...";
      this.placeholder = "Your text here...";
      this.annotationTableData[this.calltoactioncomponent.id].icon = "touch_app";
    } else if (type.value == "section") {
      this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].message = "Section Name";
      this.placeholder = "Seek Section Name";
      this.annotationTableData[this.calltoactioncomponent.id ].icon = "link";
    }
    else if (type.value == "mail") {
      this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].email = "xyz@domain.com";
      this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].message = this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].email;
      this.placeholder = "xyz@domain.com";
      this.annotationTableData[this.calltoactioncomponent.id ].icon = "email";
    }
    this.overlayJSONOutputEvent.emit(this.overlayJSON);
    this.changeAnnotationValue();
  }

changeAnnotationValue() {
  if(this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].type == "mail"){
    this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].message = this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].email;
  }
    this.annotationTableData[this.calltoactioncomponent.id].value = this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].message;
    this.overlayJSONOutputEvent.emit(this.overlayJSON);
    this.loadElement(this.overlayJSON.overlays[this.calltoactioncomponent.id - 1]);
  }

  toTransparentLink() {
    if (this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].bgToggleLink === false) {
      this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].cssProperties.buttonBgColor = "transparent";
      this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].cssProperties.buttonPadding = '0.0em';
      this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].cssProperties.boxShadow = 'none';
    }
    else {
      this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].bgToggleLink = true;
      this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].cssProperties.buttonBgColor = "#ffffff";
      this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].cssProperties.boxShadow = "0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08)";
    }
    this.overlayJSONOutputEvent.emit(this.overlayJSON);
    this.loadElement(this.overlayJSON.overlays[this.calltoactioncomponent.id - 1]);
  }

  changeLinkColor(inpColor:any) {
    this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].cssProperties.color = inpColor;
    this.overlayJSONOutputEvent.emit(this.overlayJSON);
    this.loadElement(this.overlayJSON.overlays[this.calltoactioncomponent.id - 1]);
  }

  

  changeButtonBgColor(inpColor:any) {
    this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].cssProperties.buttonBgColor = inpColor;
    this.overlayJSONOutputEvent.emit(this.overlayJSON);
    this.loadElement(this.overlayJSON.overlays[this.calltoactioncomponent.id - 1]);
  }

  changeBgColor(inpColor:any) {
    this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].cssProperties.backgroundColor = inpColor;
    this.overlayJSONOutputEvent.emit(this.overlayJSON);
    this.loadElement(this.overlayJSON.overlays[this.calltoactioncomponent.id - 1]);
  }

  _keydownEvent(event: any) {

    const inputCharacter = event.keyCode
    const specialSymbol = /[\\\!\@\#\$\%\^\&\*\(\)\_\+\-\=\<\>\,\/\`\:\;\'\"\.\?\|\~]/
    const aplhabetKeycode = inputCharacter >= 65 && inputCharacter <= 90
    const ctrl = event.ctrlKey ? event.ctrlKey : ((inputCharacter === 17) ? true : false);

    if ((inputCharacter !== 67 || inputCharacter !== 86 || inputCharacter !== 65) && !ctrl
      && (specialSymbol.test(event.key) || aplhabetKeycode)) {
      this.showSnackBar("invalid character", this.classList);
      event.preventDefault();
    }
  }
updateSeekData($event:any) {
  this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].seekTo=$event.target.value;
    this.checkTime(this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].seekTo)
    this.seekTo = this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].seekTo ;
    this.overlayJSONOutputEvent.emit(this.overlayJSON);
    this.overlayAnnotations();
  }
  
  overlayAnnotations(updatedOverlays?:any) {
    this.overlayAnnotationsEvent.emit(updatedOverlays);
  }

  checkTime(seekTime:any) {
    (<HTMLInputElement>document.getElementById('seek-time')).style.borderColor = "#ccc"
  if (seekTime === "") {
      this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].seekTo="00";
      this.showSnackBar("Please enter valid seek time", this.classList);
      (<HTMLInputElement>document.getElementById('seek-time')).style.borderColor = "#de3535"
      return false;
    }
    if (parseFloat(seekTime) > this.data.videoDuration) {
      this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].seekTo="00";
      this.showSnackBar("Seek Time must be less than Video duration .", this.classList);
      (<HTMLInputElement>document.getElementById('seek-time')).style.borderColor = "#de3535"
      return false;
    }
  
    if (seekTime.includes(":")) {
      this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].seekTo="00";
      this.showSnackBar("Seek Time must be in secs", this.classList);
      (<HTMLInputElement>document.getElementById('seek-time')).style.borderColor = "#de3535"
      return false;
    }
    return true;
  }
  decreasePaddingSize(id:any) {
    let paddingSize: string;
    if (this.overlayJSON.overlays[id].type === 'text') {
      paddingSize = this.overlayJSON.overlays[id].cssProperties.padding;
      this.overlayJSON.overlays[id].cssProperties.padding = `${+paddingSize.replace("em", "") - 0.01}em`;
    }
    else {
      paddingSize = this.overlayJSON.overlays[id].cssProperties.buttonPadding;
      this.overlayJSON.overlays[id].cssProperties.buttonPadding = `${+paddingSize.replace("em", "") - 0.01}em`;
    }
    this.overlayJSONOutputEvent.emit(this.overlayJSON);
    this.loadElement(id);
  }

  applyStyle(id:any, type:any) {
    
    switch (type) {
      case 'bold': {
        
        this.boldBtn = !this.boldBtn;
        // this.boldSelected[id] = this.boldBtn;
        
        if (this.boldBtn) {
          this.calltoactioncomponent.cssProperties.fontWeight = "Bold";
        }
        else {
          this.calltoactioncomponent.cssProperties.fontWeight = "Normal";
        }
        break;
      }
      case 'italic': {
        this.italicBtn = !this.italicBtn;
        // this.italicSelected[id] = this.italicBtn;
         if (this.italicBtn) {
          this.calltoactioncomponent.cssProperties.fontStyle = "italic";
        }
        else {
          this.calltoactioncomponent.cssProperties.fontStyle = "Normal";
        }
        break;
      }
      case 'underline': {
        this.underlineBtn = !this.underlineBtn;
        // this.underlineSelected[id] = this.underlineBtn;
        if (this.underlineBtn) {
          this.calltoactioncomponent.cssProperties.textDecoration = "underline";
        }
        else {
          this.calltoactioncomponent.cssProperties.textDecoration = "None";
        }
        break;
      }
    }
    this.loadElement(this.calltoactioncomponent);
  } 
  
  settingFontWeight(id:any) {
    const fontWeight = this.overlayJSON.overlays[id].cssProperties.fontWeight;
    switch (fontWeight) {
      case 'Normal': {
        this.boldBtn = false;
        break;
      }
      case 'Bold': {
        this.boldBtn = true;
        break;
      }
      case 'Bolder': {
        this.boldBtn = false;
        break;
      }
      case 'Lighter': {
        this.boldBtn = false;
        break;
      }
    }
    this.overlayJSONOutputEvent.emit(this.overlayJSON);
    this.loadElement(id);
  }
  increasePaddingRadius(id:any) {
    let paddingRadius: string;
    paddingRadius = this.overlayJSON.overlays[id].cssProperties.borderRadius;
    this.overlayJSON.overlays[id].cssProperties.borderRadius = `${+paddingRadius.replace("em", "") + 0.01}em`;
    this.overlayJSONOutputEvent.emit(this.overlayJSON);
    this.loadElement(id);
  }
  decreasePaddingRadius(id:any) {
    let paddingRadius: string;
    paddingRadius = this.overlayJSON.overlays[id].cssProperties.borderRadius;
    this.overlayJSON.overlays[id].cssProperties.borderRadius = `${+paddingRadius.replace("em", "") - 0.01}em`;
    this.overlayJSONOutputEvent.emit(this.overlayJSON);
    this.loadElement(id);
  }
  updatePaddingRadius(id:any, number:any) {

    this.overlayJSON.overlays[id].cssProperties.borderRadius = `${number}em`;
    this.overlayJSONOutputEvent.emit(this.overlayJSON);
    this.loadElement(id);
  }
  increasePaddingSize(id:any) {
    let paddingSize: string;
    if (this.overlayJSON.overlays[id].type === 'tel' || this.overlayJSON.overlays[id].type === 'text') {
      paddingSize = this.overlayJSON.overlays[id].cssProperties.padding;
      this.overlayJSON.overlays[id].cssProperties.padding = `${+paddingSize.replace("em", "") + 0.01}em`;
    }
    else {
      paddingSize = this.overlayJSON.overlays[id].cssProperties.buttonPadding;
      this.overlayJSON.overlays[id].cssProperties.buttonPadding = `${+paddingSize.replace("em", "") + 0.01}em`;
    }
    this.overlayJSONOutputEvent.emit(this.overlayJSON);
    this.loadElement(id);
  }
  updatePaddingSize(id:any, number:any) {
    if (this.overlayJSON.overlays[id].type === 'tel' || this.overlayJSON.overlays[id].type === 'text')
      this.overlayJSON.overlays[id].cssProperties.padding = `${number}em`;
    else {
      this.overlayJSON.overlays[id].cssProperties.buttonPadding = `${number}em`;
    }
    this.overlayJSONOutputEvent.emit(this.overlayJSON);
    this.loadElement(id);
  }
  
  updateFontSize(number:any, id:any) {
    this.calltoactioncomponent.cssProperties.fontSize = `${number}em`;
    this.loadElement(this.calltoactioncomponent);
  }
  
  increaseFontSize(id:any) {
    const fontSize: string = this.calltoactioncomponent.cssProperties.fontSize;
    this.calltoactioncomponent.cssProperties.fontSize = `${+fontSize.replace("em", "") + 0.01}em`;
    this.loadElement(this.calltoactioncomponent);
  }

  decreaseFontSize(id:any) {
    const fontSize: string = this.calltoactioncomponent.cssProperties.fontSize;
    this.calltoactioncomponent.cssProperties.fontSize = `${+fontSize.replace("em", "") - 0.01}em`;
    this.loadElement(this.calltoactioncomponent);
  }
  
  
  toTransparent(){
    if(this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].bgToggle===false){
      this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].cssProperties.backgroundColor="transparent";
      this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].cssProperties.outlineColor = "transparent";
      this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].cssProperties.outlineWidth = `0.0em`;
    }
    else{
      this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].bgToggle=true;
      this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].cssProperties.backgroundColor="#000000";
      this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].cssProperties.outlineColor ="#000000";
    }
    this.overlayJSONOutputEvent.emit(this.overlayJSON);
    this.loadElement(this.overlayJSON.overlays[this.calltoactioncomponent.id - 1]);     
  }
  setBgColor(color:any){
    this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].cssProperties.outlineColor = `${color}`;
    this.overlayJSON.overlays[this.calltoactioncomponent.id - 1].cssProperties.backgroundColor = `${color}`;
    this.loadElement(this.overlayJSON.overlays[this.calltoactioncomponent.id - 1]);
  }
  minimizeToolBar() {
    this.onMinimizing.emit(true);
  }
  
}




