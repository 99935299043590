import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Project_Meta } from '../shared/models/project.model';
import { SceneModel } from '../shared/models/scene.model';
import { AnalyticsService } from '../shared/services/analytics.service';
import { DataService } from '../shared/services/data.service';
import { RecentProjectPageComponent } from '../home/recent-project-page/recent-project-page.component';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseChartDirective } from 'ng2-charts';
import { ChartData } from 'chart.js';
import { Router } from '@angular/router';
import { FunnelChart} from 'chartjs-chart-funnel';

interface overviewChart {
  numbers: number[];
  chartData: String[];
}
interface ObjectWithUniqueUserAndHighViewPercentage{
  userName:string;
  viewPercentage:number;
  url:string;
}

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.css']
})

export class InsightsComponent implements OnInit {
  buttonName: string = "Monthly";
  totalPSVs: number = 0;
  publishCount: number = 0;
  saveCount: number = 0;
  projects: Project_Meta[] = [];
  sceneDatas: any;
  views: any;
  templateViews: any;
  rewatches: any;
  psvLinks: any[] = [];
  refPsvLinks: any[] = [];
  totalLinks: any;
  userData: any;
  range: FormGroup;
  templateId: string;
  viewsChartFlag: boolean;
  statusChartFlag: boolean;
  watchChartFlag: boolean;
  totalPsvLinks: any;
  totalUniqueViews: any;
  totalPartialViews: any;
  totalCompletedViews: any;
  funnelLabels:any;
  averageWatchTime:any;
  templateVideoDuration:any;
  linkAndView:any;
  totalLinkandView : any =[];
  constructor(public data: DataService, public analytics: AnalyticsService, private router: Router, public dialog: MatDialog) { }
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  public lineChartOptions: any= {
    responsive: true,
    scales: {
      yAxes: {
        display:true,
        title:{
          display:true,
          text:" No.of Views"
        }
      }
    },
  };

  viewsByTemplateChart: overviewChart = {
    chartData: [],
    numbers: []
  }
  watchTemplateChart: overviewChart = {
    chartData: ['Watched Partially', 'Watched Completely'],
    numbers: [0,0]
  }
  viewsChartData: ChartData<'line'> = {
    labels: this.viewsByTemplateChart.chartData,
    datasets: [
      {
        label: '',
        borderColor: "#1971FB",
        pointBackgroundColor: "#1971FB",
        pointRadius: 5,
        data: this.viewsByTemplateChart.numbers,

      },
   ],

  };
  watchChartData: ChartData<'pie'> = {
    labels: this.watchTemplateChart.chartData,
    datasets: [ {
      data: this.watchTemplateChart.numbers
    } ]
  };

  // getMostWatchedViewWithUniqueUsers(viewObject : ObjectWithUniqueUserAndHighViewPercentage[]){
  //   const watchPercentage : { [userName:string]: number } = {};
  //   let mostWatchedViewObj : ObjectWithUniqueUserAndHighViewPercentage[] = [];
  //   for(let obj of viewObject){
  //     if('viewPercentage' in obj && 'userName' in obj){
  //       const {viewPercentage, userName} = obj;

  //       if(!watchPercentage[userName]){
  //         watchPercentage[userName] = viewPercentage;
  //         mostWatchedViewObj.push(obj);
  //       }
  //       else if(viewPercentage > watchPercentage[userName]){
  //         watchPercentage[userName] = viewPercentage;
  //         mostWatchedViewObj = mostWatchedViewObj.filter(item => item.userName !== userName );
  //         mostWatchedViewObj.push(obj);
  //       }
  //     }
  //   }
  //   return mostWatchedViewObj;
  // }

  ngOnInit(): void {
    if (!this.data.analyticsTemplateId) {
      this.router.navigate(['/template-page']);
    }
    this.templateId = this.data.analyticsTemplateId;
    this.range = new FormGroup({
      start: new FormControl(),
      end: new FormControl(),
    });
    this.data.isHomePage = false;
    // this.data.analyticsSelected = true;
    this.data.fetchAllProjects().then((projects: any) => {
      if (projects) {
        for (let item of projects) {
          this.totalPSVs += 1;
          let project = new Project_Meta(item.title, item.description, item.script, item.createDate, item.lastModifiedDate, item._id, item.concatenatedPSV);
          project.isPublished = item.isPublished ? true : false;
          if (project.isPublished) {
            this.publishCount += 1;
          }
          this.projects.push(project);
        }
      }
      this.saveCount = this.totalPSVs - this.publishCount;
    });

    // this.data.fetchViewRecordsOfProject(this.data.analyticsProjectId).then((views:any) =>{
    //   this.views = views;
    // })

    this.data.fetchViewRecordsOfTemplate(this.data.analyticsTemplateId).then((views: any) => {
      this.templateViews = views;
      if (this.templateViews > 0) {
        let date: any;
        let dateCount = 10;
        let index = 0;
        let promises = [];
        while (dateCount > 0) {
          dateCount--;
          date = new Date(new Date().getTime() - (dateCount * 24 * 60 * 60 * 1000));
          this.viewsByTemplateChart.chartData[index] = date.toDateString();
          this.viewsByTemplateChart.numbers[index] = 0;
          promises.push(new Promise((resolve: any, reject: any) => {
            this.data.fetchViewsOfTemplateByDate(this.data.analyticsTemplateId, date, index).then((data: any) => {
              this.viewsByTemplateChart.numbers[data.index] = data.views;
              resolve();
            })
          }));
          index++;
        }
        Promise.all(promises).then((result: any) => {
          this.viewsChartFlag = history.state.flag;

        }).catch((err: any) => {
          console.log("error while fetching records")
        });
        let statusPromises = [];
        statusPromises.push(new Promise((resolve: any, reject: any) => {
          this.data.fetchViewsByStatusOfTemplate(this.data.analyticsTemplateId, 'partial').then((data: any) => {
            this.totalPartialViews = data;
            this.watchTemplateChart.numbers[0] = data;
            resolve();
          })
        }))
        statusPromises.push(new Promise((resolve: any, reject: any) => {
          this.data.fetchViewsByStatusOfTemplate(this.data.analyticsTemplateId, 'completed').then((data: any) => {
            this.totalCompletedViews = data;
            this.watchTemplateChart.numbers[1] = data;
            resolve();
          })
        }))
        Promise.all(statusPromises).then((result: any) => {
          this.watchChartFlag = true;
          this.statusChartFlag = history.state.flag;
          this.getFunnelChart();
        }).catch((err: any) => {
          console.log("error while fetching records")
        });
      }
    })
    this.data.getPsvLinksForTemplate(this.data.analyticsTemplateId).then(async (psvLinks: any) => {
      this.totalPsvLinks = psvLinks;
      this.linkAndView = {};
      for(let link of this.totalPsvLinks){
        await this.data.getPsvLinkViewForTemplate(link._id).then((views:any)=>{
          for(let view of views){
            if(view.userEmail && view.viewStatus && view.videoDuration && view.totalPlayed){
              this.linkAndView.watchTime = view.linkOpenTime;
              this.linkAndView.userFirstName = view.userFirstName;
              this.linkAndView.url = link.shortUrl;
              this.linkAndView.totalPlayed = view.totalPlayed.toFixed(2);
              this.templateVideoDuration = view.videoDuration.toFixed(2);
              let viewPercent = parseFloat(view.viewStatus);
              if(viewPercent >= 0 && viewPercent <= 25){
                this.linkAndView.viewPercentage = '0% - 25%';
              }else if(viewPercent >= 25 && viewPercent <= 50){
                this.linkAndView.viewPercentage = '25% - 50%';
              }else if(viewPercent >= 50 && viewPercent <= 75){
                this.linkAndView.viewPercentage = '50% - 75%';
              }else if(viewPercent >= 75 && viewPercent <= 100){
                this.linkAndView.viewPercentage = '75% - 100%';
              }else if(viewPercent = 100){
                this.linkAndView.viewPercentage = '100%';
              }

              this.linkAndView.userName = view.userEmail;
              this.totalLinkandView.push(this.linkAndView);
              this.linkAndView = {};
            }
          }
          // this.totalLinkandView = this.getMostWatchedViewWithUniqueUsers(this.totalLinkandView)
        })
      }
      const avg = this.totalLinkandView.reduce((sum:any, value:any) => sum+ parseFloat(value.totalPlayed),0)
      this.averageWatchTime = avg / this.totalLinkandView.length;
      this.totalLinkandView.sort((a:any,b:any)=> new Date(b.watchTime).getTime() - new Date(a.watchTime).getTime());
    })
    this.data.fetchUniqueViewsOfTemplate(this.data.analyticsTemplateId).then((data: any) => {
      this.totalUniqueViews = data;
    })
    // this.data.getPsvLinksForProject(this.data.analyticsProjectId).then((psvLinks:any) =>{
    //   this.psvLinks = psvLinks,
    //   this.refPsvLinks = psvLinks,
    //   this.totalLinks = this.psvLinks.length;
    // this.psvLinks.forEach((psvLink,index)=>{
    //     this.data.fetchViewRecordsOfLink(psvLink._id).then((linkViews:any) =>{
    //     psvLink.linkViews=linkViews.length;
    //   })
    //   // this.data.fetchUserDataById(psvLink.userDataId).then((userData:any) =>{
    //   //   this.psvLinks[index].userData = userData;
    //   // })
    // })
    // })
  }

  secondsToMinutes(totalPlayed:any){
    const minutes = Math.floor(totalPlayed/60);
    const remainingSeconds = totalPlayed%60;
    const formattedMin = String(minutes).padStart(2,'0');
    const formattedSec = String(remainingSeconds.toFixed(0)).padStart(2,'0');
    return formattedMin+':'+formattedSec;
  }

  publishTime() {
    let startDate = new Date(this.range.get('start').value)
    startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    let endDate = new Date(this.range.get('end').value)
    endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    this.psvLinks = this.refPsvLinks.filter(x => {
      let findDate = new Date(x.date)
      findDate = new Date(findDate.getFullYear(), findDate.getMonth(), findDate.getDate());
      if ((findDate >= startDate && findDate <= endDate) || startDate == null || endDate == null) {
        return true;
      }
      return false;
    })
  }

  getFunnelChart() {
    this.funnelLabels= ['Total Links Generated', 'Total Links Opened', 'Total Links Completed'];
    const canvas = <HTMLCanvasElement>document.getElementById('statusChart');
    const ctx = canvas.getContext('2d');
    var config = {
      type: 'funnel',

      data: {
        labels: this.funnelLabels,
        datasets: [
          {
            data: [this.totalPsvLinks.length, this.totalUniqueViews, this.totalCompletedViews],
            backgroundColor: [
              "#FF6384",
              "#36A2EB",
              "#FFCE56"
            ],
            hoverBackgroundColor: [
              "#FF6384",
              "#36A2EB",
              "#FFCE56"
            ]
          },
        ],
        options: {
          responsive: true,
          plugins:{
            legend: {
              display: true
          }
          },
          animation: {
            animateScale: true,
            animateRotate: true
          },

        }
      },
    }
    const chart = new FunnelChart(canvas, config);
  }
}
