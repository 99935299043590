<div *ngIf="data.selectedSceneLoaded" class="whole-container" [ngStyle]="{'width': data.isToolBarOpen ? '69%' : '69%'}">
  <div class="timeline-container">
    <div class="left-container">
      <div class="current-time">Current Seek Time : <div class="current-time-figures">{{data.currentTime |
          durationFormat:'mm:ss.sss'}}</div> mm:ss</div>
      <div class="annotations-table">
        <div *ngIf="this.data.annotationType != 'caption'" #tableContainer class="table-container scrollable-pane" [synchronizeScrollWith]="initalizedScrollablePane">
          <mat-table #tableRef cdkDropList (cdkDropListDropped)="reOrderAnnotation($event)" [dataSource]="data.annotationTableData.slice().reverse()">
            <ng-container matColumnDef="icon">
              <mat-cell *matCellDef="let element; let i = index" style="font-size: 1.5rem;"
                [style.color]="data.selectedDatasourceId == (data.annotationTableData.length - i - 1) ? '#000000': ''">
                <mat-icon>{{element.icon}}</mat-icon>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="value">
              <mat-cell *matCellDef="let element; let i = index"><span class="annotation-value"
                  [style.color]="data.selectedDatasourceId == (data.annotationTableData.length - i - 1) ? '#000000' : ''">{{element.value}}</span>
                <mat-icon [style.color]="data.selectedDatasourceId == (data.annotationTableData.length - i - 1) ? '#000000' : ''" (click)="deleteAnnotation(element)" >delete</mat-icon>
              </mat-cell>
            </ng-container>
            <mat-row cdkDrag [cdkDragBoundary]="tableContainer" *matRowDef="let row; let i = index; columns: displayedColumns;"
              [style.background-color]="data.selectedDatasourceId == (data.annotationTableData.length - i - 1) ? '#edecff' : ''"
              (click)="toggleAnnotation(row)"></mat-row>
          </mat-table>
        </div>
          <div *ngIf="this.data.annotationType == 'caption'" #tableContainer class="table-container scrollable-pane" [synchronizeScrollWith]="initalizedScrollablePane">
            <mat-table #tableRef cdkDropList (cdkDropListDropped)="reOrderCaptionAnnotation($event)" [dataSource]="data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.slice().reverse()">
              <ng-container matColumnDef="icon">
                <mat-cell *matCellDef="let element; let i = index" style="font-size: 1.5rem;"
                  [style.color]="data.selectedDatasourceId == (data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.length - i - 1) ? '#000000': ''">
                  <mat-icon>{{element.icon}}</mat-icon>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="value">
                <mat-cell *matCellDef="let element; let i = index"><span class="annotation-value"
                    [style.color]="data.selectedDatasourceId == (data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.length - i - 1) ? '#000000' : ''">{{element.value}}</span>
                  <mat-icon [style.color]="data.selectedDatasourceId == (data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.length - i - 1) ? '#000000' : ''" (click)="deleteCaptionAnnotation(element)" >delete</mat-icon>
                </mat-cell>
              </ng-container>
              <mat-row cdkDrag [cdkDragBoundary]="tableContainer" *matRowDef="let row; let i = index; columns: displayedColumns;"
                [style.background-color]="data.selectedDatasourceId == (data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.length - i - 1) ? '#edecff' : ''"
                (click)="toggleCaptionAnnotation(row)"></mat-row>
            </mat-table>
        </div>
      </div>
    </div>
    <div class="right-container" appCalculateWidth (onResizingMe)="onTimelineResize($event,scrollablePane)"
      [ngClass]="{'width-1':less1900 && data.isToolBarOpen , 'right-timeline-panel-1':less1900 && !data.isToolBarOpen, 'width-2':gt1900 && data.isToolBarOpen , 'right-timeline-panel':gt1900 && !data.isToolBarOpen }"
      #rtp>
      <div class="current-time-handler" [ngDraggable]=true [handle]=topNotch [bounds]="rtp"
        [ngStyle]="{'transform': 'translate(' + ((data.currentTime * timelineContainerWidth * data.zoomRatio / data.videoDuration)) + 'px,0px)'}"
        [inBounds]="inBound" #currentTimeHandler
        (movingOffset)="calculateCurrentTime(currentTimeHandler, timeScaleController, timeScale)">
        <div class="blank"></div>
        <div class="top-notch" #topNotch>
          <div class="line"></div>
        </div>
        <div class="thin-line">
        </div>
      </div>
      <div class="time-scale" #timeScale>
        <ng-container *ngFor="let ts of scaleUnitsArray; let i=index;let first = first; let last = last">
          <timestamp *ngIf="i == data.videoDuration || i % 1 == 0" appPositionTimeStamp
            [timeStampsCount]="scaleUnitsArray.length-1" [timeStampIndex]="i" [timeStamp]="ts"
            [ngClass]="{'ts-first': first, 'ts-last': last}">
          </timestamp>
        </ng-container>
      </div>
      <div class="time-scale-controller-bound" #draggerBound>
        <div class="time-scale-controller" [style.transform]="'translate(0px,0px)'" [ngDraggable]="true"
          [handle]=timeScaleController [bounds]="draggerBound" [inBounds]="inBound"
          (movingOffset)="onDraggingHandler($event, currentTimeHandler, timeScaleController, timeScale, lineGroup, scrollablePane)"
          #timeScaleController>
          <div class="inside-white" #whiteDragger>
          </div>
        </div>
      </div>
      <div class="line-group" #lineGroup>
        <ng-container *ngFor="let ts of lineGroupArray; let i=index">
          <div class="line" [ngStyle]="{'left': i/2 + '%'}"></div>
        </ng-container>
      </div>
      <div *ngIf="this.data.annotationType != 'caption'" #annotationPane #annotationContainer class="annotation-container scrollable-pane" [synchronizeScrollWith]="initializedTableContainer"
        #scrollablePane>
        <ng-container *ngFor="let annotation of data.annotationTableData.slice().reverse(); let i = index">
          <div class="annotation-duration-container" #bound>
            <div class="annotation-duration" [ngResizable]="true" [rzHandles]="'e,w'"
              (rzStop)="onAnnotationHandlerResizeEnd($event,scrollablePane,(data.annotationTableData.length - i - 1),timeScaleController,annotationTimeline)"
              [ngDraggable]="true" [handle]=annotationTimeline [bounds]="bound" [inBounds]="inBound" lockAxis="y"
              (endOffset)="onDraggingAnnotationHandlerEnd($event,(data.annotationTableData.length - i - 1),annotationTimeline)"
              [ngStyle]="{'width': annotation.durationWidth + '%', 'margin-left': annotation.startPx + 'px'}"
              [style.background-color]="data.selectedDatasourceId.toString() === (data.annotationTableData.length - i - 1).toString() ? '#edecff' : ''" #annotationTimeline
              #resizableAnnotation>
            </div>

          </div>
        </ng-container>
      </div>
      <div *ngIf="this.data.annotationType == 'caption'" >
        <div #captionPane #captionContainer class="caption-container scrollable-pane" [synchronizeScrollWith]="initializedTableContainer"
        #scrollablePane>
        <ng-container *ngFor="let annotation of data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.slice().reverse(); let i = index">
          <div class="caption_annotation-duration-container" #bound>
            <div class="caption_annotation-duration" [ngResizable]="true" [rzHandles]="'e,w'"
              (rzStop)="onCaptionAnnotationHandlerResizeEnd($event,scrollablePane,(data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.length - i - 1),timeScaleController,caption_annotationTimeline)"
              [ngDraggable]="true" [handle]=caption_annotationTimeline [bounds]="bound" [inBounds]="inBound" lockAxis="y"
              (endOffset)="onDraggingCaptionAnnotationHandlerEnd($event,(data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.length - i - 1),caption_annotationTimeline)"
              [ngStyle]="{'width': annotation.durationWidth + '%', 'margin-left': annotation.startPx + 'px'}"
              [style.background-color]="data.selectedDatasourceId.toString() === (data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.length - i - 1).toString() ? '#edecff' : ''" #caption_annotationTimeline
              #resizableAnnotation>
            </div>

          </div>
        </ng-container>
      </div>
    </div>
    </div>
  </div>
  <div class="zoom-panel"
    [ngClass]="{'width-1-zoom':less1900 && data.isToolBarOpen , 'right-timeline-panel-1':less1900 && !data.isToolBarOpen, 'width-2-zoom':gt1900 && data.isToolBarOpen , 'right-timeline-panel':gt1900 && !data.isToolBarOpen }">
    <div>
      <mat-button-toggle-group #toggleBtn="matButtonToggleGroup" class="timeline-panel">
        <mat-button-toggle [disabled]="data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.length === 0" value="Toggle Button 1" (click)="switchToCaptionTimelineButton()">
            captions
        </mat-button-toggle>
        <mat-button-toggle value="Toggle Button 2" (click)="switchToAnnotationTimelineButton()">
            annotations
        </mat-button-toggle>
    </mat-button-toggle-group>
    </div>
    <mat-icon (click)="zoomOut(timeScale,currentTimeHandler,timeScaleController,scrollablePane)">zoom_out</mat-icon>
    <div class="zoom-ratio">{{data.zoomRatio * 100}}%</div>
    <mat-icon (click)="zoomIn(timeScale,currentTimeHandler,timeScaleController,scrollablePane)">zoom_in</mat-icon>
  </div>
</div>