<!------------------------------------------------------------------------------
 # COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
 # Reserved. Infosys believes the information in this document is accurate as of
 # its publication date; such information is subject to change without notice.
 # Infosys acknowledges the proprietary rights of other companies to the
 # trademarks, product names and such other intellectual property rights
 # mentioned in this document. Except as expressly permitted, neither this
 # documentation nor any part of it may be reproduced, stored in a retrieval
 # system, or transmitted in any form or by any means, electronic, mechanical,
 # printing, photocopying, recording or otherwise, without the prior permission
 # of Infosys Limited and/or any named intellectual property rights holders
 # under this document.
-------------------------------------------------------------------------------->
<div class="inner-properties">
  <div (click)="minimizeToolBar()" class="toolbar-minimize" *ngIf="isToolBarOpen">
    <em class="icon-class material-icons">highlight_off</em>
  </div>
  <div>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title class="heading-container">CTA PROPS. </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="input-dropdown">
        <mat-form-field>
          <label class="properties font-size-sm ">TYPE</label>
          <mat-select (selectionChange)="selectionType($event)" [(ngModel)]="this.calltoactioncomponent.type">
            <mat-option [value]="'link'">Button</mat-option>
            <mat-option [value]="'tel'">Contact No</mat-option>
            <mat-option [value]="'mail'">Email</mat-option>
            <mat-option [value]="'section'">Section</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <label class="properties font-size-sm" *ngIf="calltoactioncomponent.type=='link'">TITLE</label>

      <label class="properties font-size-sm" *ngIf="calltoactioncomponent.type=='tel'">CONTACT NUMBER</label>
      <input class="font-size-md" type="text" [(ngModel)]="calltoactioncomponent.message"
        *ngIf="calltoactioncomponent.type!=='mail' && this.calltoactioncomponent.type!=='section'"
        [placeholder]="placeholder"  (input)="changeAnnotationValue()">

      <label class="properties font-size-sm" *ngIf="calltoactioncomponent.type=='link'">LINK</label>
      <input *ngIf="calltoactioncomponent.type=='link'" class="font-size-md" type="text"
        [(ngModel)]="calltoactioncomponent.value" placeholder="https://www.google.com"
        (input)="loadElement(calltoactioncomponent)">

      <label *ngIf="calltoactioncomponent.type=='section'" class="properties font-size-sm ">SEEK TO (SS)</label>
      <div *ngIf="calltoactioncomponent.type=='section'" id="{{'seek-time'}}">
        <input class="ind-time-fields" #startMin name="start-mins" type="text" required placeholder="00"
          [ngModel]="calltoactioncomponent.seekTo | durationFormat" (change)="updateSeekData($event)">
      </div>

      <label class="properties font-size-sm" *ngIf="calltoactioncomponent.type=='section'">Section Name</label>
      <input *ngIf="calltoactioncomponent.type=='section'" class="font-size-md" type="text"
        [(ngModel)]="calltoactioncomponent.message" (input)="changeAnnotationValue()" [placeholder]="placeholder" (change)="updateSeekData($event)"
        (input)="loadElement(calltoactioncomponent)">

      <label class="properties font-size-sm">PLACEHOLDER</label>
      <input class="font-size-md" type="text" [(ngModel)]="calltoactioncomponent.title" placeholder="placeholder"
        (input)="loadElement(calltoactioncomponent)">



      <label class="properties font-size-sm" *ngIf="calltoactioncomponent.type=='mail'">Email address</label>
      <input #email *ngIf="calltoactioncomponent.type=='mail'" class="font-size-md" type="text"
        [(ngModel)]="calltoactioncomponent.email" placeholder="account@domain.com"
        (input)="checkValidEmail(email.value)">

      <label class="properties font-size-sm" *ngIf="calltoactioncomponent.type=='mail'">Subject</label>
      <input *ngIf="calltoactioncomponent.type=='mail'" class="font-size-md" type="text"
        [(ngModel)]="calltoactioncomponent.subject" placeholder="Loan Enquiry"
        (blur)="loadElement(calltoactioncomponent)">

      <label class="properties font-size-sm" *ngIf="calltoactioncomponent.type=='mail'">Email Template</label>
      <textarea *ngIf="calltoactioncomponent.type=='mail'" class="font-size-md" type="text"
        [(ngModel)]="calltoactioncomponent.body" placeholder="Hi I'd like to avail the loan offer"
        (input)="loadElement(calltoactioncomponent)"></textarea>


      <div class="input-dropdown">
        <label class="properties font-size-sm "> FONT FAMILY</label>
        <mat-form-field>
          <mat-select class="font-size-md full-width" [(ngModel)]="calltoactioncomponent.cssProperties.fontFamily"
            (selectionChange)="loadElement()">
            <mat-option *ngFor="let fontFamily of fontFamilyList | orderBy" [value]="fontFamily">{{fontFamily}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <label class="properties font-size-sm">
        FONT STYLE
      </label>
      <div class="properties-bar1">
        <button class="remove-default-style properties-button style-btn"
          [ngClass]="{'active': calltoactioncomponent.cssProperties.fontWeight === 'Bold'}"
          (click)="applyStyle(calltoactioncomponent.id ,'bold')" matTooltip="Bold" matTooltipPosition=above>
          <fa-icon [icon]="faBold"></fa-icon>
        </button>
        <button class="remove-default-style properties-button style-btn"
          [ngClass]="{'active': calltoactioncomponent.cssProperties.fontStyle === 'italic'}"
          (click)="applyStyle(calltoactioncomponent.id,'italic')" matTooltip="Italic" matTooltipPosition=above>
          <fa-icon [icon]="faItalic"></fa-icon>
        </button>
        <button class="remove-default-style properties-button style-btn"
          [ngClass]="{'active': calltoactioncomponent.cssProperties.textDecoration === 'underline'}"
          (click)="applyStyle(calltoactioncomponent.id,'underline')" matTooltip="Underline" matTooltipPosition=above>
          <fa-icon [icon]="faUnderline"></fa-icon>
        </button>
      </div>




      <div class="input-dropdown">
        <label class="properties font-size-sm "> FONT WEIGHT</label>
        <mat-form-field>
          <mat-select class="font-size-md full-width" [(ngModel)]="calltoactioncomponent.cssProperties.fontWeight"
            (selectionChange)="settingFontWeight(calltoactioncomponent.id - 1)">
            <mat-option *ngFor="let fontWeight of fontWeightList" [value]="fontWeight">{{fontWeight}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <label class="properties font-size-sm">
        FONT SIZE (in em)
      </label>
      <div class="properties-bar">
        <button class="remove-default-style properties-button" (click)="decreaseFontSize(calltoactioncomponent.id)"
          matTooltip="Reduce Font Size" matTooltipPosition=left>
          <fa-icon [icon]="faMinusCircle"></fa-icon>
        </button>
        <input class="font-size-lg center-align width-half properties-font-size" type="number" step="0.01"
          [ngModel]="calltoactioncomponent.cssProperties.fontSize | calcFontSize"
          (ngModelChange)="updateFontSize($event,calltoactioncomponent.id)">
        <button class="remove-default-style properties-button" (click)="increaseFontSize(calltoactioncomponent.id)"
          matTooltip="Add Font Size" matTooltipPosition=left>
          <fa-icon [icon]="faPlusCircle"></fa-icon>
        </button>
      </div>

      <label class="properties font-size-sm color-field">
        COLOR
      </label>
      <div class="properties-bar color-field">
        <label style="display:inline-flex">
          <input class="color-picker" type="color" #linkColor
            [ngModel]="calltoactioncomponent.cssProperties.color | colorConvert"
            (input)="changeLinkColor(linkColor.value)">
          <input type="text" class="color-picker-value font-size-md"
            [(ngModel)]="calltoactioncomponent.cssProperties.color" (input)="loadElement(calltoactioncomponent)">
        </label>
      </div>

      <div *ngIf="calltoactioncomponent.type==='link' || calltoactioncomponent.type==='mail'">
        <div class="font-size-sm bg-label">
          <label class="properties font-size-sm label-margin">BACKGROUND COLOR </label>
          <mat-slide-toggle [(ngModel)]="calltoactioncomponent.bgToggleLink" (change)="toTransparentLink()">
          </mat-slide-toggle>
        </div>
        <div *ngIf="calltoactioncomponent.bgToggleLink">
          <div class="properties-bar link-bg-field"
            *ngIf="calltoactioncomponent.type=='link' || calltoactioncomponent.type==='mail'">
            <label class="bg-label">
              <input class="color-picker" type="color" #buttonBgColor
                [ngModel]="calltoactioncomponent.cssProperties.buttonBgColor | colorConvert"
                (input)="changeButtonBgColor(buttonBgColor.value)">
              <input type="text" class="color-picker-value font-size-md"
                [(ngModel)]="calltoactioncomponent.cssProperties.buttonBgColor"
                (input)="loadElement(calltoactioncomponent)">
            </label>
          </div>



          <label class="properties font-size-sm label-margin">
            BACKGROUND PADDING
          </label>
          <div class="properties-bar">
            <button class="remove-default-style properties-button"
              (click)="decreasePaddingSize(calltoactioncomponent.id - 1)" matTooltip="Reduce Padding Size"
              matTooltipPosition=left>
              <fa-icon [icon]="faMinusCircle"></fa-icon>
            </button>
            <input class="font-size-lg center-align width-half properties-font-size" type="number" step="0.01"
              [ngModel]="calltoactioncomponent.cssProperties.buttonPadding | calcFontSize"
              (ngModelChange)="updatePaddingSize(calltoactioncomponent.id - 1,$event)">
            <button class="remove-default-style properties-button"
              (click)="increasePaddingSize(calltoactioncomponent.id - 1)" matTooltip="Add Padding Size"
              matTooltipPosition=left>
              <fa-icon [icon]="faPlusCircle"></fa-icon>
            </button>
          </div>
          <label class="properties font-size-sm label-margin">
            BORDER RADIUS
          </label>
          <div class="properties-bar">
            <button class="remove-default-style properties-button"
              (click)="decreasePaddingRadius(calltoactioncomponent.id - 1)" matTooltip="Reduce Border Radius"
              matTooltipPosition=left>
              <fa-icon [icon]="faMinusCircle"></fa-icon>
            </button>
            <input class="font-size-lg center-align width-half properties-font-size" type="number" step="0.01"
              [ngModel]="calltoactioncomponent.cssProperties.borderRadius | calcFontSize"
              (ngModelChange)="updatePaddingRadius(calltoactioncomponent.id - 1,$event)">
            <button class="remove-default-style properties-button"
              (click)="increasePaddingRadius(calltoactioncomponent.id - 1)" matTooltip="Add Border Radius"
              matTooltipPosition=left>
              <fa-icon [icon]="faPlusCircle"></fa-icon>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="calltoactioncomponent.type==='tel'">
        <div class="font-size-sm">
          <label class="bg-color-label">BACKGROUND COLOR </label>
          <mat-slide-toggle [(ngModel)]="calltoactioncomponent.bgToggle" (change)="toTransparent()"></mat-slide-toggle>
        </div>
        <div *ngIf="calltoactioncomponent.bgToggle">
          <div class="properties-bar tel-color-picker">
            <label>
              <input class="color-picker" type="color" #bgColor
                [ngModel]="calltoactioncomponent.cssProperties.backgroundColor | colorConvert"
                (input)="changeBgColor(bgColor.value)">
              <input type="text" class="color-picker-value font-size-md"
                [(ngModel)]="calltoactioncomponent.cssProperties.backgroundColor"
                (input)="loadElement(calltoactioncomponent)">
            </label>
          </div>

          <label class="properties font-size-sm label-margin">
            BACKGROUND PADDING
          </label>
          <div class="properties-bar">
            <button class="remove-default-style properties-button"
              (click)="decreasePaddingSize(calltoactioncomponent.id - 1)" matTooltip="Reduce Padding Size"
              matTooltipPosition=left>
              <fa-icon [icon]="faMinusCircle"></fa-icon>
            </button>
            <input class="font-size-lg center-align width-half font-padding-text" type="number" step="0.01"
              [ngModel]="calltoactioncomponent.cssProperties.buttonPadding | calcFontSize"
              (ngModelChange)="updatePaddingSize(calltoactioncomponent.id - 1,$event)">
            <button class="remove-default-style properties-button"
              (click)="increasePaddingSize(calltoactioncomponent.id - 1)" matTooltip="Add Padding Size"
              matTooltipPosition=left>
              <fa-icon [icon]="faPlusCircle"></fa-icon>
            </button>
          </div>

          <label class="properties font-size-sm label-margin">
            BORDER RADIUS
          </label>
          <div class="properties-bar">
            <button class="remove-default-style properties-button"
              (click)="decreasePaddingRadius(calltoactioncomponent.id - 1)" matTooltip="Reduce Border Radius"
              matTooltipPosition=left>
              <fa-icon [icon]="faMinusCircle"></fa-icon>
            </button>
            <input class="font-size-lg center-align width-half properties-font-size" type="number" step="0.01"
              [ngModel]="calltoactioncomponent.cssProperties.borderRadius | calcFontSize"
              (ngModelChange)="updatePaddingRadius(calltoactioncomponent.id - 1,$event)">
            <button class="remove-default-style properties-button"
              (click)="increasePaddingRadius(calltoactioncomponent.id - 1)" matTooltip="Add Border Radius"
              matTooltipPosition=left>
              <fa-icon [icon]="faPlusCircle"></fa-icon>
            </button>

          </div>
        </div>

      </div>
    </mat-expansion-panel>
  </div>