import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { catchError, Observable, throwError } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PSVCommonPropertiesService{

  constructor(public snackBar:MatSnackBar, readonly http: HttpClient  ){}

  showSnackBar(message:any, classList:any) {
    const config = new MatSnackBarConfig();
    config.horizontalPosition = "right";
    config.verticalPosition = "bottom";
    config.panelClass = classList;
    config.duration = 3000;
    this.snackBar.open(message, 'Ok', config);
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message)
  }

  getAnimationList(): Observable<any> {
    return this.http.get("assets/data/animations.json").pipe(
      catchError(this.errorHandler)
    );
    
  }


}