<div class="inner-properties">
    <div (click)="minimizeToolBar()" class="toolbar-minimize" *ngIf="isToolBarOpen">
      <!-- <fa-icon [icon]="faCaretRight"></fa-icon> -->
      <em class="icon-class material-icons">highlight_off</em>
    </div>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title class="heading-container">CHART PROPS. </mat-panel-title>
      </mat-expansion-panel-header>
      <label class="properties font-size-sm">
        <span *ngIf="chartcomponent.chartData.data.length == 0">ADD DATA</span>
        <span *ngIf="chartcomponent.chartData.data.length > 0">CHANGE DATA</span>&nbsp;&nbsp;
        <button class="remove-default-style properties-button" (click)="openChartInputsDialog()" matTooltip="Add chart data" matTooltipPosition=right>
          <fa-icon [icon]="faEdit"></fa-icon>
        </button>
      </label>
      <div class="input-dropdown">
        <label  class="properties font-size-sm chart-type-dropdown">CHART TYPE</label>
        <mat-form-field >
          <mat-select class="font-size-md full-width"  [(ngModel)]="chartcomponent.chartName"  (selectionChange)="transformByChartType()" >
            <mat-option *ngFor="let chart of chartTypes" [value]="chart.name">{{chart.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    
      <div class="properties font-size-sm">
        <mat-checkbox class="check-box " style="padding-right:6px" (ngModelChange)="toggleMaintainAspectRatio()" [ngModel]="chartcomponent.maintainAspectRatio">MAINTAIN ASPECT RATIO</mat-checkbox>
      </div>
    
      <div class="properties properties-bar">
        <label class="font-size-sm aspect-width">WIDTH (in em)&nbsp;
          <input #chartWidth class="input-time" type="number" min="1" step="0.1" [ngModel]="chartcomponent.cssProperties.width | calcFontSize"
          
          (ngModelChange)="updateChartWidth(chartWidth.value)">
        </label>
        <label class="font-size-sm aspect-width">HEIGHT (in em)&nbsp;
          <input #chartHeight class="input-time" type="number" min="1" step="0.1" [ngModel]="chartcomponent.cssProperties.height | calcFontSize"
          
          (ngModelChange)="updateChartHeight(chartHeight.value)">
        </label>
      </div>
    
      <div class="properties properties-bar"
        *ngIf="chartcomponent.chartName === 'LINE'">
        <label class="font-size-sm aspect-width">LINE THICKNESS&nbsp;
          <input class="input-time" type="number"
            [(ngModel)]="chartcomponent.data.options.elements.line.borderWidth"
            (ngModelChange)="loadElement(chartcomponent)">
        </label>
        <label class="font-size-sm aspect-width">LINE TENSION&nbsp;
          <input class="input-time" type="number"
            [(ngModel)]="chartcomponent.data.options.elements.line.tension"
            (ngModelChange)="loadElement(chartcomponent)">
        </label>
      </div>
    
      <div class="properties properties-bar check-box-div" *ngIf="chartcomponent.chartLibrary === 'nvd3'">
        <label class="font-size-sm">SHOW/HIDE</label>
        <div class="check-box-inner" *ngIf="chartcomponent.data.charttype !== 'pie'">
          <mat-checkbox class="check-box" (input)="loadElement(chartcomponent)" [(ngModel)]="chartcomponent.data.options.showXAxisLabel">X AXIS LABEL</mat-checkbox>
          <mat-checkbox class="check-box" (input)="loadElement(chartcomponent)" [(ngModel)]="chartcomponent.data.options.showYAxisLabel">Y AXIS LABEL</mat-checkbox>
        </div>
        <div class="check-box-inner" *ngIf="chartcomponent.data.charttype !== 'pie'">
            <mat-checkbox class="check-box" (input)="loadElement(chartcomponent)" [(ngModel)]="chartcomponent.data.options.showXAxis">X AXIS</mat-checkbox>
            <mat-checkbox class="check-box" (input)="loadElement(chartcomponent)" [(ngModel)]="chartcomponent.data.options.showYAxis">Y AXIS</mat-checkbox>
        </div>
        <div class="check-box-inner">
          <mat-checkbox class="check-box" (input)="loadElement(chartcomponent)" [(ngModel)]="chartcomponent.data.options.showLegend">LEGEND</mat-checkbox>
          <mat-checkbox class="check-box" (input)="loadElement(chartcomponent)" *ngIf="chartcomponent.data.charttype !== 'pie'" [(ngModel)]="chartcomponent.data.options.showValues">VALUES</mat-checkbox>
          <mat-checkbox class="check-box" (input)="loadElement(chartcomponent)" *ngIf="chartcomponent.data.charttype === 'pie'" [(ngModel)]="chartcomponent.data.options.showValues">LABELS</mat-checkbox>
        </div>
        <div class="check-box-grid" *ngIf="chartcomponent.data.charttype === 'pie'">
          <mat-checkbox class="check-box check-box-margin" (input)="loadElement(chartcomponent)" [(ngModel)]="chartcomponent.data.options.labelsOutside">LABELS OUTSIDE</mat-checkbox>
          <mat-checkbox class="check-box" (input)="loadElement(chartcomponent)" [(ngModel)]="chartcomponent.data.options.labelSunbeamLayout">LABEL SUNBEAM LAYOUT</mat-checkbox>
        </div>
        <mat-checkbox *ngIf="chartcomponent.data.charttype !== 'pie'" class="check-box" (input)="loadElement(chartcomponent)" [(ngModel)]="chartcomponent.data.options.wrapLabels">WRAP LABELS</mat-checkbox>
      </div>
    
      <div class="properties properties-bar check-box-div" *ngIf="chartcomponent.chartLibrary === 'chartjs'">
        <label class="font-size-sm">SHOW/HIDE</label>
        <div class="check-box-inner" *ngIf="chartcomponent.data.charttype !== 'pie' && 
        chartcomponent.data.charttype !== 'doughnut'">
          <mat-checkbox class="check-box" (input)="loadElement(chartcomponent)" style="padding-right:6px;" [(ngModel)]="chartcomponent.data.options.scales.xAxes[0].scaleLabel.display">X AXIS LABEL</mat-checkbox>
          <mat-checkbox class="check-box" (input)="loadElement(chartcomponent)" [(ngModel)]="chartcomponent.data.options.scales.yAxes[0].scaleLabel.display">Y AXIS LABEL</mat-checkbox>
        </div>
        <div class="check-box-inner" *ngIf="chartcomponent.data.charttype !== 'pie' && 
        chartcomponent.data.charttype !== 'doughnut'">
          <mat-checkbox class="check-box" (input)="loadElement(chartcomponent)" style="padding-right:8px;" [(ngModel)]="chartcomponent.data.options.scales.xAxes[0].display">X AXIS</mat-checkbox>
          <mat-checkbox class="check-box" (input)="loadElement(chartcomponent)" [(ngModel)]="chartcomponent.data.options.scales.yAxes[0].display">Y AXIS</mat-checkbox>
        </div>
        <div class="check-box-inner" *ngIf="chartcomponent.data.charttype === 'pie' || 
        chartcomponent.data.charttype === 'doughnut'">
          <mat-checkbox class="check-box" (input)="loadElement(chartcomponent)" [(ngModel)]="chartcomponent.data.options.legend.display">LEGEND</mat-checkbox>
          <mat-checkbox class="check-box" (change)="toggleLabels()" [(ngModel)]="labelsCheckbox">LABELS</mat-checkbox>
        </div>
        
        <div class="check-box-grid" *ngIf="chartcomponent.data.charttype === 'pie' || 
        chartcomponent.data.charttype === 'doughnut'">
          <mat-checkbox class="check-box check-box-margin" (change)="pieLabelsPosition()" [(ngModel)]="labelsOutsideCheckbox" [disabled]="!labelsCheckbox">LABELS OUTSIDE</mat-checkbox>
          <mat-checkbox class="check-box" (change)="labelSunbeam()" [(ngModel)]="labelSunbeamCheckbox" [disabled]="!labelsCheckbox">LABEL SUNBEAM LAYOUT</mat-checkbox>
        </div>
      </div>
    
      <div class="properties properties-bar check-box-div" 
        *ngIf="chartcomponent.chartLibrary === 'chartjs' && ['line','bar','area'].includes(chartcomponent.data.charttype) ">
        <label class="font-size-sm label-margin">SHOW/HIDE GRIDLINES</label>
        <div class="font-size-sm bg-margin">
          <label class="gridline-label">X AXIS GRIDLINES</label>
          <mat-slide-toggle [(ngModel)]="chartcomponent.data.options.scales.xAxes[0].gridLines.display"
          (change)="loadElement(chartcomponent)"></mat-slide-toggle>
        </div>
        <div *ngIf="chartcomponent.data.options.scales.xAxes[0].gridLines.display">
          <div class="check-box-inner">
            <mat-checkbox class="check-box" (input)="loadElement(chartcomponent)" 
              [disabled]="!chartcomponent.data.options.scales.xAxes[0].gridLines.display"
              [(ngModel)]="chartcomponent.data.options.scales.xAxes[0].gridLines.drawBorder">
              X AXIS LINE
            </mat-checkbox>
            <mat-checkbox class="check-box" (input)="loadElement(chartcomponent)" 
              [disabled]="!chartcomponent.data.options.scales.xAxes[0].gridLines.display"
              [(ngModel)]="chartcomponent.data.data.options.scales.xAxes[0].gridLines.drawTicks">
              X AXIS TICK
            </mat-checkbox>
          </div>
          <div class="check-box-inner" >
            <mat-checkbox class="check-box" (input)="loadElement(chartcomponent)" 
              [disabled]="!chartcomponent.data.options.scales.xAxes[0].gridLines.display"
              [(ngModel)]="chartcomponent.data.options.scales.xAxes[0].gridLines.drawOnChartArea">
              DRAW ON CHART AREA (X AXIS)
            </mat-checkbox>
          </div>
          <div class="properties properties-bar" >
            <label class="font-size-sm label-width">GRID COLOR(X)</label>
            <input class="color-picker monochrome-picker label-width" type="color" #gridBorderColorX 
              [ngModel]="chartcomponent.data.options.scales.xAxes[0].gridLines.color | colorConvert"
              (input)="changeGridLinesColor('xAxes',gridBorderColorX.value)">
            <input type="text" class="color-picker-value font-size-md monochrome-value" 
              [(ngModel)]="chartcomponent.data.options.scales.xAxes[0].gridLines.color"
              (input)="loadElement(chartcomponent)">
          </div>
        </div>
        <div class="font-size-sm bg-margin">
          <label class="gridline-label">Y AXIS GRIDLINES</label>
          <mat-slide-toggle [(ngModel)]="chartcomponent.data.options.scales.yAxes[0].gridLines.display"
          (change)="loadElement(chartcomponent)"></mat-slide-toggle>
        </div>
        <div *ngIf="chartcomponent.data.options.scales.yAxes[0].gridLines.display">
          <div class="check-box-inner">
            <mat-checkbox class="check-box" (input)="loadElement(chartcomponent)" 
              [disabled]="chartcomponent.data.options.scales.yAxes[0].gridLines.display"
              [(ngModel)]="chartcomponent.data.options.scales.yAxes[0].gridLines.drawBorder">
              Y AXIS LINE
            </mat-checkbox>
            <mat-checkbox class="check-box" (input)="loadElement(chartcomponent)" 
              [disabled]="chartcomponent.data.options.scales.yAxes[0].gridLines.display"
              [(ngModel)]="chartcomponent.data.options.scales.yAxes[0].gridLines.drawTicks">
              Y AXIS TICK
            </mat-checkbox>
          </div>
          <div class="check-box-inner">
            <mat-checkbox class="check-box" (input)="loadElement(chartcomponent)" [disabled]="!chartcomponent.data.options.scales.yAxes[0].gridLines.display"
              [(ngModel)]="chartcomponent.data.options.scales.yAxes[0].gridLines.drawOnChartArea">
              DRAW ON CHART AREA (Y AXIS)
            </mat-checkbox>
          </div>
          <div class="properties properties-bar" >
            <label class="font-size-sm label-width">GRID COLOR(Y)</label>
            <input class="color-picker monochrome-picker label-width" type="color" #gridBorderColorY [ngModel]="chartcomponent.data.options.scales.yAxes[0].gridLines.color | colorConvert"
              (input)="changeGridLinesColor('yAxes',gridBorderColorY.value)">
            <input type="text" class="color-picker-value font-size-md monochrome-value" [(ngModel)]="chartcomponent.data.options.scales.yAxes[0].gridLines.color"
              (input)="loadElement(chartcomponent)">
          </div>
        </div>
      </div>
    <!-- chart annotation -->
      <div class="properties properties-bar check-box-div" 
       *ngIf="chartcomponent.data.chartLibrary === 'chartjs' && ['line','bar','area'].includes(chartcomponent.data.data.charttype)">
        <label class="font-size-sm label-margin">TICKS OPTIONS (X AXIS)</label>
        <div class="check-box-inner">
          <mat-checkbox class="check-box" (input)="loadElement(chartcomponent)" 
            [(ngModel)]="chartcomponent.data.options.scales.xAxes[0].ticks.display">
            SHOW TICKS
          </mat-checkbox>
          <mat-checkbox class="check-box" (input)="loadElement(chartcomponent)" 
            [(ngModel)]="chartcomponent.data.options.scales.xAxes[0].ticks.beginAtZero">
            BEGIN AT ZERO
          </mat-checkbox>
        </div>
        <div class="properties properties-bar">
          <label class="font-size-sm aspect-width">MIN. TICKS&nbsp;
            <input class="input-time" type="number"
              [(ngModel)]="chartcomponent.data.options.scales.xAxes[0].ticks.suggestedMin"
              (input)="loadElement(chartcomponent)">
          </label>
          <label class="font-size-sm aspect-width">MAX. TICKS&nbsp;
            <input class="input-time" type="number"
              [(ngModel)]="chartcomponent.data.options.scales.xAxes[0].ticks.maxTicksLimit"
              (input)="loadElement(chartcomponent)">
          </label>
        </div>
        <div class="properties properties-bar">
          <label class="font-size-sm aspect-width">STEP SIZE&nbsp;
            <input class="input-time" type="number"
              [(ngModel)]="chartcomponent.data.options.scales.xAxes[0].ticks.stepSize"
              (input)="loadElement(chartcomponent)">
          </label>
          <label class="font-size-sm aspect-width">MIN. ROTATION&nbsp;
            <input class="input-time" type="number"
              [(ngModel)]="chartcomponent.data.options.scales.xAxes[0].ticks.minRotation"
              (input)="loadElement(chartcomponent)">
          </label>
        </div>
      </div>
    
      <div class="properties properties-bar check-box-div" 
        *ngIf="chartcomponent.chartLibrary === 'chartjs' && ['line','bar','area'].includes(chartcomponent.data.charttype)">
        <label class="font-size-sm label-margin">TICKS OPTIONS (Y AXIS)</label>
        <div class="check-box-inner">
          <mat-checkbox class="check-box" (input)="loadElement(chartcomponent)" 
            [(ngModel)]="chartcomponent.data.options.scales.yAxes[0].ticks.display">
            SHOW TICKS
          </mat-checkbox>
          <mat-checkbox class="check-box" (input)="loadElement(chartcomponent)" 
            [(ngModel)]="chartcomponent.data.options.scales.yAxes[0].ticks.beginAtZero">
            BEGIN AT ZERO
          </mat-checkbox>
        </div>
        <div class="properties properties-bar">
          <label class="font-size-sm aspect-width">MIN. TICKS&nbsp;
            <input class="input-time" type="number"
              [(ngModel)]="chartcomponent.data.options.scales.yAxes[0].ticks.suggestedMin"
              (input)="loadElement(chartcomponent)">
          </label>
          <label class="font-size-sm aspect-width">MAX. TICKS&nbsp;
            <input class="input-time" type="number"
              [(ngModel)]="chartcomponent.data.options.scales.yAxes[0].ticks.maxTicksLimit"
              (input)="loadElement(chartcomponent)">
          </label>
        </div>
        <div class="properties properties-bar">
          <label class="font-size-sm aspect-width">STEP SIZE&nbsp;
            <input class="input-time" type="number"
              [(ngModel)]="chartcomponent.data.options.scales.yAxes[0].ticks.stepSize"
              (input)="loadElement(chartcomponent)">
          </label>
          <label class="font-size-sm aspect-width">MIN. ROTATION&nbsp;
            <input class="input-time" type="number"
              [(ngModel)]="chartcomponent.data.options.scales.yAxes[0].ticks.minRotation"
              (input)="loadElement(chartcomponent)">
          </label>
        </div>
      </div>
    
      <label class="properties font-size-sm">
        FORMAT VALUES
      </label>
      <div class="properties properties-bar">
        <label class="font-size-sm aspect-width">BEFORE&nbsp;
          <input class="input-time" [(ngModel)]="chartcomponent.data.options.preFormatValue"
            (input)="loadElement(chartcomponent)">
        </label>
        <label class="font-size-sm aspect-width">AFTER&nbsp;
          <input class="input-time" [(ngModel)]="chartcomponent.data.options.postFormatValue"
            (input)="loadElement(chartcomponent)">
        </label>
      </div>
    
      <label class="properties font-size-sm">
        FONT SIZE (in em)
      </label>
      <div class="properties-bar button-margin">
        <button class="remove-default-style properties-button" (click)="decreaseFontSize()" matTooltip="Reduce Font Size" matTooltipPosition=left>
          <fa-icon [icon]="faMinusCircle"></fa-icon>
        </button>
        <input class="font-size-lg center-align width-half font-padding-text input-box" min="0.01" type="number" step="0.01" *ngIf="chartcomponent.chartLibrary === 'nvd3'"
        
        [ngModel]="chartcomponent.data.options.fontSize" (ngModelChange)="updateFontSize($event)">
        <input class="font-size-lg center-align width-half font-padding-text" min="0.01" type="number" step="0.01" *ngIf="chartcomponent.chartLibrary === 'chartjs'"
        
        [ngModel]="chartcomponent.data.options.fontSize" (ngModelChange)="updateFontSize($event)">
        <button class="remove-default-style properties-button" (click)="increaseFontSize()" matTooltip="Add Font Size" matTooltipPosition=left>
          <fa-icon [icon]="faPlusCircle"></fa-icon>
        </button>
      </div>
    
      <label class="properties font-size-sm rotate-label" *ngIf="chartcomponent.chartLibrary === 'nvd3' && chartcomponent.data.charttype !== 'pie'">ROTATE LABELS</label>
      <div class="properties-bar">
        <label class="font-size-lg rotate-bar" *ngIf="chartcomponent.chartLibrary === 'nvd3' && chartcomponent.data.charttype !== 'pie'">
          <mat-slider class="rotate-slider-horizontal" min="0" max="90" step="1" [(ngModel)]="chartcomponent.data.options.rotateLabels"
            (input)="loadElement(chartcomponent)">
          </mat-slider>
          <span>{{chartcomponent.data.options.rotateLabels}}deg</span>
        </label>
        <!-- <label class="font-size-lg rotate-bar" *ngIf="data.overlayJSON.overlays[newOverlayComponent.id - 1].chartLibrary === 'chartjs'">
          <mat-slider class="rotate-slider-horizontal" min="0" max="90" step="1" [(ngModel)]="data.overlayJSON.overlays[newOverlayComponent.id - 1].data.data.options.scales.xAxes[0].ticks.minRotation"
            (input)="loadElement(data.overlayJSON.overlays[newOverlayComponent.id - 1])">
          </mat-slider>
          <span>{{data.overlayJSON.overlays[newOverlayComponent.id - 1].data.data.options.scales.xAxes[0].ticks.minRotation}}deg</span>
        </label> -->
      </div>
    
      <label class="font-size-sm label-margin" *ngIf="chartcomponent.chartLibrary === 'nvd3' && chartcomponent.data.charttype !== 'pie'">
        LABEL X-AXIS DISTANCE
      </label>
      <div class="properties-bar button-margin" *ngIf="chartcomponent.chartLibrary === 'nvd3' && chartcomponent.data.charttype !== 'pie'">
        <button class="remove-default-style properties-button" (click)="decreaseXaxisLabelDistance()" matTooltip="Reduce Axis Label Distance" matTooltipPosition=left>
          <fa-icon [icon]="faMinusCircle"></fa-icon>
        </button>
        <input class="font-size-lg center-align width-half font-padding-text input-box" type="number" step="1" 
        
        [(ngModel)]="chartcomponent.data.options.xAxisLabelDistance" (input)="loadElement(chartcomponent)">
        <button class="remove-default-style properties-button" (click)="increaseXaxisLabelDistance()" matTooltip="Increase Axis Label Distance" matTooltipPosition=left>
          <fa-icon [icon]="faPlusCircle"></fa-icon>
        </button>
      </div>
      <label class="font-size-sm label-margin" *ngIf="chartcomponent.chartLibrary === 'nvd3' && chartcomponent.data.charttype !== 'pie'">
        LABEL Y-AXIS DISTANCE
      </label>
      <div class="properties-bar button-margin" *ngIf="chartcomponent.chartLibrary === 'nvd3' && chartcomponent.data.charttype !== 'pie'">
        <button class="remove-default-style properties-button" (click)="decreaseYaxisLabelDistance()" matTooltip="Reduce Axis Label Distance" matTooltipPosition=left>
          <fa-icon [icon]="faMinusCircle"></fa-icon>
        </button>
        <input class="font-size-lg center-align width-half font-padding-text input-box" type="number" step="1" 
        
        [(ngModel)]="chartcomponent.data.options.yAxisLabelDistance" (input)="loadElement(chartcomponent)">
        <button class="remove-default-style properties-button" (click)="increaseYaxisLabelDistance()" matTooltip="Increase Axis Label Distance" matTooltipPosition=left>
          <fa-icon [icon]="faPlusCircle"></fa-icon>
        </button>
      </div>
      <label class="properties font-size-sm label-color">
        LABEL COLOR
      </label>
      <div class="properties-bar label-color-field" *ngIf="chartcomponent.chartLibrary === 'nvd3'">
        <input class="color-picker" type="color" #labelColorNvd3 [ngModel]="chartcomponent.data.options.labelColor | colorConvert"
          (ngModelChange)="changeLabelColorNvd3(labelColorNvd3.value)">
        <input type="text" class="color-picker-value font-size-md color-value" [(ngModel)]="chartcomponent.data.options.labelColor"
          (ngModelChange)="loadElement(chartcomponent)">
      </div>
      <div class="properties-bar" *ngIf="chartcomponent.chartLibrary === 'chartjs'">
        <input class="color-picker" type="color" #labelColorChartjs [ngModel]="chartcomponent.data.options.scales.yAxes[0].scaleLabel.fontColor | colorConvert"
          (ngModelChange)="changeLabelColorChartjs(labelColorChartjs.value)">
        <input type="text" class="color-picker-value font-size-md color-value" [(ngModel)]="chartcomponent.data.options.scales.yAxes[0].scaleLabel.fontColor"
          (ngModelChange)="updateLabelColor()">
      </div>
    
       <label class="properties font-size-sm label-color">
      CHART COLOR
      </label>
      <div class="font-size-sm radio-elements button-margin">
        <label>
          <input type="radio" class = "radio-btn-properties" name="colorType" (change)="changeColorType()" [(ngModel)]="chartcomponent.colorType" value="theme"
            checked>Theme&nbsp;
        </label>
        <label>
          <input type="radio" class = "radio-btn-properties" name="colorType" (change)="changeColorType()" [(ngModel)]="chartcomponent.colorType" value="custom">Custom&nbsp;
        </label>
        <div *ngIf="chartcomponent.colorType === 'theme'">
          <select class="font-size-md full-width" [(ngModel)]="chartcomponent.selectedColorTheme" (change)="updateColorTheme()">
            <option *ngFor="let colorTheme of chartGroups" [ngValue]="colorTheme">{{colorTheme}}</option>
          </select>
        </div>
        <div *ngIf="chartcomponent.colorType === 'custom'">
          <textarea class="full-width" [(ngModel)]="customColorList" (input)="updateCustomColorList()"></textarea>
        </div>
      </div>
    
      <label class="font-size-sm rotate-label" *ngIf="chartcomponent.chartLibrary === 'chartjs'">CHART ANIMATION</label>
      <select *ngIf="chartcomponent.chartLibrary === 'chartjs'" class="font-size-md" [(ngModel)]="chartcomponent.data.options.animation.easing"
        (change)="loadElement(chartcomponent)">
        <option *ngFor="let animation of animationList" [ngValue]="animation.value">{{animation.name}}</option>
      </select>
    
      <label class="properties font-size-sm label-color">CHART ANIMATION DURATION (in ms)</label>
      <div class="properties-bar">
        <button class="remove-default-style properties-button button-margin" (click)="decreaseAnimationDuration()" matTooltip="Reduce Animation Duration"
          matTooltipPosition=left>
          <fa-icon [icon]="faMinusCircle"></fa-icon>
        </button>
        <input class="font-size-lg center-align width-half input-box font-size-md" min="0" type="number" [(ngModel)]="chartcomponent.data.options.animation.duration"
          (input)="loadElement(chartcomponent)" *ngIf="chartcomponent.chartLibrary === 'chartjs'">
        <input class="font-size-lg center-align width-half input-box font-size-md" min="0" type="number" [(ngModel)]="chartcomponent.data.options.animationDuration"
          (input)="loadElement(chartcomponent)" *ngIf="chartcomponent.chartLibrary === 'nvd3'">
        <button class="remove-default-style properties-button" (click)="increaseAnimationDuration()" matTooltip="Add Animation Duration"
          matTooltipPosition=left>
          <fa-icon [icon]="faPlusCircle"></fa-icon>
        </button>
      </div>
    
      <div class="font-size-sm bg-margin">
        <label class="bg-label">BACKGROUND COLOR </label>
        <mat-slide-toggle [(ngModel)]="chartcomponent.bgToggle" (change)="toTransparent()"></mat-slide-toggle>
      </div>
      <div *ngIf="chartcomponent.bgToggle">
        <div class="properties-bar">
          <label class="bg-color-label">
            <input class="color-picker picker-button" type="color" #bgColor
            [ngModel]="chartcomponent.cssProperties.backgroundColor | colorConvert"
            (input)="changeBgColor(bgColor.value)">
            <input type="text" class="color-picker-value picker-value font-size-md" 
            [ngModel]="chartcomponent.cssProperties.backgroundColor"
            (ngModelChange)="setBgColor($event)">
          </label>
        </div>
        
        <label class="properties font-size-sm">
          BACKGROUND PADDING
         </label>
        <div class="properties-bar">
          <button class="remove-default-style properties-button" (click)="decreaseOutlineSize()" matTooltip="Reduce BackGround Padding" matTooltipPosition=left>
            <fa-icon [icon]="faMinusCircle"></fa-icon>
          </button>
          <input class="font-size-lg center-align width-half input-box" type="number" step="0.01" [ngModel]="chartcomponent.cssProperties.outlineWidth | calcFontSize"
          
          (ngModelChange)="updateOutlineSize($event)">
          <button class="remove-default-style properties-button" (click)="increaseOutlineSize()" matTooltip="Add BackGround Padding" matTooltipPosition=left>
            <fa-icon [icon]="faPlusCircle"></fa-icon>
          </button>
        </div>
        <label class="properties font-size-sm label-margin">
          BORDER RADIUS
        </label>
        <div class="properties-bar">
            <button class="remove-default-style properties-button" (click)="decreaseBorderRadius()" matTooltip="Reduce Border Radius" matTooltipPosition=left>
                <fa-icon [icon]="faMinusCircle"></fa-icon>
              </button>
              <input class="font-size-lg center-align width-half font-padding-text" type="number" step="0.01"
              [ngModel]="chartcomponent.cssProperties.borderRadius | calcFontSize" 
       
              (ngModelChange)="updateBorderRadius($event)" >
              <button class="remove-default-style properties-button" (click)="increaseBorderRadius()" matTooltip="Add Border Radius" matTooltipPosition=left>
                <fa-icon [icon]="faPlusCircle"></fa-icon>
              </button>
        </div>
       </div>
    </mat-expansion-panel>
    </div>