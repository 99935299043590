import { environment } from "src/environments/environment";
import { DataService } from "../services/data.service";
import { AudioOverlayAnnotation } from "./audio-overlay.model";
import { PSV_Meta } from "./psv.model";
import { CaptionAnnotation } from "./caption-annotation.model";

export class SceneModel {

  id: string;
  _id: any;
  isDefaultAnnotationsAdded: boolean = false;
  script: string;
  displayScript!: string;
  duration!: number;
  isCaptionAvailable = false;
  enableTranscript = false;
  videoMeta: any = {
    "overlays": [],
    "audios": [],
    "captions": {
        "is-available": false,
        "enable-transcript": false,
        "src": "",
        "kind": "captions",
        "srclang": "en",
        "label": "English"
    },
    "backgroundAudio":undefined,
    "captionTableData": []
  };
  videoPath: string;
  videoName: string;
  isLoaderEnabled: boolean = true;
  videoSource = {
    type: "default",
    path: "",
    autoplay: false
  }
  annotationTableData: any = [];
  audioDuration: any;
  psvModel!: PSV_Meta;
  psvId: any;
  caption_annotationTableData: any = [];
  captionUrl: any;
  captionBlob: any;
  captionStr: any;
  


  constructor(id: string, script: string, videoPath: string, readonly data: DataService) {
    this.id = id;
    this.script = script;
    this.displayScript = script;
    this.videoPath = videoPath;
    this.videoSource.path = this.videoPath;
      this.videoMeta.captions = {
        "is-available": false,
        "enable-transcript": false,
        "src": "",
        "kind": "captions",
        "srclang": "en",
        "label": "English"
      }
    this.psvModel = new PSV_Meta(this.id, this.videoSource, this.videoMeta, this.data.userData, this.data.ttsOptions);
    this.psvModel.tts = this.data.tts;
  }



  setPSV() {
    this.videoSource.path = this.videoPath;
    this.psvModel = new PSV_Meta(this.id, this.videoSource, this.videoMeta, this.data.userData, this.data.ttsOptions);
    this.psvModel.tts = this.data.tts;
    if (this.data.isSceneSelected) {
      this.psvModel.isPersonalizeScreen = true;
    }
    
    this.data.callAddDynamicAudioToVideo(this.psvModel);

    this.data.setPSVMeta(this.psvModel);
    this.isLoaderEnabled = false;
  }

  removePSV() {
    // let psvModel = new PSV_Meta(this.id, this.videoSource, this.videoMeta, this.data.userData);
    this.data.removePSV(this.psvModel)
  }

  getVideoDuration() {
    this.data.videoDuration.subscribe((val: any) => this.duration = val);
    return this.duration;
  }
  setSceneScript(val: string) {
    this.script = val;
  }
  getSceneScript(): string {
    return this.script;
  }
  getDisplayScript(): string {
    return this.displayScript;
  }

  setAnnotationTableData() {
    for (let audio of this.videoMeta.audios) {
      this.setAnnotationTableDataForOverlay(audio);
    }

    for (let overlay of this.videoMeta.overlays) {
      this.setAnnotationTableDataForOverlay(overlay);
    }
    for (let captionData of this.videoMeta.captionTableData){
      this.setAnnotationTableDataForOverlay(captionData);
    }
  }

  getSceneObjForDB() {
    let sceneObj: any = {};

    sceneObj['playerId'] = this.id;
    sceneObj['script'] = this.script;
    sceneObj['duration'] = this.duration;
    if (this.psvId) {
      sceneObj['PSV'] = this.psvId;
    }
    if (this._id) {
      sceneObj['id'] = this._id;
    }

    return sceneObj;
  }

  updatePSVObj(){
    this.psvModel.videoSource.path = this.videoPath;
  }

  getPSVObjForDB() {
    let psvObj: any = {};

    psvObj['playerId'] = this.id;
    psvObj['videoSourceObj'] = this.psvModel.videoSource;
    psvObj['psvMetaDataObj'] = this.psvModel.formatVideoMeta(this.videoMeta);
    psvObj['userDatalist'] = { 'user1': this.data.userDataId };
    psvObj['ttsOptions'] = this.psvModel.ttsOptions;
    if (this.psvId) {
      psvObj['id'] = this.psvId;
    }

    return psvObj;
  }

  setAnnotationTableDataForOverlay(newOverlayObject: any) {
    const annotationObject: any = {};
    annotationObject.id = newOverlayObject.id;
    annotationObject.durationWidth = 100;
    annotationObject.startPoint = 0;
    annotationObject.type = newOverlayObject.type;
    if(annotationObject.type != 'caption'){
    switch (newOverlayObject.type) {
      case 'text': {
        annotationObject.icon = "title";
        annotationObject.value = newOverlayObject.value;
        break;
      }
      case 'image': {
        annotationObject.icon = "image";
        if (newOverlayObject.imageName) {
          annotationObject.value = newOverlayObject.imageName;
        } else if (newOverlayObject.srcUrl === "") {
          annotationObject.value = "image" + annotationObject.id;
        } else {
          let url = newOverlayObject.srcUrl.split('/');
          annotationObject.value = url[url.length - 1];
        }
        break;
      }
      case 'link': {
        annotationObject.value = newOverlayObject.message;
        annotationObject.icon = "touch_app";
        break;
      }
      case 'video': {
        annotationObject.icon = "video_file";
        if (newOverlayObject.videoName) {
          annotationObject.value = newOverlayObject.videoName;
        } else if (newOverlayObject.srcUrl === "") {
          annotationObject.value = "video" + annotationObject.id;
        } else {
          let url = newOverlayObject.srcUrl.split('/');
          annotationObject.value = url[url.length - 1];
        }
        break;
      }
      case 'livevideo': {
        annotationObject.icon = "radio_button_checked";
        if (newOverlayObject.videoName) {
          annotationObject.value = newOverlayObject.videoName;
        } else {
          annotationObject.value = "LiveVideo" + annotationObject.id;
        }
        // } else {
        //   annotationObject.value = newOverlayObject.srcUrl;
        // }
        break;
      }
      case 'liveAudio': {
        annotationObject.icon = "keyboard_voice";
        if (newOverlayObject.audioName) {
          annotationObject.value = newOverlayObject.audioName;
        } else {
          annotationObject.value = "LiveAudio" + annotationObject.id;
        }
        break;
      }
      case 'audio': {
        annotationObject.icon = "audio_file";
        if (newOverlayObject.audioName) {
          annotationObject.value = newOverlayObject.audioName;
        } else if (newOverlayObject.srcUrl === "") {
          annotationObject.value = "audio-" + annotationObject.id;
        } else {
          let url = newOverlayObject.srcUrl.split('/');
          annotationObject.value = url[url.length - 1];
        }
        break;
      }
      case 'tel': {
        annotationObject.value = newOverlayObject.message;
        annotationObject.icon = "call";
        break;
      }
      case 'mail': {
        annotationObject.value = newOverlayObject.message;
        annotationObject.icon = "email";
        break;
      }
      case 'section': {
        annotationObject.value = newOverlayObject.message;
        annotationObject.icon = "link";
        break;
      }
      case 'chart': {
        annotationObject.icon = "poll";
        annotationObject.value = newOverlayObject.data.chartType;
        break;
      }
      case 'tts': {
        annotationObject.icon = "record_voice_over";
        if (this.data.tts === "polly") {
          annotationObject.value = newOverlayObject.params.Text ? newOverlayObject.params.Text : "audio" + newOverlayObject.id;
        } else if (this.data.tts === "google") {
          annotationObject.value = newOverlayObject.params.input.ssml ? newOverlayObject.params.input.ssml : "audio" + newOverlayObject.id;
        } else if (this.data.tts === "azure") {
          annotationObject.value = newOverlayObject.params.text ? newOverlayObject.params.text : "audio" + newOverlayObject.id;
        }
        break;
      }
      case 'progressbar': {
        annotationObject.icon = "poll";
        annotationObject.value = newOverlayObject.value;
        break;
      }
      case 'webpage': {
        annotationObject.icon = "language";
        if(newOverlayObject.value){
          annotationObject.value = newOverlayObject.value;
        }
        else{
          if(newOverlayObject.src){
            annotationObject.value = newOverlayObject.src
          }
          else{
            annotationObject.value = "Your URL";
          }
        }
        break;
      }
      case 'file': {
        annotationObject.icon = "insert_drive_file";
        if(newOverlayObject.value){
          annotationObject.value = newOverlayObject.value;
        }
        else{
          if(newOverlayObject.src){
            let url = newOverlayObject.src.split('/');
            annotationObject.value = url[url.length - 1];
          }
          else{
            annotationObject.value = "Your URL";
          }
        }
        break;
      }
    }
    this.annotationTableData.push(annotationObject);
    this.data.notifyAnnotationUpdate("");
  }
  
  else{
    annotationObject.start = newOverlayObject.start;
    annotationObject.end = newOverlayObject.end;
    annotationObject.Start = newOverlayObject.Start;
    annotationObject.End = newOverlayObject.End;
    annotationObject.caption_text = newOverlayObject.caption_text;
    annotationObject.value = newOverlayObject.caption_text;
    annotationObject.cssProperties = newOverlayObject.cssProperties;
    annotationObject.isDurationChanged = true;
    annotationObject.size = { value: 70, valid: true };
    annotationObject.icon = "closed_caption";
     this.caption_annotationTableData.push(annotationObject);
     annotationObject.isDurationChanged = false;
     this.data.notifyAnnotationUpdate("");
  }
  }
}
