<div class="heading-container" [ngClass]="{'align-to-start': !data.onCreateVideo}">
    <!-- <strong *ngIf="data.onCreateVideo" class="main-headings">{{data.titleValue}}</strong> -->
    <!-- <strong *ngIf="!data.onCreateVideo" class="main-headings">PSV Studio</strong> -->


    <!-- <div class="publish-btn-container" *ngIf="data.onCreateVideo && data.onPublishBtn" (click)="onPublish()">
        <button class="publish-btn primary-btn">Publish</button>
    </div> -->
</div>
<div *ngIf="!data.onCreateVideo">
    <app-inputtext></app-inputtext>
</div>
<div class="script-scenes" *ngIf="data.onCreateVideo">   
    <app-script-content *ngIf="!data.isSceneSelected"></app-script-content>   
    <div class="scenes-and-timeline" [ngClass]="{'scene-selected': data.isSceneSelected}">
        <app-scenes></app-scenes>
        <timeline class="timeline" *ngIf="data.isSceneSelected"></timeline>
    </div>
    <app-toolbar class="toolbar" *ngIf="data.isSceneSelected"></app-toolbar>
    <app-bg-audio-poster  *ngIf="!data.isSceneSelected" ></app-bg-audio-poster>
    
</div>