<div class="gallery-view-container" *ngIf="mediaType === 'image'">
    <mat-tab-group>
        <mat-tab label="Gallery">
            <ng-template matTabContent>
                <div class="gallery-container">
                    <div class="search-container">
                        <form action="#" autocomplete="off" class="search-form">
                            <input class="search-bar" type="text" #keyword placeholder={{searchPlaceholder}} name="search">
                            <button class="search-Btn" (click)="searchKeyword(keyword.value, 'gallery')">
                                <em class="fa fa-search fa-small" style="color:#8d8d8d"></em>
                            </button>
                        </form>
                    </div>
                    <div class="gallery-list">
                        <div class="gallery-card" *ngFor="let image of matchedImageSrc; let i=index;">
                            <div (click)="clicked(image,i)" [style.background]="selectedMediaIndex === i ? '#1e8efb' : '' " class="highlight">
                                <div class="image-style">
                                    <img #imgcheck [src]="image.url" width="100%" for="check" height="100%">
                                </div>
                                <span class="media-label">{{image.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab label="Upload" [disabled]="true">
            <ng-template matTabContent>
                <div class="uploads-container">
                    <div class="search-container">
                        <form action="#" autocomplete="off" class="search-form">
                            <input class="search-bar" type="text" #keyword placeholder={{searchPlaceholder}} name="search">
                            <button class="search-Btn" (click)="searchKeyword(keyword.value, 'uploads')">
                                <em class="fa fa-search fa-small" style="color:#8d8d8d"></em>
                            </button>
                        </form>
                    </div>
                    <div class="file-picker-container">
                        <input id="imageSrc" accept="image/*" type="file" class="file-picker">
                        <!-- {{videoOverlayComponent.videoName == "" ? "No file chosen" : videoOverlayComponent.videoName}} -->
                    </div>
                    <div class="uploads-list">
                        <div class="uploads-card" *ngFor="let image of uploadsMatchedImageSrc;index as i">
                            <div (click)="clicked(image,i)" [style.background]="selectedMediaIndex === i ? '#1e8efb' : '' " class="highlight">
                                <div class="image-style">
                                    <img #imgcheck [src]="image.url" width="100%" for="check" height="100%">
                                </div>
                                <span class="media-label">{{image.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>

<div class="gallery-view-container" *ngIf="mediaType === 'video' || mediaType === 'bgVideo'">
    <mat-tab-group>
        <mat-tab label="Gallery">
            <ng-template matTabContent>
                <div class="gallery-container">
                    <div class="search-container">
                        <form action="#" autocomplete="off" class="search-form">
                            <input class="search-bar" type="text" #keyword placeholder={{searchPlaceholder}} name="search">
                            <button class="search-Btn" (click)="searchKeyword(keyword.value, 'gallery')">
                                <em class="fa fa-search fa-small" style="color:#8d8d8d"></em>
                            </button>
                        </form>
                    </div>
                    <div class="gallery-list">
                        <div class="gallery-card video" *ngFor="let video of matchedVideoSrc;index as i">
                            <div (click)="clicked(video,i,videoElement)" [style.background]="selectedMediaIndex === i ? '#1e8efb' : '' " class="highlight">
                                <div class="video-style">
                                    <video #videoElement [src]="video.url" controls for="check" height="100%" width="100%"></video>
                                </div>
                                <span class="media-label">{{video.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab label="Upload" [disabled]="true">
            <ng-template matTabContent>
                <div class="uploads-container">
                    <div class="search-container">
                        <form action="#" autocomplete="off" class="search-form">
                            <input class="search-bar" type="text" #keyword placeholder={{searchPlaceholder}} name="search">
                            <button class="search-Btn" (click)="searchKeyword(keyword.value, 'uploads')">
                                <em class="fa fa-search fa-small" style="color:#8d8d8d"></em>
                            </button>
                        </form>
                    </div>
                    <div class="file-picker-container">
                        <input id="videoSrc" accept="video/*" type="file" class="file-picker">
                        <!-- {{videoOverlayComponent.videoName == "" ? "No file chosen" : videoOverlayComponent.videoName}} -->
                    </div>
                    <div class="uploads-list">
                        <div class="uploads-card video" *ngFor="let video of uploadsMatchedVideoSrc;index as i">
                            <div (click)="clicked(video,i,videoElement)" [style.background]="selectedMediaIndex === i ? '#1e8efb' : '' " class="highlight">
                                <div class="video-style">
                                    <video #videoElement [src]="video.url" controls for="check" height="100%" width="100%"></video>
                                </div>
                                <span class="media-label">{{video.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>

<div class="gallery-view-container" *ngIf="mediaType === 'audio'">
    <mat-tab-group>
        <mat-tab label="Gallery">
            <ng-template matTabContent>
                <div class="gallery-container">
                    <div class="search-container">
                        <form action="#" autocomplete="off" class="search-form">
                            <input class="search-bar" type="text" #keyword placeholder={{searchPlaceholder}} name="search">
                            <button class="search-Btn" (click)="searchKeyword(keyword.value, 'gallery')">
                                <em class="fa fa-search fa-small" style="color:#8d8d8d"></em>
                            </button>
                        </form>
                    </div>
                    <div class="gallery-list">
                        <div class="gallery-card audio" *ngFor="let audio of matchedAudioSrc;index as i">
                            <div (click)="clicked(audio,i,audioElement)" [style.background]="selectedMediaIndex === i ? '#1e8efb' : '' " class="highlight">
                                <div class="audio-style">
                                    <audio #audioElement [src]="audio.url" controls for="check" height="100%" width="100%"></audio>
                                </div>
                                <span class="media-label">{{audio.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab label="Upload" [disabled]="true">
            <ng-template matTabContent>
                <div class="uploads-container">
                    <div class="search-container">
                        <form action="#" autocomplete="off" class="search-form">
                            <input class="search-bar" type="text" #keyword placeholder={{searchPlaceholder}} name="search">
                            <button class="search-Btn" (click)="searchKeyword(keyword.value, 'uploads')">
                                <em class="fa fa-search fa-small" style="color:#8d8d8d"></em>
                            </button>
                        </form>
                    </div>
                    <div class="file-picker-container">
                        <input id="audioSrc" accept="audio/*" type="file" class="file-picker">
                        <!-- {{videoOverlayComponent.videoName == "" ? "No file chosen" : videoOverlayComponent.videoName}} -->
                    </div>
                    <div class="uploads-list">
                        <div class="uploads-card audio" *ngFor="let audio of uploadsMatchedAudioSrc;index as i">
                            <div (click)="clicked(audio,i,audioElement)" [style.background]="selectedMediaIndex === i ? '#1e8efb' : '' " class="highlight">
                                <div class="audio-style">
                                    <audio #audioElement [src]="audio.url" controls for="check" height="100%" width="100%"></audio>
                                </div>
                                <span class="media-label">{{audio.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>

<div class="gallery-view-container" *ngIf="mediaType === 'file'">
    <mat-tab-group>
        <mat-tab label="Gallery">
            <ng-template matTabContent>
                <div class="gallery-container">
                    <div class="search-container">
                        <form action="#" autocomplete="off" class="search-form">
                            <input class="search-bar" type="text" #keyword placeholder={{searchPlaceholder}} name="search">
                            <button class="search-Btn" (click)="searchKeyword(keyword.value, 'gallery')">
                                <em class="fa fa-search fa-small" style="color:#8d8d8d"></em>
                            </button>
                        </form>
                    </div>
                    <div class="gallery-list">
                        <div class="gallery-card" *ngFor="let doc of matchedDocumentSrc; let i=index;">
                            <div (click)="clicked(doc,i)" [style.background]="selectedMediaIndex === i ? '#1e8efb' : '' " class="highlight">
                                <div class="image-style">

                                    <img #imgcheck [src]="fileImages(doc)" width="100%" for="check" height="100%">

                                </div>
                                <span class="media-label">{{doc.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab label="Upload" [disabled]="true">
            <ng-template matTabContent>
                <div class="uploads-container">
                    <div class="search-container">
                        <form action="#" autocomplete="off" class="search-form">
                            <input class="search-bar" type="text" #keyword placeholder={{searchPlaceholder}} name="search">
                            <button class="search-Btn" (click)="searchKeyword(keyword.value, 'uploads')">
                                <em class="fa fa-search fa-small" style="color:#8d8d8d"></em>
                            </button>
                        </form>
                    </div>
                    <div class="file-picker-container">
                        <input id="docSrc" accept="image/*" type="file" class="file-picker">
                        <!-- {{videoOverlayComponent.videoName == "" ? "No file chosen" : videoOverlayComponent.videoName}} -->
                    </div>
                    <div class="uploads-list">
                        <div class="uploads-card" *ngFor="let doc of uploadsMatchedDocumentSrc;index as i">
                            <div (click)="clicked(doc,i)" [style.background]="selectedMediaIndex === i ? '#1e8efb' : '' " class="highlight">
                                <div class="image-style">
                                    <img #imgcheck [src]="doc.url" width="100%" for="check" height="100%">
                                </div>
                                <span class="media-label">{{doc.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
