import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { StorageService } from 'src/app/shared/services/storage.service';
import { DataService } from 'src/app/shared/services/data.service';
import { PreviewModalComponent } from './preview-modal/preview-modal.component';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';


@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css']
})
export class TemplateComponent implements OnInit {
  index: number;
  constructor(public data: DataService, public analyticsService: AnalyticsService,private router: Router, private dialog: MatDialog, private storageService: StorageService) { }

  allTemplates: any[] = [];
  status = Status;
  openConfirmationModal: boolean = false;
  deleteLoader: boolean = false;
  retryLoader: boolean = true;
  templateId: any

  ngOnInit(): void {
    this.data.isHomePage=false;
    this.data.isTemplatePage=true;
    this.getTemplates();
    // this.test();
  }

  async getTemplates() {
    if(this.data.userId){
    await this.data.fetchAllTemplatesByUserId(this.data.userId).then((result: any) => {
      for (let templates of result) {
        this.allTemplates.push(templates);
      }
      this.allTemplates.reverse();
    })
    }
  }

  deleteConfirmation(templateId:string, index:number) {
    this.templateId = templateId;
    this.index = index
    this.openConfirmationModal = true;
  }

  deleteSelectedTemplate(){
    this.deleteLoader = true;
    this.data.deleteTemplate(this.templateId).then((result)=>{
    this.deleteLoader = false;
      this.allTemplates.splice(this.index,1)
      this.openConfirmationModal = false;
    });
  }


  closeModal() {
    this.openConfirmationModal = false;
  }

  getStatus(status: Status) {
    switch (status) {
      case Status.IN_PROGRESS:
        return 'In-Progress';
        break;
      case Status.SUCCESS:
        return 'Success';
        break;
      case Status.FAILED:
        return 'Failed';
        break;
      case Status.IN_QUEUE:
        return 'In-Queue';
        break;
      default:
        return 'Error';
        break;
    }
  }
  openPreviewModal() {
    let dialogRef = this.dialog.open(PreviewModalComponent, {

    })
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result) {
        // this.dialogRef.close();

      }
    });
  }

  openAnalyticsPage(templateId: string, title: string) {
    this.data.analyticsTemplateId = templateId;
    this.data.analyticsTemplateTitle = title;
    this.router.navigate(['/insights'], {state: {flag: true}});
  } 

  createPsv(templateId: string) {
    this.data.isPreviewLoaded = true;
    this.data.fetchTemplateById(templateId).then((result: any) => {
      this.data.templateObj = result;
      this.openPreviewModal();
    })

  }


  refreshTemplateById(templateId: string, index: number) {
    this.data.fetchTemplateById(templateId).then((result: any) => {
      this.allTemplates[index] = result;
    })
  }

  async retry(templateId: string, index: number){
    this.retryLoader = false;
    await this.data.concatVideos(templateId).then((result) =>{
      this.refreshTemplateById(templateId, index)
    }).catch(err =>{
      this.retryLoader=true;
      this.data.showSnackBar('Something went wrong try again','ok');
    })
  }
}

enum Status {
  IN_PROGRESS = "In progress",
  SUCCESS = "Success",
  FAILED = "Failed",
  IN_QUEUE = "In queue"
}
