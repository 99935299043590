
<form class="form" [formGroup]="inputForm">
  <div class="titledesc">
    <div class="label-video-name">
      <label for="title">Template Name<span class="error-style">*</span>&nbsp;</label>
      <input type="text" placeholder="Enter Title" autocomplete="off" class="inputborder-radius" [(ngModel)]="templateTitle" id="templateTitle" formControlName="templateTitle">
      <div class="error-style" *ngIf="inputForm.get('templateTitle')?.invalid">
        <span style="margin: 36%;" *ngIf="inputForm.get('templateTitle')?.touched && !onSave">Title is Required</span>
        <span  style="margin: 36%;" *ngIf="onSave && inputForm.get('templateTitle') is null">Title is Required</span>
      </div>
    </div>
  </div>
</form>

<div style="margin-top: 11rem;float: right">
  <button type="button" style="margin:1rem" mat-button id="use-btn" class="upload" (click)="save()">
    Save
  </button>
  <button type="button" style="margin:1rem" mat-button id="cancel-btn" class="cancel" (click)="closeDialog()">
    Cancel
  </button>
</div>