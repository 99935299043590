/*******************************************************************************
 * COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
 * Reserved. Infosys believes the information in this document is accurate as of
 * its publication date; such information is subject to change without notice.
 * Infosys acknowledges the proprietary rights of other companies to the
 * trademarks, product names and such other intellectual property rights
 * mentioned in this document. Except as expressly permitted, neither this
 * documentation nor any part of it may be reproduced, stored in a retrieval
 * system, or transmitted in any form or by any means, electronic, mechanical,
 * printing, photocopying, recording or otherwise, without the prior permission
 * of Infosys Limited and/or any named intellectual property rights holders
 * under this document.
 ******************************************************************************/
import { DataService } from 'src/app/shared/services/data.service';
import { Directive, Input, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[seekVideo]'
})
export class SeekVideoDirective implements OnInit{

  constructor(readonly data: DataService, readonly el: ElementRef) { }

  ngOnInit() {

    this.data.currentPlayerTimeObservable.subscribe(
      currentTime => {
        this.el.nativeElement.currentTime = currentTime;
      }
    );

    let pausedDuration:any;

    this.data.videoPlayingObservable.subscribe(
      playingStatus => {
        if(playingStatus){
          if(this.data.isPreviewParticularSegment){
            let timecode;
            timecode = `#t=${this.data.videoPreviewStartDuration},${this.data.videoPreviewEndDuration}`;
            this.el.nativeElement.src = this.data.videoSrc + timecode;
          } else {
            this.el.nativeElement.src = this.data.videoSrc;
            if(pausedDuration){
              this.el.nativeElement.currentTime = pausedDuration;
            }
          }
          const playPromise = this.el.nativeElement.play();
          
          if (playPromise !== undefined) {
            playPromise.then((_:any) => {
            })
            .catch((error:any) => {
              console.log('Error in playing video. Retrying...');
              this.data.updateVideoPlayStatus(true);
            });
          }

        } else {
          pausedDuration = this.el.nativeElement.currentTime;
          this.el.nativeElement.pause();
        }
      }
    );

  }

}
