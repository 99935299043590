/*******************************************************************************
 * COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
 * Reserved. Infosys believes the information in this document is accurate as of
 * its publication date; such information is subject to change without notice.
 * Infosys acknowledges the proprietary rights of other companies to the
 * trademarks, product names and such other intellectual property rights
 * mentioned in this document. Except as expressly permitted, neither this
 * documentation nor any part of it may be reproduced, stored in a retrieval
 * system, or transmitted in any form or by any means, electronic, mechanical,
 * printing, photocopying, recording or otherwise, without the prior permission
 * of Infosys Limited and/or any named intellectual property rights holders
 * under this document.
 ******************************************************************************/
import { CssProperties } from './../models/css-properties.model';
export class LinkAnnotation {
    
    type!: string;
    value!: string;
    email! : string;
    subject! : string;
    body! : string;
    message!: string;
    id: number;
    className!: string;
    start: number;
    end: number;
    cssProperties: CssProperties;
    exitAnimation!: string;
    bgToggle!:boolean;
    bgToggleLink!:boolean;
    seekTo:number | undefined;
    title!:string;
    isDurationChanged:boolean = false;
    duration:any;

    constructor(id:number, currentTime:number, videoDuration: number, cssProps: CssProperties,seekTo?:number){
        this.id = id;
        this.end = videoDuration;
        this.start = currentTime;
        this.duration = this.end - this.start;
        this.cssProperties = cssProps;
        this.seekTo=seekTo;
        this.initializeAttributes();
    }

    initializeAttributes(){
        this.type = 'link';
        this.value = '';
        this.message = 'Your text here...';
        this.className = '';
        this.email = '';
        this.subject = '';
        this.body = ''
        this.bgToggle=false;
        this.bgToggleLink=true;
        this.exitAnimation = "";
        this.title="";
    }

    getAttributes(){
        let attributes: any;
        if(this.type === "link") {
            attributes = {
                type: this.type,
                value: this.value,
                message: this.message,
                id: this.id,
                "class-name": this.className,
                start: this.start,
                end: this.end,
                exitAnimation: this.exitAnimation,
                title: this.title,
                "css-properties": this.cssProperties.getLinkAttributes()
            };
        } else if(this.type === "tel") {
            attributes = {
                type: this.type,
                message: this.message,
                id: this.id,
                "class-name": this.className,
                start: this.start,
                end: this.end,
                exitAnimation: this.exitAnimation,
                title: this.title,
                "css-properties": this.cssProperties.getTelAttributes()
            };
        }
        else if(this.type === "mail"){
            attributes = {
                type: this.type,
                email:this.email,
                body:this.body,
                subject:this.subject,
                message: this.message,
                id: this.id,
                "class-name": this.className,
                start: this.start,
                end: this.end,
                exitAnimation: this.exitAnimation,
                "css-properties": this.cssProperties.getMailAttributes() 
            };
        }else if(this.type === "section") {
            attributes = {
                type: this.type,
                value: this.value,
                message: this.message,
                id: this.id,
                "class-name": this.className,
                start: this.start,
                end: this.end,
                exitAnimation: this.exitAnimation,
                seekTo:this.seekTo,
                "css-properties": this.cssProperties.getLinkAttributes()
            };
        }
        return attributes;
    }

    transformAttributes(overlayObject:any, cssProps: CssProperties){
        const overlayAnnotationObject = new LinkAnnotation(overlayObject.id, overlayObject.start, overlayObject.end, cssProps);
        overlayAnnotationObject.message = overlayObject.message;
        overlayAnnotationObject.start = overlayObject.start;
        overlayAnnotationObject.className = overlayObject["class-name"];
        overlayAnnotationObject.exitAnimation = overlayObject.exitAnimation || "";
        overlayAnnotationObject.title=overlayObject.title;
        if(overlayObject.type === "link"|| overlayObject.type === "mail"){
            overlayAnnotationObject.value = overlayObject.value;
            overlayAnnotationObject.bgToggleLink = overlayAnnotationObject.cssProperties.buttonBgColor!=='transparent'?true:false;
        } else if(overlayObject.type === "tel" ){
            overlayAnnotationObject.type = overlayObject.type;
            overlayAnnotationObject.bgToggle = overlayAnnotationObject.cssProperties.backgroundColor!=='transparent'?true:false;
        }else if(overlayObject.type==="section"){
            overlayAnnotationObject.type = overlayObject.type;
            overlayAnnotationObject.seekTo=overlayObject.seekTo;
            overlayAnnotationObject.bgToggle = overlayAnnotationObject.cssProperties.backgroundColor!=='transparent'?true:false;
        }
        if(overlayAnnotationObject.cssProperties.animation === "" && overlayAnnotationObject.className) {
            overlayAnnotationObject.cssProperties.animation = `${overlayAnnotationObject.className.split(" ")[1]} ${overlayAnnotationObject.cssProperties.animationDuration}`;
            overlayAnnotationObject.className = "";
        }
        return overlayAnnotationObject;
    }
}
