import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css']
})
export class AnalyticsComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
   
  }
  closeDialog(){
    this.dialog.closeAll();
  }
}