import { Component, OnInit } from '@angular/core';
import { DataService } from '../shared/services/data.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PublishUserdataComponent } from '../publish/publish-userdata/publish-userdata.component';


@Component({
  selector: 'app-template-published-links',
  templateUrl: './template-published-links.component.html',
  styleUrls: ['./template-published-links.component.css']
})
export class TemplatePublishedLinksComponent implements OnInit {
  templatePsvLinks: any = [];
  publishModalLoader: boolean = false;
  selectedDate: any;
  startDate: Date;
  endDate: Date;
  filter: boolean = false;
  filteredTemplateLinks: any = []
  noPsv: boolean = false;

  constructor(public data: DataService, public dialog: MatDialog, public dialogRef: MatDialogRef<TemplatePublishedLinksComponent>) { }

  ngOnInit(): void {
    this.data.getPsvLinksForTemplate(this.data.templateObj._id).then((psvLinks: any) => {
      this.publishModalLoader = true;
      for (let linkObj of psvLinks) {
        this.templatePsvLinks.push(linkObj);
      }
    }).catch((err) => {
      this.publishModalLoader = true;
      this.noPsv = true;
    })

  }

  filteredLinks(start: any, end: any) {
    this.filter = true;
    this.startDate = new Date(start);
    this.endDate = new Date(end);
    const filteredLinks = this.templatePsvLinks.filter((links: any) => {
      let dateNew = new Date(links.date)
      if (dateNew >= this.startDate && dateNew <= this.endDate) {
        return links;
      }
    })
    this.filteredTemplateLinks = filteredLinks;
  }

}
