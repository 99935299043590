<div *ngIf="isLoggedIn" class="tools">
    <app-navbar></app-navbar>
</div>
<div *ngIf="isLoggedIn" class="tool-container">
    <app-breadcrumb class="psv-breadcrumb" *ngIf="!data.isTemplatePage && !data.isHomePage && !data.analyticsSelected"></app-breadcrumb>
    <router-outlet></router-outlet>
</div>
<div *ngIf="isAccessDenied" class="access-denied-message">
  Oops!! You're not allowed here!
</div>
