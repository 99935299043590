<table>
  <tr>
    <th>Recent Publishes</th>
  </tr>
  <tr>
    <th>Date</th><th>Time</th>
  </tr>
  <tr>
    <td class="heading" routerLink="/insights" (click)="closeDialog()">03-02-2023 ---------16:36:25</td>
  </tr>
  <tr>
    <td class="heading" routerLink="/insights" (click)="closeDialog()">02-02-2023 ---------12:40:52</td>
  </tr>
  <tr>
    <td class="heading" routerLink="/insights" (click)="closeDialog()">30-01-2023 ---------13:45:59</td>
  </tr>
  <tr>
    <td class="heading" routerLink="/insights" (click)="closeDialog()">29-01-2023 ---------18:30:29</td>
  </tr>
</table>