import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SafeValue } from '@angular/platform-browser';
import { DataService } from 'src/app/shared/services/data.service';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-background-audio',
  templateUrl: './background-audio.component.html',
  styleUrls: ['./background-audio.component.css']
})
export class BackgroundAudioComponent implements OnInit {
  fromLocalfiles: boolean = false;
  fromSamplefiles: boolean = false;
  selectedBGAudio: any;
  selectedMedia: any;
  listOfAudio: any = [];
  audioArray: any = [];
  selectedMediaIndex: any;


  constructor(public data: DataService, public storageService: StorageService, public dialog: MatDialogRef<BackgroundAudioComponent>) { }

  ngOnInit(): void {
    this.storageService.listFiles('audiogallery').then((datas: any) => {
      this.listOfAudio = datas;

      for (let i = 0; i < this.listOfAudio.length; i++) {
        this.audioArray[i] = this.storageService.storageWebServerUrl + 'psv2/audiogallery/' + this.listOfAudio[i];
      }
      this.data.matchedMedia = this.audioArray;
      this.data.matchedNamesOfMedia = this.listOfAudio;
    }
    );
  }


  onSearch(data: any) {

    this.storageService.listFiles('audiogallery').then((datas: any) => {
      this.listOfAudio = datas;

      for (let i = 0; i < this.listOfAudio.length; i++) {
        this.audioArray[i] = this.storageService.storageWebServerUrl + 'psv2/audiogallery/' + this.listOfAudio[i];
      }
      this.data.matchedMedia = this.audioArray;
      this.data.matchedNamesOfMedia = this.listOfAudio;
    }
    );

  }

  radioChecked(check: any, index: any) {
    this.selectedBGAudio = check;
    this.selectedMediaIndex = index;

  }

  onFileUpload(e:any) {
    let blob: Blob;
    const fileReader = new FileReader();
    const arrayBuffer = e.target.files[0];
    const fileType = "audio/mp3";
    blob = new Blob([arrayBuffer], { type: fileType });
    fileReader.onloadend = (e) => {
      const src : any | SafeValue = URL.createObjectURL(arrayBuffer);
      this.selectedBGAudio = src;
      
      // this.audioAnnotation.audioData = this.sanitizer.bypassSecurityTrustUrl(src);      
      // this.demo = true;
      this.dialog.close(this.selectedBGAudio);
      // this.loadElement();
    };
    fileReader.readAsArrayBuffer(blob);
  }

  loadBgAudio() {

    this.dialog.close(this.selectedBGAudio);
    
    // this.data.overlayAnnotations(); 
  }

  closeDialog() {
    this.dialog.close();
  }
}

