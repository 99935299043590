import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-userdata',
  templateUrl: './userdata.component.html',
  styleUrls: ['./userdata.component.css']
})
export class UserdataComponent implements OnInit {
  constructor(readonly data: DataService) { }
  temp!: any;
  listofelements: any;
  listofinputs: any = {};
  scriptlines: any;
  listofLabels: any = [];
  @Output() cancel = new EventEmitter<void>()

  ngOnInit(): void {
    this.temp = this.data.fileContent;
    this.listofelements = this.temp.match(/{(.*?)}/gm);
    let size = this.listofelements.length
    for (let i = 0; i < size; i++) {
      this.listofLabels.push(this.listofelements[i].substring(1, this.listofelements[i].length - 1));
    }
  }

  cancelpopup() {
    this.cancel.emit();
  }

  updatedata(val: string, id: string) {
    if (id in this.listofinputs) {
      this.listofinputs[id] = val;
    }
    else {
      this.listofinputs[id] = val;
    }
    this.replaceInputs(this.listofinputs);
    this.data.notifyOnUpdateData("userdata_updated");
  }

  replaceInputs(listofinputs: any) {
    let t: any = this.temp;
    for (let key in listofinputs) {
      if (listofinputs[key]) {
        t = t.replaceAll("{" + key + "}", listofinputs[key]);
      }
    }
    this.scriptlines = t;
    this.data.scriptlines = this.scriptlines;
  }

}
