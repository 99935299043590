import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { DataService } from 'src/app/shared/services/data.service';
import { TextAnnotation } from './../../shared/models/text-annotation.model';
import { CssProperties } from './../../shared/models/css-properties.model';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Subscription } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { getMatTooltipInvalidPositionError } from '@angular/material/tooltip';
import { CaptionComponent } from '../../../../projects/caption/caption.component';
import { ToolbarComponent } from '../toolbar/toolbar.component';

@Component({
  selector: 'timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css'],
  providers: [CaptionComponent, ToolbarComponent]
})
export class Psv2TimelineComponent implements OnInit {

  timeline:any = false;
  currentTime: any = 2;
  timelineContainerWidth: any;
  reloadAnnotationTable: boolean = true;
  less1900!: boolean;
  newOverlayObject: any;
  annotationHandlers: any;
  caption_annotationHandlers: any;
  gt1900!: boolean;
  scaleUnitsArray!: any[];
  displayedColumns = ['icon', 'value'];
  dataSource = this.data.annotationTableData;
  inBound!: boolean;
  timeScaleControllerMinWidth!: any;
  lineGroupArray: any;
  translateXPostResize = 0;
  @ViewChild('scrollablePane', { static: false }) scrollablePane!: ElementRef;
  @ViewChild('tableContainer', {static: false}) tableContainer!: ElementRef;
  @ViewChild('annotationPane', {static: false}) annotationPane!: ElementRef;
  @ViewChild('captionPane', {static: false}) captionPane!: ElementRef;
  initalizedScrollablePane: any = null
  initalizedAnnotationPane: any = null
  initalizedCaptionPane: any = null
  initializedTableContainer: any = null
  @ViewChild('tableRef', { static: false }) tableReference!: any;
  @ViewChild('timeScaleController', { static: false }) timeScaleController!: any;
  selectedSceneIndex: any;
  selectedScene: any;
  annotationSwitchNotifySubscription!: Subscription;
  notifyAnnotationUpdateSubscription!: Subscription;
  notifyDurationChangeInToolbarSubscription!: Subscription;
  notifyAnnotationToggleSubscription!: Subscription;
  

  constructor(public cap: CaptionComponent, public tool: ToolbarComponent, readonly data:DataService, private storageService: StorageService) { }

  ngOnInit() {
    this.inBound = true
    this.data.notifyVideoMetaDataLoaded(true);
    this.data.notifyOnVideoMetaDataLoadedObservable.subscribe(
      (isMetaLoaded) => {
        if (isMetaLoaded) {
          // this.lineGroupArray = new Array(Math.ceil(this.data.videoDuration) * 10);
          this.scaleUnitsArray = this.calculateScaleUnits();
          for (const overlayObject of this.data.overlayJSON.overlays) {
            const annotationTableId = this.data.getAnnotationTableId(overlayObject);
            if (this.data.annotationTableData[annotationTableId]) {
              this.data.annotationTableData[annotationTableId].durationWidth = ((overlayObject.end - overlayObject.start) / this.data.videoDuration) * 100;
              this.data.annotationTableData[annotationTableId].startPoint = (overlayObject.start / this.data.videoDuration) * 100 * this.data.zoomRatio;
              if (overlayObject.isDurationChanged) {
                this.data.annotationTableData[annotationTableId].startPx = 0;
              }
              else {
                this.data.annotationTableData[annotationTableId].startPx = (overlayObject.start / this.data.videoDuration) * this.timelineContainerWidth * this.data.zoomRatio;
              }
            }
          }
          for(const overlayObject of this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData){
            const caption_annotationTableId = this.data.getCaptionTableId(overlayObject);
            if (this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[caption_annotationTableId]) {
              this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[caption_annotationTableId].durationWidth = ((overlayObject.end - overlayObject.start) / this.data.videoDuration) * 100;
              this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[caption_annotationTableId].startPoint = (overlayObject.start / this.data.videoDuration) * 100 * this.data.zoomRatio;
              if (overlayObject.isDurationChanged) {
                this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[caption_annotationTableId].startPx = 0;
              }
              else {
                this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[caption_annotationTableId].startPx = (overlayObject.start / this.data.videoDuration) * this.timelineContainerWidth * this.data.zoomRatio;
              }
            }
          }
          for (const overlayObject of this.data.overlayJSON.audios) {
            const annotationTableId = this.data.getAnnotationTableId(overlayObject);
            if (this.data.annotationTableData[annotationTableId]) {
              this.data.annotationTableData[annotationTableId].durationWidth = ((overlayObject.end - overlayObject.start) / this.data.videoDuration) * 100;
              this.data.annotationTableData[annotationTableId].startPoint = (overlayObject.start / this.data.videoDuration) * 100 * this.data.zoomRatio;
              if (overlayObject.isDurationChanged) {
                this.data.annotationTableData[annotationTableId].startPx = 0;
              }
              else {
                this.data.annotationTableData[annotationTableId].startPx = (overlayObject.start / this.data.videoDuration) * this.timelineContainerWidth * this.data.zoomRatio;
              }
            }
          }
        }
      }
    )
    this.annotationSwitchNotifySubscription = this.data.annotationSwitchNotifyObservable.subscribe(data => {
      if (data === "notified") {
        if(this.data.annotationType != 'caption'){
        this.changeAnnotationDuration();
        }
        else{
          this.changeCaptionAnnotationDuration();
        }
      }
    });
  }




  ngAfterViewInit() {
    this.notifyAnnotationUpdateSubscription = this.data.notifyAnnotationUpdateObservable.subscribe(
      currentTime => {
        this.tableReference.renderRows();
      }
    );

    this.notifyDurationChangeInToolbarSubscription = this.data.notifyDurationChangeInToolbarObservable.subscribe(
      message => {
        if (message === 'changed') {
          if(this.data.annotationType == 'caption'){
          this.onCaptionDurationChangeInToolbar();
          }
          else{
            this.onDurationChangeInToolbar()
          }
        }
      }
    );

    this.notifyAnnotationToggleSubscription = this.data.notifyAnnotationToggleObservable.subscribe(
      index =>{
        if(index != -1){
          if(this.data.annotationType != 'caption'){ 
            let element = this.data.overlayJSON.overlays[index - 1];
            this.toggleAnnotation(element);
          }
          else{
            let element = this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[index - 1];
            this.toggleCaptionAnnotation(element);
          }
          
        }
      }
    )

    setTimeout(() => {
      this.initalizedScrollablePane = this.scrollablePane
      this.initializedTableContainer = this.tableContainer
      this.initalizedAnnotationPane = this.annotationPane
      this.initalizedCaptionPane = this.captionPane
    }, 0);

    this.timeScaleController.nativeElement.style.transform = 'translate(0px,0px)';
    this.annotationHandlers = document.getElementsByClassName('annotation-duration') as HTMLCollectionOf<HTMLDivElement>;
    this.caption_annotationHandlers = document.getElementsByClassName('caption_annotation-duration') as HTMLCollectionOf<HTMLDivElement>;
  }

  ngOnDestroy(){
    this.annotationSwitchNotifySubscription.unsubscribe();
    this.notifyAnnotationUpdateSubscription.unsubscribe();
    this.notifyDurationChangeInToolbarSubscription.unsubscribe();
    this.notifyAnnotationToggleSubscription.unsubscribe();
  }



  onHandlerResizeEnd($event: any, handlerElement: any) {
    const existingTranslateXValue = new WebKitCSSMatrix(window.getComputedStyle(handlerElement).transform).m41;
    const translateLeftValue = $event.edges.left;
    const totalTranslateX = existingTranslateXValue + (translateLeftValue ? translateLeftValue : 0);
    this.translateXPostResize = totalTranslateX < 0 ? 0 : totalTranslateX;
    if (!!translateLeftValue) {
      handlerElement.style.transform = `translate(${totalTranslateX < 0 ? 0 : totalTranslateX}px, 0px)`;
    }
    handlerElement.style.maxWidth = `${this.calculateMaxWidth(totalTranslateX)}px`;
    if ($event.rectangle.width >= this.calculateMaxWidth(totalTranslateX)) {
      handlerElement.style.width = `${this.calculateMaxWidth(totalTranslateX)}px`;
    } else {
      handlerElement.style.width = `${$event.rectangle.width}px`;
    }
  }

  onDraggingHandler($event: any, currentTimeHandlerEle: any, handlerElement: any, timeScaleElement: any, lineGroup: any, annotationsArray: any) {
    const existingTranslateXValue = new WebKitCSSMatrix(window.getComputedStyle(handlerElement).transform).m41;
    this.translateXPostResize = existingTranslateXValue;
    if (this.translateXPostResize > 0) {
      handlerElement.style.transform = `translate(${this.translateXPostResize}px, 0px) !important`;
      this.translateXPostResize = 0;
    }
    const leftPercentage = (existingTranslateXValue) / (this.timelineContainerWidth - parseInt(handlerElement.style.width.slice(0, -2)));
    timeScaleElement.style.left = `-${leftPercentage * ((timeScaleElement.style.width.slice(0, -1)) - 100)}%`;
    currentTimeHandlerEle.style.left = `-${leftPercentage * ((timeScaleElement.style.width.slice(0, -1)) - 100)}%`;
    lineGroup.style.left = `-${leftPercentage * ((lineGroup.style.width.slice(0, -1)) - 100)}%`;
    for (const annotation of annotationsArray.children) {
      annotation.style.left = `-${leftPercentage * ((annotation.style.width.slice(0, -1)) - 100)}%`;
    }
  }

  calculateMaxWidth(translateX: any) {
    if (translateX > 0) {
      return this.timelineContainerWidth - translateX;
    }
    return this.timelineContainerWidth;
  }


  onTimelineResize(width: any, annotationsArray: any) {
    this.less1900 = window.outerWidth < 1900;
    this.gt1900 = !this.less1900;
    this.timelineContainerWidth = width * 60344 / 61675 // multiplying by ratio of width of timescale to container;
    if(this.data.annotationType != 'caption')
    {
      this.onZoomRatioChange(annotationsArray.nativeElement);
    }
    else{
      this.onCaptionZoomRatioChange(annotationsArray.nativeElement);
    }
  }

  calculateCurrentTime(currentTimeHandlerEle: any, timeScaleControllerEle: any, timeScaleContainer: any) {
    const currentTimeHandlerLeftinpx = new WebKitCSSMatrix(window.getComputedStyle(currentTimeHandlerEle).transform).m41;
    const timeScaleControllerEleLeft = new WebKitCSSMatrix(window.getComputedStyle(timeScaleControllerEle).transform).m41;
    const timeScaleControllerEleWidth = timeScaleControllerEle.style.width;
    let ratio = 1 / this.data.zoomRatio;
    /*if (timeScaleControllerEleWidth) {
      ratio = parseInt(timeScaleControllerEle.style.width.slice(0, -2)) / this.timelineContainerWidth;
    } else {
      ratio = 1;
    }*/
    //const remainingScrollSpace = this.timelineContainerWidth - parseInt(timeScaleControllerEle.style.width.slice(0, -2));
    //const offsetScrollSpace = remainingScrollSpace - (remainingScrollSpace - timeScaleControllerEleLeft);
    //const totalOffsetTime = (remainingScrollSpace / this.timelineContainerWidth) * this.data.videoDuration;
    //const ratioOfScrollSpace = offsetScrollSpace / remainingScrollSpace;
    //const offsetTime = ratioOfScrollSpace * totalOffsetTime;
    const currentTime = ((currentTimeHandlerLeftinpx) * (this.data.videoDuration * ratio) / this.timelineContainerWidth);
    this.data.updateCurrentTime(currentTime);
  }

  changeAnnotationDuration() {
    if (this.data.selectedAnnotation) {
      let startTime;
      let endTime;
      let translateX;
      if (this.data.selectedAnnotation.type === "tts") {
        startTime = this.data.overlayJSON.audios[this.data.selectedAnnotation.id - 1].start;
        endTime = this.data.overlayJSON.audios[this.data.selectedAnnotation.id - 1].end;
      } else {
        startTime = this.data.overlayJSON.overlays[this.data.selectedAnnotation.id - 1].start;
        endTime = this.data.overlayJSON.overlays[this.data.selectedAnnotation.id - 1].end;
      }
      const annotationTableId = this.data.getAnnotationTableId(this.data.selectedAnnotation);
      this.data.annotationTableData[annotationTableId].durationWidth = ((endTime - startTime) / this.data.videoDuration) * 100;
      this.data.annotationTableData[annotationTableId].startPoint = (startTime / this.data.videoDuration) * 100 * this.data.zoomRatio;
      if (this.data.selectedAnnotation.type === "tts") {
        if (!this.data.overlayJSON.audios[this.data.selectedAnnotation.id - 1].isDurationChanged) {
          this.data.annotationTableData[annotationTableId].startPx = (startTime / this.data.videoDuration) * this.timelineContainerWidth * this.data.zoomRatio;
        }
        else {
          this.data.annotationTableData[annotationTableId].startPx = 0;
        }
      }
      else {
        if (!this.data.overlayJSON.overlays[this.data.selectedAnnotation.id - 1].isDurationChanged) {
          this.data.annotationTableData[annotationTableId].startPx = (startTime / this.data.videoDuration) * this.timelineContainerWidth * this.data.zoomRatio;
        }
        else {
          this.data.annotationTableData[annotationTableId].startPx = 0;
        }
      }


    }
  }

  changeCaptionAnnotationDuration(){
    if (this.data.selectedAnnotation) {
      let startTime;
      let endTime;
      let translateX;
      const annotationTableId = this.data.getCaptionTableId(this.data.selectedAnnotation);
      startTime = this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[annotationTableId].start;
      endTime = this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[annotationTableId].end;
      this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[annotationTableId].durationWidth = ((endTime - startTime) / this.data.videoDuration) * 100;
      this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[annotationTableId].startPoint = (startTime / this.data.videoDuration) * 100 * this.data.zoomRatio;
        if (!this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[annotationTableId].isDurationChanged) {
          this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[annotationTableId].startPx = (startTime / this.data.videoDuration) * this.timelineContainerWidth * this.data.zoomRatio;
        }
        else {
          this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[annotationTableId].startPx = 0;
          this.data.selectedAnnotation = this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[annotationTableId];
          this.onCaptionDurationChangeInToolbar()
        }
      }
    }

  calculateScaleUnits() {
    const scaleUnitsArray = [];
    for (let i = 0; i <= this.data.videoDuration; i++) {
      scaleUnitsArray.push(`${i}s`);
    }
    return scaleUnitsArray;
  }

  onResizingHandler($event: any, timeScaleElement: any, lineGroup: any, annotationsArray: any) {
    let handlerWidth;
    if ($event.rectangle.width > this.timelineContainerWidth) {
      handlerWidth = this.timelineContainerWidth;
    } else if ($event.rectangle.width < this.timeScaleControllerMinWidth) {
      handlerWidth = this.timeScaleControllerMinWidth
    } else {
      handlerWidth = $event.rectangle.width;
    }
    const ghostElement: any = document.querySelectorAll('.time-scale-controller.resize-ghost-element')[0];
    ghostElement.style.width = `${handlerWidth}px`;
    const scaledWidthinPercentage = (this.timelineContainerWidth / handlerWidth) * 100;
    timeScaleElement.style.width = `${scaledWidthinPercentage}%`;
    lineGroup.style.width = `${scaledWidthinPercentage}%`;
    for (const annotation of annotationsArray.children) {
      annotation.style.width = `${scaledWidthinPercentage}%`;
    }
  }
  //tobe removed after merging
  initializeDummyOverlayObject() {
    const startTime = this.data.currentTime === undefined || this.data.currentTime === 0 ? 0 : this.data.currentTime;
    this.newOverlayObject = {};
    this.data.selectedAnnotation = {};
    const id = this.data.overlayJSON.overlays.length + 1;
    const cssProps = new CssProperties();
    const textAnnotation = new TextAnnotation(id, startTime, this.data.videoDuration, cssProps);
    this.newOverlayObject = textAnnotation;
    this.data.setAnnotationTableData(this.newOverlayObject);
    this.data.overlayJSON.overlays.push(this.newOverlayObject);
    this.data.selectedAnnotation = this.newOverlayObject;
    this.data.notifyAnnotationSwitch("notified");
    this.data.updateCurrentTime(this.data.overlayJSON.overlays[id - 1].start);

  }
  toggleAnnotation(element: any) {
    if (element.type === "tts") {
      this.data.annotationType = element.type;
      this.data.selectedAnnotation = this.data.overlayJSON.audios[element.id - 1];
      // this.data.selectedDatasourceId = (this.data.annotationTableData.length - parseInt(this.data.getAnnotationTableId(this.data.selectedAnnotation)) - 1);
      this.data.selectedDatasourceId = this.data.getAnnotationTableId(this.data.selectedAnnotation);
      this.data.notifyAnnotationSwitch("notified");
      this.data.updateCurrentTime(this.data.overlayJSON.audios[element.id - 1].start);
      this.data.isToolBarOpen = true;
      this.data.activeTool = 'tts';
    } else {
      this.data.annotationType = element.type;
      this.data.selectedAnnotation = this.data.overlayJSON.overlays[element.id - 1];
      this.data.aspectRatio = parseInt(this.data.selectedAnnotation.cssProperties.width.substring(-2))/ parseInt(this.data.selectedAnnotation.cssProperties.height.substring(-2));

      // this.data.selectedDatasourceId = (this.data.annotationTableData.length - parseInt(this.data.getAnnotationTableId(this.data.selectedAnnotation)) - 1);
      this.data.selectedDatasourceId = this.data.getAnnotationTableId(this.data.selectedAnnotation);
      this.data.notifyAnnotationSwitch("notified");
      this.data.updateCurrentTime(this.data.overlayJSON.overlays[element.id - 1].start);
      this.data.isToolBarOpen = true;
      this.data.activeTool = element.type;
    }
  }

  toggleCaptionAnnotation(element: any) {
      this.data.annotationType = element.type;
      this.data.selectedAnnotation = this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[element.id -1];
      this.data.aspectRatio = parseInt(this.data.selectedAnnotation.cssProperties.width.substring(-2))/ parseInt(this.data.selectedAnnotation.cssProperties.height.substring(-2));

      // this.data.selectedDatasourceId = (this.data.annotationTableData.length - parseInt(this.data.getAnnotationTableId(this.data.selectedAnnotation)) - 1);
      this.data.selectedDatasourceId = this.data.getCaptionTableId(this.data.selectedAnnotation);
      this.data.notifyAnnotationSwitch("notified");
      this.data.updateCurrentTime(this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[element.id -1].start);
      this.data.isToolBarOpen = true;
      this.data.activeTool = element.type;
      this.data.setDraggerPosition();
  }

  onDraggingAnnotationHandlerEnd($event: any, i: number, annotationTimelineEle: any) {
    // annotationTimelineEle.style.left = '0';
    // const annotationTableId = this.data.getAnnotationTableId(this.data.overlayJSON.overlays[i]);
    this.data.stateChanged = true;
    const startPx = this.data.annotationTableData[i].startPx;
    this.data.annotationTableData[i].startPx = 0;
    let id = this.data.annotationTableData[i].id - 1;
    let type = this.data.annotationTableData[i].type;
    const annotationHandlerEleLeft = new WebKitCSSMatrix(window.getComputedStyle(annotationTimelineEle).transform).m41;
    let translateX;
    let ratio = 1 / this.data.zoomRatio;
    let newStart = ((annotationHandlerEleLeft) * (this.data.videoDuration * ratio) / this.timelineContainerWidth);
    if (newStart <= 0) {
      newStart = 0.001;
    }
    if (type === "tts") {
      this.data.overlayJSON.audios[id].isDurationChanged = true;
      this.data.overlayJSON.audios[id].start = newStart;
      this.data.overlayJSON.audios[id].end = newStart + this.data.overlayJSON.audios[id].duration;
      translateX = (this.data.overlayJSON.audios[id].start / this.data.videoDuration) * this.timelineContainerWidth * this.data.zoomRatio;
    }
    else {
      this.data.overlayJSON.overlays[id].isDurationChanged = true;
      this.data.overlayJSON.overlays[id].start = parseFloat(newStart.toFixed(3));
      this.data.overlayJSON.overlays[id].end = newStart + this.data.overlayJSON.overlays[id].duration;
      translateX = (this.data.overlayJSON.overlays[id].start / this.data.videoDuration) * this.timelineContainerWidth * this.data.zoomRatio;
    }
    annotationTimelineEle.style.transform = `translate(${translateX}px,0px)`;
    this.data.loadElement(id, i);
  }

  onDraggingCaptionAnnotationHandlerEnd($event: any, i: number, caption_annotationTimelineEle: any){
    // annotationTimelineEle.style.left = '0';
    // const annotationTableId = this.data.getAnnotationTableId(this.data.overlayJSON.overlays[i]);
    this.data.stateChanged = true;
    const startPx = this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].startPx;
    this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].startPx = 0;
    let id = this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].id - 1;
    let type = this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].type;
    const caption_annotationHandlerEleLeft = new WebKitCSSMatrix(window.getComputedStyle(caption_annotationTimelineEle).transform).m41;
    let translateX;
    let ratio = 1 / this.data.zoomRatio;
    let newStart = ((caption_annotationHandlerEleLeft) * (this.data.videoDuration * ratio) / this.timelineContainerWidth);
    if (newStart <= 0) {
      newStart = 0.001;
    }
    this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[id].isDurationChanged = true;
    this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[id].start = parseFloat(newStart.toFixed(3));
    this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[id].end = newStart + this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[id].duration;
    this.cap.changeTimelineStartTime(this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[id].start, this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[id].end, id)
      translateX = (this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[id].start / this.data.videoDuration) * this.timelineContainerWidth * this.data.zoomRatio;
    caption_annotationTimelineEle.style.transform = `translate(${translateX}px,0px)`;
    // this.data.loadElement(id, i);
    this.data.setDraggerPosition();
  }

  onAnnotationHandlerResizeEnd($event: any, annotationsArray: any, i: number, timeScaleControllerEle: any, annotationTimelineEle: any) {
    // const annotationTableId = this.data.getAnnotationTableId(this.data.overlayJSON.overlays[i]);
    this.data.stateChanged = true;
    const startPx = this.data.annotationTableData[i].startPx;
    this.data.annotationTableData[i].startPx = 0;
    let type = this.data.annotationTableData[i].type;
    let id = this.data.annotationTableData[i].id - 1;
    const annotationHandlerEleLeft = new WebKitCSSMatrix(window.getComputedStyle(annotationTimelineEle).transform).m41;
    if (type === "tts") {
      this.data.overlayJSON.audios[id].isDurationChanged = true;
    }
    else {
      this.data.overlayJSON.overlays[id].isDurationChanged = true;
    }
    const timeScaleControllerEleLeft = new WebKitCSSMatrix(window.getComputedStyle(timeScaleControllerEle).transform).m41;
    const timeScaleControllerEleWidth = timeScaleControllerEle.style.width;
    let ratio = 1 / this.data.zoomRatio;
    /*if (timeScaleControllerEleWidth) {
      ratio = parseInt(timeScaleControllerEle.style.width.slice(0, -2)) / this.timelineContainerWidth;
    } else {
      ratio = 1;
    }*/
    //const remainingScrollSpace = this.timelineContainerWidth - parseInt(timeScaleControllerEle.style.width.slice(0, -2));
    //const offsetScrollSpace = remainingScrollSpace - (remainingScrollSpace - timeScaleControllerEleLeft);
    //const totalOffsetTime = (remainingScrollSpace / this.timelineContainerWidth) * this.data.videoDuration;
    //const ratioOfScrollSpace = offsetScrollSpace / remainingScrollSpace;
    //const offsetTime = ratioOfScrollSpace * totalOffsetTime;
    const annotationTimelineEleWidth = $event.size.width;
    const newDuration = (this.data.videoDuration / this.timelineContainerWidth) * annotationTimelineEleWidth * ratio;
    annotationTimelineEle.style.width = `${annotationTimelineEleWidth * 100 * ratio / this.timelineContainerWidth}%`;
    //annotationTimelineEle.style.position = 'relative';
    let newStart = ((annotationHandlerEleLeft + $event.position.left) * (this.data.videoDuration * ratio) / this.timelineContainerWidth);
    if (newStart <= 0) {
      newStart = 0.001;
    }
    if (type === "tts") {
      this.data.overlayJSON.audios[id].start = newStart;
      this.data.overlayJSON.audios[id].duration = newDuration;
      this.data.overlayJSON.audios[id].end = newStart + newDuration;
    }
    else {
      this.data.overlayJSON.overlays[id].start = parseFloat(newStart.toFixed(3));
      this.data.overlayJSON.overlays[id].duration = newDuration;
      this.data.overlayJSON.overlays[id].end = newStart + newDuration;
    }
    let translateX = (newStart / this.data.videoDuration) * this.timelineContainerWidth * this.data.zoomRatio;
    annotationTimelineEle.style.transform = `translate(${translateX}px,0px)`;
    annotationTimelineEle.style.left = '0';
    this.data.loadElement(id, i);
  }

  onCaptionAnnotationHandlerResizeEnd($event: any, annotationsArray: any, i: number, timeScaleControllerEle: any, caption_annotationTimelineEle: any) {
    this.data.stateChanged = true;
    const startPx = this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].startPx;
    this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].startPx = 0;
    let type = this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].type;
    let id = this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].id - 1;
    const caption_annotationHandlerEleLeft = new WebKitCSSMatrix(window.getComputedStyle(caption_annotationTimelineEle).transform).m41;
    this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[id].isDurationChanged = true;
    const timeScaleControllerEleLeft = new WebKitCSSMatrix(window.getComputedStyle(timeScaleControllerEle).transform).m41;
    const timeScaleControllerEleWidth = timeScaleControllerEle.style.width;
    let ratio = 1 / this.data.zoomRatio;
    const annotationTimelineEleWidth = $event.size.width;
    const newDuration = (this.data.videoDuration / this.timelineContainerWidth) * annotationTimelineEleWidth * ratio;
    caption_annotationTimelineEle.style.width = `${annotationTimelineEleWidth * 100 * ratio / this.timelineContainerWidth}%`;
    let newStart = ((caption_annotationHandlerEleLeft + $event.position.left) * (this.data.videoDuration * ratio) / this.timelineContainerWidth);
    if (newStart <= 0) {
      newStart = 0.001;
    }
    this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[id].start = parseFloat(newStart.toFixed(3));
    this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[id].duration = newDuration;
    this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[id].end = newStart + newDuration;
      this.cap.changeTimelineStartTime(this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[id].start, this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[id].end, id)
    let translateX = (newStart / this.data.videoDuration) * this.timelineContainerWidth * this.data.zoomRatio;
    caption_annotationTimelineEle.style.transform = `translate(${translateX}px,0px)`;
    caption_annotationTimelineEle.style.left = '0';
    // this.data.loadElement(id, i);
    this.data.setDraggerPosition();
  }


  onAnnotationHandlerResizing($event: any, annotation: any, i: number, timeScaleControllerEle: any, annotationTimelineEle: any) {
    // const annotationTableId = this.data.getAnnotationTableId(this.data.overlayJSON.overlays[i]);
    const startPx = this.data.annotationTableData[i].startPx;
    const annotationHandlerEleLeft = new WebKitCSSMatrix(window.getComputedStyle(annotationTimelineEle).transform).m41 + startPx;

  }

  onCaptionAnnotationHandlerResizing($event: any, annotation: any, i: number, timeScaleControllerEle: any, caption_annotationTimelineEle: any) {
    // const annotationTableId = this.data.getAnnotationTableId(this.data.overlayJSON.overlays[i]);
    const startPx = this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].startPx;
    const caption_annotationHandlerEleLeft = new WebKitCSSMatrix(window.getComputedStyle(caption_annotationTimelineEle).transform).m41 + startPx;

  }

  onDraggingAnnotationHandler($event: any, annotation: any, i: number, timeScaleControllerEle: any, annotationTimelineEle: any) {
    // const annotationTableId = this.data.getAnnotationTableId(this.data.overlayJSON.overlays[i]);
    const startPx = this.data.annotationTableData[i].startPx;
    let id = this.data.annotationTableData[i].id - 1;
    let type = this.data.annotationTableData[i].type;
    const annotationHandlerEleLeft = new WebKitCSSMatrix(window.getComputedStyle(annotationTimelineEle).transform).m41 + startPx;
    if (type === "tts") {
      this.data.overlayJSON.audios[id].isDurationChanged = true;
    }
    else {
      this.data.overlayJSON.overlays[id].isDurationChanged = true;
    }
    // const timeScaleControllerEleLeft = new WebKitCSSMatrix(window.getComputedStyle(timeScaleControllerEle).transform).m41;
    // const timeScaleControllerEleWidth = timeScaleControllerEle.style.width;
    let ratio = 1 / this.data.zoomRatio;
    const newStart = ((annotationHandlerEleLeft) * (this.data.videoDuration * ratio) / this.timelineContainerWidth);
    if (type === "tts") {
      this.data.overlayJSON.audios[id].start = newStart;
      this.data.overlayJSON.audios[id].end = newStart + this.data.overlayJSON.audios[id].duration;
    }
    else {
      this.data.overlayJSON.overlays[id].start = parseFloat(newStart.toFixed(3));
      this.data.overlayJSON.overlays[id].end = newStart + this.data.overlayJSON.overlays[id].duration;
    }
    //this.data.updateCurrentTime(newStart);
  }

  onDraggingCaptionAnnotationHandler($event: any, annotation: any, i: number, timeScaleControllerEle: any, caption_annotationTimelineEle: any) {
    const startPx = this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].startPx;
    let id = this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].id - 1;
    let type = this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].type;
    const caption_annotationHandlerEleLeft = new WebKitCSSMatrix(window.getComputedStyle(caption_annotationTimelineEle).transform).m41 + startPx;
    this.data.overlayJSON.overlays[id].isDurationChanged = true;
    let ratio = 1 / this.data.zoomRatio;
    const newStart = ((caption_annotationHandlerEleLeft) * (this.data.videoDuration * ratio) / this.timelineContainerWidth);
    if (type === "tts") {
      this.data.overlayJSON.audios[id].start = newStart;
      this.data.overlayJSON.audios[id].end = newStart + this.data.overlayJSON.audios[id].duration;
    }
    else {
      this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].start = parseFloat(newStart.toFixed(3));
      this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[i].end = newStart + this.data.overlayJSON.overlays[id].duration;
    }
  }

  onDurationChangeInToolbar() {
    this.data.stateChanged = true;
    let translateX = (this.data.selectedAnnotation.start / this.data.videoDuration) * this.timelineContainerWidth * this.data.zoomRatio;
    let id = this.data.annotationTableData.length - parseInt(this.data.getAnnotationTableId(this.data.selectedAnnotation)) - 1;
    // let id = this.data.getAnnotationTableId(this.data.selectedAnnotation);
    this.annotationHandlers[id].style.transform = `translate(${translateX}px,0px)`;
    this.data.overlayAnnotations();
    this.data.setDraggerPosition();
  }

  onCaptionDurationChangeInToolbar(){
    this.data.stateChanged = true;
    let translateX = (this.data.selectedAnnotation.start / this.data.videoDuration) * this.timelineContainerWidth * this.data.zoomRatio;
    let id = this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.length - parseInt(this.data.getCaptionTableId(this.data.selectedAnnotation)) - 1;
    this.caption_annotationHandlers[id].style.transform = `translate(${translateX}px,0px)`;
    this.data.setDraggerPosition();
  }

  zoomIn(timeScaleElement: any, currentTimeHandlerEle: any, timeScaleControllerEle: any, annotationsArray: any) {
    this.data.zoomRatio += .25;
    const scaledWidthinPercentage = this.data.zoomRatio * 100;
    timeScaleElement.style.width = `${scaledWidthinPercentage}%`;
    //lineGroup.style.width = `${scaledWidthinPercentage}%`;
    timeScaleControllerEle.style.width = `${this.timelineContainerWidth / this.data.zoomRatio}px`;
    const newTimeScaleControllerEleLeft = new WebKitCSSMatrix(window.getComputedStyle(timeScaleControllerEle).transform).m41;
    const leftPercentage = (newTimeScaleControllerEleLeft) / (this.timelineContainerWidth - parseInt(timeScaleControllerEle.style.width.slice(0, -2)));
    timeScaleElement.style.left = `-${leftPercentage * ((timeScaleElement.style.width.slice(0, -1)) - 100)}%`;
    currentTimeHandlerEle.style.left = `-${leftPercentage * ((timeScaleElement.style.width.slice(0, -1)) - 100)}%`;
    for (const annotation of annotationsArray.children) {
      annotation.style.width = `${scaledWidthinPercentage}%`;
      annotation.style.left = `-${leftPercentage * ((annotation.style.width.slice(0, -1)) - 100)}%`;
    }
  }

  zoomOut(timeScaleElement: any, currentTimeHandlerEle: any, timeScaleControllerEle: any, annotationsArray: any) {
    const timeScaleControllerEleLeft = new WebKitCSSMatrix(window.getComputedStyle(timeScaleControllerEle).transform).m41;
    if (this.data.zoomRatio > 1) {
      this.data.zoomRatio -= .25;
    }
    const scaledWidthinPercentage = this.data.zoomRatio * 100;
    timeScaleElement.style.width = `${scaledWidthinPercentage}%`;
    //lineGroup.style.width = `${scaledWidthinPercentage}%`;
    const newTimeScaleControllerWidth = this.timelineContainerWidth / this.data.zoomRatio;
    timeScaleControllerEle.style.width = `${newTimeScaleControllerWidth}px`;
    const maxTranslateX = this.calculateMaxTranslateX(newTimeScaleControllerWidth);
    if (timeScaleControllerEleLeft > maxTranslateX) {
      timeScaleControllerEle.style.transform = `translate(${maxTranslateX}px,0px)`;
    }
    if (this.data.zoomRatio == 1) {
      timeScaleControllerEle.style.transform = 'translate(0px,0px)';
    }
    const newTimeScaleControllerEleLeft = new WebKitCSSMatrix(window.getComputedStyle(timeScaleControllerEle).transform).m41;
    const leftPercentage = (newTimeScaleControllerEleLeft) / (this.timelineContainerWidth - parseInt(timeScaleControllerEle.style.width.slice(0, -2)));
    timeScaleElement.style.left = `-${leftPercentage * ((timeScaleElement.style.width.slice(0, -1)) - 100)}%`;
    currentTimeHandlerEle.style.left = `-${leftPercentage * ((timeScaleElement.style.width.slice(0, -1)) - 100)}%`;
    for (const annotation of annotationsArray.children) {
      annotation.style.width = `${scaledWidthinPercentage}%`;
      annotation.style.left = `-${leftPercentage * ((annotation.style.width.slice(0, -1)) - 100)}%`;
    }
    this.onZoomRatioChange(annotationsArray);
  }


  onZoomRatioChange(annotationsArray: any) {
    for (let overlay of this.data.overlayJSON.overlays) {
      const annotationTableId = this.data.getAnnotationTableId(overlay);
      overlay.isDurationChanged = true;
      let annotation = this.data.annotationTableData[annotationTableId];
      if (annotation) {
        annotation.startPx = 0;
        annotation.startPoint = (overlay.start / this.data.videoDuration) * 100 * this.data.zoomRatio;
        let translateX = (overlay.start / this.data.videoDuration) * this.timelineContainerWidth * this.data.zoomRatio;
        annotationsArray.children[this.data.annotationTableData.length - parseInt(annotationTableId) - 1].children[0].style.transform = `translate(${translateX}px,0px)`;
      }
    }
    for (let overlay of this.data.overlayJSON.audios) {
      const annotationTableId = this.data.getAnnotationTableId(overlay);
      overlay.isDurationChanged = true;
      let annotation = this.data.annotationTableData[annotationTableId];
      if (annotation) {
        annotation.startPx = 0;
        annotation.startPoint = (overlay.start / this.data.videoDuration) * 100 * this.data.zoomRatio;
        let translateX = (overlay.start / this.data.videoDuration) * this.timelineContainerWidth * this.data.zoomRatio;
        annotationsArray.children[this.data.annotationTableData.length - parseInt(annotationTableId) - 1].children[0].style.transform = `translate(${translateX}px,0px)`;
      }
    }
  }

  onCaptionZoomRatioChange( annotationsArray: any ) {
    for (let overlay of this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData) {
      const captionTableId = this.data.getCaptionTableId(overlay);
      overlay.isDurationChanged = true;
      let caption = this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[captionTableId];
      if (caption) {
        caption.startPx = 0;
        caption.startPoint = (overlay.start / this.data.videoDuration) * 100 * this.data.zoomRatio;
        let translateX = (overlay.start / this.data.videoDuration) * this.timelineContainerWidth * this.data.zoomRatio;
        annotationsArray.children[this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.length - parseInt(captionTableId) - 1].children[0].style.transform = `translate(${translateX}px,0px)`;
      }
    }
  }

  async deleteAnnotation(element: any) {
    this.data.stateChanged = true;
    const elementId = element.id;
    this.data.annotationTableData.splice(parseInt(this.data.getAnnotationTableId(element)), 1);
    
    if (element.type === "tts") {
      this.data.overlayJSON.audios.splice(elementId - 1, 1);
      for (let index = 0; index < this.data.overlayJSON.audios.length; index++) {
        this.data.overlayJSON.audios[index].id = index + 1;
      }
      this.data.updateAudio = true;
    } else {
      if(element.type === 'livevideo'){
        let url = this.data.overlayJSON.overlays[elementId - 1].srcUrl.split('/');
        let fileName = url[url.length-1];
        this.storageService.deleteMediaFiles(this.data.titleValue,fileName);  
      }
      this.data.overlayJSON.overlays.splice(elementId - 1, 1);
      this.data.italicSelected.splice(elementId - 1, 1);
      this.data.boldSelected.splice(elementId - 1, 1);
      this.data.underlineSelected.splice(elementId - 1, 1);
      for (let index = 0; index < this.data.overlayJSON.overlays.length; index++) {
        this.data.overlayJSON.overlays[index].id = index + 1;
      }
      this.data.updateAudio = false;
      await this.data.overlayAnnotations();
    }
    let overlayCount = 1;
    let audioCount = 1;
    for (let index = 0; index < this.data.annotationTableData.length; index++) {
      if (this.data.annotationTableData[index].type === "tts") {
        this.data.annotationTableData[index].id = audioCount;
        audioCount++;
      } else {
        this.data.annotationTableData[index].id = overlayCount;
        overlayCount++;
      }
    }
    if (this.data.annotationTableData.length > 0) {
      this.toggleAnnotation(this.data.annotationTableData[0]);
    } else {
      this.data.isToolBarOpen = false;
      this.data.selectedAnnotation = undefined;
    }

    if(element.type === "audio" || element.type === "liveAudio" || element.type === "tts" || element.type === "video" || element.type === "livevideo"){
      this.data.selectedScene.removePSV();
      this.data.reloadSelectedScene();
      let domInterval = setInterval(() => {
        if (document.getElementById('psv-overlay-player')) {
          clearInterval(domInterval);
          this.data.selectedScene.setPSV();
        }
      }, 1)
    }
    else{
      await this.data.overlayAnnotations();
    }
    
    let domInterval2 = setInterval(() => {
      if (document.getElementById('psv-overlay-player')) {
        clearInterval(domInterval2);
        this.data.notifyAnnotationSwitch("notified");
        this.data.notifyAnnotationUpdate("");
        this.reloadAnnotationtable();
      }
    }, 1)

  }

  deleteCaptionAnnotation(element: any){
    this.data.stateChanged = true;
    const elementId = element.id;
    this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.splice(parseInt(this.data.getCaptionTableId(element)), 1);
    this.data.italicSelected.splice(elementId - 1, 1);
    this.data.boldSelected.splice(elementId - 1, 1);
    this.data.underlineSelected.splice(elementId - 1, 1);
    for (let index = 0; index < this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.length; index++) {
      this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[index].id = index + 1;
    }
    this.data.updateAudio = false;
    let overlayCount = 1;
    let audioCount = 1;
    for (let index = 0; index < this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.length; index++) {
        this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[index].id = overlayCount;
        overlayCount++;
    }
    this.data.isToolBarOpen = false;
    this.data.selectedAnnotation = undefined;
    this.reloadAnnotationtable();
  }

  reloadAnnotationtable() {
    this.reloadAnnotationTable = false;
    setTimeout(() => this.reloadAnnotationTable = true);
  }

  calculateMaxTranslateX(width: any) {
    return this.timelineContainerWidth - width;
  }

  reOrderAnnotation(event: CdkDragDrop<string[]>){
    let prevIndex = this.data.annotationTableData.length - event.previousIndex - 1;
    let currIndex = this.data.annotationTableData.length - event.currentIndex - 1;
    moveItemInArray(this.data.annotationTableData, prevIndex, currIndex);

    let overlayCount = 1;
    let audioCount = 1;
    let overlays = [];
    let audios = [];
    for (let index = 0; index < this.data.annotationTableData.length; index++) {
      if (this.data.annotationTableData[index].type === "tts") {
        let audio = this.data.overlayJSON.audios[this.data.annotationTableData[index].id - 1];
        this.data.annotationTableData[index].id = audioCount;
        audio.id = audioCount;
        audios.push(audio);
        audioCount++;
      } else {
        let overlay = this.data.overlayJSON.overlays[this.data.annotationTableData[index].id - 1];
        this.data.annotationTableData[index].id = overlayCount;
        overlay.id = overlayCount;
        overlays.push(overlay);
        overlayCount++;
      }
    }

    this.data.overlayJSON.audios = audios;
    this.data.overlayJSON.overlays = overlays;

    if (this.data.annotationTableData.length > 0) {
      this.toggleAnnotation(this.data.annotationTableData[0]);
    } else {
      this.data.isToolBarOpen = false;
      this.data.selectedAnnotation = undefined;
    }
    this.data.setDraggerPosition();
    this.data.overlayAnnotations();
    this.data.notifyAnnotationSwitch("notified");
    this.data.notifyAnnotationUpdate("");
    this.reloadAnnotationtable();
  }

  reOrderCaptionAnnotation(event: CdkDragDrop<string[]>){
    let prevIndex = this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.length - event.previousIndex - 1;
    let currIndex = this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.length - event.currentIndex - 1;
    moveItemInArray(this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData, prevIndex, currIndex);
    let overlayCount = 1;
    let overlays = [];
    for (let index = 0; index < this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.length; index++) {
        let overlay = this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[index].id;
        this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[index].id = overlayCount;
        overlay.id = overlayCount;
        overlays.push(overlay);
        overlayCount++;
    }
    this.data.overlayJSON.overlays = overlays;

    if (this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.length > 0) {
      this.toggleAnnotation(this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[0]);
    } else {
      this.data.isToolBarOpen = false;
      this.data.selectedAnnotation = undefined;
    }
    this.data.notifyAnnotationSwitch("notified");
    this.data.notifyAnnotationUpdate("");
    this.reloadAnnotationtable();
  }

  switchToCaptionTimelineButton():any{
    this.notifyAnnotationUpdateSubscription.unsubscribe();
    this.notifyDurationChangeInToolbarSubscription.unsubscribe();
    this.notifyAnnotationToggleSubscription.unsubscribe();
    this.data.notifyVideoMetaDataLoaded(true);
    this.data.annotationType = 'caption';
    this.ngAfterViewInit();
    if (this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData.length > 0)
    {
    this.data.isToolBarOpen = true;
    this.toggleCaptionAnnotation(this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData[0])
    let width: number;
    const myElement  = document.querySelector('.right-container');
    if(myElement){
      const elementWidth = myElement.clientWidth;
      width = elementWidth
    }
    setTimeout(()=> {
      this.onTimelineResize(width, this.initalizedCaptionPane)
    })
    }
  }

  switchToAnnotationTimelineButton(){
    this.notifyAnnotationUpdateSubscription.unsubscribe();
    this.notifyDurationChangeInToolbarSubscription.unsubscribe();
    this.notifyAnnotationToggleSubscription.unsubscribe();
    this.data.notifyVideoMetaDataLoaded(true);
    this.ngAfterViewInit();
    this.data.isToolBarOpen = true;
    this.toggleAnnotation(this.data.annotationTableData[0]);
    let width: number;
    const myElement  = document.querySelector('.right-container');
    if(myElement){
      const elementWidth = myElement.clientWidth;
      width = elementWidth
    }
    setTimeout(()=> {
      this.onTimelineResize(width, this.initalizedScrollablePane)
    })
  }
}


