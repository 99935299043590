import { NgModule } from '@angular/core';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { UserDataComponent } from './user-data.component';
import { FormsModule } from '@angular/forms';
import { UserDataService } from './user-data.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [
    UserDataComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    MatSnackBarModule
  ],
  exports: [
    UserDataComponent
  ],
  providers: [UserDataService]
})
export class UserDataModule { 
}
