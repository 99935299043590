import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject, catchError,throwError } from 'rxjs';
import { HttpClient,HttpErrorResponse  } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  readonly onUpdateUserData = new BehaviorSubject<any>({
    Name: "Alexander",
    Address: {
      StreetName: 'No.7, Down town',
      City: 'NewYork',
      Zip: '12374'
    }
  });
  updateUserDataObservable = this.onUpdateUserData.asObservable();

  userData:any;

  constructor(readonly http: HttpClient) { }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message)
  }

  getUserDataJson(userJsonFileUrl:any): Observable<any> {
    return this.http.get(userJsonFileUrl).pipe(
      catchError(this.errorHandler)
    );
  }

}
