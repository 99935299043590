import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DataService } from 'src/app/shared/services/data.service';
import { PreviewModel } from 'src/app/shared/models/preview.model';
import { MatDialog } from '@angular/material/dialog';
import { BackgroundAudioComponent } from 'src/app/background-audio/background-audio.component';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-preview-scene',
  templateUrl: './preview-scene.component.html',
  styleUrls: ['./preview-scene.component.css']
})

export class PreviewSceneComponent implements OnInit {

  @Output() cancel = new EventEmitter<void>()
  concatList: any = [];
  previewScenes: PreviewModel[] = [];
  
  constructor(readonly data: DataService, public dialog: MatDialog, public storageService: StorageService) {
  }

  ngOnInit(): void {
    this.data.isFrompreview = true;
  }

  cancelpopup() {
    if (document.getElementById('psv-sample-player_html5_api')) {
      this.data.removePSV(this.data.player);
    }
    this.data.isPreviewLoaded = false;
    this.data.openPreviewAlert = false;
  }

  close() {
    this.cancel.emit();
  }

  openBgAudio() {
    let dialogRef = this.dialog.open(BackgroundAudioComponent);
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result) {
        this.addBgAudio(result);
        this.data.isBgAudioAdded=true;
      }
    });
  }

  async addBgAudio(audioSrc: any) {
    if (!this.data.projectModel.bgAudioUrl) {
      this.data.videoDatafile.overlays.push({
        "type": "bgAudio",
        "src-url": audioSrc,
        "class-name": "",
        "start": 0.001,
        "end": this.data.totalEstimatedTime,
        "volume":0.5
      });
      this.data.projectModel.bgAudioUrl = audioSrc;
      this.data.projectModel.bgAudioVolume = 0.5;
      await this.data.concatenateforBgAudio();
    }
    else {
      if (this.data.videoDatafile) {
        this.data.videoDatafile.overlays[this.data.videoDatafile.overlays.length - 1]['src-url'] = audioSrc;
        this.data.projectModel.bgAudioUrl = audioSrc;
        this.data.projectModel.bgAudioVolume= this.data.videoDatafile.overlays[this.data.videoDatafile.overlays.length - 1]['volume'];
        await this.data.concatenateforBgAudio();
      }
    }
  //  this.data.setBgAudioVolume(this.data.projectModel.bgAudioVolume);
  }
}



function metaData(metaData: any, PSV_Meta: any) {
  throw new Error('Function not implemented.');
}

