import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EditorModalComponent } from 'src/app/bg-videos/editor-modal/editor-modal.component';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-media-hub',
  templateUrl: './media-hub.component.html',
  styleUrls: ['./media-hub.component.css']
})
export class MediaHubComponent implements OnInit {

  mediaType: string = '';
  isMediaSelected: boolean = false;
  selectedMediaUrl: string = "";
  selectedMediaDuration: number = -1;
  selectedMediaName: string = "";
  tabIndex: Tabs = Tabs.Images;

  constructor(public dialogRef: MatDialogRef<MediaHubComponent>, public dialog: MatDialog, readonly data: DataService) { }

  ngOnInit(): void {
    if(this.mediaType === 'image'){
      this.tabIndex = Tabs.Images;
    }
    else if(this.mediaType === 'bgVideo' || this.mediaType === 'video'){
      this.tabIndex = Tabs.Videos;
    }
    else if(this.mediaType === 'audio'){
      this.tabIndex = Tabs.Audios;
    }
    else if(this.mediaType === 'file'){
      this.tabIndex = Tabs.File;
    }
  }

  mediaSelected(media:any){
    this.isMediaSelected = true;
    this.selectedMediaName = media.name;
    this.selectedMediaUrl = media.url;
    this.data.bgSelectedVideoUrl = media.url;
    this.selectedMediaDuration = media.duration;
  }

  closeDialog(){
    this.dialogRef.close();
  }

  useMedia(){
    if(this.mediaType === 'bgVideo'){
      this.dialogRef.close({url: this.selectedMediaUrl, duration: this.selectedMediaDuration, name: this.selectedMediaName});
    }
    else{
      this.dialogRef.close({name: this.selectedMediaName, url: this.selectedMediaUrl});
    }
  }

  editSelectedVideo() {
    let dialogRef = this.dialog.open(EditorModalComponent, {
      width: '900px',
      height: '598px',
    })
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result) {
        this.dialogRef.close();
        
      }
    });
  }
}

enum Tabs{
  Images = 0,
  Videos = 1,
  Audios = 2,
  File=3
}
