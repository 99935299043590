<div class="backdrop">
    <div class="video-dialog radius box-shadow">
        <h3>Video Preview</h3>
        <div *ngIf="data.isPreviewLoaded" class="preview-container">
            <div class="loader-holder psv-player-container" id="sample-player">
                <div class="loader"></div>
            </div>
        </div>
        <div class="progress-container" *ngIf="!data.isPreviewLoaded">
            <!-- <div class="preview-loader"></div> -->
            <div class="progress-msg">Your PSV is being prepared. Stitching the Scenes now...</div>
            <img src="../../../assets/gifs/PSV_Preview.gif" class="loader-animation">
        </div>
        <div id="transcript-player" class="tran-element"></div>
        <div class="bgBtn" *ngIf="data.isPreviewLoaded">
            <button class="bg-audiobtn primary-btn" type="button" (click)="openBgAudio()">Apply Background
                Music</button>
        </div>
        <div class="button-container">
            <div class="volume-container" *ngIf="this.data.isBgAudioAdded && data.isPreviewLoaded">
                <div class="volumeicon">
                    <mat-icon color="primary">volume_up</mat-icon>
                </div>
                <div class="slider">
                    <mat-slider #bgvolume color="primary" [ngModel]="data.projectModel.bgAudioVolume"
                         min="0" max="1" step="0.05">
                    </mat-slider>
                </div>
            </div>
            <button class="closebtn primary-btn" type="button" (click)="cancelpopup()" (click)="close()">Close</button>
        </div>
        <div class="backdropAlert" *ngIf="data.openPreviewAlert">
            <div class="confirmationmodal" >
                Something Went Wrong..!<br>
                Please Contact System Admin.
                <button mat-button type="button" class="tryAgain" (click)="cancelpopup()" (click)="close()">Close</button>
            </div>
        </div>
    </div>
</div>