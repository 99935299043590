<div class="export-json-modal">
    <h3>
      Confirm below options
    </h3>
  
    <p class="dialog-text">Obfuscate Metadata</p> <mat-slide-toggle  (change)="data.isObfuscateOn = $event.checked"></mat-slide-toggle> <br>
    <p class="dialog-text">Captions support</p> <mat-slide-toggle #enableCaptions (change)="$event.checked?null:enableTranscripts.checked = false;toggleCaptionsSupport($event)"></mat-slide-toggle><br>
    <p class="dialog-text">Transcripts support</p> <mat-slide-toggle #enableTranscripts (change)="$event.checked?enableCaptions.checked = true:null; toggleTranscriptsSupport($event)"></mat-slide-toggle><br>
    <p class="dialog-text">Adaptive Streaming and Encryption</p> <mat-slide-toggle (change)="data.isDashAvailable = $event.checked"></mat-slide-toggle> 
    <div class="mat-dialog-actions" mat-dialog-actions>
      <button mat-button (click)="dismiss()" class="cancel">
        Cancel
      </button>
      <button mat-button 
      [mat-dialog-close] = "data" class="submit">
        Confirm
      </button>
    </div>
  </div>
