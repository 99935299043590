<!------------------------------------------------------------------------------
 # COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
 # Reserved. Infosys believes the information in this document is accurate as of
 # its publication date; such information is subject to change without notice.
 # Infosys acknowledges the proprietary rights of other companies to the
 # trademarks, product names and such other intellectual property rights
 # mentioned in this document. Except as expressly permitted, neither this
 # documentation nor any part of it may be reproduced, stored in a retrieval
 # system, or transmitted in any form or by any means, electronic, mechanical,
 # printing, photocopying, recording or otherwise, without the prior permission
 # of Infosys Limited and/or any named intellectual property rights holders
 # under this document.
-------------------------------------------------------------------------------->
<div class="toolbar-properties">
	<div>
		<!-- <div (click)="minimizeToolBar()" class="toolbar-minimize" *ngIf="isToolBarOpen">
			<fa-icon [icon]="faCaretRight"></fa-icon>
		</div> -->
		<!-- <div class="header" *ngIf="newOverlayAnnotation.type!=='progressbar'">{{newOverlayAnnotation.type | uppercase}}</div> 
		<div class="header" *ngIf="newOverlayAnnotation.type==='progressbar'">PROGRESS BAR</div>-->

		<!-- <div class="sub-heading2 header" *ngIf="newOverlayAnnotation.type==='text'">TEXT ANNOTATION</div>
		<div class="sub-heading2 header" *ngIf="newOverlayAnnotation.type==='image'">IMAGE ANNOTATION</div>
		<div class="sub-heading2 header" *ngIf="newOverlayAnnotation.type==='link'">CALL-TO-ACTION ANNOTATION</div>
		<div class="sub-heading2 header" *ngIf="newOverlayAnnotation.type==='video'">VIDEO ANNOTATION</div> -->


		<div class="container">
			<!-- annotation buttons 
			 <div class="tools">
			 	<div>
				  <div class="content"><button type="button"><em class="material-icons">text_increase</em></button></div>
			 	</div>
			 	<div>
				  <div class="content"><button type="button"><em class="material-icons">add_photo_alternate</em></button></div>
			 	</div>
			 	<div>
				  <div class="content"><button type="button"><em class="material-icons">add_chart</em></button></div>
			 	</div>
			 	<div>
				  <div class="content" ><button type="button"><em class="material-icons">touch_app</em></button></div>
			 	</div>
			 	<div>
				  <div class="content" ><button type="button"><em class="material-icons">edit_attributes</em></button></div>
			 	</div>
			 	<div>
				  <div class="content" ><button type="button"><em class="material-icons">volume_up</em></button></div>
			 	</div>
			 	<div>
				<div class="content" ><button type="button"><em class="material-icons">radio_button_checked</em></button></div>
		  		</div>
			</div> -->
			<!-- <div class="vl"></div> -->

			<!-- Expansion-panels -->
			<div>
				
				<!-- properties Expansion-panel -->
				<div>
					
					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title class="heading-container">POSITION</mat-panel-title>
						</mat-expansion-panel-header>
						<label class="font-size-sm">DURATION</label>
						<div class="properties-bar time-box custom-properties-bar">
							<label class="font-size-sm">START TIME &nbsp;
								<!-- <mat-icon style="size: 5px;">remove_circle</mat-icon>  -->
								<input #startTime class="input-time start-field" type="number" min="0.000" step="0.001"
									[ngModel]="newOverlayAnnotation.start | number" (blur)="setStartTimeMinVal()"
									(ngModelChange)="newOverlayAnnotation.start= $event"
									(input)="changeStartTime(startTime.value,endTime.value)">
								<!-- <mat-icon style="size: 0.5em;">add_circle</mat-icon>   -->
							</label>
							<!-- <div class="v2"></div> -->
							<label class="font-size-sm">END TIME
								<!-- <mat-icon style="size: 0.5em;">remove_circle</mat-icon> -->
								<input #endTime class="input-time end-field" style="margin-right: 5px;" type="number"
									min="0.000" step="0.001" [max]="videoDuration"
									[ngModel]="newOverlayAnnotation.end | number"
									(input)="changeEndTime(startTime.value,endTime.value)"
									(ngModelChange)="newOverlayAnnotation.end=$event">
								<!-- <mat-icon style="size: 0.5em;">add_circle</mat-icon> -->
							</label>
						</div>
						<label class="font-size-sm">COORDINATES AND ORIENTATION</label>
						<div class="properties-bar time-box custom-properties-bar">
							<label class="font-size-sm">X CO-ORDINATES
								<input #item class="input-coordinates x-label" step="1.0" type="number"
									[ngModel]="newOverlayAnnotation.cssProperties.left | calcXCoord  | number"
									(ngModelChange)="updateXCoordinate($event)">
							</label>
							<!-- <div class="v2"></div> -->
							<label class="font-size-sm">Y CO-ORDINATES
								<input class="input-coordinates y-label" type="number" step="1.0"
									[ngModel]="newOverlayAnnotation.cssProperties.paddingTop | calcYCoord  | number"
									(ngModelChange)="updateYCoordinate($event)">
							</label>
						</div>
						<div
							[ngStyle]="{'height':newOverlayAnnotation.type !== 'chart' && newOverlayAnnotation.type !== 'progressbar'? '74px': '0px','width':newOverlayAnnotation.type !== 'chart'? '106%': '0%'}">
							<label
								*ngIf="newOverlayAnnotation.type !== 'chart' && newOverlayAnnotation.type !== 'progressbar'"
								class="font-size-sm">ROTATION</label>
							<div *ngIf="newOverlayAnnotation.type !== 'chart' && newOverlayAnnotation.type !== 'progressbar' "
								class="properties-bar">
								<label class="font-size-lg">
									<mat-slider min="0" max="360" step="1" value="0" thumbLabel
										[ngModel]="newOverlayAnnotation.cssProperties.rotation | calcRotation"
										(input)="updateRotation($event)">
									</mat-slider>
									<span>{{newOverlayAnnotation.cssProperties.rotation | calcRotation}}deg</span>
								</label>
							</div>
						</div>
					</mat-expansion-panel>
				</div>


				<!-- Animation Expansion-panels -->
				<div>
					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title class="heading-container">ANIMATION</mat-panel-title>
						</mat-expansion-panel-header>
						<div class="input-dropdown">
							<label class="font-size-sm">ENTRY ANIMATION</label>
							<mat-form-field>
								<mat-select [(ngModel)]="entryAnimation" (selectionChange)="updateEntryAnimation()">
									<mat-option value="">----SELECT----</mat-option>
									<mat-option *ngFor="let animation of entryAnimationList" [value]="animation.value">
										{{animation.name}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<label *ngIf="entryAnimation" class="properties font-size-sm animation-btn anim-width">ENTRY
							ANIMATION DURATION
							(IN MS)</label>
						<div *ngIf="entryAnimation" class="properties-bar">
							<button class="remove-default-style properties-button animation-minus-btn"
								(click)="decreaseEntryAnimationDuration()" matTooltip="Reduce Entry Animation Duration"
								matTooltipPosition=left>
								<fa-icon [icon]="faMinusCircle"></fa-icon>
							</button>
							<input class="font-size-lg center-align width-half input-box font-size-md" min="1"
								type="number" [(ngModel)]="entryAnimationDuration"
								(ngModelChange)="updateEntryAnimation()">
							<button class="remove-default-style properties-button "
								(click)="increaseEntryAnimationDuration()" matTooltip="Add Entry Animation Duration"
								matTooltipPosition=left>
								<fa-icon [icon]="faPlusCircle"></fa-icon>
							</button>
						</div>
						<div class="input-dropdown">
							<label class="font-size-sm">EXIT ANIMATION</label>
							<mat-form-field>
								<mat-select [(ngModel)]="exitAnimation" (selectionChange)="updateExitAnimation()">
									<mat-option value="">----SELECT----</mat-option>
									<mat-option *ngFor="let animation of exitAnimationList" [value]="animation.value">
										{{animation.name}}
									</mat-option>
								</mat-select>
							</mat-form-field>

						</div>

						<label *ngIf="exitAnimation" class="properties font-size-sm animation-btn anim-width">EXIT
							ANIMATION DURATION (IN MS)</label>
						<div *ngIf="exitAnimation" class="properties-bar">
							<button class="remove-default-style properties-button animation-minus-btn"
								(click)="decreaseExitAnimationDuration()" matTooltip="Reduce Exit Animation Duration"
								matTooltipPosition=left>
								<em class="fa-solid fa-circle-minus fa-lg"></em>
							</button>
							<input class="font-size-lg center-align width-half input-box font-size-md" min="1"
								type="number" [(ngModel)]="exitAnimationDuration"
								(ngModelChange)="updateExitAnimation()">
							<button class="remove-default-style properties-button "
								(click)="increaseExitAnimationDuration()" matTooltip="Add Exit Animation Duration"
								matTooltipPosition=left>
								<em class="fa-solid fa-circle-plus fa-lg"></em>
							</button>
						</div>
					</mat-expansion-panel>
				</div>

				<!-- Appearance Expansion-panel -->
				<div>
					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title class="heading-container">VISIBILITY</mat-panel-title>
						</mat-expansion-panel-header>

						<label class="properties font-size-sm" for="show-on">SHOW ON</label>
						<input #showOn class="seperate-row" type="text" id="show-on"
							placeholder="Enter a boolean value/variable" [ngModel]="newOverlayAnnotation.showOn"
							(ngModelChange)="newOverlayAnnotation.showOn= showOn.value">

						<label class="properties font-size-sm"
							*ngIf="newOverlayAnnotation.type !== 'progressbar'">OPACITY</label>
						<div [ngStyle]="{'height':newOverlayAnnotation.type !== 'chart'? '30px': '48px'}"
							*ngIf="newOverlayAnnotation.type !== 'progressbar'">
							<div class="properties-bar opacity">
								<label class="font-size-lg">
									<mat-slider min="0" max="100" step="1" thumbLabel
										[ngModel]="newOverlayAnnotation.cssProperties.opacity * 100| number:'1.0-0'"
										(input)="updateOpacity($event)">
									</mat-slider>
									<span>{{newOverlayAnnotation.cssProperties.opacity * 100 |
										number:"1.0-0"}}%</span>
								</label>
							</div>
						</div>
					</mat-expansion-panel>
				</div>


				<!-- Annotation based properties -->
				<div></div>




			</div>


		</div>

	</div>
</div>