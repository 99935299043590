import { Component, OnInit,  Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { OverlayTextService } from "./overlay-text.service"
import { faPlusCircle,faIcons, faMinusCircle, faAlignLeft, faAlignRight, faAlignJustify, faAlignCenter, faUnderline, faBold, faItalic, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'psv-overlay-text',
  templateUrl: './overlay-text.component.html',
  styleUrls: ['./overlay-text.component.css'
  ]
})
export class OverlayTextComponent implements OnInit {
  faCaretRight:any = faCaretRight;
  faPlusCircle:any = faPlusCircle ;
  faMinusCircle:any = faMinusCircle;
  faAlignLeft:any = faAlignLeft;
  faAlignRight :any= faAlignRight;
  faAlignJustify :any= faAlignJustify;
  faAlignCenter:any = faAlignCenter;
  faBold:any = faBold;
  faItalic :any= faItalic;
  faUnderline:any = faUnderline;
  fontFamilyList:any = ['Arial', 'Domaine_displaybold', 'Helvetica', 'Open_sansbold', 'Open_sansregular', 'Open_sanssemibold', 'Sans-serif', 'Times New Roman', 'Times',
    'Courier New', 'Courier', 'Verdana', 'Georgia', 'Palatino', 'Bookman', 'Comic Sans MS',
    'Arial Black', 'Roboto', 'Montserrat', 'Trebuchet MS', 'Oswald'];
  fontWeightList:any = ['Normal', 'Bold', 'Bolder', 'Lighter'];
  italicBtn :boolean= false;
  boldBtn :boolean= false;
  underlineBtn :boolean= false;
  italicSelected = [] as any;
  boldSelected = [] as any;
  underlineSelected = [] as any;
  cssProperties:any;
  validity: any = false;
  
  @Input() selectedAnnotation:any
  @Input() annotationTableData:any;
  @Input() isToolBarOpen!:boolean;
  showsnackbar:any;

  constructor(readonly data: OverlayTextService, private snackBar: MatSnackBar ) { }

  ngOnInit(): void {
    this.annotationTableData = this.annotationTableData;
    this.loadElement(this.textOverlayComponent);
  }
  @Output() onMinimizing = new EventEmitter<boolean>();
  @Output() change= new EventEmitter<MatSlideToggleChange>(); 
  @Output() annotationTableDataEvent = new EventEmitter<any>();
  @Output() overlayAnnotationsEvent = new EventEmitter<any>();
  @Output() setDraggerPositionEvent =new EventEmitter<any>();

  @Input() textOverlayComponent:any = {
    "type": "text",
    "value": "Hello {userName}!",
    "id": 1,
    "class-name": "",
    "start": 0.5,
    "end": 2.51,
    "exitAnimation": "",
    "css-properties": [
      {
                    "font-size": "0.61em",
                    "font-family": "Arial",
                    "font-weight": "Bold",
                    "font-style": "Normal",
                    "padding": "0.5em",
                    "background-color": "transparent",
                    "transform": "translate3d(0px, 0px, 0px) rotate(0deg)",
                    "color": "#0073af",
                    "animation": "custom-fade-in 0.5s",
                    "opacity": 1,
                    "display": "inline-block",
                    "text-decoration": "None"
    } 
   ]
};

  loadElement(updatedOverlays?:any) {
    if (!(this.textOverlayComponent.value === undefined)) {
      this.overlayAnnotations(updatedOverlays);
      this.setDraggerPosition();
    }
  }

  changeAnnotationValue() {
    this.annotationTableData[this.getAnnotationTableId(this.textOverlayComponent)].value = this.textOverlayComponent.value;
    this.checklength();
    this.validity=this.charactercheck();
    this.annotationTableDataEvent.emit(this.annotationTableData);
    this.loadElement(this.textOverlayComponent);
  }
  
  checklength()
  {
    var keyStringPattern = this.annotationTableData[this.getAnnotationTableId(this.textOverlayComponent)].value;
    var size1=keyStringPattern.length;
    if(size1>=1000)
    {
      document.getElementById('annotation-text').style.border ="2px solid red";
      this.snackBar.open('text limit is 1000 characters', "", 
      {
       duration: 4000,
       verticalPosition: 'top',
      });
    }
else 
{
  console.log("not matched");
}
}

charactercheck()
{
  var Input = this.annotationTableData[this.getAnnotationTableId(this.textOverlayComponent)].value;
  if(Input.includes('"'))
  {
    document.getElementById('annotation-text').style.border ="2px solid red";
    this.snackBar.open('usge of " " is not allowed in script', "", 
    {
duration: 2000,
verticalPosition: 'top',
    });
  }
else 
  {
    console.log("not matched");
  }
}

  getAnnotationTableId(overlayObj:any):string {
    for (let index in this.annotationTableData) {
      if (this.annotationTableData[index].type === "tts" && overlayObj.type === "tts" && overlayObj.id === this.annotationTableData[index].id) {
        return index;
      } else if (this.annotationTableData[index].type !== "tts" && overlayObj.type !== "tts" && overlayObj.id === this.annotationTableData[index].id) {
        return index;
      }
    }
    return '';
  }

  updateFontSize(number:any, id:any) {
    this.textOverlayComponent.cssProperties.fontSize = `${number}em`;
    this.loadElement(this.textOverlayComponent);
  }
  
  increaseFontSize(id:any) {
    const fontSize: string = this.textOverlayComponent.cssProperties.fontSize;
    this.textOverlayComponent.cssProperties.fontSize = `${+fontSize.replace("em", "") + 0.01}em`;
    this.loadElement(this.textOverlayComponent);
  }

  decreaseFontSize(id:any) {
    const fontSize: string = this.textOverlayComponent.cssProperties.fontSize;
    this.textOverlayComponent.cssProperties.fontSize = `${+fontSize.replace("em", "") - 0.01}em`;
    this.loadElement(this.textOverlayComponent);
  }

  increasePaddingSize(id:any) {
    let paddingSize: string;
    if (this.textOverlayComponent.type === 'tel' || this.textOverlayComponent.type === 'text') {
      paddingSize = this.textOverlayComponent.cssProperties.padding;
      this.textOverlayComponent.cssProperties.padding = `${+paddingSize.replace("em", "") + 0.01}em`;
    }
    else {
      paddingSize = this.textOverlayComponent.cssProperties.buttonPadding;
      this.textOverlayComponent.cssProperties.buttonPadding = `${+paddingSize.replace("em", "") + 0.01}em`;
    }
    this.loadElement(this.textOverlayComponent);
  }

  decreasePaddingSize(id:any) {
    let paddingSize: string;
    if (this.textOverlayComponent.type === 'text') {
      paddingSize = this.textOverlayComponent.cssProperties.padding;
      this.textOverlayComponent.cssProperties.padding = `${+paddingSize.replace("em", "") - 0.01}em`;
    }
    else {
      paddingSize = this.textOverlayComponent.cssProperties.buttonPadding;
      this.textOverlayComponent.cssProperties.buttonPadding = `${+paddingSize.replace("em", "") - 0.01}em`;
    }
    this.loadElement(this.textOverlayComponent);
  }

  applyStyle(id:any, type:any) {
    switch (type) {
      case 'bold': {
        this.boldBtn = !this.boldBtn;
        this.boldSelected[id] = this.boldBtn;
        if (this.boldBtn) {
          this.textOverlayComponent.cssProperties.fontWeight = "Bold";
        }
        else {
          this.textOverlayComponent.cssProperties.fontWeight = "Normal";
        }
        break;
      }
      case 'italic': {
        this.italicBtn = !this.italicBtn;
        this.italicSelected[id] = this.italicBtn;
        if (this.italicBtn) {
          this.textOverlayComponent.cssProperties.fontStyle = "italic";
        }
        else {
          this.textOverlayComponent.cssProperties.fontStyle = "Normal";
        }
        break;
      }
      case 'underline': {
        this.underlineBtn = !this.underlineBtn;
        this.underlineSelected[id] = this.underlineBtn;
        if (this.underlineBtn) {
          this.textOverlayComponent.cssProperties.textDecoration = "underline";
        }
        else {
          this.textOverlayComponent.cssProperties.textDecoration = "None";
        }
        break;
      }
    }
    this.loadElement(this.textOverlayComponent);
  }

  settingFontWeight(id:any) {
    const fontWeight = this.textOverlayComponent.cssProperties.fontWeight;
    switch (fontWeight) {
      case 'Normal': {
        this.boldBtn = false;
        break;
      }
      case 'Bold': {
        this.boldBtn = true;
        break;
      }
      case 'Bolder': {
        this.boldBtn = false;
        break;
      }
      case 'Lighter': {
        this.boldBtn = false;
        break;
      }
    }
    this.loadElement(this.textOverlayComponent);
   }

  updatePaddingSize(id:any, number:any) {
    if (this.textOverlayComponent.type === 'tel' || this.textOverlayComponent.type === 'text')
      this.textOverlayComponent.cssProperties.padding = `${number}em`;
    else {
      this.textOverlayComponent.cssProperties.buttonPadding = `${number}em`;
    }
    this.loadElement(this.textOverlayComponent);
  }

  updatePaddingRadius(id:any, number:any) {

    this.textOverlayComponent.cssProperties.borderRadius = `${number}em`;

    this.loadElement(this.textOverlayComponent);
  }

  increasePaddingRadius(id:any) {
    let paddingRadius: string;
    paddingRadius = this.textOverlayComponent.cssProperties.borderRadius;
    this.textOverlayComponent.cssProperties.borderRadius = `${+paddingRadius.replace("em", "") + 0.01}em`;

    this.loadElement(this.textOverlayComponent);
  }


  decreasePaddingRadius(id:any) {
    let paddingRadius: string;
    paddingRadius = this.textOverlayComponent.cssProperties.borderRadius;
    this.textOverlayComponent.cssProperties.borderRadius = `${+paddingRadius.replace("em", "") - 0.01}em`;

    this.loadElement(this.textOverlayComponent);
  }


  updateTextAlignment(alignment: any) {
    this.textOverlayComponent.cssProperties.textAlign = alignment;
    this.loadElement(this.textOverlayComponent);
  }

  changeColor(inpColor: any) {
    this.textOverlayComponent.cssProperties.color = inpColor;
    this.loadElement(this.textOverlayComponent);
  }

  changeBgColor(inpColor: any) {
    this.textOverlayComponent.cssProperties.backgroundColor = inpColor;
    this.loadElement(this.textOverlayComponent);
  }

  updateTextWidth(width: any) {
    this.textOverlayComponent.cssProperties.textWidth = `${width}em`;
    this.loadElement(this.textOverlayComponent);
  }

  updateTextHeight(height: any) {
    this.textOverlayComponent.cssProperties.textHeight = `${height}em`;
    this.loadElement(this.textOverlayComponent);
  }

  toTransparent(id:any) {
    if (this.textOverlayComponent.bgToggle === false) {
      this.textOverlayComponent.cssProperties.backgroundColor = "transparent";
      this.textOverlayComponent.cssProperties.padding = `0.0em`;
    }
    else {
      this.textOverlayComponent.bgToggle = true;
      this.textOverlayComponent.cssProperties.backgroundColor = "#000000";
    }
    this.loadElement(this.textOverlayComponent);
  }

  overlayAnnotations(updateAnnotation?: any){
  this.overlayAnnotationsEvent.emit(updateAnnotation);
  }

  setDraggerPosition(){
    this.setDraggerPositionEvent.emit();
  }
  minimizeToolBar() {
    this.onMinimizing.emit(true);
  }
}

