import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '../../services/data.service';

@Pipe({
  name: 'displayScript'
})
export class DisplayScriptPipe implements PipeTransform {

  script!: any;

  constructor(readonly data:DataService){

  }

  transform(value: any, ...args: unknown[]): unknown {
    this.script = value;
    this.replaceInputs(this.data.userData);
    return this.script;
  }

  replaceInputs(userData: any) {
    Object.keys(userData).forEach((key: any) => {
      let val = userData[key];
      if (typeof (userData[key]) == "string") {
        this.script = this.script.replaceAll('{' + key + '}', val);
      }
      else {
        this.replaceInputs(val);
      }
    });
  }

}
