/*******************************************************************************
 * COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
 * Reserved. Infosys believes the information in this document is accurate as of
 * its publication date; such information is subject to change without notice.
 * Infosys acknowledges the proprietary rights of other companies to the
 * trademarks, product names and such other intellectual property rights
 * mentioned in this document. Except as expressly permitted, neither this
 * documentation nor any part of it may be reproduced, stored in a retrieval
 * system, or transmitted in any form or by any means, electronic, mechanical,
 * printing, photocopying, recording or otherwise, without the prior permission
 * of Infosys Limited and/or any named intellectual property rights holders
 * under this document.
 ******************************************************************************/
import { ErrorHandler, Injectable, ViewChild, ElementRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { CssProperties } from './../models/css-properties.model';
import { TextAnnotation } from './../models/text-annotation.model';
import { PSV_Meta } from '../models/psv.model';
import { OnInit } from '@angular/core';
import { faFont, faIcons, faFileImage, faLink, faChartBar, faPhone, faEnvelope, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { PreviewModel } from '../models/preview.model';
import { UserDataService } from 'projects/user-data/src/lib/user-data.service'
import { environment } from 'src/environments/environment';
import { SceneModel } from '../models/scene.model';
import { StorageService } from './storage.service';
import { ErrorComponent } from './../error/error.component';
import { ImageAnnotation } from '../models/image-annotation.model';
import { AudioAnnotation } from '../models/audio-annotation.model';
import { WebpageAnnotation } from '../models/webpage-annotation.model';
import { ProgressbarAnnotation } from '../models/progressbar-annotation.model';
import { ChartAnnotation } from '../models/chart-annotation.model';
import { LinkAnnotation } from '../models/link-annotation.model';
import { AudioOverlayAnnotation } from '../models/audio-overlay.model';
import { VideoAnnotation } from '../models/video-annotation.model';
import { MatDialog } from '@angular/material/dialog';
import { AnnotationGalleryComponent } from 'src/app/editor/annotation-gallery/annotation-gallery.component';
import { URLModel } from '../models/URL-model';
import { Project_Meta } from '../models/project.model';
import { promised } from 'q';
import { ExportJsonDialog } from 'src/app/export-json/export-json.component';
import { FileAnnotation } from '../models/file-annotation.model';
import {CaptionAnnotation} from '../models/caption-annotation.model';
import { KeycloakService } from 'keycloak-angular';
import { MediaHubComponent } from 'src/app/editor/media-hub/media-hub.component';
import { PsvTemplate } from '../interfaces/Interface_template';


declare var videojs: any;
declare var psv: any;
declare var psvConcatUtility: any;
declare var JavaScriptObfuscator: any;
declare var oxvideometa: any;

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  newOverlayObject: any;
  selectedBgVideoDuration: any;
  fileAnnName: any;
  mediaFile: any;
  selectedAnnotation: any;
  annotationTableData: any = [];
  caption_annotationTableData: any = [];
  annotationType:any;
  activeTool: any;
  italicBtn = false;
  boldBtn = false;
  underlineBtn = false;
  // selectedDatasourceId :any;
  previewVideoDataUrl: any;
  previewUserDataUrl: any;
  previewScreen: boolean = false;
  fileContent: any;
  scriptArray: any[] = [];
  onCreateVideo: boolean = false;
  scriptlines: any;
  readonly OnUpdateData = new BehaviorSubject<string>("");
  updateDataObservable = this.OnUpdateData.asObservable();
  videoPreviewStartDuration = 0;
  previewScenes: PreviewModel[] = [];
  concatList: any;
  UrlObj: any;
  metaDataUrlObj: any;
  concatVideoURL: any;
  concatDataURL: any;
  concatDataUrlFromUtility: any;
  videoDatafile!: any;
  isPreviewLoaded: boolean = false;
  videoDurations: any = [];
  matchedMedia: any = [];
  matchedNamesOfMedia: any = [];
  mediaType: any;
  isPublishScreen: boolean = false;
  genericErrorMsg: any = "Something went wrong, try again";
  secureCheck: boolean = false;
  sceneFileName : any=[];

  captionBlob:any;
  exisitingCaptionUrl:any;

  readonly onUpdateTemplate = new BehaviorSubject<string>("");
  updateTemplateObservable = this.onUpdateTemplate.asObservable();

  readonly durationChangeNotify = new BehaviorSubject<string>("");
  durationChangeNotifyObservable = this.durationChangeNotify.asObservable();

  readonly currentPlayerTime = new BehaviorSubject<number>(0);
  currentPlayerTimeObservable = this.currentPlayerTime.asObservable();

  readonly ttsUpdateNotify = new BehaviorSubject<string>("");
  notifyTTSUpdateObservable = this.ttsUpdateNotify.asObservable();

  readonly videoPlaying = new BehaviorSubject<boolean>(false);
  videoPlayingObservable = this.videoPlaying.asObservable();

  readonly annotationUpdateNotify = new BehaviorSubject<string>("");
  notifyAnnotationUpdateObservable = this.annotationUpdateNotify.asObservable();

  readonly videoLoadedNotify = new BehaviorSubject<string>("");
  notifyVideoLoadedObservable = this.videoLoadedNotify.asObservable();

  readonly annotationSwitchNotify = new BehaviorSubject<string>("");
  annotationSwitchNotifyObservable = this.annotationSwitchNotify.asObservable();

  readonly startTimeUpdate = new BehaviorSubject<string>("");
  startTimeUpdateObservable = this.startTimeUpdate.asObservable();

  readonly notifyOnVideoMetaDataLoadedSubject = new BehaviorSubject<boolean>(false);
  notifyOnVideoMetaDataLoadedObservable = this.notifyOnVideoMetaDataLoadedSubject.asObservable();

  readonly notifyDurationChangeInToolbarSubject = new BehaviorSubject<string>("");
  notifyDurationChangeInToolbarObservable = this.notifyDurationChangeInToolbarSubject.asObservable();

  readonly notifyPublishClickSubject = new BehaviorSubject<string>('');
  notifyPublishClickObservable = this.notifyPublishClickSubject.asObservable();

  readonly notifyAllSceneSelectedSubject = new BehaviorSubject<string>('');
  notifyAllSceneSelectedObservable = this.notifyAllSceneSelectedSubject.asObservable();

  readonly notifySceneSelectedSubject = new BehaviorSubject<string>('');
  notifySceneSelectedObservable = this.notifySceneSelectedSubject.asObservable();

  readonly notifyAnnotationToggleSubject = new BehaviorSubject<number>(-1);
  notifyAnnotationToggleObservable = this.notifyAnnotationToggleSubject.asObservable();

  readonly notifyCreateVideoSubject = new BehaviorSubject<string>("");
  notifyCreateVideoObservable = this.notifyCreateVideoSubject.asObservable();

  readonly notifyGenerateUrlSubject = new BehaviorSubject<string>("");
  notifyGenerateUrlObservable = this.notifyGenerateUrlSubject.asObservable();

  isSceneSelected: boolean = false;
  selectedSceneIndex!: any;
  titleValue: any = '';
  totalEstimatedTime: any;
  descriptionValue: string = '';
  sampleVideosArr: string[] = ['Intro_1', 'Intro_2', 'Intro_3', 'Intro_4', 'Thank_You_1', 'Thank_You_2', 'Thank_You_3', 'Thank_You_4'];
  isVideoPlaying: boolean = false;
  videoDuration: any;
  //videoDuration:any;
  tts: any = "polly";
  isToolBarOpen!: boolean;
  aspectRatio: any;
  annotationHeight: any;
  annotationWidth: any;
  zoomRatio: any = 1;
  isPreviewParticularSegment: boolean = false;
  ttsProviderOptions: any;
  isTtsProviderChanged!: boolean;
  // italicBtn:boolean = false;
  // boldBtn:boolean = false;
  // underlineBtn:boolean = false;
  videoSrc: any;
  //videoPreviewStartDuration: any;
  videoPreviewEndDuration: any;
  currentTime: any;
  isTimelineLoaded: boolean = true;
  // selectedAnnotation: any;
  selectedScene: any;
  selectedSceneLoaded: boolean = true;
  selectedDatasourceId: any = 'null';
  blobs: URLModel = new URLModel();
  isCaptionAvailable = false;
  enableTranscript = false;

  overlayJSON: any = {
    "overlays": [],
    "audios": [],
    "captions": {
      "is-available": this.isCaptionAvailable,
      "enable-transcript": this.enableTranscript,
      "src": "",
      "kind": "captions",
      "srclang": "en",
      "label": "English"
  },
    "backgroundAudio": {},
    "captionTableData": []
  };
  projectModel: any;
  player: any;
  scenes: SceneModel[] = [];
  isLoaderEnabled!: boolean;
  isSkipSectionAvailable = false;
  isSectionAvailable: boolean = false;
  skipSectionAll: any = [];
  videoName = "";
  userDataId: any;


  ttsOptions = {
    ttsApiPath: environment.audioService,
    storageWebServerPath: environment.storageService,
    minioCachePath: environment.storageWebServerUrl
  }

  bgAudioObj:AudioOverlayAnnotation;
  isDashAvailable = false;
  posterUrl: any;
  videoSize: any = "NA";
  updateAudio: boolean = false;
  captionUrl: any;
  finalSeekArray: any;
  finalSkipArray: any;
  // activeTool:any;
  seekTo: any;
  saveLoaded: boolean = false;
  concatMetaDataBlob: any;
  isFrompreview: boolean = false;
  publishLoader: boolean = false;
  analyticsSelected: boolean = false;
  bgAudioUrl: any;
  userId: any;
  bgVolume: any;
  isBgAudioAdded: boolean = false;
  bgSelectedVideoUrl: string = "";
  bgSceneIndex: any;
  bgTrimmedVideoUrl: any;
  estimatedTime: number = 0;
  show: boolean = true;
  loadTrimmedBgVideo:boolean=false;
  openPreviewAlert:boolean=false;
  captionStr: any;

  allSection = [{
    id: this.overlayJSON.overlays.length + 1,
    type: "section",
    time: { min: "", sec: "" },
    message: "",
    seekTo: ""
  }];

  psvArr: PSV_Meta[] = [];

  readonly sceneDuration = new BehaviorSubject<number>(60);
  videoDurationObservable = this.sceneDuration.asObservable();

  readonly endTimeUpdate = new BehaviorSubject<string>("");
  endTimeUpdateObservable = this.endTimeUpdate.asObservable();

  readonly color = new BehaviorSubject<any>("123");

  colorObservalble = this.color.asObservable();
  userDataArray: any[] = [];
  customKeyArray: any[] = [];
  userDataObj: any = {};
  index: number = 1;
  isNavigation: boolean = false;
  userData: any = {};
  stateChanged: boolean = true;

  isFewUsers: boolean = true;
  isDynamicAPI: boolean = false;
  dynamicApiUrl: any;
  reload: boolean = false;
  playerId: string = "playerElementId";
  onPublishBtn: boolean = false;
  onPublishScreen: boolean = false;
  concatenatedVideoBlob: any;
  callToActionListsArray: any[] = [];
  videoPreviewAvailable = true;
  videoDescription = "";
  isSrcVideoAvailable = false;
  isObfuscateOn = false;
  fileName: any;
  italicSelected = [] as any;
  boldSelected = [] as any;
  underlineSelected = [] as any;
  cssProperties: any;
  isHomePage: boolean = true;
  isTemplatePage: boolean = false;
  isPsvLoaderPage: boolean = false;
  isPublishClicked: boolean = false;

  showSaveButton: Subject<boolean> = new Subject<boolean>();
  errorClassList = ["error-snackbar"];
  flagFilesImported = false;
  concatenatedMetaData: any;
  isEditScript: boolean = false;
  enableAspectRatio:boolean=false;
  analyticsProjectId:any;
  analyticsTemplateId:any;
  analyticsProjectTitle:any;
  analyticsTemplateTitle: any;
  analyticsProjectCreateDate:any;
  analyticsProjectLink:any;
  fromTemplate: boolean=false;
  templateObj: any;
  bgAudioVolume: any;

  @ViewChild('scrollMe',{static: true}) comment!: ElementRef;

  scrolltop!: number;


  constructor(readonly http: HttpClient, readonly sanitizer: DomSanitizer, public snackBar: MatSnackBar, private router: Router, public userDataService: UserDataService, readonly storageService: StorageService, public dialog: MatDialog, readonly keycloak: KeycloakService) {
    // this.userDataService.updateUserDataObservable.subscribe(data =>{
    //   this.userData = data;
    // });}
  }

  ngOnInit() {
    this.isLoaderEnabled = true;
  }

  setPSVMeta(metaData: PSV_Meta) {
    return new psv.overlayAnnotationsOnStudio(metaData.getPlayerElementId(), metaData.getVideoSource(), metaData.getPsvMetaData(), this.userData, undefined);
  }

  removePSV(metaData: PSV_Meta) {
    return new psv.removePlayer(metaData.getPlayerElementId(), metaData.getVideoSource());
  }

  async callAddDynamicAudioToVideo(metaData: PSV_Meta) {
    await psv.addDynamicAudioToVideo(metaData.getPlayerElementId(), metaData.getVideoSource(), metaData.getPsvMetaData(), this.formatUserDataOutput(), this.ttsOptions).then(() => {

    }).catch((err: any) => {
      this.errorMsg(err);
    })
  }

  calculateVideoDuration(val: number) {
    this.sceneDuration.next(val);
  }


  async concatVideo(sceneStr: any, videoRelatedData: any, videoConcatServiceUrl: any) {
    await psvConcatUtility.concatenateVideoRelatedData(sceneStr, videoRelatedData, videoConcatServiceUrl, "URL").then((result: any) => {
      this.UrlObj = result;
      let videoBlob = result.videoResponse;
      this.concatenatedVideoBlob = videoBlob;
      this.concatVideoURL = URL.createObjectURL(videoBlob);
      this.isPreviewLoaded = true;
      return new Promise((resolve: any, reject: any) => {
        resolve(true);
      })
    }).catch((err:any) => {
      this.UrlObj=undefined;
      this.concatDataUrlFromUtility=undefined;
      this.stateChanged=true;
      this.storageService.deleteConcatFiles(this.projectModel.id).then(() => { console.log('deleted') }).catch(err =>{this.showSnackBar("Something Went Wrong while Deleting","ok")});
      this.isPreviewLoaded=true;
      this.openPreviewAlert=true;
      this.saveLoaded=false;
      this.showSnackBar("Concatenation error","Close");
    });
  }
  async concatenateVideoRelatedData(sceneList: any, previewScenes: PreviewModel[]) {
    let videoConcatServiceUrl = environment.concatServiceUrl + 'concatVideoFiles';
    let videoRelatedData = this.formatVideoRelatedData(previewScenes);
    var sceneStr = sceneList.toString();
    await this.concatVideo(sceneStr, videoRelatedData, videoConcatServiceUrl).then((result: any) => {
      return new Promise((resolve: any, reject: any) => {
        resolve(true);
      })
    });
  }

  async trimBgVideos(videoUrl: any, inTimeStamp: any, outTimeStamp: any) {
    await this.trimVideo(videoUrl, inTimeStamp, outTimeStamp).then((videoBlob: any) => {
      this.stateChanged = true;
      this.bgTrimmedVideoUrl = this.blobs.createObjectURL(videoBlob);
      this.scenes[this.bgSceneIndex].videoPath = this.bgTrimmedVideoUrl;
      this.previewScenes[this.bgSceneIndex].sceneUrl = this.bgTrimmedVideoUrl;
      this.correctAnnotationEndTimes(parseFloat(outTimeStamp), this.bgSceneIndex);
      this.removeAllPsv();
      this.reloadScenes();
      this.setAllPsv();
      this.loadTrimmedBgVideo=false;
    }).catch(err =>{
      this.loadTrimmedBgVideo = false;
      this.showSnackBar('Something went wrong, Try again','Ok');
    })
  }

  reloadScenes() {
    this.show = false;
    setTimeout(() => this.show = true);
  }

  removeAllPsv() {
    this.estimatedTime = 0;
    for (let i = 0; i < this.scenes.length; i++) {
      if (document.getElementById('psv-player' + (i).toString() + '_html5_api')) {
        this.scenes[i].removePSV();
      }
    }
  }

  setAllPsv() {
    for (let i = 0; i < this.scenes.length; i++) {
      let domInterval = setInterval(() => {
        if (document.getElementById('player' + (i).toString())) {
          clearInterval(domInterval);
          this.scenes[i].setPSV();
        }
      }, 1)
    }
  }

  formatVideoRelatedData(previewModel: PreviewModel[]) {
    let formattedJSON: any = {};
    for (let scene of previewModel) {
      formattedJSON[scene.sceneName] = {
        "url": scene.sceneUrl,
        "metaData": scene.metaData,
        "videoDuration": scene.videoDuration,
        "captionUrl": scene.captionUrl
      }
    }
    return formattedJSON;
  }

  setPSVMetaAsJSON(playerElementId: any, videoSource: any, videoDataUrl: any, userData: any) {
    return new psv.setPSVMetaAsJSON(playerElementId, videoSource, videoDataUrl, userData, this.ttsOptions).then(() => {

    }).catch((err: any) => {
      this.errorMsg(err);
    });
  }

  async concatenatedDataOnPreview() {
    return new Promise(async (resolve: any, reject: any) => {
      // state of the project is changed
      if (this.stateChanged) {
        await this.concatenateVideoRelatedData(this.concatList, this.previewScenes).then((result: any) => {
          if(this.UrlObj){
            this.concatDataUrlFromUtility = this.UrlObj.videoDataURL;
          }
        })
        if(this.concatDataUrlFromUtility){
        await fetch(this.concatDataUrlFromUtility).then(async (response: any) => {
          await response.json().then(async (videoDataJSON: any) => {
            this.videoDatafile = videoDataJSON;

            if (this.projectModel.bgAudioUrl) {
              this.videoDatafile.overlays.push({
                "type": "bgAudio",
                "src-url": this.projectModel.bgAudioUrl,
                "class-name": "",
                "start": 0.001,
                "end": this.totalEstimatedTime,
                "volume": this.projectModel.bgAudioVolume
              });
              this.isBgAudioAdded = true;
            }
            this.projectModel.createdBy = this.userId;
            var videoDataFormat = this.convertJsonToJS(this.videoDatafile);
            this.concatenatedMetaData = videoDataFormat;
            var videoDataBlob = new Blob([videoDataFormat], { type: 'application/js' });
            this.concatMetaDataBlob = videoDataBlob;
            this.concatDataURL = URL.createObjectURL(videoDataBlob);
            var playerElementId = "sample-player";
            var videoSource = {
              type: "default",
              path: this.concatVideoURL,
              autoplay: false
            }
            if (this.isFrompreview) {
              this.player = new PSV_Meta(playerElementId, videoSource, this.concatDataURL, this.formatUserDataOutput(), this.ttsOptions, true);

              // await this.saveProject(playerElementId, videoSource, videoDataJSON);

              let domInterval = setInterval(() => {
                if (document.getElementById('sample-player')) {
                  clearInterval(domInterval);
                  this.setPSV(this.player);
                }
              }, 1)
            }
            if (!this.isNavigation) {
              await this.storageService.deleteConcatFiles(this.projectModel.id).then(() => { console.log('deleted') }).catch(err =>{this.showSnackBar("Something Went Wrong while Deleting","ok");this.saveLoaded=false;});
              await this.storageService.uploadconcatFiles(this.concatenatedVideoBlob, this.concatenatedMetaData, this.userData, this.projectModel.id, 'user1').then(async (_) => {
                this.showSnackBar('Files Saved', 'close');
                this.saveLoaded = false;
                this.publishLoader = false;
                videoSource.path = this.storageService.storageWebServerUrl + environment.azureContainerName + '/projects/' + this.projectModel.id + "/" + "TemplateVideo.mp4";
                let metaDataUrl = this.storageService.storageWebServerUrl + environment.azureContainerName + '/projects/' + this.projectModel.id + "/" + "PsvMetaData.js";
                await this.saveProject(playerElementId, videoSource, metaDataUrl).then(() => {
                  this.stateChanged = false;
                  resolve();
                });
              }).catch(err =>{this.showSnackBar("Something Went Wrong while Uploading","ok");this.saveLoaded=false;});
            }
            else {
              await this.storageService.deleteConcatFiles(this.projectModel.id).then(() => { console.log('deleted') }).catch(err =>{this.showSnackBar("Something Went Wrong while Deleting","ok");this.saveLoaded=false;});
              await this.storageService.uploadconcatFiles(this.concatenatedVideoBlob, this.concatenatedMetaData, this.userData, this.projectModel.id, Object.keys(this.userDataObj)[0]).then(async (_) => {
                this.showSnackBar('Files Saved', 'close');
                this.saveLoaded = false;
                this.publishLoader = false;
                videoSource.path = this.storageService.storageWebServerUrl + environment.azureContainerName + '/projects/' + this.projectModel.id + "/" + "TemplateVideo.mp4";
                let metaDataUrl = this.storageService.storageWebServerUrl + environment.azureContainerName + '/projects/' + this.projectModel.id + "/" + "PsvMetaData.js";
                await this.saveProject(playerElementId, videoSource, metaDataUrl).then(() => {
                  this.stateChanged = false;
                  resolve();
                });
              }).catch(err =>{this.showSnackBar("Something Went Wrong while Uploading","ok");this.saveLoaded=false;});
            }
          })
        })
      }
      }
      // no state changes in project
      else {
        if (this.isFrompreview) {
          this.isPreviewLoaded = true;
          this.fetchPSV(this.projectModel.concatenatedPSV).then(async (psvObj: any) => {
            let player = new PSV_Meta("sample-player", psvObj.videoSourceObj, psvObj.psvMetaDataUrl, this.formatUserDataOutput(), psvObj.ttsOptions, true);


            let domInterval = setInterval(() => {
              if (document.getElementById('sample-player')) {
                clearInterval(domInterval);
                this.setPSV(player);
              }
            }, 1);

            let videoDataPath = psvObj.psvMetaDataUrl;
            var videoMetaScript = document.createElement("script");
            videoMetaScript.src = videoDataPath;
            videoMetaScript.async = true;
            document.getElementsByTagName("script")[0].parentNode?.appendChild(videoMetaScript);
            videoMetaScript.addEventListener("load", () => {
              this.videoDatafile = JSON.parse(oxvideometa);
              this.projectModel.bgAudioVolume = this.videoDatafile.overlays[this.videoDatafile.overlays.length - 1]['volume'];
              this.saveLoaded = false;
              this.publishLoader = false;
              resolve();
            })
          })
        }
        else {
          this.isPreviewLoaded = true;
          this.fetchPSV(this.projectModel.concatenatedPSV).then(async (psvObj: any) => {

            let videoDataPath = psvObj.psvMetaDataUrl;
            var videoMetaScript = document.createElement("script");
            videoMetaScript.src = videoDataPath;
            videoMetaScript.async = true;
            document.getElementsByTagName("script")[0].parentNode?.appendChild(videoMetaScript);
            videoMetaScript.addEventListener("load", () => {
              this.videoDatafile = JSON.parse(oxvideometa);
              this.projectModel.bgAudioVolume = this.videoDatafile.overlays[this.videoDatafile.overlays.length - 1]['volume'];
              this.saveLoaded = false;
              this.publishLoader = false;
              resolve();
            })
          })
        }
      }

    })
  }


  async concatenateforBgAudio() {
    return new Promise(async (resolve: any, reject: any) => {
      this.videoDatafile.overlays[this.videoDatafile.overlays.length - 1]['volume']=this.projectModel.bgAudioVolume;
      var videoDataFormat = this.convertJsonToJS(this.videoDatafile);
      this.concatenatedMetaData = videoDataFormat;
      var videoDataBlob = new Blob([videoDataFormat], { type: 'application/js' });
      this.concatMetaDataBlob = videoDataBlob;
      this.concatDataURL = URL.createObjectURL(videoDataBlob);
      var playerElementId = "sample-player";
      var videoSource = {
        type: "default",
        path: this.storageService.storageWebServerUrl + environment.azureContainerName + '/projects/' + this.projectModel.id + "/" + "TemplateVideo.mp4",
        autoplay: false
      }
      if (this.isFrompreview) {
        this.player = new PSV_Meta(playerElementId, videoSource, this.concatDataURL, this.formatUserDataOutput(), this.ttsOptions, true);
        // await this.saveProject(playerElementId, videoSource, videoDataJSON);
        let domInterval = setInterval(() => {
          if (document.getElementById('sample-player')) {
            clearInterval(domInterval);
            this.removePSV(this.player);
            this.setPSV(this.player);
          }
        }, 1)
      }
      if (!this.isNavigation) {
        this.storageService.uploadFile(videoDataBlob, 'PsvMetaData.js', 'projects/' + this.projectModel.id).then(async (url: string) => {
          this.showSnackBar('Files Saved', 'close');
          this.saveLoaded = false;
          this.publishLoader = false;
          videoSource.path = url;
          await this.saveProject(playerElementId, videoSource, url).then(() => {
            this.stateChanged = false;
            resolve();
          });
        });
      }
    })
  }

  convertJsonToJS(data: any) {
    const formatVideoData = 'var oxvideometa = `' + JSON.stringify(data, null, 4) + '`';
    const obfuscatedDataStr2 = JavaScriptObfuscator.obfuscate(formatVideoData, { compact: true, selfDefending: false, deadCodeInjection: true });
    var videoDataFormat = obfuscatedDataStr2._obfuscatedCode;
    return videoDataFormat;
  }

  setBgAudioVolume(volumeLevel:any, bgAudio:any){
    this.bgAudioVolume = volumeLevel;
    bgAudio.volume = volumeLevel;
    this.bgAudioObj.volume = volumeLevel;
    this.projectModel.bgAudioVolume = volumeLevel;
    this.updateBgAudioVolumeForAllScenes(volumeLevel);
    this.removeAllPsv();
    this.reloadScenes()
    this.setAllPsv();
  }

  updateBgAudioVolumeForAllScenes(volume:number){
    for(let scene of this.scenes){
      scene.videoMeta.backgroundAudio.volume = volume;
    }
  }

  callOverlayAnnotationsOnStudio(videoSource: any, dataFromJson: any, updateAnnotation?: any) {
    return new psv.overlayAnnotationsOnStudio("overlay-player", videoSource, dataFromJson, this.formatUserDataOutput(), updateAnnotation).then((val: any) => {
      this.isLoaderEnabled = false;
      this.pauseVideo();
      if (document.getElementsByTagName("video")[0]) {
        setTimeout(() => { this.updateCurrentTime(document.getElementsByTagName("video")[0].currentTime) }, 600)

      }
    }).catch((error: any) => {
      this.errorMsg(error);
    });
  }

  notifyOnUpdateTemplate(message: string) {
    this.onUpdateTemplate.next(message);
  }


  async saveProject(playerElementId: any, videoSource: any, metaDataUrl: any) {
    let psvObj: any = {};

    return new Promise(async (resolve: any, reject: any) => {

      if (this.projectModel.concatenatedPSV) {
        await this.fetchPSV(this.projectModel.concatenatedPSV).then((psv: any) => {
          psvObj = this.createConcatenatedPSVObjForDB(psv.playerId, psv.videoSourceObj, psv.metaDataUrl, psv.ttsOptions, psv.userDatalist, psv._id)
        })
        psvObj['id'] = this.projectModel.concatenatedPSV;
        await this.updatePSV(psvObj).then(async (psvId: any) => {
          this.projectModel.concatenatedPSV = psvId;
          this.projectModel.lastModifiedDate = new Date();
          await this.updateProject(this.projectModel).then(() => {
            resolve();
          });
        })
      }
      else {
        psvObj = this.createConcatenatedPSVObjForDB(playerElementId, videoSource, metaDataUrl, this.ttsOptions, { 'user1': this.userDataId });
        await this.storePSV(psvObj).then(async (psvId: any) => {
          this.projectModel.concatenatedPSV = psvId;
          this.projectModel.lastModifiedDate = new Date();
          await this.updateProject(this.projectModel).then(() => {
            resolve();
          });
        })
      }
    })

  }
  notifyOnUpdateData(message: string) {
    this.OnUpdateData.next(message);
  }

  updateCurrentTime(currentTime: number) {
    this.currentPlayerTime.next(currentTime);
  }

  notifyAnnotationUpdate(message: string) {
    this.annotationUpdateNotify.next(message);
  }

  notifyVideoLoadedUpdate(message: string) {
    this.videoLoadedNotify.next(message);
  }

  notifyTTSUpdate(message: string) {
    this.ttsUpdateNotify.next(message);
  }

  updateVideoPlayStatus(isPlaying: boolean) {
    this.videoPlaying.next(isPlaying);
  }

  notifyVideoMetaDataLoaded(isLoaded: boolean) {
    this.notifyOnVideoMetaDataLoadedSubject.next(isLoaded);
  }

  notifyAnnotationSwitch(message: string) {
    this.annotationSwitchNotify.next(message);
  }

  notifyDurationChange(message: string) {
    this.annotationSwitchNotify.next(message);
  }

  updateStartTime(timeStamp: string) {
    this.startTimeUpdate.next(timeStamp);
  }

  updateEndTime(timeStamp: string) {
    this.endTimeUpdate.next(timeStamp);
    //To be removed after script component integrated
  }

  notifyDurationChangeInToolbar(message: string) {
    this.notifyDurationChangeInToolbarSubject.next(message);
  }

  notifyOnPublishClick(message: string) {
    this.notifyPublishClickSubject.next(message);
  }

  notifyOnAllSceneSelected(message: string) {
    this.notifyAllSceneSelectedSubject.next(message);
  }

  notifyOnSceneSelected(message: string) {
    this.notifySceneSelectedSubject.next(message);
  }

  notifyOnAnnotationToggle(index: number) {
    this.notifyAnnotationToggleSubject.next(index);
  }

  notifyOnCreateVideo(message: string) {
    this.notifyCreateVideoSubject.next(message);
  }

  notifyOnGenerateUrl(message: string) {
    this.notifyGenerateUrlSubject.next(message);
  }

  loadElement(id: any, index: any) {
    if (this.annotationTableData[index].type === 'tts') {
      this.updateAudio = true;
      this.overlayAnnotations(this.overlayJSON.audios[id]);
      this.setDraggerPosition();
    }
    else {
      this.overlayAnnotations();
      this.setDraggerPosition();
    }
  }
  fontSize: any;
  setAnnotationTableData(newOverlayObject: any) {
    const annotationObject: any = {};
    annotationObject.id = newOverlayObject.id;
    annotationObject.durationWidth = 100;
    annotationObject.startPoint = 0;
    annotationObject.cssProperties = newOverlayObject.cssProperties;
    annotationObject.type = newOverlayObject.type;
    this.annotationType = annotationObject.type;
    if(newOverlayObject.type != 'caption'){
    switch (newOverlayObject.type) {
      case 'text': {
        annotationObject.icon = "title";
        annotationObject.value = newOverlayObject.value;
        break;
      }
      case 'image': {
        annotationObject.icon = "image";
        if (newOverlayObject.imageName) {
          annotationObject.value = newOverlayObject.imageName;
        } else if (newOverlayObject.srcUrl === "") {
          annotationObject.value = "image" + annotationObject.id;
        } else {
          let url = newOverlayObject.srcUrl.split('/');
          annotationObject.value = url[url.length - 1];
        }
        break;
      }
      case 'link': {
        annotationObject.value = newOverlayObject.message;
        annotationObject.icon = "touch_app";
        break;
      }
      case 'video': {
        annotationObject.icon = "video_file";
        if (newOverlayObject.videoName) {
          annotationObject.value = newOverlayObject.videoName;
        } else if (newOverlayObject.srcUrl === "") {
          annotationObject.value = "video" + annotationObject.id;
        } else {
          let url = newOverlayObject.srcUrl.split('/');
          annotationObject.value = url[url.length - 1];
        }
        break;
      }
      case 'livevideo': {
        annotationObject.icon = "radio_button_checked";
        if (newOverlayObject.videoName) {
          annotationObject.value = newOverlayObject.videoName;
        } else {
          annotationObject.value = "LiveVideo" + annotationObject.id;
        }
        // } else {
        //   annotationObject.value = newOverlayObject.srcUrl;
        // }
        break;
      }
      case 'liveAudio': {
        annotationObject.icon = "keyboard_voice";
        if (newOverlayObject.audioName) {
          annotationObject.value = newOverlayObject.audioName;
        } else {
          annotationObject.value = "LiveAudio" + annotationObject.id;
        }
        break;
      }
      case 'audio': {
        annotationObject.icon = "audio_file";
        if (newOverlayObject.audioName) {
          annotationObject.value = newOverlayObject.audioName;
        } else if (newOverlayObject.srcUrl === "") {
          annotationObject.value = "audio-" + annotationObject.id;
        } else {
          let url = newOverlayObject.srcUrl.split('/');
          annotationObject.value = url[url.length - 1];
        }
        break;
      }
      case 'tel': {
        annotationObject.value = newOverlayObject.message;
        annotationObject.icon = "call";
        break;
      }
      case 'mail': {
        annotationObject.value = newOverlayObject.message;
        annotationObject.icon = "email";
        break;
      }
      case 'section': {
        annotationObject.value = newOverlayObject.message;
        annotationObject.icon = "link";
        break;
      }
      case 'chart': {
        annotationObject.icon = "poll";
        annotationObject.value = newOverlayObject.data.chartType;
        break;
      }
      case 'tts': {
        annotationObject.icon = "record_voice_over";
        if (this.tts === "polly") {
          annotationObject.value = newOverlayObject.params.Text ? newOverlayObject.params.Text : "audio" + newOverlayObject.id;
        } else if (this.tts === "google") {
          annotationObject.value = newOverlayObject.params.input.ssml ? newOverlayObject.params.input.ssml : "audio" + newOverlayObject.id;
        } else if (this.tts === "azure") {
          annotationObject.value = newOverlayObject.params.text ? newOverlayObject.params.text : "audio" + newOverlayObject.id;
        }
        break;
      }
      case 'progressbar': {
        annotationObject.icon = "poll";
        annotationObject.value = newOverlayObject.value;
        break;
      }
      case 'webpage': {
        annotationObject.icon = "language";
        if(newOverlayObject.value){
          annotationObject.value = newOverlayObject.value;
        }
        else{
          if(newOverlayObject.src){
            annotationObject.value = newOverlayObject.src
          }
          else{
            annotationObject.value = "Your URL";
          }
        }
        break;
      }
      case 'file': {
        annotationObject.icon = "insert_drive_file";
        if(newOverlayObject.value){
          annotationObject.value = newOverlayObject.value;
        }
        else{
          if(newOverlayObject.src){
            let url = newOverlayObject.src.split('/');
            annotationObject.value = url[url.length - 1];
          }
          else{
            annotationObject.value = "Your URL";
          }
        }
        break;
      }
    }
    this.annotationTableData.push(annotationObject);
    this.notifyAnnotationUpdate("");
  }
  else{
    annotationObject.icon = "closed_caption";
    annotationObject.value = newOverlayObject.value;
    annotationObject.Start = {min: "00", sec: "00", msec: "001", valid: true };
    annotationObject.End = {min: "00", sec: "06", msec: "001", valid: true };
    annotationObject.size = { value: 70, valid: true };
    annotationObject.caption_text = "Demo caption";
    annotationObject.start = 0.001;
    annotationObject.end  = 6.001;
    //annotationObject.isDurationChanged = true;
    this.scenes[this.selectedSceneIndex].caption_annotationTableData.push(annotationObject);
    this.notifyAnnotationUpdate("");
    if(this.scenes[this.selectedSceneIndex].caption_annotationTableData){
      this.isToolBarOpen = true;
      this.activeTool = 'caption';
    }
  }
}

  getAnnotationTableId(overlayObj: any): string {
    for (let index in this.annotationTableData) {
      if (this.annotationTableData[index].type === "tts" && overlayObj.type === "tts" && overlayObj.id === this.annotationTableData[index].id) {
        return index;
      } else if (this.annotationTableData[index].type !== "tts" && overlayObj.type !== "tts" && overlayObj.id === this.annotationTableData[index].id) {
        return index;
      }
    }
    return '';
  }

  getCaptionTableId(overlayObj: any): string {
    var captionTableData:any;
    captionTableData = this.scenes[this.selectedSceneIndex].caption_annotationTableData;
    for (let index in captionTableData) {
      if (captionTableData[index].type === "tts" && overlayObj.type === "tts" && overlayObj.id === captionTableData[index].id) {
        return index;
      } else if (captionTableData[index].type !== "tts" && overlayObj.type !== "tts" && overlayObj.id === captionTableData[index].id) {
        return index;
      }
    }
    return '';
  }

  showSnackBar(message: any, classList: any) {
    const config = new MatSnackBarConfig();
    config.horizontalPosition = "right";
    config.verticalPosition = "bottom";
    config.panelClass = classList;
    config.duration = 3000;
    this.snackBar.open(message, 'Ok', config);
  }

  showAnnotationSnackBar(message: string) {
    const config = new MatSnackBarConfig();
    config.duration = 1500;
    config.verticalPosition = "top";
    config.horizontalPosition = "left";
    config.panelClass = ["annotation-added-card"];
    this.snackBar.open(message, undefined, config);
  }

  showClipboardSnackBar(){
    const config = new MatSnackBarConfig();
    config.duration = 1000;
    config.verticalPosition = "bottom";
    config.horizontalPosition = "center";
    config.panelClass = ["clipboard-snackbar"];
    this.snackBar.open("Copied to Clipboard", undefined, config);
  }

  replaceNestedDataValue(value: any) {
    const nestedData = psv.replaceVariablesWithValues(value, this.userData);
    return nestedData === "User Data not found" ? "" : nestedData;
  }

  isEmptyFieldsPresentInUserData(userData: any) {
    for (let key of Object.keys(userData)) {
      if (typeof userData[key] === "string") {
        if (userData[key] === " " || userData[key] === "") {
          return true;
        }
      }
      else {
        if (this.isEmptyFieldsPresentInUserData(userData[key])) {
          return true;
        }
      }
    }
    return false;
  }

  async overlayAnnotations(updateAnnotation?: any) {
    const dataFromJson = this.formatVideoOutputJson();
    this.player = videojs('#psv-overlay-player');
    let videoSource = {
      type: "default",
      path: this.videoSrc,
      autoplay: false
    }
    if (this.updateAudio) {
      this.updateAudio = false;

      await psv.addDynamicAudioToVideo("overlay-player", videoSource, dataFromJson, this.formatUserDataOutput(), this.ttsOptions).then(() => {
      }).catch((err: any) =>{
        environment.isTtsAvailable = false;
      })

      this.callOverlayAnnotationsOnStudio(videoSource, dataFromJson, updateAnnotation);
    }
    else {
      this.callOverlayAnnotationsOnStudio(videoSource, dataFromJson, updateAnnotation);
    }
    if (this.isSceneSelected) {
      let annotationElements = document.getElementsByClassName("custom-overlays") as HTMLCollectionOf<HTMLDivElement>;
      for (let i = 0; i < annotationElements.length; i++) {
        annotationElements[i].addEventListener('click', ($event: any) => {
          this.notifyOnAnnotationToggle(parseInt(annotationElements[i].id));
        })
      }
    }
  }

  errorMsg(message: any) {
    // this.showSnackBar(message, this.errorClassList);
  }

  async updateSceneAudio(sceneId: number) {

  }

  getUserDataJson(userJsonFileUrl: any): Observable<any> {
    return this.http.get(userJsonFileUrl).pipe(
      catchError(this.errorHandler)
    );
  }

  getVideoDataJson(videoJsonFileUrl: any): Observable<any> {
    return this.http.get(videoJsonFileUrl).pipe(
      catchError(this.errorHandler)
    );
  }

  getCaptionData(captionFileUrl: any): Promise<any> {
    return this.http.get(captionFileUrl, { responseType: 'blob', headers: new HttpHeaders({ 'Content-Type': 'text/vtt' }) }).toPromise().catch(this.errorHandler);
  }

  getAnimationList(): Observable<any> {
    return this.http.get("assets/data/animations.json").pipe(
      catchError(this.errorHandler)
    );
  }

  getPollyVoicesList(): Observable<any> {
    return this.http.get("assets/data/polly_voices.json").pipe(
      catchError(this.errorHandler)
    );
  }

  getGoogleVoicesList(): Observable<any> {
    return this.http.get("assets/data/google_voices.json").pipe(
      catchError(this.errorHandler)
    );
  }

  getAzureVoicesList(): Observable<any> {
    return this.http.get("assets/data/azure_voices.json").pipe(
      catchError(this.errorHandler)
    );
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message)
  }

  pauseVideo() {
    this.isVideoPlaying = false;
  }

  resizerWidth : any;
  resizerHeight : any;

  setDraggerPosition() {
    if (this.selectedAnnotation.type !== "tts" && document.querySelectorAll("#psv-overlay-player > div.vjs-overlay")[0]) {
      const fontSize = parseFloat((document.querySelectorAll("#psv-overlay-player > div.vjs-overlay")[0] as HTMLElement).style.fontSize.split("px")[0]);
      const videoWidth = document.getElementsByTagName("video")[0].offsetWidth;
      const videoHeight = document.getElementsByTagName("video")[0].offsetHeight;
      const annotationFontSize = parseFloat(this.selectedAnnotation.cssProperties.fontSize.split("em"));
      switch (this.selectedAnnotation.type) {
        case 'image':{
          let imgWd = parseFloat(this.selectedAnnotation.cssProperties.width);
          this.resizerWidth= imgWd * 30 +"px";
          let imgHt = parseFloat(this.selectedAnnotation.cssProperties.height);
          this.resizerHeight= imgHt * 30 +"px";
          break;
        }
        case 'video':{
          let videoWd = parseFloat(this.selectedAnnotation.cssProperties.width);
          this.resizerWidth= videoWd * 30 +"px";
          let videoHt = parseFloat(this.selectedAnnotation.cssProperties.height);
          this.resizerHeight= videoHt * 30 +"px";
          break;
        }
        case 'livevideo':{
          let videoWd = parseFloat(this.selectedAnnotation.cssProperties.width);
          this.resizerWidth= videoWd * 30 +"px";
          let videoHt = parseFloat(this.selectedAnnotation.cssProperties.height);
          this.resizerHeight= videoHt * 30 +"px";
          break;
        }
        case 'audio':
        case 'liveAudio':
        case 'chart': {
          let chartWd = parseFloat(this.selectedAnnotation.cssProperties.width);
          this.resizerWidth= chartWd * 30 +"px";
          let chartHt = parseFloat(this.selectedAnnotation.cssProperties.height);
          this.resizerHeight= chartHt * 30 +"px";
          break;
        }
        case 'progressbar': {
          this.annotationHeight = (parseFloat(this.selectedAnnotation.cssProperties.height.split("em")) * fontSize) + 'px';
          this.annotationWidth = (parseFloat(this.selectedAnnotation.cssProperties.width.split("em")) * fontSize) + 'px';
          break;
        }
        case 'text': {
          this.annotationHeight = annotationFontSize * fontSize + 'px';
          this.annotationWidth = (this.selectedAnnotation.value.length * annotationFontSize * fontSize) / 2 + 'px';
          break;
        }
        case 'caption': {
          this.annotationHeight = annotationFontSize * fontSize + 'px';
          this.annotationWidth = (this.selectedAnnotation.value.length * annotationFontSize * fontSize) / 2 + 'px';
          break;
        }
        case 'link':
        case 'tel':
        case 'mail': {
          this.annotationHeight = annotationFontSize * fontSize + 'px';
          this.annotationWidth = (this.selectedAnnotation.message.length * annotationFontSize * fontSize) / 2 + 'px';
          break;
        }
        case 'webpage': {
          this.annotationHeight = (parseFloat(this.selectedAnnotation.cssProperties.height.split("em")) * fontSize) + 'px';
          this.annotationWidth = (parseFloat(this.selectedAnnotation.cssProperties.width.split("em")) * fontSize) + 'px';
          break;
        }
        case 'file': {
          this.annotationHeight = (parseFloat(this.selectedAnnotation.cssProperties.height.split("em")) * fontSize) + 'px';
          this.annotationWidth = (parseFloat(this.selectedAnnotation.cssProperties.width.split("em")) * fontSize) + 'px';
          break;
        }
      }
      if(this.selectedAnnotation.type !=="audio" && this.selectedAnnotation.type !=="liveAudio"){
        if (parseInt(this.annotationHeight.split("px")[0]) > videoHeight) {
          this.annotationHeight = `${videoHeight}px`
        }
        if (parseInt(this.annotationWidth.split("px")[0]) > videoWidth) {
          this.annotationWidth = `${videoWidth}px`
        }
      }
    }
  }

  transformAudioObjectsToArray() {
    const tempAudiosArray = [];
    for (const audioObject of this.overlayJSON.audios) {
      const transformedObject = Object.assign({}, audioObject.getAttributes(this.tts));
      tempAudiosArray.push(transformedObject);
    }
    return tempAudiosArray;
  }

  transformBackgroundAudioObject() {
    const transformedObject = Object.assign({}, this.bgAudioObj?.getAttributes());
      transformedObject.volume = this.bgAudioObj.volume? this.bgAudioObj.volume : 1.0;
    return transformedObject;
  }

  transformOverlayObjectsToArray() {
    var tempOverlaysArray = [];
    for (const overlayObject of this.overlayJSON.overlays) {
      const transformedObject = Object.assign({}, overlayObject.getAttributes());
      const transformedObjectData = Object.assign({}, transformedObject.data);
      if (transformedObject.type === 'chart') {
        transformedObject.chartData = undefined;
        if (transformedObject.chartLibrary === "chartjs") {
          transformedObjectData.options.backgroundColor = transformedObjectData.transformedData.datasets[0].backgroundColor;
        }
        transformedObjectData.transformedData = undefined;
        delete transformedObjectData.chartElement;
        delete transformedObjectData.chart;
        transformedObject.data = Object.assign({}, transformedObjectData);
      }
      //  if (transformedObject.type === "section" && this.seekTo) {

      //    transformedObject.seekTo = this.seekTo;
      //  }
      tempOverlaysArray.push(transformedObject);
    }
    if (this.isSectionAvailable == true) {
      this.finalSeekArray = JSON.parse(JSON.stringify(this.allSection));
      this.finalSeekArray.forEach((element: any, index: any) => {
        element.id = this.overlayJSON.overlays.length + index + 1;
        delete element["time"];
      });
      tempOverlaysArray = tempOverlaysArray.concat(this.finalSeekArray);
    }
    return tempOverlaysArray;
  }

  formatUserDataOutput() {
    const outputUserData = this.userData;
    for (const key in outputUserData) {
      if (outputUserData[key].data) {
        delete outputUserData[key];
      }
      if (outputUserData[key] && (outputUserData[key].toString().replace(/\s/g, "") == "true" || outputUserData[key].toString().replace(/\s/g, "") == "false")) {
        outputUserData[key] = JSON.parse(outputUserData[key]);
      }
    }
    for (const overlayObject of this.overlayJSON.overlays) {
      const transformedObject = overlayObject.getAttributes();
      if (transformedObject.type === 'chart') {
        outputUserData[transformedObject.chartKey] = transformedObject.chartData;
      }
    }
    return outputUserData;
  }

  detectIE() {
    const user_agent = window.navigator.userAgent;

    const msie = user_agent.indexOf('MSIE ');
    if (msie > 0) {

      // Internet Explorer 10 or some older version  => return version number
      return parseInt(user_agent.substring(msie + 5, user_agent.indexOf('.', msie)), 10);
    }

    const trident = user_agent.indexOf('Trident/');
    if (trident > 0) {
      // Internet Explorer 11 => return version number
      const rv = user_agent.indexOf('rv:');
      return parseInt(user_agent.substring(rv + 3, user_agent.indexOf('.', rv)), 10);
    }

    const edge = user_agent.indexOf('Edge/');
    if (edge > 0) {
      // Edge (Innternet Explorer 12+) => return version number
      return parseInt(user_agent.substring(edge + 5, user_agent.indexOf('.', edge)), 10);
    }

    // some other browser
    return false;
  }

  //From here code is copied from uploader.component.ts to make use of it when studio designer page
  // is called from gallery
  importAllFiles(videoInputs: any) {
    let videoJsonData;
    if (videoInputs.videoMetaFileType === "application/javascript" || videoInputs.videoMetaFileType === "text/javascript") {

      const videoMetaScript = document.createElement("script");
      videoMetaScript.addEventListener("load", function () {
        //video meta data loaded
      });
      videoMetaScript.addEventListener("error", function () {
        //showErrorMsg
      });
      videoMetaScript.src = videoInputs.videoMetaDataUrl;
      videoMetaScript.async = true;
      //  document.getElementsByTagName("script")[0].parentNode.appendChild(videoMetaScript);
    } else if (videoInputs.videoMetaFileType === "application/json") {
      this.getVideoDataJson(videoInputs.videoMetaDataUrl)
        .subscribe(videoJsonData => {
          //  this.importUserJsonFile(videoInputs, videoJsonData);
        },
          () => {
            this.videoUploadErrorMsg();
          });
    }
    const inputDataFlag = setInterval(() => {
      try {
        if ((<any>window).oxvideometa && JSON.parse((<any>window).oxvideometa)) {
          videoJsonData = JSON.parse((<any>window).oxvideometa);
          clearInterval(inputDataFlag);
          //  this.importUserJsonFile(videoInputs, videoJsonData);
        }
      } catch (e) {
        clearInterval(inputDataFlag);
        this.videoUploadErrorMsg();
      }
    }, 10);
  }

  userDataErrorMsg() {
    const message = 'Invalid User Data File. Please Upload again.';
    this.showSnackBar(message, this.errorClassList);
  }

  videoUploadErrorMsg() {
    const message = 'Invalid Video Configuration File. Please Upload again.';
    this.showSnackBar(message, this.errorClassList);
  }

  setUpVideo(videoInputs: any, videoJsonData: any, userJsonData: any) {
    if (videoJsonData.overlays || videoJsonData.audios || videoJsonData.backgroundAudio) {
      this.videoSrc = videoInputs.videoFileUrl;
      this.posterUrl = videoJsonData["poster"];
      this.isSrcVideoAvailable = true;
      this.isTimelineLoaded = true;
      this.videoName = videoJsonData["video-name"];
      this.userData = userJsonData;
      if (this.videoSize === "NA" && videoJsonData["video-size"]) {
        this.videoSize = videoJsonData["video-size"];
      }
      this.transformOverlayObject(videoJsonData);
      this.selectedAnnotation = this.overlayJSON.overlays.length > 0 ? this.overlayJSON.overlays[0] : this.overlayJSON.audios[0];
      this.selectedDatasourceId = 0;

      setTimeout(() => {
        if (this.overlayJSON.overlays.length != 0 || this.overlayJSON.audios.length != 0) {
          this.notifyAnnotationSwitch("notified");
        }
      }, 10);
      if (this.overlayJSON.overlays.length === 0 && this.overlayJSON.audios.length === 0) {
        this.videoSize = videoJsonData["video-size"];
        this.updateCurrentTime(0.1);
      } else {
        if (this.selectedAnnotation.start === 0) {
          this.updateCurrentTime(0.1);
        } else {
          this.updateCurrentTime(this.selectedAnnotation.start);
        }
        this.isToolBarOpen = true;
        if (this.selectedAnnotation.type === "tts") {
          this.activeTool = this.overlayJSON.audios[0].type;
        } else {
          this.activeTool = this.overlayJSON.overlays[0].type;
        }
      }
    } else {
      this.videoUploadErrorMsg();
    }
    this.router.navigate(['editor']);
  }

  transformOverlayObject(videoData: { skippable_sections: never[]; captions:any, overlays: any; backgroundAudio:any; audios: any; tts: string; captionTableData:any}) {
    let videoMeta: any = {
      "overlays": [],
      "audios": [],
      "captions": {},
      "backgroundAudio":{},
      "captionTableData": []
    };

    if(videoData.captions && videoData.captions['is-available']){
      videoMeta.captions = videoData.captions;
    }
    if (videoData.skippable_sections) {
      this.isSkipSectionAvailable = true;
      this.skipSectionAll = videoData.skippable_sections;
    }
    for (const objectType of videoData.overlays) {
      if (objectType.type == "section") {
        this.allSection.pop();
        this.isSectionAvailable = false;
      }
    }
    for (const overlayObject of videoData.overlays) {
      let defaultCssProps = new CssProperties();
      let cssProps = new CssProperties();
      if(overlayObject.type !== "audio" ){
        cssProps = defaultCssProps.transformCssProperties(overlayObject["css-properties"], overlayObject.type);
      }
      switch (overlayObject.type) {
          case 'text': {
            const overlayAnnotationObject = new TextAnnotation(overlayObject.id, overlayObject.start, overlayObject.end, defaultCssProps);
            const textObject = overlayAnnotationObject.transformAttributes(overlayObject, cssProps);
            videoMeta.overlays.push(textObject);
            // this.overlayJSON.overlays.push(textObject);
            // this.setAnnotationTableData(textObject);
            break;
          }
          case 'image': {
            const overlayAnnotationObject = new ImageAnnotation(overlayObject.id, overlayObject.start, overlayObject.end, defaultCssProps);
            const imageObject = overlayAnnotationObject.transformAttributes(overlayObject, cssProps);
            videoMeta.overlays.push(imageObject);
            break;
          }
          case 'link':{
            const overlayAnnotationObject = new LinkAnnotation(overlayObject.id, overlayObject.start, overlayObject.end, defaultCssProps);
            const linkObject = overlayAnnotationObject.transformAttributes(overlayObject, cssProps);
            videoMeta.overlays.push(linkObject);
            break;
          }
          case 'tel':{
            const overlayAnnotationObject = new LinkAnnotation(overlayObject.id, overlayObject.start, overlayObject.end, defaultCssProps);
            let linkObject = overlayAnnotationObject.transformAttributes(overlayObject, cssProps);
            linkObject.type = 'tel';
            videoMeta.overlays.push(linkObject);
            break;
          }
          case 'mail':{
            const overlayAnnotationObject = new LinkAnnotation(overlayObject.id, overlayObject.start, overlayObject.end, defaultCssProps);
            let linkObject = overlayAnnotationObject.transformAttributes(overlayObject, cssProps);
            linkObject.type = 'mail';
            linkObject.email = overlayObject.email;
            videoMeta.overlays.push(linkObject);
            break;
          }
          case 'section':{
            const overlayAnnotationObject = new LinkAnnotation(overlayObject.id, overlayObject.start, overlayObject.end, defaultCssProps);
            let linkObject = overlayAnnotationObject.transformAttributes(overlayObject, cssProps);
            linkObject.type = 'section';
            videoMeta.overlays.push(linkObject);
            break;
          }
          case 'video':{
            const overlayAnnotationObject = new VideoAnnotation(overlayObject.id, overlayObject.start, overlayObject.end, defaultCssProps);
            const videoObject = overlayAnnotationObject.transformAttributes(overlayObject, cssProps);
            videoMeta.overlays.push(videoObject);
            break;
          }
          case 'livevideo':{
            const overlayAnnotationObject = new VideoAnnotation(overlayObject.id, overlayObject.start, overlayObject.end, defaultCssProps);
            let liveVideoObject = overlayAnnotationObject.transformAttributes(overlayObject, cssProps);
            liveVideoObject.type = "livevideo";
            videoMeta.overlays.push(liveVideoObject);
            break;
          }
          case 'liveAudio':{
            const overlayAnnotationObject = new AudioOverlayAnnotation(overlayObject.id, overlayObject.start, overlayObject.end, defaultCssProps);
            let liveAudioObject = overlayAnnotationObject.transformAttributes(overlayObject, cssProps);
            liveAudioObject.type = "liveAudio";
            videoMeta.overlays.push(liveAudioObject);
            break;
          }
          case 'audio':{
            const overlayAnnotationObject = new AudioOverlayAnnotation(overlayObject.id, overlayObject.start, overlayObject.end, defaultCssProps);
            const audioObject = overlayAnnotationObject.transformAttributes(overlayObject, cssProps);
            videoMeta.overlays.push(audioObject);
            break;
          }
          case 'webpage':{
            const overlayAnnotationObject = new WebpageAnnotation(overlayObject.id, overlayObject.start, overlayObject.end, defaultCssProps);
            const webpageObject = overlayAnnotationObject.transformAttributes(overlayObject, cssProps);
            videoMeta.overlays.push(webpageObject);
            break;
          }
          case 'file':{
            const overlayAnnotationObject = new FileAnnotation(overlayObject.id, overlayObject.start, overlayObject.end, defaultCssProps);
            const fileObject = overlayAnnotationObject.transformAttributes(overlayObject, cssProps);
            videoMeta.overlays.push(fileObject);
            break;
          }
          case 'chart': {
            const overlayAnnotationObject = new ChartAnnotation(overlayObject.id, overlayObject.start, overlayObject.end, cssProps);
            overlayObject.chartData = Object.assign({}, this.userData[overlayObject.chartKey]);
            const chartObject = overlayAnnotationObject.transformAttributes(overlayObject, cssProps);
            videoMeta.overlays.push(chartObject);
            this.setAnnotationTableData(chartObject);
            break;
          }


        }
    }
    if(videoData.backgroundAudio){
      let defaultCssProps = new CssProperties();
      let cssProps = new CssProperties();
      const overlayAnnotationObject = new AudioOverlayAnnotation(videoData.backgroundAudio.id, videoData.backgroundAudio.start, videoData.backgroundAudio.end, defaultCssProps);
      const audioObject = overlayAnnotationObject.transformAttributes(videoData.backgroundAudio, cssProps);
      videoMeta.backgroundAudio = audioObject;
    }
    else{
      videoMeta.backgroundAudio = undefined;
    }
    if (videoData.audios) {
      this.tts = videoData.tts ? videoData.tts : "polly";
      let idCount = 1;
      for (const overlayAudioObject of videoData.audios) {
        if (!overlayAudioObject.id) {
          overlayAudioObject.id = idCount;
          idCount++;
        }
        const overlayAnnotationObject = new AudioAnnotation(overlayAudioObject.id, overlayAudioObject.start, overlayAudioObject.end, this.tts);
        const audioObject = overlayAnnotationObject.transformAttributes(overlayAudioObject, this.tts);
        videoMeta.audios.push(audioObject);
        //  this.overlayJSON.audios.push(audioObject);
        //  this.setAnnotationTableData(audioObject);
      }
    }
    if(videoData.captionTableData){
      videoMeta.captionTableData = videoData.captionTableData;
    }
    this.flagFilesImported = true;
    return videoMeta;
  }

  async loadCaptionConfig(captionUrl: string) {
    let captionData;
    this.enableTranscript = true;
    this.isCaptionAvailable = true;
    try {
      captionData = await this.getCaptionData(captionUrl)
    } catch (err) {
      this.enableTranscript = false;
      this.isCaptionAvailable = false;
      this.showSnackBar("No caption available at this moment", this.errorClassList)
    }
    //  this.captionBlob = new Blob([captionData], { type: 'text/vtt' });
  }

  calTime(...time:any[]){
    var finalTimeArray:any = [];
    for (let i = 0; i < 2; i++) {
      var t = time[i]
      const strTime = t.split('.')
      var min:any, sec:any, ms:any;
      if(strTime.length < 2){
        ms = "000"
      }
      else{
        ms = strTime[1];
        ms = ms.substring(0, 3)
      }
      if(ms.length == 1){
        ms = ms + "00"
      }
      if(ms.length ==2){
        ms = ms + "0"
      }
      min = t / 60;
      min = Math.trunc(min);
      var smin = String(min)
      if(smin.length < 2){
        smin = "0" + smin
      }
     if(t >= (min*60)){
        t = t - min*60;
      }

      sec = t;
      sec = Math.trunc(sec)
      var ssec = String(sec)
      if(ssec.length < 2){
        ssec = "0" + ssec
      }
      var finalTime:any = smin + ":" + ssec + "." + ms;
      finalTimeArray.push(finalTime)
    }
    return finalTimeArray
  }

  removePlayer() {
    if (document.getElementById("psv-overlay-player_html5_api")) {
      const videoSource = {
        type: "default",
        path: this.videoSrc,
        autoplay: false
      };
      new psv.removePlayer("overlay-player", videoSource);
    }
  }


  removeMetaDataScriptTag(srcUrl: string) {
    let scripts = document.getElementsByTagName("script");
    Array.from(scripts).forEach((script, index) => {
      if (script.src === srcUrl) {
        script.remove();
      }
    });
    (<any>window).oxvideometa = undefined;
  }


  callAudioOverlayAnnotationsOnStudio(id: any, videoSource: any, dataFromJson: any, updateAnnotation?: any) {
    return new psv.overlayAnnotationsOnStudio(id, videoSource, dataFromJson, this.formatUserDataOutput(), updateAnnotation).then((val: any) => {
      this.isLoaderEnabled = false;
      this.pauseVideo();
      if (document.getElementsByTagName("video")[0]) {
        setTimeout(() => { this.updateCurrentTime(document.getElementsByTagName("video")[0].currentTime) }, 600)

      }
    }).catch((err: any) => {
      this.errorMsg(err);
    });
  }
  formatVideoOutputJson() {
    return {
      "video-name": this.videoName,
      "isDashAvailable": this.isDashAvailable,
      "videos": [
        {
          "video-url": "videos/mpd/h264.mpd",
          "video-type": "application/dash+xml"
        },
        {
          "video-url": "videos/h264.mp4",
          "video-type": "video/mp4"
        },
        {
          "video-url": "videos/vp9.webm",
          "video-type": "video/webm"
        }
      ],
      "poster": this.posterUrl,
      "captions": this.scenes[this.selectedSceneIndex]?
      this.scenes[this.selectedSceneIndex].videoMeta.captions :
      {"is-available": this.isCaptionAvailable,
      "enable-transcript": this.enableTranscript,
      "src": "",
      "kind": "captions",
      "srclang": "en",
      "label": "English"},
      "video-aspect-ratio": {

        "width": this.aspectRatio[0],
        "height": this.aspectRatio[1]
      },
      "video-size": this.videoSize,
      "tts": this.tts,
      "audioCache": false,
      "overlays": this.transformOverlayObjectsToArray(),
      "backgroundAudio":this.bgAudioObj ? this.transformBackgroundAudioObject() : {},
      "audios": this.transformAudioObjectsToArray(),
      "skippable_sections": this.formatSkippableData(),
      "captionTableData": this.scenes[this.selectedSceneIndex]?this.scenes[this.selectedSceneIndex].videoMeta.captionTableData:[]
    }

  }

  formatSkippableData() {

    if (this.isSkipSectionAvailable) {
      this.finalSkipArray = JSON.parse(JSON.stringify(this.skipSectionAll));
      this.finalSkipArray.forEach((element: any, index: number) => {
        delete element["timeFrom"];
        delete element["timeTo"];
      });

    }
    return this.finalSkipArray;
  }

  // skipSectionAll(skipSectionAll: any): string {
  //   throw new Error('Method not implemented.');
  // }


  // allSection(allSection: any): string {
  //   throw new Error('Method not implemented.');
  // }

  async overlayAudioAnnotations(scene: SceneModel, updateAnnotation?: any) {
    const dataFromJson = this.formatVideoOutputJson();
    this.player = videojs('#psv-' + scene.id);
    let videoSource = {
      type: "default",
      path: scene.videoPath,
      autoplay: false
    }
    if (this.updateAudio) {
      this.updateAudio = false;

      await psv.addDynamicAudioToVideo(scene.id, videoSource, dataFromJson, this.formatUserDataOutput(), this.ttsOptions).then(() => {
      }).catch((err: any) => {
        this.errorMsg(err);
      })
      dataFromJson.captions = scene.psvModel.psvMetaData.captions;
      this.callAudioOverlayAnnotationsOnStudio(scene.id, videoSource, dataFromJson, updateAnnotation);
    }
    else {
      this.callAudioOverlayAnnotationsOnStudio(scene.id, videoSource, dataFromJson, updateAnnotation)
    }
  }

  setPSV(metaData: PSV_Meta) {
    return new psv.setPSVMetaAsJS(metaData.playerElementId, metaData.videoSource, metaData.psvMetaData, metaData.userData, metaData.ttsOptions).then(() => {

    }).catch((err: any) => {
      this.errorMsg(err);
    });
  }

  // getUserDataJson(userJsonFileUrl:any): Observable<any> {
  //   return this.http.get(userJsonFileUrl).pipe(
  //     catchError(this.errorHandler)
  //   );
  // }

  getUrlRequest(apiUrl: string) {
    return this.http.get<any>(apiUrl, { observe: "response" });
  }

  openGallery(type?:any) {
    let imgheight = 10;
    let imgwidth = 5;
    let selectedMedia:any;

    let dialogRef = this.dialog.open(MediaHubComponent, { disableClose: true});
    dialogRef.componentInstance.mediaType = type;

    dialogRef.afterClosed().subscribe(media =>{
      selectedMedia = media;
      if (type == "image") {
        this.selectedAnnotation.srcUrl = selectedMedia.url;
        this.selectedAnnotation.cssProperties.width = imgwidth + "em";
        this.selectedAnnotation.cssProperties.height = imgheight + "em";
        this.aspectRatio = imgwidth / imgheight;
        this.overlayAnnotations();
        this.setDraggerPosition();
        this.annotationTableData[this.getAnnotationTableId(this.selectedAnnotation)].value = selectedMedia.name;
      }

      else if (type == "video") {
        this.selectedAnnotation.srcUrl = selectedMedia.url;
        this.selectedAnnotation.videoData = "";
        this.selectedAnnotation.cssProperties.width = imgwidth + "em";
        this.selectedAnnotation.cssProperties.height = imgheight + "em";
        this.aspectRatio = imgwidth / imgheight;
        this.overlayAnnotations();
        this.setDraggerPosition();
        this.annotationTableData[this.getAnnotationTableId(this.selectedAnnotation)].value = selectedMedia.name;

      }

      else if (type == "audio") {
        this.selectedAnnotation.srcUrl = selectedMedia.url;
        this.selectedAnnotation.audioData = "";
        this.overlayAnnotations();
        this.annotationTableData[this.getAnnotationTableId(this.selectedAnnotation)].value = selectedMedia.name;
      }
    })
  }

  reloadComponents() {
    this.onPublishBtn = false
    this.onCreateVideo = false;
    this.isSceneSelected = false;
    // this.data.reload = true;
    this.isEditScript = true;
    this.isPublishScreen = false;
  }

  async onPublish() {
    this.publishLoader = true;
    this.isFrompreview = false;
    this.saveLoaded = true;
    var projectName = this.titleValue;
    this.notifyOnPublishClick('clicked');

    if (this.projectModel.id) {
      this.updateScenesAndPSV();
    }
    else {
      this.storeScenesAndPSV();
    }

    this.concatenate();


  }

  async concatenate() {

    await this.concatenatedDataOnPreview().then(() => {
      this.router.navigate(['/publish']);
      this.isPublishScreen = true;
    });
  }

  async fetchScene(sceneId: any) {
    let apiUrl = environment.dbUrl + 'api/scenes/fetchSceneById';
    let requestBody = JSON.stringify({ sceneId: sceneId });

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  async storeScene(sceneObj: any) {
    let apiUrl = environment.dbUrl + 'api/scenes/addNewScene';
    let requestBody = JSON.stringify({ scene: sceneObj });

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data._id);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  async updateScene(sceneObj: any) {
    let apiUrl = environment.dbUrl + 'api/scenes/editScene';
    let requestBody = JSON.stringify({ sceneData: sceneObj });

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  async deleteScene(sceneId: any) {
    let apiUrl = environment.dbUrl + 'api/scenes/deleteScene';
    let requestBody = JSON.stringify({ sceneId: sceneId });

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data._id);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }



  async fetchPSV(psvId: any) {
    let apiUrl = environment.dbUrl + 'api/psv/fetchPSVById';
    let requestBody = JSON.stringify({ id: psvId });

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }


  async deletePSV(psvId: any) {
    let apiUrl = environment.dbUrl + 'api/psv/deletePSV';
    let requestBody = JSON.stringify({ psvId: psvId });

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data._id);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }


  async storePSV(psvObj: any) {
    let apiUrl = environment.dbUrl + 'api/psv/addNewPSV';
    let requestBody = JSON.stringify({ psv: psvObj });

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data._id);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  async updatePSV(psvObj: any) {
    let apiUrl = environment.dbUrl + 'api/psv/editPSV';
    let requestBody = JSON.stringify({ psvData: psvObj });

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  fetchAllProjects() {
    let apiUrl = environment.dbUrl + 'api/project/fetchAllProjects';
    let requestBody = JSON.stringify({ val: "fetching all projects" });

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  fetchAllProjectsByUserId(userId:any) {
    let apiUrl = environment.dbUrl + 'api/project/fetchAllProjectsByUserId';
    let requestBody = JSON.stringify({ userId: userId });

    return new Promise((resolve:any, reject:any) =>{
      this.postReq(apiUrl, requestBody).subscribe((res:any) =>{
        if (res.success) {
          resolve(res.data);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  fetchProjectById(projectId: any) {
    let apiUrl = environment.dbUrl + 'api/project/fetchProjectById';
    let requestBody = JSON.stringify({ id: projectId });

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  fetchTemplateById(templateId: any) {
    let apiUrl = environment.dbUrl + 'api/template/fetchTemplateById';
    let requestBody = JSON.stringify({ id: templateId });

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  deleteTemplate(templateId: string){
    return new Promise((resolve: any, reject: any) => {
      this.storageService.deleteTemplateFiles(templateId).then(()=>{
        this.deleteTemplateFromDb(templateId).then((result:any) => {
          if(result.success){
            resolve();
          }
          else{
            reject();
          }
        })
      }).catch((err)=>{this.showSnackBar("Something went wrong while removing template files","Ok")})
    })
  }

  deleteTemplateFromDb(templateId: any) {
    let apiUrl = environment.dbUrl + 'api/template/deleteTemplate';
    let requestBody = JSON.stringify({ templateId: templateId });

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  fetchAllTemplatesByUserId(userId: any) {
    let apiUrl = environment.dbUrl + 'api/template/fetchTemplatesByUserId';
    let requestBody = JSON.stringify({ userId: userId });

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  async generateTemplate(title: string, userId:any, userDataId:any){
    let psvTemplate: PsvTemplate = {};
    this.concatList = [];
    return new Promise((resolve: any, reject: any) => {
      if(this.previewScenes){
        for (let i = 0; i < this.previewScenes.length; i++) {
          this.previewScenes[i].sceneName = "player" + i.toString();
        }
        for (let index in this.previewScenes) {
          this.overlayJSON = this.scenes[index].videoMeta;
          var videoData = JSON.stringify(this.formatVideoOutputJson());
          let videoData_temp = JSON.parse(videoData);
          videoData_temp.captions = this.scenes[index].videoMeta.captions;
          videoData = JSON.stringify(videoData_temp)
          this.previewScenes[index].metaData = videoData;
          this.concatList.push(this.previewScenes[index].sceneName);
        }
        psvTemplate.title = title;
        psvTemplate.userId = userId;
        psvTemplate.createdDate =new Date();
        psvTemplate.concatStatus='In queue';
        psvTemplate.videoNamesString=this.concatList.toString();
        psvTemplate.concatenationData=this.formatVideoRelatedData(this.previewScenes);
        psvTemplate.userdataId=userDataId;
        psvTemplate.projectId = this.projectModel.id;
        resolve(psvTemplate);
      }
      else{
        reject('Something went wrong!')
      }
  })
  }

  storeTemplate(templateObj:PsvTemplate) {
    let apiUrl = environment.dbUrl + 'api/template/addNewTemplate';
    let requestBody = JSON.stringify({templateObj : templateObj});

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  updateTemplateStatus(templateId:string, concatStatus:string) {
    let apiUrl = environment.dbUrl + 'api/template/editConcatStatus';
    let requestBody = JSON.stringify({id : templateId, status : concatStatus});

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  concatVideos(templateId: any) {
    // need to change concatApiUrl
    let apiUrl = environment.concatServiceUrl + 'concatTemplate';
    let requestBody = JSON.stringify({templateId:templateId});

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res);
        }
        else {
          reject(res);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  trimVideo(bgVideoUrl:any, timeString:any, trimDuration:any) {
    let apiUrl = environment.concatServiceUrl + 'trimBgVideos';
    let requestBody = {
      method: 'POST',
      body: JSON.stringify({videoUrl:bgVideoUrl,inTimeStamp:timeString,durationStamp:trimDuration}),
      headers: {
          "Content-Type": "application/json"
      }
  }
    return new Promise((resolve: any, reject: any) => {
      fetch(apiUrl, requestBody).then(response => response.blob().then(videoBlob => {
        resolve(videoBlob);
      })).catch(err =>{
          reject();
        })
    })
  }

  fetchViewRecordsOfProject(projectId: any) {
    let apiUrl = environment.dbUrl + 'api/views/fetchViewRecordsOfProject';
    let requestBody = JSON.stringify({ projectId: projectId });

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  fetchViewRecordsOfTemplate(templateId: any) {
    let apiUrl = environment.dbUrl + 'api/views/fetchViewRecordsOfTemplate';
    let requestBody = JSON.stringify({ templateId: templateId });

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  fetchViewsByStatusOfTemplate(templateId: any, status: any) {
    let apiUrl = environment.dbUrl + 'api/views/fetchViewsByStatusOfTemplate';
    let requestBody = JSON.stringify({ templateId: templateId, status: status });

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  fetchUniqueViewsOfTemplate(templateId: any) {
    let apiUrl = environment.dbUrl + 'api/views/fetchUniqueViewsOfTemplate';
    let requestBody = JSON.stringify({ templateId: templateId });

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  fetchViewsOfTemplateByDate(templateId: any, date: any, index: any) {
    let apiUrl = environment.dbUrl + 'api/views/fetchViewsOfTemplateByDate';
    let requestBody = JSON.stringify({ templateId: templateId, date: date.toString(), index: index});

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  fetchViewRecordsOfLink(psvLinkId: any) {
    let apiUrl = environment.dbUrl + 'api/views/fetchViewRecordsOfLink';
    let requestBody = JSON.stringify({ psvLink: psvLinkId });

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  fetchViewsOfUserData(projectId: any) {
    let apiUrl = environment.dbUrl + 'api/views/fetchViewsOfUserData';
    let requestBody = JSON.stringify({ projectId: projectId });

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  async storeProject(projectObj: any) {
    let apiUrl = environment.dbUrl + 'api/project/addNewProject';
    let requestBody = JSON.stringify({ project: projectObj });

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data._id);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }


  async validateLink(link: any,password? : any ) : Promise<any> {
    let apiUrl = environment.dbUrl + 'api/psvLink/validateLink';
    let requestBody = JSON.stringify({ "shortUrl" : link , "password" : password });

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        resolve(res);
      }, err =>{
        reject(err);
      })
    })
  }

  async updateProject(projectObj: any) {
    let apiUrl = environment.dbUrl + 'api/project/editProject';
    let requestBody = JSON.stringify({ projectData: projectObj });

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  async deleteProject(projectId: any) {
    let apiUrl = environment.dbUrl + 'api/project/deleteProject';
    let requestBody = JSON.stringify({ projectId: projectId });

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data._id);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }


  async fetchUserDataById(userDataId: any) {
    let apiUrl = environment.dbUrl + 'api/userData/fetchUserDataById';
    let requestBody = JSON.stringify({ userDataId: userDataId });

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  async fetchUserDatas(userDataIds: any[]): Promise<any[]> {
    let promises = [];
    let userDataArray: any = [];
    for (let userDataId of userDataIds) {
      promises.push(await this.fetchUserDataById(userDataId));
    }

    await Promise.all(promises).then((userDatas: any) => {
      for (let userData of userDatas) {
        userDataArray.push(userData.userData);
      }
    }).catch(this.handleAPIErrors);

    return userDataArray;
  }

  async addNewUserData(userDataObj: any) {
    let apiUrl = environment.dbUrl + 'api/userData/addNewUserData';
    let requestBody = JSON.stringify({ userData: userDataObj });

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data._id);
        }
        else {
          reject(res.message);
        }
      }, (err) =>{
        reject(err);
      })
    })
  }

  async updateUserData(userDataObj: any) {
    let apiUrl = environment.dbUrl + 'api/userData/editUserData';
    let requestBody = JSON.stringify({ userDataObj: userDataObj });

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  async deleteUserData(userDataId: any) {
    let apiUrl = environment.dbUrl + 'api/userData/deleteUserData';
    let requestBody = JSON.stringify({ userDataId: userDataId });

    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl, requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data._id);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  async storeScenesAndPSV() {
    let sceneIds;
    let promises: any = [];

    let userDataObj: any = {};
    userDataObj['userData'] = this.userData;
    await this.addNewUserData(userDataObj).then((userDataId: any) => {
      this.userDataId = userDataId;
    }).catch(this.handleAPIErrors);

    for (let [i, scene] of this.scenes.entries()) {
      scene.updatePSVObj();
      let psvObj = scene.getPSVObjForDB();
      let sceneObj = scene.getSceneObjForDB();

      await this.storePSV(psvObj).then((psvId: any) => {
        this.scenes[i].psvId = psvId;
        sceneObj['PSV'] = psvId;
        promises.push(this.storeScene(sceneObj));
      }).catch(this.handleAPIErrors);
    }

    await Promise.all(promises).then(async (result) => {
      sceneIds = result;
      for (let [i, sceneId] of sceneIds.entries()) {
        this.scenes[i]._id = sceneId;
      }
      this.projectModel.scenes = sceneIds;
      this.projectModel.lastModifiedDate = new Date();
      await this.storeProject(this.projectModel).then((projectId: any) => {
        this.projectModel.id = projectId;
      }).catch(this.handleAPIErrors);
    }).catch(this.handleAPIErrors);
  }

  async updateScenesAndPSV() {
    let sceneIds;
    let promises: any = [];


    let userDataObj: any = {};
    userDataObj['id'] = this.userDataId;
    userDataObj['userData'] = this.userData;
    await this.updateUserData(userDataObj).catch(this.handleAPIErrors);



    for (let [i, scene] of this.scenes.entries()) {
      scene.updatePSVObj();
      let psvObj = scene.getPSVObjForDB();
      let sceneObj = scene.getSceneObjForDB();

      if (psvObj.id) {
        await this.updatePSV(psvObj).then((psvId: any) => {
          promises.push(this.updateScene(sceneObj));
        }).catch(this.handleAPIErrors);
      }
      else {
        await this.storePSV(psvObj).then((psvId: any) => {
          this.scenes[i].psvId = psvId;
          sceneObj['PSV'] = psvId;
          promises.push(this.storeScene(sceneObj));
        }).catch(this.handleAPIErrors);
      }
    }

    await Promise.all(promises).then(async (result) => {
      sceneIds = result;
      for (let [i, sceneId] of sceneIds.entries()) {
        this.scenes[i]._id = sceneId;
      }
      this.projectModel.script = this.fileContent;
      this.projectModel.scenes = sceneIds;
      this.projectModel.lastModifiedDate = new Date();
      await this.updateProject(this.projectModel).catch(this.handleAPIErrors);
    }).catch(this.handleAPIErrors);
  }

  createConcatenatedPSVObjForDB(playerId: any, videoSourceObj: any, psvMetaDataUrl: any, ttsOptions: any, userDataList: any, id?: any) {
    let psvObj: any = {};

    psvObj['id'] = id
    psvObj['playerId'] = playerId;
    psvObj['videoSourceObj'] = videoSourceObj;
    psvObj['psvMetaDataUrl'] = psvMetaDataUrl;
    psvObj['ttsOptions'] = ttsOptions;
    psvObj['userDatalist'] = userDataList;

    return psvObj;
  }

  formatUserDataJSON(listofLabels: any[][]) {
    let object: any = {};
    for (let list of listofLabels) {
      let revList = list.reverse();
      let key = revList.pop();
      object[key] = typeof object[key] == 'object' ? this.MergeObjects(this.formatBranch(revList), object[key]) : this.formatBranch(revList);
    }
    return object;
  }

  formatBranch(branches: any) {
    let obj: any = {};
    if (branches.length === 0) {
      return " ";
    }
    let key = branches.pop();
    obj[key] = this.formatBranch(branches);
    return obj;
  }

  // updateUserDataChange(userdata: any, newKeys: any) {
  //   let userDataKeys = Object.keys(userdata);
  //   let newUserData: any = {};
  //   for (let newKey of newKeys) {
  //     if (userDataKeys.includes(newKey)) {
  //       newUserData[newKey] = userdata[newKey];
  //     }
  //     else {
  //       newUserData[newKey] = ' ';
  //     }
  //   }
  //   for (let userDataKey of userDataKeys) {
  //     if (!newKeys.includes(userDataKey)) {
  //       newUserData[userDataKey] = userdata[userDataKey];
  //     }
  //   }
  //   return newUserData;
  // }

  MergeObjects(target: any, ...sources: any) {
    sources.forEach((source: any) => {
      Object.keys(source).forEach((key: any) => {
        const tVal = target[key];
        const sVal = source[key];
        if (tVal && sVal && typeof tVal === 'object' && typeof sVal === 'object') {
          target[key] = this.MergeObjects(tVal, sVal);
        }
        else if (tVal && sVal && typeof tVal === 'object' && typeof sVal !== 'object') {
          target[key] = tVal;
        }
        else if (tVal && sVal && typeof tVal !== 'object' && typeof sVal === 'object') {
          target[key] = tVal;
        }
        else {
          target[key] = sVal;
        }
      })
    })
    return target;
  }

  generateShortUrl(baseUrl : string, longUrl : string, duration:any, password : any, embedBase:any, projectId:any, templateId:any, userDataId:any, isClientDemo: boolean) {
    let addShortUrl = environment.dbUrl + 'api/psvLink/shorten';
    const requestBody = JSON.stringify({ 'baseUrl': baseUrl, 'link': longUrl, 'dynamicApiUrl': this.dynamicApiUrl, "duration" : duration, "password" : password, "embedBase": embedBase, "projectId":projectId, "templateId": templateId, "userDataId":userDataId, "isAuthRequired": !isClientDemo})
    return new Promise((resolve: any, reject: any) => {
      this.postReq(addShortUrl,requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data.shortUrl);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  getLinkData(urlcode : string){
    let getLongUrlFromDb = environment.dbUrl + 'api/getLinkData/'+urlcode;
    const requestBody = JSON.stringify({ })
    return new Promise((resolve: any, reject: any) => {
      this.postReq(getLongUrlFromDb,requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  getPsvLinksForProject(projectId:any){
    let apiUrl = environment.dbUrl + 'api/psvLink/getPsvLinksForProject';
    const requestBody = JSON.stringify({ projectId: projectId})
    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl,requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  getPsvLinksForTemplate(templateId:any){
    let apiUrl = environment.dbUrl + 'api/psvLink/getPsvLinksForTemplate';
    const requestBody = JSON.stringify({ templateId: templateId})
    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl,requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  getPsvLinkViewForTemplate(psvLinkId:any){
    let apiUrl = environment.dbUrl + 'api/views/fetchViewRecordsOfLink';
    const requestBody = JSON.stringify({ psvLink: psvLinkId})
    return new Promise((resolve: any, reject: any) => {
      this.postReq(apiUrl,requestBody).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      })
    })
  }

  openExportJSONDialog(saveFiles: boolean): void {
    const dialogRef = this.dialog.open(ExportJsonDialog, {
      data: { isDashAvailable: false, isCaptionAvailable: false, enableTranscript: false, isObfuscateOn: false }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isDashAvailable = result.isDashAvailable || false;
        this.isCaptionAvailable = result.isCaptionAvailable || false;
        this.enableTranscript = result.enableTranscript || false;
        this.isObfuscateOn = result.isObfuscateOn || false;
        this.exportToJsonFile();
      }
    });
  }

  async exportToJsonFile() {
    let userdata, videodata
    let templateVideo:any;
    let readMe:any;

    await fetch('./assets/data/Readme.txt').then(res => res.blob().then(readmeBlob =>{
      readMe = readmeBlob;
    }));

    let templateVideoUrl = this.storageService.storageWebServerUrl + this.storageService.containerName + '/projects/' + this.projectModel.id + '/TemplateVideo.mp4';
    await fetch(templateVideoUrl).then(res => res.blob().then(videoBlob =>{
      templateVideo = videoBlob;
    }));

    const exportFileDefaultName1 = 'UserData.json';
    let exportFileDefaultName2 = '';
    const exportFileDefaultName3 = `Captions.vtt`;
    const exportFileDefaultName4 = 'TemplateVideo.mp4';
    const userdataFormat = JSON.stringify(this.formatUserDataOutput(), null, 4);
    userdata = new Blob([userdataFormat], { type: 'application/json' });
    const videoData = this.videoDatafile;
    // videoData.captions.src = "Captions.vtt";
    let videoDataFormat;
    if (this.isObfuscateOn) {
      const formatVideoData = 'var oxvideometa = `' + JSON.stringify(videoData, null, 4) + '`';
      const obfuscatedDataStr2 = JavaScriptObfuscator.obfuscate(formatVideoData, { compact: true, selfDefending: false, deadCodeInjection: true });
      videoDataFormat = obfuscatedDataStr2._obfuscatedCode;
      videodata = new Blob([obfuscatedDataStr2._obfuscatedCode], { type: 'application/js' });
      exportFileDefaultName2 = 'VideoData.js';
    } else {
      videoDataFormat = JSON.stringify(videoData, null, 4);
      videodata = new Blob([videoDataFormat], { type: 'application/json' });
      exportFileDefaultName2 = 'VideoData.json';
    }
    const fileName = this.projectModel.title;
    const jszip = new JSZip();
    jszip.file(exportFileDefaultName2, videodata);
    jszip.file(exportFileDefaultName1, userdata);
    jszip.file(exportFileDefaultName4, templateVideo);
    jszip.file("Readme.txt", readMe);
    if (this.isCaptionAvailable) {
      jszip.file(exportFileDefaultName3, this.captionBlob, {
        createFolders: false
      });
    }
    jszip.generateAsync({ "type": "blob" }).then(function (content:any) {
      saveAs(content, fileName);
    });
  }
  correctAnnotationEndTimes(bgVideoDuration: any, index: any) {
    for (let annotation of this.scenes[index].videoMeta.overlays) {
      if (annotation.end > bgVideoDuration) {
        annotation.end = bgVideoDuration;
      }
    }
    for (let annotation of this.scenes[index].videoMeta.audios) {
      if (annotation.end > bgVideoDuration) {
        annotation.end = bgVideoDuration;
      }
    }
  }

  initializeFontSize(){
    setTimeout(() => {
      this.fontSize = parseFloat((document.querySelectorAll("#psv-overlay-player > div.vjs-overlay")[0] as HTMLElement)?.style.fontSize.split("px")[0]);
    }, 50);
    if(!this.fontSize){
      // this.data.errorMsg("Couldn't get parent element");
    }
    if(this.selectedAnnotation){
     if(this.selectedAnnotation.type !== "audio" && this.selectedAnnotation.type !== "tts"){
      this.aspectRatio = parseInt(this.selectedAnnotation.cssProperties.width.substring(-2))/ parseInt(this.selectedAnnotation.cssProperties.height.substring(-2));
     }
   }
  }

  reloadSelectedScene() {
    this.selectedSceneLoaded = false;
    setTimeout(() => this.selectedSceneLoaded = true);
  }

  postReq(apiUrl:string, requestBody:any){
    return this.http.post<any>(apiUrl, requestBody, { headers: { "Content-Type": "application/json" } });
  }

  handleAPIErrors = (err:any) =>{
    if(err instanceof HttpErrorResponse){
      this.showSnackBar(this.genericErrorMsg, 'close');
      return;
    }
    else{
      this.showSnackBar(err, 'close');
      return;
    }
  }

  getProfileInfo(){
    return new Promise((resolve, reject) =>{
      this.http.get(GRAPH_ENDPOINT).subscribe(profile =>{
        resolve(profile);
      })
    })
  }

}

