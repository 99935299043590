/*******************************************************************************
 * COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
 * Reserved. Infosys believes the information in this document is accurate as of
 * its publication date; such information is subject to change without notice.
 * Infosys acknowledges the proprietary rights of other companies to the
 * trademarks, product names and such other intellectual property rights
 * mentioned in this document. Except as expressly permitted, neither this
 * documentation nor any part of it may be reproduced, stored in a retrieval
 * system, or transmitted in any form or by any means, electronic, mechanical,
 * printing, photocopying, recording or otherwise, without the prior permission
 * of Infosys Limited and/or any named intellectual property rights holders
 * under this document.
 ******************************************************************************/
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[synchronizeScrollWith]'
})
export class SyncScrollDirective {

  isMouseOver = true;

  constructor(readonly el:ElementRef) { 
  }

  @Input('synchronizeScrollWith')
  targetElement:any;

  // @HostListener('mouseover')
  // onMouseOver(){
  //   this.isMouseOver = true;
  // }

  // @HostListener('mouseout')
  // onMouseLeave(){
  //   this.isMouseOver = true;
  // }
  
  @HostListener('scroll')
  onElementScroll(){
    if(this.isMouseOver){
      const yScroll = this.el.nativeElement.scrollTop;
      const element = this.el.nativeElement.querySelector('div');
      if(element==null){
        this.targetElement.nativeElement.scrollTo(0,yScroll);
      }
     else{
        this.targetElement.nativeElement.scrollTo(0,yScroll);
      }
    
    }
  }

}
