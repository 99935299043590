/*******************************************************************************
 * COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
 * Reserved. Infosys believes the information in this document is accurate as of
 * its publication date; such information is subject to change without notice.
 * Infosys acknowledges the proprietary rights of other companies to the
 * trademarks, product names and such other intellectual property rights
 * mentioned in this document. Except as expressly permitted, neither this
 * documentation nor any part of it may be reproduced, stored in a retrieval
 * system, or transmitted in any form or by any means, electronic, mechanical,
 * printing, photocopying, recording or otherwise, without the prior permission
 * of Infosys Limited and/or any named intellectual property rights holders
 * under this document.
 ******************************************************************************/
import { Directive, ElementRef, HostListener, OnInit, Output, EventEmitter } from '@angular/core';
declare var ResizeSensor: any;

@Directive({
  selector: '[appCalculateWidth]'
})
export class CalculateWidthDirective implements OnInit {

  @Output('onResizingMe') onResizeElement = new EventEmitter<string>();

  constructor(readonly el: ElementRef) {
  }

  ngOnInit() {
    new ResizeSensor(this.el.nativeElement, () => {
      // used arrow function to preserve "this" inside function scope
      this.onElementResize();
    }
    );
  }

  onElementResize() {
    const elementWidth = this.el.nativeElement.getBoundingClientRect().width;
    this.onResizeElement.emit(elementWidth);
  }


}
