<!-- <div class="bc-container">
    <ul class="progressbar">
        <li class="active" (click)="data.reloadComponents()">Edit Script</li>
        <li class="active" (click)="allSceneSelected()">Edit Scenes</li>
        <li class="inactive" [ngClass]="{'active' : data.isSceneSelected}"  (click)="sceneSelected(0)">Edit Elements</li>
        <li  (click)="onPublish()">Publish</li>
    </ul>
</div> -->

<div [ngClass]="{'dim': this.data.saveLoaded}" class="flex-container" *ngIf="!data.isSceneSelected">
    <span>No. of Scenes: <strong>{{totalScene}}</strong> </span> |
    <span>Total Length: <strong>{{data.estimatedTime| duration}}</strong>  mm:ss</span>|
    <div class="preview-save-btn-container" *ngIf="!isSceneSelected">
        <!-- <button type="button" class="blue-buttons primary-btn preview-save" name="preview-button"
            (click)="showPreview()">Preview</button> | -->
        <button type="button" class="blue-buttons primary-btn preview-save"
            (click)="SavePreview()">Save</button>
    
    </div>
</div>
<div [ngClass]="{'dim': this.data.saveLoaded}" class="container">
    <div class="flex-scenes">
        <!-- <div class="display-scenes"> -->
            <div class="scene-container" *ngIf="!data.isSceneSelected">
                <div *ngIf="data.show">
                    <div class="scenes" *ngFor="let scene of data.scenes; index as i">
                        <div class="video-and-actions">
                            <div class="video-container">
                                <div [id]="'player'+i">
                                    <video preload="metadata" [id]="'psv-player'+i"
                                        class="video-js vjs-default-skin vjs-psv-skin overlay-video"
                                        (loadedmetadata)="calculateSceneDuration($event,i)" (playing)="playVideo()"
                                        (pause)="data.isVideoPlaying = false"
                                        data-setup='{ "controls": true, "autoplay": false, "preload": "auto", "fluid" : true}'
                                        playsinline>
                                        <source [src]="scene.videoPath | sanitizeVideo" type='video/mp4'>
                                    </video>
                                </div>
                            </div>
                            <div class="video-action-container">
                                <mat-icon matTooltip="Edit Scene" class="action-icon edit-scene-icon" (click)="sceneSelected(i)">edit
                                </mat-icon>
                                <mat-icon matTooltip="Delete Scene" class="action-icon delete-scene-icon" (click)="deleteScene(i)">
                                    delete_outline
                                </mat-icon>
                                <!-- <button   (click)="openPosterDialog()">Poster
                                </button> -->
                                <mat-icon class="action-icon  change-BGV-icon" (click)="changeBGVideo(i,('psv-player'+i))"
                                style="border-color: rgb(15, 114, 6);" matTooltip="Change Background Video">video_call
                            </mat-icon>
                            
                        </div>
                    </div>
                      <p *ngIf="data.scenes[i].videoName" class="Video-prop"> {{data.scenes[i].videoName}} 
                            <span class="seperator">|</span> {{sceneResolution[i]}} 
                            <span class="seperator">|</span> {{sceneDuration[i]}} </p>
                        <textarea type="text" #inputScript class="scene-script" [ngModel]="data.scenes[i].script"
                            (ngModelChange)="setSceneScript(inputScript.value,i)" (blur)="data.notifyOnUpdateTemplate('scene_updated')"></textarea><br>
                        <div class="add-scene">
                            <hr>
                            <button (click)="addScene(i)">
                                <mat-icon class=" sec-text" style="font-size: 24px;">add</mat-icon>
                            </button>
                        </div>
                        <br>
                    </div>
                </div>
            </div>
        <!-- </div> -->
        <div class="btn-and-saveprogress-container">
            <!-- <div class="preview-save-btn-container" *ngIf="!isSceneSelected">
              
                <button type="button" class="blue-buttons primary-btn preview-save" name="preview-button"
                    (click)="showPreview()">Preview</button>
                <button type="button" disabled="disabled" class="blue-buttons primary-btn preview-save"
                    (click)="SavePreview()">Save</button>
                    
            </div> -->
            <div>
                <app-preview-scene *ngIf="data.previewScreen" (cancel)="cancelPreview($event)"></app-preview-scene>
            </div>
            <!-- <div *ngIf="this.data.saveLoaded" class="loadScreen">
                <div>
                    <em class="fa fa-spinner fa-spin spin-big"></em>
                </div>
                <div class="progress-msg">Saving Files is in Progress</div>
            </div> -->
        </div>
    </div>
</div>
<div class="editor-scene-container scene-container" *ngIf="data.isSceneSelected" [style.margin-top]="'0px'">
    <div [ngStyle]="{'margin':'1%'}" id = "transcript-overlay-player" class="tran-element"></div>
    <div class="current-scene-container" *ngIf="data.isSceneSelected">
        <div class="current-scene">
            <div class="scene-selected-text">Selected Scene:</div>
            <select class="select-scene bottom-border" name="select-scene" [(ngModel)]="sceneChangeIndex"
                (change)="sceneChanged(sceneChangeIndex)">
                <option *ngFor="let scene of data.scenes; index as i" [value]="i">Scene {{i+1}}</option>
            </select>
        </div>
    </div>
    <div *ngIf="data.selectedSceneLoaded" class="scenes" [ngStyle]="{'margin-left':data.isToolBarOpen ? '35%' : '35%'}">
        <div  class="video-container box-shadow white-bg" id="videoContainer">
            <div #playerContainer id="overlay-player">
                <div class="preview-loader-holder" *ngIf="data.isLoaderEnabled">
                    <div class="preview-loader"></div>
                </div>
                <!-- <div *ngIf="data.selectedAnnotation && data.selectedAnnotation.type !== 'tts' && data.selectedAnnotation.type !=='audio'"
                    class="annotation-dragger" [ngDraggable]=true [bounds]="playerContainer"
                    [position]="draggerCurrentPosition" [ngClass]="{'hidden': data.isVideoPlaying}"
                    (endOffset)="setAnnotationPosition($event)" (movingOffset)="calculateAnnotationPosition($event)"
                    [inBounds]="inBounds" #annotationDragger>
                    <div *ngIf="data.selectedAnnotation.type === 'image' || data.selectedAnnotation.type === 'chart' || data.selectedAnnotation.type === 'video' || data.selectedAnnotation.type === 'livevideo' || data.selectedAnnotation.type === 'file' || data.selectedAnnotation.type === 'webpage' || data.selectedAnnotation.type === 'text' " [style.width]="data.resizerWidth "
                    [style.height]="data.resizerHeight"  #resizeElement [rzAspectRatio]="data.selectedAnnotation.maintainAspectRatio"
                    class="resizable-widget" #annotationResize [ngResizable]="true" [rzHandles]="'e,s,se'" id="resize" 
                    (rzStop)="setAnnotationPositionResizer($event)">
                </div>
            </div> -->
                <video preload="metadata" seekVideo id="psv-overlay-player"
                    (loadedmetadata)="calculateVideoProperties($event)" (playing)="playVideo()"
                    (pause)="data.isVideoPlaying = false" class="video-js vjs-default-skin overlay-video"
                    data-setup='{ "controls": true, "autoplay": false, "preload": "auto", "fluid" : true}' playsinline>
                    <source [src]="data.scenes[this.data.selectedSceneIndex].videoPath | sanitizeVideo" type='video/mp4'>
                </video>
            </div>
        </div>
        <textarea type="text" #scriptInput class="scene-script" [ngModel]="data.selectedScene.script"
            (ngModelChange)="changeSceneScript(scriptInput.value)"></textarea>
    </div>
</div>
<div *ngIf="this.data.saveLoaded" class="save-loader-progress">
    <!-- <mat-progress-spinner class="save-loader-animation" mode="indeterminate" [diameter]="50"></mat-progress-spinner> -->
    <img src="../../../assets/gifs/PSV_Save.gif" class="save-loader-animation">
    <label class="save-spinner-label">Saving Files in Progress</label>
</div>
