import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-export-json',
  templateUrl: './export-json.component.html',
  styleUrls: ['./export-json.component.css']
})
export class ExportJsonDialog implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ExportJsonDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  // If transcript support is enabled, enable captions support too
  toggleTranscriptsSupport(event:any) {
    this.data.enableTranscript = event.checked;
    if (event.checked) {
      this.data.isCaptionAvailable = true
    }
  }

  // If caption support is disabled, disable transcripts support too
  toggleCaptionsSupport(event:any) {
    this.data.isCaptionAvailable = event.checked;
    if (!event.checked) {
      this.data.enableTranscript = false
    }
  }

  dismiss(): void {
    this.dialogRef.close();
  }

}
