<div class="temp-style">
    <div class="temp-style">
        <div align="center" class="msg">
            <span *ngIf="!isErrorMessage" class="demo">Saving Template is in progress...<br> Click "View Templates" to check the status</span>
            <span *ngIf="isErrorMessage" class="demo">Something went wrong from our side<br> Click "View Templates" to check the status</span>
        </div>

    </div>
    <div  style="position:absolute">
        <button type="button"  mat-button id="use-btn" class="vtbtn" (click)="templatePage()">
            View Templates
        </button>  
        
        <div  class="closebtn" (click)="closeDialog()" >
            <em class="icon-class material-icons">highlight_off</em>
        </div>
      </div>
</div>
