import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Project_Meta } from 'src/app/shared/models/project.model';
import { SceneModel } from 'src/app/shared/models/scene.model';
import { DataService } from 'src/app/shared/services/data.service';
import { SampleAudioService } from 'src/app/shared/services/sample-audio.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Subscription, elementAt } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { style } from '@angular/animations';

@Component({
  selector: 'app-inputtext',
  templateUrl: './inputtext.component.html',
  styleUrls: ['./inputtext.component.css']
})

export class InputtextComponent implements OnInit {
  PageTitle: string = "Project Details";
  fileContent!: any;
  temp!: any;
  array: any
  listofinputs: any = {};
  oncreate: any;
  changeAudio!: boolean;
  sampleAudio: any;
  isAudioOn: boolean = false;
  listOfScriptObj: any;
  listOfScriptObjArray: any = [];
  scriptLines: any = [];
  scriptAudio: any = {};
  audioDurations: any = {};
  allAudioLoaded: boolean = false;
  psvLoader: boolean = false;
  sectionArray: any[] = [];
  createdDate!: Date;
  totalScene: any;
  createVideoSubscription!: Subscription;
  validity: any = false;


  inputForm = new FormGroup({
    title: new FormControl(null, Validators.required),
    script: new FormArray([])
  });
  showsnackbar: any;
  keyStringPattern: any;
  code: boolean;

  constructor(readonly data: DataService, readonly sampleAudioService: SampleAudioService, public http: HttpClient, readonly storageService: StorageService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    if (this.data.scriptArray.length === 0) {
      this.script.push(new FormControl(null, Validators.required));
      this.data.scriptArray[0] = "";
    }
    else {
      this.script.clear();
      this.setValueInScript(this.data.scriptArray);
    }
    this.createVideoSubscription = this.data.notifyCreateVideoObservable.subscribe(message => {
      if (message === "clicked") {
        this.createvideo();
      }
    })

  }

  valueChange() {
    this.isAudioOn = false;
    this.data.scriptArray = this.script.value;
    this.data.fileContent = this.data.scriptArray.join('\n');
    this.validity=this.validateUserDataKeyStrings();
    }




  ngOnDestroy() {
    this.createVideoSubscription.unsubscribe();
    this.data.notifyOnCreateVideo("");
  }

  get script(): FormArray {
    return this.inputForm.get('script') as FormArray;
  }

  setTitleVal(val: string) {
    this.data.titleValue = val.trim();
    this.data.projectModel.title = this.data.titleValue;
  }
  setDescriptionVal(val: string) {
    this.data.descriptionValue = val.trim();
    this.data.projectModel.description = this.data.descriptionValue;
  }

  public uploadfile(contentOfFile: any): void {
    let file = contentOfFile.target.files[0];
    let fileReader: FileReader = new FileReader();
    fileReader.onloadend = () => {
      this.data.fileContent = fileReader.result;
      this.data.scriptArray = [];
      this.data.scriptArray = this.data.fileContent.split('\n');
      this.validity=this.validateUserDataKeyStrings();
      this.script.clear();
      this.setValueInScript(this.data.scriptArray);
    }
    fileReader.readAsText(file);
  }

  setValueInScript(sectionArray: any) {
    for (let index = 0; index < sectionArray.length; index++) {
      this.script.push(new FormControl(null, Validators.required));
    }
    this.script.setValue(sectionArray);
  }

  addSection() {
    this.isAudioOn = false;
    this.script.push(new FormControl(null, Validators.required));
    let length = this.data.scriptArray.length
    this.data.scriptArray[length] = "";
  }

  removeSection(index: number) {
    this.isAudioOn = false;
    this.data.scriptArray.splice(index, 1);
    this.data.fileContent = "";
    this.data.fileContent = this.data.scriptArray.join('\n');
    this.script.clear();
    this.setValueInScript(this.data.scriptArray);
  }



  async createvideo() {

    if (this.inputForm.valid) {
      let date = new Date();
      let project = new Project_Meta(this.data.titleValue, this.data.descriptionValue, this.data.fileContent, date, date);
      this.data.projectModel = project;
      this.createdDate = new Date();
      this.psvLoader = true;
      this.scriptLines = this.data.fileContent.split("\n");
      this.data.projectModel.lastModifiedDate = this.createdDate;
      this.data.projectModel.script = this.data.fileContent;

      // let promises = [];
      // for (let script of this.scriptLines) {
      //   promises.push(this.fetchAudio(script));
      // }
      // await Promise.all(promises).then((result: any) => {
      //   this.allAudioLoaded = true;
      // }).catch((err:any) => {
      //   this.data.showSnackBar(this.data.genericErrorMsg,'close');
      // });

      this.byPassTTSAndSetDurations();
      this.updateUserdata();
      if(this.data.scenes.length !== 0){
        this.updateScenes();
      }
      this.data.isEditScript = false;
    }
    else {
      this.oncreate = true
    }
  }

  byPassTTSAndSetDurations() {
    for (let script of this.scriptLines) {
      this.data.videoDurations.push(6);
    }
    this.allAudioLoaded = true;
    this.data.onCreateVideo = true;
    this.data.onPublishBtn = true;
    this.psvLoader = false;
  }

  async updateScenes() {
    this.data.stateChanged = true;
    this.totalScene = this.data.scriptArray.length;
    let extraScenesNum = this.data.scenes.length - this.totalScene;
    if (extraScenesNum >= 0) {
      for (let i = 0; i < this.totalScene; i++) {
        this.data.scenes[i].script = this.data.scriptArray[i].replaceAll('\r', '');
        this.data.scenes[i].videoMeta.audios[0].params.Text = this.data.scriptArray[i].replaceAll('\r', "");
        this.data.scenes[i].annotationTableData[this.data.getAnnotationTableId(this.data.scenes[i].videoMeta.audios[0])].value = this.data.scriptArray[i];
        this.data.scenes[i].caption_annotationTableData[this.data.getCaptionTableId(this.data.scenes[i].videoMeta.audios[0])].value = this.data.scriptArray[i];
      }
      this.data.scenes.splice(this.totalScene, extraScenesNum);
    }
    else {
      for (let i = 0; i < this.totalScene; i++) {
        let sceneId = "player" + i.toString();
        let videoPath;
        await this.storageService.getReadOnlyUrl('videos/6 sec white.mp4').then(url =>{
          videoPath = url;
        })
        if (this.data.scenes[i]) {
          this.data.scenes[i].script = this.data.scriptArray[i].replaceAll('\r', '');
          this.data.scenes[i].videoMeta.audios[0].params.Text = this.data.scriptArray[i].replaceAll('\r', '');
          this.data.scenes[i].annotationTableData[this.data.getAnnotationTableId(this.data.scenes[i].videoMeta.audios[0])].value = this.data.scriptArray[i].replaceAll('\r', '');
        }
        else {
          this.data.scenes.push(new SceneModel(sceneId.toString(), this.data.scriptArray[i].replaceAll('\r', ''), videoPath, this.data));
        }
      }
    }
  }

  setAudioDuration($event: any, i: number) {
    this.audioDurations[i] = $event.currentTarget.duration;
    if (Object.keys(this.audioDurations).length === this.scriptLines.length) {
      this.setBgVideoDuration(this.audioDurations);
      this.psvLoader = false;
      this.data.onCreateVideo = true;
      this.data.onPublishBtn = true;
    }
  }

  setBgVideoDuration(audioDurations: any) {
    for (let i = 0; i < Object.keys(this.audioDurations).length; i++) {
      let videoDuration = Math.round(audioDurations[i]) + 2;
      if (videoDuration <= 21) {
        this.data.videoDurations.push(videoDuration);
      }
      else {
        this.data.videoDurations.push(21);
      }
    }
  }

  fetchAudio(scriptLine: any) {
    return new Promise((resolve: any, reject: any) => {
      this.sampleAudioService.ttsProviderOptions.text = scriptLine;
      this.sampleAudioService.getSampleTTSAudio().then((audioObj: any) => {
        this.scriptAudio[scriptLine] = audioObj[0].url;
        resolve();
      }).catch((err: any) => {
        reject(err);
      });
    })
  }

  updateUserdata() {
    this.listOfScriptObj = this.data.fileContent.match(/{(.*?)}/gm);
    if (this.listOfScriptObj) {
      let size = this.listOfScriptObj.length
      for (let i = 0; i < size; i++) {
        let key = (this.listOfScriptObj[i].substring(1, this.listOfScriptObj[i].length - 1));
        this.listOfScriptObjArray.push(key.split('.'));
      }
      if (this.data.isEditScript && this.data.userData) {
        let newFormattedUserdata = this.data.formatUserDataJSON(this.listOfScriptObjArray);
        this.data.userData = this.data.MergeObjects(newFormattedUserdata, this.data.userData);
      }
    }
  }

  updateSampleAudio(text?: string) {
    if (this.isAudioOn) {
      if (text) {
        this.sampleAudioService.ttsProviderOptions.text = text;
      }
      this.changeAudio = true;
      this.sampleAudioService.getSampleTTSAudio().then((audioObj: any) => {
        this.sampleAudio = audioObj[0].url;
        this.changeAudio = false;
      }).catch(err => {
        console.log(err)
      });
    }

  }

  validateUserDataKeyStrings()
    {
      var keyStringPattern = this.data.fileContent;
             if (keyStringPattern.includes('"'))
            {
            document.getElementById('inputtext').style.border ="2px solid red";
            this.snackBar.open(' usge of " " is not allowed in script ', "",
              {
                duration: 2000,
              });
            }
        else
            {
              console.log("not matched");
            }

    }

  }

