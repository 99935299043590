<div class="backdrop">
    <div class="userdata-dialog radius box-shadow">
        <h3>User Data</h3>
        <div class="input-flex">
            <div class="userdata-label-field" *ngFor="let element of listofLabels">
                <label>{{element}}</label>
                <div class="text-field">
                    <input class="fieldstyle" type="text" #ele id={{element}} (blur)="updatedata(ele.value,element)"
                        placeholder="Input {{element}} Here">
                </div>
            </div>
        </div>
        <div class="button-container">
            <button class="blueBtn btn-fontsize-pointer radius closebtn" type="button"
                (click)="cancelpopup()">Cancel</button>
            <button class="blueBtn btn-fontsize-pointer radius updatebtn" type="button"
                (click)="cancelpopup()">Update</button>
        </div>
    </div>
</div>