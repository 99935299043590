<!------------------------------------------------------------------------------
 # COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
 # Reserved. Infosys believes the information in this document is accurate as of
 # its publication date; such information is subject to change without notice.
 # Infosys acknowledges the proprietary rights of other companies to the
 # trademarks, product names and such other intellectual property rights
 # mentioned in this document. Except as expressly permitted, neither this
 # documentation nor any part of it may be reproduced, stored in a retrieval
 # system, or transmitted in any form or by any means, electronic, mechanical,
 # printing, photocopying, recording or otherwise, without the prior permission
 # of Infosys Limited and/or any named intellectual property rights holders
 # under this document.
-------------------------------------------------------------------------------->
<div class="inner-properties" style="position: inherit;">
  <div (click)="minimizeToolBar()" class="toolbar-minimize" *ngIf="isToolBarOpen">
    <!-- <fa-icon [icon]="faCaretRight"></fa-icon> -->
    <em class="icon-class material-icons">highlight_off</em>
  </div>
  <div>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title class="heading-container">IMAGE PROPS. </mat-panel-title>
      </mat-expansion-panel-header>
      <label class="properties font-size-sm">
        IMAGE PATH &nbsp; </label>
      <input type="text" class="font-size-md" [(ngModel)]="pathUrl" (input)="loadImagePath()"
        id="imgSelected" placeholder="Enter image path">
      <span class="font-span-sm">OR</span><br>
      <label class="properties font-size-sm no-margin">ADD IMAGE&nbsp;</label><br>
      <div class="properties font-size-sm file-display">
        <!-- <input id="imgSrc" accept="image/*" (change)="onFileSelected($event)" type="file" class="file-name">
        <span>{{imageOverlayComponent.imageName == "" ? "No file chosen" : imageOverlayComponent.imageName}}</span><br>
        <span class="font-span-sm">OR</span> -->
        <div>
          <button class="from-gallery-button" (click)="openGalleryForImage()" type="button">Choose From Gallery</button>
        </div>
      </div>
      <label class="properties font-size-sm">LINK &nbsp; &nbsp; &nbsp;</label>
      <input class="font-size-md" type="text" [(ngModel)]="imageOverlayComponent.link"
        placeholder="https://www.google.com" (input)="loadElement(imageOverlayComponent)"> <br>
      <label class="properties font-size-sm">CALLBACK &nbsp; &nbsp; &nbsp;</label>
      <input class="font-size-md" type="text" [(ngModel)]="imageOverlayComponent.callback" placeholder="callback"
        (input)="loadElement(imageOverlayComponent)"><br>
      <label class="properties font-size-sm">ALTERNATE TEXT</label>
      <input class="font-size-md" type="text" [(ngModel)]="imageOverlayComponent.altText" placeholder="alt-text"
        (input)="loadElement(imageOverlayComponent)">
      <div class="properties font-size-sm">
        <mat-checkbox class="check-box " style="padding-right:6px" (ngModelChange)="toggleMaintainAspectRatio()"
          [ngModel]="imageOverlayComponent.maintainAspectRatio">MAINTAIN ASPECT RATIO</mat-checkbox>
      </div>
      <div class="properties-bar">
        <label class="font-size-sm aspect-width">WIDTH (in em)&nbsp;
          <input #imgWidth [ngModel]="imageOverlayComponent.cssProperties.width | calcFontSize"
            (ngModelChange)="updateImageWidth(imgWidth.value)" class="input-time font-size-md" type="number" min="0">
        </label>
        <label class="font-size-sm aspect-width">HEIGHT (in em)&nbsp;
          <input #imgHeight [ngModel]="imageOverlayComponent.cssProperties.height | calcFontSize"
            (ngModelChange)="updateImageHeight(imgHeight.value)" class="input-time font-size-md" type="number" min="0">
        </label>
      </div>
      <div class="font-size-sm">
        <label class="bg-label">BACKGROUND COLOR </label>
        <mat-slide-toggle [(ngModel)]="imageOverlayComponent.bgToggle" (change)="toTransparent()"></mat-slide-toggle>
      </div>
      <div *ngIf="imageOverlayComponent.bgToggle">
        <div class="properties-bar">
          <label class="bg-color-label">
            <input class="color-picker" type="color" #imgBgColor
              [ngModel]="imageOverlayComponent.cssProperties.backgroundColor | colorConvert"
              (ngModelChange)="changeBgColor(imgBgColor.value)">
            <input type="text" class="color-picker-value font-size-md"
              [ngModel]="imageOverlayComponent.cssProperties.backgroundColor" (ngModelChange)="setBgColor($event)">
          </label>
        </div>
        <label class="properties font-size-sm">
          BACKGROUND PADDING
        </label>
        <div class="properties-bar">
          <button class="remove-default-style properties-button" (click)="decreaseOutlineSize()"
            matTooltip="Reduce Font Size" matTooltipPosition=left>
            <!-- <fa-icon [icon]="faMinusCircle"></fa-icon> -->
            <em class="fa-solid fa-circle-minus fa-2x"></em>
          </button>
          <input class="font-size-lg center-align width-half font-padding-text" type="number" step="0.01"
            [ngModel]="imageOverlayComponent.cssProperties.outlineWidth | calcFontSize"
            (ngModelChange)="updateOutlineSize($event)">
          <button class="remove-default-style properties-button" (click)="increaseOutlineSize()"
            matTooltip="Add Font Size" matTooltipPosition=left>
            <!-- <fa-icon [icon]="faPlusCircle"></fa-icon> -->
            <em class="fa-solid fa-circle-plus fa-2x"></em>
          </button>
        </div>
        <label class="properties font-size-sm label-margin">
          BORDER RADIUS
        </label>
        <div class="properties-bar">
          <button class="remove-default-style properties-button" (click)="decreaseBorderRadius()"
            matTooltip="Reduce Border Radius" matTooltipPosition=left>
            <!-- <fa-icon [icon]="faMinusCircle"></fa-icon> -->
            <em class="fa-solid fa-circle-minus fa-2x"></em>
          </button>
          <input class="font-size-lg center-align width-half font-padding-text" type="number" step="0.01"
            [ngModel]="imageOverlayComponent.cssProperties.borderRadius | calcFontSize"
            (ngModelChange)="updateBorderRadius($event)">
          <button class="remove-default-style properties-button" (click)="increaseBorderRadius()"
            matTooltip="Add Border Radius" matTooltipPosition=left>
            <!-- <fa-icon [icon]="faPlusCircle"></fa-icon> -->
            <em class="fa-solid fa-circle-plus fa-2x"></em>
          </button>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
</div>