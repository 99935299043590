<!-- <div class="heading-container">
  <strong class="main-headings">PSV Studio</strong>
</div> -->

<div class="main-container" *ngIf="!data.isPublishClicked">
  <div>
    <strong class="sub-heading main-headings">Configure the PSV users</strong>
  </div>
  <div class="expansion-container">
    <mat-accordion>
      <mat-expansion-panel class="expansion-panel-container" (opened)=" data.isFewUsers=true; data.isDynamicAPI = false" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon class='material-icons-outlined'>people</mat-icon> &nbsp; Manual Configuration
          </mat-panel-title>
        </mat-expansion-panel-header>
        <strong>User Data:</strong>
        <div style="width: 100%;" *ngIf="loaded">
          <div class="user-card-container" *ngFor="let item of data.customKeyArray ; index as i">
            <div class="user">
              <!-- <input type="text" class="common-styling" #inputName value={{data.customKeyArray[i]}}
                    (change)="changeToInputname(inputName.value,i)" size="60"> -->
              <div class="userdata-card">
                <div class="avatar">
                  <mat-icon>person</mat-icon>
                </div>
                <div class="data-view">
                  <input type="text" class="common-styling" #inputName value={{data.customKeyArray[i]}}
                    (change)="changeToInputname(inputName.value,i)" size="60">
                  <div>{{data.userDataObj[item] | json}}</div>
                </div>
              </div>
              <button mat-button (click)="openDialog(data.userDataObj[item],i,'clone')">
                <mat-icon>content_copy</mat-icon>
              </button>
              <button mat-button (click)="openDialog(data.userDataObj[item],i,'edit')">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-button (click)="openDialog(data.userDataObj[item],i,'delete')">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
          <!-- <div class="btn-container" fxLayout="row" fxLayoutGap="20">
            <button class="add-user-btn upload-btn common-styling primary-btn" disabled>Add User</button>
            <span>or</span>
            <button fxLayout="row" fxLayoutAlign="center" faxLayoutGap="5" class="upload-btn common-styling primary-btn"
              disabled>
              <em class="material-icons">file_upload</em>
              Upload File
            </button>
          </div> -->
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel class="expansion-panel-container" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>language</mat-icon>&nbsp; API (Static userdata)
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="column">
          <p>In order to publish PSV for many people you need to configure a web API which returns list of
            userdata.
          </p>
          <div fxLayout="row">
            <input #apiUrlID class="width-property api-url-input" type="url" placeholder="Enter API URL">
            <button class="secondary-btn fetch-btn" [disabled]="isSecureAPI && credForm.invalid" mat-button (click)="getAPI(apiUrlID.value)">
              Fetch
            </button>
          </div>
          <form [formGroup]="credForm" class="secured-api">
            <div class="is-secure-check">
              <label>Is this a HTTP Post API? &nbsp;</label>
              <input formControlName="isSecureApiCheck" type="checkbox" [(ngModel)]="isSecureAPI">
            </div>
            <div *ngIf="isSecureAPI" class="credentials">
              <input placeholder="Request Body" [(ngModel)]="apiRequestBody" formControlName="requestBody" type="text">
              <div class="error-style" *ngIf="credForm.get('requestBody')?.invalid">
                <span *ngIf="credForm.get('requestBody')?.touched && !isGetClicked">Request Body is Required</span>
                <span *ngIf="isGetClicked && credForm.get('requestBody') is null">Request Body is Required</span>
              </div>
              <!-- <input placeholder="Password" [(ngModel)]="apiPassKey" formControlName="passKey" type="password">
              <div class="error-style" *ngIf="credForm.get('passKey')?.invalid">
                <span *ngIf="credForm.get('passKey')?.touched && !isGetClicked">Password is Required</span>
                <span *ngIf="isGetClicked && credForm.get('passKey') is null">Password is Required</span>
              </div> -->
            </div>
          </form>
          <div class="mapper-box">

          </div>
          <p style="margin:2% 0 2% 40%;">OR</p>
          <p>Click on the below button to upload a Excel sheet:</p>
          <input class="excelButton" id="custom" #filename type="file" (change)="onFileChange($event)" multiple="false" (click)="filename.value=''" accept=".xlsx"/>
        <label for="custom" class="width-property upload-btn common-styling primary-btn">Upload Excel File</label>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel class="expansion-panel-container" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>electric_bolt</mat-icon> &nbsp; API (Dynamic userdata)
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="column">
          <p>In order to publish PSV with dynamic datas you need to configure a web API which returns dynamic user data.
          </p>
          <div fxLayout="row">
            <input #dynamicApiUrlID class="width-property api-url-input" type="url" placeholder="Enter Dynamic API URL">
            <button class="secondary-btn fetch-btn" mat-button (click)="data.isDynamicAPI = true; getAPI(dynamicApiUrlID.value)">
              Fetch
            </button>
          </div>
          <div class="is-secure-check">
            <label>Is this an API which responds with session relevant data? &nbsp;</label>
            <input type="checkbox" [(ngModel)]="isOneLink">
          </div>
          <!-- <form [formGroup]="credForm" class="secured-api">
            <div class="is-secure-check">
              <label>Is this a HTTP Post API? &nbsp;</label>
              <input formControlName="isSecureApiCheck" type="checkbox" [(ngModel)]="isSecureAPI">
            </div>
            <div *ngIf="isSecureAPI" class="credentials">
              <input placeholder="Username" [(ngModel)]="apiUserName" formControlName="userName" type="text">
              <div class="error-style" *ngIf="credForm.get('userName')?.invalid">
                <span *ngIf="credForm.get('userName')?.touched && !isGetClicked">Username is Required</span>
                <span *ngIf="isGetClicked && credForm.get('userName') is null">Username is Required</span>
              </div>
              <input placeholder="Password" [(ngModel)]="apiPassKey" formControlName="passKey" type="password">
              <div class="error-style" *ngIf="credForm.get('passKey')?.invalid">
                <span *ngIf="credForm.get('passKey')?.touched && !isGetClicked">Password is Required</span>
                <span *ngIf="isGetClicked && credForm.get('passKey') is null">Password is Required</span>
              </div>
            </div>
          </form> -->
          <div class="mapper-box">

          </div>
          <p style="margin:2% 0 2% 40%;">OR</p>
          <button fxLayout="row" fxLayoutAlign="center" faxLayoutGap="5"
            class="width-property upload-btn common-styling primary-btn" disabled>
            <em class="material-icons">file_upload</em>
            Upload File
          </button>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel class="expansion-panel-container" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <!-- <i style="font-size: 1.8rem; padding: 0.2rem; margin-right: 1rem;"
             class="fa-solid fa-upload"></i> -->
             <mat-icon class="material-icons-outlined">file_download</mat-icon> &nbsp;
              Export Metadata
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="column">
          <p>Please select the options of your choice and export. Please go through the instructions given along with Metadata.
          </p>
          <div fxLayout="column">
            <div class="export-option">
              <p class="dialog-text">Obfuscate Metadata</p> <mat-slide-toggle color="primary" (change)="data.isObfuscateOn = $event.checked"></mat-slide-toggle>
            </div>
            <div class="export-option">
              <p class="dialog-text">Captions support</p> <mat-slide-toggle color="primary" #enableCaptions (change)="$event.checked?null:enableTranscripts.checked = false;toggleCaptionsSupport($event)"></mat-slide-toggle>
            </div>
            <div class="export-option">
              <p class="dialog-text">Transcripts support</p> <mat-slide-toggle color="primary" #enableTranscripts (change)="$event.checked?enableCaptions.checked = true:null; toggleTranscriptsSupport($event)"></mat-slide-toggle>
            </div>
            <div class="export-option">
              <p class="dialog-text">Adaptive Streaming and Encryption</p> <mat-slide-toggle color="primary" (change)="data.isDashAvailable = $event.checked"></mat-slide-toggle>
            </div>
            <div class="mat-dialog-actions" mat-dialog-actions>
              <button (click)="data.exportToJsonFile()" mat-button class="submit secondary-btn export-btn">
                Export
              </button>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

    </mat-accordion>
    <strong style="margin-top: 3rem;" class="sub-heading main-headings">Other Settings</strong>

    <mat-form-field class="date-picker-container">
      <mat-label>Set Expiration Date</mat-label>
      <input matInput #expDate type="datetime-local" [min]="minDate" #dateInput [(ngModel)]="selectedTime" (change)="pastSelection(dateInput.value)" [max]="maxDate" placeholder="MM-DD-YYYY HH:MM:SS" pattern="(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})" >
    </mat-form-field>
    <button mat-raised-button (click)="expireDuration(expDate.value)">Save</button>
  </div>
  <div fxLayout="row" fxLayoutGap="5">
    <input type="checkbox" id="clientDemoCheck" [(ngModel)]="isClientDemo">
    <label for="secureLink" class="secure-link-container sub-heading main-headings">Client Demo</label>
  </div>
    <div fxLayout="row" fxLayoutGap="5">
      <input type="checkbox" id="secureLink" (change)="setSecureLink()">
      <label for="secureLink" class="secure-link-container sub-heading main-headings">Secure Link</label>
    </div>
    <div *ngIf="isSecureLink" fxLayout="row"
      class="set-password-container secure-link-container common-styling date-picker-container">
      <input matInput #passcode maxlength ="4" class="set-password-subcontainer " [type]="inputFieldType? 'text':'password'" placeholder="Set Password for 4 digit" required >
      <button style="border: none;" (click)="inputFieldType = !inputFieldType" >
        <em class="fa set-password-container" [ngClass]="{'fa-eye-slash': !inputFieldType,'fa-eye': inputFieldType}"></em>
      </button>
      <button mat-button (click)="getPasscode(passcode.value)">Save</button>
    </div>
    <!-- <button mat-button class="publishBtn primary-btn" (click)=" generateURL()">Publish</button> -->
  </div>

<div class="container publish-container" *ngIf="data.isPublishClicked" fxLayout="column">
  <div fxLayout="row">
    <strong>PSV(s) published successfully. Find the sharable link(s) below.</strong>
  </div>
  <div fxLayout="column" class="publish-sub-container" *ngFor="let j of finalKeyArray;let index=index">
    <div fxLayout="column">
      <div class="user-container">
        <p *ngIf="data.isFewUsers">{{j}}&apos;s PSV</p>
        <p *ngIf="!data.isFewUsers">{{j}}&apos;s PSV</p>
      </div>
      <div fxLayout="row" fxLayoutGap="5px">
        <!-- <div>&colon;</div> -->
        <div class="psv-url-container" fxLayout="row" fxLayoutAlign="end">
          <div class="urlToBeCopied">{{urlArray[index]}} </div>
          <button mat-button matTooltip="Copy Link" (click)="data.showClipboardSnackBar()" [cdkCopyToClipboard]="urlArray[index]">
            <mat-icon>content_copy</mat-icon>
          </button>
          <button mat-button (click)="openEmbedDialog(embedUrlArray[index])" matTooltip="Embed this PSV">
            <mat-icon>code</mat-icon>
          </button>
          <img alt="qrCode" class="qr-code"  src="../../assets/images/qr-code-scan-icon.svg" (click)="openQRModal(urlArray[index])" >

        </div>
      </div>
    </div>
  </div>
  <!-- <button class="publishBtn primary-btn" mat-button routerLink="" (click)="reloadData()">Go to Home</button> -->
</div>
