/*******************************************************************************
 * COPYRIGHT NOTICE © 2017 Infosys Limited, Bangalore, India. All Rights
 * Reserved. Infosys believes the information in this document is accurate as of
 * its publication date; such information is subject to change without notice.
 * Infosys acknowledges the proprietary rights of other companies to the
 * trademarks, product names and such other intellectual property rights
 * mentioned in this document. Except as expressly permitted, neither this
 * documentation nor any part of it may be reproduced, stored in a retrieval
 * system, or transmitted in any form or by any means, electronic, mechanical,
 * printing, photocopying, recording or otherwise, without the prior permission
 * of Infosys Limited and/or any named intellectual property rights holders
 * under this document.
 ******************************************************************************/
import { Component, OnInit, Inject, ElementRef, ViewChildren } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { AudioAnnotation } from '../audio-annotation.model';
import { Psv2AudioToolbarService } from '../psv2-audio-toolbar.service';
@Component({
  selector: 'change-tts-dialog',
  templateUrl: './change-tts-dialog.component.html',
  styleUrls: ['./change-tts-dialog.component.css']
})
export class ChangeTTSDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ChangeTTSDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, readonly dataService: Psv2AudioToolbarService) { }

  ttsProvider:any;
  faVolumeUp = faVolumeUp;
  sampleAudio:any;
  params:any = {
    ttsProviderJSON:JSON,
    ttsProviderTypes:[],
    selectedVoice:"",
    selectedLanguage:"",
    ttsEngine:"standard",
    voicesList:[],
    ttsvoiceType:"",
    languagesList:[],
    languageCode:"",
    splitString:[],
    text:""
  };
  audioAnnotation!: AudioAnnotation;
  changeAudio:boolean=true;

  ngOnInit() {
    this.ttsProvider = this.dataService.tts;
    if(!this.dataService.isTtsProviderChanged){
    this.changeTTSProvider();
    }else{
      this.params.ttsEngine = this.dataService.ttsProviderOptions.ttsEngine;
      this.params.ttsProviderJSON=this.dataService.ttsProviderOptions.ttsProviderJSON;
      this.params.selectedLanguage = this.dataService.ttsProviderOptions.selectedLanguage;
      this.params.selectedVoice = this.dataService.ttsProviderOptions.selectedVoice ;
      this.params.voicesList=this.dataService.ttsProviderOptions.voicesList;
      this.params.languagesList=this.dataService.ttsProviderOptions.languagesList;
      this.params.ttsvoiceType=this.dataService.ttsProviderOptions.ttsvoiceType;
      this.params.ttsProviderTypes=this.dataService.ttsProviderOptions.ttsProviderTypes;
      this.updateSampleAudio(this.dataService.ttsProviderOptions);
    }
  }

   changeTTSProvider() {
    this.dataService.tts = this.ttsProvider;
    if (this.ttsProvider == "polly") {
      this.dataService.getPollyVoicesList().subscribe(result => {
        this.params.ttsProviderJSON = result;
        this.params.languagesList = Object.keys(result);
        this.params.selectedLanguage =  this.params.languagesList[0];
        this.params.voicesList =  this.params.ttsProviderJSON[this.params.selectedLanguage];
        this.params.selectedVoice =  this.params.voicesList[0];
        this.updateSampleAudio(this.params);
      });
    } else if (this.ttsProvider === "google") {
      this.dataService.getGoogleVoicesList().subscribe(result => {
        this.params.ttsProviderJSON = result;
        this.params.ttsProviderTypes = Object.keys(result);
        this.params.ttsvoiceType =  this.params.ttsProviderTypes[0];
        this.params.languagesList = Object.keys(result[ this.params.ttsvoiceType]);
        this.params.selectedLanguage =  this.params.languagesList[1];
        this.params.voicesList = result[ this.params.ttsvoiceType][ this.params.selectedLanguage].voiceName;
        this.params.selectedVoice = this.params.voicesList[0];
        this.params.splitString =  this.params.selectedVoice.split("-");
        this.params.languageCode = this.params.splitString[0] + "-" + this.params.splitString[1];
        this.updateSampleAudio(this.params);
      });
    } else if (this.ttsProvider == "azure") {
      this.dataService.getAzureVoicesList().subscribe(result => {
        this.params.ttsProviderJSON = result;
        this.params.ttsProviderTypes = Object.keys(result);
        this.params.ttsvoiceType =  this.params.ttsProviderTypes[0];
        this.params.languagesList = Object.keys(result[ this.params.ttsvoiceType]);
        this.params.selectedLanguage =  this.params.languagesList[1];
        this.params.voicesList = result[ this.params.ttsvoiceType][ this.params.selectedLanguage].voiceName;
        this.params.selectedVoice = this.params.voicesList[0];
        this.params.splitString = this.params.selectedVoice.split("-");
        this.params.languageCode = this.params.splitString[0] + "-" + this.params.splitString[1];
        this.updateSampleAudio(this.params);
      });
    }
    
  }

  updateSampleAudio(params:any) {
    this.changeAudio=true;
    this.dataService.getSampleTTSAudio(params).then((audioObj:any) => {
      this.sampleAudio = audioObj[0].url;
      this.changeAudio=false;
    }).catch(err => {
      console.log(err)
    });
  }

  changeVoiceType() {
    this.params.languagesList = Object.keys( this.params.ttsProviderJSON[ this.params.ttsvoiceType]);
    this.params.selectedLanguage =  this.params.languagesList[1];
    this.params.voicesList =  this.params.ttsProviderJSON[ this.params.ttsvoiceType][ this.params.selectedLanguage].voiceName;
    this.params.selectedVoice = this.params.voicesList[0];
    this.params.splitString =  this.params.selectedVoice.split("-");
    this.params.languageCode = this.params.splitString[0] + "-" + this.params.splitString[1];
    this.updateSampleAudio(this.params);
  }

  changeTTSLanguage() {
    if (this.ttsProvider === "polly") {
      this.params.voicesList =  this.params.ttsProviderJSON[ this.params.selectedLanguage];
      this.params.selectedVoice = this.params.voicesList[0];
    } else {
      this.params.voicesList =  this.params.ttsProviderJSON[ this.params.ttsvoiceType][ this.params.selectedLanguage].voiceName;
      this.params.selectedVoice = this.params.voicesList[0];
      this.params.splitString =  this.params.selectedVoice.split("-");
      this.params.languageCode = this.params.splitString[0] + "-" + this.params.splitString[1];
      } 
    this.updateSampleAudio(this.params);
  }

  changeTTSVoice() {
    if (!this.params.voicesList.includes(this.params.selectedVoice)) {
      this.params.selectedVoice = this.params.voicesList[0];
      const classList = ["error-snackbar"];
      this.data.showSnackBar("Voice not available", classList);
    } 
      this.params.splitString = this.params.selectedVoice.split("-");
      this.params.languageCode = this.params.splitString[0] + "-" + this.params.splitString[1];
  
    this.updateSampleAudio(this.params);
  }

  updateTTS() {
    this.dataService.tts = this.ttsProvider;
    if (this.dataService.overlayJSON.audios.length > 0) {
      for (const audioObject of this.dataService.overlayJSON.audios) {
        if (audioObject.tts === "polly") {
          this.params.text = audioObject.params.Text;
        } else if (audioObject.tts === "google") {
          this.params.text = audioObject.params.input.ssml;
        } else if (audioObject.tts === "azure") {
          this.params.text = audioObject.params.text;
        }
        this.audioAnnotation = new AudioAnnotation(audioObject.id, audioObject.start, audioObject.end, this.ttsProvider);
        this.audioAnnotation.initializeAttributes(this.params,this.ttsProvider);
        this.dataService.overlayJSON.audios[audioObject.id - 1].params =
          JSON.parse(JSON.stringify(this.audioAnnotation.getAttributes(this.ttsProvider).params));
        this.dataService.overlayJSON.audios[audioObject.id - 1] = this.audioAnnotation.transformAttributes(audioObject, this.ttsProvider);
      }
      this.dataService.updateAudio = true;
      // this.dataService.notifyTTSUpdate("Updated tts");
      this.dataService.isLoaderEnabled = true;
      // this.dataService.overlayAnnotations();
    }
    this.dataService.isTtsProviderChanged=true;
    this.dialogRef.close();
  }

  dismiss(): void {
    this.dialogRef.close();
  }

}


