import { Component, OnInit } from '@angular/core';
import { DataService } from '../shared/services/data.service';
import { Project_Meta } from '../shared/models/project.model';
import { MatDialog } from '@angular/material/dialog';
import { NewProjectUploaderComponent } from './new-project-uploader/new-project-uploader.component';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { SceneModel } from '../shared/models/scene.model';
import { KeycloakService } from 'keycloak-angular';
import { StorageService } from '../shared/services/storage.service';
import { trigger,state,style,animate,transition} from '@angular/animations';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { filter } from 'rxjs';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { ProfileInfo } from '../shared/interfaces/profile-info';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
      trigger('flyInOut', [
        transition('void => *', [
          animate("0ms 20s")
        ]),
        transition('* => void', [
          animate(1200, style({ transform: 'translatey(100%)' }))
        ])
      ])
    ]
})

export class HomeComponent implements OnInit {
  projects: Project_Meta[] = [];
  analyticsDescription = "Have a Sneak Peek into the PSV User Engagement"
  sceneDatas: any = [];
  recentProjectLoader: boolean = false;
  publishedPSVCount: number = 0;
  userProfileInfo: any = {};
  deleteProjectId: number= -1;
  openConfirmationModal: boolean = false;
  files: any = [];
  PSVids: any = [];
  SceneIds: any = [];
  userFirstName: any;
  userLastName: any;
  isGuest: boolean = false;
  profileInfo: ProfileInfo;
  isIframe = false;
  loginDisplay = false;

  constructor(public data: DataService, public http: HttpClient, private router: Router, public dialog: MatDialog, public storageService: StorageService, private authService: MsalService, private msalBroadcastService: MsalBroadcastService) { }

  async ngOnInit(): Promise<void> {
    this.isIframe = window !== window.parent && !window.opener;
    this.msalBroadcastService.msalSubject$
      .subscribe((result: EventMessage) => {
        console.log(result);
      });

    this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None)
    )
    .subscribe(() => {
      this.data.getProfileInfo().then(profileInfo =>{
        this.profileInfo = profileInfo;
        this.userFirstName = this.profileInfo ? this.profileInfo.displayName : "Guest";
        this.data.userId = this.profileInfo ? this.profileInfo.mail.replace('@infosys.com','') : undefined;
        if (this.data.reload) {
          window.location.reload();
          this.data.reload = false;
        }
        if(this.data.userId){
          this.recentProjectLoader = true;
          this.data.fetchAllProjectsByUserId(this.data.userId).then((data: any) => {
            if (data) {
              for (let item of data) {
                  let project = new Project_Meta(item.title, item.description, item.script, item.createDate, item.lastModifiedDate, item._id, item.concatenatedPSV, item.bgAudioUrl, item.bgAudioVolume,item.createdBy) ;
                  project.scenes = item.scenes;
                  project.isPublished = item.isPublished ? true : false;
                  this.projects.push(project);
              }
            }
            this.projects.reverse();
            this.recentProjectLoader = false;
          }).catch(this.data.handleAPIErrors);
        }
        else{
          this.isGuest = true;
        }
      })
    })
      // let userLoggedIn = await this.data.keycloak.isLoggedIn();
    // this.userProfileInfo = userLoggedIn ? await this.data.keycloak.loadUserProfile() : null;
    // this.userFirstName = this.userProfileInfo ? this.userProfileInfo.firstName : "Guest";
    // this.data.userId = this.userProfileInfo ? this.userProfileInfo.email.split('@')[0] : undefined;
  }

  login() {
    this.authService.loginRedirect();
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }


  openDialog() {
    let dialogRef = this.dialog.open(NewProjectUploaderComponent, { disableClose: true });
  }

  createNewProject() {
    let date = new Date();
    let project = new Project_Meta('', '', '', date, date);
    this.data.projectModel = project;
    this.router.navigate(['/editor']);
  }

  async loadProject(id: any) {
    this.data.stateChanged = false;
    this.data.scenes = [];
    this.data.titleValue = this.projects[id].title;
    this.data.descriptionValue = this.projects[id].description;
    this.data.fileContent = this.projects[id].script;
    this.data.projectModel = this.projects[id];
    // if(this.data.projectModel.bgAudioUrl){
    //   this.data.isBgAudioAdded = true;
    // }

    let scenePromises: any = [];
    let psvPromises: any = [];

    for (let sceneId of this.data.projectModel.scenes) {
      scenePromises.push(this.data.fetchScene(sceneId));
    }
    await Promise.all(scenePromises).then(async (sceneDatas: any) => {
      this.sceneDatas = sceneDatas;
      for (let sceneData of sceneDatas) {
        psvPromises.push(this.data.fetchPSV(sceneData.PSV));
      }
      await Promise.all(psvPromises).then(async (psvDatas: any) => {
        this.data.userDataId = psvDatas[0].userDatalist.user1;
        await this.data.fetchUserDataById(psvDatas[0].userDatalist.user1).then((data: any) => {
          this.data.userData = data.userData;
        }).catch(this.data.handleAPIErrors);
        for (let index = 0; index < psvDatas.length; index++) {
          this.data.scenes.push(new SceneModel(this.sceneDatas[index].playerId, this.sceneDatas[index].script, psvDatas[index].videoSourceObj.path, this.data));
          this.data.scriptArray.push(this.data.scenes[index].script);
          this.data.scenes[index]._id = this.sceneDatas[index]._id;
          this.data.scenes[index].duration = this.sceneDatas[index].duration;
          this.data.scenes[index].videoMeta = this.data.transformOverlayObject(psvDatas[index].psvMetaDataObj);
          if(!this.data.bgAudioObj && this.data.scenes[index].videoMeta.backgroundAudio){
            this.data.bgAudioObj = this.data.scenes[index].videoMeta.backgroundAudio;
          }
          this.data.scenes[index].videoSource = psvDatas[index].videoSourceObj;
          this.data.scenes[index].psvId = this.sceneDatas[index].PSV;
          this.data.scenes[index].isDefaultAnnotationsAdded = true;
          this.data.scenes[index].setAnnotationTableData();
        }
        this.data.onCreateVideo = true;
        this.data.onPublishBtn = true;
        this.router.navigate(['/editor']);
      })
    }).catch(this.data.handleAPIErrors);
  }
  deleteConfirmation(Id: any) {
    this.openConfirmationModal = true;
    this.deleteProjectId = Id;
  }

  closeModal() {
    this.openConfirmationModal = false;
    this.deleteLoader=false;
  }
  deleteLoader:boolean=false;
  async deleteProject() {
    this.deleteLoader=true;
    //Getting all Ids of a Project

    if (this.projects[this.deleteProjectId].scenes.length !== 0) {
      for (var i = 0; i < this.projects[this.deleteProjectId].scenes.length; i++) {
        await this.data.fetchScene(this.projects[this.deleteProjectId].scenes[i]).then((data: any) => {
          this.PSVids[i] = data.PSV;
          this.SceneIds[i] = this.projects[this.deleteProjectId].scenes[i];
        });
      }
    } else { this.data.showSnackBar("Scenes Not Found", "OK"); }

    // Deletion Process starts from here

    if (this.deleteProjectId !== -1 && this.PSVids && this.SceneIds) {
      for (var i = 0; i < this.projects[this.deleteProjectId].scenes.length; i++) {
        this.data.deletePSV(this.PSVids[i]);
        await this.data.deleteScene(this.projects[this.deleteProjectId].scenes[i]);
      }
      await this.storageService.deleteProject(this.projects[this.deleteProjectId].id).then(async (data: any) => {
        await this.data.deleteProject(this.projects[this.deleteProjectId].id).then(() => {
          this.projects.splice(this.deleteProjectId, 1);
          this.openConfirmationModal = false;
          this.deleteLoader=false;
          this.data.showSnackBar("Project Deleted Succesfully", "Ok");
        }).catch(this.data.handleAPIErrors);
      }).catch(this.data.handleAPIErrors);
    }
    else {
      this.data.showSnackBar("Unable to Delete this Project Please Contact Admin", "OK");
    }
  }
}
