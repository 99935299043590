import { Component, OnInit } from '@angular/core';
import { DataService } from '../shared/services/data.service';
@Component({
  selector: 'app-help-page',
  templateUrl: './help-page.component.html',
  styleUrls: ['./help-page.component.css']
})
export class HelpPageComponent implements OnInit {

  constructor(public data:DataService) {  }

  ngOnInit(): void {
    this.data.isHomePage=false;
  }
  playVideo() {
    this.data.isVideoPlaying = true;
  }
}
