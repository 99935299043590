<!------------------------------------------------------------------------------
 # COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
 # Reserved. Infosys believes the information in this document is accurate as of
 # its publication date; such information is subject to change without notice.
 # Infosys acknowledges the proprietary rights of other companies to the
 # trademarks, product names and such other intellectual property rights
 # mentioned in this document. Except as expressly permitted, neither this
 # documentation nor any part of it may be reproduced, stored in a retrieval
 # system, or transmitted in any form or by any means, electronic, mechanical,
 # printing, photocopying, recording or otherwise, without the prior permission
 # of Infosys Limited and/or any named intellectual property rights holders
 # under this document.
-------------------------------------------------------------------------------->

  <div class="inner-properties">
    <div (click)="minimizeToolBar()" class="toolbar-minimize" *ngIf="isToolBarOpen">
      <em class="icon-class material-icons">highlight_off</em>
    </div>
    <div>
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title class="heading-container">CAPTION PROPS. </mat-panel-title>
        </mat-expansion-panel-header>
        
          <div class="tree-holder" id="captionList" #scrollMe [scrollTop]="scrolltop">
            <div *ngFor="let item of this.data.scenes[this.data.selectedSceneIndex].caption_annotationTableData; let i = index; trackBy: trackByFn">
              <form class="caption-group">
                <fa-icon [icon]="faTimes" class="cancel-icon" (click)="removeCaption(i)" matTooltip="Remove caption"
                  matTooltipPosition=left></fa-icon>
        
                 <div class="row caption-fields">
                  <div class="properties-bar time-box custom-properties-bar">
                    <label class="font-size-sm">START TIME &nbsp;
                      <input #startTime class="input-time start-field" type="number" min="0.000" step="0.001"
                      [ngModel]="item.start  | number" (blur)="setStartTimeMinVal()"
                      (ngModelChange)="item.start = $event" (input)="changeStartTime(startTime.value,endTime.value, i)"
                      [ngModelOptions]="{standalone: true}">
                    </label>
                    <label class="font-size-sm">END TIME
                      <input #endTime class="input-time end-field" type="number"
                        min="0.000" step="0.001" pattern="^\d+(?:\.\d{1,3})?$" [max]="this.data.videoDuration"
                        [ngModel]="item.end  | number" (blur)="setStartTimeMinVal()"
                      (input)="changeEndTime(startTime.value,endTime.value, i)" (ngModelChange)="item.end = $event"
                      [ngModelOptions]="{standalone: true}">
                    </label>
                  </div>
                  <div class="properties-bar time-box custom-properties-bar">
                    <label class="font-size-sm">CAPTION WIDTH (in %)</label>
                    <div class="input-time1 end-field" style="margin-right: 5px;" id="{{'text-length-'+i}}" [ngClass]="{'error-field':!item.size.valid}">
                      <input class="input-time1 end-field" name="text-length" (keydown)="_keydown($event)" type="text"
                        maxlength="3" placeholder="00" [(ngModel)]="item.size.value">
                    </div>
                  </div>
        
                </div>
                <div class="row caption-padding">
                  <div class="properties-bar-caption time-box custom-properties-bar">
                    <label class="font-size-sm">CAPTION</label>
                    <textarea id="{{'text-content-'+i}}" name="caption-text" class="form-control caption-area"
                      [(ngModel)]="item.caption_text" (input)="changeAnnotationValue(i)"></textarea>
                  </div>
                </div>
              </form>
            </div>
          </div>
        
          <div class="btn-holder">
            <button class="cancel" matTooltip="select file to upload" mat-button (click)="uploadFile.click()">
              <i class="material-icons pointer" aria-hidden="true">
                cloud_upload
              </i>

              <div class="upload-file">
                <input #uploadFile style="display:none;" class="custom-file-input" id="userVtt" accept=".txt,.vtt,.srt"
                  (change)="uploadCaption($event)" type="file">
              </div>
            </button>
            <button class="btn-circle btn-xl" (click)="this.tool.initializeCaptionOverlayObject('caption')" matTooltip="Add captions" matTooltipPosition=above>
              <fa-icon [icon]="faPlus"></fa-icon>
            </button>
            <div class="btn-break-line">
              <button mat-button (click)="updateData()" class="submit">Update</button>
            </div>
          </div>
      </mat-expansion-panel>
    </div>
  
  
  </div>