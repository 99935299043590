import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatScript'
})
export class FormatScriptPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    let result = value.replaceAll('\n','\n==========\n');
    return result;
  }

}
