import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { faPlusCircle, faMinusCircle, faCaretRight, faVolumeUp, faCog } from '@fortawesome/free-solid-svg-icons';
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import { MatDialog } from '@angular/material/dialog';

declare var psv: any;

@Component({
  selector: 'psv-image-overlay',
  templateUrl: './image-overlay.html',
  styleUrls: ['./image-overlay.css']
})
export class ImageOverlayComponent implements OnInit, OnChanges {

@Input() overlayJSON:any;
@Input() selectedAnnotation: any;
@Input() annotationTableData:any = [];
@Input() currentTime: any;
@Input() userData:any = {};
@Input() imageOverlayComponent:any;
@Input() fontSize:any;
@Input() aspectRatio:any;
@Input() isToolBarOpen!:boolean;
@Output() change = new EventEmitter<MatSlideToggleChange>();
@Output() overlayJSONEvent =new EventEmitter<any>();
@Output() openGalleryEvent =new EventEmitter<any>();
@Output() overlayAnnotationsEvent = new EventEmitter<any>();
@Output() updateCurrentTimeEvent = new EventEmitter<number>();
@Output() annotationTableDataEvent = new EventEmitter<any>();
@Output() selectedAnnotationEvent = new EventEmitter<any>();
@Output() setDraggerPositionEvent = new EventEmitter<any>();
@Output() imageOverlayComponentOutput = new EventEmitter<any>();
@Output() onMinimizing = new EventEmitter<boolean>();

faPlusCircle = faPlusCircle;
faMinusCircle = faMinusCircle;
faCaretRight:any = faCaretRight;
faVolumeUp: any = faVolumeUp;
faCog:any = faCog;
imageRepoUrl: string='';
pathUrl:any;
// aspectRatio: any=2;

constructor(public dialogRef : MatDialog) { }

ngOnInit(): void {
  if(this.imageOverlayComponent.srcUrl){
    this.pathUrl = this.imageOverlayComponent.srcUrl;
  }
}

ngOnChanges(changes: SimpleChanges): void {
  console.log("onchanges",this.aspectRatio);
}

onFileSelected(e:any){
  let imageData: any;
  let imageWidth: number;
  let imageHeight: number;
  const imageSource:ArrayBuffer = e.srcElement.files[0];
  const imageName = e.srcElement.files[0].name;
  const extn = imageName.split('.')[1];
  const img = new Image();
  img.onload = function() {
    imageWidth = img.width;
    imageHeight = img.height;
  };
  let imgBlob = new Blob([imageSource], { type: 'image/' + extn });
  img.src = URL.createObjectURL(imgBlob);
  const reader = new FileReader();
  reader.onloadend = function() {
    imageData = URL.createObjectURL(imgBlob);
  }
  reader.readAsArrayBuffer(imgBlob);
  const inputDataFlag = setInterval(() => {
    if(imageData && imageData !== "" && imageWidth && imageHeight) {
      this.imageOverlayComponent.srcUrl = "";
      this.aspectRatio = imageWidth/imageHeight;
      this.imageOverlayComponent.cssProperties.width = imageWidth/this.fontSize + "em";
      this.imageOverlayComponent.cssProperties.height = imageHeight/this.fontSize + "em";
      this.imageOverlayComponent.imageData = imageData;
      this.annotationTableData[this.getAnnotationTableId(this.imageOverlayComponent)].value=e.srcElement.files[0].name;
      this.imageOverlayComponent.altText = e.srcElement.files[0].name;
      this.imageOverlayComponent.imageName = e.srcElement.files[0].name;
      this.overlayJSONEvent.emit(this.overlayJSON);
      this.annotationTableDataEvent.emit(this.annotationTableData);
      this.loadElement();
      clearInterval(inputDataFlag);
    }
  }, 10);
  }
  updateImageHeight(height:any){
    this.imageOverlayComponent.cssProperties.height = `${height}em`;
    if(this.imageOverlayComponent.maintainAspectRatio){
      const widthInPx = (height*this.fontSize)*this.aspectRatio;
      this.imageOverlayComponent.cssProperties.width = `${(widthInPx/this.fontSize)}em`;
    }
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.annotationTableDataEvent.emit(this.annotationTableData);
    this.loadElement();
  }
  updateImageWidth(width:any){
    this.imageOverlayComponent.cssProperties.width = `${width}em`;
    if(this.imageOverlayComponent.maintainAspectRatio){
      const heightInPx = (width*this.fontSize)/this.aspectRatio;
      this.imageOverlayComponent.cssProperties.height = `${(heightInPx/this.fontSize)}em`;
    }
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.annotationTableDataEvent.emit(this.annotationTableData);
    this.loadElement();
  }
  toggleMaintainAspectRatio(){
    this.imageOverlayComponent.maintainAspectRatio = !this.imageOverlayComponent.maintainAspectRatio;
    const height = this.imageOverlayComponent.cssProperties.height.split("em")[0];
    const widthInPx = (height*this.fontSize)*this.aspectRatio;
    this.imageOverlayComponent.cssProperties.width = `${(widthInPx/this.fontSize)}em`;
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.annotationTableDataEvent.emit(this.annotationTableData);
    this.loadElement();
  }
  loadElement(updatedOverlayAnnotation?: any) {
    this.overlayAnnotations(updatedOverlayAnnotation);
    this.setDraggerPosition();
  }
  setBgColor(color:any){
    this.imageOverlayComponent.cssProperties.outlineColor = `${color}`;
    this.imageOverlayComponent.cssProperties.backgroundColor = `${color}`;
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.annotationTableDataEvent.emit(this.annotationTableData);
    this.loadElement();
  }
  //newOverlayObject:any;
  overlayAnnotations(updateAnnotation: any) {
    this.overlayAnnotationsEvent.emit(updateAnnotation);
    this.annotationTableDataEvent.emit();
    this.selectedAnnotationEvent.emit();
  }
  setDraggerPosition() {
      this.setDraggerPositionEvent.emit();
  }
  getAnnotationTableId(overlayObj:any) {
    for (let index in this.annotationTableData) {
      if (this.annotationTableData[index].type === "tts" && overlayObj.type === "tts" && overlayObj.id === this.annotationTableData[index].id) {
        return index;
      } else if (this.annotationTableData[index].type !== "tts" && overlayObj.type !== "tts" && overlayObj.id === this.annotationTableData[index].id) {
        return index;
      }
    }
    return "";
  }

  overlayJSONFunction(overlayJSON:any){
    this.overlayJSONEvent.emit(overlayJSON);
  }
  toTransparent(){
    if(this.imageOverlayComponent.bgToggle===false){
      this.imageOverlayComponent.cssProperties.backgroundColor="transparent";
      this.imageOverlayComponent.cssProperties.outlineColor = "transparent";
      this.imageOverlayComponent.cssProperties.outlineWidth = `0.0em`;
    }
    else{
      this.imageOverlayComponent.bgToggle=true;
      this.imageOverlayComponent.cssProperties.backgroundColor="#000000";
      this.imageOverlayComponent.cssProperties.outlineColor ="#000000";
    }
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.annotationTableDataEvent.emit(this.annotationTableData);
    this.loadElement();     
  }

  increaseOutlineSize() {
    const paddingSize: string = this.imageOverlayComponent.cssProperties.outlineWidth;
    this.imageOverlayComponent.cssProperties.outlineWidth = `${+paddingSize.replace("em", "") + 0.01}em`;
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.loadElement();
  }

  decreaseOutlineSize() {
    const paddingSize: string = this.imageOverlayComponent.cssProperties.outlineWidth;
    this.imageOverlayComponent.cssProperties.outlineWidth = `${+paddingSize.replace("em", "") - 0.01}em`;
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.loadElement();
  }

  updateOutlineSize(number:any){
    this.imageOverlayComponent.cssProperties.outlineWidth = `${number}em`;
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.loadElement();
  }

  decreaseBorderRadius() {
    const borderRadius: string = this.imageOverlayComponent.cssProperties.borderRadius;
    this.imageOverlayComponent.cssProperties.borderRadius = `${+borderRadius.replace("em", "") - 0.01}em`;
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.loadElement();
  }

  increaseBorderRadius() {
    const borderRadius: string = this.imageOverlayComponent.cssProperties.borderRadius;
    this.imageOverlayComponent.cssProperties.borderRadius = `${+borderRadius.replace("em", "") + 0.01}em`;
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.loadElement();
  }

  updateBorderRadius(number:any){
    this.imageOverlayComponent.cssProperties.borderRadius = `${number}em`;
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.loadElement();
  }

  openGalleryForImage(){
    this.openGalleryEvent.emit();
    this.pathUrl="";
  }

  changeBgColor(inpColor:any) {
    this.imageOverlayComponent.cssProperties.backgroundColor=inpColor;
    this.overlayJSONEvent.emit(this.overlayJSON);
    this.setBgColor(inpColor);
  }

  loadImagePath() {
    let url;
    let userDataFlag = false;
    this.imageOverlayComponent.altText = "Invalid Path";
    this.imageOverlayComponent.srcUrl = this.pathUrl;
    const pattern=/{[A-Za-z0-9.\[\]]+}/;
    if(pattern.test(this.imageOverlayComponent.srcUrl)) {
      url = this.replaceNestedDataValue(this.imageOverlayComponent.srcUrl);
      userDataFlag = true;
    } else {
      url = this.imageOverlayComponent.srcUrl;
    }
    let imageWidth: number;
    let imageHeight: number;
    let errorFlag = false;
    const img = new Image();
    img.onload = function() {
      imageWidth = img.width;
      imageHeight = img.height;
    }
    img.onerror = function(){
      errorFlag = true;
    }
    img.src = url;
    const inputDataFlag = setInterval(() => {
      if(imageWidth && imageHeight) {
        this.aspectRatio = imageWidth/imageHeight;
        this.imageOverlayComponent.cssProperties.width = imageWidth/this.fontSize + "em";
        this.imageOverlayComponent.cssProperties.height = imageHeight/this.fontSize + "em";
        this.imageOverlayComponent.imageData = "";
        this.imageOverlayComponent.imageName = "";
        (document.getElementById("imgSrc") as HTMLInputElement).value = "";
        this.annotationTableData[this.getAnnotationTableId(this.imageOverlayComponent)].value = this.imageOverlayComponent.srcUrl;
        this.overlayJSONEvent.emit(this.overlayJSON);
        this.annotationTableDataEvent.emit(this.annotationTableData);
        this.loadElement();
        clearInterval(inputDataFlag);
      } else if(userDataFlag) {
        this.imageOverlayComponent.imageData = "";
        this.imageOverlayComponent.imageName = "";
        (document.getElementById("imgSrc") as HTMLInputElement).value = "";
        this.annotationTableData[this.getAnnotationTableId(this.imageOverlayComponent)].value = this.imageOverlayComponent.srcUrl;
        this.overlayJSONEvent.emit(this.overlayJSON);
        this.annotationTableDataEvent.emit(this.annotationTableData);
        this.loadElement();
        clearInterval(inputDataFlag);
      } else if(errorFlag) {
        this.loadElement();
        if (!this.selectedAnnotation.srcUrl){
          this.selectedAnnotation.imageData?this.annotationTableData[this.getAnnotationTableId(this.imageOverlayComponent)].value = this.selectedAnnotation.imageName:this.annotationTableData[this.getAnnotationTableId(this.imageOverlayComponent)].value = `image${this.selectedAnnotation.id}`;
        }
        else{
          this.annotationTableData[this.getAnnotationTableId(this.imageOverlayComponent)].value = this.imageOverlayComponent.srcUrl;
        }
        this.overlayJSONEvent.emit(this.overlayJSON);
        this.annotationTableDataEvent.emit(this.annotationTableData);
        clearInterval(inputDataFlag);
      }
    }, 10);
  }
  replaceNestedDataValue(value:any) {
    const nestedData = psv.replaceVariablesWithValues(value, this.userData);
    return nestedData === "NOT FOUND" ? "" : nestedData;
  }


  updateCurrentTime(currentTime: number) {
    this.updateCurrentTimeEvent.emit(currentTime);
  }
  minimizeToolBar() {
    this.onMinimizing.emit(true);
  }
}