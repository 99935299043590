import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeValue } from '@angular/platform-browser';
import * as RecordRTC from 'recordrtc';

@Component({
  selector: 'lib-live-Recording',
  templateUrl: './live-recording.html',
  styleUrls: ['./live-recording.css'
  ]
})
export class LiveRecordingComponent implements OnInit {




  @Output() saveEvent = new EventEmitter<any>();
  @Output() closeEvent = new EventEmitter<any>();
  @Output() continueEvent = new EventEmitter<any>();
  

  videoDuration: any;

  declare record: any;
  recording: boolean = false;

  url: any | SafeValue;
  error: any;
  blobHolder: any;
  constructor(private domSanitizer: DomSanitizer) { }
  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }
  initiateRecording() {
    this.recording = true;
    let mediaConstraints = {
      video: false,
      audio: true
    };
    console.log("rec on");
    navigator.mediaDevices.getUserMedia(mediaConstraints).then(this.successCallback.bind(this), this.errorCallback.bind(this));
  }
  options:any;
  successCallback(stream: any) {
      this.options = {
      mimeType: "audio/mp3",
      numberOfAudioChannels: 1,
      sampleRate: 50000,
    };
  
    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.record = new StereoAudioRecorder(stream, this.options);
    console.log("rec work");
    this.record.record();
  }
  
  stopRecording() {
    this.recording = false;
    console.log("rec off");
    this.record.stop(this.processRecording.bind(this));
  }
    
  enabler: boolean = false;
  processRecording(blob: any) {
    this.enabler = true;
    this.blobHolder = blob;
    this.url = URL.createObjectURL(blob);
  }

  errorCallback(error: any) {
    this.error = 'Cannot play audio in your browser';
  }

  close() {
    this.closeEvent.emit();
  }
  continue() {
    this.saveEvent.emit(this.blobHolder);
    console.log("load" + this.url);
  }
 
  ngOnInit(): void {
  }

}
