<div class="chart-container">
    <div class="modal-title">Define Chart Data</div>
    <div class="category-box">
        <label class="category-title" for="chartKey">Chart Key: </label>
        <input class="category-value" type="text" id="chartKey" (input)="changeChartKey()" [(ngModel)]="data.overlayJSON.overlays[data.selectedAnnotation.id - 1].chartKey">
    </div>
    <div class="category-box">
        <label class="category-title" for="xAxisLabel">Enter X axis Label: </label>
        <input class="category-value" type="text" id="xAxisLabel" [(ngModel)]="xAxisLabel">
    </div>
    <div class="category-box">
        <label class="category-title" for="yAxisLabel">Enter Y axis Label: </label>
        <input class="category-value" type="text" id="yAxisLabel" [(ngModel)]="yAxisLabel">
    </div>


    <mat-accordion *ngIf="xAxisLabel && yAxisLabel" multi="true">
        <div [@.disabled]="disableAnimation" class="dataset-card">
        <mat-expansion-panel [expanded]="false" class="expansion-card" *ngFor="let dataset of data.overlayJSON.overlays[data.selectedAnnotation.id -1].chartData.data; let i=index; trackBy: trackByFn">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ dataset.label ? dataset.label : "New Dataset" }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <fa-icon class="delete-col fa-icon-plus remove-dataset" matTooltip="Remove Dataset" [icon]="faTrashAlt" (click)="removeDataSet(i)"></fa-icon>
            <table aria-describedby="chartData">
                <tr>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
                <tr>
                    <td>
                        <mat-form-field class="example-full-width">
                            <input class="input-height" matInput required [(ngModel)]="dataset.label" placeholder="Enter dataset label (string)">
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="example-full-width">
                            <input class="input-height" matInput [(ngModel)]="dataset.lineColor" placeholder="Enter dataset color (string)">
                        </mat-form-field>
                    </td>
                </tr>
                <tr *ngFor="let data of dataset.dataset; let j=index; trackBy: trackByFn">
                    <td>
                        <mat-form-field class="example-full-width">
                            <input class="input-height" matInput required [(ngModel)]="data.key" placeholder="Enter {{xAxisLabel}} (string)">
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="example-full-width">
                            <input class="input-height" type="number" required matInput [(ngModel)]="data.value" placeholder="Enter {{yAxisLabel}} (number)">
                        </mat-form-field>
                    </td>
                    <td class="delete-col" (click)="removeRow(i, j)">&times;</td>
                    <td class="delete-col" *ngIf="j===(dataset.dataset.length -1)" (click)="addMoreChartInputs(i)">
                        <fa-icon class="fa-icon-plus" matTooltip="Add Data" [icon]="faPlusCircle"></fa-icon>
                    </td>
                </tr>
            </table>
        </mat-expansion-panel>
    </div>
    </mat-accordion>

    <div mat-dialog-actions class="more-inputs" *ngIf="xAxisLabel && yAxisLabel">
        <button mat-button class="cancel" (click)="addDataset()">
            <fa-icon class="fa-icon-plus" [icon]="faPlusCircle"></fa-icon> Add Dataset
        </button>
    </div>

    <div mat-dialog-actions>
        <button mat-button (click)="onClose()" class="cancel">Cancel</button>
        <button mat-button (click)="updateChartData()" class="submit">Submit</button>
    </div>

</div>