import { Component, OnInit, Injectable } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject, Subject } from 'rxjs';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatDialogRef } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { DataService } from 'src/app/shared/services/data.service';
/**
 * Node for to-do item
 */
export class TodoItemNode {
  children!: TodoItemNode[];
  filename!: string;
  item!: string;
}
/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  filename!: string;
  item!: string;
  level!: number;
  expandable!: boolean;
}

@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);
  userData: any;
  typeOfOperation: any;
  apiData: any;
  newJson: any;
  userDataJsonSubject = new Subject<any>();

  get data(): TodoItemNode[] {
    return this.dataChange.value;
  }

  constructor() { }

  initialize() {
    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    //     file node as children.
    const dataObject = JSON.parse(JSON.stringify(this.userData));
    const data = this.buildFileTree(dataObject, 0);

    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
  buildFileTree(obj: { [key: string]: any }, level: number): TodoItemNode[] {
    return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new TodoItemNode();
      node.filename = key;
      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.item = value;
        }
      }
      return accumulator.concat(node);
    }, []);
  }

  updateItem(node: TodoItemNode, name: string) {
    node.item = name;
    this.dataChange.next(this.data);
  }

  transformTreeToJson(obj: any, newJson: any) {
    for (var index in obj) {
      if (obj[index].children && obj[index].children[0].filename != "xAxisLabel" && obj[index].children[1].filename != "yAxisLabel") {
        let newJsonObj = {};
        newJson[obj[index].filename] = this.transformTreeToJson(obj[index].children, newJsonObj);
      }
      else {
        let char = obj[index].item.charAt(obj[index].item.length - 1);
        if(obj[index].item == "" || char == " "){
          return null;
        }else{
          newJson[obj[index].filename] = obj[index].item;
        }
      }
    }
    return newJson;
  }

}

@Component({
  selector: 'app-publish-userdata',
  templateUrl: './publish-userdata.component.html',
  styleUrls: ['./publish-userdata.component.css'],
  providers: [ChecklistDatabase]
})

export class PublishUserdataComponent implements OnInit {
  operationType: any;
  userDataObject: any;
  apiKeys: any;
  apiResponse:any;
  mapperKey: any;
  identifierKey: any;
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<TodoItemFlatNode>;

  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);

  constructor(public _database: ChecklistDatabase, public dialogRef: MatDialogRef<PublishUserdataComponent>, public data: DataService) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren,
    );
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    _database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });

    _database.userDataJsonSubject.subscribe(data => {
      this.userDataObject = data;
    });
  }

  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item ? existingNode : new TodoItemFlatNode();
    flatNode.filename = node.filename;
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    if(node.children?.length > 0 && node.children[0].filename == "xAxisLabel" && node.children[1].filename == "yAxisLabel"){
     flatNode.expandable = false;
    }
    else{
      flatNode.expandable = !!node.children?.length;
    }
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Save the node to database */
  saveNode(node: TodoItemFlatNode, itemValue: any) {
    const nestedNode = this.flatNodeMap.get(node);
    this._database.updateItem(nestedNode!, itemValue);
  }

  ngOnInit(): void {
    this.operationType = this._database.typeOfOperation;
    this.apiKeys = this._database.apiData ? Object.keys(this._database.apiData[0]) : [];
    this._database.initialize();
  }

  ngAfterViewInit() {
    this.treeControl.expandAll();
  }

  transformUserDataArrayToObject() {
    this._database.userData = this.userDataObject;
    this.operationType === 'map' ? this.dialogRef.close([this.userDataObject, this.identifierKey]) : this.dialogRef.close(this.userDataObject);
  }

  updateUserData() {
    this._database.newJson = {};
    this._database.newJson = this._database.transformTreeToJson(this._database.data, this._database.newJson);
    if(this._database.newJson !== null){
      this._database.userDataJsonSubject.next(this._database.newJson);
      const data = this._database.buildFileTree(this._database.newJson, 0);
      this._database.dataChange.next(data);
      this.transformUserDataArrayToObject();
    }else{
      this.data.showSnackBar('Enter Valid Userdata', 'close');
    }
  }

  cancelDialog() {
    this.dialogRef.close('cancel');
  }

  deleteUserData() {
    this.dialogRef.close();
  }

}