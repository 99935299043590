import { DataService } from "../services/data.service";
import { AudioOverlayAnnotation } from "./audio-overlay.model";
export class PSV_Meta {
    playerElementId: string;
    videoSource = {
        type : "default",
        path : "",
        autoplay : false
    };
    psvMetaData;
    userData;
    ttsOptions;
    tts:any;
    isPersonalizeScreen!:boolean;

    constructor(playerElementId: string, videoSource: any, psvMetaData: any, userData: any,ttsOptions?: any,publishScreen?:boolean){
        this.playerElementId = playerElementId;
        this.videoSource = videoSource;
        if(publishScreen){
        this.psvMetaData = psvMetaData;
        }
        else{
          this.psvMetaData = this.formatVideoMeta(psvMetaData);
        }
        this.userData = userData;
        this.ttsOptions = ttsOptions;
    }


    public getPlayerElementId():string{
        return this.playerElementId;
    }
    public getVideoSource():any{
        return this.videoSource;
    }
    public getPsvMetaData():any{
        return this.psvMetaData;
    }
    public getUserData():any{
        return this.userData;
    }
    public getTtsOptions():any{
        return this.ttsOptions;
    }

    formatVideoMeta(videoMeta: any) {
        return {
          "video-name": "",
          "videos": [
            {
              "video-url": "videos/mpd/h264.mpd",
              "video-type": "application/dash+xml"
            },
            {
              "video-url": "videos/h264.mp4",
              "video-type": "video/mp4"
            },
            {
              "video-url": "videos/vp9.webm",
              "video-type": "video/webm"
            }
          ],
          "captions": videoMeta.captions,
          "video-aspect-ratio": {
            "width": videoMeta["video-aspect-ratio"]?.width,
            "height": videoMeta["video-aspect-ratio"]?.height
          },
          "audioCache": false,
          "overlays": this.isPersonalizeScreen? videoMeta.overlays : this.transformOverlayObjectsToArray(videoMeta),
          "backgroundAudio": videoMeta.backgroundAudio ? this.transformBackgroundAudioObject(videoMeta): {},
          "audios": this.isPersonalizeScreen? videoMeta.audios : this.transformAudioObjectsToArray(videoMeta),
          "skippable_sections":[] as any[],
          "captionTableData": videoMeta.captionTableData
        }
    }

    transformOverlayObjectsToArray(videoMeta:any) {
      var tempOverlaysArray = [];
      for (const overlayObject of videoMeta.overlays) {
        const transformedObject = Object.assign({}, overlayObject.getAttributes());
        const transformedObjectData = Object.assign({}, transformedObject.data);
        if (transformedObject.type === 'chart') {
          transformedObject.chartData = undefined;
          if (transformedObject.chartLibrary === "chartjs") {
            transformedObjectData.options.backgroundColor = transformedObjectData.transformedData.datasets[0].backgroundColor;
          }
          transformedObjectData.transformedData = undefined;
          delete transformedObjectData.chartElement;
          delete transformedObjectData.chart;
          transformedObject.data = Object.assign({}, transformedObjectData);
        }
        //  if (transformedObject.type === "section" && this.seekTo) {
  
        //    transformedObject.seekTo = this.seekTo;
        //  }
        tempOverlaysArray.push(transformedObject);
      }
      return tempOverlaysArray;
    }

    transformAudioObjectsToArray(videoMeta:any) {
      const tempAudiosArray = [];
      for (const audioObject of videoMeta.audios) {
        const transformedObject = Object.assign({}, audioObject.getAttributes(audioObject.tts));
        tempAudiosArray.push(transformedObject);
      }
      return tempAudiosArray;
    }
    transformBackgroundAudioObject(videoMeta:any) {
      const transformedObject = Object.assign({}, videoMeta.backgroundAudio?.getAttributes());
      return transformedObject;
    }

}