import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { DataService } from '../../shared/services/data.service';
import { ActivatedRoute, Params } from '@angular/router';
import { PSV_Meta } from "../../shared/models/psv.model";
import { HttpClient } from '@angular/common/http';
import { importType } from '@angular/compiler/src/output/output_ast';
import { MatDialog } from '@angular/material/dialog';
import CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-psv-video-player',
  templateUrl: './psv-video-player.component.html',
  styleUrls: ['./psv-video-player.component.css']
})
export class PsvVideoPlayerComponent implements OnInit {
  id: any;
  url: any;
  decodedUrl: any;
  isDynamicAPI:boolean = false;
  apiUserData:any;
  linkData:any;
  psvObj!: PSV_Meta;
  cornerType: boolean = false;
  shadowToggle:boolean = false;
  concatenatedPSV:any;
  userData:any;
  secret = 'RUNPSV';
  isValidLink: boolean = false ;
  isLinkProtected: any;
  linkPasscode:any;
  inputFieldType: boolean = false;
  onsubmit : boolean = false;
  linkLoader: boolean = true;
  isValidated: boolean = false;
  isOneLink:boolean = false;
  incorrectPassword: boolean = false;
  linkExpired: boolean = false;


  constructor(public data: DataService, private activatedRoute: ActivatedRoute, public http: HttpClient, private el:ElementRef, public dialog : MatDialog) {
    this.data.onPublishScreen = true;
  
  }

  async show(passVal:any){
    this.linkPasscode = passVal;
    await this.data.validateLink(window.location.href,this.linkPasscode).then((res:any) =>{
      this.isValidated = true;
      if(res.success){
        this.isValidLink = true
      }else if(res.message === "Password is incorrect"){
        this.isValidLink = false;
        this.incorrectPassword = true;
        setTimeout(function(){window.location.reload();}, 5000);

      }
      else{
        this.linkExpired  = true;  
      }
    });
    if (this.isValidLink){
      let longUrl = this.linkData.link
      if(longUrl){
        console.log(longUrl)
        this.url = longUrl.split('/psv?psvData=')[1];
      }
      this.decodedUrl = atob(this.url);
      const obj = JSON.parse(this.decodedUrl);
      this.isDynamicAPI = obj.isDynamicAPI;
      this.isOneLink = obj.isOneLink ? obj.isOneLink : false;
      // recording this view
      if(this.isOneLink){
        this.recordView(this.linkData.shortUrl);
      }
  
      this.fetchUserDataAndPSV(obj.projectId, obj.userDataId).then(() => {
        this.psvObj = new PSV_Meta(this.data.playerId, this.concatenatedPSV.videoSourceObj, this.concatenatedPSV.psvMetaDataUrl, this.userData, this.data.ttsOptions, this.data.onPublishScreen);
        this.data.setPSV(this.psvObj);
      });
    }
  }

 

  async ngOnInit() {
    this.linkLoader = true;
    this.data.isPsvLoaderPage = true;
    let urlcode = this.activatedRoute.snapshot.paramMap.get('urlcode') || '';
    this.linkData = await this.data.getLinkData(urlcode).catch(this.data.handleAPIErrors);
    if(this.linkData){
      this.linkLoader = false;
      if(this.linkData.isSecured){
        this.isLinkProtected = true;
      }
      else{
        await this.data.validateLink(window.location.href).then((res:any) =>{
          this.isValidated = true;
          if(res.success){
            this.isValidLink = true
          }else if(res.message === "Password is incorrect"){
            this.isValidLink = false;
            this.incorrectPassword = true;
            setTimeout(function(){window.location.reload();}, 5000);
          }
          else{
            this.linkExpired  = true;  
          }
        }).catch(this.data.handleAPIErrors);
        if (this.isValidLink){
          let longUrl = this.linkData.link
          if(longUrl){
            this.url = longUrl.split('/psv?psvData=')[1];
          }
          this.decodedUrl = atob(this.url);
          const obj = JSON.parse(this.decodedUrl);
          this.isDynamicAPI = obj.isDynamicAPI;
          this.isOneLink = obj.isOneLink ? obj.isOneLink : false;
          // recording this view
          if(this.isOneLink){
            this.recordView(this.linkData._id);
          }
      
          this.fetchUserDataAndPSV(obj.projectId, obj.userDataId).then(() => {
            this.psvObj = new PSV_Meta(this.data.playerId, this.concatenatedPSV.videoSourceObj, this.concatenatedPSV.psvMetaDataUrl, this.userData, this.data.ttsOptions, this.data.onPublishScreen);
            this.data.setPSV(this.psvObj);
          }).catch(this.data.handleAPIErrors);
        }
      }
    }
    else{
      this.linkLoader = false;
    }
  }

  async recordView(psvLink:string){
    let userProfileInfo:any = await this.getKeycloakData();
    let apiUrl = environment.dbUrl + 'api/views/recordView';
    let viewRecord = {
      "psvLink": psvLink,
      "userEmail": userProfileInfo.email,
      "userFirstName": userProfileInfo.firstName ? userProfileInfo.firstName : "",
      "userSecondName": userProfileInfo.lastName ? userProfileInfo.lastName : "",
      "linkOpenTime": new Date()
    }
    let requestBody = JSON.stringify({ "viewRecord": viewRecord });

    return new Promise(async (resolve, reject) =>{
      this.data.postReq(apiUrl,requestBody).subscribe((res) =>{
        if (res.success) {
          resolve(res.data);
        }
        else {
          reject(res.message);
        }
      }, err =>{
        reject(err);
      });
    })
  }

  async getKeycloakData(): Promise<any>{
    return new Promise(async (resolve, reject) =>{
      let userLoggedIn = await this.data.keycloak.isLoggedIn();
      let userProfileInfo = userLoggedIn ? await this.data.keycloak.loadUserProfile() : null;
      resolve(userProfileInfo);
    })
  }
  





  toggleShadow(){
    this.shadowToggle = !this.shadowToggle;
    let player = this.el.nativeElement.querySelector("#psv-playerElementId");
    if(this.shadowToggle){
      player.classList.add('drop-shadow');
    }
    else{
      player.classList.remove('drop-shadow');
    }
  }

  async getDynamicApiResponse(apiUrl:string){
    const requestBody = JSON.stringify({ });
    return new Promise((resolve: any, reject: any) => {
      this.http.post<any>(apiUrl, requestBody, { headers: { "Content-Type": "application/json" }, observe: "response" }).subscribe((res: any) => {
        if (res.ok) {
          resolve(res.body);
        }
        else {
          reject(res.statusText);
        }
      })
    })
  }

  toggleRoundedCorners(){
    this.cornerType = !this.cornerType;
    let player = this.el.nativeElement.querySelector("#psv-playerElementId");
    if(this.cornerType){
      if(!player.classList.contains('rounded-corner')){
        player.classList.add('rounded-corner');
      }
      player.classList.remove('sharp-corner');
    }
    else{
      if(!player.classList.contains('sharp-corner')){
        player.classList.add('sharp-corner');
      }
      player.classList.remove('rounded-corner');
    }
  }

  async fetchUserDataAndPSV(projectId:any,userDataId:any){
    return new Promise( async (resolve:any,reject:any) =>{
      if(typeof userDataId != 'string') {
        this.data.fetchProjectById(projectId).then(async (result: any) => {
          await this.data.fetchPSV(result.concatenatedPSV).then((psvObj: any) => {
            this.concatenatedPSV = psvObj;
          }).catch(this.data.handleAPIErrors);

          if(this.isDynamicAPI){
            if(this.isOneLink){
              let userLoggedIn = await this.data.keycloak.isLoggedIn();
              let userProfileInfo = userLoggedIn ? await this.data.keycloak.loadUserProfile() : null;
              if(userProfileInfo){
                let userName = userProfileInfo.firstName ? userProfileInfo.firstName : userProfileInfo.lastName;
                this.apiUserData = {
                  "name": userName
                };
              }
            }
            else{
              await this.getDynamicApiResponse(this.linkData.dynamicApiUrl).then((apiUserData:any) =>{
                this.apiUserData = apiUserData;
              });
            }

            let mappedUserData = userDataId;
            const iterateKeys = (obj: any) => Object.keys(obj).forEach(key => { 
              if (typeof obj[key] === 'object') { 
                iterateKeys(obj[key]);
              } else {
                obj[key] = this.apiUserData[obj[key]];
              }
            })
            iterateKeys(mappedUserData);
            this.userData = mappedUserData;
          }
          else{
            this.userData = userDataId;
          }

          resolve();
        }).catch(this.data.handleAPIErrors);
      } else {
        await Promise.all([this.data.fetchProjectById(projectId), this.data.fetchUserDataById(userDataId)]).then(async (result: any) => {
          await this.data.fetchPSV(result[0].concatenatedPSV).then((psvObj: any) => {
            this.concatenatedPSV = psvObj;
          }).catch(this.data.handleAPIErrors);
  
          this.userData = result[1].userData;
          resolve();
        }).catch(this.data.handleAPIErrors);
      }
    })
  }


}
