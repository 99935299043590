import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { url } from 'inspector';
import { PassThrough } from 'stream';
import { DataService } from '../../shared/services/data.service';

@Component({
  selector: 'app-thumbnail',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.css']
})
export class ThumbnailComponent implements OnInit {

  imageUrl!: string;
  uploadForm!: FormGroup;
  constructor(public fb: FormBuilder, public data:DataService, public matDialogRef: MatDialogRef<ThumbnailComponent>) { }

  ngOnInit(): void {
    this.uploadForm = this.fb.group({
      image: [null],
      url: ['']
    })
  }
  get uploadFormControls() {
    return this.uploadForm.controls;
  }

  changeImage(event:any) {
    const file = (event.target as HTMLInputElement).files![0];
    if (file) {
      const fileReader = new FileReader();
      this.uploadForm.patchValue({
        image: file,
        url: ''
      });
      this.uploadForm?.get('url')?.disable();

      fileReader.onload = () => {
        this.imageUrl = fileReader.result as string;
      }
      fileReader.readAsDataURL(file)
    }
  }

  cancel() {
    
      this.uploadForm.reset();
    this.uploadForm.patchValue({
      image: '',
    })
    this.imageUrl = ""
    this.uploadForm?.get('url')?.enable();
    this.matDialogRef.close()
    
    
  }

  checkFormvalidity() {
    if (this.uploadForm?.get('url')?.value || this.uploadForm?.get('image')?.value) {
      return false;
    } else {
      return true
    }
  }

  submit() {
    if (!this.imageUrl) {
      this.data.posterUrl = this.uploadForm?.get('url')?.value;
    }
    else {
      this.data.posterUrl = this.imageUrl;
    }
    this.matDialogRef.close()
  }

}
