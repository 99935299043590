<div class="heading-container">
    <strong class=" main-headings">Recent Projects</strong>
</div>
<div class="sec-text" *ngIf="projects.length == 0">No projects yet</div>
<div *ngIf="projects.length > 0" class="projects-container">
    <div *ngFor="let model of projects; index as i" style="max-width: 20%;"[@flyInOut]>
        <div class="project-sub-container tile">
            <mat-icon class="del-template-btn" (click)="deleteConfirmation(i)">delete_outline</mat-icon>
            <img class="recent-project-thumbnail" alt="Project Thumbnail" src="../../../assets/images/thumbnail.png">
            <div style="padding-left: 6%; padding-top: 1%;">
                <p class="project-heading sub-heading2">{{model.title}}</p>
                <p class="date-container sec-headings-icons">{{model.createDate| date:'short'}}</p>
                <textarea rows="2" disabled="true" class="description-container sub-heading2">{{model.description}}</textarea>
               <div><div class="use-template-btn" (click)="loadProject(i)">Open</div>
                    <div mat-button class="use-template-btn-analytics" (click)="openAnalyticsProject(i)" >View Analytics</div>
                </div> 
            </div>
        </div>
    </div>
</div>
<div class="backdrop" *ngIf="openConfirmationModal">
<div class="confirmationmodal" >
        Are you sure want to delete? 
        <button mat-button type="button" class="yesBtn" (click)="deleteProject()">Yes</button>
        <button mat-button type="button" class="noBtn" (click)="closeModal()" >No</button>
        <div *ngIf="this.deleteLoader" class="loadScreen">
            <div id="overlay">
                <em class="fa fa-spinner fa-spin spin-big"></em>
            </div>
          </div>
    </div>
</div>