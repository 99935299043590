import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(value: number): string {
    const minutes:number = Math.floor(value/60);
    return minutes.toString().padStart(2,'0') + ':' + parseInt((value - minutes*60).toString()).toString().padStart(2,'0');
  }

}
