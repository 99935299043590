import { Component, Input, OnInit, Output, EventEmitter, Injector } from '@angular/core';
import { DataService } from '../services/data.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { HttpEvent, HttpInterceptor, HttpHandler,HttpRequest,HttpResponse,HttpErrorResponse} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  loading!: boolean;
  error!: string;

  constructor(readonly data: DataService,public snackBar: MatSnackBar,private injector: Injector ) { }
  errorClassList = ["error-snackbar"];

  ngOnInit(){
  }
  
  errorMsg() {
    const message = 'Error occured in DataService.';
    this.data.showSnackBar(message, this.errorClassList);
  }

}
   