import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CalcFontSizePipe } from './pipes/calc-font-size/calc-font-size.pipe';
import { colorConvertPipe } from './pipes/color-convert.pipe';
import { VideoOverlayComponent } from './video-overlay.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatExpansionModule } from '@angular/material/expansion';


@NgModule({
  declarations: [
    VideoOverlayComponent,
    CalcFontSizePipe,
    colorConvertPipe,
  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    MatSlideToggleModule,
    FontAwesomeModule,
    BrowserModule,
    MatCheckboxModule,
    MatExpansionModule
  ],
  exports: [
    VideoOverlayComponent
  ]
})
export class VideoOverlayModule { }
