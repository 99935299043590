/*******************************************************************************
 * COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
 * Reserved. Infosys believes the information in this document is accurate as of
 * its publication date; such information is subject to change without notice.
 * Infosys acknowledges the proprietary rights of other companies to the
 * trademarks, product names and such other intellectual property rights
 * mentioned in this document. Except as expressly permitted, neither this
 * documentation nor any part of it may be reproduced, stored in a retrieval
 * system, or transmitted in any form or by any means, electronic, mechanical,
 * printing, photocopying, recording or otherwise, without the prior permission
 * of Infosys Limited and/or any named intellectual property rights holders
 * under this document.
 ******************************************************************************/
import { CssProperties } from './css-properties.model';
export class TextAnnotation {
    
    isDurationChanged:boolean = false;
    type!: string;
    value!: string;
    id: number;
    className!: string;
    start: number;
    end: number;
    link!: string;
    bgToggle!:boolean;
    exitAnimation!: string;
    showOn!: string;
    title!: string;
    callback!: string;
    cssProperties: CssProperties;
    message: any;
    params: any;
    duration:any;

    constructor(id:number, currentTime:number, videoDuration: number, cssProps: CssProperties){
        this.id = id;
        this.end = videoDuration;
        this.cssProperties = cssProps;
        this.start = currentTime;
        this.duration = this.end - this.start;
        this.initializeAttributes();
    }

    initializeAttributes(){
        this.type = 'text';
        this.value = 'Your text here...';
        this.className = '';
        this.bgToggle=false;
        this.exitAnimation = "";
        this.link = "";
        this.showOn = "";
        this.title = "";
        this.callback = "";
    }

    getAttributes(){
        let attributes: any;
        if(this.link === "") {
            attributes = {
                type: this.type,
                value: this.value,
                id: this.id,
                "class-name": this.className,
                start: this.start,
                end: this.end,
                exitAnimation: this.exitAnimation,
                showOn : this.showOn,
                title: this.title,
                callback: this.callback,
                "css-properties": this.cssProperties.getTextAttributes()
            };
         }
         else if(this.callback === "") {
            attributes = { 
                type: this.type,
                value: this.value,
                id: this.id,
                "class-name": this.className,
                start: this.start,
                end: this.end,
                link: this.link,
                exitAnimation: this.exitAnimation,
                showOn : this.showOn,
                title: this.title,
                "css-properties": this.cssProperties.getTextAttributes()
            };
          }
          else  if(this.title === ""){
            attributes = {
                type: this.type,
                value: this.value,
                id: this.id,
                "class-name": this.className,
                start: this.start,
                end: this.end,
                link: this.link,
                exitAnimation: this.exitAnimation,
                showOn : this.showOn,
                callback: this.callback,
                "css-properties": this.cssProperties.getTextAttributes()
            };
         }
        return attributes;
    }

    transformAttributes(overlayObject:any, cssProps: CssProperties){
        const textObject = new TextAnnotation(overlayObject.id,overlayObject.start, overlayObject.end, cssProps);
        textObject.value = overlayObject.value;
        textObject.start = overlayObject.start;
        textObject.bgToggle= textObject.cssProperties.backgroundColor!=='transparent'?true:false;
        textObject.className = overlayObject["class-name"];
        textObject.link = overlayObject.link;
        textObject.exitAnimation = overlayObject.exitAnimation || "";
        textObject.showOn = overlayObject.showOn || "";
        if(overlayObject.type === "link"){
            textObject.value = overlayObject.value;
            textObject.bgToggle = textObject.cssProperties.buttonBgColor!=='transparent'?true:false;
        } else if(overlayObject.type === "callback"){
            textObject.type = overlayObject.type;
            textObject.bgToggle = textObject.cssProperties.backgroundColor!=='transparent'?true:false;
        }
        textObject.title=overlayObject.title;
        if(textObject.cssProperties.animation === "" && textObject.className) {
            textObject.cssProperties.animation = `${textObject.className.split(" ")[1]} ${textObject.cssProperties.animationDuration}`;
            textObject.className = "";
        }
        return textObject;
    }

}
