<div class="live-video-container">
  <h4>Live Video Recorder</h4>
  <div class="row">
      <div class="recoredVideo">
        <video style="width:43rem"  controls autoplay playsinline #recordedVideo id="vid"></video>
      </div>
  </div>
  <div class="row">
    <div class="example-button-row">
      <button mat-raised-button class="button button1" *ngIf="!isRecording" (click)="startRecordingClick()"> Record </button>
      <button mat-raised-button class="button button2" *ngIf="isRecording" (click)="stopRecordingClick()"> Stop</button>
      <button mat-raised-button class="button buttoncontinue" *ngIf="videoSrc" (click)="download()">Add</button>
    </div>
  </div>
</div>