import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AudioAnnotation } from '../models/audio-annotation.model';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

declare var psv:any;

@Injectable({
  providedIn: 'root'
})
export class SampleAudioService {

  genericErrorMsg:any="Something has gone wrong. Please try again.";
  ttsProviderOptions:any = {
    ttsProviderJSON:JSON,
    ttsProviderTypes:[],
    selectedVoice:"",
    selectedLanguage:"",
    ttsEngine:"standard",
    voicesList:[],
    ttsvoiceType:"",
    languagesList:[],
    languageCode:"",
    splitString:[],
    text:""
  };
  audioAnnotation:any;
  tts:any = 'polly';
  ttsOptions = {
    ttsApiPath: environment.audioService,
    storageWebServerPath: environment.storageService,
    minioCachePath: environment.storageWebServerUrl
  };

  constructor(readonly http:HttpClient, public snackBar: MatSnackBar) { 
    this.getPollyVoicesList().subscribe((result:any) => {
      this.ttsProviderOptions.ttsProviderJSON = result;
      this.ttsProviderOptions.languagesList = Object.keys(result);
      this.ttsProviderOptions.selectedLanguage =  this.ttsProviderOptions.languagesList[0];
      this.ttsProviderOptions.voicesList =  this.ttsProviderOptions.ttsProviderJSON[this.ttsProviderOptions.selectedLanguage];
      this.ttsProviderOptions.selectedVoice =  "Amy";
    })
  }

  getPollyVoicesList(): Observable<any> {
    return this.http.get("assets/data/polly_voices.json").pipe(
      catchError(this.errorHandler)
    );
  }

  async getSampleTTSAudio() {
    this.audioAnnotation = new AudioAnnotation(1, 1, 10, this.tts);
    this.audioAnnotation.initializeAttributes(this.ttsProviderOptions, this.tts);
    let sampleJson:any;
    sampleJson = {
      "tts": this.tts,
      audios: [
        JSON.parse(JSON.stringify(this.audioAnnotation.getAttributes(this.tts)))
      ]
    }

    return new Promise((resolve, reject) => {
      new psv.textToSpeechConfiguration(sampleJson, this.ttsOptions).then((audioFile:any) => {
        resolve(audioFile);
      }).catch((err:any) => {
        reject(err);
      });
    });
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message)
    }

    showSnackBar(message: any, classList: any) {
      const config = new MatSnackBarConfig();
      config.horizontalPosition = "right";
      config.verticalPosition = "bottom";
      config.panelClass = classList;
      config.duration = 3000;
      this.snackBar.open(message, 'Ok', config);
    }
}
