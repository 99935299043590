import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DataService } from 'src/app/shared/services/data.service';
import { FormControl, Validators } from '@angular/forms';
import { Project_Meta } from 'src/app/shared/models/project.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-project-uploader',
  templateUrl: './new-project-uploader.component.html',
  styleUrls: ['./new-project-uploader.component.css']
})
export class NewProjectUploaderComponent implements OnInit {

  title = new FormControl('', [Validators.required]);

  constructor(public dialogRef: MatDialogRef<NewProjectUploaderComponent>, readonly data: DataService, private router: Router) { }

  ngOnInit(): void {
  }

  getErrorMessage() {
    return 'You must enter a value';
  }

  cancelDialog() {
    this.dialogRef.close('cancel');
  }

  createNewProject() {
    this.data.isHomePage = false;
    this.cancelDialog();
    this.router.navigate(['/editor']);
  }

  setTitle(input: any) {
    this.data.titleValue = input.trim();
  }

  setDescription(input: any) {
    this.data.descriptionValue = input.trim();
  }

}
