<div *ngIf="linkLoader" style="margin-top: 20%; margin-left: 50%;">
   <!-- loader ui --><mat-spinner></mat-spinner>
</div>

<div class="mat-dailog-container" *ngIf="!linkLoader && !incorrectPassword && !isValidLink && !linkExpired && isLinkProtected" >
   <div class="upload-form">
   <!-- <h3 style="font-weight: bolder">Please enter the passcode</h3> -->
         <p>
            <input matInput #passcode maxlength ="4" class="set-password-subcontainer " [type]="inputFieldType? 'text':'password'" placeholder="Enter passcode" required >
            <button style="border: none;" (click)="inputFieldType = !inputFieldType" >
               <em class="fa set-password-container" [ngClass]="{'fa-eye-slash': !inputFieldType,'fa-eye': inputFieldType}"></em>
             </button>
         </p>
         <button  mat-button class="upload"  (click)="show(passcode.value)">Submit</button>
   </div>
</div>

<div *ngIf="isValidated && isValidLink && !incorrectPassword">
   <div class="player-options">
      <div class="corner-control">
         <div class="rounded-toggle" (click)="toggleRoundedCorners()" [ngClass]="{'toggle': cornerType}">
            <mat-icon>rounded_corner</mat-icon>
         </div>
      </div>
      <div class="shadow-control">
         <div class="shadow-toggle" (click)="toggleShadow()" [ngClass]="{'toggle': shadowToggle}">
            <mat-icon>blur_on</mat-icon>
         </div>
      </div>
   </div>
   <div class="whole-container">
      <div class="loader-holder psv-player-container" [id]="this.data.playerId" style="margin-top: 7%;">
         <div class="loader"></div>
      </div>
   </div>
</div>

<div *ngIf="isValidated && linkExpired" class="expire-message">
  <h1>Link is expired</h1> 
</div>

<div *ngIf="isValidated && incorrectPassword" class="expire-message">
   <h1>Password is incorrect, Try Again! Page will reload in 5 seconds</h1> 
 </div>



