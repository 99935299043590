<div>
    <h3 align="center" class="preview">PSV Preview</h3>
        <div *ngIf="data.isPreviewLoaded" class="preview-container">
            <div class="loader-holder psv-player-container" id="sample-player">
                <div class="loader"></div>
            </div>
            <div class="tran-model">
                <div [ngStyle]="{'margin':'5%' }" id = "transcript-sample-player" class="tran-element"></div>
            </div>
        </div>

</div>
<div>
    <div class="button-container">
        <button  type="button" mat-button id="close-btn" class="user-data" (click)="openUserdataDialog()">Edit Userdata</button>
        <button  type="button" mat-button id="close-btn" class="close" (click)="dialog.closeAll()">Close</button>
        <button class="publishbtn primary-btn" type="button" (click)="publishPsv()">Publish</button>
</div>
