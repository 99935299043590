import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DataService } from '../shared/services/data.service';
import { MatDialog } from '@angular/material/dialog';
import { PublishUserdataComponent } from './publish-userdata/publish-userdata.component';
import { StorageService } from 'src/app/shared/services/storage.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import CryptoJS from 'crypto-js';
import { EmbedPsvComponent } from './embed-psv/embed-psv.component';
import { ApiErrorHandlerComponent } from './api-error-handler/api-error-handler.component';
import * as XLSX from 'xlsx';
import { QrModalComponent } from './qr-modal/qr-modal.component';
import { TemplatePublishedLinksComponent } from '../template-published-links/template-published-links.component';

@Component({
  selector: 'app-publish',
  templateUrl: './publish.component.html',
  styleUrls: ['./publish.component.css'],
})
export class PublishComponent implements OnInit {
  apiUserDataObj: any = {};
  finalKeyArray: any[] = [];
  urlDomain = '';
  loaded: boolean = true;
  urlArray: any[] = [];
  embedUrlArray: any[] = [];
  projectName: any = this.data.titleValue;
  isSecureLink: boolean = false;
  inputFieldType: boolean = false;
  concatenatedPSV: any;
  userDataIds: any[] = [];
  userDataList: any;
  apiMapper: any;
  identifierKey: any;
  isSecureAPI: boolean = false;
  isGetClicked: boolean = false;
  isOneLink: boolean = false;
  apiRequestBody: any;
  apiUserName: any;
  apiPassKey: any;
  notifyGenerateUrlSubscription!: Subscription;
  minDate: any;
  selectedTime: any;
  maxDate: Date;
  password: any;
  convertedJson!: string;
  expDuration: any;
  projectId: any;
  isClientDemo: boolean = false;

  onFileChange(event: any) {
    const selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(selectedFile);
    fileReader.onload = (event) => {
      let binaryData = event.target?.result;
      let workbook = XLSX.read(binaryData, { type: 'binary' });
      workbook.SheetNames.forEach(sheet => {
        const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
        this.apiUserDataObj = data;
        this.data.isFewUsers = false;
        let resp = { ok: true, status: 200, statusText: "OK" }
        this.openDialog(this.data.userDataObj[Object.keys(this.data.userDataObj)[0]], 0, "map", this.apiUserDataObj, resp);
      })
    }
  }


  expireDuration(dateVal: any) {
    let date = new Date(dateVal);
    let milliseconds = date.getTime();
    this.expDuration = milliseconds / 1000;
  }

  getPasscode(passVal: any) {
    this.password = passVal;
  }

  videoSrc: any = {
    type: "default",
    path: this.data.templateObj.finalVideoSrc,
    autoplay: false
  }

  credForm = new FormGroup({
    isSecureApiCheck: new FormControl(false),
    requestBody: new FormControl(null, Validators.required)
  });

  result: any;
  videoDataUrl = this.data.templateObj.finalMetadataSrc;

  constructor(public data: DataService, public dialog: MatDialog, readonly storageService: StorageService, private http: HttpClient, private router: Router) {
    this.data.onPublishScreen = true;
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    this.maxDate = new Date(currentYear + 0, 12, 0);
    this.expDuration = new Date(currentYear + 10, 12, 0);
    this.expDuration = this.expDuration.getTime() / 1000;
  }

  //open modal
  openPublishedModalUrlsModal(){
    let dialogref = this.dialog.open(TemplatePublishedLinksComponent,{
      width: '56%',
      height: '67%',
    });
  }

  // clone,edit,delete user data operation in one or few people implementation
  openDialog(userDataObj: any, index: number, type: string, apiData?: any, apiResponse?: any) {
    let dialogRef = this.dialog.open(PublishUserdataComponent, { disableClose: true });
    dialogRef.componentInstance._database.userData = userDataObj;
    dialogRef.componentInstance._database.apiData = apiData;
    dialogRef.componentInstance.apiResponse = apiResponse;
    dialogRef.componentInstance._database.typeOfOperation = type;
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result !== 'cancel') {
        if (type == 'clone') {
          this.data.userDataArray.push(result);
          let newKey = 'user' + this.data.userDataArray.length.toString();
          this.data.userDataObj[newKey] = result;
          this.data.customKeyArray = Object.keys(this.data.userDataObj);
          this.reloadUserDataArr();

          let userDataObj: any = {};
          userDataObj['userData'] = result;
          await this.data
            .addNewUserData(userDataObj)
            .then(async (userDataId: any) => {
              this.userDataIds.push(userDataId);
              let currentKey =
                this.data.customKeyArray[
                this.data.userDataArray.indexOf(result)
                ];
              // let userDatalist = this.concatenatedPSV.userDatalist;
              this.data.userDataId[currentKey] = userDataId;

              // let psvObj: any = this.data.createConcatenatedPSVObjForDB(
              //   this.concatenatedPSV.playerId,
              //   this.concatenatedPSV.videoSourceObj,
              //   this.concatenatedPSV.psvMetaDataUrl,
              //   this.concatenatedPSV.ttsOptions,
              //   userDatalist,
              //   this.concatenatedPSV._id
              // );

              // await this.data.updatePSV(psvObj).then((psvData: any) => {
              //   this.concatenatedPSV = psvData;
              // }).catch(this.data.handleAPIErrors);
            }).catch(this.data.handleAPIErrors);

          //   this.storageService
          //     .uploadFiles(
          //       result,
          //       this.data.projectModel.id,
          //       Object.keys(this.data.userDataObj)[
          //         this.data.userDataArray.indexOf(result)
          //       ]
          //     )
          //     .subscribe((datas) => {});
        }

        if (type == 'edit') {
          this.data.userDataArray[index] = result;
          if (index === 0) {
            this.data.userData = result;
          }
          this.data.userDataObj[this.data.customKeyArray[index]] = result;

          let userDataObj: any = {};
          userDataObj['id'] = this.userDataIds[index];
          userDataObj['userData'] = result;

          await this.data.updateUserData(userDataObj);

          // this.storageService
          //   .uploadFiles(
          //     result,
          //     this.data.projectModel.id,
          //     Object.keys(this.data.userDataObj)[
          //       this.data.userDataArray.indexOf(result)
          //     ]
          //   )
          //   .subscribe((datas) => {});
        }

        if (type == 'delete') {
          if(index !== 0){
            var keyOfObj = Object.keys(this.data.userDataObj)[index];
          await this.data
            .deleteUserData(this.userDataIds[index])
            .then(async () => {
              this.userDataIds.splice(index, 1);
              // let userDatalist: any = this.concatenatedPSV.userDatalist;
              delete this.data.userDataId[keyOfObj];
              // let psvObj: any = this.data.createConcatenatedPSVObjForDB(
              //   this.concatenatedPSV.playerId,
              //   this.concatenatedPSV.videoSourceObj,
              //   this.concatenatedPSV.psvMetaDataUrl,
              //   this.concatenatedPSV.ttsOptions,
              //   userDatalist,
              //   this.concatenatedPSV._id
              // );
              // await this.data.updatePSV(psvObj).then((psvData: any) => {
                //   this.concatenatedPSV = psvData;
                // }).catch(this.data.handleAPIErrors);
              }).catch(this.data.handleAPIErrors);
              this.storageService
              // .deleteFiles(this.data.projectModel.id, keyOfObj)
              // .subscribe((datas) => {});
              delete this.data.userDataObj[keyOfObj];
              this.data.customKeyArray.splice(index, 1);
              this.data.userDataArray.splice(index, 1);
            }
            else{
              this.data.showSnackBar('Default userdata cannot be deleted', 'Ok');
            }
        }

        if (type == 'map') {
          this.apiMapper = result[0];
          this.identifierKey = result[1];
          this.data.showSnackBar('User data keys mapped successfully', 'close');
        }
      }
    });
  }

  openErrorDialog(apiStatus: any, apiStatusText: any) {
    let dialogRef = this.dialog.open(ApiErrorHandlerComponent, { disableClose: true });
    dialogRef.componentInstance.apiStatus = apiStatus;
    dialogRef.componentInstance.apiStatusText = apiStatusText;
  }

  setSecureLink() {
    this.isSecureLink = !this.isSecureLink;
    this.data.secureCheck = this.isSecureLink;
  }

  pastSelection(time: any) {
    var todate: any = new Date().getTime();
    var selectDate: any = new Date(time).getTime();
    if (todate > selectDate) {
      this.selectedTime = "";
      this.data.showSnackBar("Please Select Valid Time", "ok");
    }
  }

  async ngOnInit() {
    var expDateFull: any = new Date();
    var ExpDate: any = expDateFull.getDate();
    if (ExpDate < 10) { ExpDate = "0" + ExpDate }
    var month: any = expDateFull.getMonth() + 1;
    if (month < 10) { month = "0" + month }
    var year: any = expDateFull.getFullYear();
    var hours: any = expDateFull.getHours();
    var minutes: any = expDateFull.getMinutes();
    if (minutes < 10) { minutes = "0" + minutes }
    this.minDate = year + "-" + month + "-" + ExpDate + "T" + hours + ":" + minutes;
    this.data.isHomePage = false;
    this.urlDomain = environment.publishUrl;

    if (this.data.fromTemplate) {
      for (let key of Object.keys(this.data.userDataId)) {
        this.userDataIds.push(this.data.userDataId[key]);
      }
      await this.data.fetchUserDatas(this.userDataIds).then((result) => {
        this.data.userDataArray = result
      });
      this.data.userDataObj = this.data.userDataArray
      this.data.customKeyArray = Object.keys(this.data.userDataId);
      this.formatUserDataArray(
        this.data.userDataArray,
        this.data.customKeyArray
      );
    }
    else {
      await this.data
        .fetchPSV(this.data.projectModel.concatenatedPSV)
        .then(async (psvObj: any) => {
          this.concatenatedPSV = psvObj;
          for (let key of Object.keys(psvObj.userDatalist)) {
            this.userDataIds.push(psvObj.userDatalist[key]);
          }

          this.data.userDataArray = await this.data.fetchUserDatas(
            this.userDataIds
          );

          if (!this.data.isNavigation) {
            if (this.data.userDataArray.length > 1) {
              for (
                let index = 1;
                index < this.data.userDataArray.length;
                index++
              ) {
                this.result = {};
                this.result = this.compareUserDataChange(
                  this.data.userDataArray[index],
                  this.data.userData
                );
                this.data.userDataArray[index] = this.result;
              }
            }
            this.data.customKeyArray = Object.keys(
              this.concatenatedPSV.userDatalist
            );
            this.formatUserDataArray(
              this.data.userDataArray,
              this.data.customKeyArray
            );
          } else {
            for (let index = 1; index < this.data.userDataArray.length; index++) {
              this.result = {};
              this.result = this.compareUserDataChange(
                this.data.userDataArray[index],
                this.data.userData
              );
              this.data.userDataArray[index] = this.result;
            }
            this.data.userDataArray[0] = this.data.userData;
            this.formatUserDataArray(
              this.data.userDataArray,
              this.data.customKeyArray
            );
            this.data.customKeyArray = Object.keys(this.data.userDataObj);
          }
        }
        ).catch(this.data.handleAPIErrors);
    }
    this.notifyGenerateUrlSubscription =
      this.data.notifyGenerateUrlObservable.subscribe((message) => {
        if (message === 'clicked') {
          this.generateURL();
        }
      });
  }

  ngOnDestroy() {
    this.notifyGenerateUrlSubscription.unsubscribe();
    this.data.notifyOnGenerateUrl('');
  }



  reloadComponents() {
    this.data.onPublishBtn = false;
    this.data.onCreateVideo = false;
    this.data.isSceneSelected = false;
    this.data.isEditScript = true;
  }

  /*used to compare nested object,helped to compare the userdata change when we navigate
  back to edit scene page*/
  compareUserDataChange(userData: any, changeduserData: any): any {
    let keysOfUserData = Object.keys(userData);
    let keysOfChangedData = Object.keys(changeduserData);
    var temp: any = new Object();
    for (let item of keysOfChangedData) {
      if (!keysOfUserData.includes(item)) {
        if (typeof changeduserData[item] === 'string') {
          temp[item] = '';
        } else {
          temp[item] = {};
          temp[item] = this.compareUserDataChange(
            temp[item],
            changeduserData[item]
          );
        }
      } else {
        if (typeof userData[item] === 'string') {
          temp[item] = userData[item];
        } else {
          temp[item] = this.compareUserDataChange(
            userData[item],
            changeduserData[item]
          );
        }
      }
    }
    return temp;
  }

  navigationFunction() {
    this.data.onPublishBtn = true;
    this.data.isNavigation = true;
  }

  formatUserDataArray(userDataArray: any, key: any) {
    this.data.userDataObj = {};
    for (let index in userDataArray) {
      this.data.userDataObj[key[index]] = userDataArray[index];
    }
  }

  formatUserData(userDataArray: any) {
    this.data.userDataObj[`user${this.data.index}`] = userDataArray;
    this.data.index = this.data.index + 1;
  }

  reloadData() {
    this.data.onCreateVideo = false;
    this.data.reload = true;
  }

  reloadUserDataArr() {
    this.loaded = false;
    setTimeout(() => (this.loaded = true));
  }

  /*invoke on key change*/
  async changeToInputname(value: any, indexvalue: number) {
    // this.storageService
    //   .deleteFiles(
    //     this.projectName,
    //     Object.keys(this.data.userDataObj)[indexvalue]
    //   )
    //   .subscribe((datas) => { });
    this.data.customKeyArray[indexvalue] = value;
    this.updateUserDataKeys(indexvalue);
    // let psvObj = this.data.createConcatenatedPSVObjForDB(
    //   this.concatenatedPSV.playerId,
    //   this.concatenatedPSV.videoSourceObj,
    //   this.concatenatedPSV.psvMetaDataUrl,
    //   this.concatenatedPSV.ttsOptions,
    //   this.userDataList,
    //   this.concatenatedPSV._id
    // );
    // await this.data.updatePSV(psvObj).then((psvObj: any) => {
    //   this.concatenatedPSV = psvObj;
    // }).catch(this.data.handleAPIErrors);
    // this.storageService.uploadFiles(this.data.userDataObj[value], this.data.projectModel.id, Object.keys(this.data.userDataObj)[indexvalue]).subscribe(datas => { });
  }

  updateUserDataKeys(value: number) {
    this.data.userDataObj = {};
    this.userDataList = {};
    for (let item of this.data.customKeyArray) {
      this.data.userDataObj[item] =
        this.data.userDataArray[this.data.customKeyArray.indexOf(item)];
      this.userDataList[item] =
        this.userDataIds[this.data.customKeyArray.indexOf(item)];
    }
    this.data.userDataId = this.userDataList;
  }

  // To get the userdata from an API url, which is the input of hundreds of people
  getAPI(apiUrl: string) {
    if (this.data.isDynamicAPI) {
      this.data.isFewUsers = false;
      this.data.dynamicApiUrl = apiUrl;
      let requestBody = JSON.stringify({});
      this.http.post<any>(apiUrl, requestBody, { headers: { "Content-Type": "application/json" }, observe: "response" }).subscribe((res: any) => {
        this.apiUserDataObj = [res.body];
        this.openDialog(this.data.userDataObj[Object.keys(this.data.userDataObj)[0]], 0, "map", this.apiUserDataObj, res);
      }, (err) => {
        this.openErrorDialog(err.status, err.statusText)
      })
    }
    else if (this.isSecureAPI) {
      if (this.credForm.valid) {
        this.data.isFewUsers = false;
        let requestBody = this.apiRequestBody;
        this.http.post<any>(apiUrl, requestBody, { headers: { "Content-Type": "application/json" }, observe: "response" }).subscribe((res: any) => {
          this.apiUserDataObj = res.body;
          this.openDialog(this.data.userDataObj[Object.keys(this.data.userDataObj)[0]], 0, "map", this.apiUserDataObj, res);
        }, (err) => {
          this.openErrorDialog(err.status, err.statusText)
        })
      }
      else {
        this.isGetClicked = true;
      }
    }
    else {
      this.data.isFewUsers = false;
      this.data.getUrlRequest(apiUrl).subscribe((res) => {
        this.apiUserDataObj = res.body;
        console.log(res.status);

        this.openDialog(this.data.userDataObj[Object.keys(this.data.userDataObj)[0]], 0, "map", this.apiUserDataObj, res);
      }, (err) => {
        this.openErrorDialog(err.status, err.statusText)
      });
    }
  }

  //replace the keys of apiUserdataObj with selected identifierKey
  replaceKeysOfUserData(userDataObj: any, idKey: any) {
    let newObj: any = {};
    for (let key of Object.keys(userDataObj)) {
      let keyValue = userDataObj[key][idKey];
      newObj[keyValue] = userDataObj[key];
    }
    return newObj;
  }

  openEmbedDialog(psvUrl: string) {
    let dialogRef = this.dialog.open(EmbedPsvComponent);

    dialogRef.componentInstance.embedSrc = psvUrl;
  }

  /*generate url of the psvs, this.selectedMode is true then one or few people part,
  else hundreds of people*/
  async generateURL() {
    this.data.isPublishClicked = true;
    if (this.data.isFewUsers) {
      this.finalKeyArray = Object.keys(this.data.userDataObj);
    } else {
      if (this.identifierKey) {
        this.apiUserDataObj = this.replaceKeysOfUserData(
          this.apiUserDataObj,
          this.identifierKey
        );
        this.finalKeyArray = Object.keys(this.apiUserDataObj);
      } else {
        this.finalKeyArray = Object.keys(this.apiUserDataObj).map((val) => {
          return 'user' + (Number(val) + 1);
        });
      }
    }
    for (let item of this.finalKeyArray) {
      let userObj;
      let shortUrl: any;
      let longUrl;
      if (this.data.isFewUsers) {
        userObj = {
          "isDynamicAPI": false,
          "templateId": this.data.templateObj._id,
          "userDataId": this.data.userDataId[item]
        };
        let stringObj = JSON.stringify(userObj);
        var encodedPsvModel = btoa(stringObj);
        longUrl = this.urlDomain + '/psv?psvData=' + encodedPsvModel;
      }
      else if (this.data.isDynamicAPI) {
        let mappedObj = JSON.parse(JSON.stringify(this.apiMapper));
        userObj = {
          "isDynamicAPI": true,
          "templateId": this.data.templateObj._id,
          "userDataId": mappedObj,
          "isOneLink": this.isOneLink
        };
        let stringObj = JSON.stringify(userObj);
        var encodedPsvModel = btoa(stringObj);
        longUrl = this.urlDomain + '/psv?psvData=' + encodedPsvModel;
      }
      else {
        let mappedObj = JSON.parse(JSON.stringify(this.apiMapper));
        const iterateKeys = (obj: any) =>
          Object.keys(obj).forEach((key) => {
            if (typeof obj[key] === 'object') {
              iterateKeys(obj[key]);
            } else {
              obj[key] = this.identifierKey
                ? this.apiUserDataObj[item][obj[key]]
                : this.apiUserDataObj[item - 1][obj[key]];
            }
          });
        iterateKeys(mappedObj);
        userObj = {
          "isDynamicAPI": false,
          "templateId": this.data.templateObj._id,
          "userDataId": mappedObj
        };
        let stringObj = JSON.stringify(userObj);
        var encodedPsvModel = btoa(stringObj);
        longUrl = this.urlDomain + '/psv?psvData=' + encodedPsvModel;
      }
      shortUrl = await this.data.generateShortUrl(this.urlDomain, longUrl, this.expDuration, this.password, environment.embedUrl, this.data.templateObj.projectId, this.data.templateObj._id, this.data.userDataId[item], this.isClientDemo).catch(this.data.handleAPIErrors);
      this.urlArray[this.finalKeyArray.indexOf(item)] = shortUrl;
      //setexprielink
      // await this.data.setExpireLink(
      //   this.urlArray[this.finalKeyArray.indexOf(item)],
      //   this.expDuration,
      //   this.password
      // );
      let shortId;
      if(this.isClientDemo){
        shortId = shortUrl.split('/psvclientdemos/')[1];
      }
      else{
        shortId = shortUrl.split('/psvinternal/')[1];
      }
      this.embedUrlArray[this.finalKeyArray.indexOf(item)] = environment.embedUrl + '/embed/' + shortId;
    }
    // this.data.projectModel.isPublished = true;
    // await this.data.updateProject(this.data.projectModel).catch(this.data.handleAPIErrors);
  }

  // If transcript support is enabled, enable captions support too
  toggleTranscriptsSupport(event: any) {
    this.data.enableTranscript = event.checked;
    if (event.checked) {
      this.data.isCaptionAvailable = true
    }
  }

  // If caption support is disabled, disable transcripts support too
  toggleCaptionsSupport(event: any) {
    this.data.isCaptionAvailable = event.checked;
    if (!event.checked) {
      this.data.enableTranscript = false
    }
  }
  openQRModal(psvURl: any) {
    let dialogRef = this.dialog.open(QrModalComponent, {
      width: '325px',
      height: '260px',
    })
    dialogRef.componentInstance.url = psvURl




  }

}
