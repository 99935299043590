<div class="mat-dailog-container">
<form class="upload-form" [formGroup]="uploadForm" (ngSubmit)="submit()">
    <h3 style="font-weight: bolder">
        Select poster image
    </h3>
    
        Please choose from the options below
        <div class="upload-type-container">
            <div class="upload-file">
                <input type="file" accept="image/*" (change)="changeImage($event)">
                <input type="text" formControlName="url" placeholder="Enter Path">
            </div>
        </div>

        <div class="btn-sub-section ">
            <button mat-button (click)="cancel()" class="cancel">Discard</button><br>
        <button  mat-button class="upload-btn" class="submit">Submit</button>
    </div>
    </form> 
</div>