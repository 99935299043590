<div class="container">
    <img alt="PSV Logo" (click)="this.data.reload=true;this.data.isHomePage = true" routerLink="/" src=".././assets/images/PSV Logo.png">
    <div *ngIf="!data.isPsvLoaderPage" class="icon-container sub-heading2">
        <div class="align-icon-name">
            <em class="material-icons-outlined" routerLink="/"
                [ngClass]="{'icon-change':data.isHomePage,'icon-default':!data.isHomePage}"
                (click)="this.data.reload=true;this.data.isHomePage = true">home</em>
            <p>Home</p>
        </div>
        <div class="align-icon-name">
            <em class="icon-default material-icons" (click)="data.isPublishClicked = false;" routerLink="/template-page">insights</em>
            <p>Analytics</p>
        </div>
        <!-- <div class="align-icon-name">
            <em class='icon-default material-icons-outlined' routerLink="/helppage" routerLinkActive="icon-change">help_outline</em>
            <p>Help</p>
        </div> -->
        <div class="align-icon-name">
            <em class='icon-default material-icons-outlined' (click)="data.isPublishClicked = false;" routerLink="/template-page" routerLinkActive="icon-change">all_inbox</em>
            <p>Templates</p>
        </div>
    </div>
</div>