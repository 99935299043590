import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AudioOverlayComponent } from './audio-overlay.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SanitizeVideoPipe } from './pipes/sanitize-video.pipe';


@NgModule({
  declarations: [
    AudioOverlayComponent,
    SanitizeVideoPipe
  ],
  imports: [
    FormsModule,
    BrowserModule,
    MatExpansionModule,
    ReactiveFormsModule,
    FontAwesomeModule
  ],
  exports: [
    AudioOverlayComponent
  ]
})
export class AudioOverlayModule { }
