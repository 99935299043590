<div class="dialog-container">
    <h3 class="text-center"><strong><strong>Choose Background Audio</strong></strong></h3>
    <div>
        <p class="text-center" [ngStyle]="{'font-size' : '15px'}">
            Please choose from the below options
        </p>
        <div class="sample">
            <button class="white-btn choose-btn" (click)="fromSamplefiles=true;fromLocalfiles=false">
                Choose from samples
            </button>
            <button class="white-btn choose-btn" (click)="fromLocalfiles=true;fromSamplefiles=false">
                Choose from local files
            </button>
        </div>

    </div>
    <div class="choose-bgvideo-dailog container-fluid" [ngStyle]="{'background-color': ' rgb(249, 250, 250)', 'overflow-x' : 'hidden' }">
    <div class="properties font-size-sm file-display" *ngIf="fromLocalfiles">
        <input style="margin-left: 36%;" mat-button accept="video/*" (change)="onFileUpload($event)" type="file"><br>
    </div>
</div>

    <div *ngIf="fromSamplefiles">
        <div class="media-btns-container">
        <div>
            <form action="#">
                <input class="search-bar" type="text" #mediaName placeholder="Search Background Audio" name="search">
                <button class="search-Btn" (click)="onSearch(mediaName.value)">
                    <em class="fa fa-search fa-small" style="color:#8d8d8d"></em>
                </button>
            </form>
        </div>
        <div class="media-container">
            <div class="gallery-list">
                <div class="gallery-card audio" *ngFor="let media of this.data.matchedMedia; let i=index;">
                    <div (click)="radioChecked(media,i)" [style.background]="selectedMediaIndex === i ? '#464646' : '' "
                        class="highlight-audio">
                        <div class="audio-style">
                            <!-- <input #checkbox (click)="radioChecked(audiocheck,i)" type="radio" name="audios" class="checkbox"
                                id={{i}} /> -->
                            <audio #audiocheck [src]="media" controls for="check"></audio>
                        </div>
                        <span [style.color]="selectedMediaIndex === i ? '#ffffff' : '' "
                            class="media-label">{{this.data.matchedNamesOfMedia[i]}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-holder">
            <button type="button" mat-button (click)="closeDialog()" class="cancel">Cancel</button>
            <button type="button" mat-button (click)="loadBgAudio()" class="upload">Add</button>
        </div>
    </div>
    </div>
</div>