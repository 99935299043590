import { Component, ElementRef, OnInit, HostListener} from '@angular/core';
import { AudioAnnotation } from 'src/app/shared/models/audio-annotation.model';
import { PSV_Meta } from 'src/app/shared/models/psv.model';
import { SceneModel } from 'src/app/shared/models/scene.model';
import { PreviewModel } from 'src/app/shared/models/preview.model';
import { DataService } from 'src/app/shared/services/data.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { SampleAudioService } from 'src/app/shared/services/sample-audio.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { BgVideosComponent } from 'src/app/bg-videos/bg-videos.component';
import { TextAnnotation } from 'src/app/shared/models/text-annotation.model';
import { CssProperties } from 'src/app/shared/models/css-properties.model';
import { Router } from '@angular/router';
import { ReplaceTempUrlsService } from 'src/app/shared/services/replace-temp-urls.service';
import { ThumbnailComponent } from '../thumbnail/thumbnail.component';
import { MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { MediaHubComponent } from '../media-hub/media-hub.component';
import { AudioOverlayAnnotation } from 'src/app/shared/models/audio-overlay.model';

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 500,
  hideDelay: 0,
  touchendHideDelay: 0,
  position: 'right'
};

@Component({
  selector: 'app-scenes',
  templateUrl: './scenes.component.html',
  styleUrls: ['./scenes.component.css'],
  providers: [{provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}]
})
export class ScenesComponent implements OnInit {

  thumbnailUrl: any = "./../assets/data/image.jpg";
  player: string = "player";
  sceneDurations!: number[];
  totalScene!: number;
  isSceneSelected: boolean = false;
  selectedSceneIndex!: number;
  sceneChangeIndex!: number;
  isCharActionClicked: boolean = false;
  isRecActionClicked: boolean = false;
  isRecording: boolean = true;
  charColor: string = "#000000";
  sceneId: string = "player";
  projectName: string = '';
  projectDescription!: string;
  stitchedScenes: boolean = false;
  //TBR
  videoPath = "./assets/videos/waterbill.mp4";
  draggerCurrentPosition: any;
  annotationWidth: any = null;
  annotationHeight: any = null;
  inBounds!: boolean;
  selectedAnnotation: any;
  sampleAudio: any;
  sampleAudioDuration: any;
  sampleAudioEle: any;
  updateDataSubscription!: Subscription;
  publishSubscription!: Subscription;
  allSceneSubscription!: Subscription;
  sceneSelectedSubscription!: Subscription;
  BGVideo: boolean = false;
  clickedVideo: string = "";
  bgVideoComp: any;
  selectedVideoId:string=''
  sceneResolution : any=[];
  sceneDuration : any=[];



  constructor(readonly data: DataService, readonly storageService: StorageService, readonly replaceTempUrlsService: ReplaceTempUrlsService,
    eleRef: ElementRef, readonly element: ElementRef, readonly sampleAudioService: SampleAudioService,
    public dialog: MatDialog, private router: Router) { }

  setBgAudioOnAvailable(){
      const cssprop = new CssProperties
      let bgAudio = new AudioOverlayAnnotation(20, 0.001, this.data.totalEstimatedTime , cssprop);
      bgAudio.srcUrl = this.data.projectModel.bgAudioUrl
      this.data.bgAudioObj = bgAudio;
      for(let scene of this.data.scenes){
        scene.videoMeta.backgroundAudio = new AudioOverlayAnnotation(2, 0.001, scene.duration, cssprop);
        scene.videoMeta.backgroundAudio.srcUrl = this.data.projectModel.bgAudioUrl;
      }
      // this.data.removeAllPsv();
      // this.data.reloadScenes();
      // this.data.setAllPsv();
  }

  async ngOnInit() {
    this.projectName = this.data.titleValue;
    if (this.data.scenes.length !== 0) {
      if(this.data.scenes.length !== this.data.previewScenes.length){
        for (let [id, scene] of this.data.scenes.entries()) {
          this.data.previewScenes.push(new PreviewModel(scene.id, scene.videoPath, "", scene.duration));
        }
      }
      this.totalScene = this.data.scenes.length;
    } else {
      this.totalScene = this.data.scriptArray.length;
      for (let i = 0; i < this.totalScene; i++) {
        this.sceneId = "player" + i.toString();
        let videoPath;
        await this.storageService.getReadOnlyUrl('videos/' + this.data.videoDurations[i] + ' sec white.mp4').then((url:any) =>{
          videoPath = url;
          this.data.scenes.push(new SceneModel('player' + i.toString(), this.data.scriptArray[i].replace('\r', ''), videoPath, this.data));
          this.data.previewScenes.push(new PreviewModel('player' + i.toString(), this.data.scenes[i].videoPath, "", this.data.scenes[i].duration));
        })
        //let videoPath = this.storageService.storageWebServerUrl  + this.storageService.containerName + '/videos/' + this.data.videoDurations[i] + '%20sec%20white' + ".mp4";
      }
    }
    // if(this.data.projectModel.bgAudioUrl){
    //   this.setBgAudioOnAvailable();
    // }
    this.data.setAllPsv();
    this.updateDataSubscription = this.data.updateDataObservable.subscribe(val => {
      if (val === "userdata_updated") {
        this.userdataUpdated();
      }
      else if (val === "template_updated") {
        this.templateUpdated();

      }
    })


    this.publishSubscription = this.data.notifyPublishClickObservable.subscribe((message: any) => {
      if (message === 'clicked') {
        this.data.scenes = this.data.scenes;
        this.previewVideoMeta();
      }
    })

    this.allSceneSubscription = this.data.notifyAllSceneSelectedObservable.subscribe((message:any) =>{
      if(message == 'clicked'){
        this.allSceneSelected();
      }
    })

    this.sceneSelectedSubscription = this.data.notifySceneSelectedObservable.subscribe((message:any) =>{
      if(message == 'clicked'){
        this.sceneSelected(0);
      }
    })

  }


  ngOnDestroy() {
    this.allSceneSubscription.unsubscribe();
    this.sceneSelectedSubscription.unsubscribe();
    this.updateDataSubscription.unsubscribe();
    this.publishSubscription.unsubscribe();
    this.data.notifyOnAllSceneSelected("");
    this.data.notifyOnSceneSelected("");
    this.data.notifyOnUpdateData("");
    this.data.notifyOnPublishClick("");
  }

  initializeDefaultAnnotations(index: any) {
    this.data.stateChanged = true;
    this.data.scenes[index].isDefaultAnnotationsAdded = true;
    const startTime = this.data.currentTime === undefined || this.data.currentTime === 0 ? 0.001 : this.data.currentTime;
    this.data.selectedAnnotation = {};
    const audioId = this.data.overlayJSON.audios.length + 1;
    const audioAnnotation = new AudioAnnotation(audioId, startTime, this.data.scenes[index].duration, this.data.tts);
    let newAudioObject = audioAnnotation;
    newAudioObject.params.Text = this.data.scenes[index].script;
    this.data.selectedAnnotation = newAudioObject;
    this.data.setAnnotationTableData(newAudioObject);
    this.data.overlayJSON.audios.push(newAudioObject);
    this.data.overlayJSON.audios[newAudioObject.id - 1].params.Text = this.data.scenes[index].script;
    this.data.notifyAnnotationSwitch("notified");

    this.data.selectedAnnotation = {};
    let textId = this.data.overlayJSON.overlays.length + 1;
    const cssProps = new CssProperties();
    cssProps.left = '5%';
    const textAnnotation = new TextAnnotation(textId, startTime, this.data.scenes[index].duration, cssProps);
    let newTextObject = textAnnotation;
    this.data.selectedAnnotation = newTextObject;
    newTextObject.value = this.data.scenes[index].script;
    this.data.setAnnotationTableData(newTextObject);
    this.data.overlayJSON.overlays.push(newTextObject);
    this.data.notifyAnnotationSwitch("notified");
    this.data.updateAudio = true;
    this.data.overlayAudioAnnotations(this.data.scenes[index]);
  }

  OnResizing(){
    const overlayObjects = document.querySelectorAll("#psv-overlay-player .custom-overlays");
  }

  allSceneSelected() {
    this.data.onPublishBtn = true;
    this.data.isToolBarOpen = false;
    this.data.scenes[this.data.selectedSceneIndex].videoMeta = this.data.overlayJSON;
    this.data.scenes[this.data.selectedSceneIndex].annotationTableData = this.data.annotationTableData;
    this.data.selectedAnnotation = null;
    this.data.isSceneSelected = false;
    this.isSceneSelected = false;
    this.data.estimatedTime = 0;
    this.data.reloadScenes();
    this.data.setAllPsv();
  }

  sceneSelected(index: number) {
    this.data.onPublishBtn = false;
    this.data.overlayJSON = this.data.scenes[index].videoMeta;
    this.data.annotationTableData = this.data.scenes[index].annotationTableData;
    this.data.selectedScene = new SceneModel("overlay-player", this.data.scenes[index].script, this.data.scenes[index].videoPath, this.data.scenes[index].data);
    this.data.selectedScene.videoMeta = this.data.scenes[index].videoMeta;
    this.data.selectedScene.annotationTableData = this.data.scenes[index].annotationTableData;
    this.data.videoDuration = this.data.scenes[index].duration
    this.data.isSceneSelected = true;
    this.isSceneSelected = true;
    this.data.selectedSceneIndex = index;
    this.sceneChangeIndex = index;
    let domInterval = setInterval(() => {
      if (document.getElementById('psv-overlay-player')) {
        clearInterval(domInterval);
        this.data.selectedScene.setPSV();
      }
    }, 1);
  }

  convertPercentToPx() {
    const offsetWidth = (document.getElementById("psv-overlay-player") as HTMLDivElement)?.offsetWidth;
    if (this.data.selectedAnnotation && this.data.selectedAnnotation.type !== "tts") {
      const xCoOrdinateinPx = (parseFloat(this.data.selectedAnnotation.cssProperties.left.slice(0, -1)) * offsetWidth) / 100;
      const yCoOrdinateinPx = (parseFloat(this.data.selectedAnnotation.cssProperties.paddingTop.slice(0, -1)) * offsetWidth) / 100;
      this.draggerCurrentPosition = { x: xCoOrdinateinPx, y: yCoOrdinateinPx };
      this.data.setDraggerPosition();
    }
  }
  // onPublish() {
  //   this.data.publishLoader = true;
  //   this.data.isFrompreview = false;
  //   this.data.saveLoaded = true;
  //   this.data.onPublishBtn = false;
  //   var projectName = this.data.titleValue;
  //   this.data.notifyOnPublishClick('clicked');
  //   this.concatenate();
  // }
  async onPublish() {
    this.data.publishLoader = true;
    this.data.isFrompreview = false;
    this.data.saveLoaded = true;
    var projectName = this.data.titleValue;
    this.data.notifyOnPublishClick('clicked');

    if (this.data.projectModel.id) {
      this.data.updateScenesAndPSV();
    }
    else {
      this.data.storeScenesAndPSV();
    }

    this.concatenate();


  }

  async concatenate() {

    await this.data.concatenatedDataOnPreview().then(() => {
      this.router.navigate(['/publish']);
    });
  }
  sceneChanged(index: number) {
    this.data.isToolBarOpen = false;
    this.data.scenes[this.data.selectedSceneIndex].videoMeta = this.data.overlayJSON;
    this.data.scenes[this.data.selectedSceneIndex].annotationTableData = this.data.annotationTableData;
    this.data.selectedScene.removePSV();
    //this.data.selectedAnnotation = null;
    this.data.selectedSceneIndex = index;
    this.data.reloadSelectedScene();
    this.data.overlayJSON = this.data.scenes[index].videoMeta;
    this.data.annotationTableData = this.data.scenes[index].annotationTableData;
    this.data.reloadSelectedScene();
    let domInterval = setInterval(() => {
      if (document.getElementById('psv-overlay-player')) {
        clearInterval(domInterval);
        this.data.selectedScene = new SceneModel("overlay-player", this.data.scenes[index].script, this.data.scenes[index].videoPath, this.data.scenes[index].data);
        this.data.selectedScene.videoMeta = this.data.scenes[index].videoMeta;
        this.data.selectedScene.annotationTableData = this.data.scenes[index].annotationTableData;
        this.data.selectedScene.setPSV();
        // this.data.notifyAnnotationUpdate('scene changed');
      }
    }, 1)
  }


  previewVideoMeta() {
    this.data.concatList = [];
    for (let index in this.data.previewScenes) {
      this.data.overlayJSON = this.data.scenes[index].videoMeta;
      var videoData = JSON.stringify(this.data.formatVideoOutputJson());
      var videoDataBlob = new Blob([videoData], { type: 'application/json' });
      var videoDataUrl = URL.createObjectURL(videoDataBlob);
      this.data.previewScenes[index].metaData = videoDataUrl;
      this.data.concatList.push(this.data.previewScenes[index].sceneName);
    }
  }

  async showPreview() {
    if(Object.keys(this.data.userData).length == 0){
      this.data.showSnackBar("Please configure userdata before previewing",'close');
      return;
    }
    this.previewVideoMeta();
    this.data.previewScreen = true;
    if (this.data.projectModel.id) {
      await this.data.updateScenesAndPSV();
    }
    else {
      await this.data.storeScenesAndPSV();
    }
    //checking whether there's any scene with blob video, and if there's, uploading it to minio
    await this.replaceTempUrlsService.replaceTempUrls();
    await this.data.updateScenesAndPSV();
    //concatenation of scenes
    this.previewVideoMeta();
    await this.data.concatenatedDataOnPreview();
  }

  // async concatenate() {
  //   await this.data.concatenatedDataOnPreview();
  // }

  async SavePreview() {
    if(Object.keys(this.data.userData).length == 0){
      this.data.showSnackBar("Please configure userdata before previewing",'close');
      return;
    }
    this.data.projectModel.createdBy = this.data.userId;
    this.data.saveLoaded = true;
    this.data.isFrompreview = false;
    if (this.data.projectModel.id) {
      await this.data.updateScenesAndPSV();
    }
    else {
      await this.data.storeScenesAndPSV();
    }
    await this.replaceTempUrlsService.replaceTempUrls();
    await this.data.updateScenesAndPSV();
    this.data.saveLoaded = false;
  }


  changeSceneScript(value: any) {
    this.data.stateChanged = true;
    this.data.scenes[this.data.selectedSceneIndex].setSceneScript(value);
    this.data.scriptArray[this.data.selectedSceneIndex] = value;
    this.data.selectedScene.setSceneScript(value);
    if (this.data.tts === 'polly') {
      this.data.overlayJSON.audios[0].params.Text = value;
    } else if (this.data.tts === "google") {
      this.data.overlayJSON.audios[0].params.input.ssml = value;
    } else if (this.data.tts === "azure") {
      this.data.overlayJSON.audios[0].params.Text = value;
    }
    this.data.annotationTableData[this.data.getAnnotationTableId(this.data.overlayJSON.audios[0])].value = value;
    this.data.updateAudio = true;
    this.data.notifyOnUpdateTemplate("scene_updated");
    this.data.overlayAnnotations();
  }

  cancelPreview($event: any) {
    this.data.previewScreen = false;
  }

  changeBGVideo(index: number, videoId: string) {
    this.selectedVideoId=videoId

    this.clickedVideo = "";
    this.data.bgSceneIndex=index;
    let dialogRef = this.dialog.open(MediaHubComponent, { disableClose: true});
    dialogRef.componentInstance.mediaType = 'bgVideo';
    dialogRef.afterClosed().subscribe((result:any) =>{
      if(result){
        this.setVideopath(index, result.url, result.duration);
      }
    })
    //=l;
    if (this.clickedVideo != "") {
    }
  }

  setVideopath(index: number, path: string, videoDuration:any) {
    this.data.stateChanged = true;
    this.data.scenes[index].videoPath = path;
    this.data.previewScenes[index].sceneUrl = path;
    this.data.correctAnnotationEndTimes(videoDuration, index);
    this.data.removeAllPsv();
    this.data.reloadScenes();
    setTimeout(() => {
      document.getElementById(this.selectedVideoId)?.scrollIntoView({behavior:'smooth',block: 'end'} )
    });
    this.data.setAllPsv();
  }
  changeColor(val: string) {
    this.charColor = val;
  }
  setSceneScript(val: string, index: number) {
    this.data.stateChanged = true;
    val = val.replace('\n', '');
    let updatedSceneLines = "";
    this.data.scenes[index].setSceneScript(val);
    this.data.scriptArray[index] = val;
    for (let i = 0; i < this.data.scriptArray.length; i++) {
      if (this.data.scriptArray.length - 1 === i) {
        updatedSceneLines += this.data.scriptArray[i];
      }
      else {
        updatedSceneLines += this.data.scriptArray[i] + '\n';
      }
    }
    this.data.fileContent = updatedSceneLines;
    if (this.data.tts === 'polly') {
      this.data.scenes[index].videoMeta.audios[0].params.Text = val;
    } else if (this.data.tts === "google") {
      this.data.scenes[index].videoMeta.audios[0].params.input.ssml = val;
    } else if (this.data.tts === "azure") {
      this.data.scenes[index].videoMeta.audios[0].params.Text = val;
    }

    let psv = new PSV_Meta(this.data.scenes[index].id, this.data.scenes[index].videoSource, this.data.scenes[index].videoMeta, this.data.userData, this.data.ttsOptions);
    this.data.callAddDynamicAudioToVideo(psv);
    // this.data.notifyOnUpdateTemplate("scene_updated");
  }


  playVideo() {
    this.data.isVideoPlaying = true;
  }

  @HostListener('window:sampleCallback', [ '$event' ])
  psvEventListener(event:any) {
    //Call respective method additionally pass params as required
    alert("An example to show callback function ");

  }

  async calculateVideoProperties(value: any) {
    this.data.aspectRatio = [value.target.videoWidth, value.target.videoHeight];
    const widthToHeightRatio: any = value.target.videoWidth / value.target.videoHeight;
    this.data.videoDuration = value.target.duration;
    this.data.isTimelineLoaded = true;
    this.data.notifyVideoMetaDataLoaded(true);
    this.element.nativeElement.querySelector("#psv-overlay-player > video").addEventListener("timeupdate", (event: any) => {
      this.data.currentTime = (event.currentTarget as any).currentTime;
    });

    this.annotationWidth = this.data.annotationWidth
    this.annotationHeight = this.data.annotationHeight
    this.inBounds = true;
    setTimeout(() => {
      this.selectedAnnotation = this.data.selectedAnnotation
    }, 0);
    this.data.annotationSwitchNotifyObservable.subscribe(data => {
      this.selectedAnnotation = this.data.selectedAnnotation
      if (data === "notified") {
        this.convertPercentToPx();
      }
    });
    this.data.notifyAnnotationUpdateObservable.subscribe(data => {
      if (!this.data.selectedAnnotation) {
        this.selectedAnnotation = this.data.selectedAnnotation
      }
    })
    this.data.updateAudio = true;
    await this.data.overlayAnnotations();
    let annotationElements = document.getElementsByClassName("custom-overlays") as HTMLCollectionOf<HTMLDivElement>;
    for(let i = 0; i < annotationElements.length; i++){
      annotationElements[i].addEventListener('click', ($event:any) =>{
        console.log("selected" + annotationElements[i].id);
      })
    }
    this.data.initializeFontSize();
  }

  calculateSceneDuration(value: any, index: any) {
    this.data.aspectRatio = [value.target.videoWidth, value.target.videoHeight];
    this.data.scenes[index].duration = value.target.duration;
    this.data.previewScenes[index].videoDuration = value.target.duration;
    this.data.estimatedTime += this.data.scenes[index].duration;
    this.data.totalEstimatedTime = this.data.estimatedTime;
    this.data.overlayJSON = this.data.scenes[index].videoMeta;
    this.data.annotationTableData = this.data.scenes[index].annotationTableData;
    if (!this.data.scenes[index].isDefaultAnnotationsAdded) {
      this.initializeDefaultAnnotations(index);
    }
    else {
      this.data.updateAudio = true;
      this.data.overlayAudioAnnotations(this.data.scenes[index]);
      this.data.selectedDatasourceId = this.data.annotationTableData.length - 1;
    }
    this.data.scenes[index].videoMeta = this.data.overlayJSON;
    this.data.scenes[index].annotationTableData = this.data.annotationTableData;
    let videoName = (value.target.currentSrc).split("/videos/")[1] ? (value.target.currentSrc).split("/videos/")[1]:(value.target.currentSrc).split("/videogallery/")[1] ?(value.target.currentSrc).split("/videogallery/")[1]:this.data.scenes[index].videoName ;
    videoName = videoName.split('?')[0].replaceAll("%20"," ");
    this.data.scenes[index].videoName = videoName;
    this.sceneResolution[index]=value.target.videoWidth+" x "+ value.target.videoHeight;
    this.sceneDuration[index]=((value.target.duration).toFixed(2))+" sec";
  }

  async deleteScene(index: number) {
    if(this.data.scenes.length > 1){
      this.data.stateChanged = true;
      let updatedSceneLines = "";

      let sceneId = this.data.scenes[index]._id;
      let psvId = this.data.scenes[index].psvId;

      if (this.data.projectModel.id) {
        await this.data.deletePSV(psvId).then(async () => {
          await this.data.deleteScene(sceneId).then(() => {
            this.data.projectModel.scenes.splice(index, 1);
          })
        })
      }

      this.data.removeAllPsv();
      this.data.scenes.splice(index, 1);
      this.totalScene = this.data.scenes.length;
      this.data.previewScenes.splice(index, 1);
      this.updatePlayerId();
      this.data.reloadScenes();
      this.data.setAllPsv();
      this.data.scriptArray.splice(index, 1);
      for (let i = 0; i < this.data.scriptArray.length; i++) {
        if (this.data.scriptArray.length - 1 === i) {
          updatedSceneLines += this.data.scriptArray[i];
        }
        else {
          updatedSceneLines += this.data.scriptArray[i] + '\n';
        }
      }
      this.data.fileContent = updatedSceneLines;
      this.data.notifyOnUpdateTemplate("scene_updated");
    } else {
      this.data.showSnackBar("No. of Scenes Should be Greater Than Zero","OK");
    }
  }

  async addScene(index: number) {
    this.data.stateChanged = true;
    this.data.removeAllPsv();
    let newIndex = index + 1;
    let videoPath;
    await this.storageService.getReadOnlyUrl('videos/6 sec white.mp4').then(url =>{
      videoPath = url;
    })
    let newScene = new SceneModel("player" + newIndex.toString(), "", videoPath, this.data);
    this.data.scenes.splice(newIndex, 0, newScene);
    this.data.previewScenes.splice(newIndex, 0, new PreviewModel("player" + newIndex.toString(), newScene.videoPath, "", newScene.duration));
    this.data.scriptArray.splice(newIndex, 0, newScene.script);
    this.totalScene = this.data.scenes.length;
    this.updatePlayerId();
    this.data.reloadScenes();
    this.data.setAllPsv();
    this.data.notifyOnUpdateTemplate("scene_updated");

    let psvObj = this.data.scenes[newIndex].getPSVObjForDB();
    let sceneObj: any = {};

    if (this.data.projectModel.id) {
      await this.data.storePSV(psvObj).then(async (psvId: any) => {
        this.data.scenes[newIndex].psvId = psvId;
        sceneObj = this.data.scenes[newIndex].getSceneObjForDB();

        await this.data.storeScene(sceneObj).then((sceneId: any) => {
          this.data.scenes[newIndex]._id = sceneId;
          this.data.projectModel.scenes.splice(newIndex, 0, sceneId);
        })
      })
    }
  }


  updatePlayerId() {
    for (let i = 0; i < this.data.scenes.length; i++) {
      this.data.scenes[i].id = "player" + i.toString();
    }
    for (let i = 0; i < this.data.previewScenes.length; i++) {
      this.data.previewScenes[i].sceneName = "player" + i.toString();
    }
  }


  userdataUpdated() {
    this.data.stateChanged = true;
    this.data.removeAllPsv();
    this.data.reloadScenes();
    this.data.setAllPsv();
  }

  test: any = [' '];


  async templateUpdated() {
    this.data.selectedDatasourceId = this.data.annotationTableData.length - 1;
    this.data.removeAllPsv();
    this.data.previewScenes = [];
    this.totalScene = this.data.scriptArray.length;
    let extraScenesNum = this.data.scenes.length - this.totalScene;
    if (extraScenesNum >= 0) {
      for (let i = 0; i < this.totalScene; i++) {
        let sceneId = "player" + i.toString();
        this.data.scenes[i].script = this.data.scriptArray[i].replaceAll('\r', '');
        this.data.scenes[i].videoMeta.audios[0].params.Text = this.data.scriptArray[i].replaceAll('\r', "");
        this.data.scenes[i].annotationTableData[this.data.getAnnotationTableId(this.data.scenes[i].videoMeta.audios[0])].value = this.data.scriptArray[i];
        this.data.previewScenes.push(new PreviewModel(sceneId.toString(), this.data.scenes[i].videoPath, "", this.data.scenes[i].duration));
      }
      this.data.scenes.splice(this.totalScene, extraScenesNum);
      this.data.reloadScenes();
      this.data.setAllPsv();
    }
    else {
      for (let i = 0; i < this.totalScene; i++) {
        let sceneId = "player" + i.toString();
        let videoPath;
        await this.storageService.getReadOnlyUrl('videos/6 sec white.mp4').then(url =>{
          videoPath = url;
        })
        if (this.data.scenes[i]) {
          this.data.scenes[i].script = this.data.scriptArray[i].replaceAll('\r', '');
          this.data.scenes[i].videoMeta.audios[0].params.Text = this.data.scriptArray[i].replaceAll('\r', '');
          this.data.scenes[i].annotationTableData[this.data.getAnnotationTableId(this.data.scenes[i].videoMeta.audios[0])].value = this.data.scriptArray[i];
        }
        else {
          this.data.scenes.push(new SceneModel(sceneId.toString(), this.data.scriptArray[i], videoPath, this.data));
        }
        this.data.previewScenes.push(new PreviewModel(sceneId.toString(), this.data.scenes[i].videoPath, "", this.data.scenes[i].duration));
      }
      this.data.reloadScenes();
      this.data.setAllPsv();
    }
  }



//   setAnnotationPosition(coOrdinates: any) {
//     const offsetWidth = (<HTMLDivElement>document.getElementById("psv-overlay-player")).offsetWidth;
//     const xCoOrdinateinPx = coOrdinates.x;
//     const yCoOrdinateinPx = coOrdinates.y;
//     const xCoOrdinateinPercent = (xCoOrdinateinPx * 100) / offsetWidth;
//     const yCoOrdinateinPercent = (yCoOrdinateinPx * 100) / offsetWidth;
//     this.data.overlayJSON.overlays[this.data.selectedAnnotation.id - 1].cssProperties.left = `${xCoOrdinateinPercent}%`;
//     this.data.overlayJSON.overlays[this.data.selectedAnnotation.id - 1].cssProperties.paddingTop = `${yCoOrdinateinPercent}%`;
//     this.data.setDraggerPosition();
//     this.data.overlayAnnotations();
//   }

//   startResizing(){
//   }
// setAnnotationPositionResizer(cord:any){

//     const widthInPx = cord.size.width;
//     const heightInPx = cord.size.height;
//     const widthInEm = `${(widthInPx)/30}em`;
//     const heightInEm = `${(heightInPx )/30}em`;
//     this.data.overlayJSON.overlays[this.data.selectedAnnotation.id - 1].cssProperties.width = widthInEm;
//     this.data.overlayJSON.overlays[this.data.selectedAnnotation.id - 1].cssProperties.height = heightInEm;
//     if(this.data.selectedAnnotation.type === 'chart'){
//       this.data.overlayJSON.overlays[this.data.selectedAnnotation.id - 1].data.options.height = heightInEm;
//       this.data.overlayJSON.overlays[this.data.selectedAnnotation.id - 1].data.options.width = widthInEm;
//     }
//     this.data.setDraggerPosition();
//     this.data.overlayAnnotations();
//   }

  calculateAnnotationPosition(coOrdinates: any) {
    const offsetWidth = (<HTMLDivElement>document.getElementById("psv-overlay-player")).offsetWidth;
    const xCoOrdinateinPx = coOrdinates.x;
    const yCoOrdinateinPx = coOrdinates.y;
    const xCoOrdinateinPercent = (xCoOrdinateinPx * 100) / offsetWidth;
    const yCoOrdinateinPercent = (yCoOrdinateinPx * 100) / offsetWidth;
    const annotationType = this.data.selectedAnnotation.type
    let annotation

    switch (annotationType) {
      case 'image':
        annotation = document.getElementById(this.data.selectedAnnotation.id)?.parentElement
        if (annotation) {
          annotation.style.paddingTop = `${yCoOrdinateinPercent}%`;
        }
        break;
      case 'chart':
        if (this.data.selectedAnnotation.chartName === 'BAR - VARIANT 1' || this.data.selectedAnnotation.chartName === "PIE - VARIANT 1") {
          annotation = document.getElementById(`overlay-player${this.data.selectedAnnotation.id}`)
        }
        else {
          annotation = (document.getElementById(`overlay-player${this.data.selectedAnnotation.id}`) as HTMLDivElement).parentElement

        }
        if (annotation) {
          annotation.style.marginTop = `${yCoOrdinateinPercent}%`
        }
        break;
      default:
        annotation = (document.getElementById(this.data.selectedAnnotation.id) as HTMLDivElement).parentElement;
        if (annotation) {
          if (annotationType === "progressbar") {
            annotation.style.marginTop = `${yCoOrdinateinPercent}%`
          }
          else { annotation.style.paddingTop = `${yCoOrdinateinPercent}%` }
        }
        break;
    }
    if (annotation) {
      annotation.style.left = `${xCoOrdinateinPercent}%`
    }
  }

  deleteSceneWithID() {
    throw new Error('Function not implemented.');
  }

  openPosterDialog(): void {
    const dialogRef = this.dialog.open(ThumbnailComponent, {
      data: {}, autoFocus: false
    });
  }
}
