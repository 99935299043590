<div class="container">
    <div>
        <strong class="main-headings">New Project</strong>
        <em class="icon-class material-icons" (click)="cancelDialog()">highlight_off</em>
    </div>
    <mat-form-field>
        <mat-label>Project Name</mat-label>
        <input autocomplete="off" matInput type="text" [formControl]="title" required (change)="setTitle(title.value)">
        <mat-error *ngIf="title.invalid"></mat-error>
    </mat-form-field>
    <mat-form-field>
        <textarea matInput placeholder="Description" #description
            (change)="setDescription(description.value)"></textarea>
    </mat-form-field>
    <button class="btns primary-btn" (click)="createNewProject()" [disabled]="title.invalid">Start</button>
    <!-- <div class="upload-btn-container">
        <button class="upload-btn logo-bg-color"><em class="material-icons">file_upload</em></button>
        <strong>Upload Video</strong>
    </div> -->
</div>