/*******************************************************************************
 * COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
 * Reserved. Infosys believes the information in this document is accurate as of
 * its publication date; such information is subject to change without notice.
 * Infosys acknowledges the proprietary rights of other companies to the
 * trademarks, product names and such other intellectual property rights
 * mentioned in this document. Except as expressly permitted, neither this
 * documentation nor any part of it may be reproduced, stored in a retrieval
 * system, or transmitted in any form or by any means, electronic, mechanical,
 * printing, photocopying, recording or otherwise, without the prior permission
 * of Infosys Limited and/or any named intellectual property rights holders
 * under this document.
 ******************************************************************************/
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'durationFormat'
})
export class DurationFormatPipe implements PipeTransform {

  transform(value: any, type?: string){
    let mins, secs , msec;
    if(value===0){
      return `00:00.000`;
    }
    else if(value!==undefined){
      const ind = value.toString().indexOf(".")
      if (value >= 60) {
        mins = Math.floor(value / 60);
        mins = (mins < 10) ? `0${mins}` : mins;
      } else {
        mins = '00';
      }
      secs = Math.floor(value % 60);
      secs = (secs < 10) ? `0${secs}` : secs;
      if (!type) {
        return `${mins}:${secs}`;
      } else if (type === 'mm:ss.sss') {
        if(ind<=0){ 
          msec = '000';
        }
        else{
          msec=value.toString().slice(ind+1, ind+4);
          if(msec === '0' || !ind){
            msec='000';
          }
          else if(msec.length===1){
            msec=`${msec}00`
          }
          else if(msec.length===2){
            msec=`${msec}0`
          }
        }
        return `${mins}:${secs}.${msec}`;
      }
      return value;
  }
}

}
