import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-template-modal',
  templateUrl: './template-modal.component.html',
  styleUrls: ['./template-modal.component.css']
})
export class TemplateModalComponent implements OnInit {

  constructor( private dialogRef: MatDialogRef<TemplateModalComponent>,private dialog: MatDialog) { }
  onSave:any;
  templateTitle: string;
  inputForm = new FormGroup({
    templateTitle: new FormControl(null, Validators.required)    
  });
  ngOnInit(): void {
  }
  closeDialog(){
    this.dialogRef.close()
  }
  save(){
    if(this.templateTitle){
    this.dialogRef.close(this.templateTitle);
    }
  }
}
