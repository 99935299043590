<div [ngClass]="{'dim': this.data.saveLoaded}" class="container containers-common-style">
    <span class="heading-style" style="margin-top: 1rem" align="center">Background Music</span>
    <button class="add-section-btn containers-common-style sec-headings-icons" (click)="bgAudioAndPoster('audio')">
        Choose Music from gallery
    </button>
    <div *ngIf="data.bgAudioObj">
        <audio #bgAudio [src]="data.bgAudioObj.srcUrl" class="bg-audio-tag" controls></audio>
            <div>
                <mat-icon class="volumeicon" color="primary">volume_up</mat-icon>
                <mat-slider class="slider" #bgvolume color="primary" [ngModel]="data.projectModel.bgAudioVolume"
                (ngModelChange)="data.setBgAudioVolume(bgvolume.value, bgAudio)" min="0" max="1" step="0.05">
                </mat-slider>
            </div>
    </div>
    <div *ngIf="!data.bgAudioObj">
        <p class="msg">Please select the Background Music </p>
    </div>
    <hr class="dashed">
    <span class="heading-style" align="center" style="margin-top: -1rem">Poster</span>
    <button class="add-section-btn containers-common-style sec-headings-icons" (click)="bgAudioAndPoster('image')">
        Choose Poster Image from gallery
    </button>
    <div *ngIf="posterUrl" class="image-style" align="center">
        <img height="75%" width="75%" [src]="posterUrl" alt="Project">
    </div>
    <div *ngIf="!posterUrl">
        <p class="msg">Please select the Poster Image</p>
    </div>

</div>