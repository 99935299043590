<!------------------------------------------------------------------------------
 # COPYRIGHT NOTICE © 2017 Infosys Limited, Bangalore, India. All Rights
 # Reserved. Infosys believes the information in this document is accurate as of
 # its publication date; such information is subject to change without notice.
 # Infosys acknowledges the proprietary rights of other companies to the
 # trademarks, product names and such other intellectual property rights
 # mentioned in this document. Except as expressly permitted, neither this
 # documentation nor any part of it may be reproduced, stored in a retrieval
 # system, or transmitted in any form or by any means, electronic, mechanical,
 # printing, photocopying, recording or otherwise, without the prior permission
 # of Infosys Limited and/or any named intellectual property rights holders
 # under this document.
-------------------------------------------------------------------------------->
<div class="change-video-box">
  <h3 class="heading">
    Text to Speech Configuration
  </h3>
  <div class="tts-form">
    <div class="form-element">
      <label class="radio-group-label">Provider</label>
      <mat-radio-group class="radio-group" aria-label="Select TTS Provider" [(ngModel)]="ttsProvider" (change)="changeTTSProvider()">
        <mat-radio-button class="radio-button" value="polly">AWS Polly</mat-radio-button>
        <mat-radio-button class="radio-button" value="google">Google</mat-radio-button>
        <mat-radio-button class="radio-button" value="azure">Azure</mat-radio-button>
      </mat-radio-group>
    </div>

      <div  class="form-element" *ngIf="ttsProvider=='polly'">
        <label class="radio-group-label">TTS Engine</label>
        <mat-radio-group class="radio-group" aria-label="Select voice Engine" [(ngModel)]="params.ttsEngine" (change)="updateSampleAudio(params)" >
          <mat-radio-button class="radio-button" value="standard">Standard</mat-radio-button>
          <mat-radio-button class="radio-button" value="neural">Neural</mat-radio-button>
        </mat-radio-group>
      </div>

      <div  class="form-element" *ngIf="ttsProvider=='google' || ttsProvider=='azure'">
        <label class="radio-group-label">Voice Type</label>
        <mat-radio-group class="radio-group" aria-label="Select voice type" [(ngModel)]="params.ttsvoiceType" (change)="changeVoiceType()" >
          <mat-radio-button *ngFor="let voice of params.ttsProviderTypes" class="radio-button" [value]="voice">{{voice}}</mat-radio-button>
        </mat-radio-group>
        </div>


    <div class="form-element">
      <label class="radio-group-label m-top3">Languages</label>
      <mat-form-field>
        <mat-select [(ngModel)]="params.selectedLanguage" (selectionChange)="changeTTSLanguage()">
          <mat-option *ngFor="let language of params.languagesList" [value]="language">
            {{language}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-element">
      <label class="radio-group-label m-top3">Voices</label>
      <mat-form-field>
        <mat-select [(ngModel)]="params.selectedVoice" (selectionChange)="changeTTSVoice()">
          <mat-option *ngFor="let voice of params.voicesList" [value]="voice">
            {{voice}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
      <div class="form-element">
       <label  class="radio-group-label m-top3">Sample audio</label>
       <mat-progress-spinner  style=" margin-top:20px ; " *ngIf="changeAudio" mode="indeterminate" [diameter]="25"></mat-progress-spinner>
        <audio  *ngIf="sampleAudio && !changeAudio" controls #audio [src]="sampleAudio | sanitizeVideo " type="audio/mp3" >
      </audio>
      </div>
  <div mat-dialog-actions class="dialog-buttons">
    <div class="disclaimer-text">
      Disclaimer: Please note that while adding any audio script on PSV, you understand and will ensure that you do not enter Client Personal and Sensitive data on this platform since it will be sent to respective Cloud TTS provider for processing. By continuing, you acknowledge this disclaimer.
    </div>
    <button mat-button (click)="dismiss()" class="cancel">Cancel</button>
    <button mat-button (click)="updateTTS()"
      class="submit">Update</button>
  </div>
</div>
</div>