<div class="dialog-container">
    <div>
        <form action="#">
            <input class="search-bar" type="text" #mediaName placeholder={{galleryView}} name="search">
            <button class="search-Btn" (click)="onSearch(mediaName.value)">
                <em class="fa fa-search fa-small" style="color:#8d8d8d"></em>
            </button>
        </form>
    </div>
    <div class="media-container">
        <div class="gallery-list" *ngIf="this.data.selectedAnnotation.type =='image'">
            <div class="gallery-card" *ngFor="let media of this.data.matchedMedia; let i=index;">
                <div (click)="radioChecked(media,i)" [style.background]="selectedMediaIndex === i ? '#464646' : '' " class="highlight">
                    <div class="image-style">
                        <!-- <input #checkbox (click)="radioChecked(imgcheck,i)" type="radio" name="images" class="checkbox"
                            id={{i}} /> -->
                        <img #imgcheck [src]="media" width="100%" for="check" height="100%">
                    </div>
                    <span class="media-label">{{this.data.matchedNamesOfMedia[i]}}</span>
                </div>
            </div>
        </div>
        <div class="gallery-list" *ngIf="this.data.selectedAnnotation.type =='document'">
            <div class="gallery-card" *ngFor="let media of this.data.matchedMedia; let i=index;">
                <div (click)="radioChecked(media,i)" [style.background]="selectedMediaIndex === i ? '#464646' : '' " class="highlight">
                    <div class="image-style">
                        <input type="file" [src]="media"  width="100%" height="100%">
                    </div>
                    <span class="media-label">{{this.data.matchedNamesOfMedia[i]}}</span>
                </div>
            </div>
        </div>

        <div class="gallery-list" *ngIf="this.data.selectedAnnotation.type =='audio'">
            <div class="gallery-card audio" *ngFor="let media of this.data.matchedMedia; let i=index;">
                <div (click)="radioChecked(media,i)" [style.background]="selectedMediaIndex === i ? '#464646' : '' " class="highlight-audio">
                    <div class="audio-style">
                        <!-- <input #checkbox (click)="radioChecked(audiocheck,i)" type="radio" name="audios" class="checkbox"
                            id={{i}} /> -->
                        <audio controls controlsList="nodownload"><source [src]=media type="audio/mpeg"></audio>
                    </div>
                    <span [style.color]="selectedMediaIndex === i ? '#ffffff' : '' " class="media-label">{{this.data.matchedNamesOfMedia[i]}}</span>
                </div>
            </div>
        </div>

        <div class="gallery-list" *ngIf="this.data.selectedAnnotation.type =='video'">
            <div class="gallery-card video" *ngFor="let media of this.data.matchedMedia; let i=index;">
                <div (click)="radioChecked(media,i)" [style.background]="selectedMediaIndex === i ? '#464646' : '' " class="highlight">
                    <div class="video-style">
                        <!-- <input #checkbox (click)="radioChecked(videocheck,i)" type="radio" name="videos" class="checkbox"
                            id={{i}} /> -->
                        <video #videocheck [src]="media" controls for="check" height="100%" width="100%"></video>
                    </div>
                    <span class="media-label">{{this.data.matchedNamesOfMedia[i]}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="btn-holder">
        <button type="button" mat-button (click)="closeDialog()" class="cancel">Cancel</button>
        <button type="button" mat-button (click)="loadMedia()" class="upload">Add</button>
    </div>
</div>