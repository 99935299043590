/*******************************************************************************
 * COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
 * Reserved. Infosys believes the information in this document is accurate as of
 * its publication date; such information is subject to change without notice.
 * Infosys acknowledges the proprietary rights of other companies to the
 * trademarks, product names and such other intellectual property rights
 * mentioned in this document. Except as expressly permitted, neither this
 * documentation nor any part of it may be reproduced, stored in a retrieval
 * system, or transmitted in any form or by any means, electronic, mechanical,
 * printing, photocopying, recording or otherwise, without the prior permission
 * of Infosys Limited and/or any named intellectual property rights holders
 * under this document.
 ******************************************************************************/
 import { CssProperties } from './css-properties.model';
 import { DataService } from '../services/data.service';
 export class WebpageAnnotation {
     
     type!: string;
     value!: string;
     className!: string;
     start: number;
     id: number;
     end: number;
     cssProperties: CssProperties;
     src!: string;
     webpageData!:string;
     altText!: string;
     link!: string;
     maintainAspectRatio!: boolean;
     webpageName!:string;
     exitAnimation!: string;
     bgToggle!:boolean;
     callback!:string;
     isDurationChanged:boolean = false;
 
     constructor(id: number, currentTime:number, videoDuration: number, cssProps: CssProperties){
         this.end = videoDuration;
         this.id = id;
         this.start = currentTime;
         this.cssProperties = cssProps;
         this.initializeAttributes();
         
     }
 
     initializeAttributes(){
         this.type = 'webpage';
         this.value = '';
         this.className = '';
         this.src = "";
         this.webpageData = "";
         this.altText = "Invalid Path";
         this.maintainAspectRatio = true;
         this.webpageName="";
         this.bgToggle=false;
         this.exitAnimation = "";
         this.link = "";
         this.callback="";
     }
 
     getAttributes(){
         return(
             {
                 type: this.type,
                 value: this.value,
                 id: this.id,
                 "src": this.src === "" ? this.webpageData : this.src,
                 "alt-text": this.altText,
                 "class-name": this.className,
                 start: this.start,
                 end: this.end,
                 link: this.link,
                 exitAnimation: this.exitAnimation,
                 maintainAspectRatio: this.maintainAspectRatio,
                 callback: this.callback,
                 "css-properties": this.cssProperties.getWebpageAttributes(),
                 webpageName:this.webpageName
             }
         );
     }
 
     transformAttributes(overlayObject:any, cssProps: CssProperties){
         const overlayAnnotationObject = new WebpageAnnotation(overlayObject.id, overlayObject.srart, overlayObject.end, cssProps);
         if(overlayObject.webpageName) {
             overlayAnnotationObject.webpageData = overlayObject["src"];
         } else {
             overlayAnnotationObject.src = overlayObject["src"];
         }
         overlayAnnotationObject.altText = overlayObject["alt-text"];
         overlayAnnotationObject.start = overlayObject.start;
         overlayAnnotationObject.value = overlayObject.value;

         overlayAnnotationObject.maintainAspectRatio = overlayObject.maintainAspectRatio;
         overlayAnnotationObject.className = overlayObject["class-name"];
         overlayAnnotationObject.webpageName =overlayObject.webpageName;
         overlayAnnotationObject.link =overlayObject.link;
         overlayAnnotationObject.callback=overlayObject.callback;
         overlayAnnotationObject.bgToggle =overlayAnnotationObject.cssProperties.backgroundColor!=='transparent'?true:false;
         overlayAnnotationObject.exitAnimation = overlayObject.exitAnimation || "";
         if(overlayAnnotationObject.cssProperties.animation === "" && overlayAnnotationObject.className) {
             overlayAnnotationObject.cssProperties.animation = `${overlayAnnotationObject.className.split(" ")[1]} ${overlayAnnotationObject.cssProperties.animationDuration}`;
             overlayAnnotationObject.className = "";
         }
         return overlayAnnotationObject;
     }
 
 }
 