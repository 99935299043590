const proxyUrl = "https://experiencepsv.idemo-ppc.com";
export const environment = {
  production: true,
  azureContainerName: "psv2-test",
  storageWebServerUrl: proxyUrl + "/web-server/",
  storageService: proxyUrl + "/az-blob-storage-service/",
  audioService: proxyUrl + "/audio-service",
  concatServiceUrl: proxyUrl + "/az-concat-service/",
  dbUrl: proxyUrl + "/psv-db/",
  embedUrl: proxyUrl + "/published",
  publishUrl: proxyUrl + "/published",
  clientId: '7820877c-ea1e-409b-8910-7a5f66c7b94c', // Application (client) ID from the app registration
  authority: 'https://login.microsoftonline.com/63ce7d59-2f3e-42cd-a8cc-be764cff5eb6', // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
  redirectUri: proxyUrl + '/studio',// This is your redirect URI
  keycloakUrl:'https://10.156.89.37:8080',
  isTtsAvailable: true
};
