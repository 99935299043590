export class PreviewModel{
    sceneName:string;
    sceneUrl:string;
    metaData:string;
    videoDuration:number;
    captionUrl: string;

    constructor(sceneName:string, sceneUrl:string, metaData:string, videoDuration:number){
        this.sceneName = sceneName;
        this.sceneUrl = sceneUrl;
        this.metaData = metaData;
        this.videoDuration = videoDuration;
    }
}