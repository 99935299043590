import { Component } from '@angular/core';
import {MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions} from '@angular/material/tooltip';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import { DataService } from './shared/services/data.service';
import { ProfileInfo } from './shared/interfaces/profile-info';

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 500,
  hideDelay: 0,
  touchendHideDelay: 0,
  position: 'below'
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [{provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}]
})
export class AppComponent {

  constructor(readonly data:DataService, private authService: MsalService, private broadcastService: MsalBroadcastService){}

  title = 'psv2-studio';
  isIframe = false;
  isLoggedIn: boolean = false;
  isAccessDenied: boolean = false;
  allowedUsers: string[] = ["govind.r01@infosys.com", "vinay_r@infosys.com", "anshika.singh06@infosys.com", "reshma.thomas@infosys.com", "vasudha.gurram@infosys.com", "pranavsai.muramalla@infosys.com", "pankaj.negi@infosys.com", "srushti.kadam01@infosys.com"];
  private readonly _destroying$ = new Subject<void>();

  ngOnInit(){
    this.isIframe = window !== window.parent && !window.opener;
    if(!this.authService.instance.getActiveAccount){
      this.login();
    }

    this.broadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.data.getProfileInfo().then((profileInfo: ProfileInfo) =>{
        if(this.allowedUsers.includes(profileInfo.mail.toLowerCase())){
          this.isLoggedIn = true;
        }
        else{
          this.isAccessDenied = true;
        }
      })
    })
  }

  login() {
    this.authService.loginRedirect();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
