import { Component, Input, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from 'src/app/shared/services/data.service';
import { StorageService } from 'src/app/shared/services/storage.service';


@Component({
  selector: 'app-media-dialog',
  templateUrl: './media-dialog.component.html',
  styleUrls: ['./media-dialog.component.css']
})
export class MediaDialogComponent implements OnInit {

  url:any;
  fontSize: any;
  videoUrl: any;
  projectName: any = this.data.titleValue;
  audioSaveLoader: boolean = false;
  videoSaveLoader: boolean = false;
  @Input() mediaAnnotation:any;
  constructor(public data:DataService, readonly sanitizer: DomSanitizer, private storageService: StorageService,private dialogRef : MatDialogRef<MediaDialogComponent>) { }

  ngOnInit(): void {
  }

  callUpdateCurrentTime($event:any){
    this.data.updateCurrentTime($event);
  }


  async saveAudio($event:any){
    this.audioSaveLoader=true;
    let srcUrl = this.data.blobs.createObjectURL($event);
    this.audioSaveLoader = false;
    this.dialogRef.close(srcUrl);
//Rework
    //  if(this.mediaAnnotation){
    //   await this.storageService.deleteMediaFiles(this.projectName,this.mediaAnnotation).then(async () =>{
    //     await this.storageService.uploadMediaFiles("liveAudio",$event, this.projectName , titleId).then(datas => {
    //       this.url = this.storageService.storageWebServerUrl + this.storageService.bucketname + '/projects/'+ this.data.titleValue+'/media/'+ this.mediaAnnotation.srcUrl;
          
    //     });
    //   })
    // }
  }



  

  initializeFontSize(){
    this.data.notifyVideoLoadedObservable.subscribe(data=>{
      setTimeout(() => {
        this.fontSize = parseFloat((document.querySelectorAll("#psv-overlay-player > div.vjs-overlay")[0] as HTMLElement).style.fontSize.split("px")[0]);
      }, 50);
    })
  }

async saveVideo(mediaBolb:any){
  this.videoSaveLoader = true;
  let srcUrl = this.data.blobs.createObjectURL(mediaBolb);
  this.videoSaveLoader = false;
  this.dialogRef.close(srcUrl);
  
  // if(this.mediaAnnotation){
  //   await this.storageService.deleteMediaFiles(this.projectName, this.mediaAnnotation).then(async () =>{
  //     await this.storageService.uploadMediaFiles("liveVideo",mediaBolb, this.projectName, titleId).then(datas => {
        
  //      });
  //   })
  // }


}

  close(){
    this.dialogRef.close();
    // this.data.isToolBarOpen=false;
  }
  renameTitle($event:any){
    this.data.annotationTableData[this.data.getAnnotationTableId(this.data.selectedAnnotation)].value = $event;
  }
}
